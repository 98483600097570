import React, { useEffect } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { AllFarmsCont, FarmsPageCont, FarmsTitle, SingleFarmCont, FarmLogo, FarmTitle, FarmEndDate, PoolAmount, ProposerTypeMessage, DaoButton } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { myChain, wax, anchor, currentApiList, currentAtomicApiList, currentWebsiteURL, currentUsername, wombat } from '../data/config';


const MyFarms = () => {

  const { farmList, setFarmList } = useStateContext();

useEffect(() => {
setFarmList([]);
 const waxUsername = localStorage.getItem('waxAccount');
 const walletProvider = localStorage.getItem('walletProvider');
  
 if(!currentUsername){ return(''); }

  axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
    table:"farms",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    index_position: 3,
    limit:50,
    lower_bound: waxUsername,
    upper_bound: waxUsername,
    json:true
  })
    .then((response) => {
      setFarmList([response.data.rows]);
    })
    .catch((error) => console.log(error));
  }
, []);

  return (
  <div id="seo">
    <Helmet>
    <title>My Farms</title>
    <meta name="description" content="Track your NFT farms. Add tokens to the reward pool, deposit RAM, change your logo" />
    <link rel="canonical" href="https://waxdao.io/my-farms" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>
    <FarmsPageCont>
      <FarmsTitle>
        Manage Farms
        &nbsp;&nbsp;

      <a href={`${currentWebsiteURL}/create-farm`}>
      <DaoButton>
      + New Farm
      </DaoButton>
      </a>
      </FarmsTitle>

      <AllFarmsCont>


{farmList == null && (

<ProposerTypeMessage>
  You have not created any farms yet.<br/><br/>
  To create your own NFT farm, go to the <a href={`${currentWebsiteURL}/create-farm`} style={{color:"#fa872d"}} >Create Farm</a> page.
</ProposerTypeMessage>
)}

{farmList != null && farmList[0] != null && farmList[0].length == 0 && (

  <ProposerTypeMessage>
    You have not created any farms yet.<br/><br/>
    To create your own NFT farm, go to the <a href={`${currentWebsiteURL}/create-farm`} style={{color:"#fa872d"}} >Create Farm</a> page.
  </ProposerTypeMessage>
)}

{farmList[1] == null && farmList[0] != null && Array.isArray(farmList[0]) ? (farmList[0].map((item, index) => (
    <a href={`${currentWebsiteURL}/edit-farm/${item.farmname}`}>
    <SingleFarmCont>
      {item.logo != null && (
        <FarmLogo src={`https://ipfs.io/ipfs/${item.logo}`} />
      )}
      {item.farmname != null && (
        <FarmTitle>
        {item.farmname}
        </FarmTitle>
      )}

      {item.poolsize != null && (
        <PoolAmount>
          <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
         {item.poolsize.substring(item.poolsize.indexOf(' '))}
        </PoolAmount>
      )}

      {item.enddate != null && (
        <FarmEndDate>
          Ends {new Date(item.enddate * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

    </SingleFarmCont>
    </a>
    ))) : farmList[1] != null && Array.isArray(farmList) && farmList.map((item, index) => (
      <a href={`${currentWebsiteURL}/edit-farm/${item.farmname}`}>
      <SingleFarmCont>
      {item.logo != null && (
        <FarmLogo src={`https://ipfs.io/ipfs/${item.logo}`} />
      )}
      {item.farmname != null && (
        <FarmTitle>
        {item.farmname}
        </FarmTitle>
      )}
      {item.poolsize != null && (
        <PoolAmount>
          <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
         {item.poolsize.substring(item.poolsize.indexOf(' '))}
        </PoolAmount>
      )}
      {item.enddate != null && (
        <FarmEndDate>
          Ends {new Date(item.enddate * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

     </SingleFarmCont>
     </a>
      ))}






</AllFarmsCont>

      </FarmsPageCont>
    </UALProvider>
 
    </div>
  )
}

export default MyFarms