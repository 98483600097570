import React, { useState } from 'react'
import { BsInfoCircle } from 'react-icons/bs';
import { resizerPrefix, resizerSuffix } from '../data/constants';
import { TallFiltersCont, TallFiltersInnerCont } from '../Styles';
import { CreateFarmIPFS } from './LeftDiv';
import DatePicker from "react-datepicker";
import { handleCalendarChange } from '../data/functions/pack_functions';

const CreateNonCustodial = (props) => {
    const farmName = props.farmName;
    const setFarmName = props.setFarmName;
    const farmLogo = props.farmLogo;
    const setFarmLogo = props.setFarmLogo;
    const rewardToken = props.rewardToken;
    const setRewardToken = props.setRewardToken;
    const rewardContract = props.rewardContract;
    const setRewardContract = props.setRewardContract;
    const setEnterModalDisplay = props.setEnterModalDisplay;
    const setEnterModalText = props.setEnterModalText;
    const decimals = props.decimals;
    const setDecimals = props.setDecimals;
    const timestamp = props.timestamp;
    const setTimestamp = props.setTimestamp;
    const paymentMethod = props.paymentMethod;
    const setPaymentMethod = props.setPaymentMethod;
    const assetToBurn = props.assetToBurn;
    const setAssetToBurn = props.setAssetToBurn;
    const farmConfig = props.farmConfig;
    const setFarmConfig = props.setFarmConfig;

  return (
    <TallFiltersInnerCont>
        <h3>Farm Name</h3>
        <input placeholder="e.g. mynftfarm" maxLength={12} value={farmName} onChange={(e) => setFarmName(e.target.value.toLowerCase())} />
        <br/><br/>

        <h3>Farm Logo (IPFS Hash)</h3>
        <input
            type="text"
            placeholder="e.g. Qmabc....."
            id="farmLogo"
            value={farmLogo}
            onChange={(e) => {
                setFarmLogo(e.target.value);
            }}
        />
        <br />
        <span className="text-sm italic">*100px by 100px</span>

        {farmLogo != null && farmLogo != "" && farmLogo.length > 10 && (
            <CreateFarmIPFS
            src={`${resizerPrefix}${farmLogo}${resizerSuffix}`}
            />
        )}
        <br />
        <br />

        <TallFiltersCont>
        <TallFiltersInnerCont>
            <span className="font-bold">Note:</span> At this time, non-custodial farms have 2 configuration options:
            <br/><br/>
            - Full Collection: This means that all NFTs in the collection(s) are stakeable, and use a "shared pool" reward model.
            <br/>
            - Dynamic: This allows you to specify which templates can be staked, and how much rewards each template receives.
            <br/><br/>
            You can add the collections/templates after the farm is created. waxdaofarmer must be added as a notify account on all collections you add to the farm.
        </TallFiltersInnerCont>
        </TallFiltersCont>

          <br />
          <br />
          <h3>Configuration</h3>
          <select
            onChange={(e) => {
              setFarmConfig(e.target.value);
            }}
          >
            <option value="" hidden>
              Choose
            </option>
            <option value="Full Collection">Full Collection</option>
            <option value="Dynamic">Dynamic</option>
          </select>

    
        <TallFiltersCont>
                  <div className="w-100 pl-3 pr-3">
                    <span className="font-bold">Warning:</span> Entering
                    incorrect token details will result in a pool that doesn't
                    work, and WaxDAO will not be able to help you. <br />
                    Make 100% sure that you have the correct symbol, precision
                    and contract for the reward token.
                    <br />
                    WaxDAO will not be held responsible for user errors.
                  </div>
                </TallFiltersCont>

                <br />
                <br />
                <h3>Reward Token Symbol</h3>

                <input
                  type="text"
                  id="rewardToken"
                  placeholder="e.g. WOJAK"
                  maxLength={7}
                  value={rewardToken}
                  onChange={(e) => {
                    setRewardToken(e.target.value.toUpperCase());
                  }}
                />

                <br />
                <br />
                <h3 className="flex">
                  Reward Token Contract&nbsp;&nbsp;
                  <BsInfoCircle
                    className="mt-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEnterModalDisplay("");
                      setEnterModalText(
                        <span>
                          If WAX is the reward token, the info is: <br />
                          Contract: eosio.token
                          <br />
                          Decimals: 8<br />
                          For other tokens, search our{" "}
                          <a
                            href="https://waxdao.io/tokens"
                            target="none"
                            className="text-secondary"
                          >
                            Token Tracker
                          </a>{" "}
                          for this info
                        </span>
                      );
                    }}
                  />
                  <br />
                </h3>

                <input
                  type="text"
                  id="rewardContract"
                  maxLength={12}
                  placeholder="e.g. eosio.token"
                  value={rewardContract}
                  onChange={(e) => {
                    setRewardContract(e.target.value.toLowerCase());
                  }}
                />

                <br />
                <br />
                <h3 className="flex">
                  Reward Token Decimals&nbsp;&nbsp;
                  <BsInfoCircle
                    className="mt-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEnterModalDisplay("");
                      setEnterModalText(
                        <span>
                          If WAX is the reward token, the info is: <br />
                          Contract: eosio.token
                          <br />
                          Decimals: 8<br />
                          For other tokens, search our{" "}
                          <a
                            href="https://waxdao.io/tokens"
                            target="none"
                            className="text-secondary"
                          >
                            Token Tracker
                          </a>{" "}
                          for this info
                        </span>
                      );
                    }}
                  />
                  <br />
                </h3>

                <input
                  type="number"
                  id="decimals"
                  placeholder="e.g. 8"
                  value={decimals}
                  onChange={(e) => {
                    setDecimals(e.target.value);
                  }}
                />

<br />
                <br />
                <h3>Farm Expiration Date</h3>

                <DatePicker
                  showTimeSelect
                  timeIntervals={15}
                  selected={new Date(timestamp * 1000)}
                  onChange={(e) => {
                    handleCalendarChange(e, setTimestamp);
                  }}
                />

                <br />
                <br />
                <h3>Payment Method</h3>
                <select
                  onChange={(e) => {
                    setPaymentMethod(e.target.value);
                  }}
                >
                  <option value="" hidden>
                    Choose
                  </option>
                  <option value="Pay 250 Wax">Pay 250 Wax</option>
                  <option value="Pay 25,000 WOJAK">Pay 25,000 WOJAK</option>
                  <option value="Burn 1 Wojak NFT">Burn 1 Wojak NFT</option>
                  <option value="Use 1 WaxDAO Credit">
                    Use 1 WaxDAO Credit
                  </option>
                </select>

                <span
                  className={paymentMethod != "Burn 1 Wojak NFT" && "hidden"}
                >
                  <br />
                  <br />
                  <h3>Asset ID To Burn</h3>
                  <input
                    placeholder="e.g. 19353289652"
                    id="assetID"
                    value={assetToBurn}
                    onChange={(e) => {
                      setAssetToBurn(e.target.value);
                    }}
                  />
                  <br />
                  <br />
                  <TallFiltersCont>
                    <div className="w-100 pl-3 pr-3">
                      Don't have a Wojak NFT to burn? You can buy one from our{" "}
                      <a href="https://waxdao.io/drops/247" target="none">
                        Official Drop
                      </a>{" "}
                      and then{" "}
                      <a href="https://waxdao.io/unbox/246" target="none">
                        unbox it
                      </a>
                      .
                      <br />
                      <br />
                      The unboxed Wojak can be burned to create a farm, by
                      simply pasting the asset ID into the field above.
                    </div>
                  </TallFiltersCont>
                </span>                
        
    </TallFiltersInnerCont>
  )
}

export default CreateNonCustodial