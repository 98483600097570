import { useState } from "react";
import { currentApiList, getCurrentApiList, getCurrentAtomicApiList } from "../../data/config";
import axios from 'axios';

export const useGetCollectionInfo = () => {
    
    const [collectionInfo, setCollectionInfo] = useState([]);
    const [collectionInfoIsLoading, setCollectionInfoIsLoading] = useState(true);

    const getCollectionInfo = (CollectionName) => {
        axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/collections/${CollectionName}`)
        .then((response) => {
            setCollectionInfo([response.data.data]);                        
        })        
        .catch((error) => console.log(error))
        .finally(() => setCollectionInfoIsLoading(false))
    }

    return [collectionInfo, getCollectionInfo, collectionInfoIsLoading]
}