import { useState } from "react";
import { mainnetAtomicApiList } from "../../data/config";
import axios from 'axios';

export const useGetDAOProofAssets = () => {
  const [proofAssets, setProofAssets] = useState([]);

  const getProofAssets = async (waxUsername, minimumAmount, schemas) => {   
    let tempProofAssets = [];     

    try {
      for (let i = 0; i < schemas.length; i++) {
        const thisCollection = schemas[i].collection_name;
        const thisSchema = schemas[i].schema_name;

        for (let apiIndex = 0; apiIndex < mainnetAtomicApiList.length; apiIndex++) {
          try {
            const atomicResponse = await axios.get(`${mainnetAtomicApiList[apiIndex]}/atomicassets/v1/assets?collection_name=${thisCollection}&schema_name=${thisSchema}&owner=${waxUsername}&page=1&limit=1000&order=desc&sort=transferred`);

            const rows = atomicResponse.data.data;
            for (let j = 0; j < rows.length; j++) {
              tempProofAssets.push(rows[j].asset_id);

              if (tempProofAssets.length >= minimumAmount) {
                setProofAssets(tempProofAssets);
                return;
              }
            }

            // If successful, break from API loop to proceed to the next schema
            break;

          } catch (error) {
            // If not the last API in the list, continue to next API.
            if (apiIndex < mainnetAtomicApiList.length - 1) {
              continue;
            } else {
              // If it's the last API and still error, throw the error.
              throw error;
            }
          }
        }
      }

      setProofAssets(tempProofAssets);
    } catch (error) {
      console.error(error);
    }
  }

  return [proofAssets, getProofAssets]
}
