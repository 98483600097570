import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { MainContainer, FarmMenu, FarmMenuItem, FarmTopMenu, FarmTopMenuItem, LeftDiv, DaoName, Creator, DaoMenu, DaoMenuItem, RightDiv, AboutDAO, DaoTitle, AboutDaoCont, AboutDaoTitle, AboutDaoBody, SingleTemplate, TemplatesCont, StakingCont, DaoButton, NFT, NFTsCont, NFTLogo, NoDaosMessage, ProposalInput, DaoButtonCont, RamModal, WalletCont, FarmButtonCont, ToggleSwitch, InnerSwitch, ToggleSwitchCont, ToggleMessage, StakeManyButton, StakeManyDiv, LeftProfile, RightProfile, ProfileCont, ProfileImage, LeftProfileImg, SocialButton, FarmsTitle, FarmsPageCont, DropPageCont, MissingInfoMsg, Drop, DropTitle, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DropImage, Remaining, Price, QuantityToBuy, MinMint, AddNFT, DropDescription, DropBody, QuantityToDeposit } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
var thisCollection;
var list = [];
var list2 = [];
import { Name, raw } from "eos-common";
import ReactMarkdown from 'react-markdown';
import { myChain, wax, anchor, currentApiList, currentAtomicApiList, currentUsername, currentWebsiteURL, wombat } from '../../data/config';
import { useGetCollectionInfo } from '../../components/CustomHooks/useGetCollectionInfo';
import LoadingPage from '../../components/LoadingPage';
import Error from '../../components/Error';
import { JobOfferTitle, MilestoneContainer, NewListingBody, NewListingCont } from '../../Styles';
import { useGetCollectionFarms } from '../../components/CustomHooks/useGetCollectionFarms';
import FarmCard from '../../components/FarmCard';
import { useGetCollectionDrops } from '../../components/CustomHooks/useGetCollectionDrops';
import DropCard from '../../components/DropCard';
import { RecentFarmsContainer } from '../../data/css/FarmsListCSS';
import { CollectionProfileAvatarCont, CollectionProfileBGContainer, CollectionProfileContainer, FarmsAndDropsCont, MilestoneBoldWhite, MilestoneThird } from '../../data/css/CollectionPageStyles';
import collection_profile_stock_bg from '../../data/collection_profile_stock_bg.png'
import { resizerPrefix, resizerSuffix } from '../../data/constants';
import { useGetCollectionFloorPrice } from '../../components/CustomHooks/useGetCollectionFloorPrice';




const CollectionPage = ({ location }) => {

  const { enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,

   } = useStateContext();

const { CollectionName } = useParams();
const [collectionInfo, getCollectionInfo, collectionInfoIsLoading] = useGetCollectionInfo(CollectionName);
const [collectionFarms, getCollectionFarms, collectionFarmsAreLoading] = useGetCollectionFarms(CollectionName);
const [collectionDrops, collectionPacks, getCollectionDrops, collectionDropsAreLoading] = useGetCollectionDrops(CollectionName);
const [collectionFloorPrice, getCollectionFloorPrice, collectionFloorPriceIsLoading] = useGetCollectionFloorPrice(CollectionName);
const [showDrops, setShowDrops] = useState(true);
const [showFarms, setShowFarms] = useState(true);
const [showUnpack, setShowUnpack] = useState(true);

useEffect(() => {
    getCollectionInfo(CollectionName)
}, []);

useEffect(() => {
  if(!collectionInfoIsLoading){
    getCollectionFarms(CollectionName, setShowFarms)
  }
}, [collectionInfoIsLoading]);

useEffect(() => {
  if(!collectionFarmsAreLoading && !collectionInfoIsLoading){
    getCollectionDrops(CollectionName, setShowDrops, setShowUnpack)
  }
}, [collectionFarmsAreLoading]);

useEffect(() => {
  if(!collectionFarmsAreLoading && !collectionInfoIsLoading && !collectionDropsAreLoading){
    getCollectionFloorPrice(CollectionName)
  }
}, [collectionDropsAreLoading]);

if(collectionInfo.length > 0 && !collectionInfoIsLoading && !collectionFarmsAreLoading && !collectionDropsAreLoading && !collectionFloorPriceIsLoading){
  return (
    <div id="seo">
    <Helmet>
    <title>{CollectionName} Collection</title>
    <meta name="description" content={`Edit and create drops for ${CollectionName} NFT collection`} />
    <link rel="canonical" href={`https://waxdao.io/collection/${CollectionName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>

      <CollectionProfileContainer>
        <CollectionProfileBGContainer>
          {collectionInfo[0]?.data.images != null && JSON.parse(collectionInfo[0]?.data.images) != null  && JSON.parse(collectionInfo[0]?.data.images).banner_1920x500?.length > 0 ? (
            <img src={`${resizerPrefix}${JSON.parse(collectionInfo[0].data.images)?.banner_1920x500}`} style={{objectFit:"cover", width: "100%", height: "100%"}} />
          ) : (
            <img src={collection_profile_stock_bg} style={{width: "auto", height: "auto", minHeight: "100%", minWidth: "100%"}} />
          )}
          
        </CollectionProfileBGContainer>

        <CollectionProfileAvatarCont>
          <img src={collectionInfo[0].data.img != null && `${resizerPrefix}${collectionInfo[0].data.img}${resizerSuffix}`} style={{width: "auto", height: "auto", minHeight: "100%", minWidth: "100%"}} />
        </CollectionProfileAvatarCont>
      </CollectionProfileContainer>

        <div className='p-5 mt-4 text-white'>
          <br/>
          <span className='text-2xl font-semibold'>
            {collectionInfo[0].name != null && collectionInfo[0].name}
          </span>
          <br/>
          <span className='text-md'>
            {collectionInfo[0].author != null && (<span>By {collectionInfo[0].author}</span>)}
          </span>
          

      <MilestoneContainer>
        <MilestoneThird>
          {collectionInfo[0].market_fee != null && (<span>Collection Fee <MilestoneBoldWhite>{Number(collectionInfo[0].market_fee * 100)}%</MilestoneBoldWhite></span>)}
        </MilestoneThird>
        <MilestoneThird>
          {collectionInfo[0].created_at_time != null && (<span>Created <MilestoneBoldWhite>{new Date(Number(collectionInfo[0].created_at_time)).toLocaleDateString('en-us', { year:"numeric", month:"short"})}</MilestoneBoldWhite></span>)}
        </MilestoneThird>
        <MilestoneThird>
          {collectionFloorPrice != null && (<span>Floor <MilestoneBoldWhite>{Number(collectionFloorPrice).toFixed(2)} WAX</MilestoneBoldWhite></span>)} 
        </MilestoneThird>
      </MilestoneContainer>


          <br/>
          <span className='text-md'>
            {collectionInfo[0].data.description != null && (<ReactMarkdown>{collectionInfo[0].data.description}</ReactMarkdown>)}
          </span>

        </div>

<FarmsAndDropsCont>
  <NewListingBody>



    {/* {JSON.stringify(collectionInfo[0])} */}


    <JobOfferTitle>NFT Farms
      <button onClick={() => setShowFarms((prev) => !prev)}>
        {showFarms ? "x" : "+"}
      </button>
    </JobOfferTitle>
    <RecentFarmsContainer closed={!showFarms && true}>
      {collectionFarms != null && collectionFarms.length > 0 ? collectionFarms.map((item, index) => (
        <a href={`${currentWebsiteURL}/farm/${item.farmname}`}>
          <FarmCard item={item} />
        </a>
      )) : ("No farms")}
    </RecentFarmsContainer>

    <br/>
    <JobOfferTitle>Drops
      <button onClick={() => setShowDrops((prev) => !prev)}>
        {showDrops ? "x" : "+"}
      </button>
    </JobOfferTitle>
    <RecentFarmsContainer closed={!showDrops && true}>
      {collectionDrops != null && collectionDrops.length > 0 ? collectionDrops.map((item, index) => (
        <a href={`${currentWebsiteURL}/drops/${item.ID}`}>
          <DropCard item={item} />
        </a>
      )) : ("No drops")}
    </RecentFarmsContainer>

    <br/>
    <JobOfferTitle>Unpack
      <button onClick={() => setShowUnpack((prev) => !prev)}>
        {showUnpack ? "x" : "+"}
      </button>
    </JobOfferTitle>
    <RecentFarmsContainer closed={!showUnpack && true}>
      {collectionPacks != null && collectionPacks.length > 0 ? collectionPacks.map((item, index) => (
        <a href={`${currentWebsiteURL}/unbox/${item.ID}`}>
          <DropCard item={item} />
        </a>
      )) : ("No unbox links")}
    </RecentFarmsContainer>

  </NewListingBody>
</FarmsAndDropsCont>

    </UALProvider>
    <br/><br/><br/>
    </div>
  )}
  else if( (collectionInfoIsLoading || collectionFarmsAreLoading || collectionDropsAreLoading || collectionFloorPriceIsLoading) ){
    return(<LoadingPage />)
  } else if( (collectionInfo == null || collectionInfo.length == 0) && !collectionInfoIsLoading ){
    return(<Error error="This collection does not exist" />)
  }
}

export default CollectionPage