import React from 'react'
import NumberFormat from 'react-number-format';
import { currentWebsiteURL } from '../data/config';
import { resizerPrefix, resizerSuffix } from '../data/constants';
import { DropCardBottomHalf, DropCardDate, DropCardEndDate, DropCardImage, DropCardLiveStatus, DropCardName, DropCardPrice, DropCardTitle, DropCardTopHalf, DropCardWrapper, FarmCardPoolSize } from '../data/css/DropStyles'
import { FarmCardUnofficial } from '../data/css/FarmsListCSS';
import { WalletCardMint } from '../data/css/WalletStyles';
import { getGovernanceAsset } from '../data/functions/dao_functions';
import { dropIsLive, dropIsSoldOut } from '../data/functions/DropFunctions';
import { isAnOfficialFarm } from '../data/functions/farm_functions';
import { DropImage, DropIsLive, DropType, FarmEndDate, FarmTitle, PoolAmount, SingleDropLogo, SoldOut } from './LeftDiv';

const DAOCard = (props) => {
    const item = props.item;
    const sort = props.sort;
  return (
    <DropCardWrapper>
        <DropCardTopHalf>
            {item.logo != null && (
                <DropCardImage src={`${resizerPrefix}${item.logo}${resizerSuffix}`} />
            )}
        </DropCardTopHalf>

        <FarmCardUnofficial official={true}>
        {getGovernanceAsset(item)}
        </FarmCardUnofficial>


      {item.creator != null && (
        <a href={`${currentWebsiteURL}/u/${item.creator}`}>
            <DropCardTitle>
            {item.creator}
            </DropCardTitle>
        </a>
      )}

        {item.daoname != null && (
            <DropCardName>
                {item.daoname}
            </DropCardName>
        )}

        <DropCardBottomHalf />

        {sort != null && sort == "popular" && (
        <FarmCardPoolSize>
            <NumberFormat displayType='text' thousandSeparator={true} value={item.totalstaked} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
            &nbsp;NFTs
        </FarmCardPoolSize>
        )}


    </DropCardWrapper>
  )
}

export default DAOCard