import axios from "axios";
import { StakeLeftRow, StakeRightRow, StakersLeft, StakersRow } from "../../components/LeftDiv";
import { MainButton, SmallButton } from "../../Styles";
import { wax, anchor, myChain, currentApiList, currentAtomicApiList, currentUsername, currentWebsiteURL, currentAtomicHubLink, currentHyperionApiList } from "../config";
import { BoldWhiteSpan } from "../css/FarmPageStyles";
import { checkWallet, getWalletSession } from "./wallet_functions";

export const depositTokens = async (farmName, amountToAdd, precision, contract, rewardtoken, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');


  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2]; 

  try {
    // Reset our response state to clear any previous transaction data
    
    const action = [{
        account: contract,
        name: 'transfer',
        authorization: [{
          actor: this_user,
          permission: this_permission,
      }],
      data: {
          to: 'waxdaofarmer',
          from: this_user,
          quantity: Number(amountToAdd).toFixed(Number(precision)) + ' ' + rewardtoken,
          memo: '|farm_deposit|' + farmName + '|',
      }
    }]
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction({
      actions: action
    }, {

      blocksBehind: 3,
      expireSeconds: 600,
      broadcast: true,
    })
    // Update application state with the responses of the transaction
    setLoadingDisplay('');
    setEnterButtonsDisplay('hidden');
    setEnterModalText('Processing your transaction...')
    const timer = setTimeout(() => {
      
        setEnterModalText('Your rewards have been added to the farm');
        setLoadingDisplay('none');
        setEnterButtonsDisplay('');
  
    }, 4000);
    return () => clearTimeout(timer);

  } catch(e) {


          setEnterModalText(JSON.stringify(e.message));

    console.log(e)
    
  }

}



export const removeTemplate = async (FarmName, tempToRemove, rewardType, farmType, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2]; 

    var action_name;
    if( rewardType == "dynamic" && farmType == "template"){ action_name = "rmvdynamict"; }
    else if( rewardType == "dynamic" && farmType == "nc.v2"){ action_name = "removenctemp"; }
    else{ action_name = "removetempl"; }
  
    try {
      
      const action = [{
            account: "waxdaofarmer",
            name: action_name,
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              user: this_user,
              farmname: FarmName,
              temp_id: tempToRemove,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Removing your template...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your template has been removed.');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 1000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
  } //end removeTemplate



export const addDynamicTemp = async (farmName, farm_type, templateID, collection, stakingValue, precision, rewardtoken, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    let walletSession = await getWalletSession(setEnterModalText);
    let session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2]; 
  
    let action_name;

    if(farm_type == "nc.v2"){
      action_name = {
        account: "waxdaofarmer",
        name: 'addnctemp',
        authorization: [{
          actor: this_user,
          permission: this_permission,
      }],
      data: {
          user: this_user,
          farmname: farmName,
          template_info: {collection_name: collection, template_id: templateID, hourly_reward: Number(stakingValue).toFixed(Number(precision)) + ' ' + rewardtoken, multiplier: 1}
      }
    }      
    } else {
      action_name = {
        account: "waxdaofarmer",
        name: 'setdynamict',
        authorization: [{
          actor: this_user,
          permission: this_permission,
      }],
      data: {
          user: this_user,
          farmname: farmName,
          temp_id: templateID,
          hourly_reward: Number(stakingValue).toFixed(Number(precision)) + ' ' + rewardtoken,
          collection_name: collection,
      }
    }
    }


    try {

      const action = [action_name]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your template has been added');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 1000);
      return () => clearTimeout(timer);
  
    } catch(e) {
  
  
            setEnterModalText(JSON.stringify(e.message));
  
      console.log(e)
      
    }
  
  }



export const addCollection = async (farmName, collection, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2]; 
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
          account: "waxdaofarmer",
          name: 'addnewcol',
          authorization: [{
            actor: this_user,
            permission: this_permission,
        }],
        data: {
            user: this_user,
            farmname: farmName,
            collection: collection,
        }
      }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your collection has been added');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
  
  
            setEnterModalText(JSON.stringify(e.message));
  
      console.log(e)
      
    }
  
  }

  
  export const removeCollection = async (farmName, collection, reward_type, farmtype, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2]; 
  
    try {
      const action = [{
          account: "waxdaofarmer",
          name: 'removecol',
          authorization: [{
            actor: this_user,
            permission: this_permission,
        }],
        data: {
            user: this_user,
            farmname: farmName,
            collection: collection,
        }
      }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your collection has been removed');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 1000);
      return () => clearTimeout(timer);
  
    } catch(e) {
  
  
            setEnterModalText(JSON.stringify(e.message));
  
      console.log(e)
      
    }
  
  }


export const newProfile = async (FarmName, description, telegram, discord, twitter, website, nftDrop, image1, image2, image3, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2]; 
  
    try {
      
      const action = [{
            account: "waxdaofarmer",
            name: 'newprofile',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              user: this_user,
              farmname: FarmName,
              description: description,
              telegram: telegram,
              discord: discord,
              twitter: twitter,
              website: website,
              latest_drop: nftDrop,
              image1: image1,
              image2: image2,
              image3: image3,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Creating your profile...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your profile is now created');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
  } //end newProfile

export const increaseTemplateMax = async (FarmName, amountOfTemplatesToAdd, paymentMethod, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2]; 
  
    var contract_to_interact_with;
  
    var cost_of_upgrade;
    
    if(paymentMethod == "WAX"){
      if(amountOfTemplatesToAdd == '10'){
        cost_of_upgrade = "10.00000000 WAX";
      }
      else if(amountOfTemplatesToAdd == '50'){
        cost_of_upgrade = "25.00000000 WAX";
      }
      else if(amountOfTemplatesToAdd == '100'){
        cost_of_upgrade = "40.00000000 WAX";
      }
      else if(amountOfTemplatesToAdd == '500'){
        cost_of_upgrade = "100.00000000 WAX";
      }
      contract_to_interact_with = "eosio.token";
    } else {
      if(amountOfTemplatesToAdd == '10'){
        cost_of_upgrade = '2293 WOJAK';
      }
      else if(amountOfTemplatesToAdd == '50'){
        cost_of_upgrade = '5733 WOJAK';
      }
      else if(amountOfTemplatesToAdd == '100'){
        cost_of_upgrade = '9174 WOJAK';
      }
      else if(amountOfTemplatesToAdd == '500'){
        cost_of_upgrade = '22935 WOJAK';
      }
      contract_to_interact_with = "mdcryptonfts";
    }
  
  
    try {
      
      const action = [{
            account: contract_to_interact_with,
            name: 'transfer',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: this_user,
              quantity: cost_of_upgrade,
              memo: '|template_upgrade|' + FarmName + '|' + amountOfTemplatesToAdd + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Increasing your limit...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your template limit has been increased. Refresh the page to see changes.');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }

  } //end increaseTemplateMax
  


export const addTemplates = async (FarmName, templates, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2]; 

    try {
      
      const action = [{
            account: "waxdaofarmer",
            name: 'addtempl',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              user: this_user,
              farmname: FarmName,
              temp_ids: templates.split(','),
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Adding your templates...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your templates have been added.');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
  } //end addTemplates


export const extendFarmNew = async (FarmName, newEndDate, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2]; 

    try {
      
      const action = [{
        account: 'waxdaofarmer',
        name: 'extendfarm',
        authorization: [{
          actor: this_user,
          permission: this_permission,
      }],
      data: {
          farmname: FarmName,
          endtime: newEndDate,

      }
    }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Extending farm...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your farm has been extended.');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
  } //end extendFarmNew

  export const banUser = async (FarmName, user, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2]; 

    try {
      
      const action = [{
        account: 'waxdaofarmer',
        name: 'banuser',
        authorization: [{
          actor: this_user,
          permission: this_permission,
      }],
      data: {
          farmname: FarmName,
          farm_owner: currentUsername,
          user_to_ban: user,

      }
    }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Ban hammer in motion...')
      const timer = setTimeout(() => {
        
          setEnterModalText('The ban hammer has been dropped');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 2000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
  } //end banUser


  export const unbanUser = async (FarmName, user, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2]; 

    try {
      
      const action = [{
        account: 'waxdaofarmer',
        name: 'unbanuser',
        authorization: [{
          actor: this_user,
          permission: this_permission,
      }],
      data: {
          farmname: FarmName,
          user_to_unban: user,

      }
    }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Lifting ban hammer...')
      const timer = setTimeout(() => {
        
          setEnterModalText('User has been unbanned');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 2000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
  } //end unbanUser


  export const kickUser = async (FarmName, user, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2]; 

    try {
      
      const action = [{
        account: 'waxdaofarmer',
        name: 'kickuser',
        authorization: [{
          actor: this_user,
          permission: this_permission,
      }],
      data: {
          farmname: FarmName,
          user_to_kick: user,

      }
    }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Booting em...')
      const timer = setTimeout(() => {
        
          setEnterModalText('User got the boot, fam');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 2000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
  } //end kickUser


  export const changeLogoNew = async (farmName, logoHash, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2]; 

    try {
      
      const action = [{
        account: 'eosio.token',
        name: 'transfer',
        authorization: [{
          actor: this_user,
          permission: this_permission,
      }],
      data: {
        to: 'waxdaofarmer',
        from: this_user,
        quantity: "1.00000000 WAX",
        memo: '|logo_change|' + farmName + '|' + logoHash + '|',
      }
    }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Changing logo...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your logo has been changed');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
  } //end changeLogoNew


export const editStakeableAssets = (farmData, dynamicTemps, currentViewStakeableTab, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    if(farmData.farmtype == 'template' && farmData.reward_type != 'dynamic'){
        return (
            <span>
                These templates from collection <BoldWhiteSpan><a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>{farmData.collection}</a></BoldWhiteSpan>
                <br/><br/>
                <div className="flex gap-4 flex-wrap">
                {farmData.templates.map((item, index) => (
                  <StakersRow key={index}>
                  <StakeLeftRow>
                  <a href={`${currentAtomicHubLink}/explorer/template/${farmData.collection}/${item}`} target="none" className="font-semibold">{item}</a>
                  </StakeLeftRow>
                  <StakeRightRow>
                   <SmallButton
                    onClick={() => removeTemplate(farmData.farmname, item, farmData.reward_type, farmData.farmtype, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}
                   >
                     Remove
                   </SmallButton>
                  </StakeRightRow>                    
                </StakersRow>
                ))}
                </div>
            </span>)
    }
    //v2 farm
    else if((farmData.farmtype == 'template' && farmData.reward_type == 'dynamic')
      ||
      (farmData.farmtype == 'nc.v2' && farmData.reward_type == 'dynamic')
    ){
      if(currentViewStakeableTab == "Collections"){
        return (
            <span>
                <div className="flex gap-4 flex-wrap mt-3 mb-3">
                {farmData.collections.map((item, index) => (
                  <StakersRow key={index}>
                  <StakeLeftRow>
                  <a href={`${currentWebsiteURL}/collection/${item.collection_name}`} className="font-semibold">{item.collection_name}</a>
                  </StakeLeftRow>
                  <StakeRightRow>
                   <SmallButton
                    onClick={() => removeCollection(farmData.farmname, item.collection_name, farmData.reward_type, farmData.farmtype, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}
                   >
                     Remove
                   </SmallButton>
                  </StakeRightRow>                    
                </StakersRow>
                ))}
                </div>
            </span>)
          }
          else if(currentViewStakeableTab == "Templates"){
            return(
              <span>
              <div className="mt-3">
              {dynamicTemps.map((item, index) => (
                  <StakersRow key={index}>
                    <StakeLeftRow>
                    <a href={`${currentAtomicHubLink}/explorer/template/${item.collection}/${item.template_id}`} target="none" className="font-semibold">{item.template_id}</a> ({item.reward_amount})
                    </StakeLeftRow>
                    <StakeRightRow>
                     <SmallButton
                      onClick={() => removeTemplate(farmData.farmname, item.template_id, farmData.reward_type, farmData.farmtype, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}
                     >
                       Remove
                     </SmallButton>
                    </StakeRightRow>                    
                  </StakersRow>
              ))}
              </div>
          </span>              
            )
          }
    }
    else if(farmData.farmtype == 'collection'){
        return (<span>Any asset from <BoldWhiteSpan><a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>{farmData.collection}</a></BoldWhiteSpan> collection</span>)
    }
    else if(farmData.farmtype == 'schema'){
        return (<span>Any asset from <BoldWhiteSpan><a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>{farmData.collection}</a></BoldWhiteSpan> collection, in the <BoldWhiteSpan>{farmData.schema}</BoldWhiteSpan> schema</span>)
    }
    else if(farmData.farmtype == 's.exclude'){
        return (
        <span>
            Any asset from <BoldWhiteSpan><a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>{farmData.collection}</a></BoldWhiteSpan> collection, in the <BoldWhiteSpan>{farmData.schema}</BoldWhiteSpan> schema, EXCEPT for the following templates:
            <br/>
            <div className="flex gap-4 flex-wrap">
            {farmData.templates.map((item, index) => (
                <MainButton key={index}>
                    {item}
                </MainButton>
            ))}
            </div>
        </span>)
    }
}

export const assetsAreAddable = (farmData) => {
  if(farmData.farmtype == "collection" || farmData.farmtype == "schema"){
    return false
  }
  else{
    return true
  }
}

export const farmIsUpgradeable = (farmData) => {
  if(farmData.farmtype == "collection" || farmData.farmtype == "schema" || farmData.farmtype == "nc.c.shared" || (farmData.farmtype == 'template' && farmData.reward_type == 'dynamic')){
    return false
  }
  else{
    return true
  }
}

export const getRewardTokenBalance = (contract, symbol, setRewardTokenBalance) => {
  let unfiltered = []

  axios
    .get(
      `${
        currentHyperionApiList[Math.floor(Math.random() * (1 - 0)) + 0]
      }/v2/state/get_tokens?limit=100&account=${currentUsername}&sort=desc`
    )
    .then((response) => {
      unfiltered = response.data.tokens
      
      if(response.data.tokens.length >= 100){
        axios
        .get(
          `${
            currentHyperionApiList[Math.floor(Math.random() * (1 - 0)) + 0]
          }/v2/state/get_tokens?limit=100&skip=100&account=${currentUsername}&sort=desc`
        ).then((res) => {
          unfiltered = unfiltered.concat(res.data.tokens)
          //setRewardTokenBalance(unfiltered)
          //return
        })
        .catch((error) => console.log(error.response.data));
      }
      let i = 0;
      while(i < unfiltered.length){
        if(unfiltered[i].contract == contract && unfiltered[i].symbol == symbol){
          setRewardTokenBalance(unfiltered[i].amount)
          return
        }
        i++
      }
    })
    .catch((error) => console.log(error.response.data));
};