import React, { useEffect } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { ProposerTypeMessage, DaoButton, NFT, NewRow, BuyWojak, ProposalInput, NewProposal, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DisabledButton, MainContainer, CreateDaoCont, DaoTitle, CreateFarmMsg, DropDown, UserWhitelist, BurnableCont, MissingInfoMsg, PaymentOptCont, FinalizeButton, CreateDropIPFS, LoadSpinner, LoadSpinnerRed, LoadSpinnerBlue, LoadSpinnerGreen, PropDescription, SubmitButton, ProposalChoices, RemoveButton, AddButton, NFTsCont, AttributesCont, Attribute, AddIngredient, StakeManyButton, NoDaosMessage, WalletMessage, BlendMessage, BlendMessageLink, CallToAction } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { DatePickerComponent, DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import '.././datepicker.css';

import { currentApiList, currentAtomicApiList, wax, anchor, myChain, currentHyperionApiList, currentWebsiteURL  } from '../../data/config';
import { checkWallet } from '../../data/functions/wallet_functions';
import { createBlend } from '../../data/functions/blend_functions';

const schemaTimer = setTimeout(() => {
  

  }, 2000);



// INGREDIENTS

const handleFungibleIngredientAdd = (setIngredientsList, ingredientsList) => {

    setIngredientsList([...ingredientsList, {"ingredient_type":"fungible", "filter_by":"", "return_to_user": false, "burn_non_fungible":true, "fungible_token_symbol":"8,WAX", "fungible_token_contract":"", "fungible_token_amount":"1", "collection_name":"", "schema_name":"", "template_id":"1", "non_fungible_quantity":"0", "required_attributes":[], "returned_attributes":[] }])

}

const handleNonFungibleIngredientAdd = (setIngredientsList, ingredientsList) => {

  setIngredientsList([...ingredientsList, {"ingredient_type":"nonfungible", "filter_by":"", "return_to_user": false, "burn_non_fungible":true, "fungible_token_symbol":"8,WAX", "fungible_token_contract":"", "fungible_token_amount":"1", "collection_name":"", "schema_name":"", "template_id":"1", "non_fungible_quantity":"0", "required_attributes":[], "returned_attributes":[] }])

}

const handleIngredientRemove = (setIngredientsList, ingredientsList, index) => {
    const list = [...ingredientsList];
    list.splice(index, 1);
    setIngredientsList(list);
    //console.log(list)

}


const handleIngredientTypeChange = (e, index, ingredientsList, setIngredientsList) => {
    const {name, value} = e.target;
    const list = [...ingredientsList];
    list[index][name] = value;
    setIngredientsList(list);

}

const handleIngredientSymbolChange = (e, index, ingredientsList, setIngredientsList) => {
    const {name, value} = e.target;
    const list = [...ingredientsList];
    list[index][name] = value.toUpperCase();
    setIngredientsList(list);

}

const handleIngredientContractChange = (e, index, ingredientsList, setIngredientsList) => {
    const {name, value} = e.target;
    const list = [...ingredientsList];
    list[index][name] = value.toLowerCase();
    setIngredientsList(list);

}


const handleIngredientFungibleQuantityChange = (e, index, ingredientsList, setIngredientsList) => {
    const {name, value} = e.target;
    const list = [...ingredientsList];
    list[index][name] = value;
    setIngredientsList(list);

}

const handleIngredientFilterChange = (e, index, ingredientsList, setIngredientsList) => {
    const {name, value} = e.target;
    const list = [...ingredientsList];
    list[index][name] = value;
    setIngredientsList(list);

}

const handleIngredientBurnNonFungibleChange = (e, index, ingredientsList, setIngredientsList) => {
    const {name, value} = e.target;
    const list = [...ingredientsList];
    list[index][name] = JSON.parse(value);
    setIngredientsList(list);

}

const handleIngredientReturnToUserChange = (e, index, ingredientsList, setIngredientsList) => {
  const {name, value} = e.target;
  const list = [...ingredientsList];
  list[index][name] = JSON.parse(value);
  setIngredientsList(list);

}

const handleIngredientNonFungibleQuantityChange = (e, index, ingredientsList, setIngredientsList) => {
  const {name, value} = e.target;
  const list = [...ingredientsList];
  list[index][name] = value;
  setIngredientsList(list);

}

const handleIngredientCollectionChange = (e, index, ingredientsList, setIngredientsList) => {
    const {name, value} = e.target;
    const list = [...ingredientsList];
    list[index][name] = value.toLowerCase();
    setIngredientsList(list);

}

const handleIngredientSchemaChange = (e, index, ingredientsList, setIngredientsList, schemaTimer) => {
    const {name, value} = e.target;
    const list = [...ingredientsList];
    list[index][name] = value.toLowerCase();
    setIngredientsList(list);

}


const loadAttributes = (index, ingredientsList, setIngredientsList, setLoadingAttributes, setCurrentAttributeIndex, setAttributeLoadingError) => {
    const list = [...ingredientsList];
    setLoadingAttributes(true);
    setCurrentAttributeIndex(index);
    setAttributeLoadingError(false);
    var apiWorked = false;

  
        try {
        axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
          table:"schemas",
          scope:list[index].collection_name,
          code:"atomicassets",
          limit:1,
          lower_bound:list[index].schema_name,
          upper_bound:list[index].schema_name,
          json:true
        }).then((resultResponse) => {
            list[index].schema_format = resultResponse.data.rows[0].format;
            setIngredientsList(list);
            setLoadingAttributes(false);
            apiWorked = true;
      
        })
  
  
    }//end try timer
  
    catch(e) {
      setLoadingAttributes(false);
      setAttributeLoadingError(true);
      console.log(e)
      
      
    }//end catch timer
  

    const timer2 = setTimeout(() => {
  
      setLoadingAttributes(false);
      if(apiWorked == false){
        setAttributeLoadingError(true);
      }
  
      }, 5000);

      return () => {clearTimeout(timer2);}


}


const handleIngredientAttributeRemove = (index, attributeIndex, ingredientsList, setIngredientsList, name, type) => {

    const assetList = [...ingredientsList];
    assetList[index].required_attributes.splice(assetList[index].required_attributes.findIndex(attributeIndex => attributeIndex.name === name), 1);
    setIngredientsList(assetList);

  
  
  }
  
  
  
  
  const handleIngredientAttributeAdd = (index, attributeIndex, ingredientsList, setIngredientsList, name, type) => {
        const list = [...ingredientsList];
        list[index].required_attributes = [...list[index].required_attributes, {"name":name, "type":type, "value": '', "criteria": 'exact', "range_min": '0', "range_max": '0', "combined_amount": '0', "list_of_accepted_values": []}];

        setIngredientsList(list);
        
  
  }

  


  const handleIngredientAttributeChange = (e, index, attributeIndex, ingredientsList, setIngredientsList, attribute_name, type) => {
    const {name, value} = e.target;
    const list = [...ingredientsList];
    list[index].required_attributes[list[index].required_attributes.findIndex(attributeIndex => attributeIndex.name === name)] = {"name":name, "type":type, "value":value, "criteria": 'exact', "range_min": '0', "range_max": '0', "combined_amount": '0', "list_of_accepted_values": []};
    setIngredientsList(list);

}





const handleReturnAttributeRemove = (index, attributeIndex, ingredientsList, setIngredientsList, name, type) => {

  const assetList = [...ingredientsList];
  assetList[index].returned_attributes.splice(assetList[index].returned_attributes.findIndex(attributeIndex => attributeIndex.name === name), 1);
  setIngredientsList(assetList);



}




const handleReturnAttributeAdd = (index, attributeIndex, ingredientsList, setIngredientsList, name, type) => {
      const list = [...ingredientsList];
      list[index].returned_attributes = [...list[index].returned_attributes, {"name":name, "type":type, "value": ''}];

      setIngredientsList(list);
      

}




const handleReturnAttributeChange = (e, index, attributeIndex, ingredientsList, setIngredientsList, attribute_name, type) => {
  const {name, value} = e.target;
  const list = [...ingredientsList];
  list[index].returned_attributes[list[index].returned_attributes.findIndex(attributeIndex => attributeIndex.name === name)] = {"name":name, "type":type, "value":value};
  setIngredientsList(list);

}






const handleIngredientTemplateChange = (e, index, ingredientsList, setIngredientsList) => {
    const {name, value} = e.target;
    const list = [...ingredientsList];
    list[index][name] = value;
    setIngredientsList(list);

}



// RESULTS

const handleFungibleResultAdd = (setResultsList, resultsList) => {

    setResultsList([...resultsList, { result_type: 'fungible', preminted_pool_id: [], collection_name: '', schema_name: '', template_id: '1', fungible_token_symbol: '8,WAX', fungible_token_contract: '', fungible_token_amount: '1', non_fungible_quantity: '1', nft_name: 'name', nft_image: 'image', mutable_attributes: []}])

}

const handlePremintedResultAdd = (setResultsList, resultsList) => {

  setResultsList([...resultsList, { result_type: 'preminted', preminted_pool_id: [], collection_name: '', schema_name: '', template_id: '1', fungible_token_symbol: '8,WAX', fungible_token_contract: '', fungible_token_amount: '1', non_fungible_quantity: '1', nft_name: 'name', nft_image: 'image', mutable_attributes: []}])

}

const handleMintOnDemandResultAdd = (setResultsList, resultsList) => {

  setResultsList([...resultsList, { result_type: 'mintondemand', preminted_pool_id: [], collection_name: '', schema_name: '', template_id: '1', fungible_token_symbol: '8,WAX', fungible_token_contract: '', fungible_token_amount: '1', non_fungible_quantity: '1', nft_name: 'name', nft_image: 'image', mutable_attributes: []}])

}

const handleResultRemove = (setResultsList, resultsList, index) => {
    const list = [...resultsList];
    list.splice(index, 1);
    setResultsList(list);

}


const handleResultTypeChange = (e, index, resultsList, setResultsList) => {
    const {name, value} = e.target;
    const list = [...resultsList];
    list[index][name] = value;
    setResultsList(list);
    //console.log(ingredientsList)

}

const handleResultSymbolChange = (e, index, resultsList, setResultsList) => {
    const {name, value} = e.target;
    const list = [...resultsList];
    list[index][name] = value.toUpperCase();
    setResultsList(list);
    //console.log(ingredientsList)

}

const handleResultContractChange = (e, index, resultsList, setResultsList) => {
    const {name, value} = e.target;
    const list = [...resultsList];
    list[index][name] = value.toLowerCase();
    setResultsList(list);
    //console.log(ingredientsList)

}


const handleResultFungibleQuantityChange = (e, index, resultsList, setResultsList) => {
    const {name, value} = e.target;
    const list = [...resultsList];
    list[index][name] = value;
    setResultsList(list);
    //console.log(ingredientsList)

}

const handleResultNonFungibleQuantityChange = (e, index, resultsList, setResultsList) => {
  const {name, value} = e.target;
  const list = [...resultsList];
  list[index][name] = value;
  setResultsList(list);
  //console.log(ingredientsList)

}



const handleResultCollectionChange = (e, index, resultsList, setResultsList) => {
    const {name, value} = e.target;
    const list = [...resultsList];
    list[index][name] = value.toLowerCase();
    setResultsList(list);
    //console.log(ingredientsList)

}

const handleResultSchemaChange = (e, index, resultsList, setResultsList) => {
    const {name, value} = e.target;
    const list = [...resultsList];
    list[index][name] = value.toLowerCase();
    setResultsList(list);
    //console.log(ingredientsList)

}


const handleResultTemplateChange = (e, index, resultsList, setResultsList) => {
    const {name, value} = e.target;
    const list = [...resultsList];
    list[index][name] = value;
    setResultsList(list);
    console.log(resultsList)


}



const loadResultAttributes = (index, resultsList, setResultsList) => {
    const list = [...resultsList];
    //console.log(ingredientsList)

  
        try {
        //get result from API
        axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
          table:"schemas",
          scope:list[index].collection_name,
          code:"atomicassets",
          limit:1,
          lower_bound:list[index].schema_name,
          upper_bound:list[index].schema_name,
          json:true
        }).then((resultResponse) => {
            //console.log("done")
            //console.log(resultResponse)
            list[index].schema_format = resultResponse.data.rows[0].format;
            setResultsList(list);
            //console.log(list)
      
        })
  
  
    }//end try timer
  
    catch(e) {

      console.log(e)
      
    }//end catch timer
  


}



const handleResultAttributeRemove = (index, attributeIndex, resultsList, setResultsList, name, type) => {

    // remove the asset
    const assetList = [...resultsList];
    assetList[index].mutable_attributes.splice(assetList[index].mutable_attributes.findIndex(attributeIndex => attributeIndex.name === name), 1);
    setResultsList(assetList);
    //console.log('handled removing it');

  
  
  }
  
  
  
  
  const handleResultAttributeAdd = (index, attributeIndex, resultsList, setResultsList, name, type) => {
        //console.log(index)
        const list = [...resultsList];
        list[index].mutable_attributes = [...list[index].mutable_attributes, {"name":name, "type":type, "value": ''}];

        setResultsList(list);
        
        //console.log('handled adding it');
  
  }


  const handleResultPoolIDAdd = (index, poolIndex, resultsList, setResultsList, allPoolsInUse, setAllPoolsInUse) => {
    //console.log(index)
    
    const list = [...resultsList];
    console.log(list)
    list[index].preminted_pool_id = [...list[index].preminted_pool_id, poolIndex];

    setResultsList(list);

    setAllPoolsInUse([...allPoolsInUse, poolIndex])


}

const handleResultPoolIDRemove = (index, poolIndex, resultsList, setResultsList, allPoolsInUse, setAllPoolsInUse) => {

  // remove the asset
  const assetList = [...resultsList];
  assetList[index].preminted_pool_id.splice(assetList[index].preminted_pool_id.indexOf(poolIndex), 1);
  setResultsList(assetList);

  const allPoolsList = [...allPoolsInUse];
  allPoolsList.splice(allPoolsList.indexOf(poolIndex), 1);
  setAllPoolsInUse(allPoolsList);


  //console.log('handled removing it');



}



  const handleResultAttributeChange = (e, index, attributeIndex, resultsList, setResultsList, attribute_name, type) => {
    const {name, value} = e.target;
    const list = [...resultsList];

    //console.log(list[index].required_attributes[Number(attributeIndex)])

    list[index].mutable_attributes[list[index].mutable_attributes.findIndex(attributeIndex => attributeIndex.name === name)] = {"name":name, "type":type, "value":value};
    //list[index].required_attributes[list[index].required_attributes.findIndex(attributeIndex => attributeIndex.name === name)] = {"name":name, "type":type, "value":value};
    setResultsList(list);
    //console.log(list)

}


// RECEIVERS

// RESULTS

const handleReceiverAdd = (setPaymentReceivers, paymentReceivers) => {

  setPaymentReceivers([...paymentReceivers, { payment_receiver: '', payment_percentage: ''}])

}

const handleReceiverRemove = (setPaymentReceivers, paymentReceivers, index) => {
  const list = [...paymentReceivers];
  list.splice(index, 1);
  setPaymentReceivers(list);
  //console.log(list)

}


const handleReceiverAddressChange = (e, index, paymentReceivers, setPaymentReceivers) => {
  const {name, value} = e.target;
  const list = [...paymentReceivers];
  list[index][name] = value;
  setPaymentReceivers(list);
  //console.log(list)


}

const handleReceiverPercentageChange = (e, index, paymentReceivers, setPaymentReceivers) => {
  const {name, value} = e.target;
  const list = [...paymentReceivers];
  list[index][name] = value;
  setPaymentReceivers(list);
  //console.log(list)


}



const CreateBlendV2 = () => {
  
  const {  decimals, setDecimals, farmName, setFarmName, rewardToken, setRewardToken, rewardContract, setRewardContract, 
   collection, setCollection, schema, setSchema, farmLogo, setFarmLogo, paymentMethod, setPaymentMethod, timestamp, 
   setTimestamp, setEpochTime, assetDisplay, setAssetDisplay, assetToBurn, setAssetToBurn, burnable, setBurnable, 
   burnableDisplay, setBurnableDisplay, stakingPeriod, setStakingSeconds, stakingSeconds, setStakingPeriod, quantity, 
   setQuantity, farmType, setFarmType, farmTypeDisplay, setFarmTypeDisplay, farmTypeValue, setFarmTypeValue, templates, 
   setTemplates, schemaDisplay, setSchemaDisplay, templateMessage, setTemplateMessage,                 
   rewardType,
   setRewardType,
   rewardTypeDisplay,
   setRewardTypeDisplay,
   rewardTypeValue,
   setRewardTypeValue,
   rewardAmount,
   setRewardAmount,
   enterModalText,
   setEnterModalText,
   loadingDisplay, 
   setLoadingDisplay, 
   enterButtonsDisplay,
   setEnterButtonsDisplay,
   enterModalDisplay, 
   setEnterModalDisplay,
   usernamesDisplay,
   setUsernamesDisplay,
   farmnameDisplay,
   setFarmnameDisplay,
   whitelistType,
   setWhitelistType,
   setStartTime,
   setEndTime,
   startTimestamp,
   setStartTimestamp,
   endTimestamp,
   setEndTimestamp,
   dropPrice,
   setDropPrice,
   dropSymbol,
   setDropSymbol,
   dropPrecision,
   setDropPrecision,
   templateID,
   setTemplateID,
   totalAvailable,
   setTotalAvailable,
   limitPerUser,
   setLimitPerUser,
   cooldownSeconds,
   setCooldownSeconds,
   minimumNftsStaked,
   setMinimumNftsStaked,
   usernames,
   setUsernames,
   dropDescription,
   setDropDescription,
   logoHash,
   setLogoHash,
   receivingAccount,
   setReceivingAccount,
   revenueOption,
   setRevenueOption,
   poolName,
   setPoolName,
   revenueDisplay,
   setRevenueDisplay,
   percentToPool,
   setPercentToPool,
   setIsWhitelistedForDrops,
   isWhitelistedForDrops,
   loading,
   setLoading,
   ingredientsList,
   setIngredientsList,
   resultsList,
   setResultsList,
   poolIDs,
   setPoolIDs,
   assetVector,
   setAssetVector, 
   stakeMultipleDisplay, 
   setStakeMultipleDisplay,
   blendTitle,
   setBlendTitle,
   paymentReceivers,
   setPaymentReceivers,
   allPoolsInUse,
   setAllPoolsInUse,
   loadingAttributes,
   setLoadingAttributes,
   currentAttributeIndex,
   setCurrentAttributeIndex,
   attributeLoadingError,
   setAttributeLoadingError,

  
  } = useStateContext();






  useEffect(() => {
    const abortCont2 = new AbortController();

    //clear any previous state data

    setBurnable([]);
    setBurnableDisplay('hidden');
    setDecimals('');
    setFarmName('');
    setRewardToken('');
    setRewardContract('');
    setFarmLogo('');
    setPaymentMethod('Payment Method');
    setStakingPeriod('');
    setCollection('');
    setSchema('');
    setQuantity('');
    setFarmType('Select Farm Type');
    setUsernamesDisplay('hidden');
    setFarmnameDisplay('hidden');
    setDropDescription('');
    setLogoHash('');
    setRevenueOption('no');
    setPoolName('');
    setRevenueDisplay('hidden');
    setPercentToPool('0');
    setLoading(true);
    setLoadingDisplay('hidden');
    setBlendTitle('');
    setLoadingAttributes(false);
    setCurrentAttributeIndex('');
    setAttributeLoadingError(false);


      }

    
    , []);




    useEffect(() => {
      setPoolIDs([]);
      setAllPoolsInUse([]);
      
      
      
      
       const waxUsername = localStorage.getItem('waxAccount');
       const walletProvider = localStorage.getItem('walletProvider');
        
       if(waxUsername){
         //console.log('You have an active session');
         '';
         //setProfileDisplay('flex');
       }else{
         //console.log('You are not logged in');
         return('');
       }
      
      
          
      
        axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
                  table:"premintpools",
                  scope:"waxdaomarket",
                  code:"waxdaomarket",
                  key_type: 'name',
                  index_position: 2,
                  limit:100,
                  lower_bound: waxUsername,
                  upper_bound: waxUsername,
                  json:true
                })
                  .then((response) => {          
                    setPoolIDs(response.data.rows);
                  })
                  .catch((error) => console.log(error));
      
    
        }
      
      , []);





    const getImage = (coverImage, loadingDisplay, setLoadingDisplay, loading, setLoading) => {


        setLoadingDisplay('');
        setLoading(true);
  
        }







  return (
    <div id="seo">
    <Helmet>
    <title>Create Super Blend - WaxDAO</title>
    <meta name="description" content="Create super blends on WaxDAO, using NFTs and fungible tokens." />
    <link rel="canonical" href="https://waxdao.io/create-blend" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    <MainContainer>


    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>







    
      <CreateDaoCont>







        <DaoTitle>
        Create Super Blend
        </DaoTitle>
        <CreateFarmMsg>
        You can use NFTs and/or fungible tokens as ingredients.<br/><br/> 
        Mutable attributes can be specified for blend results.<br/> 
        </CreateFarmMsg>

<br/><br/>
{/* 
<BlendMessage>
  To get whitelisted to use the super blender, just <BlendMessageLink href={`${currentWebsiteURL}/drops/247`}>grab a Wojak pack</BlendMessageLink>, unbox it.. and then you will see a "Whitelist Me" button under the Wojak NFT in <BlendMessageLink href={`${currentWebsiteURL}/wallet`}>your WaxDAO wallet</BlendMessageLink>
</BlendMessage>
 */}

{/* 
        <MissingInfoMsg>
        IMPORTANT!!<br/><br/>
        waxdaomarket needs to be a "Notify Account" on your collection if you want to receive your RAM back 
        when NFTs from mint-on-demand drops are burned.

<div className='text-center text-white'>

        <label htmlFor="collection" ><br /><br/>NFT Collection:<br /></label>
        <ProposalInput type="text" 
        id="collection" 
        maxLength={12}
        value={collection}
        onChange={(e) => {
          setCollection(e.target.value.toLowerCase());
        }}
        />

        <DaoButton onClick={() => {addNotify(collection, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}}>
          Add waxdaomarket Now
        </DaoButton>

        </div>

        </MissingInfoMsg>

       <br/><br/>
 */}
       <MissingInfoMsg>
        Note: You must be authorized on the collection for any mint-on-demand blend results.
        </MissingInfoMsg>
        <br/>



        <NewProposal>








<label htmlFor="templates"><br />Blend Title:<br /></label>

<ProposalInput
placeholder="e.g. My Super Blend"
type="text"
maxLength={30}
value={blendTitle}
id="template" 
onChange={(e) => {
setBlendTitle(e.target.value);
}}
/>

  
<label htmlFor="templates"><br />Blend Description:<br /></label>


<UserWhitelist
  placeholder="Tell people about your blend"
  value={dropDescription}
  type="text"
  rows={4}
  id="template" 
  onChange={(e) => {
  setDropDescription(e.target.value);
  }}
  />

  <br/>

  <label htmlFor="templates"><br />Cover Image:<br /></label>

  <ProposalInput
  type="text"
  maxLength={70}
  placeholder="IPFS hash only. e.g. Qmfkjs..."
  value={logoHash}
  id="template" 
  onChange={(e) => {
  setLogoHash(e.target.value);
  getImage(e.target.value, loadingDisplay, setLoadingDisplay, loading, setLoading);
  }}
  />




  {logoHash != null && logoHash != '' && logoHash.length > 10 && (
    <span className={loadingDisplay}>
      <span className={loading == false && 'hidden'}>
      <LoadSpinner>
      <LoadSpinnerRed  />
      <LoadSpinnerBlue  />
      <LoadSpinnerGreen />
      </LoadSpinner>

        <br/>Loading Image...<br/>
      </span>
      <span className={loading == true && 'hidden'}>
    <CreateDropIPFS src={`https://ipfs.io/ipfs/${logoHash}`} onLoad={() => {setLoading(false);}}  onError={() => {setLoading(false);}} />
    </span>
    </span>
  )}







    <label htmlFor="templates"><br />Total Available:<br /></label>

    <ProposalInput
    placeholder="Put 0 if unlimited"
    type="text"
    maxLength={20}
    value={totalAvailable}
    id="template" 
    onChange={(e) => {
    setTotalAvailable(e.target.value);
    }}
    />


<label htmlFor="templates"><br />Limit Per User:<br /></label>

<ProposalInput
placeholder="Put 0 if unlimited"
type="text"
maxLength={20}
value={limitPerUser}
id="template" 
onChange={(e) => {
setLimitPerUser(e.target.value);
}}
/>


<label htmlFor="templates"><br />Cooldown Timer (in seconds):<br /></label>

<ProposalInput
placeholder="Put 0 if no reset"
type="text"
maxLength={8}
value={cooldownSeconds}
id="template" 
onChange={(e) => {
setCooldownSeconds(e.target.value);
}}
/>


<label htmlFor="templates"><br />NFT Receiving Account:<br /></label>

<ProposalInput
placeholder="leave blank if burning all NFTs"
type="text"
maxLength={12}
value={receivingAccount}
id="template" 
onChange={(e) => {
setReceivingAccount(e.target.value.toLowerCase());
}}
/>




{/* START PAYMENT RECEIVERS */}

{paymentReceivers.length >= 1 && paymentReceivers.map((singleReceiver,receiverIndex) => (


<div key={receiverIndex} >

<br/>

<span>

<label htmlFor="payment_receiver" ><br />Wax Address: <br /></label>
      <ProposalInput
      name="payment_receiver"
      placeholder='e.g. mike.wam'
      maxLength={12} 
      value={paymentReceivers[receiverIndex].payment_receiver}
      onChange={(e) => {
        handleReceiverAddressChange(e, receiverIndex, paymentReceivers, setPaymentReceivers)
      }}
      />      

<label htmlFor="payment_percentage" ><br />Percentage: <br /></label>
      <ProposalInput
      name="payment_percentage"
      placeholder='e.g. 20.5'
      maxLength={10} 
      value={paymentReceivers[receiverIndex].payment_percentage}
      onChange={(e) => {
        handleReceiverPercentageChange(e, receiverIndex, paymentReceivers, setPaymentReceivers)
      }}
      />



</span>


{/* END OF FUNGIBLE FIELDS */}


<span>


</span>






{/* END OF NONFUNGIBLE FIELDS */}






<br/>


<RemoveButton 
onClick={() => {
    handleReceiverRemove(setPaymentReceivers, paymentReceivers, receiverIndex);
}}
>
&nbsp; - &nbsp;
</RemoveButton>





</div>

)


)}


{/* END PAYMENT RECEIVERS */}


{paymentReceivers.length < 5 && 
        <span>
            <br/>
        <AddIngredient 

            onClick={() => {
                handleReceiverAdd(setPaymentReceivers, paymentReceivers);
            }}

            
            >
            Add Token Receiver
        </AddIngredient>
        <br/><br/>
        </span>

}




<form>
        <DropDown         
          onChange={(e) => {if(e.target.value == 'none'){setUsernamesDisplay('hidden'); setFarmnameDisplay('hidden');} else if(e.target.value == 'farm'){setUsernamesDisplay('hidden'); setFarmnameDisplay('');} else if(e.target.value == 'usernames'){setUsernamesDisplay(''); setFarmnameDisplay('hidden');}    setWhitelistType(e.target.value)}}
          >
        <option value="" hidden>
          Whitelist Type
        </option>
        <option value="none">Public Drop</option>
        <option value="farm">WaxDAO NFT Farm</option>
        <option value="usernames">List Of Usernames</option>
        </DropDown>
        </form>

<span className={usernamesDisplay}>
        <label htmlFor="templates"><br />Users To Whitelist:<br /></label>

        <TooltipComponent content="Separate each with a comma"
      position="BottomCenter">
      <UserWhitelist
        placeholder="john,bob,alice"
        value={usernames}
        type="text"
        rows={4}
        id="template" 
        onChange={(e) => {
        setUsernames(e.target.value.replace(/ /g,''));
        }}
        />
        </TooltipComponent>
</span>

<span className={farmnameDisplay}>
        <label htmlFor="templates"><br />Farm Name:<br /></label>

        <ProposalInput
        type="text"
        maxLength={12}
        value={farmName}
        id="template" 
        onChange={(e) => {
        setFarmName(e.target.value.toLowerCase());
        }}
        />

        <label htmlFor="templates"><br />Minimum NFTs Staked:<br /></label>

        <ProposalInput
        placeholder="How many NFTs to get whitelisted?"
        type="text"
        maxLength={12}
        value={minimumNftsStaked}
        id="template" 
        onChange={(e) => {
        setMinimumNftsStaked(e.target.value);
        }}
        />

</span>




      <label htmlFor="stakingPeriod" ><br />Start Time:<br /></label>
        <div className='daterangepicker-control-section'>
      <DateTimePickerComponent
        id="datepicker"
         cssClass='customClass'
         
         onChange={(e) => {
             setStartTime(e)
        }}
         
         >
      </DateTimePickerComponent>
      </div>


      <label htmlFor="stakingPeriod" ><br />End Time:<br /></label>
        <div className='daterangepicker-control-section'>
      <DateTimePickerComponent
        id="datepicker"
         cssClass='customClass'
         
         onChange={(e) => {
             setEndTime(e)
        }}
         
         >
      </DateTimePickerComponent>
      </div>


<NewProposal>

<DaoTitle>
    Manage Ingredients
</DaoTitle>




<br />


<span >

{(ingredientsList.length == 0 || ingredientsList == null) && 
        <span>
            <br/>
        <AddIngredient 

            onClick={() => {
                handleFungibleIngredientAdd(setIngredientsList, ingredientsList);
            }}

            
            >
            Add Fungible Ingredient
        </AddIngredient>

        <AddIngredient 

        onClick={() => {
            handleNonFungibleIngredientAdd(setIngredientsList, ingredientsList);
        }}


        >
        Add Non-Fungible Ingredient
        </AddIngredient>
        </span>

}


{ingredientsList.map((singleIngredient,index) => (


<div key={index} className="choices">

<p className="font-semibold mt-4">
    Ingredient {index + 1} ({singleIngredient.ingredient_type != null && singleIngredient.ingredient_type})
</p>






{singleIngredient.ingredient_type != null && singleIngredient.ingredient_type == "fungible" && (
<span>


<label htmlFor="fungible_token_symbol" ><br />Token Symbol: <br /></label>
      <ProposalInput
      name="fungible_token_symbol"
      placeholder='e.g. 8,WAX'
      maxLength={10} 
      value={ingredientsList[index].fungible_token_symbol}
      onChange={(e) => {
        handleIngredientSymbolChange(e, index, ingredientsList, setIngredientsList)
      }}
      />

<label htmlFor="fungible_token_contract" ><br />Token Contract: <br /></label>
      <ProposalInput
      name="fungible_token_contract"
      placeholder='e.g. eosio.token'
      maxLength={12} 
      value={ingredientsList[index].fungible_token_contract}
      onChange={(e) => {
        handleIngredientContractChange(e, index, ingredientsList, setIngredientsList)
      }}
      />      

<label htmlFor="fungible_token_amount" ><br />Token Quantity: <br /></label>
      <ProposalInput
      name="fungible_token_amount"
      placeholder='e.g. 1.5'
      maxLength={10} 
      value={ingredientsList[index].fungible_token_amount}
      onChange={(e) => {
        handleIngredientFungibleQuantityChange(e, index, ingredientsList, setIngredientsList)
      }}
      />



</span>
)}

{/* END OF FUNGIBLE FIELDS */}





{singleIngredient.ingredient_type != null && singleIngredient.ingredient_type == "nonfungible" && (
<span>


<form>
        <DropDown value={singleIngredient.filter_by != null && singleIngredient.filter_by}
        name="filter_by"         
            onChange={(e) => {
                handleIngredientFilterChange(e, index, ingredientsList, setIngredientsList )
            }}>
        <option value="" hidden>
          Ingredient Criteria
        </option>
        <option value="collection">Collection</option>
        <option value="schema">Schema</option>
        <option value="template">Template</option>
        <option value="attributes">Attributes</option>
        </DropDown>
        </form>
<br/>

<form>
        <DropDown value={singleIngredient.burn_non_fungible != null && singleIngredient.burn_non_fungible}
        name="burn_non_fungible"         
            onChange={(e) => {
                handleIngredientBurnNonFungibleChange(e, index, ingredientsList, setIngredientsList )
            }}>
        <option value="" hidden>
          Burn Ingredient?
        </option>
        <option value={true}>Burn NFT: Yes</option>
        <option value={false}>Burn NFT: No</option>
        </DropDown>
        </form>
<br/>


<span className={singleIngredient.burn_non_fungible != null && singleIngredient.burn_non_fungible == true && "hidden"}>
        <form>
        <DropDown value={singleIngredient.return_to_user != null && singleIngredient.return_to_user}
        name="return_to_user"         
            onChange={(e) => {
                handleIngredientReturnToUserChange(e, index, ingredientsList, setIngredientsList )
            }}>
        <option value="" hidden>
          Return NFT To User?
        </option>
        <option value={true}>Return To User: Yes</option>
        <option value={false}>Return To User: No</option>
        </DropDown>
        </form>
<br/>
</span>



<label htmlFor="non_fungible_quantity"><br />Quantity Required:<br /></label>

<ProposalInput
type="text"
name="non_fungible_quantity"
maxLength={3}
value={ingredientsList[index].non_fungible_quantity}
id="non_fungible_quantity" 
onChange={(e) => {
  handleIngredientNonFungibleQuantityChange(e, index, ingredientsList, setIngredientsList);
}}
/>



{singleIngredient.filter_by != null && (singleIngredient.filter_by == "collection" || singleIngredient.filter_by == "schema" || singleIngredient.filter_by == "template" || singleIngredient.filter_by == "attributes") && (
<span>

<label htmlFor="collection_name" ><br />Collection: <br /></label>
<ProposalInput
name="collection_name"
placeholder='e.g. hoodpunknfts'
maxLength={12} 
value={ingredientsList[index].collection_name}
onChange={(e) => {
handleIngredientCollectionChange(e, index, ingredientsList, setIngredientsList)
}}
/>




</span>
)}


{singleIngredient.filter_by != null && (singleIngredient.filter_by == "schema" || singleIngredient.filter_by == "template" || singleIngredient.filter_by == "attributes") && (
<span>

<label htmlFor="schema_name" ><br />Schema: <br /></label>
<ProposalInput
name="schema_name"
placeholder='e.g. stickers'
maxLength={12} 
value={ingredientsList[index].schema_name}
onChange={(e) => {
handleIngredientSchemaChange(e, index, ingredientsList, setIngredientsList, schemaTimer)
}}
/>




</span>
)}


{singleIngredient.filter_by != null && (singleIngredient.filter_by == "template") && (
<span>

<label htmlFor="template_id" ><br />Template ID: <br /></label>
<ProposalInput
name="template_id"
placeholder='e.g. 123456'
maxLength={8} 
value={ingredientsList[index].template_id}
onChange={(e) => {
handleIngredientTemplateChange(e, index, ingredientsList, setIngredientsList)
}}
/>




</span>
)}


{ ( ( singleIngredient.filter_by != null && singleIngredient.filter_by == "attributes" ) || ( singleIngredient.return_to_user != null && singleIngredient.return_to_user == true && singleIngredient.burn_non_fungible != null && singleIngredient.burn_non_fungible == false ) ) && (
<span>

<br/>

{(singleIngredient.collection_name == null || singleIngredient.collection_name.length < 1 || singleIngredient.schema_name == null || singleIngredient.schema_name.length < 1) ? (
   <span>
   Enter collection and schema name first...<br/>
 <DisabledButton>
    Load Attributes
  </DisabledButton>
  </span>
) : (

<DaoButton onClick={() => {loadAttributes(index, ingredientsList, setIngredientsList, setLoadingAttributes, setCurrentAttributeIndex, setAttributeLoadingError)}}>
    Load Attributes
</DaoButton>
)}


<br/>
<span className={(loadingAttributes == false || currentAttributeIndex != index ) && 'hidden'}>
      <LoadSpinner>
      <LoadSpinnerRed  />
      <LoadSpinnerBlue  />
      <LoadSpinnerGreen />
      </LoadSpinner>

        <br/>Loading Attributes...<br/>
</span>

<span className={(attributeLoadingError == false || currentAttributeIndex != index ) && 'hidden'}>
        <br/>There was an error loading the attributes. Make sure you have the correct collection name and schema entered above.<br/>
</span>


{singleIngredient.schema_format != null && (singleIngredient.schema_format.length >= 1) && singleIngredient.filter_by == "attributes" && (
<span>
<br/>
Choose Required Attributes:

<NFTsCont>
{singleIngredient.schema_format.map((singleAttribute,attributeIndex) => (

<Attribute key={attributeIndex} 
    style={singleIngredient.required_attributes != null && singleIngredient.required_attributes.findIndex(attributeIndex => attributeIndex.name === singleAttribute.name) > -1 ? {border:'1px solid #fa872d'} :  {border:'1px solid #696969'}}
    onClick={() => {

        if(singleIngredient.required_attributes != null && singleIngredient.required_attributes.findIndex(attributeIndex => attributeIndex.name === singleAttribute.name) > -1){
            handleIngredientAttributeRemove(index, attributeIndex, ingredientsList, setIngredientsList, singleAttribute.name, singleAttribute.type);
        } else {
            handleIngredientAttributeAdd(index, attributeIndex, ingredientsList, setIngredientsList, singleAttribute.name, singleAttribute.type);
        }
    }}
    >
{singleAttribute.name != null && singleAttribute.name}
</Attribute>


))}
</NFTsCont>

{singleIngredient.schema_format.map((singleAttribute,attributeIndex) => (
<span>

<span className={singleIngredient.required_attributes != null && singleIngredient.required_attributes.findIndex(attributeIndex => attributeIndex.name === singleAttribute.name) == -1  && "hidden"}>

<label htmlFor={singleAttribute.name != null && singleAttribute.name} ><br />{singleAttribute.name != null && singleAttribute.name}: <br /></label>
<ProposalInput
name={singleAttribute.name != null && singleAttribute.name}
maxLength={50} 
//value={index.template_id}
onChange={(e) => {
handleIngredientAttributeChange(e, index, attributeIndex, ingredientsList, setIngredientsList, singleAttribute.name, singleAttribute.type)
}}
/>
<br/>
</span>

</span>

))}




</span>
)}







{singleIngredient.schema_format != null && (singleIngredient.schema_format.length >= 1) && singleIngredient.filter_by != null && singleIngredient.filter_by != "collection" && (
<span>
<br/><br/>
Choose Attributes To Modify:<br/>
**NOTE: THESE ATTRIBUTES MUST NOT BE IMMUTABLE**

<NFTsCont>
{singleIngredient.schema_format.map((singleAttribute,attributeIndex) => (

<Attribute key={attributeIndex} 
    style={singleIngredient.returned_attributes != null && singleIngredient.returned_attributes.findIndex(attributeIndex => attributeIndex.name === singleAttribute.name) > -1 ? {border:'1px solid #fa872d'} :  {border:'1px solid #696969'}}
    onClick={() => {

        if(singleIngredient.returned_attributes != null && singleIngredient.returned_attributes.findIndex(attributeIndex => attributeIndex.name === singleAttribute.name) > -1){
            handleReturnAttributeRemove(index, attributeIndex, ingredientsList, setIngredientsList, singleAttribute.name, singleAttribute.type);
        } else {
            handleReturnAttributeAdd(index, attributeIndex, ingredientsList, setIngredientsList, singleAttribute.name, singleAttribute.type);
        }
    }}
    >
{singleAttribute.name != null && singleAttribute.name}
</Attribute>


))}
</NFTsCont>

{singleIngredient.schema_format.map((singleAttribute,attributeIndex) => (
<span>

<span className={singleIngredient.returned_attributes != null && singleIngredient.returned_attributes.findIndex(attributeIndex => attributeIndex.name === singleAttribute.name) == -1  && "hidden"}>

<label htmlFor={singleAttribute.name != null && singleAttribute.name} ><br />{singleAttribute.name != null && singleAttribute.name}: <br /></label>
<ProposalInput
name={singleAttribute.name != null && singleAttribute.name}
maxLength={50} 
//value={index.template_id}
onChange={(e) => {
handleReturnAttributeChange(e, index, attributeIndex, ingredientsList, setIngredientsList, singleAttribute.name, singleAttribute.type)
}}
/>
<br/>
</span>

</span>

))}




</span>
)}







</span>
)}





</span>
)}

{/* END OF NONFUNGIBLE FIELDS */}






<br/>

{ingredientsList.length > 0 && 


<RemoveButton 
onClick={() => {
    handleIngredientRemove(setIngredientsList, ingredientsList, index);
}}
>
&nbsp; - &nbsp;
</RemoveButton>


}




{(ingredientsList.length - 1 === index || ingredientsList.length == 0 || ingredientsList == null) && ingredientsList.length < 10 && 
        <span>
            <br/>
        <AddIngredient 

            onClick={() => {
                handleFungibleIngredientAdd(setIngredientsList, ingredientsList);
            }}

            
            >
            Add Fungible Ingredient
        </AddIngredient>

        <AddIngredient 

        onClick={() => {
            handleNonFungibleIngredientAdd(setIngredientsList, ingredientsList);
        }}


        >
        Add Non-Fungible Ingredient
        </AddIngredient>
        </span>

}




</div>

)


)}

</span>





<br/><br/>

<DaoTitle>
    Manage Results
</DaoTitle>




<br />


<span >

{(resultsList.length == 0 || resultsList == null) && 
        <span>
            <br/>
        <AddIngredient 

            onClick={() => {
                handleFungibleResultAdd(setResultsList, resultsList);
            }}

            
            >
            Add Fungible Result
        </AddIngredient>

        <AddIngredient 

        onClick={() => {
          handlePremintedResultAdd(setResultsList, resultsList);
        }}


        >
        Add Pre-Minted Result
        </AddIngredient>

        <AddIngredient 

        onClick={() => {
          handleMintOnDemandResultAdd(setResultsList, resultsList);
        }}


        >
        Add Mint-On-Demand Result
        </AddIngredient>
        </span>

}


{resultsList.map((singleResult,index) => (


<div key={index} className="choices">

<p className="font-semibold mt-4">
    Blend Result {index + 1} ({singleResult.result_type != null && singleResult.result_type})
</p>

<br/>






{singleResult.result_type != null && singleResult.result_type == "fungible" && (
<span>


<label htmlFor="fungible_token_symbol" ><br />Token Symbol: <br /></label>
      <ProposalInput
      name="fungible_token_symbol"
      placeholder='e.g. 8,WAX'
      maxLength={10} 
      value={resultsList[index].fungible_token_symbol}
      onChange={(e) => {
        handleResultSymbolChange(e, index, resultsList, setResultsList)
      }}
      />

<label htmlFor="fungible_token_contract" ><br />Token Contract: <br /></label>
      <ProposalInput
      name="fungible_token_contract"
      placeholder='e.g. eosio.token'
      maxLength={12} 
      value={resultsList[index].fungible_token_contract}
      onChange={(e) => {
        handleResultContractChange(e, index, resultsList, setResultsList)
      }}
      />      

<label htmlFor="fungible_token_amount" ><br />Token Quantity: <br /></label>
      <ProposalInput
      name="fungible_token_amount"
      placeholder='e.g. 1.5'
      maxLength={10} 
      value={resultsList[index].fungible_token_amount}
      onChange={(e) => {
        handleResultFungibleQuantityChange(e, index, resultsList, setResultsList)
      }}
      />



</span>
)}

{/* END OF FUNGIBLE FIELDS */}



{singleResult.result_type != null && singleResult.result_type == "preminted" && (
<span>
{ poolIDs.length > 0 ? poolIDs.map((poolItem, poolIndex) => (


//render all this if the farm type is template and the template matches
<span key={poolIndex} className={poolItem.status != "available" && 'hidden'}>
<NFT 
style={singleResult.preminted_pool_id != null && singleResult.preminted_pool_id.indexOf(poolItem.ID) > -1 ? {border:'1px solid #fa872d'} :  {border:'1px solid #696969'}}
>

      
<br/><span className="font-semibold">{poolItem.display_name != null ? poolItem.display_name : ''}</span> 

<br/><br/>


<StakeManyButton
onClick={() => {

    if(singleResult.preminted_pool_id != null && singleResult.preminted_pool_id.indexOf(poolItem.ID) > -1){
      handleResultPoolIDRemove(index, poolItem.ID, resultsList, setResultsList, allPoolsInUse, setAllPoolsInUse)
    } else if(singleResult.preminted_pool_id != null && singleResult.preminted_pool_id.indexOf(poolItem.ID) == -1
      && allPoolsInUse.indexOf(poolItem.ID) == -1 ){
      handleResultPoolIDAdd(index, poolItem.ID, resultsList, setResultsList, allPoolsInUse, setAllPoolsInUse)
    } else{}

  }}
  
  style={singleResult.preminted_pool_id != null && singleResult.preminted_pool_id.indexOf(poolItem.ID) > -1 ? {border:'1px solid #fa872d'} : {border:'1px solid #696969'} }
  className={singleResult.preminted_pool_id != null && singleResult.preminted_pool_id.indexOf(poolItem.ID) > -1 ? "text-orange" :  "text-gray hover:text-black" }
  >

{singleResult.preminted_pool_id != null && singleResult.preminted_pool_id.indexOf(poolItem.ID) == -1 && allPoolsInUse.indexOf(poolItem.ID) == -1 && "Add"}

{singleResult.preminted_pool_id != null && singleResult.preminted_pool_id.indexOf(poolItem.ID) > -1 && "Remove"}
{singleResult.preminted_pool_id != null && singleResult.preminted_pool_id.indexOf(poolItem.ID) == -1 && allPoolsInUse.indexOf(poolItem.ID) > -1 && "In Use"}
</StakeManyButton>

</NFT>
</span>






)) : (
<NoDaosMessage>
  You don't have any pools to include in your drop.
</NoDaosMessage>


)}

</span>
)}





{singleResult.result_type != null && singleResult.result_type == "mintondemand" && (
<span>


<label htmlFor="non_fungible_quantity" ><br />Quantity To Mint: <br /></label>
      <ProposalInput
      name="non_fungible_quantity"
      placeholder='e.g. 1'
      maxLength={2} 
      value={resultsList[index].non_fungible_quantity}
      onChange={(e) => {
        handleResultNonFungibleQuantityChange(e, index, resultsList, setResultsList)
      }}
      />


<label htmlFor="collection_name" ><br />Collection: <br /></label>
<ProposalInput
name="collection_name"
placeholder='e.g. hoodpunknfts'
maxLength={12} 
value={resultsList[index].collection_name}
onChange={(e) => {
handleResultCollectionChange(e, index, resultsList, setResultsList)
}}
/>




<label htmlFor="schema_name" ><br />Schema: <br /></label>
<ProposalInput
name="schema_name"
placeholder='e.g. stickers'
maxLength={12} 
value={resultsList[index].schema_name}
onChange={(e) => {
handleResultSchemaChange(e, index, resultsList, setResultsList)
}}
/>



<label htmlFor="template_id" ><br />Template ID: <br /></label>
<ProposalInput
name="template_id"
placeholder='e.g. 123456'
maxLength={8} 
value={resultsList[index].template_id}
onChange={(e) => {
handleResultTemplateChange(e, index, resultsList, setResultsList)
}}
/>




<span>

<br/>

Add Mutable Attributes?
<br/>

{(singleResult.collection_name == null || singleResult.collection_name.length < 1 || singleResult.schema_name == null || singleResult.schema_name.length < 1 || singleResult.template_id.length < 4) ? (
   <span>
   Enter collection, schema and template ID first...<br/>
 <DisabledButton>
    Load Attributes
  </DisabledButton>
  </span>
) : (

<DaoButton onClick={() => {loadResultAttributes(index, resultsList, setResultsList)}}>
    Load Attributes
</DaoButton>
)}



<br/>

{singleResult.schema_format != null && (singleResult.schema_format.length >= 1) && (
<span>



<NFTsCont>
{singleResult.schema_format.map((singleAttribute,attributeIndex) => (

<Attribute key={attributeIndex} 
    style={singleResult.mutable_attributes != null && singleResult.mutable_attributes.findIndex(attributeIndex => attributeIndex.name === singleAttribute.name) > -1 ? {border:'1px solid #fa872d'} :  {border:'1px solid #696969'}}
    onClick={() => {

        if(singleResult.mutable_attributes != null && singleResult.mutable_attributes.findIndex(attributeIndex => attributeIndex.name === singleAttribute.name) > -1){
            handleResultAttributeRemove(index, attributeIndex, resultsList, setResultsList, singleAttribute.name, singleAttribute.type);
        } else {
            handleResultAttributeAdd(index, attributeIndex, resultsList, setResultsList, singleAttribute.name, singleAttribute.type);
        }
    }}
    >
{singleAttribute.name != null && singleAttribute.name}
</Attribute>


))}
</NFTsCont>

{singleResult.schema_format.map((singleAttribute,attributeIndex) => (
<span>

<span className={singleResult.mutable_attributes != null && singleResult.mutable_attributes.findIndex(attributeIndex => attributeIndex.name === singleAttribute.name) == -1  && "hidden"}>

<label htmlFor={singleAttribute.name != null && singleAttribute.name} ><br />{singleAttribute.name != null && singleAttribute.name}: <br /></label>
<ProposalInput
name={singleAttribute.name != null && singleAttribute.name}
maxLength={50} 
//value={index.template_id}
onChange={(e) => {
handleResultAttributeChange(e, index, attributeIndex, resultsList, setResultsList, singleAttribute.name, singleAttribute.type)
}}
/>
<br/>
</span>

</span>

))}




</span>
)}







</span>






</span>
)}

{/* END OF NONFUNGIBLE FIELDS */}






<br/>


{resultsList.length > 0 && 


<RemoveButton 
onClick={() => {
    handleResultRemove(setResultsList, resultsList, index);
}}
>
&nbsp; - &nbsp;
</RemoveButton>


}







{resultsList.length - 1 === index && resultsList.length < 10 && 
        <span>
            <br/>
        <AddIngredient 

            onClick={() => {
                handleFungibleResultAdd(setResultsList, resultsList);
            }}

            
            >
            Add Fungible Result
        </AddIngredient>

        <AddIngredient 

        onClick={() => {
          handlePremintedResultAdd(setResultsList, resultsList);
        }}


        >
        Add Pre-Minted Result
        </AddIngredient>

        <AddIngredient 

        onClick={() => {
          handleMintOnDemandResultAdd(setResultsList, resultsList);
        }}


        >
        Add Mint-On-Demand Result
        </AddIngredient>
        </span>

}




</div>

)


)}

</span>





<br/>
<br/>
        <SubmitButton 
        onClick={() => {
            createBlend(blendTitle, dropDescription, logoHash, startTimestamp, endTimestamp, totalAvailable, whitelistType, farmName, minimumNftsStaked, usernames, ingredientsList, resultsList, paymentReceivers, receivingAccount, limitPerUser, cooldownSeconds, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay);
        }}        
        >
          Create Blend
        </SubmitButton>





</NewProposal>










<br/><br/>

    </NewProposal>
    
    </CreateDaoCont>
    
    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default CreateBlendV2