import NumberFormat from "react-number-format";
import {
  AboutDaoBody,
  AboutDaoTitle,
  ProposalAuthor,
  ProposalButton,
  ProposalButtonContainer,
  ProposalDescription,
  ProposalTitle,
  SingleProposal,
  SingleTemplate,
  TemplatesCont,
} from "../../components/LeftDiv";
import { currentWebsiteURL } from "../config";
import { PROPOSAL_STATUSES } from "../constants";
import { PoolLineBreak, SinglePoolDetail, SinglePoolDetailBold } from "../css/PoolPageStyles";
import { getPrecisionFromSymbol, getTokenNameFromSymbol } from "./pool_functions";
import { getWalletSession } from "./wallet_functions";
import { MainButton, MilestoneContainer, SmallButton } from "../../Styles";

const getMinimumVotes = (minVotes, symbol) => {
  let precision = Number(getPrecisionFromSymbol(symbol));

  if(precision == 0){
    return minVotes
  } else {
    //return minVotes.slice(0, -precision)
    return (minVotes / (10 ** precision) )
  }
  
}


export const createDAO = async (
  paymentMethod,
  twoAssetsToBurn,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let success_message =
    'Your DAO is created. You can customize it from the inventory page, under "Tools"';

  if (paymentMethod == "Pay 500 Wax") {
    try {
      const action = [
        {
          account: "eosio.token",
          name: "transfer",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            to: "buildawaxdao",
            from: this_user,
            quantity: "500.00000000 WAX",
            memo: "create_dao",
          },
        },
      ];
      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 600,
          broadcast: true,
        }
      );
      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(success_message);
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 2000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e);
    }
  } //end if paymentMethod == WAX

  if (paymentMethod == "Pay 50,000 WOJAK") {
    try {
      const action = [
        {
          account: "mdcryptonfts",
          name: "transfer",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            to: "buildawaxdao",
            from: this_user,
            quantity: "50000 WOJAK",
            memo: "create_dao",
          },
        },
      ];
      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 600,
          broadcast: true,
        }
      );
      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(success_message);
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 2000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e);
    }
  } //end if paymentMethod == WOJAK

  if (paymentMethod == "Burn 2 Wojak NFTs") {
    try {
      const action = [
        {
          account: "atomicassets",
          name: "transfer",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            asset_ids: twoAssetsToBurn,
            from: this_user,
            to: "buildawaxdao",
            memo: "create_dao",
          },
        },
      ];
      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 600,
          broadcast: true,
        }
      );
      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(success_message);
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 2000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e);
    }
  } //end if paymentMethod == burn NFT
};

export const filterDaosByLocked = (daoList) => {
  let i = 0;
  let filteredList = [];

  while (i < daoList.length) {
    if (daoList[i].status == "locked") {
      filteredList.push(daoList[i]);
    }
    i++;
  }
  return filteredList;
};

export const getGovernanceType = (dao) => {
  let govType;

  return govType;
};

export const getGovernanceAsset = (dao) => {
  if (dao.staketype == "token") {
    return getTokenNameFromSymbol(dao.tokensymbol);
  } else if (dao.staketype == "tokenpool") {
    return dao.poolname;
  }
};

export const printGovernanceDescription = (dao) => {
  if (dao.staketype == "token") {
    return (
      <span>
        Stake {getTokenNameFromSymbol(dao.tokensymbol)}@{dao.contract} for
        voting power
      </span>
    );
  } else if (dao.staketype == "tokenpool") {
    return (
      <span>
        Stake {getTokenNameFromSymbol(dao.tokensymbol)} in{" "}
        <a href={`${currentWebsiteURL}/pool/${dao.poolname}`}>{dao.poolname}</a>{" "}
        for voting power
      </span>
    );
  }
};

export const showDaoInfo = (daoData, treasuryAssets) => {

  return <span>     
                <SinglePoolDetail>
                  DAO Type{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {daoData.staketype}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>

                <SinglePoolDetail>
                Governance Asset(s)
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
  {(daoData.staketype == "token" || daoData.staketype == "tokenpool") && (
      <span>
          {daoData.tokensymbol}@{daoData.contract}
      </span>
  )}

  {(daoData.staketype == "nftfarm") && (
      <span>
          Stake NFTs in the <a href={`${currentWebsiteURL}/farm/${daoData.poolname}`}>{daoData.poolname} farm</a> for voting power
      </span>
  )}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>      


                <SinglePoolDetail>
                  Treasury Assets{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                {treasuryAssets.length > 0 && (
                  <MainButton>
                  {treasuryAssets.map((item, index) => (
                    <span>
                    {item.amount}
                    </span>
                  ))}
                  </MainButton>
                )}

              {treasuryAssets.length == 0 && 
                  'This DAO has no assets in its treasury'
                }
                  </SinglePoolDetailBold>
                </SinglePoolDetail>


                <SinglePoolDetail>
                Voting Period{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                  {daoData?.length != null && daoData?.length / 86400} Days
                  </SinglePoolDetailBold>
                </SinglePoolDetail>

                <SinglePoolDetail>
                Minimum Staking Period{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                  {daoData?.mintime / 86400} Days
                  </SinglePoolDetailBold>
                </SinglePoolDetail>   

                <SinglePoolDetail>
                Vote Threshold{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                  {Math.round(daoData?.threshold)}% required for a proposal to pass
                  </SinglePoolDetailBold>
                </SinglePoolDetail> 

                <SinglePoolDetail>
                Minimum Votes{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                {(daoData?.staketype == "token" || daoData?.staketype == "tokenpool") && (
                    <span>
                        Proposals with less than {getMinimumVotes(daoData?.minimumvotes, daoData.tokensymbol)} {getTokenNameFromSymbol(daoData?.tokensymbol)} voted will be considered invalid
                    </span>
                )}

                {daoData?.staketype == "nftfarm" && (
                    <span>
                        Proposals with less than <NumberFormat value={Math.round(daoData?.minimumvotes)} displayType="text" thousandSeparator={true}></NumberFormat> NFTs staked will be considered invalid
                    </span>
                )}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>                                                

</span> 
}

export const showProposals = (proposals) => {
  if (proposals.length > 0) {
    return proposals.map((item, index) => (
      <MilestoneContainer key={index}>
        <a href={`${currentWebsiteURL}/proposals/${item.ID}`}>
          <ProposalTitle>{item.title}</ProposalTitle>

          <ProposalAuthor>by {item.proposer}</ProposalAuthor>

          <ProposalDescription>{item.description}</ProposalDescription>

          <ProposalButtonContainer>
            <SmallButton>
              {
                PROPOSAL_STATUSES[
                  PROPOSAL_STATUSES.findIndex(
                    (attributeIndex) => attributeIndex.value === item.status
                  )
                ].display_text
              }
            </SmallButton>

            <SmallButton>{item.proposal_type}</SmallButton>

            <SmallButton>ID #{item.ID}</SmallButton>
          </ProposalButtonContainer>
        </a>
      </MilestoneContainer>
    ));
  } else {
    return "There are no proposals for this DAO";
  }
};

export const stakeTokens = async (
  DAOName,
  stakeAmount,
  token_contract,
  tokenName,
  decimals,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];  

  let amountToSend = Number(stakeAmount).toFixed(Number(decimals))

    try {

      const action = [
        {
          account: "buildawaxdao",
          name: "staketokens",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            user: this_user,
            dao_name: DAOName,
          },
        },
        {
          account: token_contract,
          name: "transfer",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            to: "buildawaxdao",
            from: this_user,
            quantity: amountToSend + " " + tokenName,
            memo: "|stake_tokens|" + DAOName + "|",
          },
        },
      ];
  
      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 600,
          broadcast: true,
        }
      );

      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Staking your tokens...");
      const timer = setTimeout(() => {
        setEnterModalText("Your tokens have been staked.");
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 4000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e);
    }
    
};

export const submitProposal = async (
  DAOName,
  proposalTypeValue,
  choicesList,
  description,
  proposalTitle,
  proposalAmount,
  proposalSymbol,
  proposalDecimals,
  proposalContract,
  receivingAccount,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  const choiceArray = [];

  {
    Object.values(choicesList).map((value, index) => {
      console.log(value["choice"]);
      choiceArray.push(value["choice"]);
    });
  }

  var tknsym;
  var tknqty;
  var tknctrct;

  if (proposalTypeValue != "tkn.transfer") {
    tknsym = "8,WAX";
    tknqty = "0";
    tknctrct = "";
  } else {
    tknsym = proposalDecimals + "," + proposalSymbol;
    tknqty = proposalAmount;
    tknctrct = proposalContract;
  }

  //return;

  if (proposalTypeValue != "standard" && proposalTypeValue != "tkn.transfer") {
    setLoadingDisplay("none");
    setEnterButtonsDisplay("");
    setEnterModalText("You need to select a proposal type");
    return;
  }

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

    try {

      const action = [
        {
          account: "buildawaxdao",
          name: "proposal",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            user: this_user,
            daoname: DAOName,
            proposal_type: proposalTypeValue,
            choices: choiceArray,
            receivers: [receivingAccount],
            asset_ids: [],
            quantity: tknqty,
            tokensymbol: tknsym,
            contract: tknctrct,
            description: description,
            title: proposalTitle,
          },
        },
      ];

      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 600,
          broadcast: true,
        }
      );

      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Creating your proposal...");
      const timer = setTimeout(() => {
        setEnterModalText("Your proposal has been created.");
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 4000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e);
    }
    
};

export const unstakeTokens = async (
  DAOName,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

    try {

      const action = [
        {
          account: "buildawaxdao",
          name: "unstaketoken",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            user: this_user,
            daoname: DAOName,
          },
        },
      ];

      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 600,
          broadcast: true,
        }
      );
      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Unstaking your tokens...");
      const timer = setTimeout(() => {
        setEnterModalText("Your tokens have been unstaked.");
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 2000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e);
    }
    
};

export const depositToTreasury = async (
  DAOName,
  treasuryDepositAmount,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

    try {

      const action = [
        {
          account: "eosio.token",
          name: "transfer",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            to: "buildawaxdao",
            from: this_user,
            quantity: Number(treasuryDepositAmount).toFixed(8) + " WAX",
            memo: "|treasury|" + DAOName + "|",
          },
        },
      ];

      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 600,
          broadcast: true,
        }
      );
      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Depositing your funds...");
      const timer = setTimeout(() => {
        setEnterModalText(
          "Your funds have been deposited to the treasury. They can only be retrieved with a DAO vote"
        );
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 2000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e);
    }

};
