import { useState } from "react";
import { currentApiList } from "../../data/config";
import axios from 'axios';

export const useGetFarmsByCreator = () => {
    
    const [farmsByCreator, setFarmsByCreator] = useState([]);
    const [creatorFarmsAreLoading, setCreatorFarmsAreLoading] = useState(true);

    const getFarmsByCreator = async (UserName, setShowFarms) => {
        setCreatorFarmsAreLoading(true);
        
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "farms",
                    scope: "waxdaofarmer",
                    code: "waxdaofarmer",
                    key_type: "name",
                    index_position: 3,
                    limit: 100,
                    lower_bound: UserName,
                    upper_bound: UserName,
                    json: true,
                });

                if (res.data.rows) {
                    setFarmsByCreator(res.data.rows);
                    if(res.data.rows.length === 0){
                        setShowFarms(false);
                    }
                    console.log(res.data.rows);
                    break; // Successful, so break out of the loop
                }
            } catch (error) {
                console.log(`Failed on API endpoint ${api}. Error: ${error}`);
            }
        }

        setCreatorFarmsAreLoading(false);
    }

    return [farmsByCreator, getFarmsByCreator, creatorFarmsAreLoading]
}
