import React, { useEffect } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import { AllFarmsCont, DaoButton, FarmEndDate, FarmLogo, FarmsPageCont, FarmsTitle, FarmTitle, PoolAmount, ProposerTypeMessage, SingleFarmCont } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { currentIpfsEndpoint, currentWebsiteURL, currentApiList, currentAtomicApiList, wax, anchor, myChain } from '../../data/config';




const MyPools = () => {

  const { poolList, setPoolList } = useStateContext();

useEffect(() => {
setPoolList([]);

 const waxUsername = localStorage.getItem('waxAccount');
 const walletProvider = localStorage.getItem('walletProvider');
  
 if(waxUsername){
   //console.log('You have an active session');
   '';
   //setProfileDisplay('flex');
 }else{
   //console.log('You are not logged in');
   return('');
 }
  
  
  axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
    table:"pools",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    index_position: 3,
    limit:50,
    lower_bound: waxUsername,
    upper_bound: waxUsername,
    json:true
  })
    .then((response) => {

      setPoolList([response.data.rows]);
    })
    .catch((error) => console.log(error));

  }

, []);

  return (
  <div id="seo">
    <Helmet>
    <title>My Staking Pools</title>
    <meta name="description" content="Track your Wax staking pools on Waxdao" />
    <link rel="canonical" href="https://waxdao.io/my-pools" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    <FarmsPageCont>
      <FarmsTitle>
        Manage Pools
        &nbsp;&nbsp;

      <a href={`${currentWebsiteURL}/create-pool`}>
      <DaoButton>
      + New Pool
      </DaoButton>
      </a>
      </FarmsTitle>

      <AllFarmsCont>


{poolList == null && (

<ProposerTypeMessage>
  You have not created any pools yet.<br/><br/>
  To create your own staking pool, go to the <a href={`${currentWebsiteURL}/create-pool`} style={{color:"#fa872d"}} >Create Pool</a> page.
</ProposerTypeMessage>
)}

{poolList != null && poolList[0] != null && poolList[0].length == 0 && (

  <ProposerTypeMessage>
    You have not created any pools yet.<br/><br/>
    To create your own staking pool, go to the <a href={`${currentWebsiteURL}/create-pool`} style={{color:"#fa872d"}} >Create Pool</a> page.
  </ProposerTypeMessage>
)}

{poolList[1] == null && poolList[0] != null && Array.isArray(poolList[0]) ? (poolList[0].map((item, index) => (
    <a href={`${currentWebsiteURL}/edit-pool/${item.poolname}`}>
    <SingleFarmCont>
      {item.logo != null && (
        <FarmLogo src={`${currentIpfsEndpoint}/${item.logo}`} />
      )}
      {item.poolname != null && (
        <FarmTitle>
        {item.poolname}
        </FarmTitle>
      )}

      {item.poolsize != null && (
        <PoolAmount>
          <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
         {item.poolsize.substring(item.poolsize.indexOf(' '))}
        </PoolAmount>
      )}

      {item.enddate != null && (
        <FarmEndDate>
          Ends {new Date(item.enddate * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

    </SingleFarmCont>
    </a>
    ))) : poolList[1] != null && Array.isArray(poolList) && poolList.map((item, index) => (
      <a href={`${currentWebsiteURL}/edit-pool/${item.poolname}`}>
      <SingleFarmCont>
      {item.logo != null && (
        <FarmLogo src={`${currentIpfsEndpoint}/${item.logo}`} />
      )}
      {item.poolname != null && (
        <FarmTitle>
        {item.poolname}
        </FarmTitle>
      )}
      {item.poolsize != null && (
        <PoolAmount>
          <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
         {item.poolsize.substring(item.poolsize.indexOf(' '))}
        </PoolAmount>
      )}
      {item.enddate != null && (
        <FarmEndDate>
          Ends {new Date(item.enddate * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

     </SingleFarmCont>
     </a>
      ))}






</AllFarmsCont>

      </FarmsPageCont>
    </UALProvider>
    </div>
  )
}

export default MyPools