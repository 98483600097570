import React from 'react'
import { NotFoundCont, WalletCallToActionHref } from './LeftDiv'
import { useToggle } from './CustomHooks/useToggle';
import { currentWebsiteURL } from '../data/config';

const Error = (props) => {
    const [isVisible, toggle] = useToggle();
  return (
    <NotFoundCont>
        {props.error != null && props.error}
        <br/>
        <WalletCallToActionHref href={currentWebsiteURL}>
            Take Me Home!
        </WalletCallToActionHref>



    </NotFoundCont>
  )
}

export default Error