import { useState } from "react";
import { currentApiList, v2DAOContract, v2FarmContract } from "../../data/config";
import axios from 'axios';

const TABLE_MAP = {
    0: "valuesbycol",
    1: "valuesbysch",
    2: "valuesbytemp",
    3: "valuesbyattr"
};

export const useGetV2FarmRequirements = () => {
    
    const [requirements, setRequirements] = useState([]);
    const [requirementsAreLoading, setRequirementsAreLoading] = useState(true);

    const getRequirements = async (farm_name, farm_type) => {
        setRequirementsAreLoading(true);
        
        let lower_bound = null;
        const table = TABLE_MAP[farm_type];
        
        for (const api of currentApiList) {
            try {
                let res;
                do {
                    res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                        table: table,
                        scope: farm_name,
                        code: v2FarmContract,
                        limit: 500,
                        lower_bound,
                        json: true,
                    });
                    
                    if (res.data.rows) {
                        console.log(res)
                        setRequirements(prevRequirements => [...prevRequirements, ...res.data.rows]);
                    }

                    lower_bound = res.data.next_key;
                } while (res.data.more)
                
                break;
                
            } catch (error) {
                console.log(error);
            }
        }

        setRequirementsAreLoading(false);
    }

    return [requirements, getRequirements, requirementsAreLoading]
}
