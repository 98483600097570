import styled from 'styled-components'
import { currentTheme, testTheme, theme } from '../../Styles'


export const DefaultHrefButton = styled.a`
    display: inline-block;
    width: auto;
    height: auto;
    border-radius: 10px;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 900;
    background-color: ${testTheme.secondary};
    text-align: center;
    vertical-align: middle;


    @media (max-width: 500px) {
        font-size: 14px;
    }

    :hover{
        background-color: ${testTheme.secondaryHover};
        transition: background-color 1s;
    }
`

export const DefaultButton = styled.button`
    display: inline-block;
    width: auto;
    height: auto;
    border-radius: 10px;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 900;
    background-color: ${props => props.a?.indexOf("Live drops only") > -1 ? testTheme.secondary : testTheme.lightBlue};
    text-align: center;
    vertical-align: middle;
    color: ${testTheme.textMain};

    :hover{
        background-color: ${testTheme.secondaryHover};
        transition: background-color 1s;
    }
`

export const DefaultButton2 = styled.button`
    display: inline-block;
    width: auto;
    height: auto;
    border-radius: 10px;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 900;
    background-color: ${props => props.a?.indexOf("Hide sold out") > -1 ? testTheme.secondary : testTheme.lightBlue};
    text-align: center;
    vertical-align: middle;
    color: ${testTheme.textMain};

    :hover{
        background-color: ${testTheme.secondaryHover};
        transition: background-color 1s;
    }
`

export const DropFiltersCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    width: 100%;
    max-width: 100%;
    margin-top: 0px;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    justify-content: left;
    padding: 1em;
    background-color: ${testTheme.mainLight};
    border: 1px solid ${testTheme.textSecondary};
    font-weight: 900;
    font-size: 14px;
    justify-items: center;
    align-items: center;
    height: auto;

`

export const DropCardWrapper = styled.div`
    display: block;
    position: relative;
    width: 220px;
    height: 400px;
    background-color: ${theme.mainDarker};
    border: 1px solid transparent;
    border-radius: 15px;

    :hover{
        background-color: #dfdcdc40;
        transition: background-color 0.5s;
    }


`

export const DropCardName = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 270px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${testTheme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const DropCardEndDate = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 310px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${testTheme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`
export const PremintPoolCardViewPool = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 330px;
    width:100%;
    //text-align: center;
    display: flex;
    justify-content: left;
    gap: 10px;
    padding-left: 10px;


    color: ${testTheme.textSecondary};

`


export const DropCardTopHalf = styled.div`
    display: flex;
    height: 220px;
    width: 100%;
    align-items: center;
`

export const DropCardImage = styled.img`

    width: auto;
    max-width: 180px;
    height: auto;
    max-height: 180px;
    border-radius: 5%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 900px) {
       // width: auto;
       // max-width: 100px;
       // padding: 10px;
       // height: 100px;
       // max-height: 100px;
        
    }
`

export const DropCardTitle = styled.div`
    z-index: 150;
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: ${theme.textSecondary};
    top: 220px;
    left: 50px;
    border: 1px solid ${theme.secondaryHover};
    width: 120px;
    height: 24px;
    text-align: center;
    border-radius: 15px;

    :hover{
        background-color: #dfdcdc40;
        transition: background-color 0.5s;
        color: ${theme.textMain};
        transition: color 0.5s;
    }
`

export const DropCardBottomHalf = styled.div`
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    background-color: ${theme.mainDarker};
    top: 236px;
    width: 100%;
    height: 162px;
    border-radius: 0px 0px 15px 15px;

`

export const DropPageCardBottomHalf = styled.div`
    position: absolute;
    font-size: 14px;
    font-weight: 400;
    background-color: ${theme.mainDarker};
    top: 236px;
    width: 100%;
    height: 262px;
    border-radius: 0px 0px 15px 15px;

`

export const DropCardPrice = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 400;
    top: 270px;
    width: 100%;
    text-align: center;
    color: white;
`

export const DropCardDate = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 400;
    top: 310px;
    width: 100%;
    text-align: center;
    color: white;
`


export const DropCardLiveStatus = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 400;
    top: 350px;
    width: 100%;
    text-align: center;
    color: ${props => props.status == "live" ? "#39c339" : "indianred"};
`

export const FarmCardPoolSize = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 350px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${theme.secondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const DropPageNFTCardWrapper = styled.div`
    display: block;
    position: relative;
    width: 500px;
    max-width: 90%;
    height: 500px;
    background-color: ${theme.mainDarker};
    border: 1px solid transparent;
    border-radius: 15px;

    :hover{
        background-color: #dfdcdc40;
        transition: background-color 0.5s;
    }


`

export const DropPageCollection = styled.div`
    z-index: 150;
    display: inline-block;
    //position: absolute;
    font-size: 14px;
    font-weight: 400;
    color: ${theme.textSecondary};
    //top: 220px;
    //left: 50px;
    border: 2px solid ${theme.secondaryHover};
    width: 120px;
    height: 24px;
    text-align: center;
    border-radius: 15px;

    :hover{
        background-color: #dfdcdc40;
        transition: background-color 0.5s;
        color: ${theme.textMain};
        transition: color 0.5s;
    }
`


export const DropPageCardName = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 16px;
    font-weight: 500;
    top: 240px;
    width:100%;
    text-align: center;
    color: ${testTheme.textMain};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const DropPageCardStartDate = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 280px;
    width:100%;
    text-align: center;
    color: ${testTheme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const DropPageCardEndDate = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 320px;
    width:100%;
    text-align: center;
    color: ${testTheme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const DropPageCardCooldown = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 360px;
    width:100%;
    text-align: center;
    color: ${testTheme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const DropPageCardLimitPerUser = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 400px;
    width:100%;
    text-align: center;
    color: ${testTheme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const DropPageCardMinimumMint = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 440px;
    width:100%;
    text-align: center;
    color: ${testTheme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`