import React from 'react'
import NumberFormat from 'react-number-format';
import { currentWebsiteURL } from '../data/config';
import { resizerPrefix, resizerSuffix } from '../data/constants';
import { DropCardBottomHalf, DropCardDate, DropCardEndDate, DropCardImage, DropCardLiveStatus, DropCardName, DropCardPrice, DropCardTitle, DropCardTopHalf, DropCardWrapper, FarmCardPoolSize } from '../data/css/DropStyles'
import { PoolCardAPR, PoolCardEndDate, PoolCardName, PoolCardPoolSize, PoolCardTokenToStake } from '../data/css/PoolPageStyles';
import { dropIsLive, dropIsSoldOut } from '../data/functions/DropFunctions';
import { DropImage, DropIsLive, DropType, FarmEndDate, FarmTitle, PoolAmount, SingleDropLogo, SoldOut } from './LeftDiv';

const PoolCard = (props) => {
    const item = props.item;
    const sort = props.sort;
  return (
    <DropCardWrapper>
        <DropCardTopHalf>
            {item.logo != null && (
                <DropCardImage src={`${resizerPrefix}${item.logo}${resizerSuffix}`} />
            )}
        </DropCardTopHalf>

        {item?.apy != null && item.apy > 0 && (
        <PoolCardAPR>
            {item.apy}% APR
        </PoolCardAPR>
        )}

      {item.creator != null && (
        <a href={`${currentWebsiteURL}/u/${item.creator}`}>
            <DropCardTitle>
            {item.creator}
            </DropCardTitle>
        </a>
      )}

        {item.poolname != null && (
            <PoolCardName>
                {item.poolname}
            </PoolCardName>
        )}

        {item.tokentostake != null && (
            <PoolCardTokenToStake>
                Stake {item.tokentostake.substring(item.tokentostake.indexOf(",") + 1)}
            </PoolCardTokenToStake>
        )}

        <DropCardBottomHalf />

        {item.enddate != null &&(
            <PoolCardEndDate>
                Ends {new Date(item.enddate * 1000).toLocaleDateString()}
            </PoolCardEndDate>
        )}


        {item.poolsize != null && sort != "popular" && (
        <PoolCardPoolSize>
            <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
            {item.poolsize.substring(item.poolsize.indexOf(' '))}
        </PoolCardPoolSize>
        )}

    </DropCardWrapper>
  )
}

export default PoolCard