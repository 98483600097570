import axios from 'axios';
import React, { useEffect } from 'react'
import NumberFormat from 'react-number-format';
import { currentWebsiteURL } from '../data/config';
import { resizerPrefix, resizerSuffix } from '../data/constants';
import { DropCardBottomHalf, DropCardDate, DropCardEndDate, DropCardImage, DropCardLiveStatus, DropCardName, DropCardPrice, DropCardTitle, DropCardTopHalf, DropCardWrapper, FarmCardPoolSize } from '../data/css/DropStyles'
import { FarmCardUnofficial } from '../data/css/FarmsListCSS';
import { WalletCardMint } from '../data/css/WalletStyles';
import { dropIsLive, dropIsSoldOut } from '../data/functions/DropFunctions';
import { farmIsExpired, isAnOfficialFarm } from '../data/functions/farm_functions';
import { useGetImage } from './CustomHooks/useGetImage';
import { DropImage, DropIsLive, DropType, FarmEndDate, FarmTitle, PoolAmount, SingleDropLogo, SoldOut } from './LeftDiv';

const V2FarmCard = (props) => {
    const item = props.item;
    const sort = props.sort;



  return (
    <DropCardWrapper>
        <DropCardTopHalf>
            {item.profile?.avatar != null && (
                 <DropCardImage 
                 src={`${resizerPrefix}${item.profile?.avatar}`} 
                 //onerror={fetch(`${resizerPrefix}${item.logo}`,{headers: {"Cache-Control":'max-age=84600, public'}}).then(r=>r.blob()).then(d=> this.src=URL.createObjectURL(d))}
                 //onError={({ currentTarget }) => {
                 //   currentTarget.onerror = null; // prevents looping
                 //   currentTarget.src=resizerPrefix + item.logo;
                 // }}                 
                 
                 />
                
            )}
        </DropCardTopHalf>


        <FarmCardUnofficial official={true}>
        OFFICIAL
        </FarmCardUnofficial>


      {item.creator != null && (
        <a href={`${currentWebsiteURL}/u/${item.creator}`}>
            <DropCardTitle>
            {item.creator}
            </DropCardTitle>
        </a>
      )}

        {item.farmname != null && (
            <DropCardName>
                {item.farmname}
            </DropCardName>
        )}

        <DropCardBottomHalf />

        {item.enddate != null &&(
            <DropCardEndDate>
                Ends {new Date(item.enddate * 1000).toLocaleDateString()}
            </DropCardEndDate>
        )}


        {item.poolsize != null && sort != "popular" && (
        <FarmCardPoolSize>
            <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
            {item.poolsize.substring(item.poolsize.indexOf(' '))}
        </FarmCardPoolSize>
        )}

        {sort != null && sort == "popular" && (
        <FarmCardPoolSize>
            <NumberFormat displayType='text' thousandSeparator={true} value={item.totalstaked} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
            &nbsp;NFTs
        </FarmCardPoolSize>
        )}



        {dropIsSoldOut(item) && (
            <DropCardLiveStatus status="sold out">
            SOLD OUT
            </DropCardLiveStatus>
        )}

        {dropIsLive(item) && (
            <DropCardLiveStatus status="live">
            LIVE!!!
            </DropCardLiveStatus>
        )}  


    </DropCardWrapper>
  )
}

export default V2FarmCard