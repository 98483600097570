import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { FarmsPageCont, FarmsTitle, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, SuggestionsCont, Suggestion, SuggestionDesc, SuggestionDeets, SuggestionUser, SuggestionBtn, MissingInfoMsg, FinalizeButton, VestingButton } from '../../components/LeftDiv';
import { myChain, wax, anchor, getCurrentApiList, wombat, currentUsername, currentWebsiteURL } from "../../data/config.js";
import { cancelDrip, claimDrip, finalizeDrip, handleEscrowFilter } from '../../data/functions/escrow_functions';
import { ExploreFiltersCont, ExploreFiltersScrollCont, FoldersContainer, FoldersRow, MainButton, NewListingBody, NewListingCont, SingleFolderThird, SkillsButton } from '../../Styles';
import { PayerDripCardWrapper } from '../../data/css/EscrowStyles';
import PayerDripCard from '../../components/PayerDripCard';
import ReceiverDripCard from '../../components/ReceiverDripCard';
import { ESCROW_STATUSES } from '../../data/constants';

const MyEscrow = () => {

  const { 
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
  } = useStateContext();

  const [payerDrips, setPayerDrips] = useState([]);
  const [receiverDrips, setReceiverDrips] = useState([]);
  const [isPayer, setIsPayer] = useState(true);
  const [escrowFilters, setEscrowFilters] = useState([]);

useEffect(() => {

    if(!currentUsername){return;}

    axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
      table:"drips",
      scope:"waxdaoescrow",
      code:"waxdaoescrow",
      limit:1000,
      key_type: 'name',
      index_position: 2,
      limit:1000,
      reverse: true,
      lower_bound:currentUsername,
      upper_bound:currentUsername,
      json:true
    }).then((res) => {
        setPayerDrips(res.data.rows);
    })
    .catch((error) => console.log(error));
  }, []);




  useEffect(() => {

    if(!currentUsername){return;}

    axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
      table:"drips",
      scope:"waxdaoescrow",
      code:"waxdaoescrow",
      limit:1000,
      key_type: 'name',
      index_position: 3,
      limit:1000,
      reverse: true,
      lower_bound:currentUsername,
      upper_bound:currentUsername,
      json:true
    }).then((res) => {
        setReceiverDrips(res.data.rows);
    })
    .catch((error) => console.log(error));
  }, []);




  return (
  <div id="seo">
    <Helmet>
    <title>Manage Escrow</title>
    <meta name="description" content="View and manage your escrow agreements. Finalize, cancel and claim." />
    <link rel="canonical" href="https://waxdao.io/manage-escrow" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>
    
    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>

<NewListingCont>
  <NewListingBody>
<ExploreFiltersCont>
    <h2>Manage Escrow</h2>
    <FoldersContainer>
                <FoldersRow>
                  <SingleFolderThird selected={isPayer && true}
                   onClick={() => setIsPayer(true)}
                  >
                  Payer
                  </SingleFolderThird>
                  <SingleFolderThird selected={!isPayer && true}
                  onClick={() => setIsPayer(false)}
                  >
                  Receiver
                  </SingleFolderThird>
                </FoldersRow>
              </FoldersContainer>



    </ExploreFiltersCont>

    <ExploreFiltersCont>
      <h2>Filters</h2>

    <ExploreFiltersScrollCont>
    {ESCROW_STATUSES?.length > 0 && ESCROW_STATUSES.map((item, index) => (
        <SkillsButton value={item.value} onClick={(e) => handleEscrowFilter(item.value, escrowFilters, setEscrowFilters) } selectedList={escrowFilters} thisSkill={item.value} >
            {item.display_text}
        </SkillsButton>
    ))}

    </ExploreFiltersScrollCont>
    </ExploreFiltersCont>


<span className={!isPayer && "hidden"}>

    {payerDrips != null && payerDrips.length > 0 ? payerDrips.map((item, index) => (
      <span className={escrowFilters.length > 0 && escrowFilters.indexOf(item.status) == -1 && "hidden"}>
      <PayerDripCard key={index} item={item} />
      </span>
    )) : (
      <ExploreFiltersCont>
        <div className='pl-4'>
      You do not have any open escrow deals. You can create one on the <a href={`${currentWebsiteURL}/escrow`}>main escrow page</a>.
      </div>
      </ExploreFiltersCont>
    )}

</span>

<span className={isPayer && "hidden"}>


    {receiverDrips != null && receiverDrips.length > 0 ? receiverDrips.map((item, index) => (
        <span className={escrowFilters.length > 0 && escrowFilters.indexOf(item.status) == -1 && "hidden"}>
        <ReceiverDripCard key={index} item={item} />
        </span>
    )) : (
      <ExploreFiltersCont>
        <div className='pl-4'>
        You are not the receiver of any escrow deals.
        </div>
      </ExploreFiltersCont>
    )}


</span>


</NewListingBody>
</NewListingCont>





    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default MyEscrow