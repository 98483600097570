import axios from "axios";
import { GameButton, NFT, NFTLogo } from "../../components/LeftDiv";
import { theme } from "../../Styles";
import {
  currentApiList,
  currentAtomicApiList,
  wax,
  anchor,
  myChain,
  getCurrentApiList,
  getCurrentAtomicApiList,
  currentWebsiteURL,
  stringyThis,
} from "../config";
import { resizerPrefix, resizerSuffix } from "../constants";
import { authorizeWaxDAO, getWalletSession } from "./wallet_functions";

export function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export const dropIsLive = (this_drop) => {
  var isLive = false;

  if (
    this_drop.end_time != null &&
    this_drop.start_time != null &&
    (this_drop.end_time == 0 || this_drop.end_time * 1000 > new Date()) &&
    (this_drop.start_time == 0 || this_drop.start_time * 1000 < new Date()) &&
    ((this_drop.total_left != null && this_drop.total_left != "0") ||
      (this_drop.total_available != null && this_drop.total_available == "0"))
  ) {
    isLive = true;
  }

  return isLive;
};

export const dropIsSoldOut = (this_drop) => {
  var isSoldOut = false;

  if (
    this_drop.total_left != null &&
    this_drop.total_left == "0" &&
    this_drop.total_available != null &&
    this_drop.total_available != "0"
  ) {
    isSoldOut = true;
  }

  return isSoldOut;
};

export const purchaseDrop = async (
  DropID,
  quantity,
  dropType,
  rewardContract,
  pricePerNFT,
  farmPrecision,
  tokenName,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  var uniqueID = getRndInteger(100000000, 200000000);

  var apiWorked = "no";

  var amountToSend = (pricePerNFT * quantity).toFixed(farmPrecision);

  try {
    const action = [
      {
        account: "waxdaomarket",
        name: "assertdrop",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          drop_ID: DropID,
          quantity_to_assert: quantity,
        },
      },

      {
        account: rewardContract,
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxdaomarket",
          from: this_user,
          quantity: amountToSend + " " + tokenName,
          memo: "|purchase_drop|" + DropID + "|" + uniqueID + "|",
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 180,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");

    if (dropType == "premint.pool") {
      setEnterModalText("Fetching preminted NFTs...");
      const timer = setTimeout(() => {
        try {
          axios
            .post(
              `${
                currentApiList[Math.floor(Math.random() * (2 - 0)) + 0]
              }/v1/chain/get_table_rows`,
              {
                table: "results",
                scope: "waxdaomarket",
                code: "waxdaomarket",
                limit: 1,
                lower_bound: uniqueID,
                upper_bound: uniqueID,
                json: true,
              }
            )
            .then((resultResponse) => {
              if (resultResponse.data.rows[0].outcome != "") {
                apiWorked = "yes";

                axios
                  .get(
                    `${
                      currentAtomicApiList[
                        Math.floor(Math.random() * (1 - 0)) + 0
                      ]
                    }/atomicassets/v1/assets?ids=${resultResponse.data.rows[0].outcome.join(
                      "%2C"
                    )}&page=1&limit=100&order=desc&sort=asset_id`
                  )
                  .then((validResponse) => {
                    //console.log(validResponse.data);
                    setEnterModalText(
                      <span className="text-center align-center justify-center">
                        Here are your new NFTs!
                        <br />
                        {validResponse != null &&
                        validResponse.data.data.length > 0 ? (
                          validResponse.data.data.map((item, index) => (
                            <NFT key={index}>
                              <p key={index}>
                                <span className="font-bold">
                                  {item.name != null ? item.name : ""}
                                </span>
                                <br />
                                Mint{" "}
                                {item.template_mint != null
                                  ? item.template_mint
                                  : ""}
                              </p>

                              {item.data.img != null && (
                                <NFTLogo
                                  src={`https://ipfs.io/ipfs/${item.data.img.trim()}`}
                                />
                              )}

                              {item.data.img == null &&
                                item.data.video != null && (
                                  <video
                                    style={{
                                      width: "150px",
                                      maxWidth: "150px",
                                      height: "150px",
                                      maxHeight: "150px",
                                      borderRadius: "25%",
                                    }}
                                    loop
                                  >
                                    <source
                                      src={`https://ipfs.io/ipfs/${item.data.video.trim()}`}
                                      type="video/mp4"
                                    />
                                  </video>
                                )}

                              {item.data.img == null &&
                                item.data.video == null &&
                                item.data.image != null && (
                                  <NFTLogo
                                    src={`https://ipfs.io/ipfs/${item.data.image.trim()}`}
                                  />
                                )}
                            </NFT>
                          ))
                        ) : (
                          <span></span>
                        )}
                      </span>
                    );
                  });
              } else {
                setEnterModalText(
                  "Could not retrieve result. Check your wallet to see what you got."
                );
              }
              setLoadingDisplay("none");
              setEnterButtonsDisplay("");
            });
        } catch (e) {
          //end try timer

          setEnterModalText("Could not retrieve result. Check your wallet.");
          console.log(e);
        } //end catch timer
      }, 4000);
      const timer2 = setTimeout(() => {
        if (apiWorked == "no") {
          setEnterModalText("Could not retrieve result. Check your wallet.");
          setEnterButtonsDisplay("");
          setLoadingDisplay("none");
        }
      }, 5000);

      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } //end if drop type is premint pool
    else {
      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("Your purchase was successful");
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 4000);
      return () => clearTimeout(timer);
    }
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    console.log(e);
  }
}; //end purchaseDrop

export const claimDrop = async (
  DropID,
  quantity,
  dropType,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  var uniqueID = getRndInteger(100000000, 200000000);

  var apiWorked = "no";

  try {
    const action = [
      {
        account: "waxdaomarket",
        name: "claimdrop",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          drop_ID: DropID,
          quantity_to_mint: quantity,
          unique_id: uniqueID,
        },
      },
    ];

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 180,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");

    if (dropType == "premint.pool") {
      setEnterModalText("Fetching preminted NFT...");
      const timer = setTimeout(() => {
        try {
          axios
            .post(
              `${
                currentApiList[Math.floor(Math.random() * (2 - 0)) + 0]
              }/v1/chain/get_table_rows`,
              {
                table: "results",
                scope: "waxdaomarket",
                code: "waxdaomarket",
                limit: 1,
                lower_bound: uniqueID,
                upper_bound: uniqueID,
                json: true,
              }
            )
            .then((resultResponse) => {
              if (resultResponse.data.rows[0].outcome != "") {
                apiWorked = "yes";

                axios
                  .get(
                    `${
                      currentAtomicApiList[
                        Math.floor(Math.random() * (1 - 0)) + 0
                      ]
                    }/atomicassets/v1/assets?ids=${
                      resultResponse.data.rows[0].outcome
                    }&page=1&limit=1&order=desc&sort=asset_id`
                  )
                  .then((validResponse) => {
                    setEnterModalText(
                      <span className="text-center align-center justify-center">
                        Here's your new NFT!
                        <br />
                        {validResponse != null && (
                          <span>
                            {validResponse.data.data[0].data.name}
                            <br />
                            <img
                              src={`https://ipfs.io/ipfs/${validResponse.data.data[0].data.img}`}
                              style={{
                                width: "150px",
                                maxWidth: "150px",
                                height: "auto",
                                marginLeft: "auto",
                                marginRight: "auto",
                                alignSelf: "center",
                              }}
                            />
                          </span>
                        )}
                      </span>
                    );
                  });
              } else {
                setEnterModalText(
                  "Could not retrieve result. Check your wallet to see what you got."
                );
              }
              setLoadingDisplay("none");
              setEnterButtonsDisplay("");
            });
        } catch (e) {
          setEnterModalText("Could not retrieve result. Check your wallet.");
          console.log(e);
        } //end catch timer
      }, 4000);
      const timer2 = setTimeout(() => {
        if (apiWorked == "no") {
          setEnterModalText("Could not retrieve result. Check your wallet.");
          setEnterButtonsDisplay("");
          setLoadingDisplay("none");
        }
      }, 5000);

      return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
      };
    } //end if drop type is premint pool
    else {
      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText("Your purchase was successful");
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 4000);
      return () => clearTimeout(timer);
    }
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    console.log(e);
  }
}; //end claimDrop

export const claimOIGDrop = async (
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    const action = [
      {
        account: "waxdaomarket",
        name: "claimoigdrop",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
        },
      },
    ];

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 180,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    const timer = setTimeout(() => {
      setEnterModalText(
        <span>Your NFT has been claimed! You can view it in your <a style={{color:theme.secondary}} href={`${currentWebsiteURL}/wallet`}>Inventory</a></span>
      );
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 2000);
    return () => clearTimeout(timer);


  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    console.log(e);
  }
}; //end claimOIGDrop





export const claimBohn = async (
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  let drop_id = 177592;

  try {
    const action = [

      {
        account: "neftyblocksd",
        name: "assertprice",
        authorization: [
          {
            actor: this_user,
            permission: "active",
          },
        ],
        data: {
          drop_id: drop_id,
          listing_price: "40.00000000 WAX",
          settlement_symbol: "8,WAX",
        },
      },
      {
        account: "eosio.token",
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: "active",
          },
        ],
        data: {
          from: this_user,
          memo: "deposit",
          quantity: "40.00000000 WAX",
          to: "neftyblocksd",
        },
      },
      {
        account: "neftyblocksd",
        name: "claimwproof",
        authorization: [
          {
            actor: this_user,
            permission: "active",
          },
        ],
        data: {
          asset_ids: ["1099876499298"],
          claimer: this_user,
          drop_id: drop_id,
          amount: "1",
          intended_delphi_median: "0",
          referrer: "NeftyBlocks",
          country: "US",
          currency: "8,WAX",
        },
      },
    ];

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    const timer = setTimeout(() => {
      setEnterModalText(
        <span>Your NFT has been claimed! You can view it in your <a style={{color:theme.secondary}} href={`${currentWebsiteURL}/wallet`}>Inventory</a></span>
      );
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 2000);
    return () => clearTimeout(timer);


  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    console.log(e);
  }
}; //end claimBohn




export const unbox = async (
  DropID,
  asset_id,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let uniqueID = getRndInteger(100000000, 200000000);

  let apiWorked = "no";

  try {
    const action = [
      {
        account: "atomicassets",
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxdaomarket",
          from: this_user,
          asset_ids: [asset_id],
          memo: "|unbox|" + DropID + "|" + uniqueID + "|",
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Fetching pack results...");
    const timer = setTimeout(() => {
      try {
        //get result from API
        axios
          .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
            table: "results",
            scope: "waxdaomarket",
            code: "waxdaomarket",
            limit: 1,
            lower_bound: uniqueID,
            upper_bound: uniqueID,
            json: true,
          })
          .then((resultResponse) => {
            if (resultResponse.data.rows[0].outcome != "") {
              apiWorked = "yes";

              axios
                .get(
                  `${getCurrentAtomicApiList}/atomicassets/v1/assets?ids=${resultResponse.data.rows[0].outcome.join(
                    "%2C"
                  )}&page=1&limit=100&order=desc&sort=asset_id`
                )
                .then((validResponse) => {
                  setEnterModalText(
                    <span className="text-center align-center justify-center">
                      Here are your new NFTs!
                      <br />
                      {validResponse != null &&
                      validResponse.data.data.length > 0 ? (
                        validResponse.data.data.map((item, index) => (
                          <NFT key={index}>
                            <p>
                              <span className="font-bold">
                                {item.name != null ? item.name : ""}
                              </span>
                              <br />
                              Mint{" "}
                              {item.template_mint != null
                                ? item.template_mint
                                : ""}
                            </p>

                            {item.data.img != null && (
                              <NFTLogo
                                src={`${resizerPrefix}${item.data.img.trim()}${resizerSuffix}`}
                              />
                            )}

                            {item.data.img == null &&
                              item.data.video != null && (
                                <video
                                  style={{
                                    width: "150px",
                                    maxWidth: "150px",
                                    height: "150px",
                                    maxHeight: "150px",
                                    borderRadius: "25%",
                                  }}
                                  loop
                                >
                                  <source
                                    src={`${resizerPrefix}${item.data.video.trim()}${resizerSuffix}`}
                                    type="video/mp4"
                                  />
                                </video>
                              )}

                            {item.data.img == null &&
                              item.data.video == null &&
                              item.data.image != null && (
                                <NFTLogo
                                  src={`${resizerPrefix}${item.data.image.trim()}${resizerSuffix}`}
                                />
                              )}
                          </NFT>
                        ))
                      ) : (
                        <span></span>
                      )}
                    </span>
                  );
                });
            } else {
              setEnterModalText(
                "Could not retrieve result. Check your wallet to see what you got."
              );
            }
            setLoadingDisplay("none");
            setEnterButtonsDisplay("");
          });
      } catch (e) {
        setEnterModalText("Could not retrieve result. Check your inventory.");
        console.log(e);
      } //end catch timer
    }, 4000);
    const timer2 = setTimeout(() => {
      if (apiWorked == "no") {
        setEnterModalText("Could not retrieve result. Check your wallet.");
        setEnterButtonsDisplay("");
        setLoadingDisplay("none");
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    console.log(e);
  }
}; //end unbox

export const createDrop = async (
  dropPrice,
  dropSymbol,
  dropPrecision,
  rewardContract,
  collection,
  schema,
  templateID,
  totalAvailable,
  limitPerUser,
  cooldownSeconds,
  whitelistType,
  usernames,
  farmName,
  minimumNftsStaked,
  startTimestamp,
  endTimestamp,
  dropDescription,
  logoHash,
  receivingAccount,
  revenueOption,
  poolName,
  percentToPool,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  let theMinimum;

  if (
    minimumNftsStaked == null ||
    minimumNftsStaked == "" ||
    minimumNftsStaked == undefined
  ) {
    theMinimum = "0";
  } else {
    theMinimum = minimumNftsStaked;
  }

  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  try {

    const action = [
      {
        account: "waxdaomarket",
        name: "createdrop",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          price: dropPrice,
          token_symbol: dropPrecision + "," + dropSymbol,
          contract: rewardContract,
          collection: collection,
          schema: schema,
          template_id: templateID,
          total_available: totalAvailable,
          limit_per_user: limitPerUser,
          cooldown: cooldownSeconds,
          whitelist_type: whitelistType,
          allowed_users: usernames.split(","),
          farmname: farmName,
          minimum_to_stake: theMinimum,
          start_time: startTimestamp,
          end_time: endTimestamp,
          drop_description: dropDescription,
          drop_logo: logoHash,
          receiver: receivingAccount,
          pool_or_farm: revenueOption,
          pool_or_farm_name: poolName,
          percent_to_pool: percentToPool,
          pool_id: [],
          drop_type: "standard",
          pack_template: "0",
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText(
        "Your drop has been created. Make sure your collection has RAM (Inventory -> RAM -> Collection RAM)"
      );
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 2000);
    return () => clearTimeout(timer);
  } catch (e) {
    if (
      e.message ==
      "assertion failure with message: You must add waxdaomarket as an authorized account on this collection"
    ) {
      setEnterModalText(
        <span>
          waxdaomarket is not authorized on this collection.
          <br />
          <br />
          <GameButton
            onClick={() => {
              authorizeWaxDAO(
                collection,
                setEnterModalText,
                setLoadingDisplay,
                setEnterModalDisplay
              );
            }}
          >
            Authorize Now
          </GameButton>
        </span>
      );
    } else {
      setEnterModalText(JSON.stringify(e.message));
    }

    console.log(e);
  }
}; //end createDrop