import React, { useEffect, useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { FarmsPageCont, FarmsTitle, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, SuggestionsCont, Suggestion, SuggestionDesc, SuggestionDeets, SuggestionUser, SuggestionBtn, MissingInfoMsg, FinalizeButton, VestingButton } from '../../components/LeftDiv';
import { myChain, wax, anchor, getCurrentApiList, wombat, currentUsername, currentWebsiteURL } from "../../data/config.js";
import { cancelDrip, claimDrip, finalizeDrip, handleEscrowFilter } from '../../data/functions/escrow_functions';
import { ExploreFiltersCont, ExploreFiltersScrollCont, FoldersContainer, FoldersRow, MainButton, NewListingBody, NewListingCont, SingleFolderThird, SkillsButton } from '../../Styles';
import { PayerDripCardWrapper } from '../../data/css/EscrowStyles';
import PayerDripCard from '../../components/PayerDripCard';
import ReceiverDripCard from '../../components/ReceiverDripCard';
import { LOCK_STATUSES } from '../../data/constants';
import CreatorLockCard from '../../components/CreatorLockCard';
import ReceiverLockCard from '../../components/ReceiverLockCard';

const ManageLocks = () => {

  const { 
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
  } = useStateContext();

  const [creatorLocks, setCreatorLocks] = useState([]);
  const [receiverLocks, setReceiverLocks] = useState([]);
  const [isPayer, setIsPayer] = useState(true);
  const [lockFilters, setLockFilters] = useState([]);

useEffect(() => {

    if(!currentUsername){return;}

    axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
      table:"locks",
      scope:"waxdaolocker",
      code:"waxdaolocker",
      limit:1000,
      key_type: 'name',
      index_position: 2,
      limit:1000,
      reverse: true,
      lower_bound:currentUsername,
      upper_bound:currentUsername,
      json:true
    }).then((res) => {
        setCreatorLocks(res.data.rows);
    })
    .catch((error) => console.log(error));
  }, []);




  useEffect(() => {

    if(!currentUsername){return;}

    axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
      table:"locks",
      scope:"waxdaolocker",
      code:"waxdaolocker",
      limit:1000,
      key_type: 'name',
      index_position: 3,
      limit:1000,
      reverse: true,
      lower_bound:currentUsername,
      upper_bound:currentUsername,
      json:true
    }).then((res) => {
        setReceiverLocks(res.data.rows);
    })
    .catch((error) => console.log(error));
  }, []);




  return (
  <div id="seo">
    <Helmet>
    <title>Manage Locks</title>
    <meta name="description" content="View and manage your token locks." />
    <link rel="canonical" href="https://waxdao.io/manage-locks" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}

      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>

<NewListingCont>
  <NewListingBody>
<ExploreFiltersCont>
    <h2>Manage Token Locks</h2>
    <FoldersContainer>
                <FoldersRow>
                  <SingleFolderThird selected={isPayer && true}
                   onClick={() => setIsPayer(true)}
                  >
                  Creator
                  </SingleFolderThird>
                  <SingleFolderThird selected={!isPayer && true}
                  onClick={() => setIsPayer(false)}
                  >
                  Receiver
                  </SingleFolderThird>
                </FoldersRow>
              </FoldersContainer>



    </ExploreFiltersCont>

    <ExploreFiltersCont>
      <h2>Filters</h2>

    <ExploreFiltersScrollCont>
    {LOCK_STATUSES?.length > 0 && LOCK_STATUSES.map((item, index) => (
        <SkillsButton value={item.value} onClick={(e) => handleEscrowFilter(item.value, lockFilters, setLockFilters) } selectedList={lockFilters} thisSkill={item.value} >
            {item.display_text}
        </SkillsButton>
    ))}

    </ExploreFiltersScrollCont>
    </ExploreFiltersCont>


<span className={!isPayer && "hidden"}>

    {creatorLocks != null && creatorLocks.length > 0 ? creatorLocks.map((item, index) => (
      <span className={lockFilters.length > 0 && lockFilters.indexOf(item.status) == -1 && "hidden"}>
            <CreatorLockCard key={index} item={item} />
      </span>
    )) : (
      <ExploreFiltersCont>
        <div className='pl-4'>
      You do not have any active token locks. You can create one on the <a href={`${currentWebsiteURL}/locker`}>lock creation page</a>.
      </div>
      </ExploreFiltersCont>
    )}

</span>

<span className={isPayer && "hidden"}>


    {receiverLocks != null && receiverLocks.length > 0 ? receiverLocks.map((item, index) => (
        <span className={lockFilters.length > 0 && lockFilters.indexOf(item.status) == -1 && "hidden"}>
                <ReceiverLockCard key={index} item={item} />
        </span>
    )) : (
      <ExploreFiltersCont>
        <div className='pl-4'>
        You are not the receiver of any token locks.
        </div>
      </ExploreFiltersCont>
    )}


</span>


</NewListingBody>
</NewListingCont>





    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default ManageLocks