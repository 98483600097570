import React, { useEffect } from 'react'
import NumberFormat from 'react-number-format';
import { useStateContext } from '../contexts/ContextProvider';
import { currentUsername, currentWebsiteURL } from '../data/config';
import { LOCK_STATUSES } from '../data/constants';
import { PayerDripCardWrapper, ReceiverDetails, ReceiverIDNumber, ReceiverStatus, ReceiverTotal } from '../data/css/EscrowStyles';
import { CreatorLockCardWrapper } from '../data/css/LockerStyles';
import { cancelDrip, depositEscrowFunds, finalizeDrip } from '../data/functions/escrow_functions';
import { depositLockFunds, withdrawLock } from '../data/functions/locker_functions';
import { SmallButton } from '../Styles';
const ReceiverLockCard = (props) => {
    const item = props.item;

    const { 
        enterModalText,
        setEnterModalText,
        loadingDisplay, 
        setLoadingDisplay, 
        enterButtonsDisplay,
        setEnterButtonsDisplay,
        enterModalDisplay, 
        setEnterModalDisplay,
      } = useStateContext();

  return (
    <CreatorLockCardWrapper>
        <ReceiverIDNumber>ID: {item.ID}</ReceiverIDNumber>
        <ReceiverStatus>
        Status:                     {
                      LOCK_STATUSES[
                        LOCK_STATUSES.findIndex(
                          (attributeIndex) =>
                            attributeIndex.value === item.status
                        )
                      ].display_text
                    }
        </ReceiverStatus>   

        <ReceiverDetails>
        Details: {item.amount}@{item.token_contract} was locked by <a href={`https://waxblock.io/account/${item.creator}`} target="none">{item.creator}</a> and will be released on {new Date(item.unlock_time * 1000).toLocaleDateString()}
        </ReceiverDetails>

        <br/><br/>

        <div className='flex justify-around w-100'>
        <a href={`${currentWebsiteURL}/locks/${item.ID}`}>
                    <SmallButton>
                        View
                    </SmallButton>
                    </a>

            {item.status == 1 && currentUsername == item.receiver && item.unlock_time < Math.round(Date.now() / 1000) && (
                <SmallButton onClick={() => {withdrawLock(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}}>
                    Withdraw
                </SmallButton>
            )}

        </div>

    </CreatorLockCardWrapper>
  )
}

export default ReceiverLockCard