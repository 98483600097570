import { MilestoneContainer, MilestoneDescription, SmallButton } from "../Styles";
import { ChoiceButton } from "../pages/daosv2/DAOStylesV2";
import { SubmitButton } from "./LeftDiv";

const showVotePercentage = (choice_votes, total_votes) => {
    if(choice_votes === 0){
        return "0%";
    }

    return (Number(choice_votes) / Number(total_votes) * 100).toFixed(2) + "%";
}

const CurrentResultCard = ({ choice, total_votes }) => {
    return (
        <MilestoneContainer>
            <h3>Votes: {choice.total_votes} ({showVotePercentage(choice.total_votes, total_votes)})</h3>
            <MilestoneDescription>
            {choice.description}
            </MilestoneDescription>
        </MilestoneContainer>
    );
};

export default CurrentResultCard;
