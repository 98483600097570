import React, { useState } from 'react'
import { ipfsPrefix, resizerPrefix, resizerSuffix } from '../data/constants';
import { DropCardBottomHalf, DropCardEndDate, DropCardImage, DropCardName, DropCardTitle, DropCardTopHalf, DropCardWrapper } from '../data/css/DropStyles'
import { NFTCardCheckbox, ShowNFTInfo } from '../data/css/PremiintStyles';
import { WalletCardMint, WalletCardVideo } from '../data/css/WalletStyles';
import { handleAddAsset, handleAddingToRemoval, handleRemoveAsset, handleRemovingFromRemoval } from '../data/functions/premint_functions';
import { ModalOverlay } from './LeftDiv';
import { ImInfo } from 'react-icons/im'
import AttributeModal from './AttributeModal';
import { FarmNFTStakingRate } from '../data/css/FarmPageStyles';
import { currentWebsiteURL } from '../data/config';

const WojakCard = (props) => {
    const item = props.item;
    const selectedAsset = props.selectedAsset;
    const setSelectedAsset = props.setSelectedAsset;

    const [showAttributeModal, setShowAttributeModal] = useState(false);

  return (
    <DropCardWrapper>
        <DropCardTopHalf>
            {item.data.img != null && (
                <DropCardImage src={`${resizerPrefix}${item.data.img}${resizerSuffix}`} />
            )}
            {item.data != null && item.data.img == null && item.data.video != null && (
                <WalletCardVideo controls loop>
                    <source 
                    src={`${ipfsPrefix}${item.data.video.trim()}${resizerSuffix}`} 
                    type="video/mp4"/>
                </WalletCardVideo>
            )}
        </DropCardTopHalf>
            
        <NFTCardCheckbox selected={selectedAsset == item.asset_id ? "yes" : "no"} onClick={() => {selectedAsset == item.asset_id ? setSelectedAsset("") : setSelectedAsset(item.asset_id)}}>
            {selectedAsset == item.asset_id && (<span>&#x2713;</span>)}
        </NFTCardCheckbox>

        <ShowNFTInfo onClick={() => setShowAttributeModal(true)}>
            <ImInfo className='ml-auto mr-auto' />
        </ShowNFTInfo>

        {showAttributeModal && (
            <span>
                <ModalOverlay />
                <AttributeModal showAttributeModal={showAttributeModal} setShowAttributeModal={setShowAttributeModal} item={item} />
            </span>
        )}

        {item.template_mint != null && (
            <WalletCardMint>
            #{item.template_mint}
            </WalletCardMint>
        )}

      {item.collection != null && (
          <a href={`${currentWebsiteURL}/collection/${item.collection.collection_name}`}>
        <DropCardTitle>
        {item.collection.collection_name}
        </DropCardTitle>
        </a>
      )}

        {item.data.name != null && (
            <DropCardName>
                {item.data.name}
            </DropCardName>
        )}

        <DropCardBottomHalf />

    </DropCardWrapper>
  )
}

export default WojakCard