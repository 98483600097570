import { getWalletSession } from "./wallet_functions";

export const handleRemoveAsset = (asset_id, assetVector, setAssetVector) => {
  const assetList = [...assetVector];
  assetList.splice(assetList.indexOf(asset_id), 1);
  setAssetVector(assetList);
};

export const handleAddAsset = (asset_id, assetVector, setAssetVector) => {
  setAssetVector([...assetVector, asset_id]);
};

export const authorizeWaxDAO = async (
  collection,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");
  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    const action = [
      {
        account: "atomicassets",
        name: "addcolauth",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          collection_name: collection,
          account_to_add: "waxdaomarket",
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText(
        "You have authorized waxdaomarket, you can now create drops"
      );
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    console.log(e);
  }
}; //end authorizeWaxDAO

export const createPack = async (
  assetVector,
  collection,
  templateID,
  totalAvailable,
  startTimestamp,
  dropDescription,
  logoHash,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");
  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  try {
    const action = [
      {
        account: "waxdaomarket",
        name: "createdrop",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          price: 0,
          token_symbol: "0,FREE",
          contract: "free",
          collection: collection,
          schema: "na",
          template_id: Number(templateID),
          total_available: Number(totalAvailable),
          limit_per_user: 0,
          cooldown: 0,
          whitelist_type: "none",
          allowed_users: [],
          farmname: "na",
          minimum_to_stake: 0,
          start_time: Number(startTimestamp),
          end_time: Number(4102488000),
          drop_description: dropDescription,
          drop_logo: logoHash,
          receiver: "na",
          pool_or_farm: "no",
          pool_or_farm_name: "na",
          percent_to_pool: 0,
          pool_id: assetVector,
          drop_type: "premint.pack",
          pack_template: Number(templateID),
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your unbox link has been created.");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    if (
      e.message ==
      "assertion failure with message: You must add waxdaomarket as an authorized account on this collection"
    ) {
      setEnterModalText(
        <span>
          waxdaomarket is not authorized on this collection.
          <br />
          <br />
          <GameButton
            onClick={() => {
              authorizeWaxDAO(
                collection,
                setEnterModalText,
                setLoadingDisplay,
                setEnterButtonsDisplay,
                setEnterModalDisplay
              );
            }}
          >
            Authorize Now
          </GameButton>
        </span>
      );
    } else {
      setEnterModalText(JSON.stringify(e.message));
    }
    console.log(e);
  }
}; //end createDrop

export const filterPoolsByAvailable = (
  poolsList,
  PoolID,
  setPoolData,
  setTotalAvailable,
  setPoolStatus,
  setCollection,
  setAssetVector
) => {
  let filteredPools = [];
  let i = 0;
  let x = 0;
  let poolIDWasFound = false;
  let poolIDIsAvailable = false;
  let collection = "";

  while (i < poolsList.length) {
    if (poolsList[i].ID == PoolID) {
      poolIDWasFound = true;
      if (poolsList[i].status == "available") {
        poolIDIsAvailable = true;
      }
      setPoolData(poolsList[i]);
      setTotalAvailable(poolsList[i].assets.length);
      setPoolStatus(poolsList[i].status);
      collection = poolsList[i].collection;
      setCollection(collection);
      setAssetVector([Number(poolsList[i].ID)]);

      break;
    }
    i++;
  }

  if (poolIDWasFound && poolIDIsAvailable) {
    while (x < poolsList.length) {
      if (
        poolsList[x].status == "available" &&
        poolsList[x].collection == collection
      ) {
        filteredPools.push(poolsList[x]);
      }
      x++;
    }
  }

  return [filteredPools, poolIDWasFound, poolIDIsAvailable];
};

export const handleCalendarChange = (e, setStartTime) => {
  let date = new Date(e);
  const value = date.getTime() / 1000;

  setStartTime(value);
  return;
};
