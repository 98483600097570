import axios from "axios";
import { WalletCallToAction } from "../../components/LeftDiv";
import { wax, anchor, myChain, currentApiList, currentAtomicApiList } from "../config";
import { checkWallet, getWalletSession } from "./wallet_functions";

export const requestNewListing = async (contract, token_symbol, project_name, project_description, website, 
                                        whitepaper, nft_collection, github, twitter, telegram, medium,
                                        discord, youtube, comments, logo,
                                        setEnterModalText, setLoadingDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];

    var project_information = {"token_symbol": token_symbol, "project_name": project_name, "project_description": project_description, 
            "website": website, "whitepaper": whitepaper, "nft_collection": nft_collection, "github": github,
            "twitter": twitter, "telegram": telegram, "medium": medium, "discord": discord, "youtube": youtube, 
            "comments": comments, "logo": logo };




        try {
            const action = [{
              account: 'waxdaosynths',
              name: 'newlisting',
              authorization: [{
                actor: this_user,
                permission: this_permission,
            }],
            data: {
                contract: contract,
                project_information: project_information,
            }
          }]
            const response = await session[0].signTransaction({
              actions: action
            }, {
      
              blocksBehind: 3,
              expireSeconds: 3600,
              broadcast: true,
            })
            setLoadingDisplay('');
            setEnterModalText('Processing your transaction...')
            const timer = setTimeout(() => {
              
                setEnterModalText('Your listing request has been submitted');
                setLoadingDisplay('none');
          
            }, 4000);
            return () => clearTimeout(timer);
        
          } catch(e) {
                setEnterModalText(JSON.stringify(e.message));
                console.log(e)
          }

  }//end requestNewListing