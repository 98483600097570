import React from 'react'
import NumberFormat from 'react-number-format';
import { currentWebsiteURL } from '../data/config';
import { resizerPrefix, resizerSuffix } from '../data/constants';
import { DropCardBottomHalf, DropCardDate, DropCardEndDate, DropCardImage, DropCardLiveStatus, DropCardName, DropCardPrice, DropCardTitle, DropCardTopHalf, DropCardWrapper } from '../data/css/DropStyles'
import { dropIsLive, dropIsSoldOut } from '../data/functions/DropFunctions';
import { DropImage, DropIsLive, DropType, FarmEndDate, FarmTitle, PoolAmount, SingleDropLogo, SoldOut } from './LeftDiv';

const BlendCard = (props) => {
    const item = props.item;
  return (
    <DropCardWrapper>
        <DropCardTopHalf>
            {item.cover_image != null && (
                <DropCardImage src={`${resizerPrefix}${item.cover_image}${resizerSuffix}`} />
            )}
        </DropCardTopHalf>

      {item.creator != null && (
        <a href={`${currentWebsiteURL}/u/${item.creator}`}>
            <DropCardTitle>
            {item.creator}
            </DropCardTitle>
        </a>
      )}

        {item.blend_title != null && (
            <DropCardName>
                {item.blend_title}
            </DropCardName>
        )}

        <DropCardBottomHalf />

        {item.end_time != null && item.total_left != null && item.total_left != '0' &&(
            <DropCardEndDate>
                Ends {new Date(item.end_time * 1000).toLocaleDateString()}
            </DropCardEndDate>
        )}



        {dropIsSoldOut(item) && (
            <DropCardLiveStatus status="sold out">
            SOLD OUT
            </DropCardLiveStatus>
        )}

        {dropIsLive(item) && (
            <DropCardLiveStatus status="live">
            LIVE!!!
            </DropCardLiveStatus>
        )}  


    </DropCardWrapper>
  )
}

export default BlendCard