import { useState } from "react";
import { currentApiList, v2DAOContract } from "../../data/config";
import axios from 'axios';

export const useGetV2DaosByCreator = () => {
    
    const [daosByCreator, setDAOsByCreator] = useState([]);
    const [creatorDAOsAreLoading, setCreatorDAOsAreLoading] = useState(true);

    const getDAOsByCreator = async (UserName) => {
        setCreatorDAOsAreLoading(true);

        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "daos",
                    scope: v2DAOContract,
                    code: v2DAOContract,
                    limit: 1000,
                    json: true,
                });
                
                if (res.data.rows) {
                    let all_daos = [];
                    for(let row of res.data.rows){
                        if(row.creator === UserName){
                            all_daos.push(row);
                        }
                    }
                    setDAOsByCreator(all_daos);
                    console.log(res.data.rows);
                    break; // If the request is successful, we break the loop
                }
            } catch (error) {
                console.log(`Failed on API endpoint ${api}. Error: ${error}`);
            }
        }

        setCreatorDAOsAreLoading(false);
    }

    return [daosByCreator, getDAOsByCreator, creatorDAOsAreLoading]
}
