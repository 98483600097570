import axios from "axios";
import { Name } from "eos-common";
import { anchor, getCurrentApiList, wax, wombat } from "../config";

export const checkWallet = async (walletProvider, setEnterModalText) => {
  if (
    walletProvider != "wax" &&
    walletProvider != "anchor" &&
    walletProvider != "wombat"
  ) {
    setEnterModalText(
      "You are not logged in. Click the wallet icon in the top menu"
    );
    return false;
  }
  return true;
}; //end checkWallet

export const getCustomersData = async (nameValue, setWhitelistStatus) => {
  try {
    const wlistResponse = await axios.post(
      `${getCurrentApiList}/v1/chain/get_table_rows`,
      {
        table: "whitelist",
        scope: "waxywojaknft",
        code: "waxywojaknft",
        limit: 1,
        lower_bound: nameValue,
        upper_bound: nameValue,
        json: true,
      }
    );

    if (wlistResponse.data.rows[0]?.username !== undefined) {
      setWhitelistStatus(true);
      localStorage.setItem("wlstatus", "true");
    }
  } catch (e) {
    console.log(e);
  }
};

export const logUserIn = async (
  walletProvider,
  setCurrentUser,
  setIsLoggedIn,
  setEnterModalText,
  setWhitelistStatus
) => {
  let this_user;

  const walletCheck = await checkWallet(walletProvider, setEnterModalText);

  if (walletProvider == "wax") {
    await wax.logout();
    try {
      await wax.login();
      this_user = wax.users[0].accountName;
    } catch (e) {}
  } else if (walletProvider == "anchor") {
    await anchor.login();
    this_user = anchor.users[0].accountName;

    const nameValue = new Name(this_user).raw().toString();
    getCustomersData(nameValue, setWhitelistStatus);
  } else if (walletProvider == "wombat") {
    await wombat.login();
    this_user = wombat.users[0].accountName;
  } else {
    return;
  }

  setIsLoggedIn(true);
  setCurrentUser(this_user);
  localStorage.setItem("waxAccount", this_user);
  localStorage.setItem("walletProvider", walletProvider);
  location.reload();
}; //end logUserIn

export const logUserOut = async (
  walletProvider,
  setCurrentUser,
  setIsLoggedIn
) => {
  if (walletProvider == "wax") {
    await wax.logout();
  } else if (walletProvider == "anchor") {
    await anchor.logout();
  } else if (walletProvider == "wombat") {
    await wombat.logout();
  } else {
    return;
  }

  setIsLoggedIn(false);
  setCurrentUser("");
  localStorage.removeItem("waxAccount");
  localStorage.removeItem("walletProvider");
  localStorage.removeItem("wlstatus");
  location.reload();
}; //end logUserOut

export const buildCollectionUrl = (collection) => {
  let prefix = "https://wax.atomichub.io/explorer/collection/wax-mainnet/";
  return prefix + collection;
}

export const buildSchemaUrl = (collection, schema) => {
  let prefix = "https://wax.atomichub.io/explorer/schema/wax-mainnet/";
  return prefix + collection + "/" + schema;
}

export const buildTemplateUrl = (collection, template_id) => {
  let prefix = "https://wax.atomichub.io/explorer/template/wax-mainnet/";
  return prefix + collection + "/" + template_id;
}