import { useState } from "react";
import { currentApiList, getCurrentApiList } from "../../data/config";
import axios from 'axios';

export const useGetCollectionFarms = () => {
    
    const [collectionFarms, setCollectionFarms] = useState([]);
    const [collectionFarmsAreLoading, setCollectionFarmsAreLoading] = useState(true);

    const getCollectionFarms = (CollectionName, setShowFarms) => {
        
        axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
            table:"farms",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            limit:1000,
            json:true
            }).then((res) => {
                
                if(res.data.rows.length > 0){
                    let i = 0;
                    let col_list = [];
                    while(i < res.data.rows.length){
                        if(res.data.rows[i].collection == CollectionName || (res.data.rows[i].collections.findIndex(attributeIndex => attributeIndex.collection_name === CollectionName) > -1) ){
                            col_list.push(res.data.rows[i])
                        }
                        i++;
                    }
                    setCollectionFarms(col_list);
                    if(col_list.length == 0){
                        setShowFarms(false);
                    }
                    console.log(res.data.rows);
                }
            })
            .catch((error) => console.log(error))
            .finally(() => setCollectionFarmsAreLoading(false))

    }

    return [collectionFarms, getCollectionFarms, collectionFarmsAreLoading]
}