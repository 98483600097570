import { useState } from "react";
import { currentApiList, v2DAOContract } from "../../data/config";
import axios from 'axios';

export const useGetSingleDAOProfile = () => {
    
    const [daoProfile, setDaoProfile] = useState([]);
    const [profileIsLoading, setProfileIsLoading] = useState(true);

    const getDaoProfile = async (dao) => {
        setProfileIsLoading(true);
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "daoprofiles",
                    scope: v2DAOContract,
                    code: v2DAOContract,
                    limit: 1,
                    lower_bound: dao,
                    upper_bound: dao,
                    json: true,
                });
                
                if (res.data.rows) {
                    setDaoProfile(res.data.rows[0]);
                    console.log(res.data.rows[0]);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        setProfileIsLoading(false);
    }

    return [daoProfile, getDaoProfile, profileIsLoading]
}
