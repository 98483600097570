import React from 'react'
import NumberFormat from 'react-number-format';
import { currentWebsiteURL } from '../data/config';
import { resizerPrefix, resizerSuffix } from '../data/constants';
import { DropCardBottomHalf, DropCardDate, DropCardEndDate, DropCardImage, DropCardLiveStatus, DropCardName, DropCardPrice, DropCardTitle, DropCardTopHalf, DropCardWrapper } from '../data/css/DropStyles'
import { dropIsLive, dropIsSoldOut } from '../data/functions/DropFunctions';
import { DropImage, DropIsLive, DropType, FarmEndDate, FarmTitle, PoolAmount, SingleDropLogo, SoldOut } from './LeftDiv';

const DropCard = (props) => {
    const item = props.item;
  return (
    <DropCardWrapper>
        <DropCardTopHalf>
            {item.drop_logo != null && (
                <DropCardImage 
                src={`${resizerPrefix}${item.drop_logo}${resizerSuffix}`} 
                />
            )}
        </DropCardTopHalf>

      {item.collection != null && (
        <a href={`${currentWebsiteURL}/collection/${item.collection}`}>
        <DropCardTitle>
        {item.collection}
        </DropCardTitle>
        </a>
      )}

        {item.price != null && item.drop_type != null && item.drop_type != "premint.pack" && (
            <DropCardName>
                {item.price == "0 FREE" ? "Free Drop" : (<span><NumberFormat displayType='text' thousandSeparator={true} value={Number(item.price.substring(0, item.price.indexOf(' '))).toFixed(2)} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> {item.price.substring(item.price.indexOf(' '))} </span>)}
            </DropCardName>
        )}

        <DropCardBottomHalf />

        {item.end_time != null && item.total_left != null && item.total_left != '0'  && item.drop_type != null && item.drop_type != "premint.pack" && (
            <DropCardEndDate>
                Ends {new Date(item.end_time * 1000).toLocaleDateString()}
            </DropCardEndDate>
        )}



        {dropIsSoldOut(item) && item.drop_type != null && item.drop_type != "premint.pack" && (
            <DropCardLiveStatus status="sold out">
            SOLD OUT
            </DropCardLiveStatus>
        )}

        {dropIsLive(item) && item.drop_type != null && item.drop_type != "premint.pack" && (
            <DropCardLiveStatus status="live">
            LIVE!!!
            </DropCardLiveStatus>
        )}  


    </DropCardWrapper>
  )
}

export default DropCard