import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { FarmsTitle, AllFarmsCont, NewUIFarmsPageCont, DropFilterButton, SQLInput } from '../../components/LeftDiv';
import { addTokenEndpoint, addUserEndpoint, adminMode, adminServerURL, blogImagePath, currentWebsiteURL, getAllBlogsEndpoint, getAllTokensEndpoint, getBlogPostEndpoint, localServerHttps, localServerURL, loginEndpoint, removeBlogEndpoint, tokenServerURL } from '../../data/config';
import NotFound from '../../components/NotFound';
import { BlogCard, BlogDate, BlogThumbnail, BlogThumbnailCont, BlogTitle, BlogTitleCont, RemoveBlogButton } from '../../data/css/BlogStyles';
import { useToggle } from '../../components/CustomHooks/useToggle';



const AllBlogs = () => {

const [blogList, setBlogList] = useState([]);
const [blogWasChanged, toggleBlogWasChanged] = useToggle();



if(blogList != null && blogList.length > 0){
  
  return (
    <div id="seo">
    <Helmet>
    <title>WaxDAO Blog - All Articles</title>
    <meta name="description" content={``} />
    <link rel="canonical" href={`https://waxdao.io/blog`} />
    </Helmet>

    <NewUIFarmsPageCont>
<FarmsTitle>
    WaxDAO Blog <span className={!adminMode && "hidden"}>ADMIN MODE IS ON</span>
</FarmsTitle>

<AllFarmsCont>




{blogList != null && blogList.map((item, index) => (

<span key={index} className={item.base_token.str != "WAX@eosio.token" && "hidden"}>
<BlogCard>
<BlogDate>
<img src={`https://logos.waxdao.io/${item.quote_token.str.replace("@","_").toLowerCase()}.png`} style={{width:"100px", height:"100px"}}         onError={(e) =>
          (e.target.onerror = null)(
            (e.target.src =
              "https://logos.waxdao.io/wojak_mdcryptonfts.png")
          )
        } />
</BlogDate>


    {/* <img src={`https://logos.waxdao.io/${item.quote_token.str.replace("@"|"_")}`} */}

{item.quote_token != null && (
    <BlogDate>
       Str: {item.quote_token.str}<br/>
       Symbol: {item.quote_token.symbol.name}<br/>
       Decimals: {item.quote_token.symbol.precision}<br/>
       Contract: {item.quote_token.contract}<br/>
    </BlogDate>
)}

{item.quote_volume != null && (
    <BlogDate>
       Quote Vol: {item.quote_volume}
    </BlogDate>
)}

<BlogDate>
{item.last_price != null && (<span>Price: {item.last_price}<br/></span>)}
{item.high24 != null && (<span>24h High: {item.high24}<br/></span>)}
{item.low24 != null && (<span>24h Low: {item.low24}<br/></span>)}
{item.volume24 != null && (<span>24h Vol: {item.volume24}<br/></span>)}
{item.volumeMonth != null && (<span>30d Vol: {item.volumeMonth}<br/></span>)}
{item.volumeWeek != null && (<span>7d Vol: {item.volumeWeek}<br/></span>)}
{item.change24 != null && (<span>24h Change: {item.change24}<br/></span>)}
{item.changeWeek != null && (<span>7d Change: {item.changeWeek}<br/></span>)}
</BlogDate>

</BlogCard>
</span>    
))}



{/* 
{blogList != null && blogList.map((item, index) => (

<span key={index} className={item.base_token.str != "WAX@eosio.token" && "hidden"}>
<BlogCard>
    <BlogTitleCont>
    {item.title != null && (
        <BlogTitle>
            {item.title}
        </BlogTitle>
    )}
    </BlogTitleCont>
<BlogThumbnailCont>
<BlogThumbnail src={`${blogImagePath}/${item.thumbnail}`} />
</BlogThumbnailCont>

{item.base_token != null && (
    <BlogDate>
        {item.base_token.str}
    </BlogDate>
)}

<a href={`${currentWebsiteURL}/blog/${item.slug}`}>
<RemoveBlogButton>
    Read More...
</RemoveBlogButton>
</a>


<br/>
<RemoveBlogButton className={!adminMode && "hidden"} onClick={() => { removeBlogPost(item.ID); }}>
    Delete Blog
</RemoveBlogButton>


</BlogCard>
</span>    
))}

 */}


</AllFarmsCont>

    </NewUIFarmsPageCont>

    <br/><br/><br/>
    </div>
  )


}

else{return(
  <NotFound />
)}


}

export default AllBlogs