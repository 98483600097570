import { getWalletSession } from "./wallet_functions";

export const createLock = async (receivingAccount, token_contract, amount, precision, token_name, timestamp, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    let walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2];

    let amountToSend = Number(amount).toFixed(Number(precision)) + " " + token_name.toUpperCase();

    try {

      const action = [{
        account: 'waxdaolocker',
        name: 'createlock',
        authorization: [{
          actor: this_user,
          permission: this_permission,
        }],
        data: {
            creator: this_user,
            receiver: receivingAccount,
            amount: amountToSend,
            token_contract: token_contract,
            unlock_time: timestamp,
        }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 120,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {

          setEnterModalText('Your lock has been created. To deposit the funds, go to waxdao.io/manage-locks');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');

      }, 2000);
      return () => clearTimeout(timer);

    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)

    }

} //end createLock

export const cancelLock = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    let walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2];


    try {
      const action = [{
            account: 'waxdaolocker',
            name: 'cancellock',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
            lock_ID: ID,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 90,
        broadcast: true,
      })

      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {

          setEnterModalText('Your lock has been cancelled');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');

      }, 2000);
      return () => clearTimeout(timer);

    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)

    }

  } //end cancelLock  

  export const withdrawLock = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    let walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2];


    try {
      const action = [{
            account: 'waxdaolocker',
            name: 'withdraw',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
            lock_ID: ID,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 90,
        broadcast: true,
      })

      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {

          setEnterModalText('Your funds have been withdrawn');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');

      }, 2000);
      return () => clearTimeout(timer);

    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)

    }

  } //end withdrawLock

  export const depositLockFunds = async (ID, amount, contract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    let walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2];

    try {

      const action = [{
            account: contract,
            name: 'transfer',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              from: this_user,
              to: 'waxdaolocker',
              quantity: amount,
              memo: "|deposit|" + ID + "|",
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 90,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {

          setEnterModalText('Your deposit was successful');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');

      }, 2000);
      return () => clearTimeout(timer);

    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)

    }

  } //end depositLockFunds