import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useGetAllPools } from "../../components/CustomHooks/useGetAllPools";
import { useGetBlenderBalances } from "../../components/CustomHooks/useGetBlenderBalances";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentWebsiteURL } from "../../data/config";
import { resizerPrefix, resizerSuffix } from "../../data/constants";
import {
  PoolLineBreak,
  SinglePoolDetail,
  SinglePoolDetailBold,
} from "../../data/css/PoolPageStyles";
import {
  AllTokensWrapper,
  SelectPoolDropDown,
  SelectTokenDropDown,
  SelectTokenTextWrapper,
  SendAllButton,
  SingleTokenButton,
  StakedTokenCard,
  TokenCard,
  TokenDetailsButton,
  TokenDetailsContainer,
} from "../../data/css/WalletStyles";
import { roundWalletBalance } from "../../data/functions/pool_functions";
import {
    blendDeposit,
    getAssetQuantity,
    getAssetSymbol,
  getPoolsToStakeIn,
  openUnstakeTokensSection,
  stakeTokens,
  stakeTokensNew,
  transferTokens,
  transferTokensNew,
  unstakeTokens,
  withdrawBlendToken,
} from "../../data/functions/wallet_functions";
import {
  ExploreFiltersCont,
  ExploreFiltersInnerCont,
  FoldersContainer,
  FoldersRow,
  MainButton,
  MilestoneContainer,
  SingleFolderFourth,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";

export const showTokensSection = (
  currentAssetSection,
  currentTokenSection,
  setCurrentTokenSection,
  openWalletTokenBalancesTab,
  setMyTokenBalances,
  currentTokenAction,
  setCurrentTokenAction,
  myTokenBalances,
  setLoadingDisplay
) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();
  const [pools, getAllPools, poolsAreLoading] = useGetAllPools();
  const [stakedTokens, setStakedTokens] = useState([]);
  const [stakedTokensAreLoading, setStakedTokensAreLoading] = useState(true);

  return (
    <span className={currentAssetSection != "Tokens" && "hidden"}>
      <ExploreFiltersCont>
        {/* <h2>Asset Types</h2> */}
        <ExploreFiltersInnerCont>
          <FoldersContainer>
            <FoldersRow>
              <SingleFolderFourth
                onClick={() => {
                  setCurrentTokenSection("Wallet");
                  openWalletTokenBalancesTab(setMyTokenBalances);
                }}
                selected={currentTokenSection == "Wallet" && true}
              >
                Wallet
              </SingleFolderFourth>
              <SingleFolderFourth
                onClick={() => setCurrentTokenSection("Blender")}
                selected={currentTokenSection == "Blender" && true}
              >
                Blender
              </SingleFolderFourth>
              <SingleFolderFourth
                onClick={() => {
                  setCurrentTokenSection("Unstake");
                  openUnstakeTokensSection(
                    setStakedTokens,
                    setStakedTokensAreLoading
                  );
                  getAllPools();
                }}
                selected={currentTokenSection == "Unstake" && true}
              >
                Unstake
              </SingleFolderFourth>
              <a href={`${currentWebsiteURL}/wojak/swap`} target="none">
              <SingleFolderFourth
                onClick={() => setCurrentTokenSection("Swap")}
                selected={currentTokenSection == "Swap" && true}
              >
                Swap
              </SingleFolderFourth>
              </a>
            </FoldersRow>
          </FoldersContainer>
        </ExploreFiltersInnerCont>
      </ExploreFiltersCont>

      {showWalletTokenActions(
        currentAssetSection,
        currentTokenSection,
        currentTokenAction,
        setCurrentTokenAction,
        getAllPools
      )}

      {showTransferTokensSection(
        currentAssetSection,
        currentTokenSection,
        currentTokenAction,
        setCurrentTokenAction,
        myTokenBalances,
        setLoadingDisplay
      )}
      {showStakeTokensSection(
        currentAssetSection,
        currentTokenSection,
        currentTokenAction,
        setCurrentTokenAction,
        myTokenBalances,
        setLoadingDisplay,
        pools,
        getAllPools,
        poolsAreLoading
      )}
      {showUnstakeTokensSection(
        currentAssetSection,
        currentTokenSection,
        stakedTokens,
        stakedTokensAreLoading,
        pools,
        getAllPools,
        poolsAreLoading,
        setLoadingDisplay
      )}
      {showBlenderBalancesSection(
    currentAssetSection,
    currentTokenSection,
    setLoadingDisplay,
    myTokenBalances,
  )}
    </span>
  );
};

export const showWalletTokenActions = (
  currentAssetSection,
  currentTokenSection,
  currentTokenAction,
  setCurrentTokenAction,
  getAllPools
) => {
  return (
    <span
      className={
        (currentAssetSection != "Tokens" || currentTokenSection != "Wallet") &&
        "hidden"
      }
    >
      <ExploreFiltersCont>
        <ExploreFiltersInnerCont>
          <FoldersContainer>
            <FoldersRow>
              <SingleFolderFourth
                onClick={() => {
                  setCurrentTokenAction("Transfer");
                }}
                selected={currentTokenAction == "Transfer" && true}
              >
                Transfer
              </SingleFolderFourth>
              <SingleFolderFourth
                onClick={() => {
                  setCurrentTokenAction("Stake");
                  getAllPools();
                }}
                selected={currentTokenAction == "Stake" && true}
              >
                Stake
              </SingleFolderFourth>
            </FoldersRow>
          </FoldersContainer>
        </ExploreFiltersInnerCont>
      </ExploreFiltersCont>
    </span>
  );
};

export const showTransferTokensSection = (
  currentAssetSection,
  currentTokenSection,
  currentTokenAction,
  setCurrentTokenAction,
  myTokenBalances,
  setLoadingDisplay
) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();
  const [selectedToken, setSelectedToken] = useState("");
  const [selectedTokenObject, setSelectedTokenObject] = useState({});
  const [amount, setAmount] = useState(0);
  const [memo, setMemo] = useState("");
  const [receiver, setReceiver] = useState("");
  return (
    <span
      className={
        (currentAssetSection != "Tokens" ||
          currentTokenSection != "Wallet" ||
          currentTokenAction != "Transfer") &&
        "hidden"
      }
    >
      <TallFiltersCont>
        <TallFiltersInnerCont>
          <h3>Send To:</h3>
          <input
            placeholder="Wax address"
            value={receiver}
            onChange={(e) => setReceiver(e.target.value.toLowerCase())}
            maxLength={13}
          />
          <br />
          <br />
          <h3>Amount:</h3>
          <input
            placeholder="0"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />{" "}
          {showSelectTokenMenu(
            myTokenBalances,
            selectedToken,
            setSelectedToken,
            setSelectedTokenObject
          )}
          <br />
          <SendAllButton
            onClick={() =>
              myTokenBalances.findIndex(
                (attributeIndex) =>
                  attributeIndex.currency ===
                  selectedToken.substring(0, selectedToken.indexOf("@"))
              ) > -1 &&
              setAmount(
                myTokenBalances[
                  myTokenBalances.findIndex(
                    (attributeIndex) =>
                      attributeIndex.currency ===
                      selectedToken.substring(0, selectedToken.indexOf("@"))
                  )
                ].amount
              )
            }
          >
            Send all
          </SendAllButton>
          <br />
          <br />
          <h3>Memo:</h3>
          <input
            placeholder="optional"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            maxLength={240}
          />
          <br />
          <br />
          <MainButton
            onClick={() =>
              transferTokensNew(
                receiver,
                amount,
                selectedTokenObject,
                memo,
                setEnterModalDisplay,
                setEnterModalText,
                setLoadingDisplay
              )
            }
          >
            Send {amount} {selectedToken}
          </MainButton>
        </TallFiltersInnerCont>
      </TallFiltersCont>
    </span>
  );
};

export const showSelectTokenMenu = (
  myTokenBalances,
  selectedToken,
  setSelectedToken,
  setSelectedTokenObject
) => {
  const [showTokens, setShowTokens] = useState(false);

  return (
    <SelectTokenDropDown onClick={() => setShowTokens((prev) => !prev)}>
      <SelectTokenTextWrapper className={showTokens && "hidden"}>
        {selectedToken != "" ? selectedToken : "Select Token"}
      </SelectTokenTextWrapper>
      <span className={!showTokens && "hidden"}>
        <AllTokensWrapper>
          {myTokenBalances.length > 0
            ? myTokenBalances
                .sort((a, b) => b.amount - a.amount)
                .map((item, index) => (
                  <SingleTokenButton
                    key={index}
                    onClick={() => {
                      setSelectedToken(item.currency + "@" + item.contract);
                      setSelectedTokenObject(item);
                    }}
                  >
                    {item.amount != null && (
                      <NumberFormat
                        displayType="text"
                        thousandSeparator={true}
                        value={item.amount}
                      />
                    )}{" "}
                    {item.currency != null && item.currency}
                  </SingleTokenButton>
                ))
            : "No tokens"}
        </AllTokensWrapper>
      </span>
    </SelectTokenDropDown>
  );
};

export const showStakeTokensSection = (
  currentAssetSection,
  currentTokenSection,
  currentTokenAction,
  setCurrentTokenAction,
  myTokenBalances,
  setLoadingDisplay,
  pools,
  getAllPools,
  poolsAreLoading
) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();
  const [selectedToken, setSelectedToken] = useState("");
  const [selectedTokenObject, setSelectedTokenObject] = useState({});
  const [selectedPool, setSelectedPool] = useState("");
  const [amount, setAmount] = useState(0);
  const [showPools, setShowPools] = useState(false);

  return (
    <span
      className={
        (currentAssetSection != "Tokens" ||
          currentTokenSection != "Wallet" ||
          currentTokenAction != "Stake") &&
        "hidden"
      }
    >
      {poolsAreLoading
        ? "Loading...."
        : myTokenBalances?.map((item, index) => (
            <TokenCard
              selected={
                selectedToken == item.currency + "@" + item.contract
                  ? true
                  : false
              }
              key={index}
              className={getPoolsToStakeIn(pools, item).length == 0 && "hidden"}
            >
              <img
                src={`https://logos.waxdao.io/${item?.currency?.toLowerCase()}_${item?.contract?.toLowerCase()}.png`}
                onError={(e) =>
                  (e.target.onerror = null)(
                    (e.target.src = "https://logos.waxdao.io/default-logo.png")
                  )
                }
              />
              {item.currency}
              <TokenDetailsButton
                onClick={() => {
                  setSelectedToken(
                    selectedToken != item.currency + "@" + item.contract
                      ? item.currency + "@" + item.contract
                      : ""
                  );
                  setSelectedPool("");
                  setShowPools(false);
                  setAmount(0);
                  setSelectedTokenObject(item);
                }}
              >
                Details
              </TokenDetailsButton>
              <br />
              <TokenDetailsContainer
                className={
                  selectedToken != item.currency + "@" + item.contract && "hidden"
                }
              >
                <span style={{ fontSize: "12px" }}>
                  Balance{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {myTokenBalances.findIndex(
                      (attributeIndex) =>
                        attributeIndex.currency ===
                        selectedToken.substring(0, selectedToken.indexOf("@"))
                    ) > -1 &&
                      myTokenBalances[
                        myTokenBalances.findIndex(
                          (attributeIndex) =>
                            attributeIndex.currency ===
                            selectedToken.substring(
                              0,
                              selectedToken.indexOf("@")
                            )
                        )
                      ].amount}
                  </SinglePoolDetailBold>
                </span>
                <br />
                <br />
                <h3>Amount</h3>
                <input
                  placeholder="0"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />

                <SelectPoolDropDown
                  onClick={() => setShowPools((prev) => !prev)}
                >
                  <SelectTokenTextWrapper className={showPools && "hidden"}>
                    {selectedPool != "" ? selectedPool : "Select Pool"}
                  </SelectTokenTextWrapper>
                  <span className={!showPools && "hidden"}>
                    <AllTokensWrapper>
                      {getPoolsToStakeIn(pools, item).length > 0
                        ? getPoolsToStakeIn(pools, item).map((item, index) => (
                            <SingleTokenButton
                              key={index}
                              onClick={() => {
                                setSelectedPool(item.poolname);
                              }}
                            >
                              {item.poolname != null && item.poolname}
                            </SingleTokenButton>
                          ))
                        : "No pools"}
                    </AllTokensWrapper>
                  </span>
                </SelectPoolDropDown>
                <br />
                <SendAllButton
                  onClick={() =>
                    myTokenBalances.findIndex(
                      (attributeIndex) =>
                        attributeIndex.currency ===
                        selectedToken.substring(0, selectedToken.indexOf("@"))
                    ) > -1 &&
                    setAmount(
                      myTokenBalances[
                        myTokenBalances.findIndex(
                          (attributeIndex) =>
                            attributeIndex.currency ===
                            selectedToken.substring(
                              0,
                              selectedToken.indexOf("@")
                            )
                        )
                      ].amount
                    )
                  }
                >
                  Stake all
                </SendAllButton>
                <br />
                <br />
                <MainButton
                  onClick={() =>
                    stakeTokensNew(
                      amount,
                      selectedTokenObject,
                      selectedPool,
                      setEnterModalDisplay,
                      setEnterModalText,
                      setLoadingDisplay
                    )
                  }
                >
                  Stake
                </MainButton>
              </TokenDetailsContainer>
            </TokenCard>
          ))}
    </span>
  );
};

export const showSelectPoolMenu = (pools, selectedPool, setSelectedPool) => {
  const [showPools, setShowPools] = useState(false);

  return (
    <SelectTokenDropDown onClick={() => setShowPools((prev) => !prev)}>
      <SelectTokenTextWrapper className={showPools && "hidden"}>
        {selectedPool != "" ? selectedPool : "Select Pool"}
      </SelectTokenTextWrapper>
      <span className={!showPools && "hidden"}>
        <AllTokensWrapper>
          {pools.length > 0
            ? pools.map((item, index) => (
                <SingleTokenButton
                  key={index}
                  onClick={() => {
                    setSelectedPool(item.poolname);
                  }}
                >
                  {item.poolname != null && item.poolname}
                </SingleTokenButton>
              ))
            : "No pools"}
        </AllTokensWrapper>
      </span>
    </SelectTokenDropDown>
  );
};

export const showUnstakeTokensSection = (
  currentAssetSection,
  currentTokenSection,
  stakedTokens,
  stakedTokensAreLoading,
  pools,
  getAllPools,
  poolsAreLoading,
  setLoadingDisplay
) => {
  const [selectedPool, setSelectedPool] = useState("");
  const [amount, setAmount] = useState(0);

  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();

  return (
    <span
      className={
        (currentAssetSection != "Tokens" || currentTokenSection != "Unstake") &&
        "hidden"
      }
    >
      {stakedTokens.length > 0 && !poolsAreLoading ? (
        stakedTokens.map((item, index) => (
          <StakedTokenCard
            selected={selectedPool == item.poolname ? true : false}
            key={index}
          >
            <img
              src={`${resizerPrefix}${
                pools[
                  pools.findIndex(
                    (attributeIndex) =>
                      attributeIndex.poolname === item.poolname
                  )
                ].logo
              }${resizerSuffix}`}
            />
            <a href={`${currentWebsiteURL}/pool/${item.poolname}`}>
              {item.poolname}
            </a>
            <TokenDetailsButton
              onClick={() => {
                setSelectedPool(
                  selectedPool != item.poolname ? item.poolname : ""
                );
                setAmount(0);
              }}
            >
              Details
            </TokenDetailsButton>
            <br />
            <TokenDetailsContainer
              className={selectedPool != item.poolname && "hidden"}
            >
              <span style={{ fontSize: "12px" }}>
                <SinglePoolDetail>
                  Staked{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {roundWalletBalance(item.amountstaked)}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Earned{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>{item.claimable}</SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Unlock Time{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {item.unlocktime != null
                      ? Math.round(Date.now()) < item.unlocktime * 1000
                        ? new Date(item.unlocktime * 1000).toLocaleDateString()
                        : "Unlocked"
                      : "N/A"}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
              </span>

              <br />
              <br />

              <h3>Amount</h3>
              <input
                placeholder="0"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />

              <br />
              <SendAllButton
                onClick={() =>
                  setAmount(
                    item.amountstaked.substring(
                      0,
                      item.amountstaked.indexOf(" ")
                    )
                  )
                }
              >
                Unstake all
              </SendAllButton>
              <br />
              <br />
              <MainButton
                onClick={() =>
                  unstakeTokens(
                    amount,
                    item,
                    setEnterModalDisplay,
                    setEnterModalText,
                    setLoadingDisplay
                  )
                }
              >
                Unstake
              </MainButton>
            </TokenDetailsContainer>
          </StakedTokenCard>
        ))
      ) : (
        <div>No tokens to show</div>
      )}
    </span>
  );
};


export const showBlenderBalancesSection = (
    currentAssetSection,
    currentTokenSection,
    setLoadingDisplay,
    myTokenBalances,
  ) => {
    const [blenderBalances, getBlenderBalances, blenderBalancesAreLoading] = useGetBlenderBalances();
    const [amount, setAmount] = useState(0);
    const [selectedToken, setSelectedToken] = useState("");
    const [selectedTokenToDeposit, setSelectedTokenToDeposit] = useState("");
    const [selectedTokenObject, setSelectedTokenObject] = useState({});

    useEffect(() => {
        if(currentAssetSection == "Tokens" && currentTokenSection == "Blender"){
            getBlenderBalances();
        }
    }, [currentTokenSection])
    
  
    const {
      enterModalDisplay,
      setEnterModalDisplay,
      enterModalText,
      setEnterModalText,
    } = useStateContext();
  
    return (
      <span
        className={
          (currentAssetSection != "Tokens" || currentTokenSection != "Blender") &&
          "hidden"
        }
      >

        <TallFiltersCont>
            <TallFiltersInnerCont>
            <h3>Deposit Tokens To Blender</h3>

          <input
            placeholder="0"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />{" "}
          {showSelectTokenMenu(
            myTokenBalances,
            selectedTokenToDeposit,
            setSelectedTokenToDeposit,
            setSelectedTokenObject
          )}
          <br />
          <SendAllButton
            onClick={() =>
              myTokenBalances.findIndex(
                (attributeIndex) =>
                  attributeIndex.currency ===
                  selectedTokenToDeposit.substring(0, selectedTokenToDeposit.indexOf("@"))
              ) > -1 &&
              setAmount(
                myTokenBalances[
                  myTokenBalances.findIndex(
                    (attributeIndex) =>
                      attributeIndex.currency ===
                      selectedTokenToDeposit.substring(0, selectedTokenToDeposit.indexOf("@"))
                  )
                ].amount
              )
            }
          >
            Send all
          </SendAllButton>

          <br />
          <br />
          <MainButton
            onClick={() =>
                blendDeposit(
                    amount,
                    selectedTokenObject,
                    setEnterModalDisplay,
                    setEnterModalText,
                    setLoadingDisplay)
            }
          >
            Deposit {amount} {selectedTokenToDeposit}
          </MainButton>
            </TallFiltersInnerCont>
            
        </TallFiltersCont>        


        {blenderBalancesAreLoading && "Loading..."}
        {!blenderBalancesAreLoading && blenderBalances.length > 0 ? blenderBalances.map((item, index) => (
            <StakedTokenCard 
            selected={
                selectedToken == getAssetSymbol(item.balance) + "@" + item.contract
                  ? true
                  : false
              }
            key={index}>
                              <img
                src={`https://logos.waxdao.io/${getAssetSymbol(item.balance).toLowerCase()}_${item.contract.toLowerCase()}.png`}
                onError={(e) =>
                  (e.target.onerror = null)(
                    (e.target.src = "https://logos.waxdao.io/default-logo.png")
                  )
                }
              />
                {item.balance}
                <TokenDetailsButton
                onClick={() => {
                  setSelectedToken(
                    selectedToken != getAssetSymbol(item.balance) + "@" + item.contract
                      ? getAssetSymbol(item.balance) + "@" + item.contract
                      : ""
                  );

                  setAmount(0);
                }}
              >
                Details
              </TokenDetailsButton>
              <br />
              <TokenDetailsContainer
                className={
                  selectedToken != getAssetSymbol(item.balance) + "@" + item.contract && "hidden"
                }
              >
                <span style={{ fontSize: "12px" }}>
                  Wallet Balance{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {
                        myTokenBalances.findIndex(
                          (attributeIndex) =>
                            attributeIndex.currency ===
                            selectedToken.substring(
                              0,
                              selectedToken.indexOf("@")
                            )
                        )
                       > -1 ? myTokenBalances[
                        myTokenBalances.findIndex(
                          (attributeIndex) =>
                            attributeIndex.currency ===
                            selectedToken.substring(
                              0,
                              selectedToken.indexOf("@")
                            )
                        )
                      ].amount : 0}

                  </SinglePoolDetailBold>
                </span>
                <br />
                <br />
                <h3>Amount</h3>
                <input
                  placeholder="0"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />


                <br />
                <SendAllButton
                  onClick={() =>
                    myTokenBalances.findIndex(
                      (attributeIndex) =>
                        attributeIndex.currency ===
                        selectedToken.substring(0, selectedToken.indexOf("@"))
                    ) > -1 &&
                    setAmount(
                      myTokenBalances[
                        myTokenBalances.findIndex(
                          (attributeIndex) =>
                            attributeIndex.currency ===
                            selectedToken.substring(
                              0,
                              selectedToken.indexOf("@")
                            )
                        )
                      ].amount
                    )
                  }
                >
                    Select Max Wallet Balance
                </SendAllButton>
                  <br/>
                <SendAllButton
                  onClick={() =>
                    setAmount(
                      getAssetQuantity(item.balance)
                    )
                  }
                >
                    Select Max Blender Balance
                </SendAllButton>

                <br />
                <br />
                <MainButton
                  onClick={() =>
                    blendDeposit(
                        amount,
                        myTokenBalances.findIndex(
                            (attributeIndex) =>
                              attributeIndex.currency ===
                              selectedToken.substring(0, selectedToken.indexOf("@"))
                          ) > -1 &&
        
                            myTokenBalances[
                              myTokenBalances.findIndex(
                                (attributeIndex) =>
                                  attributeIndex.currency ===
                                  selectedToken.substring(
                                    0,
                                    selectedToken.indexOf("@")
                                  )
                              )
                            ],
                        setEnterModalDisplay,
                        setEnterModalText,
                        setLoadingDisplay)
                  }
                >
                  Deposit
                </MainButton>
                &nbsp;&nbsp;
                <MainButton
                  onClick={() =>
                    withdrawBlendToken(
                    amount,
                    item,
                    setEnterModalDisplay,
                    setEnterModalText,
                    setLoadingDisplay)
                  }
                >
                  Withdraw
                </MainButton>
              </TokenDetailsContainer>
            </StakedTokenCard>
        )) : (
            "No balances to show"
        )}
    

      </span>
    );
  };
  