import React, { useEffect } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import { AllFarmsCont, FarmsPageCont, FarmsTitle, SingleFarmCont, FarmLogo, FarmTitle, FarmEndDate, PoolAmount, ProposerTypeMessage, MissingInfoMsg, DaoButton, PaymentOptCont, BurnableCont, NFT, NewRow, BuyWojak, ProposalInput, NewProposal, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DisabledButton, SingleDropLogo, DropDown, FilterMenu, DropType, SoldOut, NewUIFarmsPageCont, CloseWindow } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import waxdao_ad from '../../data/waxdao_ad.png';
import { currentAdBanner, currentWebsiteURL, getCurrentApiList, wombat } from '../../data/config';
import { wax, anchor, myChain, currentApiList, currentAtomicApiList } from '../../data/config';
import DropListDiv from './DropListDiv';
import DropFilters from './DropFilters';
import CurrentBannerAd from '../../components/CurrentBannerAd';
import { DefaultHrefButton } from '../../data/css/DropStyles';
import { ExploreFiltersCont, ExploreFiltersScrollCont, FoldersContainer, FoldersRow, NewListingBody, NewListingCont, SingleFolderFourth, SkillsButton } from '../../Styles';


const Drops = () => {

  const { dropList, setDropList,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    logoHash,
    setLogoHash,
    dropSortMode,
    setDropSortMode,
    pageCount,
    setPageCount,
    loadMoreDisplay,
    setLoadMoreDisplay,
    currentNavbarLocation,
    setCurrentNavbarLocation,
    setDropFilters,
    dropFilters

} = useStateContext();


useEffect(() => {

setDropList([]);
setLogoHash('');
setDropSortMode('newest');
setPageCount();
setLoadMoreDisplay('');
setCurrentNavbarLocation("Market");



          

          axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
            table:"drops",
            scope:"waxdaomarket",
            code:"waxdaomarket",
            reverse: true,
            //lower_bound: 10000000000,
            upper_bound: 10000000000,
            limit:100,
            json:true
          })
            .then((response) => {
        
                setDropList(response.data.rows);

                if(response.data.more == true){

                  setPageCount(Number(response.data.next_key));
    
                } else { setLoadMoreDisplay('hidden'); }

                console.log(response.data.next_key);
              
              
            })
            .catch((error) => console.log(error));

  
  }

, []);




const getNextPage = (pageCount, setPageCount, dropList, setDropList) => {

    var lowerBound = pageCount;

      axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
        table:"drops",
        scope:"waxdaomarket",
        code:"waxdaomarket",
        //lower_bound: lowerBound,
        reverse: true,
        upper_bound: lowerBound,
        limit:100,
        json:true
      })
        .then((response) => {

            var list = [...dropList];


            if(Array.isArray(dropList[0])){
              list = dropList[0];
              //console.log('it is an array');
            }

            list = list.concat(response.data.rows);

            setDropList(list);

            if(response.data.more == true){

              setPageCount(Number(response.data.next_key));
              console.log(Number(response.data.next_key))

            } else { setLoadMoreDisplay('hidden'); }
            
          
        })
        .catch((error) => console.log(error));
      




}


  return (
  <div id="seo">
    <Helmet>
    <title>NFT Drops</title>
    <meta name="description" content="View a list of NFT drops on WaxDAO" />
    <link rel="canonical" href="https://waxdao.io/drops" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <CloseWindow onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </CloseWindow>
      </Modal>


    <NewListingCont>
    <NewListingBody>


<CurrentBannerAd />


      <AllFarmsCont>

<DropFilters />
<FoldersContainer>
  <FoldersRow>
    <SingleFolderFourth onClick={(e) => { setDropSortMode("newest") }} selected={dropSortMode == "newest" && true}>
    Newest
    </SingleFolderFourth>
    <SingleFolderFourth onClick={(e) => { setDropSortMode("oldest") }} selected={dropSortMode == "oldest" && true}>
    Oldest
    </SingleFolderFourth>
    <SingleFolderFourth onClick={(e) => { setDropSortMode("ending_soonest") }} selected={dropSortMode == "ending_soonest" && true}>
    Ending Soonest
    </SingleFolderFourth>
    <SingleFolderFourth onClick={(e) => { setDropSortMode("ending_latest") }} selected={dropSortMode == "ending_latest" && true}>
    Ending Latest
    </SingleFolderFourth>
  </FoldersRow>
</FoldersContainer>


<NewRow />

        
<DropListDiv />




<br/>
<NewRow />
<DaoButton className={loadMoreDisplay} onClick={() => { getNextPage(pageCount, setPageCount, dropList, setDropList) }}>
  Load More
</DaoButton>


<DisabledButton className={loadMoreDisplay != 'hidden' && 'hidden'} >
  No More Results
</DisabledButton>

</AllFarmsCont>
      </NewListingBody>
      </NewListingCont>
      <br/><br/><br/>
    </UALProvider>
 
    </div>
  )
}

export default Drops