import React, { useEffect } from 'react'
import NumberFormat from 'react-number-format';
import { useStateContext } from '../contexts/ContextProvider';
import { currentWebsiteURL } from '../data/config';
import { LOCK_STATUSES } from '../data/constants';
import { PayerDripCardWrapper, ReceiverDetails, ReceiverIDNumber, ReceiverStatus, ReceiverTotal } from '../data/css/EscrowStyles';
import { CreatorLockCardWrapper } from '../data/css/LockerStyles';
import { cancelDrip, depositEscrowFunds, finalizeDrip } from '../data/functions/escrow_functions';
import { cancelLock, depositLockFunds } from '../data/functions/locker_functions';
import { SmallButton } from '../Styles';
const CreatorLockCard = (props) => {
    const item = props.item;

    const { 
        enterModalText,
        setEnterModalText,
        loadingDisplay, 
        setLoadingDisplay, 
        enterButtonsDisplay,
        setEnterButtonsDisplay,
        enterModalDisplay, 
        setEnterModalDisplay,
      } = useStateContext();

  return (
    <CreatorLockCardWrapper>
        <ReceiverIDNumber>ID: {item.ID}</ReceiverIDNumber>
        <ReceiverStatus>
        Status:                     {
                      LOCK_STATUSES[
                        LOCK_STATUSES.findIndex(
                          (attributeIndex) =>
                            attributeIndex.value === item.status
                        )
                      ].display_text
                    }
        </ReceiverStatus>   

        <ReceiverDetails>
        Details: {item.amount}@{item.token_contract} will be released to <a href={`https://waxblock.io/account/${item.receiver}`} target="none">{item.receiver}</a> on {new Date(item.unlock_time * 1000).toLocaleDateString()}
        </ReceiverDetails>

        <br/><br/>

        <div className='flex justify-around w-100'>
        <a href={`${currentWebsiteURL}/locks/${item.ID}`}>
                    <SmallButton>
                        View
                    </SmallButton>
                    </a>

            {item.status == 0 && (
                <SmallButton onClick={() => {depositLockFunds(item.ID, item.amount, item.token_contract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}}>
                    Deposit
                </SmallButton>
            )}

            {item.status == 0 && (
                <SmallButton onClick={() => {cancelLock(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}} >
                    Cancel
                </SmallButton>
            )}

        </div>

    </CreatorLockCardWrapper>
  )
}

export default CreatorLockCard