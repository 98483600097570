import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Api, JsonRpc } from "eosjs";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  DropDescription,
  DropBody,
  UnboxTemplate,
  DropPageAdBannerCont,
  NewRow,
} from "../../components/LeftDiv";
import NumberFormat from "react-number-format";
var thisCollection;
var list = [];
var list2 = [];
import { Name, raw } from "eos-common";
import ReactMarkdown from "react-markdown";
import { currentWebsiteURL, wombat } from "../../data/config";
import CurrentBannerAd from "../../components/CurrentBannerAd";
import {
  currentApiList,
  currentAtomicApiList,
  anchor,
  wax,
  myChain,
} from "../../data/config";
import {
  purchaseDrop,
  claimDrop,
  claimOIGDrop,
  claimBohn,
} from "../../data/functions/DropFunctions";
import { MainButton, NewListingBody, NewListingCont, TallFiltersCont, TallFiltersInnerCont } from "../../Styles";
import LoadingPage from "../../components/LoadingPage";
import Error from "../../components/Error";
import DropPageNFTCard from "../../components/DropPageNFTCard";
import { getAssetQuantity, getAssetSymbol } from "../../data/functions/wallet_functions";
import { getPrecisionFromAsset, getTokenNameFromAsset } from "../../data/functions/pool_functions";

const OIGDrop = ({ location }) => {
  const {
    dropPrice,
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay
  } = useStateContext();

  return (
    <div id="seo">
      <Helmet>
        <title>OIG Drop Details</title>
        <meta
          name="description"
          content={`Claim your free NFT for voting in the OIG election`}
        />
        <link rel="canonical" href={`https://waxdao.io/oig`} />
      </Helmet>

      <UALProvider
        chains={[myChain]}
        authenticators={[anchor, wax, wombat]}
        appName={"WaxDAO"}
      >
        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <GameButton onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </GameButton>
        </Modal>

        <NewListingCont>
          <NewListingBody>
            <DropPageAdBannerCont>
              <CurrentBannerAd />
            </DropPageAdBannerCont>

        <TallFiltersCont>
          <h2>Free NFT For OIG Voters!</h2>
          <p>Created By <a href={`${currentWebsiteURL}/collection/shiftybearsx`}>shiftybearsx</a></p>
        </TallFiltersCont>


          <TallFiltersCont>
            <span className="pl-3 italic">
            Note: This is a whitelisted drop. Only people who vote in the OIG election can claim a free NFT.
            </span>
          </TallFiltersCont>


        <div className="w-100 flex text-center justify-center mt-4">
           <DropPageNFTCard dropData="oig" templateData="" /> 
        </div>

        <TallFiltersCont>
          <h2>Description</h2>
          <br/>
          <TallFiltersInnerCont>
            <ReactMarkdown>
              Anyone who votes in the [current OIG election](https://oig.wax.io/candidates) gets to claim 1 free NFT. No strings attached!

              You can vote for any candidate you like.
            </ReactMarkdown>
          </TallFiltersInnerCont>
        </TallFiltersCont>

        <TallFiltersCont>
        <div className="w-100 flex text-center justify-center pl-4 pt-3">
            <MainButton
                onClick={() => {
                claimOIGDrop(
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterButtonsDisplay,
                    setEnterModalDisplay
                );
                }}
            >
                Claim NFT
            </MainButton>
            {/* 
<NewRow />
            <MainButton
                onClick={() => {
                claimBohn(
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterButtonsDisplay,
                    setEnterModalDisplay
                );
                }}
            >
                Claim Bohn
            </MainButton>    
             */}        
            </div>
        </TallFiltersCont>

          </NewListingBody>
        </NewListingCont>
      </UALProvider>
      <br />
      <br />
      <br />
    </div>
  )
};

export default OIGDrop;
