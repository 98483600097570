import styled from 'styled-components'


<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>

//COLORS
//#c30000
//#47158e
//#dc0bb6

export const InfoLabel = styled.label`
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    vertical-align: middle;
   
`