import { useState } from "react";
import { currentApiList, currentUsername, getCurrentApiList } from "../../data/config";
import axios from 'axios';
import { Name } from "eos-common";

export const useGetBannedUsers = () => {
    
    const [bannedUsers, setBannedUsers] = useState([]);
    const [bannedUsersAreLoading, setBannedUsersAreLoading] = useState(true);
    const [bannedUsersWereLoaded, setBannedUsersWereLoaded] = useState(false);

    const getBannedUsers = (FarmName) => {
    
        axios
        .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
          table: "blacklist",
          scope: FarmName,
          code: "waxdaofarmer",
          limit: 1000,
          json: true,
        })
        .then((res) => {
            setBannedUsers(res.data.rows);
            setBannedUsersWereLoaded(true);
        })
        .catch((error) => console.log(error))
        .finally(() => setBannedUsersAreLoading(false))

    }

    return [bannedUsers, getBannedUsers, bannedUsersAreLoading, bannedUsersWereLoaded]
}


