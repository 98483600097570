import { currentUsername, getCurrentAtomicApiList } from "../config";
import { getWalletSession } from "./wallet_functions";
import axios from "axios";

let pageSize = 100;

export const handleRemoveAsset = (asset_id, assetVector, setAssetVector) => {
    const assetList = [...assetVector];
    assetList.splice(assetList.indexOf(asset_id), 1);
    setAssetVector(assetList);
}

export const handleAddAsset = (asset_id, assetVector, setAssetVector) => {
        setAssetVector([...assetVector, asset_id])   
}

export const handleRemovingFromRemoval = (asset_id, removalVector, setRemovalVector) => {
  const assetList = [...removalVector];
  assetList.splice(assetList.indexOf(asset_id), 1);
  setRemovalVector(assetList);
}

export const handleAddingToRemoval = (asset_id, removalVector, setRemovalVector) => {
      setRemovalVector([...removalVector, asset_id])  
}

export const addToPremintPool = async (PoolID, assetVector, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];
  
    try {

      const action = [{
            account: 'atomicassets',
            name: 'transfer',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
            to: 'waxdaomarket',
            from: this_user,
            asset_ids: assetVector,
            memo: '|premint_deposit|' + PoolID + '|',
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your assets have been added');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {

            setEnterModalText(JSON.stringify(e.message));

      console.log(e)
      
    }

  } //end addToPremintPool

export const removeFromPremintPool = async (PoolID, removalVector, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];
  
  
    try {
      const action = [{
            account: 'waxdaomarket',
            name: 'removeassets',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
            pool_id: PoolID,
            user: this_user,
            assets_to_remove: removalVector,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your assets have been returned to your wallet');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)
      
    }
  
  } //end removeFromPremintPool


export const getNextPage = (pageCount, setPageCount, stakedAssets, setStakedAssets, totalAssets, listOfAssets, setLoadMoreDisplay ) => {

    axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?ids=${listOfAssets.slice((pageCount * 100) - 100,pageCount * 100).join("%2C")}&page=${`1`}&limit=100&order=desc&sort=transferred`)
    .then((validResponse) => {
      let list = [...stakedAssets];

      if(Array.isArray(stakedAssets[0])){
        list = stakedAssets[0];
      }

      list = list.concat(validResponse.data.data);

      setStakedAssets(list);

      if(pageCount * pageSize < totalAssets){

        setPageCount(pageCount + 1);

      } else { setLoadMoreDisplay('hidden'); }
     
    })
    .catch((error) => console.log(error));
  
}

export const getNextUnstakedPage = (unstakedPageCount, setUnstakedPageCount, unstaked, setUnstaked, setUnstakedLoadMoreDisplay, newCollection) => {
  if(!currentUsername){return;}

  let thisPage = unstakedPageCount;

  if(newCollection == ""){
    axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?owner=${currentUsername}&page=${thisPage}&limit=100&order=desc&sort=transferred`)
    .then((atomicResponse) => {
      let list = [...unstaked];
      list = list.concat(atomicResponse.data.data);
      setUnstaked(list);
      
      if(atomicResponse.data.data.length >= 100){
        setUnstakedPageCount(thisPage + 1);
      } else { setUnstakedLoadMoreDisplay('hidden'); }
      
    })
    .catch((error) => console.log(error));
  }
  else{
    axios.get(`${currentAtomicApiList[Math.floor(Math.random() * (1 - 0) ) + 0]}/atomicassets/v1/assets?collection_name=${newCollection}&owner=${currentUsername}&page=${thisPage}&limit=100&order=desc&sort=transferred`)
    .then((atomicResponse) => {
  
      let list = [...unstaked];
      list = list.concat(atomicResponse.data.data);
      setUnstaked(list);

      if(atomicResponse.data.data.length >= 100){
        setUnstakedPageCount(thisPage + 1);
      } else { setUnstakedLoadMoreDisplay('hidden'); }
    })
    .catch((error) => console.log(error));
  }  
}

export const switchCollection = (unstakedPageCount, setUnstakedPageCount, unstaked, setUnstaked, setUnstakedLoadMoreDisplay, setNewCollection, newCollection) => {
  setUnstaked([]);

  if(!currentUsername){return;}

  let thisPage = 1;
  setNewCollection(newCollection);

    axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${newCollection}&owner=${currentUsername}&page=${thisPage}&limit=100&order=desc&sort=asset_id`)
    .then((atomicResponse) => {
  
      setUnstaked(atomicResponse.data.data);


      if(atomicResponse.data.data.length >= 100){

        setUnstakedPageCount(2);
        setUnstakedLoadMoreDisplay("");
      } else { setUnstakedLoadMoreDisplay('hidden'); }
      
    })
    .catch((error) => console.log(error));
}

export const createPremintPool = async (
  displayName,
  collection_name,
  setEnterModalText,
  setLoadingDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    const action = [
      {
        account: "waxdaomarket",
        name: "createpool",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          collection: collection_name,
          display_name: displayName,
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 300,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your pool has been created");
      setLoadingDisplay("none");
    }, 2000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    console.log(e);
  }
}; //end createPremintPool