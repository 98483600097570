import styled from 'styled-components'
import { currentTheme, testTheme, theme } from '../../Styles'


export const AdCardBeginDate = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 20px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${testTheme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const AdCardBeginDateLine1 = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 45px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${testTheme.textMain};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const AdCardBeginDateLine2 = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 200;
    top: 65px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${testTheme.textSecondary};
    font-style: italic;

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const AdCardEndDate = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 120px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${testTheme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const AdCardEndDateLine1 = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 145px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${testTheme.textMain};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const AdCardEndDateLine2 = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 200;
    top: 165px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${testTheme.textSecondary};
    font-style: italic;

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`