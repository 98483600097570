import { getWalletSession } from "./wallet_functions";


export const redeemSynth = async (amount, symbol, precision, receiver, memo, setEnterModalText, setLoadingDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    var amount_to_transfer;
    var response_message;
    

    if(Number(precision) == 0){
        amount_to_transfer = Math.round(Number(amount)) + " " + symbol;
    } else {
        amount_to_transfer = Number(amount).toFixed(Number(precision)) + " " + symbol;
    }

    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];

    var contract_account = "waxdaosynths";
    var response_message = "Your tokens have been redeemed! Check bloks to verify that the tx is confirmed";


        try {
            const action = [{
              account: contract_account,
              name: 'transfer',
              authorization: [{
                actor: this_user,
                permission: this_permission,
            }],
            data: {
                from: this_user,
                to: receiver,
                quantity: amount_to_transfer,
                memo: memo,
            }
          }]
            const response = await session[0].signTransaction({
              actions: action
            }, {
      
              blocksBehind: 3,
              expireSeconds: 300,
              broadcast: true,
            })
            setLoadingDisplay('');
            setEnterModalText('Processing your transaction...')
            const timer = setTimeout(() => {
              
                setEnterModalText(response_message);
                setLoadingDisplay('none');
          
            }, 1000);
            return () => clearTimeout(timer);
        
          } catch(e) {
                setEnterModalText(JSON.stringify(e.message));
                console.log(e)
          }

  }//end redeemSynth

  export const createSynth = async (synthTokenName, underlyingAssets, setEnterModalText, setLoadingDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');


    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];

    var contract_account = "waxdaosynths";
    var response_message = "Your new synth has been created!";

    var asset_index = 0;
    var final_asset_vector = [];
    for(var i = 0; i < underlyingAssets.length; i++){
        if(Number(underlyingAssets[asset_index].token_symbol.substring(0, underlyingAssets[asset_index].token_symbol.indexOf(","))) == 0){
            final_asset_vector = [...final_asset_vector, {"amount": Math.round(underlyingAssets[asset_index].amount) + " " + underlyingAssets[asset_index].token_symbol.substring(underlyingAssets[asset_index].token_symbol.indexOf(",") + 1), "contract":underlyingAssets[asset_index].contract}];
        } else {
            final_asset_vector = [...final_asset_vector, {"amount": Number(underlyingAssets[asset_index].amount).toFixed(underlyingAssets[asset_index].token_symbol.substring(0, underlyingAssets[asset_index].token_symbol.indexOf(","))) + " " + underlyingAssets[asset_index].token_symbol.substring(underlyingAssets[asset_index].token_symbol.indexOf(",") + 1), "contract":underlyingAssets[asset_index].contract}];
        }
        asset_index ++;

        console.log(final_asset_vector)
    }


        try {
            const action = [{
              account: contract_account,
              name: 'newsynth',
              authorization: [{
                actor: this_user,
                permission: this_permission,
            }],
            data: {
                user: this_user,
                token_symbol: "8," + synthTokenName,
                basket_of_assets: final_asset_vector,
            }
          }]
            const response = await session[0].signTransaction({
              actions: action
            }, {
      
              blocksBehind: 3,
              expireSeconds: 300,
              broadcast: true,
            })
            setLoadingDisplay('');
            setEnterModalText('Processing your transaction...')
            const timer = setTimeout(() => {
              
                setEnterModalText(response_message);
                setLoadingDisplay('none');
          
            }, 4000);
            return () => clearTimeout(timer);
        
          } catch(e) {
                setEnterModalText(JSON.stringify(e.message));
                console.log(e)
          }

  }//end createSynth


  export const buySynth = async (amount, synthToBuy, receiver, memo, setEnterModalText, setLoadingDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    var amount_to_transfer;
    var response_message;
    

    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];

    var contract_account = "waxdaosynths";
    var response_message = "Your order has gone through. Check bloks to verify that the tx is confirmed";

    var symbol = synthToBuy.substring(0, synthToBuy.indexOf("|"));

    var current_ingredient_index = 0;
    var synth_actions = [];
    var ingredient_list = [...Array.from(JSON.parse(synthToBuy.substring(synthToBuy.indexOf("|") + 1)))];
    
    while(current_ingredient_index < ingredient_list.length){

            var THIS_SYMBOL = ingredient_list[current_ingredient_index].amount.substring(ingredient_list[current_ingredient_index].amount.indexOf(" ") + 1);
            var THIS_AMOUNT = Number(ingredient_list[current_ingredient_index].amount.substring(0, ingredient_list[current_ingredient_index].amount.indexOf(" "))) * Number(amount).toFixed(8);
            var THIS_PRECISION; 
            if(ingredient_list[current_ingredient_index].amount.indexOf(".") > -1){
                THIS_PRECISION = Number(ingredient_list[current_ingredient_index].amount.substring(ingredient_list[current_ingredient_index].amount.indexOf(".") + 1, ingredient_list[current_ingredient_index].amount.indexOf(" ")).length);
            } else {
                THIS_PRECISION = 0;
            }

            var THIS_AMOUNT_TO_TRANSFER = THIS_AMOUNT.toFixed(THIS_PRECISION) + " " + THIS_SYMBOL;
            
            console.log("symbol: " + THIS_SYMBOL);
            console.log("amount: " + THIS_AMOUNT);
            console.log("precision: " + THIS_PRECISION);
            console.log(THIS_AMOUNT_TO_TRANSFER);
            console.log("contract: " + ingredient_list[current_ingredient_index].contract);

            synth_actions[current_ingredient_index] = {
                account: ingredient_list[current_ingredient_index].contract,
                name: 'transfer',
                authorization: [{
                  actor: this_user,
                  permission: this_permission,
              }],
              data: {
                to: 'waxdaosynths',
                from: this_user,
                quantity: THIS_AMOUNT_TO_TRANSFER,
                memo: '|deposit|' + symbol + '|' + current_ingredient_index + '|',
              }
            }

    
        current_ingredient_index ++;
    }






        try {
            const action = [                {
                account: "waxdaosynths",
                name: 'assertsynth',
                authorization: [{
                  actor: this_user,
                  permission: this_permission,
              }],
              data: {
                  user: this_user,
                  quantity: Number(amount).toFixed(8) + " " + symbol,
              }
            }]

            const final_action = [...action, ...synth_actions]
            const response = await session[0].signTransaction({
              actions: final_action
            }, {
      
              blocksBehind: 3,
              expireSeconds: 300,
              broadcast: true,
            })
            setLoadingDisplay('');
            setEnterModalText('Processing your transaction...')
            const timer = setTimeout(() => {
              
                setEnterModalText(response_message);
                setLoadingDisplay('none');
          
            }, 4000);
            return () => clearTimeout(timer);
        
          } catch(e) {
                setEnterModalText(JSON.stringify(e.message));
                console.log(e)
          }

  }//end buySynth