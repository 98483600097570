import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen } from '../../components/LeftDiv';
import { wax, anchor, myChain, getCurrentApiList, currentUsername, wombat } from '../../data/config';
import { claimRewards, getHourlyReward, getPoolSize, getRewardToken, getStakeAllAmountUnformatted, getTokenPrecision, getTokenToStake, getTotalStaked, roundWalletBalance, stakeTokens, unstakeAllTokens, unstakeTokens } from '../../data/functions/pool_functions';
import { PoolLineBreak, SinglePoolDetail, SinglePoolDetailBold } from '../../data/css/PoolPageStyles';
import { CloseModalButton, ExploreFiltersCont, ExploreFiltersInnerCont, FoldersContainer, FoldersRow, MainButton, MilestoneContainer, MilestoneDescription, NewListingBody, SingleFolderThird } from '../../Styles';
import { CollectionProfileAvatarCont, CollectionProfileBGContainer, CollectionProfileContainer, MilestoneBoldWhite } from '../../data/css/CollectionPageStyles';
import collection_profile_stock_bg from '../../data/collection_profile_stock_bg.png'
import { resizerPrefix, resizerSuffix } from '../../data/constants';
import LoadingPage from '../../components/LoadingPage';

const PoolPage = ({ location }) => {

  const { enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,  
  
  } = useStateContext();

const { PoolName } = useParams();

const [stakingAmount, setStakingAmount] = useState("");
const [poolInfo, setPoolInfo] = useState([]);
const [poolInfoIsLoading, setPoolInfoIsLoading] = useState(true);
const [walletBalance, setWalletBalance] = useState([]);
const [stakedBalance, setStakedBalance] = useState("");
const [unlockTime, setUnlockTime] = useState("");
const [poolMemberInfo, setPoolMemberInfo] = useState([]);
const [currentSection, setCurrentSection] = useState("Claim");



useEffect(() => {

  const abortCont = new AbortController();
  const abortCont2 = new AbortController();
  const abortCont3 = new AbortController();
  const abortCont4 = new AbortController();


  axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
    table:"pools",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    indexName: 'poolname',
    limit:1,
    lower_bound:PoolName,
    upper_bound:PoolName,
    json:true,
    signal: abortCont.signal
  })
    .then((response) => {
      setPoolInfo(response.data.rows[0]);
      console.log(response.data.rows[0])

      let this_token = getTokenToStake(response.data.rows[0]);

      if(currentUsername){

        axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
          table:"accounts",
          scope:currentUsername,
          code:response.data.rows[0].stkcontract,
          limit:50,
          json:true,
          signal: abortCont3.signal
        }).then((poolResponse) => {

          let i = 0;
          while( i < poolResponse.data.rows.length ){
            if( poolResponse.data.rows[i].balance.substring(poolResponse.data.rows[i].balance.indexOf(" ") + 1) == this_token ){
              setWalletBalance(poolResponse.data.rows[i]);
              break;
            }
            i++;
          }

        })
        .catch((error) => console.log(error));
      }



    })
    .catch((error) => console.log(error))
    .finally(() => setPoolInfoIsLoading(false))

    return() => {
      abortCont.abort();
      abortCont2.abort();
      abortCont3.abort();
      abortCont4.abort();
      
    }

}, []);


const [dropTimer, setDropTimer] = useState("");
const [timerWasUpdated, setTimerWasUpdated] = useState(true);

useEffect(() => {
    const timer = setTimeout(() => {
      if (poolMemberInfo.unlocktime != null) {
        let current_time = Date.now() / 1000;
        if (current_time < poolMemberInfo.unlocktime) {
          let total_minutes_left = Math.round(
            Number(poolMemberInfo.unlocktime - current_time) / 60
          );
          let days_left = Math.floor(total_minutes_left / 1440);
          let hours_left =  Math.floor( (total_minutes_left - (days_left * 1440) ) / 60 );
          let minutes_left = total_minutes_left - (days_left * 1440) - (hours_left * 60);
          setDropTimer(days_left + "d " + hours_left + "h " + minutes_left + "m");
        } else {
          setDropTimer("");
        }
      }
      setTimerWasUpdated((prev) => !prev);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [dropTimer, timerWasUpdated]);






useEffect(() => {

  const abortCont5 = new AbortController();

  if(!currentUsername){ return('');}

  axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
    table:"tokens",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    index_position: 2,
    limit:100,
    lower_bound:currentUsername,
    upper_bound:currentUsername,
    json:true,
    signal: abortCont5.signal
  }).then((claimableResponse) => {
    var claimitr = 0;

    while(claimitr < claimableResponse.data.rows.length){
      if(claimableResponse.data.rows[claimitr].poolname == PoolName){
        setPoolMemberInfo(claimableResponse.data.rows[claimitr]);
        break;
      }
      else{
        claimitr ++;
      }
    }


  })

.catch((error) => console.log(error));

return() => abortCont5.abort();

}, []);




if(poolInfo != null && !poolInfoIsLoading){
  return (
    <div id="seo">
    <Helmet>
    <title>{PoolName} Details</title>
    <meta name="description" content={`Stake NFTs in the ${PoolName} pool on WaxDao`} />
    <link rel="canonical" href={`https://waxdao.io/pool/${PoolName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <CloseModalButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </CloseModalButton>
      </Modal>


      <CollectionProfileContainer>
        <CollectionProfileBGContainer>
          {poolInfo.cover_image != null && poolInfo.cover_image.length > 0 ? (
            <img src={`${"https://ipfs.io/ipfs/"}${poolInfo.cover_image}`} style={{objectFit:"cover", width: "100%", height: "100%"}} />
          ) : (
            <img src={collection_profile_stock_bg} style={{width: "auto", height: "auto", minHeight: "100%", minWidth: "100%"}} />
          )}
          
        </CollectionProfileBGContainer>

        <CollectionProfileAvatarCont>
          <img src={poolInfo.logo != null && `${resizerPrefix}${poolInfo.logo}${resizerSuffix}`} style={{width: "auto", height: "auto", minHeight: "100%", minWidth: "100%"}} />
        </CollectionProfileAvatarCont>
      </CollectionProfileContainer>

        <div className='p-5 mt-4 text-white'>
          <br/>
          <span className='text-2xl font-semibold'>
            {poolInfo.poolname != null && poolInfo.poolname}
          </span>
          <br/>
          <span className='text-md'>
            {poolInfo.creator != null && (<span>By {poolInfo.creator}</span>)}
          </span>

      <MilestoneContainer>
        <MilestoneDescription>
        Stake <MilestoneBoldWhite>{getTokenToStake(poolInfo)}</MilestoneBoldWhite> to earn <MilestoneBoldWhite>{getRewardToken(poolInfo)}</MilestoneBoldWhite>
        </MilestoneDescription>
      </MilestoneContainer>

      
      </div>
      
      <NewListingBody>

      <ExploreFiltersCont>
      <h2>Pool Details</h2>

    <br/>
    <ExploreFiltersInnerCont>
      <SinglePoolDetail>
        Hourly Pool <PoolLineBreak><br/></PoolLineBreak><SinglePoolDetailBold>{getHourlyReward(poolInfo)}</SinglePoolDetailBold>
      </SinglePoolDetail>
      <SinglePoolDetail>
        Total Pool <PoolLineBreak><br/></PoolLineBreak><SinglePoolDetailBold>{getPoolSize(poolInfo)}</SinglePoolDetailBold>
      </SinglePoolDetail>
      <SinglePoolDetail>
        Minimum Stake <PoolLineBreak><br/></PoolLineBreak><SinglePoolDetailBold>{poolInfo.minamount} {getTokenToStake(poolInfo)}</SinglePoolDetailBold>
      </SinglePoolDetail>
      <SinglePoolDetail>
        Total Staked <PoolLineBreak><br/></PoolLineBreak><SinglePoolDetailBold>{getTotalStaked(poolInfo)}</SinglePoolDetailBold>
      </SinglePoolDetail>
      <SinglePoolDetail>
        Lock Period <PoolLineBreak><br/></PoolLineBreak><SinglePoolDetailBold>{poolInfo.mintime / 86400} Days</SinglePoolDetailBold>
      </SinglePoolDetail>
      <SinglePoolDetail>
        Expires <PoolLineBreak><br/></PoolLineBreak><SinglePoolDetailBold>{new Date(poolInfo.enddate * 1000).toLocaleDateString()}</SinglePoolDetailBold>
      </SinglePoolDetail>
    </ExploreFiltersInnerCont>
    </ExploreFiltersCont>



      {currentUsername && (
        <span>
        <ExploreFiltersCont>
        <h2>My Info</h2>

        <br/>
        <ExploreFiltersInnerCont>
          <SinglePoolDetail>
            Wallet Balance <PoolLineBreak><br/></PoolLineBreak><SinglePoolDetailBold>{walletBalance.balance != null ? roundWalletBalance(walletBalance.balance) : "0"}</SinglePoolDetailBold>
          </SinglePoolDetail>
          <SinglePoolDetail>
            Staked Balance <PoolLineBreak><br/></PoolLineBreak><SinglePoolDetailBold>{poolMemberInfo.amountstaked != null ? roundWalletBalance(poolMemberInfo.amountstaked) : "0"}</SinglePoolDetailBold>
          </SinglePoolDetail>
          <SinglePoolDetail>
            Unclaimed Rewards <PoolLineBreak><br/></PoolLineBreak><SinglePoolDetailBold>{poolMemberInfo.claimable != null ? roundWalletBalance(poolMemberInfo.claimable) : "0"}</SinglePoolDetailBold>
          </SinglePoolDetail>
          <SinglePoolDetail>
            Unlock Time <PoolLineBreak><br/></PoolLineBreak>
            <SinglePoolDetailBold>
            {dropTimer != "" && dropTimer}
            {poolMemberInfo == null && "N/A"}
            {poolMemberInfo != null && poolMemberInfo?.unlocktime == null && "N/A"}
            {poolMemberInfo?.unlocktime != null && dropTimer == "" && "Unlocked"}
  
              </SinglePoolDetailBold>
          </SinglePoolDetail>
        </ExploreFiltersInnerCont>
        </ExploreFiltersCont>
       

       <ExploreFiltersCont>
        <h2>Actions</h2>

        <br/>
        <FoldersContainer>
            <FoldersRow>
              <SingleFolderThird onClick={(e) => { setCurrentSection("Claim") }} selected={currentSection == "Claim" && true}>
                Claim
              </SingleFolderThird>
              <SingleFolderThird onClick={(e) => { setCurrentSection("Stake") }} selected={currentSection == "Stake" && true}>
                Stake
              </SingleFolderThird>
              <SingleFolderThird onClick={(e) => { setCurrentSection("Unstake") }} selected={currentSection == "Unstake" && true}>
                Unstake
              </SingleFolderThird>
            </FoldersRow>
          </FoldersContainer>
          <br/>
          <ExploreFiltersInnerCont>

          {currentSection == "Claim" && (
            <MainButton
              onClick={() => claimRewards(PoolName, poolInfo.contract, poolInfo.rwdtoken, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
              Claim {poolMemberInfo.claimable != null && roundWalletBalance(poolMemberInfo.claimable)}
            </MainButton>  
          )}

          {currentSection == "Stake" && (
            <span>
              <input placeholder='Amount' value={stakingAmount} onChange={(e) => setStakingAmount(e.target.value)} /> &nbsp;
            <MainButton
              onClick={() => stakeTokens(stakingAmount, poolInfo.stkcontract, getTokenToStake(poolInfo), PoolName, getTokenPrecision(poolInfo), setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
              Stake {stakingAmount != null && stakingAmount.length > 0 && (<span>{stakingAmount} {getTokenToStake(poolInfo)}</span>)}
            </MainButton>  
            &nbsp;
            <MainButton
              onClick={() => stakeTokens(getStakeAllAmountUnformatted(walletBalance.balance), poolInfo.stkcontract, getTokenToStake(poolInfo), PoolName, getTokenPrecision(poolInfo), setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
              Stake All
            </MainButton>  

            </span>
          )}

          {currentSection == "Unstake" && (
            <span>
              <input placeholder='Amount' value={stakingAmount} onChange={(e) => setStakingAmount(e.target.value)} /> &nbsp;
            <MainButton
              onClick={() => unstakeTokens(stakingAmount, getTokenToStake(poolInfo), PoolName, getTokenPrecision(poolInfo), setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
              Unstake {stakingAmount != null && stakingAmount.length > 0 && (<span>{stakingAmount} {getTokenToStake(poolInfo)}</span>)}
            </MainButton>  
            &nbsp;
            <MainButton
              onClick={() => unstakeAllTokens(PoolName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
              Unstake All
            </MainButton>  

            </span>
          )}
          
          


          </ExploreFiltersInnerCont>
          </ExploreFiltersCont>
        </span>
      )}

      </NewListingBody>


    </UALProvider>
    <br/><br/><br/>
    </div>
  )} else{
    return(<LoadingPage />)
  }
}

export default PoolPage