import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { UALProvider } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
} from "../../components/LeftDiv";
import { Name } from "eos-common";
import {
  wax,
  anchor,
  myChain,
  currentAtomicHubLink,
  currentUsername,
  getCurrentApiList,
  wombat,
} from "../../data/config";
import {
  getFarmSocials,
} from "../../data/functions/farm_functions";
import {
  CollectionProfileAvatarCont,
  CollectionProfileBGContainer,
  CollectionProfileContainer,
} from "../../data/css/CollectionPageStyles";
import collection_profile_stock_bg from "../../data/collection_profile_stock_bg.png";
import waxapes_cover from "../../data/waxapes_cover.png";
import {
  FarmDescriptionContainer,
  FoldersContainer,
  FoldersRow,
  MilestoneDescription,
  NewListingBody,
  SingleFolderThird,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import { resizerPrefix, resizerSuffix } from "../../data/constants";

import LoadingPage from "../../components/LoadingPage";
import { isLoggedIn } from "../../data/functions/wallet_functions";
import { useGetV2FarmInfo } from "../../components/CustomHooks/useGetV2FarmInfo";
import Error from "../../components/Error";
import { showEditFarmProfile, showEditFarmRewards, showEditFarmStatus } from "./v2_farm_functions";
import { useGetV2Stakeables } from "../../components/CustomHooks/useGetV2Stakeables";

const EditV2Farm = ({ location }) => {
  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
  } = useStateContext();

  const { FarmName } = useParams();

  const [currentSection, setCurrentSection] = useState("Profile");
  const [currentRewardTab, setCurrentRewardTab] = useState("Stakeables");
  const [currentStakeablesTab, setCurrentStakeablesTab] = useState("View");
  const [collectionToAdd, setCollectionToAdd] = useState("");
  const [schemaToAdd, setSchemaToAdd] = useState("");
  const [showOpenFarmModal, setShowOpenFarmModal] = useState(false);
  const [openOrExtend, setOpenOrExtend] = useState("Open");

  //Custom Hooks
  const [farmData, getFarmData, farmDataIsLoading, farmProfile, farmSocials, setFarmProfile, 
    setFarmSocials, amountsToAdd, setAmountsToAdd, amountsToDeposit, setAmountsToDeposit] = useGetV2FarmInfo();
  const [stakeables, getStakeables, stakeablesAreLoading] = useGetV2Stakeables();

  useEffect(() => {
    if(!isLoggedIn()){
        return;
    }
    getFarmData(FarmName)
  }, [])
  


  if (!farmDataIsLoading && farmData != null && farmData.creator == currentUsername) {
    return (
      <div id="seo">
        <Helmet>
          <title>{FarmName} Details</title>
          <meta
            name="description"
            content={`Stake NFTs in the ${FarmName} farm on WaxDao`}
          />
          <link rel="canonical" href={`https://waxdao.io/v2/farm/${FarmName}`} />
        </Helmet>

        <UALProvider
          chains={[myChain]}
          authenticators={[anchor, wax, wombat]}
          appName={"WaxDAO"}
        >
          <Modal className={enterModalDisplay}>
            <ModalContent>{enterModalText}</ModalContent>

            <Spinner style={{ display: loadingDisplay }}>
              <SpinnerRed />
              <SpinnerBlue />
              <SpinnerGreen />
            </Spinner>

            <br />
            <GameButton onClick={() => setEnterModalDisplay("hidden")}>
              Close Window
            </GameButton>
          </Modal>

          <CollectionProfileContainer>
            <CollectionProfileBGContainer>
              {farmProfile?.cover_image != null && farmProfile?.cover_image.length > 0 ? (
                <img
                  src={`${resizerPrefix}${farmProfile?.cover_image}`}
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={collection_profile_stock_bg}
                  style={{
                    width: "auto",
                    height: "auto",
                    minHeight: "100%",
                    minWidth: "100%",
                  }}
                />
              )}
            </CollectionProfileBGContainer>

            <CollectionProfileAvatarCont>
              <img
                src={
                    farmProfile.avatar != null &&
                  `${resizerPrefix}${farmProfile.avatar}${resizerSuffix}`
                }
                style={{
                  width: "auto",
                  height: "auto",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              />
            </CollectionProfileAvatarCont>
          </CollectionProfileContainer>

          <div className="p-5 mt-4 text-white">
            <br />
            <span className="text-2xl font-semibold">{FarmName}</span>
            <br />
            <span className="text-md">
              {farmData.creator != null && <span>By {farmData.creator}</span>}
            </span>

            {farmProfile?.description != null &&
              farmProfile?.description?.length > 3 && (
                <FarmDescriptionContainer>
                  <MilestoneDescription>
                    {farmProfile.description}
                  </MilestoneDescription>
                </FarmDescriptionContainer>
              )}         

              {getFarmSocials(farmSocials)}
          </div>

          <NewListingBody>

            <TallFiltersCont>
                <h2>Manage Farm</h2>
                <br/>
                <FoldersContainer>
                    <FoldersRow>
                        <SingleFolderThird
                            selected={currentSection == "Profile" && true}
                            onClick={() => setCurrentSection("Profile")}
                        >
                            Profile
                        </SingleFolderThird>

                        <SingleFolderThird
                            selected={currentSection == "Status" && true}
                            onClick={() => setCurrentSection("Status")}                        
                        >
                            Farm Status
                        </SingleFolderThird>     

                        <SingleFolderThird
                            selected={currentSection == "Rewards" && true}
                            onClick={() => {
                              setCurrentSection("Rewards")
                              getStakeables(farmData.farm_type, FarmName)
                            }}                        
                        >
                            Rewards
                        </SingleFolderThird>                                                
                    </FoldersRow>
                </FoldersContainer>
                <br/>
                <TallFiltersInnerCont>
                    {currentSection == "Profile" && (
                        showEditFarmProfile(FarmName, farmProfile, setFarmProfile, farmSocials, setFarmSocials,     setEnterModalText,
                            setLoadingDisplay,
                            setEnterButtonsDisplay,
                            setEnterModalDisplay)
                    )}

                    {currentSection == "Status" && (
                        showEditFarmStatus(farmData, showOpenFarmModal, setShowOpenFarmModal,
                          setEnterModalDisplay, setEnterModalText, setLoadingDisplay, openOrExtend, setOpenOrExtend,
                          setEnterButtonsDisplay)
                    )}

                    {currentSection == "Rewards" &&  (
                      showEditFarmRewards(farmData, currentRewardTab, setCurrentRewardTab, stakeables, 
                        stakeablesAreLoading, currentStakeablesTab, setCurrentStakeablesTab,
                        collectionToAdd, setCollectionToAdd, schemaToAdd, setSchemaToAdd,
                        amountsToAdd, setAmountsToAdd, setEnterModalText,
                        setLoadingDisplay,
                        setEnterButtonsDisplay,
                        setEnterModalDisplay, amountsToDeposit, setAmountsToDeposit)
                    )}
                </TallFiltersInnerCont>
            </TallFiltersCont>
        
          </NewListingBody>
        </UALProvider>
        <br />
        <br />
        <br />
      </div>
    );
  } else if(!isLoggedIn()){
    return <Error error="You need to log in to view this page" />
  } else if(isLoggedIn() && !farmDataIsLoading && currentUsername != farmData.creator){
    return <Error error={`Only ${farmData.creator} can modify this page`} />
  } else if(farmDataIsLoading){
    return <LoadingPage />
  } else if(!farmDataIsLoading && farmData == null){
    return <Error error="Farm could not be located" />
  }
  
  
  else {
    return <LoadingPage />;
  }
};

export default EditV2Farm;
