import styled from 'styled-components'


<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>

export const BlogCard = styled.div`
    border: 1px solid dimgrey;
    border-radius: 15px;
    width: 300px;
    max-width: 95%;
    padding:20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    flex-wrap: wrap;
    justify-content: center;
    height: 420px;
    text-align: center;

    :hover{


    }

    @media (max-width: 900px) {

    }
    
`

export const AdminPanelButton = styled.button`

    font-size: 12px;
    font-weight: 500;
    //color: #bcbcbc;
    background-color: transparent;
    //border: 1px solid #bcbcbc;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px;
    height: 40px;
    margin-left: 1em;

    :hover{
        background-color: #161616;
        //color: white;
        //border: 1px solid white;
    }



`

export const SubmitBlogButton = styled.button`

    font-size: 12px;
    font-weight: 500;
    //color: #bcbcbc;
    background-color: transparent;
    //border: 1px solid #bcbcbc;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;

    :hover{
        background-color: #161616;
        //color: white;
        //border: 1px solid white;
    }



`

export const BlogTitleCont = styled.div`
    width: 100%;
    max-width: 100%;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    color: white;
    font-size: 12px;
    font-weight: 500;
    text-align: center;

`

export const BlogTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    text-align: center;

`

export const BlogThumbnailCont = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    //margin-top: 5px;
    color: white;
    text-align: center;
    background-color: #4e4b4b45;
    border-radius: 10px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;
`

export const BlogThumbnail = styled.img`
    display: inline-block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    text-align: center;
    border-radius: 10px;


`

export const BlogDate = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    text-align: center;

`

export const RemoveBlogButton = styled.button`
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border: 1px solid #bcbcbc;
    color: #bcbcbc;
    border-radius: 5px;
    padding: 0.5em;
    font-size: 14px;
    :hover{
        //background-color: #1c1c1c;
        border-color: #c30000;
        color: #c30000;
    }

`

export const BlogContainer = styled.div`
    text-align: left;
    color: white;
    
    h2{
        text-align: center;
        font-size: 24px;
        font-weight: 900;
    }

    p{
        text-align: left;
        margin-top: 1em;
    }
`

export const BlogBody = styled.div `
    width: 95%;
    color: #bcbcbc;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 1em;

    h2{
        text-align: left;
        margin-top: 1em;
        color: white;
    }

    h3{
        text-align: left;
        margin-top: 1em;
        color: white;
        font-size: 18px;
    }

    a{
        color: #c30000;
    }

    ul{
        text-align: left;
        list-style-type: circle;
        padding-left: 2em;
        margin-top: 0.5em;
    }
`

export const BlogFooter = styled.div `
    width: 95%;
    color: #bcbcbc;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 1em;
    font-weight: normal;
    font-style: italic;

    h2{
        text-align: left;
        margin-top: 1em;
        color: white;
    }

    a{
        color: #c30000;
    }
`

