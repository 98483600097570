import { useState } from 'react';
import axios from 'axios';
import { currentAtomicApiList } from '../../data/config';

const useGetRequiredNfts = () => {
  const [nfts, setNfts] = useState([]);
  const [loadingStates, setLoadingStates] = useState({});
  const [fetchAttempts, setFetchAttempts] = useState({});

  const getRequiredNfts = async (
    waxUsername,
    index,
    filter_by,
    collection_name,
    schema_name,
    templateID,
    attributes
  ) => {
    setLoadingStates(prevState => ({ ...prevState, [index]: true }));
    setFetchAttempts(prevState => ({ ...prevState, [index]: (prevState[index] || 0) + 1 }));
    let list = [];
    for (const api of currentAtomicApiList) {
      try {
        let url;
        switch (filter_by) {
          case 0: // collection
            url = `${api}/atomicassets/v1/assets?collection_name=${collection_name}&owner=${waxUsername}&page=1&limit=100&order=desc&sort=transferred`;
            break;
          case 1: // schema
            url = `${api}/atomicassets/v1/assets?collection_name=${collection_name}&schema_name=${schema_name}&owner=${waxUsername}&page=1&limit=100&order=desc&sort=transferred`;
            break;
          case 2: // template
            url = `${api}/atomicassets/v1/assets?collection_name=${collection_name}&template_id=${templateID}&owner=${waxUsername}&page=1&limit=100&order=desc&sort=transferred`;
            break;
          default:
            throw new Error('Invalid filter');
        }
        const atomicResponse = await axios.get(url);
        if (atomicResponse?.data?.data) {
          list = atomicResponse.data.data;
          // Update state with new NFTs, keep old ones
          setNfts(prevNfts => {
            // Check if index already exists
            const existingIndex = prevNfts.findIndex(nft => nft.index === index);
            if (existingIndex !== -1) {
              // Update the existing object
              return prevNfts.map(nft =>
                nft.index === index ? { ...nft, nfts: list } : nft
              );
            } else {
              // Add a new object
              return [...prevNfts, { index, nfts: list }];
            }
          });
          break; // Exit loop as a successful call has been made.
        }
      } catch (error) {
        console.error(`Error on API ${api}: `, error);
      }
    }
    setLoadingStates(prevState => ({ ...prevState, [index]: false }));
  };

  console.log(nfts)
  return [nfts, getRequiredNfts, loadingStates, fetchAttempts];
};

export default useGetRequiredNfts;
