import { getWalletSession } from "./wallet_functions";

export const isClaimable = (drip) => {
    let secondsBetweenPayouts = drip.hours_between_payouts * 3600;
    if(drip.last_claim + secondsBetweenPayouts < Date.now() / 1000){
        return true;
    } else {
        return false;
    }
}

export const getNextClaim = (drip) => {
    let secondsBetweenPayouts = drip.hours_between_payouts * 3600;
    if(drip.last_claim + secondsBetweenPayouts > Date.now() / 1000){
        return Math.round(( (drip.last_claim + secondsBetweenPayouts) - (Date.now() / 1000) ) / 60);
    } else {
        return null;
    }
}

export const handleEscrowFilter = (textToFind, escrowFilters, setEscrowFilters) => {
    const list = [...escrowFilters];
    if(list.indexOf(textToFind) > -1){
        list.splice(list.indexOf(textToFind), 1);
        setEscrowFilters(list);
    } else {
        setEscrowFilters([...list, textToFind]);
    }
}

export const createDrip = async (receivingAccount, token_contract, payout_amount, precision, token_name, timestamp, hours_between_payouts, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    let walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2];

    let amountToSend = Number(payout_amount).toFixed(Number(precision)) + " " + token_name.toUpperCase();

    try {

      const action = [{
        account: 'waxdaoescrow',
        name: 'createdrip',
        authorization: [{
          actor: this_user,
          permission: this_permission,
        }],
        data: {
            payer: this_user,
            receiver: receivingAccount,
            token_contract: token_contract,
            payout_amount: amountToSend,
            end_time: timestamp,
            hours_between_payouts: hours_between_payouts,
        }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 90,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {

          setEnterModalText('Your slow drip has been created. To deposit the funds, go to waxdao.io/manage-escrow');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');

      }, 2000);
      return () => clearTimeout(timer);

    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)

    }

} //end createDrip

export const finalizeDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    let walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2];

    try {

      const action = [{
            account: 'waxdaoescrow',
            name: 'finalizedrip',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              ID: ID,
              payer: this_user,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 90,
        broadcast: true,
      })

      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
          setEnterModalText('Your drip has been finalized');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
      }, 2000);
      return () => clearTimeout(timer);

    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)

    }

  } //end finalizeDrip

export const cancelDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    let walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2];


    try {
      const action = [{
            account: 'waxdaoescrow',
            name: 'canceldrip',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              ID: ID,
              payer: this_user,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 90,
        broadcast: true,
      })

      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {

          setEnterModalText('Your drip has been cancelled');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');

      }, 4000);
      return () => clearTimeout(timer);

    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)

    }

  } //end cancelDrip  


export const claimDrip = async (ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    let walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2];

    try {

      const action = [{
            account: 'waxdaoescrow',
            name: 'claimdrip',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              ID: ID,
              receiver: this_user,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {

          setEnterModalText('Your claim was successful');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');

      }, 2000);
      return () => clearTimeout(timer);

    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)

    }

  } //end claimDrip

  export const depositEscrowFunds = async (ID, amount, contract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");

    let walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2];

    try {

      const action = [{
            account: contract,
            name: 'transfer',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              from: this_user,
              to: 'waxdaoescrow',
              quantity: amount,
              memo: "|drip_deposit|" + ID + "|",
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {

        blocksBehind: 3,
        expireSeconds: 90,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {

          setEnterModalText('Your deposit was successful');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');

      }, 2000);
      return () => clearTimeout(timer);

    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)

    }

  } //end depositEcrowFunds