import axios from "axios";
import { currentUsername, getCurrentApiList, getCurrentAtomicApiList, v1MarketContract } from "../config";
import { getWalletSession } from "./wallet_functions";

export const getNextStakedPage = (
    pageCount,
    setPageCount,
    stakedAssets,
    setStakedAssets,
    totalAssets,
    listOfAssets,
    detailedList,
    pageSize,
    setLoadMoreDisplay,
  ) => {
    axios
      .get(
        `${getCurrentAtomicApiList}/atomicassets/v1/assets?ids=${listOfAssets.slice(pageSize * pageCount, pageCount * pageSize + pageSize).join(
          "%2C"
        )}&limit=100&order=desc&sort=transferred`
      )
      .then((validResponse) => {
        let list = [...stakedAssets];
        console.log("list")
        console.log(list)
        console.log("detailed list")
        console.log(detailedList)


        if (Array.isArray(stakedAssets[0])) {
          list = stakedAssets[0];
        }

        let x = 0;
        if (validResponse.data.data.length > 0) {
          while (x < validResponse.data.data.length) {
            validResponse.data.data[x].waxdao_farm_name =
              detailedList[
                detailedList.findIndex(
                  (attributeIndex) =>
                    attributeIndex.asset_id ===
                    validResponse.data.data[x].asset_id
                )
              ]?.farmname;
            validResponse.data.data[x].waxdao_unlock_time =
              detailedList[
                detailedList.findIndex(
                  (attributeIndex) =>
                    attributeIndex.asset_id ===
                    validResponse.data.data[x].asset_id
                )
              ]?.unlock_time;
            x++;
          }
        }

        list = list.concat(validResponse.data.data);

        setStakedAssets(list);

        if (pageCount * pageSize < totalAssets) {
          setPageCount(pageCount + 1);
        } else {
          setLoadMoreDisplay("hidden");
        }
      })
      .catch((error) => console.log(error))

  };


export const getFirstStakedPage = (user, setDetailedList, setStakedAssets, setListOfAssets, setTotalAssets, setPageCount, setLoadMoreDisplay, setFirstStakedPageWasLoaded) => {
    axios
    .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
      table: "stakebackup",
      scope: "waxdaofarmer",
      code: "waxdaofarmer",
      key_type: "name",
      index_position: 2,
      limit: 100,
      lower_bound: user,
      upper_bound: user,
      json: true,
    })
    .then((farmVectors) => {
      let itr = 0;
      let list = [];
      let detailed_list = [];

      console.log("Lioading first staked")
      while (itr < farmVectors.data.rows.length) {
        if (farmVectors.data.rows[itr].assets != "") {
          list = list.concat(farmVectors.data.rows[itr].assets);
          let asset_index = 0;
          while (asset_index < farmVectors.data.rows[itr].assets.length) {
            detailed_list = [
              ...detailed_list,
              {
                asset_id: farmVectors.data.rows[itr].assets[asset_index],
                farmname: farmVectors.data.rows[itr].farmname,
                unlock_time: farmVectors.data.rows[itr].unlocktime,
              },
            ];
            asset_index++;
          }

          itr++;
        } else {
          itr++;
        }
      }

      //get the atomicassets data for "list" of assets

      if (list.length >= 1) {
        axios
          .get(
            `${getCurrentAtomicApiList}/atomicassets/v1/assets?ids=${list.slice(0,100).join(
              "%2C"
            )}&page=1&limit=100&order=desc&sort=transferred`
          )
          .then((validResponse) => {
            let x = 0;
            if (validResponse.data.data.length > 0) {
              while (x < validResponse.data.data.length) {
                validResponse.data.data[x].waxdao_farm_name =
                  detailed_list[
                    detailed_list.findIndex(
                      (attributeIndex) =>
                        attributeIndex.asset_id ===
                        validResponse.data.data[x].asset_id
                    )
                  ].farmname;
                validResponse.data.data[x].waxdao_unlock_time =
                  detailed_list[
                    detailed_list.findIndex(
                      (attributeIndex) =>
                        attributeIndex.asset_id ===
                        validResponse.data.data[x].asset_id
                    )
                  ].unlock_time;
                x++;
              }
            }

            setStakedAssets(validResponse.data.data);
            setListOfAssets(list);
            setTotalAssets(Number(list.length));
            setDetailedList(detailed_list);
            setFirstStakedPageWasLoaded(true)
            

            if (list.length > 100) {
              setPageCount(2);
            } else {
              setLoadMoreDisplay("hidden");
            }
          });
      } else {
        setLoadMoreDisplay("hidden");
        setFirstStakedPageWasLoaded(true)
      }
    })
    .catch((e) => console.log(e))
}

export const getFirstUnstakedPage = (user, setUnstaked, setUnstakedPageCount, setUnstakedLoadMoreDisplay, setFirstUnstakedPageWasLoaded) => {
    axios
    .get(
      `${getCurrentAtomicApiList}/atomicassets/v1/assets?owner=${user}&page=1&limit=100&order=desc&sort=transferred`
    )
    .then((validResponse) => {
      setUnstaked(validResponse.data.data);
      setFirstUnstakedPageWasLoaded(true);

      if (validResponse.data.data.length == 100) {
        setUnstakedPageCount(2);
      } else {
        setUnstakedLoadMoreDisplay("hidden");
      }
    })
}

export const switchCollection = (
    user,
    setUnstakedPageCount,
    setUnstaked,
    setUnstakedLoadMoreDisplay,
    setNewCollection,
    newCollection,
    setShowSearchCollections
  ) => {
    setUnstaked([]);
    setShowSearchCollections(false);

    let thisPage = 1;
    setNewCollection(newCollection);

    axios
      .get(
        `${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${newCollection}&owner=${user}&page=${thisPage}&limit=100&order=desc&sort=transferred`
      )
      .then((atomicResponse) => {
        setUnstaked(atomicResponse.data.data);
        console.log(atomicResponse.data.data)

        if (atomicResponse.data.data.length >= 100) {
          setUnstakedPageCount(2);
          setUnstakedLoadMoreDisplay("");
        } else {
          setUnstakedLoadMoreDisplay("hidden");
        }
      })
      .catch((error) => console.log(error));
  };

  export const getNextUnstakedPage = (user, unstakedPageCount, setUnstakedPageCount, unstaked, setUnstaked, setUnstakedLoadMoreDisplay, newCollection) => {
  
  
    if(newCollection.length >= 1){
      axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${newCollection}&owner=${user}&page=${unstakedPageCount}&limit=100&order=desc&sort=transferred`)
      .then((validResponse) => {
        let list = [...unstaked];
  
        if(Array.isArray(unstaked[0])){
          list = unstaked[0];
        }
  
        list = list.concat(validResponse.data.data);
        setUnstaked(list);
  
        if(validResponse.data.data.length == 100){
  
          setUnstakedPageCount(unstakedPageCount + 1);
  
        } else { setUnstakedLoadMoreDisplay('hidden'); }
      
      })
      .catch((error) => console.log(error));
    } else {
      axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?owner=${user}&page=${unstakedPageCount}&limit=100&order=desc&sort=transferred`)
      .then((validResponse) => {
        let list = [...unstaked];
  
        if(Array.isArray(unstaked[0])){
          list = unstaked[0];
        }
  
        list = list.concat(validResponse.data.data);
        setUnstaked(list);
  
        if(validResponse.data.data.length == 100){
  
          setUnstakedPageCount(unstakedPageCount + 1);
  
        } else { setUnstakedLoadMoreDisplay('hidden'); }
      
      })
      .catch((error) => console.log(error));
    }//end else
      
  }


export const handleUserProfileInputChange = (e, userProfile, setUserProfile) => {
    const { name, value } = e.target;
    if (Object.keys(userProfile.profile).includes(name)) {
        setUserProfile(prevState => ({
            ...prevState,
            profile: {
                ...prevState.profile,
                [name]: value
            }
        }));
    } else if (Object.keys(userProfile.socials).includes(name)) {
        setUserProfile(prevState => ({
            ...prevState,
            socials: {
                ...prevState.socials,
                [name]: value
            }
        }));
    }
};


export const submitUserProfile = async (
  profile,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  try {
    const action = [
      {
        account: v1MarketContract,
        name: "setprofile",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          profile: profile.profile,
          socials: profile.socials
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 300,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your profile has been saved");
      setLoadingDisplay("none");
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; //end transferNFTs
