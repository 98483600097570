import { useState } from "react";
import { currentApiList, floorPriceApiProvider, getCurrentApiList, getCurrentAtomicApiList } from "../../data/config";
import axios from 'axios';

export const useGetCollectionFloorPrice = () => {
    
    const [collectionFloorPrice, setCollectionFloorPrice] = useState(0.69);
    const [collectionFloorPriceIsLoading, setCollectionFloorPriceIsLoading] = useState(true);

    const getCollectionFloorPrice = (CollectionName) => {
        axios.get(`${floorPriceApiProvider}/atomicmarket/v1/sales/templates?symbol=WAX&collection_name=${CollectionName}&page=1&limit=100&order=asc&sort=price`)
        .then((response) => {
            if(response.data.data.length > 0){
                if(response.data.data[0].price.amount != null){
                    console.log("Floor price: " + response.data.data[0].price.amount)
                    setCollectionFloorPrice(Number(response.data.data[0].price.amount / 100000000));
                }

            }                    
        })        
        .catch((error) => console.log(error))
        .finally(() => setCollectionFloorPriceIsLoading(false))
    }

    return [collectionFloorPrice, getCollectionFloorPrice, collectionFloorPriceIsLoading]
}