import styled from 'styled-components';
import { theme } from '../../Styles';

export const Form = styled.form`
  @media (min-width: 640px) {
    width: 60%;
    margin: 0 auto;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #1F2A44;
  border-radius: 1rem;
  color: #fff;
`;

export const Label = styled.label`
  width: 100%;
  margin-bottom: 0.5rem;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 2px solid #fff;
  border-radius: 0.25rem;
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 2px solid #fff;
  border-radius: 0.25rem;
  background-color: #1F2A44;
  color: #fff;
`;

export const Option = styled.option`
  background-color: #1F2A44;
  color: #fff;
`;

export const SetttingsRow = styled.div`
  width: 100%;
  border: 1px solid gray;

  h2{
    border-bottom: 1px solid gray;
    color: gray;
    font-size: 14px;
  }
`

export const SettingsColumn = styled.div`
  width: 33.3%;
  border: 1px solid gray;
  display: inline-block;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: ${theme.mainDarker};
`;

export const TableData = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: transparent;
  }
`;


export const V2PropCard = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
`;

export const V2TopLine = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
`;

export const V2Title = styled.h2`
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
`;

export const V2Description = styled.p`
    font-size: 14px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1;
    margin-top: 0px;
    
`;

export const V2BottomLine = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    margin-top: 10px;
`;

export const V2Button = styled.button`
    background: transparent;
    border: 1px solid ${theme.secondary};
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8rem;

`;


export const ChoiceButton = styled.button`

    font-size: 18px;
    font-weight: normal;
    color: ${props => props.selected == true ? theme.secondary : "#696969"};
    background-color: transparent;
    //border: 1px solid #696969;
    border: 1px solid ${props => props.selected == true ? theme.secondary : "#696969"};
    width: 65%;
    max-width: 65%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;

    :hover{
        border: 1px solid ${theme.secondary};
        color: ${theme.secondary};
    }
`

export const SubmitPropV2Button = styled.button`

    font-size: 18px;
    font-weight: normal;
    color: ${props => props.selected == true ? "white" : "#696969"};
    background-color: ${props => props.selected == true ? theme.secondary : "transparent"};
    //border: 1px solid #696969;
    border: 1px solid ${props => props.selected == true ? theme.secondary : "#696969"};
    width: 65%;
    max-width: 65%;
    padding: 5px;
    border-radius: 20px;
    margin-top:10px;

`