import React from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import { Modal } from '../components';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import {Helmet} from "react-helmet";
import { FarmsPageCont, FarmsTitle, Suggestion, SuggestionDeets, SuggestionDesc, SuggestionsCont } from '../components/LeftDiv';
import { currentWebsiteURL, myChain, anchor, wax } from '../data/config';
import { TallFiltersCont } from '../Styles';
import { LEMBAS_ASSETS } from '../data/constants';


const FarmFAQ = () => {
    const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, isOpen, setIsOpen, whitelistStatus, setWhitelistStatus, assetID, setAssetID } = useStateContext();

    return (
      <div id="seo">
      <Helmet>
      <title>FAQ - WaxDAO</title>
      <meta name="description" content="FAQ about how to use WaxDao to create your own NFT farm. Join us on Telegram if you have questions." />
      <link rel="canonical" href="https://waxdao.io/farm-faq" />
      </Helmet>
      <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
      <FarmsPageCont>



        <FarmsTitle>
          NFT Farm FAQ
        </FarmsTitle>
<SuggestionsCont>

  <Suggestion>
    <SuggestionDesc>
    What Is NFT Farming?
    </SuggestionDesc>
    <SuggestionDeets>
    NFT farming is when you stake your NFTs in a pool, and receive staking rewards. When you stake your NFTs on WaxDAO, you accumulate rewards every hour.
    </SuggestionDeets>
  </Suggestion>


  <Suggestion>
    <SuggestionDesc>
    How Much Staking Rewards Will I Receive?
    </SuggestionDesc>
    <SuggestionDeets>
    The reward rate is set by the person who creates the farm. There are 2 farm types- shared pool, and exact amount.
    <br/><br/>
    If you are staking in a "shared pool" farm, then the more NFTs that get staked in the farm, the less rewards each NFT will receive.<br/><br/>
    If the farm type is "exact amount per NFT", then you will receive the same amount of rewards (per NFT) every hour.
    <br/><br/>
    You can view the current staking rates in the "About" section of each individual farm.
    </SuggestionDeets>
  </Suggestion>


  <Suggestion>
    <SuggestionDesc>
    How Can I Create My Own NFT Farm?
    </SuggestionDesc>
    <SuggestionDeets>
    All you need to do is go to the <a href={`${currentWebsiteURL}/create-farm`} >Create Farm page</a> and fill out the form. Then, submit the transaction and your farm will be created.
    <br/><br/>
    There is a tutorial video on that page, so you can reference that if any of the settings confuse you.
    <br/><br/>
    Once your farm is created, you can add tokens to the reward pool from the <a href={`${currentWebsiteURL}/my-farms`}>Manage Farms page.</a>
    </SuggestionDeets>
  </Suggestion>


  <Suggestion>
    <SuggestionDesc>
    How Much Does It Cost?
    </SuggestionDesc>
    <SuggestionDeets>
    There are 3 pricing options for creating a new farm. You can either pay 250 Wax, <a href="https://wax.alcor.exchange/swap?input=WAX-eosio.token&output=WOJAK-mdcryptonfts" target="none">25,000 WOJAK</a>, or burn <a href={`${currentWebsiteURL}/drops/247`}>1 Wojak NFT</a>.
    <br/><br/>
    Since the prices of all of these will fluctuate, we recommend checking current market conditions to figure out which is the cheapest option.
    </SuggestionDeets>
  </Suggestion>


  <Suggestion>
    <SuggestionDesc>
    Why Do Users Need To Deposit RAM Before They Can Stake?
    </SuggestionDesc>
    <SuggestionDeets>
    We are providing a service that requires data to be stored on the blockchain. Storing this data has a (temporary) cost.
    <br/><br/>
    Since our prices are so low, there is no profit margin for us to spend on storing data for users. In order to keep these 
    low prices, users need to be responsible for their own data.
    <br/><br/>
    Here's how it works: when you stake, your WAX deposit will be used to purchase RAM for storing your data.
    <br/><br/>
    When you unstake, this RAM will be sold for WAX and returned back to you at the current market value.
    <br/><br/>
    The alternative option would be for us to drastically increase our prices. And I don't think anyone wants that, right?
    </SuggestionDeets>
  </Suggestion>

  <Suggestion>
    <SuggestionDesc>
    How Come I Receive Different Amount Of Rewards Every Day?
    </SuggestionDesc>
    <SuggestionDeets>
      Please see the question above ("How Much Staking Rewards Will I Receive?").<br/><br/>
      This topic is covered in the response to that question.
    </SuggestionDeets>
  </Suggestion>


  <Suggestion>
    <SuggestionDesc>
    Can I Reward People With Multiple Different Tokens In The Same Farm?
    </SuggestionDesc>
    <SuggestionDeets>
      At this time, you can only reward 1 token per farm. This feature is coming soon though, so stay tuned for updates!
    </SuggestionDeets>
  </Suggestion>


  <Suggestion>
    <SuggestionDesc>
    Who Can I Speak To If I Have More Questions?
    </SuggestionDesc>
    <SuggestionDeets>
    Feel free to <a href="https://t.me/hoodpunks" target="none"> join our Telegram</a> if you need to reach out.
    </SuggestionDeets>
  </Suggestion>

</SuggestionsCont>
       
      </FarmsPageCont>
      <br/><br/><br/>
      </UALProvider>
      </div>
  )
}

export default FarmFAQ