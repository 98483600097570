import React, { useEffect, useState } from "react";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";

import {
  myChain,
  wax,
  anchor,
  currentApiList,
  currentAtomicApiList,
  wombat,
} from "../../data/config";
import { useGetMyPremintedPools } from "../../components/CustomHooks/useGetMyPremintedPools";
import LoadingPage from "../../components/LoadingPage";
import { NewListingBody, NewListingCont, TallFiltersCont } from "../../Styles";
import PremintPoolCard from "../../components/PremintPoolCard";
import { isLoggedIn } from "../../data/functions/wallet_functions";
import Error from "../../components/Error";

const MyPremintedPools = () => {
  const [pools, getPools, poolsAreLoading] = useGetMyPremintedPools();

  useEffect(() => {
    if(!isLoggedIn()) return
    getPools();
  }, []);

  if (!poolsAreLoading && isLoggedIn()) {
    return (
      <div id="seo">
        <Helmet>
          <title>Create Preminted Packs - WaxDAO</title>
          <meta
            name="description"
            content="Create pre-minted packs on WaxDAO"
          />
          <link rel="canonical" href="https://waxdao.io/create-pack" />
        </Helmet>

        <UALProvider
          chains={[myChain]}
          authenticators={[anchor, wax, wombat]}
          appName={"WaxDAO"}
        >
            <NewListingCont>
            <NewListingBody>
                
                <TallFiltersCont>
                    <h2>My Pre-Minted Pools</h2>
                </TallFiltersCont>
                    <br/><br/>

<div className="flex flex-wrap gap-5 mt-4 justify-center">
        {pools.length > 0 ? pools.map((item, index) => (
            <PremintPoolCard item={item} createPack={true} />
        )) : (
            <div className="w-100 text-center font-semibold">
                You haven't created any pre-minted pools to use for your pack.
            </div>
        )}
</div>



</NewListingBody>
</NewListingCont>
        </UALProvider>
        <br />
        <br />
        <br />
      </div>
    );
  } else if (poolsAreLoading) {
    return <LoadingPage />;
  } else if(!isLoggedIn()){
    return <Error error="You are not logged in" />
  }
};

export default MyPremintedPools;
