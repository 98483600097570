import { currentAtomicHubLink, currentWebsiteURL } from "../../data/config";
import { BoldWhiteSpan } from "../../data/css/FarmPageStyles";
import {
  SinglePoolDetail,
  SinglePoolDetailBold,
} from "../../data/css/PoolPageStyles";
import { MainButton } from "../../Styles";

export const showUnstakedAssets = (
  unstaked,
  rewardType,
  dynamicTemps,
  farmType,
  farmTemplates,
  assetVector,
  collections,
  stakedAssets
) => {
  let initialAssets = unstaked;
  let filteredAssets = [];
  let i = 0;

  while (i < initialAssets.length) {
    if (
      (rewardType != "dynamic" &&
        farmType == "template" &&
        initialAssets[i].template != null &&
        farmTemplates.indexOf(parseInt(initialAssets[i].template.template_id)) >
          -1) ||
      (rewardType == "dynamic" &&
        farmType == "template" &&
        initialAssets[i].template != null &&
        dynamicTemps.findIndex(
          (attributeIndex) =>
            attributeIndex.template_id ===
            parseInt(initialAssets[i].template.template_id)
        ) > -1) ||
        (rewardType == "dynamic" &&
        farmType == "nc.v2" &&
        initialAssets[i].template != null &&
        dynamicTemps.findIndex(
          (attributeIndex) =>
            attributeIndex.template_id ===
            parseInt(initialAssets[i].template.template_id)
        ) > -1) ||
        (rewardType == "sharedpool" &&
        farmType == "nc.c.shared" &&
        initialAssets[i].collection?.collection_name != null &&
        collections.findIndex(
          (attributeIndex) =>
            attributeIndex.collection_name ===
            initialAssets[i].collection.collection_name
        ) > -1 &&
          stakedAssets.findIndex((attributeIndex) =>
          attributeIndex.asset_id ===
          initialAssets[i].asset_id) == -1
        )

    ) {
      filteredAssets.push(initialAssets[i]);
    } else if (
      farmType == "s.exclude" &&
      initialAssets[i].template != null &&
      farmTemplates.indexOf(parseInt(initialAssets[i].template.template_id)) ==
        -1
    ) {
      filteredAssets.push(initialAssets[i]);
    } else if (farmType == "s.exclude" && initialAssets[i].template == null) {
      filteredAssets.push(initialAssets[i]);
    } else if (farmType == "schema" || farmType == "collection") {
      filteredAssets.push(initialAssets[i]);
    } else if (
      rewardType == "dynamic" &&
      farmType == "template" &&
      initialAssets[i].template != null &&
      dynamicTemps.findIndex(
        (attributeIndex) =>
          attributeIndex.template_id ===
          parseInt(initialAssets[i].template.template_id)
      ) > -1
    ) {
      filteredAssets.push(initialAssets[i]);
    }

    i++;
  }

  return filteredAssets;
};

export const getStakeableAssets = (farmData, dynamicTemps) => {
  if (farmData.farmtype == "template" && farmData.reward_type != "dynamic") {
    return (
      <span>
        These templates from{" "}
        <BoldWhiteSpan>
          <a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>
            {farmData.collection}
          </a>
        </BoldWhiteSpan>{" "}
        collection:
        <br />
        <div className="flex gap-4 flex-wrap">
          {farmData.templates.map((item, index) => (
            <a key={index} href={`${currentAtomicHubLink}/explorer/template/${farmData.collection}/${item}`} target="none">
            <MainButton >{item}</MainButton>
            </a>
          ))}
        </div>
      </span>
    );
  } else if ((
    farmData.farmtype == "template" &&
    farmData.reward_type == "dynamic") || (farmData.farmtype == "nc.v2" && farmData.reward_type == "dynamic")
  ) {
    return (
      <span>
        Supported collections:
        <br />
        <div className="flex gap-4 flex-wrap mt-3 mb-3">
          {farmData.collections.map((item, index) => (
            <a href={`${currentWebsiteURL}/collection/${item.collection_name}`}>
              <MainButton key={index}>{item.collection_name}</MainButton>
            </a>
          ))}
        </div>
        Supported templates:
        <br />
        <div className="mt-3">
          {dynamicTemps.map((item, index) => (
            <div className="w-100" key={index}>
              <a
                href={`${currentAtomicHubLink}/explorer/template/${item.collection}/${item.template_id}`}
                target="none"
                className="font-semibold"
              >
                {item.template_id}
              </a>{" "}
              - {item.reward_amount}
            </div>
          ))}
        </div>
      </span>
    );
  } else if (farmData.farmtype == "collection") {
    return (
      <span>
        Any asset from{" "}
        <BoldWhiteSpan>
          <a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>
            {farmData.collection}
          </a>
        </BoldWhiteSpan>{" "}
        collection
      </span>
    );
  } else if (farmData.farmtype == "schema") {
    return (
      <span>
        Any asset from{" "}
        <BoldWhiteSpan>
          <a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>
            {farmData.collection}
          </a>
        </BoldWhiteSpan>{" "}
        collection, in the <BoldWhiteSpan>{farmData.schema}</BoldWhiteSpan>{" "}
        schema
      </span>
    );
  } else if (farmData.farmtype == "s.exclude") {
    return (
      <span>
        Any asset from{" "}
        <BoldWhiteSpan>
          <a href={`${currentWebsiteURL}/collection/${farmData.collection}`}>
            {farmData.collection}
          </a>
        </BoldWhiteSpan>{" "}
        collection, in the <BoldWhiteSpan>{farmData.schema}</BoldWhiteSpan>{" "}
        schema, EXCEPT for the following templates:
        <br />
        <div className="flex gap-4 flex-wrap">
          {farmData.templates.map((item, index) => (
            <a key={index} href={`${currentAtomicHubLink}/explorer/template/${farmData.collection}/${item}`} target="none">
            <MainButton>{item}</MainButton>
            </a>
          ))}
        </div>
      </span>
    );
  } else if (
    farmData.farmtype == "nc.c.shared" &&
    farmData.reward_type == "sharedpool"
  ) {
    return (
      <span>
        Supported collections:
        <br />
        <div className="flex gap-4 flex-wrap mt-3 mb-3">
          {farmData.collections.map((item, index) => (
            <a href={`${currentWebsiteURL}/collection/${item.collection_name}`}>
              <MainButton key={index}>{item.collection_name}</MainButton>
            </a>
          ))}
        </div>
      </span>
    );
  }
};

export const selectAllUnstaked = (unstaked, assetVector, setAssetVector) => {
  let initialAssets = unstaked;
  let finalAssets = [...assetVector];
  let i = 0;

  while (i < initialAssets.length) {
    if (finalAssets.indexOf(initialAssets[i].asset_id) == -1) {
      finalAssets.push(initialAssets[i].asset_id);
    }
    i++;
  }
  setAssetVector(finalAssets);
};

export const deselectAllUnstaked = (setAssetVector) => {
  setAssetVector([]);
};

export const selectAllStaked = (
  stakedAssets,
  removalVector,
  setRemovalVector
) => {
  let initialAssets = stakedAssets;
  let finalAssets = [...removalVector];
  let i = 0;

  while (i < initialAssets.length) {
    if (finalAssets.indexOf(initialAssets[i].asset_id) == -1) {
      finalAssets.push(initialAssets[i].asset_id);
    }
    i++;
  }
  setRemovalVector(finalAssets);
};

export const deselectAllStaked = (setRemovalVector) => {
  setRemovalVector([]);
};
