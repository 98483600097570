import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Api, JsonRpc } from "eosjs";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  DropDescription,
  DropBody,
  UnboxTemplate,
  DropPageAdBannerCont,
} from "../../components/LeftDiv";
import NumberFormat from "react-number-format";
var thisCollection;
var list = [];
var list2 = [];
import { Name, raw } from "eos-common";
import ReactMarkdown from "react-markdown";
import { currentWebsiteURL, wombat } from "../../data/config";
import CurrentBannerAd from "../../components/CurrentBannerAd";
import {
  currentApiList,
  currentAtomicApiList,
  anchor,
  wax,
  myChain,
} from "../../data/config";
import {
  purchaseDrop,
  claimDrop,
} from "../../data/functions/DropFunctions";
import { MainButton, NewListingBody, NewListingCont, TallFiltersCont, TallFiltersInnerCont } from "../../Styles";
import LoadingPage from "../../components/LoadingPage";
import Error from "../../components/Error";
import DropPageNFTCard from "../../components/DropPageNFTCard";
import { getAssetQuantity, getAssetSymbol } from "../../data/functions/wallet_functions";
import { getPrecisionFromAsset, getTokenNameFromAsset } from "../../data/functions/pool_functions";

const DropPage = ({ location }) => {
  const {
    setStakedAssets,
    dropPrice,
    setDropPrice,
    whitelistType,
    setWhitelistType,
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,


    dropType,
    setDropType,
    poolIDs,
    setPoolIDs,
  } = useStateContext();

  const { DropID } = useParams();

  const [dropData, setDropData] = useState([]);
  const [dropIsLoading, setDropIsLoading] = useState(true);
  const [templateData, setTemplateData] = useState([]);
  const [quantity, setQuantity] = useState(1);

  //check drops table for drop ID

  useEffect(() => {

    axios
      .post(
        `${
          currentApiList[Math.floor(Math.random() * (2 - 0)) + 0]
        }/v1/chain/get_table_rows`,
        {
          table: "drops",
          scope: "waxdaomarket",
          code: "waxdaomarket",
          limit: 1,
          lower_bound: DropID,
          upper_bound: DropID,
          json: true,
        }
      )
      .then((response) => {

        setDropData(response.data.rows[0])


        setPoolIDs(response.data.rows[0].other);

        var pool_id = response.data.rows[0].other[0];
        //console.log(pool_id)

        if (response.data.rows[0].drop_type == "premint.pack") {
          return;
        }


        if (response.data.rows[0].drop_type == "standard") {
          axios
            .get(
              `${
                currentAtomicApiList[Math.floor(Math.random() * (1 - 0)) + 0]
              }/atomicassets/v1/templates/${response.data.rows[0].collection}/${
                response.data.rows[0].template_id
              }`
            )
            .then((atomicResponse) => {
              setTemplateData(atomicResponse.data.data);
 
            }); //end then atomicresponse
        } //end if drop type is standard

        if (response.data.rows[0].drop_type == "premint.pool") {
          setQuantity("1");

          //get the pool details from our table
          axios
            .post(
              `${
                currentApiList[Math.floor(Math.random() * (2 - 0)) + 0]
              }/v1/chain/get_table_rows`,
              {
                table: "premintpools",
                scope: "waxdaomarket",
                code: "waxdaomarket",
                limit: 1,
                lower_bound: pool_id,
                upper_bound: pool_id,
                json: true,
              }
            )
            .then((poolResponse) => {
  
              if (Number(poolResponse.data.rows[0].amount_of_assets) >= 1) {
                axios
                  .get(
                    `${
                      currentAtomicApiList[
                        Math.floor(Math.random() * (1 - 0)) + 0
                      ]
                    }/atomicassets/v1/assets?ids=${poolResponse.data.rows[0].assets.join(
                      "%2C"
                    )}&page=1&limit=1000&order=desc&sort=asset_id`
                  )
                  .then((validResponse) => {
                    setStakedAssets(validResponse.data.data);
                  });
              }
            })
            .catch((error) => console.log(error));
        } //end if drop type is premint.pool
      })
      .catch((error) => console.log(error))
      .finally(() => setDropIsLoading(false))
  }, []);


  if(!dropIsLoading && dropData != null && dropData?.drop_type != "premint.pack"){
  return (
    <div id="seo">
      <Helmet>
        <title>Drop {DropID} Details</title>
        <meta
          name="description"
          content={`Buy NFTs from drop ${DropID} on WaxDao`}
        />
        <link rel="canonical" href={`https://waxdao.io/drops/${DropID}`} />
      </Helmet>

      <UALProvider
        chains={[myChain]}
        authenticators={[anchor, wax, wombat]}
        appName={"WaxDAO"}
      >
        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <GameButton onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </GameButton>
        </Modal>

        <NewListingCont>
          <NewListingBody>
            <DropPageAdBannerCont>
              <CurrentBannerAd />
            </DropPageAdBannerCont>

        <TallFiltersCont>
          <h2>Drop {DropID}</h2>
          <p>By <a href={`${currentWebsiteURL}/u/${dropData.user}`}>{dropData.user}</a></p>
        </TallFiltersCont>

        {dropData?.whitelist_type != "none" && (
          <TallFiltersCont>
            <span className="pl-3 italic">
            Note: This is a whitelisted drop. Make sure you are whitelisted by the creator if you want to participate.
            </span>
          </TallFiltersCont>
        )}

        <div className="w-100 flex text-center justify-center mt-4">
          <DropPageNFTCard dropData={dropData} templateData={templateData} />
        </div>

        <TallFiltersCont>
          <h2>Description</h2>
          <br/>
          <TallFiltersInnerCont>
            <ReactMarkdown>
              {dropData.drop_description}
            </ReactMarkdown>
          </TallFiltersInnerCont>
        </TallFiltersCont>

        <TallFiltersCont>
          <h2>Place Order</h2>
          <br/>
          <TallFiltersInnerCont>
            <h3>Quantity</h3>
            <input value={quantity} onChange={(e) => setQuantity(e.target.value)} />
            <br/><br/>
            <h3>Price</h3>
            <NumberFormat displayType='text' thousandSeparator={true} value={quantity * getAssetQuantity(dropData.price)} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> {getTokenNameFromAsset(dropData.price)}
            {/* {getAssetSymbol(dropData.dropPrice)} */}
            <br/><br/>
            {dropData.price != null && dropData.price != "0 FREE" && (
                    <MainButton
                      onClick={() => {
                        purchaseDrop(
                          DropID,
                          quantity,
                          dropData.drop_type,
                          dropData.contract,
                          getAssetQuantity(dropData.price),
                          getPrecisionFromAsset(dropData.price),
                          getTokenNameFromAsset(dropData.price),
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay
                        );
                      }}
                    >
                      Buy {quantity} NFTs
                    </MainButton>
                  )}

                 {dropData?.price != null 
                    && dropData?.price == "0 FREE" 
                  && (
                    <MainButton
                      onClick={() => {
                        claimDrop(
                          DropID,
                          quantity,
                          dropData.drop_type,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay
                        );
                      }}
                    >
                      Claim {quantity} NFTs
                    </MainButton>
                  )}

          </TallFiltersInnerCont>
        </TallFiltersCont>

          </NewListingBody>
        </NewListingCont>
      </UALProvider>
      <br />
      <br />
      <br />
    </div>
  )}
  else if(dropIsLoading){
    return(<LoadingPage />)
  }
  else if(!dropIsLoading && dropData == null){
    return(<Error error="Could not load drop. If this is an error, try refreshing the page" />)
  }
};

export default DropPage;
