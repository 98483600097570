import { useState } from "react";
import { currentApiList, v2DAOContract } from "../../data/config";
import axios from 'axios';

export const useGetV2DAOProposals = () => {
    
    const [daoProposals, setDaoProposals] = useState([]);
    const [proposalsAreLoading, setProposalsAreLoading] = useState(true);

    const getDaoProposals = async (dao_name) => {
        setProposalsAreLoading(true);
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "proposals",
                    scope: dao_name,
                    code: v2DAOContract,
                    limit: 1000,
                    json: true,
                });
                
                if (res.data.rows) {
                    setDaoProposals(res.data.rows);
                    console.log(res.data.rows);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        setProposalsAreLoading(false);
    }

    return [daoProposals, getDaoProposals, proposalsAreLoading]
}
