import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { MainContainer, NewUIFarmsPageCont, NFTsCont, NFTLogo, NoDaosMessage, ProposalInput, DaoButtonCont, RamModal, WalletCont, FarmButtonCont, ToggleSwitch, InnerSwitch, ToggleSwitchCont, ToggleMessage, StakeManyButton, StakeManyDiv, LeftProfile, RightProfile, ProfileCont, ProfileImage, LeftProfileImg, SocialButton, FarmsTitle, FarmsPageCont, DropPageCont, MissingInfoMsg, Drop, DropTitle, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DropImage, Remaining, Price, QuantityToBuy, MinMint, AddNFT, DropDescription, DropBody, PoolAssetsTitle, UnboxTemplate, DropPageAdBannerCont, NotFoundCont, SQLTextarea } from '../../components/LeftDiv';
import { getBlogPostEndpoint, localServerURL, adminMode, editBlogEndpoint, adminServerURL, getTokenEndpoint, currentAtomicHubLink, currentWebsiteURL, tokenServerURL } from '../../data/config';
import NotFound from '../../components/NotFound';
import { BlogContainer, BlogBody, BlogFooter, RemoveBlogButton } from '../../data/css/BlogStyles';
import { InfoContTopRow, InfoContWebsiteRow, InfoContWebsiteSubject, InfoWebsiteButton, InfoWebsiteButtonWrapper, NameAndPriceCont, NameAndPriceStatsRow, NameAndPriceTopRow, StatsStat, StatsTitle, TokenListLogo, TokenPageAlert, TokenPageAlertTwo, TokenPageAnswer, TokenPageChange24, TokenPageCont, TokenPageFirstCont, TokenPageInfoCont, TokenPageLogo, TokenPageMobileInfoCont, TokenPageNavbar, TokenPageNavbarTokenName, TokenPagePrice, TokenPageQuestion, TokenPageQuestionsCont, TokenPageShowInfoButton, TokenPageShowInfoButtonCont, TokenPageSymbol } from '../../data/css/TokenTrackerStyles';
import CurrentBannerAd from '../../components/CurrentBannerAd';
import NumberFormat from 'react-number-format';
import LoadingPage from '../../components/LoadingPage';
import { useStateContext } from '../../contexts/ContextProvider';
import { TallFiltersCont, TallFiltersInnerCont } from '../../Styles';



const TokenPage = ({ location }) => {

const { TokenID } = useParams();

const { currentNavbarLocation, setCurrentNavbarLocation } = useStateContext();

const [isLoading, setIsLoading] = useState(true);
const [thisTokenData, setThisTokenData] = useState([]);
const [showInfo, setShowInfo] = useState(false);

useEffect(() => {

    setCurrentNavbarLocation("Tokens");

    axios.post(`${tokenServerURL}/${getTokenEndpoint}`, {
        id: TokenID,
      },
        {
        headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        },

    }).then((response) => {
        setThisTokenData(response.data[0]);
        setIsLoading(false);
    }).catch((e) => { console.log(e); setIsLoading(false);  })

}
    
    , []);



if(thisTokenData?.id != null && thisTokenData?.id != ""){
  
  return (
    <div id="seo">
    <Helmet>
    <title>{thisTokenData?.symbol} Token Market Cap</title>
    <meta name="description" content={`${thisTokenData?.symbol} Cryptocurrency Market Cap and price information`} />
    <link rel="canonical" href={`https://waxdao.io/tokens/${TokenID}`} />
    </Helmet>

    <TokenPageCont>
<br/>
<TokenPageNavbar>
    <a href={`${currentWebsiteURL}/tokens`}>Tokens</a> &nbsp; &#10140; <TokenPageNavbarTokenName>{TokenID}</TokenPageNavbarTokenName>
</TokenPageNavbar>

<div style={{width: "95%", marginLeft:"auto", marginRight:"auto"}}> 
<CurrentBannerAd />
</div>
<br/>

{thisTokenData?.alerts != null && (JSON.parse(thisTokenData?.alerts)?.one != null && JSON.parse(thisTokenData?.alerts)?.one != "" || JSON.parse(thisTokenData?.alerts)?.two != null && JSON.parse(thisTokenData?.alerts)?.two != "") && (
        <span>
        
            {JSON.parse(thisTokenData?.alerts).one != null && JSON.parse(thisTokenData?.alerts).one != "" && (
                <TokenPageAlert>
                {JSON.parse(thisTokenData?.alerts).one}
                </TokenPageAlert>   
            )}

        
            {JSON.parse(thisTokenData?.alerts).two != null && JSON.parse(thisTokenData?.alerts).two != "" && (
                <TokenPageAlertTwo>
                {JSON.parse(thisTokenData?.alerts).two}
                </TokenPageAlertTwo>  
            )}
        
        
        
        
        
        
        </span>
    )}



<TokenPageFirstCont>
    <NameAndPriceCont>
        <NameAndPriceTopRow>
        <TokenPageLogo src={`https://logos.waxdao.io/${TokenID.replace("@","_").toLowerCase()}.png`}  onError={(e) =>
            (e.target.onerror = null)(
                (e.target.src =
                "https://logos.waxdao.io/default-logo.png")
            )
            } />
            {thisTokenData?.symbol != null && (
                <TokenPageSymbol>
                    {thisTokenData?.symbol}
                </TokenPageSymbol>)}
            </NameAndPriceTopRow>
            {thisTokenData?.price != null && (
                <span>
            <TokenPagePrice>{thisTokenData?.price} WAX
            {thisTokenData?.historical_info != null && (
            <TokenPageChange24 className={Number(JSON.parse(thisTokenData?.historical_info)?.change24) > 0 ? "text-green" : "text-redprice"}>
            {Number(JSON.parse(thisTokenData?.historical_info)?.change24).toFixed(2)}%
            </TokenPageChange24>
            
            )}
            </TokenPagePrice>            
            </span>
            )}

    <NameAndPriceStatsRow>
       <StatsTitle>Market Cap</StatsTitle>
       <StatsStat>
           {JSON.parse(thisTokenData?.supply)?.circulating != null && thisTokenData?.price != null ? (
               <span>
                <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(Number(JSON.parse(thisTokenData?.supply)?.circulating) * Number(thisTokenData?.price))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> WAX
               </span>
           ) : "?"}
       </StatsStat>
    </NameAndPriceStatsRow>

    <NameAndPriceStatsRow>
       <StatsTitle>Circulating Supply</StatsTitle>
       <StatsStat>
           {JSON.parse(thisTokenData?.supply)?.circulating != null ? (
               <span>
                <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(Number(JSON.parse(thisTokenData?.supply)?.circulating))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
               </span>
           ) : "?"}
       </StatsStat>
    </NameAndPriceStatsRow>

    <NameAndPriceStatsRow>
       <StatsTitle>Max Supply</StatsTitle>
       <StatsStat>
           {JSON.parse(thisTokenData?.supply)?.max != null ? (
               <span>
                <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(Number(JSON.parse(thisTokenData?.supply)?.max))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
               </span>
           ) : "?"}
       </StatsStat>
    </NameAndPriceStatsRow>

    <NameAndPriceStatsRow>
       <StatsTitle>24H Volume</StatsTitle>
       <StatsStat>
           {JSON.parse(thisTokenData?.historical_info)?.volume24 != null ? (
               <span>
                <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(Number(JSON.parse(thisTokenData?.historical_info)?.volume24))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
               </span>
           ) : "?"}
       </StatsStat>
    </NameAndPriceStatsRow>

    </NameAndPriceCont>

</TokenPageFirstCont>

<TokenPageShowInfoButtonCont>
<TokenPageShowInfoButton onClick={() => { !showInfo ? setShowInfo(true) : setShowInfo(false) }} >
    {!showInfo ? "Show More Info" : "Hide Info"}
</TokenPageShowInfoButton>
</TokenPageShowInfoButtonCont>


<TokenPageInfoCont>
    <InfoContTopRow>
        Info
    </InfoContTopRow>

    { thisTokenData?.project_info != null &&
        (JSON.parse(thisTokenData?.project_info).website != null && JSON.parse(thisTokenData?.project_info).website != "" ||
        JSON.parse(thisTokenData?.project_info).whitepaper != null && JSON.parse(thisTokenData?.project_info).whitepaper != "" || 
        JSON.parse(thisTokenData?.project_info).nft_collection != null && JSON.parse(thisTokenData?.project_info).nft_collection != "" ||
        JSON.parse(thisTokenData?.project_info).github != null && JSON.parse(thisTokenData?.project_info).github != "" 
        ) && (
        <span>
        <InfoContWebsiteRow>
            <InfoContWebsiteSubject>
            Project Info:
            </InfoContWebsiteSubject>
            <InfoWebsiteButtonWrapper>

            {JSON.parse(thisTokenData?.project_info).website != null && JSON.parse(thisTokenData?.project_info).website != "" && (
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.project_info).website} target="none">
                Website
                </InfoWebsiteButton>   
            )}

            {JSON.parse(thisTokenData?.project_info).whitepaper != null && JSON.parse(thisTokenData?.project_info).whitepaper != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.project_info).whitepaper} target="none">
                Whitepaper
                </InfoWebsiteButton>
            )}

            {JSON.parse(thisTokenData?.project_info).nft_collection != null && JSON.parse(thisTokenData?.project_info).nft_collection != "" &&  ( 
                <InfoWebsiteButton href={`${currentAtomicHubLink}/explorer/collection/${JSON.parse(thisTokenData?.project_info).nft_collection}`} target="none">
                NFT Collection
                </InfoWebsiteButton>
            )}

            {JSON.parse(thisTokenData?.project_info).github != null && JSON.parse(thisTokenData?.project_info).github != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.project_info).github} target="none">
                Github
                </InfoWebsiteButton>
            )}
            </InfoWebsiteButtonWrapper>
        </InfoContWebsiteRow>
        </span>
    )}


    {thisTokenData?.contract != null && thisTokenData?.symbol != null && (
        <span>
        <InfoContWebsiteRow>
            <InfoContWebsiteSubject>
            Explorers:
            </InfoContWebsiteSubject>
            <InfoWebsiteButtonWrapper>
            
                <InfoWebsiteButton href={`https://waxblock.io/account/${thisTokenData?.contract}?code=${thisTokenData?.contract}&scope=${thisTokenData?.symbol}&table=stat&lower_bound=&upper_bound=&limit=10#contract-tables`} target="none">
                waxblock.io
                </InfoWebsiteButton>   



                <InfoWebsiteButton href={`https://eosauthority.com/account/${thisTokenData?.contract}?network=wax&scope=${thisTokenData?.symbol}&table=stat&limit=10&index_position=1&key_type=i64&reverse=0&mode=contract&sub=tables`} target="none">
                eosauthority
                </InfoWebsiteButton>


            </InfoWebsiteButtonWrapper>
        </InfoContWebsiteRow>
        </span>
    )}

    <InfoContWebsiteRow>
            <InfoContWebsiteSubject>
            Wallets:
            </InfoContWebsiteSubject>
            <InfoWebsiteButtonWrapper>

                <InfoWebsiteButton href={`https://greymass.com/en/anchor/`} target="none">
                Anchor
                </InfoWebsiteButton>   

                <InfoWebsiteButton href={`https://www.ledger.com/`} target="none">
                Ledger
                </InfoWebsiteButton>

                <InfoWebsiteButton href={`https://mycloudwallet.com/`} target="none">
                MyCloudWallet
                </InfoWebsiteButton>     


            </InfoWebsiteButtonWrapper>
        </InfoContWebsiteRow>


        {thisTokenData?.socials != null && 
        (JSON.parse(thisTokenData?.socials).twitter != null && JSON.parse(thisTokenData?.socials).twitter != "" ||
        JSON.parse(thisTokenData?.socials).discord != null && JSON.parse(thisTokenData?.socials).discord != "" || 
        JSON.parse(thisTokenData?.socials).telegram != null && JSON.parse(thisTokenData?.socials).telegram != "" ||
        JSON.parse(thisTokenData?.socials).medium != null && JSON.parse(thisTokenData?.socials).medium != "" ||
        JSON.parse(thisTokenData?.socials).youtube != null && JSON.parse(thisTokenData?.socials).youtube != "" 
        ) && (

        <span>
        <InfoContWebsiteRow>
            <InfoContWebsiteSubject>
            Social:
            </InfoContWebsiteSubject>
            <InfoWebsiteButtonWrapper>

            {JSON.parse(thisTokenData?.socials).twitter != null && JSON.parse(thisTokenData?.socials).twitter != "" && (
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.socials).twitter} target="none">
                Twitter
                </InfoWebsiteButton>   
            )}

            {JSON.parse(thisTokenData?.socials).discord != null && JSON.parse(thisTokenData?.socials).discord != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.socials).discord} target="none">
                Discord
                </InfoWebsiteButton>
            )}

            {JSON.parse(thisTokenData?.socials).telegram != null && JSON.parse(thisTokenData?.socials).telegram != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.socials).telegram} target="none">
                Telegram
                </InfoWebsiteButton>
            )}

            {JSON.parse(thisTokenData?.socials).medium != null && JSON.parse(thisTokenData?.socials).medium != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.socials).medium} target="none">
                Medium
                </InfoWebsiteButton>
            )}

            {JSON.parse(thisTokenData?.socials).youtube != null && JSON.parse(thisTokenData?.socials).youtube != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.socials).youtube} target="none">
                YouTube
                </InfoWebsiteButton>
            )}



            </InfoWebsiteButtonWrapper>
        </InfoContWebsiteRow>
        </span>
    )}

{thisTokenData?.exchanges != null && 
        (JSON.parse(thisTokenData?.exchanges).alcor_amm != null && JSON.parse(thisTokenData?.exchanges).alcor_amm != "" ||
        JSON.parse(thisTokenData?.exchanges).alcor_amm != null && JSON.parse(thisTokenData?.exchanges).defibox_amm != "" ||
        JSON.parse(thisTokenData?.exchanges).taco_amm != null && JSON.parse(thisTokenData?.exchanges).taco_amm != ""
        ) && (

        <span>
        <InfoContWebsiteRow>
            <InfoContWebsiteSubject>
            Exchanges:
            </InfoContWebsiteSubject>
            <InfoWebsiteButtonWrapper>

            {JSON.parse(thisTokenData?.exchanges).alcor_amm != null && JSON.parse(thisTokenData?.exchanges).alcor_amm != "" && (
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.exchanges).alcor_amm} target="none">
                Alcor AMM
                </InfoWebsiteButton>   
            )}

            {JSON.parse(thisTokenData?.exchanges).taco_amm != null && JSON.parse(thisTokenData?.exchanges).taco_amm != "" && (
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.exchanges).taco_amm} target="none">
                TacoSwap
                </InfoWebsiteButton>   
            )}

            {JSON.parse(thisTokenData?.exchanges).defibox_amm != null && JSON.parse(thisTokenData?.exchanges).defibox_amm != "" && (
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.exchanges).defibox_amm} target="none">
                DeFiBox
                </InfoWebsiteButton>   
            )}

            </InfoWebsiteButtonWrapper>
        </InfoContWebsiteRow>
        </span>
    )}


    
</TokenPageInfoCont>




<span className={!showInfo && "hidden"}>
<TokenPageMobileInfoCont>
    <InfoContTopRow>
        Info
    </InfoContTopRow>

    { thisTokenData?.project_info != null &&
        (JSON.parse(thisTokenData?.project_info).website != null && JSON.parse(thisTokenData?.project_info).website != "" ||
        JSON.parse(thisTokenData?.project_info).whitepaper != null && JSON.parse(thisTokenData?.project_info).whitepaper != "" || 
        JSON.parse(thisTokenData?.project_info).nft_collection != null && JSON.parse(thisTokenData?.project_info).nft_collection != "" ||
        JSON.parse(thisTokenData?.project_info).github != null && JSON.parse(thisTokenData?.project_info).github != "" 
        ) && (
    
        <span>
        <InfoContWebsiteRow>
            <InfoContWebsiteSubject>
            Project:
            </InfoContWebsiteSubject>
            <InfoWebsiteButtonWrapper>

            {JSON.parse(thisTokenData?.project_info).website != null && JSON.parse(thisTokenData?.project_info).website != "" && (
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.project_info).website} target="none">
                Website
                </InfoWebsiteButton>   
            )}

            {JSON.parse(thisTokenData?.project_info).whitepaper != null && JSON.parse(thisTokenData?.project_info).whitepaper != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.project_info).whitepaper} target="none">
                Whitepaper
                </InfoWebsiteButton>
            )}

            {JSON.parse(thisTokenData?.project_info).nft_collection != null && JSON.parse(thisTokenData?.project_info).nft_collection != "" &&  ( 
                <InfoWebsiteButton href={`${currentAtomicHubLink}/explorer/collection/${JSON.parse(thisTokenData?.project_info).nft_collection}`} target="none">
                NFT Collection
                </InfoWebsiteButton>
            )}

            {JSON.parse(thisTokenData?.project_info).github != null && JSON.parse(thisTokenData?.project_info).github != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.project_info).github} target="none">
                Github
                </InfoWebsiteButton>
            )}
            </InfoWebsiteButtonWrapper>
        </InfoContWebsiteRow>
        </span>
    )}


    {thisTokenData?.contract != null && thisTokenData?.symbol != null && (
        <span>
        <InfoContWebsiteRow>
            <InfoContWebsiteSubject>
            Explorers:
            </InfoContWebsiteSubject>
            <InfoWebsiteButtonWrapper>

                <InfoWebsiteButton href={`https://waxblock.io/account/${thisTokenData?.contract}?code=${thisTokenData?.contract}&scope=${thisTokenData?.symbol}&table=stat&lower_bound=&upper_bound=&limit=10#contract-tables`} target="none">
                waxblock.io
                </InfoWebsiteButton>   



                <InfoWebsiteButton href={`https://eosauthority.com/account/${thisTokenData?.contract}?network=wax&scope=${thisTokenData?.symbol}&table=stat&limit=10&index_position=1&key_type=i64&reverse=0&mode=contract&sub=tables`} target="none">
                eosauthority
                </InfoWebsiteButton>


            </InfoWebsiteButtonWrapper>
        </InfoContWebsiteRow>
        </span>
    )}

    <InfoContWebsiteRow>
            <InfoContWebsiteSubject>
            Wallets:
            </InfoContWebsiteSubject>
            <InfoWebsiteButtonWrapper>

                <InfoWebsiteButton href={`https://greymass.com/en/anchor/`} target="none">
                Anchor
                </InfoWebsiteButton>   

                <InfoWebsiteButton href={`https://www.ledger.com/`} target="none">
                Ledger
                </InfoWebsiteButton>

                <InfoWebsiteButton href={`https://mycloudwallet.com/`} target="none">
                MyCloudWallet
                </InfoWebsiteButton>     


            </InfoWebsiteButtonWrapper>
        </InfoContWebsiteRow>


        {thisTokenData?.socials != null && 
        (JSON.parse(thisTokenData?.socials).twitter != null && JSON.parse(thisTokenData?.socials).twitter != "" ||
        JSON.parse(thisTokenData?.socials).discord != null && JSON.parse(thisTokenData?.socials).discord != "" || 
        JSON.parse(thisTokenData?.socials).telegram != null && JSON.parse(thisTokenData?.socials).telegram != "" ||
        JSON.parse(thisTokenData?.socials).medium != null && JSON.parse(thisTokenData?.socials).medium != "" ||
        JSON.parse(thisTokenData?.socials).youtube != null && JSON.parse(thisTokenData?.socials).youtube != "" 
        ) && (
        <span>
        <InfoContWebsiteRow>
            <InfoContWebsiteSubject>
            Social:
            </InfoContWebsiteSubject>
            <InfoWebsiteButtonWrapper>

            {JSON.parse(thisTokenData?.socials).twitter != null && JSON.parse(thisTokenData?.socials).twitter != "" && (
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.socials).twitter} target="none">
                Twitter
                </InfoWebsiteButton>   
            )}

            {JSON.parse(thisTokenData?.socials).discord != null && JSON.parse(thisTokenData?.socials).discord != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.socials).discord} target="none">
                Discord
                </InfoWebsiteButton>
            )}

            {JSON.parse(thisTokenData?.socials).telegram != null && JSON.parse(thisTokenData?.socials).telegram != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.socials).telegram} target="none">
                Telegram
                </InfoWebsiteButton>
            )}

            {JSON.parse(thisTokenData?.socials).medium != null && JSON.parse(thisTokenData?.socials).medium != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.socials).medium} target="none">
                Medium
                </InfoWebsiteButton>
            )}

            {JSON.parse(thisTokenData?.socials).youtube != null && JSON.parse(thisTokenData?.socials).youtube != "" &&  ( 
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.socials).youtube} target="none">
                YouTube
                </InfoWebsiteButton>
            )}



            </InfoWebsiteButtonWrapper>
        </InfoContWebsiteRow>
        </span>
    )}

{thisTokenData?.exchanges != null && 
        (JSON.parse(thisTokenData?.exchanges).alcor_amm != null && JSON.parse(thisTokenData?.exchanges).alcor_amm != ""
        ) && (

        <span>
        <InfoContWebsiteRow>
            <InfoContWebsiteSubject>
            Exchanges:
            </InfoContWebsiteSubject>
            <InfoWebsiteButtonWrapper>

            {JSON.parse(thisTokenData?.exchanges).alcor_amm != null && JSON.parse(thisTokenData?.exchanges).alcor_amm != "" && (
                <InfoWebsiteButton href={JSON.parse(thisTokenData?.exchanges).alcor_amm} target="none">
                Alcor AMM
                </InfoWebsiteButton>   
            )}

            </InfoWebsiteButtonWrapper>
        </InfoContWebsiteRow>
        </span>
    )}


    
</TokenPageMobileInfoCont>
</span>
















<TokenPageQuestionsCont>


{thisTokenData?.description != null && thisTokenData?.description != "" && (
    <span>
    <TallFiltersCont>
    <h2>What is {JSON.parse(thisTokenData?.project_info)?.project_name != null && JSON.parse(thisTokenData?.project_info).project_name != "" ? JSON.parse(thisTokenData?.project_info).project_name : thisTokenData?.symbol }?</h2>
    <br/>
    <TallFiltersInnerCont>
    {thisTokenData?.description}
    </TallFiltersInnerCont>
    </TallFiltersCont>
    </span>
)}


{thisTokenData?.price != null && thisTokenData?.price != "" && (
    <span>
    <TallFiltersCont>
    <h2>What is the {thisTokenData?.symbol} price today?</h2>
    <br/>
    <TallFiltersInnerCont>
    {thisTokenData?.symbol} is currently {thisTokenData?.price} WAX, with a 24 hour trading volume of {Number(JSON.parse(thisTokenData?.historical_info)?.volume24).toFixed(2)} WAX.
    </TallFiltersInnerCont>
    </TallFiltersCont>
    </span>
)}


    <TallFiltersCont>
    <h2>How do I add {thisTokenData?.symbol} to my Wax Wallet?</h2>
    <br/>
    <TallFiltersInnerCont>
    In order to add {thisTokenData?.symbol} to your wax wallet, click "Add Custom Token".
    <br/>
    Then, you need to enter the following info:
    <ul>
    <li>Token Symbol: {thisTokenData?.symbol}</li>
    <li>Decimal Places: {thisTokenData?.decimals}</li>
    <li>Token Contract: {thisTokenData?.contract}</li>
    <li>Token Name: {thisTokenData?.symbol}</li>
    <li><div class="ipfsHash">Token Image: {`https://logos.waxdao.io/${thisTokenData?.id.toLowerCase().replace("@","_")}.png`}</div></li>
    </ul>
    </TallFiltersInnerCont>
    </TallFiltersCont>

    {JSON.parse(thisTokenData?.historical_info) != null && (<span>


    <TallFiltersCont>
    <h2>What was the 24 hour high for {thisTokenData?.symbol}?</h2>
    <br/>
    <TallFiltersInnerCont>
    The 24 hour high for {thisTokenData?.symbol} was {JSON.parse(thisTokenData?.historical_info)?.high24} WAX.
    </TallFiltersInnerCont>
    </TallFiltersCont>

    <TallFiltersCont>
    <h2>What was the 24 hour low for {thisTokenData?.symbol}?</h2>
    <br/>
    <TallFiltersInnerCont>
    The 24 hour low for {thisTokenData?.symbol} was {JSON.parse(thisTokenData?.historical_info)?.low24} WAX.
    </TallFiltersInnerCont>
    </TallFiltersCont>

    <TallFiltersCont>
    <h2>What was the 24 hour volume for {thisTokenData?.symbol}?</h2>
    <br/>
    <TallFiltersInnerCont>
    The 24 hour volume for {thisTokenData?.symbol} was {JSON.parse(thisTokenData?.historical_info)?.volume24} WAX.
    </TallFiltersInnerCont>
    </TallFiltersCont>

    <TallFiltersCont>
    <h2>What is the 7 day volume for {thisTokenData?.symbol}?</h2>
    <br/>
    <TallFiltersInnerCont>
    In the last 7 days, there has been {Number(JSON.parse(thisTokenData?.historical_info)?.volumeWeek).toFixed(2)} WAX worth of {thisTokenData?.symbol} traded.
    </TallFiltersInnerCont>
    </TallFiltersCont>



    </span>)}

    <TallFiltersCont>
    <h2>What is the best exchange to convert {thisTokenData?.symbol} to WAX?</h2>
    <br/>
    <TallFiltersInnerCont>
    You can buy or sell {thisTokenData?.symbol} for WAX using <a href="https://wax.alcor.exchange" target="none">Alcor Exchange</a>.
    </TallFiltersInnerCont>
    </TallFiltersCont>

    <TallFiltersCont>
    <h2>What is the best wallet to store my {thisTokenData?.symbol} tokens?</h2>
    <br/>
    <TallFiltersInnerCont>
    The most convenient would probably be <a href="https://mycloudwallet.com" target="none">My Cloud Wallet</a>, but we highly recommend <a href="https://greymass.com/en/anchor/" target="none">Anchor Wallet</a>.
    <br/>
    Anchor is the most secure since you own your private keys. You can even use Anchor Wallet to <a href="https://youtu.be/FgTMLp4-P9Q" target="none">store your WAX tokens on a Ledger Hardware Wallet</a>.
    </TallFiltersInnerCont>
    </TallFiltersCont>
        
    <TallFiltersCont>
    <h2>Where can I see transactions for {thisTokenData?.symbol}?</h2>
    <br/>
    <TallFiltersInnerCont>
    You can view {thisTokenData?.symbol} transactions by going to the <a href={`https://waxblock.io/account/${thisTokenData?.contract}`} target="none">{thisTokenData?.contract} contract page on WaxBlock</a>.
    </TallFiltersInnerCont>
    </TallFiltersCont>

    <TallFiltersCont>
    <h2>How can I create my own WAX token?</h2>
    <br/>
    <TallFiltersInnerCont>
    We have a <a href={`${currentWebsiteURL}/token-creator`}>WAX Token Creator</a> right here on WaxDAO. You don't need to learn how to code, and you can have your own token in under 5 minutes.
    </TallFiltersInnerCont>
    </TallFiltersCont>




</TokenPageQuestionsCont>




    </TokenPageCont>

    <br/><br/><br/>
    </div>
  )


}

else if(isLoading){return(
    <LoadingPage />
  )}


else{return(
  <NotFound />
)}

}

export default TokenPage