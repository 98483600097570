import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { UALProvider } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  DropPageAdBannerCont,
} from "../../components/LeftDiv";

import ReactMarkdown from "react-markdown";
import { currentUsername, getCurrentApiList, wombat } from "../../data/config";
import CurrentBannerAd from "../../components/CurrentBannerAd";
import {
  anchor,
  wax,
  myChain,
} from "../../data/config";
import {
  FoldersContainer,
  FoldersRow,
  MainButton,
  NewListingBody,
  NewListingCont,
  SingleFolderFourth,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import {
  getPrecisionFromAsset,
  getQuantityFromAsset,
  getTokenNameFromAsset,
} from "../../data/functions/pool_functions";
import DatePicker from "react-datepicker";
import { handleCalendarChange } from "../../data/functions/pack_functions";
import { cancelDrop, editDropDetails } from "../../data/functions/wallet_functions";
import LoadingPage from "../../components/LoadingPage";
import Error from "../../components/Error";

const EditDrop = ({ location }) => {
  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
  } = useStateContext();

  const { DropID } = useParams();

  const [dropData, setDropData] = useState([]);
  const [dropIsLoading, setDropIsLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [currentDescriptionTab, setCurrentDescriptionTab] = useState("Edit");
  const [price, setPrice] = useState(0);
  const [precision, setPrecision] = useState(0);
  const [tokenName, setTokenName] = useState("");
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);

  useEffect(() => {
    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "drops",
        scope: "waxdaomarket",
        code: "waxdaomarket",
        limit: 1,
        lower_bound: DropID,
        upper_bound: DropID,
        json: true,
      })
      .then((response) => {
        setDropData(response.data.rows[0]);
        setDescription(response.data.rows[0].drop_description);
        setPrice(getQuantityFromAsset(response.data.rows[0].price));
        setPrecision(getPrecisionFromAsset(response.data.rows[0].price));
        setTokenName(getTokenNameFromAsset(response.data.rows[0].price));
        setStartTime(response.data.rows[0].start_time);
        setEndTime(response.data.rows[0].end_time);
      })
      .catch((error) => console.log(error))
      .finally(() => setDropIsLoading(false));
  }, []);

if(!dropIsLoading && dropData != null && currentUsername == dropData.user && dropData?.drop_type != "premint.pack"){

  return (
    <div id="seo">
      <Helmet>
        <title>Drop {DropID} Details</title>
        <meta
          name="description"
          content={`Buy NFTs from drop ${DropID} on WaxDao`}
        />
        <link rel="canonical" href={`https://waxdao.io/drops/${DropID}`} />
      </Helmet>

      <UALProvider
        chains={[myChain]}
        authenticators={[anchor, wax, wombat]}
        appName={"WaxDAO"}
      >
        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <GameButton onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </GameButton>
        </Modal>

        <NewListingCont>
          <DropPageAdBannerCont>
            <CurrentBannerAd />
          </DropPageAdBannerCont>

          <NewListingBody>
            <TallFiltersCont>
              <h2>Edit Drop {DropID}</h2>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>{tokenName} Price</h2>
              <br />
              <TallFiltersInnerCont>
                <input
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Start Time</h2>
              <br />
              <TallFiltersInnerCont>
                <DatePicker
                  showTimeSelect
                  timeIntervals={15}
                  selected={new Date(startTime * 1000)}
                  onChange={(e) => {
                    handleCalendarChange(e, setStartTime);
                  }}
                />
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>End Time</h2>
              <br />
              <TallFiltersInnerCont>
                <DatePicker
                  showTimeSelect
                  timeIntervals={15}
                  selected={new Date(endTime * 1000)}
                  onChange={(e) => {
                    handleCalendarChange(e, setEndTime);
                  }}
                />
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Description</h2>
              <br />
              <FoldersContainer>
                <FoldersRow>
                  <SingleFolderFourth
                    onClick={() => setCurrentDescriptionTab("Edit")}
                    selected={currentDescriptionTab == "Edit" && true}
                  >
                    Edit
                  </SingleFolderFourth>
                  <SingleFolderFourth
                    onClick={() => setCurrentDescriptionTab("Preview")}
                    selected={currentDescriptionTab == "Preview" && true}
                  >
                    Preview
                  </SingleFolderFourth>
                </FoldersRow>
              </FoldersContainer>
              <br />
              <TallFiltersInnerCont>
                {currentDescriptionTab == "Edit" && (
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                )}
                {currentDescriptionTab == "Preview" && (
                  <ReactMarkdown>{description}</ReactMarkdown>
                )}
              </TallFiltersInnerCont>
            </TallFiltersCont>
            <br />
            <br />

            <div className="text-center w-100">
              <MainButton
                onClick={() =>
                  editDropDetails(
                    DropID,
                    startTime,
                    endTime,
                    description,
                    price,
                    precision,
                    tokenName,
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterModalDisplay
                  )
                }
              >
                Confirm Changes
              </MainButton>
              &nbsp;&nbsp;
              <MainButton
                onClick={() =>
                  cancelDrop(
                    DropID,
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterModalDisplay
                  )
                }
              >
                Cancel Drop
              </MainButton>

            </div>
          </NewListingBody>
        </NewListingCont>
      </UALProvider>
      <br />
      <br />
      <br />
    </div>
  )}
  else if(dropIsLoading){
    return(<LoadingPage />)
  }
  else if(!dropIsLoading && dropData != null && dropData.drop_type == "premint.pack"){
      return(<Error error="Unbox links can be edited at waxdao.io/edit-pack/<PACK_ID>" />)
  }

  else if(!dropIsLoading && dropData != null && (currentUsername == null || currentUsername == "" || currentUsername != dropData.user) ){
      return(<Error error="You are not the creator of this drop" />)
  }
  else if(!dropIsLoading && dropData == null){
      return(<Error error="This drop does not exist. If this is an error, try refreshing the page" />)
  }
  else{
      return null
  }
};

export default EditDrop;
