import { useState } from "react";
import { currentApiList, currentUsername, v2DAOContract } from "../../data/config";
import axios from 'axios';

export const useGetRoyaltyWhitelistStatus = () => {
    
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const [statusIsLoading, setStatusIsLoading] = useState(true);

    const getWhitelistStatus = async () => {
        setStatusIsLoading(true);
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "royaltylist",
                    scope: "waxywojaknft",
                    code: "waxywojaknft",
                    limit: 1,
                    lower_bound: currentUsername,
                    upper_bound: currentUsername,
                    json: true,
                });
                
                if (res.data.rows) {
                    if(res.data.rows[0] != null){
                        setIsWhitelisted(true);
                        console.log(res.data.rows);   
                    }
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        setStatusIsLoading(false);
    }

    return [isWhitelisted, getWhitelistStatus, statusIsLoading]
}
