import React, { useEffect, useState } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { TrustedByTitle, CallToAction, CallToActionCont, StatsCont, SingleStat, SingleStatHeader, SingleStatDetail, FeaturesCont, SingleFeature, TrustedBySmall, BenefitsCont, SingleBenefitPic, SingleBenefitText, SingleBenefitTitle, SingleBenefitDescription, SocialProofCont, SocialProofTitle, SocialProofLogoCont, LastCallToActionCont, LastCallToActionTitle, HomepageFAQCont, HomepageFAQTitle, HomepageFAQDescription, HomepageQuestion, HomepageQuestionPlusSign, HomepageFAQAnswer, FAQHref } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';
import hero_ad_img from '../data/4159.png';
import save_time_and_money from '../data/time_money.jpeg';
import unlock from '../data/lock_and_key.jpeg';
import freedom from '../data/no_begging.jpeg';
import shifty_bears_logo from '../data/shifty_bears.png';
import neon_space_logo from '../data/neon_space.png';
import sk8coin_logo from '../data/sk8coin.png';
import jittery_ape_logo from '../data/jittery_ape.png';
import gods_n_legends_logo from '../data/gods_n_legends.png';
import online_inn_logo from '../data/online_inn.png';
import metaforce_logo from '../data/metaforce.png';
import sublime_sound_logo from '../data/sublime_sound.png';
import { anchor, wax, myChain, atomicApiList, hyperionApiList, mainnetApiList, mainnetAtomicApiList, currentWebsiteURL, currentAtomicApiList, currentApiList, localServerURL, getStatsEndpoint, wombat, notificationProps } from '../data/config';
import { HomePageCont, HeroLeftSide, HeroRightSide, HeroLargeText, HeroMediumText, CallToActionHrefHomepage, CallToActionContent, HeroAd, HeroAdImage, HeroAdMessage, WojakCallToAction, SocialProofSingleLogo } from '../data/css/HomePageStyles';
import { testTheme, theme } from '../Styles';
import NotificationBanner from '../components/NotificationBanner';

const sumArray = (array, setTotalBurned) => {
  var totalWojaksBurned = Number(0);
  for(var i = 0; i < array.length; i++){
    totalWojaksBurned += Number(array[i].assets);
  }
  setTotalBurned(totalWojaksBurned);
}

const sumNfts = (array, setTotalNftsStaked) => {
  var totalStakedOnWaxdao = Number(0);
  for(var i = 0; i < array.length; i++){
    totalStakedOnWaxdao += Number(array[i].totalstaked);
  }
  setTotalNftsStaked(totalStakedOnWaxdao);
}

const HomePage = () => {

  const { totalBurned, setTotalBurned, totalNftsStaked, setTotalNftsStaked,
  } = useStateContext();

 const [faqIsExpanded, setFaqIsExpanded] = useState(false);
 const [currentExpandedFAQAnswer, setCurrentExpandedFAQAnswer] = useState("");
 const [totalFarms, setTotalFarms] = useState("575+");

  useEffect(() => {
  
    const abortCont = new AbortController();

    setTotalBurned('600');

    axios.get(`${currentAtomicApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/atomicassets/v1/burns?collection_name=hoodpunknfts&schema_name=waxywojaks&page=1&limit=100&order=desc`)
    .then((burnResponse) => {
      sumArray(burnResponse.data.data, setTotalBurned);
    })
    .catch((error) => console.log(error));

    }, [])



    useEffect(() => {
      setTotalNftsStaked('400000');
  
      const fetchData = async (api, lowerBound = 0) => {
          try {
              const farmResponse = await axios.post(`${api}/v1/chain/get_table_rows`, {
                  table: "farms",
                  scope: "waxdaofarmer",
                  code: "waxdaofarmer",
                  limit: 1000,
                  json: true,
                  lower_bound: lowerBound
              });
  
              const rows = farmResponse.data.rows;
              console.log(farmResponse)
              if (farmResponse.data.more) {
                  return rows.concat(await fetchData(api, farmResponse.data.next_key));
              } else {
                  return rows;
              }
          } catch (error) {
              console.log(`Failed on API endpoint ${api}. Error: ${error}`);
              return [];
          }
      };
  
      const handleFetchData = async () => {
          for (const api of currentApiList) {
              const rows = await fetchData(api);
              if (rows.length) {
                 
                  sumNfts(rows, setTotalNftsStaked);
                  console.log(rows)
                  setTotalFarms(rows.length);
                  break;
              }
          }
      };
  
      handleFetchData();
  }, [])



  return (
  <div id="seo">
    <Helmet>
    <title>Home - WaxDAO</title>
    <meta name="description" content="WaxDAO builds tools for creators on Wax blockchain" />
    <link rel="canonical" href={currentWebsiteURL} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>
    <HomePageCont>

      <HeroLeftSide>
        <HeroLargeText>
        <span style={{color: theme.secondary}}>NFT Market</span> And Tools On WAX
        </HeroLargeText>
        <HeroMediumText>
        WaxDAO builds tools for users and creators on WAX.
        Buy, sell and stake NFTs. Add utility to your project.
        </HeroMediumText>

        <CallToActionCont>
        <CallToActionHrefHomepage href={`${currentWebsiteURL}/drops`}>
          <CallToActionContent>
          BROWSE MARKET
          </CallToActionContent>
        </CallToActionHrefHomepage>
        <CallToActionHrefHomepage href={`${currentWebsiteURL}/create-farm`}>
          <CallToActionContent>
          CREATE NFT FARM
          </CallToActionContent>
        </CallToActionHrefHomepage>
        </CallToActionCont>

        <StatsCont>
          <SingleStat>
            <SingleStatHeader>
            {totalNftsStaked != null && <NumberFormat displayType='text' thousandSeparator={true} value={Math.floor(totalNftsStaked / 1000)} />}k+
            </SingleStatHeader>
            <SingleStatDetail>
              NFTs Staked
            </SingleStatDetail>
            
          </SingleStat>
          <SingleStat>
            <SingleStatHeader>
            {totalFarms != null && totalFarms}
            </SingleStatHeader>
            <SingleStatDetail>
              Farms Created
            </SingleStatDetail>
          </SingleStat>
          <SingleStat>
          <SingleStatHeader>
          {totalBurned != null && totalBurned}
          </SingleStatHeader>
          <SingleStatDetail>
              Wojaks Burned
            </SingleStatDetail>
          </SingleStat>
        </StatsCont>

      </HeroLeftSide>


      <HeroRightSide>
        <HeroAd>

          <HeroAdImage src={hero_ad_img} />

        
        <HeroAdMessage>
        Buy one of our Wojak NFTs and receive exclusive discounts on our products
        <br/><br/>
        <WojakCallToAction href={`${currentWebsiteURL}/drops/247`}>
          <CallToActionContent>
          Buy Now
          </CallToActionContent>
        </WojakCallToAction>
        </HeroAdMessage>
        
        </HeroAd>
      </HeroRightSide>



    </HomePageCont>

      <FeaturesCont>
        
        <SingleFeature href={`${currentWebsiteURL}/nft-farming`}>
            <TrustedByTitle>
                NFT Farms
            </TrustedByTitle>
            <TrustedBySmall>
            Any WAX project can use WaxDAO to create a staking system for their NFTs.
            </TrustedBySmall>
              
        </SingleFeature>

        <SingleFeature href={`${currentWebsiteURL}/create-blend`}>
        <TrustedByTitle>
                Super Blends
            </TrustedByTitle>
            <TrustedBySmall>
            Create advanced blends that use NFTs, fungible tokens and more.  
            </TrustedBySmall>
            
        </SingleFeature>

        <SingleFeature href={`${currentWebsiteURL}/token-creator`}>
            <TrustedByTitle>
                Token Creator
            </TrustedByTitle>
            <TrustedBySmall>
            Our token creator allows you to launch your own WAX token in just a few clicks.
            </TrustedBySmall>
        </SingleFeature >
      </FeaturesCont>

      <BenefitsCont>
        <SingleBenefitPic src={save_time_and_money} />

        <SingleBenefitText>
          <SingleBenefitTitle>
            Save time and money by using WaxDAO's pre-built creator tools.
          </SingleBenefitTitle>
          <SingleBenefitDescription>
          Hiring developers is costly and time consuming. We've done the heavy lifting for you, now you can sit back 
          and focus on your project without breaking the bank.
          </SingleBenefitDescription>
          
        </SingleBenefitText>
        </BenefitsCont>


<BenefitsCont>
        <SingleBenefitText>
          <SingleBenefitTitle>
            No more begging other platforms to "list your project" for you.
          </SingleBenefitTitle>
          <SingleBenefitDescription>
          The days of asking permission are over. WaxDAO allows you to set up a staking system for your project, without 
          ever needing to contact anyone. All you need to do is fill out the form and submit a small payment. Your staking 
          system will be up and running in minutes.
          </SingleBenefitDescription>
          
        </SingleBenefitText>
        <SingleBenefitPic src={freedom} />
        </BenefitsCont>


<BenefitsCont>
        <SingleBenefitPic src={unlock}/>
        <SingleBenefitText>
          <SingleBenefitTitle>
            Access the advanced features without needing to spend thousands of dollars.
          </SingleBenefitTitle>
          <SingleBenefitDescription>
          Many platforms have an affordable "base model" and then lock you out of the advanced features. With WaxDAO, 
          you get access to the best features without needing to spend your life savings. Most of our products are 250 
          WAX or less, including our super blender.
          </SingleBenefitDescription>
          
        </SingleBenefitText>
        </BenefitsCont>

<SocialProofCont>
  <SocialProofTitle>
  Trusted By
  </SocialProofTitle>
  <SocialProofLogoCont>
  <SocialProofSingleLogo>
    <a href="https://thebearcave.site" target="none">
    <img src={shifty_bears_logo} />
    </a>
  </SocialProofSingleLogo>
  <SocialProofSingleLogo>
    <a href="https://www.neonspace.io/" target="none">
    <img src={neon_space_logo} />
    </a>
  </SocialProofSingleLogo>
  <SocialProofSingleLogo>
    <a href="https://www.sk8coin.io/" target="none">
    <img src={sk8coin_logo} />
    </a>
  </SocialProofSingleLogo>
  <SocialProofSingleLogo>
    <a href="https://www.thejitteryape.io/" target="none">
    <img src={jittery_ape_logo} />
    </a>
  </SocialProofSingleLogo>
  </SocialProofLogoCont>

  <SocialProofLogoCont>
  <SocialProofSingleLogo>
    <a href="https://www.godsnlegends.com/" target="none">
    <img src={gods_n_legends_logo} />
    </a>
  </SocialProofSingleLogo>
  <SocialProofSingleLogo>
    <a href="https://www.foodmonsters.io/" target="none">
    <img src={online_inn_logo} />
    </a>
  </SocialProofSingleLogo>
  <SocialProofSingleLogo>
    <a href="https://metaforcecomics.com/" target="none">
    <img src={metaforce_logo} />
    </a>
  </SocialProofSingleLogo>
  <SocialProofSingleLogo>
    <a href="https://www.sublime-sound.com/" target="none">
    <img src={sublime_sound_logo} />
    </a>
  </SocialProofSingleLogo>
  </SocialProofLogoCont>
</SocialProofCont>


<HomepageFAQCont>
<HomepageFAQTitle>
  Common Questions
</HomepageFAQTitle>
<HomepageFAQDescription>
  We understand that this can be a bit overwhelming. Here is some information that might be helpful to you.
</HomepageFAQDescription>

<HomepageQuestion>
This seems too good to be true.
</HomepageQuestion>
<HomepageQuestionPlusSign onClick={() => {
   if(faqIsExpanded == false){setFaqIsExpanded(true); setCurrentExpandedFAQAnswer("Answer 1");}
   else if(faqIsExpanded == true && currentExpandedFAQAnswer != "Answer 1"){setCurrentExpandedFAQAnswer("Answer 1");}
   else{setFaqIsExpanded(false);}
}}>
  +
</HomepageQuestionPlusSign>
<HomepageFAQAnswer className={(currentExpandedFAQAnswer != "Answer 1" || faqIsExpanded == false) && "hidden"}>
Yeah, we get that a lot- seriously... we do. WaxDAO believes in making tools accessible to everyone, instead of just making rich people richer and pricing out the average person. Don't get us wrong- we are not a charity, yes we need to make a living. But, our products are priced very reasonably so everyone can use them. Skeptical? Don't take our word for it, just ask anyone on Wax Blockchain about WaxDAO. Our reputation speaks for itself.
</HomepageFAQAnswer>



<HomepageQuestion>
What other utility do the Wojak NFTs have?
</HomepageQuestion>
<HomepageQuestionPlusSign onClick={() => {
   if(faqIsExpanded == false){setFaqIsExpanded(true); setCurrentExpandedFAQAnswer("Answer 2");}
   else if(faqIsExpanded == true && currentExpandedFAQAnswer != "Answer 2"){setCurrentExpandedFAQAnswer("Answer 2");}
   else{setFaqIsExpanded(false);}
}}>
  +
</HomepageQuestionPlusSign>
<HomepageFAQAnswer className={(currentExpandedFAQAnswer != "Answer 2" || faqIsExpanded == false) && "hidden"}>
Here are a few things you can do with your Wojak NFTs:
<ul><br/>
<li>• Burn one for access to our token creator</li>
<li>• Stake them for passive $WAXDAO rewards</li> 
<li>• Burn one for access to our super blender</li>
<li>• Burn one to create an NFT farm</li>
<li>• Collect them because you enjoy them :)</li>
<li>• Burn one to create a single sided staking pool for your token</li>
<li>• Burn 2 to create a DAO</li>
<li>• More utility coming soon!</li>
</ul>
</HomepageFAQAnswer>


<HomepageQuestion>
Who created this, are they anonymous?
</HomepageQuestion>
<HomepageQuestionPlusSign onClick={() => {
   if(faqIsExpanded == false){setFaqIsExpanded(true); setCurrentExpandedFAQAnswer("Answer 3");}
   else if(faqIsExpanded == true && currentExpandedFAQAnswer != "Answer 3"){setCurrentExpandedFAQAnswer("Answer 3");}
   else{setFaqIsExpanded(false);}
}}>
  +
</HomepageQuestionPlusSign>
<HomepageFAQAnswer className={(currentExpandedFAQAnswer != "Answer 3" || faqIsExpanded == false) && "hidden"}>
WaxDAO was created by Mike D Crypto. Mike is a long-time crypto enthusiast who spent a couple years making YouTube tutorials about crypto, and then decided to start his own project. You can check out his <FAQHref href="https://youtube.com/c/MikeDCrypto" target="none">YouTube channel</FAQHref> for more information (and WaxDAO tutorials).
</HomepageFAQAnswer>


<HomepageQuestion>
Can you create a custom smart contract for me?
</HomepageQuestion>
<HomepageQuestionPlusSign onClick={() => {
   if(faqIsExpanded == false){setFaqIsExpanded(true); setCurrentExpandedFAQAnswer("Answer 4");}
   else if(faqIsExpanded == true && currentExpandedFAQAnswer != "Answer 4"){setCurrentExpandedFAQAnswer("Answer 4");}
   else{setFaqIsExpanded(false);}
}}>
  +
</HomepageQuestionPlusSign>
<HomepageFAQAnswer className={(currentExpandedFAQAnswer != "Answer 4" || faqIsExpanded == false) && "hidden"}>
Yes, we have created smart contracts for many other projects- including ShiftyBears, MetaForce Comics, Nethrim Design, Sk8coin and more! Contact MikeD_Crypto on Telegram if you need contract work done.
</HomepageFAQAnswer>


<HomepageQuestion>
I still have more questions.
</HomepageQuestion>
<HomepageQuestionPlusSign onClick={() => {
   if(faqIsExpanded == false){setFaqIsExpanded(true); setCurrentExpandedFAQAnswer("Answer 5");}
   else if(faqIsExpanded == true && currentExpandedFAQAnswer != "Answer 5"){setCurrentExpandedFAQAnswer("Answer 5");}
   else{setFaqIsExpanded(false);}
}}>
  +
</HomepageQuestionPlusSign>
<HomepageFAQAnswer className={(currentExpandedFAQAnswer != "Answer 5" || faqIsExpanded == false) && "hidden"}>
Feel free to join our <FAQHref href="https://t.me/hoodpunks" target="none">Telegram</FAQHref> and get involved in the community. Mike is there every day to answer questions, and if he isn't, someone else will help you.
</HomepageFAQAnswer>

</HomepageFAQCont>





<LastCallToActionCont>
<LastCallToActionTitle>
Get started now by buying a Wojak NFT
</LastCallToActionTitle>
<br/><br/>
        <WojakCallToAction href={`${currentWebsiteURL}/drops/247`}>
          <CallToActionContent>
          Buy Now
          </CallToActionContent>
        </WojakCallToAction>


</LastCallToActionCont>


    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default HomePage