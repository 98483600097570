import React, { useEffect } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { NewestSingleDropCont, FarmLogo, FarmTitle, FarmEndDate, PoolAmount, SingleDropLogo, DropDown, FilterMenu, DropType, SoldOut, AllFarmsCont, DropIsLive } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';

import { currentWebsiteURL } from '../../data/config';
import { dropIsLive, dropIsSoldOut } from '../../data/functions/DropFunctions';
import DropCard from '../../components/DropCard';



const DropListDiv = (props) => {

  const { dropList, dropSortMode, dropFilters } = useStateContext();




  return (
<AllFarmsCont>
<div className="flex flex-wrap gap-5 mt-4 justify-center">

{/* Sort by ending soonest */}

  {dropSortMode != null && dropSortMode == "ending_soonest" && (dropList?.sort((a,b) => a.end_time - b.end_time).map((item, index) => (
    <span key={index} className={( (!dropIsLive(item) && dropFilters.indexOf("Live drops only") > -1) || (item.drop_type != null && item.drop_type == "premint.pack") || ( dropIsSoldOut(item) && dropFilters.indexOf("Hide sold out") > -1 ) ) && "hidden"}>
    <a href={`${currentWebsiteURL}/drops/${item.ID}`}>
    <DropCard item={item} />
    </a>
    </span>
  )))}
    


{/* Sort by ending latest */}

  {dropSortMode != null && dropSortMode == "ending_latest" && (dropList?.sort((a,b) => b.end_time - a.end_time).map((item, index) => (
   <span key={index} className={( (!dropIsLive(item) && dropFilters.indexOf("Live drops only") > -1) || (item.drop_type != null && item.drop_type == "premint.pack") || ( dropIsSoldOut(item) && dropFilters.indexOf("Hide sold out") > -1 ) ) && "hidden"}>
    <a href={`${currentWebsiteURL}/drops/${item.ID}`}>
    <DropCard item={item} />
    </a>
    </span>
  )))}
    




{/* Sort by newest */}

  {dropSortMode != null && dropSortMode == "oldest" && (dropList?.sort((a,b) => a.start_time - b.start_time).map((item, index) => (
    <span key={index} className={( (!dropIsLive(item) && dropFilters.indexOf("Live drops only") > -1) || (item.drop_type != null && item.drop_type == "premint.pack") || ( dropIsSoldOut(item) && dropFilters.indexOf("Hide sold out") > -1 ) ) && "hidden"}>
    <a href={`${currentWebsiteURL}/drops/${item.ID}`}>
    <DropCard item={item} />
    </a>
    </span>
  )))}
    




{/* Sort by oldest */}

    {dropSortMode != null && dropSortMode == "newest" && (dropList?.sort((a,b) => b.start_time - a.start_time).map((item, index) => (
      <span key={index} className={( (!dropIsLive(item) && dropFilters.indexOf("Live drops only") > -1) || (item.drop_type != null && item.drop_type == "premint.pack") || ( dropIsSoldOut(item) && dropFilters.indexOf("Hide sold out") > -1 ) ) && "hidden"}>
      <a href={`${currentWebsiteURL}/drops/${item.ID}`}>
      <DropCard item={item} />
      </a>
      </span>
    )))}
    


</div>
</AllFarmsCont>    

  )
}

export default DropListDiv