import React, { useEffect } from 'react'
import { currentAdBanner, currentWebsiteURL } from '../data/config';
import { useGetBannerAds } from './CustomHooks/useGetBannerAds';
import { resizerPrefix } from '../data/constants';

const CurrentBannerAd = () => {

const [bannerAds, getBannerAds] = useGetBannerAds();

useEffect(() => {

    getBannerAds();
      
      }
    
    , []);
    


  return (
    <span>


{bannerAds != null && bannerAds[0] != null && (
<span>
{bannerAds[0].info[0] == "waxdao_logo" ? (
<span>
<br/>
<a href={`${currentWebsiteURL}/drops/247`}>
<img src={currentAdBanner} className="ml-auto mr-auto" />
</a>
</span>
) : (
<span>
<br/>
<a href={`${bannerAds[0].info[1].indexOf("http") > -1 ? bannerAds[0].info[1] : "https://" + bannerAds[0].info[1]}`} target="none">
<img src={`${resizerPrefix}${bannerAds[0].info[0]}`} className="ml-auto mr-auto" style={{width: "auto", maxWidth:"95%", height:"auto", maxHeight: "200px"}} />
</a>
</span>
) }

</span>
      )}


    </span>
  )
}

export default CurrentBannerAd