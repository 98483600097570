import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  MainContainer,
  DaoTitle,
  DaoButton,
  CreateDaoCont,
  DAO,
  DaosCont,
  MyDaoTitle,
} from "../components/LeftDiv";
import {
  currentApiList,
  currentAtomicApiList,
  wax,
  anchor,
  myChain,
  currentWebsiteURL,
  wombat,
  getCurrentApiList,
} from "../data/config";
import LoadingPage from "../components/LoadingPage";
import Error from "../components/Error";
import { JobOfferTitle, NewListingBody, NewListingCont, TallFiltersCont } from "../Styles";
import DAOCard from "../components/DAOCard";
import { filterDaosByLocked } from "../data/functions/dao_functions";

const DAOs = () => {
  const [daoList, setDaoList] = useState([]);
  const [daosAreLoading, setDaosAreLoading] = useState(true);

  useEffect(() => {
    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "daos",
        scope: "buildawaxdao",
        code: "buildawaxdao",
        limit: 1000,
        json: true,
      })
      .then((response) => {
        setDaoList(response.data.rows);
      })
      .catch((error) => console.log(error))
      .finally(() => setDaosAreLoading(false));
  }, []);

  if (!daosAreLoading && daoList?.length > 0) {
    return (
      <div id="seo">
        <Helmet>
          <title>All DAOs</title>
          <meta name="description" content="View all DAOs on WaxDAO" />
          <link rel="canonical" href="https://waxdao.io/daos" />
        </Helmet>

        <UALProvider
          chains={[myChain]}
          authenticators={[anchor, wax, wombat]}
          appName={"WaxDAO"}
        >
          <NewListingCont>
            <NewListingBody>
              <TallFiltersCont>
                <h2>All DAOs</h2>
                </TallFiltersCont>
              <div
                className={ "flex flex-wrap gap-5 mt-4 justify-center"

                }
              >
                {daoList != null &&
                  filterDaosByLocked(daoList)
                    .map((item, index) => (
             
                        <a href={`${currentWebsiteURL}/daos/${item.daoname}`}>
                          <DAOCard item={item} />
                        </a>

                    ))}
              </div>
            </NewListingBody>
          </NewListingCont>

   
        </UALProvider>
      </div>
    );
  } else if (daosAreLoading) {
    return <LoadingPage />;
  } else if (!daosAreLoading && (daoList[0] == null || daoList.length == 0)) {
    return <Error error="API error, please refresh the page" />;
  }
};

export default DAOs;
