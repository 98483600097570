import React, { useState } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { DropFilterButton, FilterMenu } from '../../components/LeftDiv';
import { DropFiltersCont, DefaultButton, DefaultButton2 } from '../../data/css/DropStyles';
import { ExploreFiltersCont, ExploreFiltersScrollCont, SkillsButton } from '../../Styles';
import { DROP_FILTERS } from '../../data/constants';
  
const handleDropFilter = (textToFind, dropFilters, setDropFilters) => {
    const list = [...dropFilters];
    if(list.indexOf(textToFind) > -1){
        list.splice(list.indexOf(textToFind), 1);
        setDropFilters(list);
    } else {
        setDropFilters([...list, textToFind]);
    }
}

export const handleFilterChange = (e, filters, setFilters) => {
  const value = e.target.value;
  var list;
  list = [...filters]; 
  if(list.indexOf(value) > -1){list.splice(list.indexOf(value), 1);}
  else{list.push(value);}
  setFilters(list);
  return;
}


const DropFilters = (props) => {

  const { dropList, dropSortMode, setDropSortMode, dropFilters, setDropFilters } = useStateContext();

  const [filters, setFilters] = useState([]);


  return (
/*
<DropFiltersCont>

<form>
        <FilterMenu        
          onChange={(e) => {setDropSortMode(e.target.value)}}
          >
        <option value="" hidden>
          Sort
        </option>
        <option value="ending_soonest">Ending Soonest</option>
        <option value="ending_latest">Ending Latest</option>
        <option value="newest">Newest</option>
        <option value="oldest">Oldest</option>
        </FilterMenu>
        </form>

<DefaultButton a={dropFilters} onClick={() => { handleDropFilter("Live drops only", dropFilters, setDropFilters) }} >
    Live Only
</DefaultButton>

<DefaultButton2 a={dropFilters} onClick={() => { handleDropFilter("Hide sold out", dropFilters, setDropFilters) }} >
    Hide Sold Out
</DefaultButton2>


</DropFiltersCont>    
*/

<ExploreFiltersCont>
  <h2>Filters</h2>

<ExploreFiltersScrollCont>
{DROP_FILTERS?.length > 0 && DROP_FILTERS.map((item, index) => (
    <SkillsButton value={item.value} onClick={(e) => handleDropFilter(item.value, dropFilters, setDropFilters) } selectedList={dropFilters} thisSkill={item.value.toString()} >
        {item.display_text}
    </SkillsButton>
))}

</ExploreFiltersScrollCont>
</ExploreFiltersCont>



  )
}

export default DropFilters