import React, { useEffect, useState } from "react";
import { useStateContext } from "../../contexts/ContextProvider";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  currentApiList,
  currentAtomicApiList,
  wax,
  anchor,
  myChain,
  currentWebsiteURL,
  wombat,
  getCurrentApiList,
  v2DAOContract,
} from "../../data/config";
import LoadingPage from "../../components/LoadingPage";
import Error from "../../components/Error";
import { NewListingBody, NewListingCont, TallFiltersCont } from "../../Styles";
import V2DAOCard from "../../components/V2DAOCard";
import { useGetV2DAOProfiles } from "../../components/CustomHooks/useGetV2DAOProfiles";
import { matchDAOProfile } from "./dao_v2_functions";

const DAOsV2 = () => {
  const [daoList, setDaoList] = useState([]);
  const [daosAreLoading, setDaosAreLoading] = useState(true);
  const [daoProfiles, getDaoProfiles, profilesAreLoading] = useGetV2DAOProfiles();

  useEffect(() => {
    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "daos",
        scope: v2DAOContract,
        code: v2DAOContract,
        limit: 1000,
        json: true,
      })
      .then((response) => {
        setDaoList(response.data.rows);
      })
      .catch((error) => console.log(error))
      .finally(() => setDaosAreLoading(false));
  }, []);

  useEffect(() => {
    if(!daosAreLoading){
        getDaoProfiles();
    }
  }, [daosAreLoading]);  



  if (!daosAreLoading && daoList?.length > 0 && !profilesAreLoading) {
    return (
      <div id="seo">
        <Helmet>
          <title>All DAOs</title>
          <meta name="description" content="View all DAOs on WaxDAO" />
          <link rel="canonical" href="https://waxdao.io/v2/daos" />
        </Helmet>

        <UALProvider
          chains={[myChain]}
          authenticators={[anchor, wax, wombat]}
          appName={"WaxDAO"}
        >
          <NewListingCont>
            <NewListingBody>
              <TallFiltersCont>
                <h2>All DAOs</h2>
                </TallFiltersCont>
              <div
                className={ "flex flex-wrap gap-5 mt-4 justify-center"

                }
              >
                {daoList != null &&
                  daoList.sort((a, b) => b.time_created - a.time_created)
                    .map((item, index) => (
             
                        <a href={`${currentWebsiteURL}/v2/daos/${item.daoname}`}>
                          <V2DAOCard item={item} profile={matchDAOProfile(item.daoname, daoProfiles)} />
                        </a>

                    ))}
              </div>
            </NewListingBody>
          </NewListingCont>

   
        </UALProvider>
      </div>
    );
  } else if (daosAreLoading || profilesAreLoading) {
    return <LoadingPage />;
  } else if (!daosAreLoading && (daoList[0] == null || daoList.length == 0)) {
    return <Error error="API error, please refresh the page" />;
  }
};

export default DAOsV2;
