import React from 'react';
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import { Modal } from '../../components';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import {Helmet} from "react-helmet";
import { currentWebsiteURL } from '../../data/config';


const PoolFAQ = () => {
    const {  currentColor } = useStateContext();

    return (
      <div id="seo">
      <Helmet>
      <title>Staking Pool FAQ - WaxDAO</title>
      <meta name="description" content="Answers to frequently asked questions about Waxdao staking pools." />
      <link rel="canonical" href="https://waxdao.io/pool-faq" />
      </Helmet>
      <UALProvider chains={[myChain]} authenticators={[anchor]} appName={"WaxDAO"}>
      <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
        <Header title="Staking Pool FAQ" category="Info" />
        <div className="justify-center items-center">
        <div>
        <p className="mt-4 text-xl">Looking for more info about our staking pools?</p>
        <p className="mt-4 text-xl">You came to the right place.</p>
        </div>
          <h2
            className="text-3xl font-semibold mt-4"
          >What Is A Staking Pool?</h2>
          
          <p className="mt-4 text-xl">WaxDAO staking pools allow creators to reward their token holders.</p>
          <p className="mt-4 text-xl">Users can stake their tokens into a pool, and receive hourly staking rewards directly into their Wax wallet.</p>

          <h2
            className="text-3xl font-semibold mt-4"
          >How Much Staking Rewards Will I Receive?</h2>
          <p className="mt-4 text-xl">The reward rate is set by the person who creates the staking pool.</p>
          <p className="mt-4 text-xl">You can view the current staking rate on the page of any pool. However, note that this amount will fluctuate as people join/leave the pool.</p>


          <h2
            className="text-3xl font-semibold mt-4"
          >How Can I Create A Staking Pool?</h2>
          <p className="mt-4 text-xl">All you need to do is go to the <a href={`${currentWebsiteURL}/create-pool`} style={{color: currentColor}}>create-pool page</a> and fill out the form.</p>
          <p className="mt-4 text-xl">Then, submit the transaction and your pool will be created.</p>
          <p className="mt-4 text-xl">Once your pool is created, you can add rewards to it by going to the <a href={`${currentWebsiteURL}/my-pools`} style={{color: currentColor}}>My Pools page.</a></p>

          <h2
            className="text-3xl font-semibold mt-4"
          >How Much Does It Cost?</h2>
          <p className="mt-4 text-xl">There are 3 options for creating a new pool. You can either pay 250 Wax, <a href="https://wax.alcor.exchange/swap?input=WAX-eosio.token&output=WOJAK-mdcryptonfts" target="none" style={{color: currentColor}}>25,000 WOJAK</a> or burn <a href={`${currentWebsiteURL}/drops/247`} style={{color: currentColor}}>1 Wojak NFT</a>.</p>
          <p className="mt-4 text-xl">Since the prices of all of these will fluctuate, we recommend checking current market conditions to figure out which is the cheapest option.</p>
          <p className="mt-4 text-xl">Note that these prices are subject (and likely) to change over time. It takes a lot of CPU for our contract to perform the calculations required for your staking pool.</p>
          <p className="mt-4 text-xl">Due to this cost, there is a high likelihood that these low prices won't last forever.</p>
        

          <h2
            className="text-3xl font-semibold mt-4"
          >Any Advice For Creating A Staking Pool?</h2>
          <p className="mt-4 text-xl">One thing that we would definitely suggest, is to make sure that your reward token has a sufficient amount of decimal places (8 is ideal).</p>
          <p className="mt-4 text-xl">I won't go too deep into the boring math stuff, but basically here's how it works.</p>
          <p className="mt-4 text-xl">If your token only allows whole numbers for example (0 decimal places), then let's say there are 1000 users in your pool.
          Each one is supposed to get 0.1 tokens per hour. Except "0.1" does not exist, because you used a token with no decimal places.
          </p>
          <p className="mt-4 text-xl">The contract will take 100 tokens from your balance to send these stakers their rewards. That 0.1 gets rounded down to the smallest possible unit (1 whole number), so each user gets 0 tokens.
          Your pool loses 10 tokens, your stakers get 0. 
          </p>
          <p className="mt-4 text-xl">You can avoid that scenario by using a reward token that has enough decimal places to split rewards amongst all of the stakers. Setting a minimum staking amount can also help with this.
          </p>

          <p className="mt-4 text-xl"><span className="font-bold">TL;DR:</span> Use a reward token with 8 or more decimal places if possible. Set a minimum staking amount on your pool.
          Yes, you can use less than 8 decimal places. It's not the end of the world, but it won't be ideal.
          </p>
        


          <h2
            className="text-3xl font-semibold mt-4"
          >Why Do Users Need To Deposit RAM Before They Can Stake?</h2>
          <p className="mt-4 text-xl">We are providing a service that requires a ton of data to be stored on the blockchain.</p>
          <p className="mt-4 text-xl">Storing this data has a (temporary) cost.</p>
          <p className="mt-4 text-xl">Since our prices are so low, there is no profit margin for us to spend on storing data for users.</p>
          <p className="mt-4 text-xl">Furthermore, requiring a small (1 WAX) deposit from the user protects the contract (and your staking pool) from being spammed.</p>
          <p className="mt-4 text-xl">If a user did not have to make this small deposit, then anyone could create thousands of accounts and spam our contract for free.</p>
          <p className="mt-4 text-xl">This 1 WAX deposit fee is meant to mitigate those attacks, but don't worry- users automatically get that 1 WAX back once they unstake their entire balance from the pool.</p>
          <p className="mt-4 text-xl">So, it's just a temporary "hold", and not an actual fee.</p>
          <p className="mt-4 text-xl">The alternative option would be for us to drastically increase our prices. And I don't think anyone wants that, right?</p>
        


          <h2
            className="text-3xl font-semibold mt-4"
          >How Come I Receive Different Amount Of Rewards Every Day?</h2>
          <p className="mt-4 text-xl">Our staking pools are based on a shared pool model.</p>
          <p className="mt-4 text-xl">The pool is split up amongst all of the people who stake in it. As people come and go, the reward rate will fluctuate.</p>
          <p className="mt-4 text-xl">In other words, the more people who join a pool- the less tokens each person gets. Supply and demand.</p>
         

          <h2
            className="text-3xl font-semibold mt-4"
          >When Can I Unstake My Tokens?</h2>
          <p className="mt-4 text-xl">The creator of the pool is able to set a minimum staking period when they create the pool.</p>
          <p className="mt-4 text-xl">This number will be different for each pool, but never less than 1 day.</p>
          <p className="mt-4 text-xl">You can view the minimum staking period on the pool's page. Note: this timer resets every time you stake more tokens.</p>
         

          <h2
            className="text-3xl font-semibold mt-4"
          >Can I Add Multiple Tokens To The Staking Pool?</h2>
          <p className="mt-4 text-xl">At this time, you can only add 1 reward token per pool.</p>
          <p className="mt-4 text-xl">However, you can create as many pools as you like (provided that you pay the pool creation fee).</p>

          <h2
            className="text-3xl font-semibold mt-4"
          >Can I Remove Tokens From The Reward Pool?</h2>
          <p className="mt-4 text-xl">You can only add tokens to a pool, there is no option to remove them.</p>
          <p className="mt-4 text-xl">We don't think that allowing withdrawals is a good idea, since it will lead to rugpulls.</p>
          <p className="mt-4 text-xl">A project creates a pool, gets people to buy their token, then removes the rewards... we don't see any good reason to add that feature.</p>
          <p className="mt-4 text-xl">However, if you are worried that no one will use your pool- you can just stake some qualifying tokens into that pool.
          That way, if no one else joins, you will get your deposit back in the form of staking rewards.
          </p>



          <h2
            className="text-3xl font-semibold mt-4"
          >Who Can I Speak To If I Have More Questions?</h2>
          <p className="mt-4 text-xl">Feel free to join us on <a href="https://t.me/hoodpunks" style={{color: currentColor}}>Telegram</a> if you need to reach out.</p>


        </div>
  
        
    
       
      
      </div>
      </UALProvider>
      </div>
  )
}

export default PoolFAQ