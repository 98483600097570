import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import axios from "axios";
import NumberFormat from "react-number-format";
import {
  currentApiList,
  currentAtomicApiList,
  wax,
  anchor,
  myChain,
  currentWebsiteURL,
  wombat,
  getCurrentApiList,
  currentUsername,
  v2DAOContract,
} from "../../data/config";

import {
  CloseWindow,
  DAO,
  Modal,
  ModalContent,
  Spinner,
  SpinnerBlue,
  SpinnerGreen,
  SpinnerRed,
  SubmitButton,
} from "../../components/LeftDiv";
import { PROPOSAL_STATUSES, V2_PROPOSAL_STATUSES } from "../../data/constants";
import { finalizeV2Proposal, formatEndTime, showChoices, showCurrentResults, showProposalOutcomeText, showTransferWarning } from "./proposal_v2_functions";
import { FarmDescriptionContainer, MilestoneDescription, NewListingBody, NewListingCont, TallFiltersCont } from "../../Styles";
import { ChoiceButton, SubmitPropV2Button } from "./DAOStylesV2";
import { useGetV2ProposalInfo } from "../../components/CustomHooks/useGetV2ProposalInfo";
import LoadingPage from "../../components/LoadingPage";
import Error from "../../components/Error";
import { useGetV2DAOInfo } from "../../components/CustomHooks/useGetV2DAOInfo";
import { useGetProofAssetsForVoting } from "../../components/CustomHooks/useGetProofAssetsForVoting";
import { isLoggedIn } from "../../data/functions/wallet_functions";
import { voteInV2DAO } from "./dao_v2_functions";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const ProposalPageV2 = ({ location }) => {
  const { currentColor } =
    useStateContext();

  const [enterModalDisplay, setEnterModalDisplay] = useState("hidden");
  const [enterModalText, setEnterModalText] = useState("");
  const [loadingDisplay, setLoadingDisplay] = useState("none");
  const [enterButtonsDisplay, setEnterButtonsDisplay] = useState("");

  const [selectedChoice, setSelectedChoice] = useState(null);

  //custom hooks
  const [proposalInfo, getProposalInfo, infoIsLoading] = useGetV2ProposalInfo();
  const [daoData, getDaoData, daoDataIsLoading] = useGetV2DAOInfo();
  const [proofAssets, getProofAssetsForVoting] = useGetProofAssetsForVoting();

  const { DAOName, ProposalID } = useParams();

  useEffect(() => {
    getProposalInfo(DAOName, ProposalID);
  }, []);

  useEffect(() => {
    if(!infoIsLoading && proposalInfo != null){
      getDaoData(DAOName);
    }
  }, [infoIsLoading, proposalInfo])

  useEffect(() => {
    //if daodata was loaded and the dao type is relevant, get their proof assets
    if(!infoIsLoading && !daoDataIsLoading && proposalInfo != null && daoData != null){
      if(isLoggedIn() && daoData.dao_type == 5){
        getProofAssetsForVoting(currentUsername, daoData.gov_schemas);
      }      
    }


  }, [infoIsLoading, daoDataIsLoading])
  
  


  if(!infoIsLoading && proposalInfo != null && !daoDataIsLoading && daoData != null){
  return (
    <div id="seo">
      <Helmet>
        <title>Proposal {ProposalID} - WaxDAO</title>
        <meta
          name="description"
          content={`Vote on proposal ${ProposalID} on WaxDao`}
        />
        <link
          rel="canonical"
          href={`${currentWebsiteURL}/v2/${DAOName}/proposals/${ProposalID}`}
        />
      </Helmet>

      <UALProvider
        chains={[myChain]}
        authenticators={[anchor, wax, wombat]}
        appName={"WaxDAO"}
      >
        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <CloseWindow onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </CloseWindow>
        </Modal>
        <br />
        <br />
        <br />

        {proposalInfo != null && (
          <span>
            <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
              <h2 className="text-lg">
                {proposalInfo.title != null && proposalInfo.title}{" "}
                {DAOName != null && (
                  <span
                    className="p-1 pl-2 pr-2 text-sm border-1 rounded-2xl font-semibold"
                    style={{ background: "#F8F8FF", color: "#696969" }}
                  >
                    <a href={`${currentWebsiteURL}/v2/daos/${DAOName}`}>
                      in {DAOName}
                    </a>
                  </span>
                )}
              </h2>

              <div
                className="flex flex-wrap gap-2 mt-2"
                style={{ justifyContent: "right", marginBottom: "10px" }}
              >
                {proposalInfo.outcome != null && (
                  <div className="inline-block p-1 pl-2 pr-2 text-sm border-1 rounded-2xl">
                  {showProposalOutcomeText(proposalInfo.end_time, proposalInfo.outcome)}
                  </div>
                )}

                {proposalInfo.proposal_id != null && (
                  <div className="inline-block p-1 pl-2 pr-2 text-sm border-1 rounded-2xl">
                    ID #{proposalInfo.proposal_id}
                  </div>
                )}

                <div className="inline-block p-1 pl-2 pr-2 text-sm border-1 rounded-2xl">
                  {proposalInfo?.end_time != null &&
                    formatEndTime(proposalInfo.end_time)}
                </div>
              </div>

              <div
                className="p-2 text-sm rounded-sm"
                style={{ backgroundColor: "#F8F8FF" }}
              >
                <div className="p-2 text-md inline-block">
                  <span className="font-semibold" style={{ color: "#696969" }}>
                    TOTAL VOTES:
                  </span>{" "}
                  <br />
                  <span className="font-bold">{proposalInfo?.total_votes}</span>
                </div>
              </div>

            </div>

            <NewListingCont>
              <NewListingBody>
                    {showTransferWarning(proposalInfo)}

                <FarmDescriptionContainer>
                  <h2>Description</h2>
                  <MilestoneDescription>
                    <ReactMarkdown>
                    {proposalInfo?.description != null && proposalInfo.description}
                    </ReactMarkdown>
                  </MilestoneDescription>
                </FarmDescriptionContainer> 
                <br/><br/>                   


                <h2>Current Results</h2>
                <br/>
                {showCurrentResults(proposalInfo.choices, proposalInfo.total_votes)}


                <br/><br/>
                <h2 className="text-lg mt-2">Vote</h2>
                <br />

                {showChoices(
                  proposalInfo.choices,
                  selectedChoice,
                  setSelectedChoice
                )}

                <div className="items-center text-center">
                  <SubmitPropV2Button
                    disabled={selectedChoice === null}
                    selected={selectedChoice != null}
                    onClick={() => {
                      if (selectedChoice !== null) {
                        voteInV2DAO(
                          DAOName,
                          ProposalID,
                          selectedChoice,
                          proofAssets,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay
                        );
                      }
                    }}
                  >
                    Submit Vote
                  </SubmitPropV2Button>
                    {daoData?.dao_type == 5 && (
                      <span>
                        <br/><br/>
                        You have {proofAssets.length} NFTs to vote with
                        <br/><br/>
                      </span>
                    )}
                  


                  {proposalInfo.end_time != null && (
                    <button
                      type="button"
                      className="text-lg p-3
        hover:drop-shadow-xl
        hover:bg-light-gray
        text-white rounded-lg mt-4"
                      disabled={
                        proposalInfo.end_time > Math.round(Date.now() / 1000) ||
                        proposalInfo.outcome !== 0
                      }
                      onClick={() => {
                        if (
                          proposalInfo.end_time <=
                            Math.round(Date.now() / 1000) &&
                          proposalInfo.outcome === 0
                        ) {
                          finalizeV2Proposal(
                            DAOName,
                            ProposalID,
                            setEnterModalText,
                            setLoadingDisplay,
                            setEnterButtonsDisplay,
                            setEnterModalDisplay
                          );
                        }
                      }}
                      style={{
                        background:
                          proposalInfo.outcome !== 0 ||
                          proposalInfo.end_time > Math.round(Date.now() / 1000)
                            ? "#DCDCDC"
                            : currentColor,
                        width: "60%",
                        color:
                          proposalInfo.outcome !== 0 ||
                          proposalInfo.end_time > Math.round(Date.now() / 1000)
                            ? "#FFFAFA"
                            : "white",
                      }}
                    >
                      {proposalInfo.outcome !== 0
                        ? "Finalized"
                        : "Finalize Proposal"}
                    </button>
                  )}

                  <br />
                </div>
              </NewListingBody>
            </NewListingCont>
          </span>
        )}

        {/* END IF PROPOSAL ID EXISTS */}

        {proposalInfo == null && (
          <span>
            <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
              <p>This proposal ID does not exist</p>
            </div>
          </span>
        )}
      </UALProvider>
      <br />
      <br />
      <br />
    </div>
  );
        }
      else if(infoIsLoading || daoDataIsLoading){
        return <LoadingPage />
      }
      else if(!infoIsLoading && proposalInfo == null){
        return <Error error={"Proposal could not be found"} />
      }

};

export default ProposalPageV2;
