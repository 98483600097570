import { useState } from "react";
import { currentApiList, getCurrentApiList } from "../../data/config";
import axios from 'axios';

export const useGetBannerAds = () => {
    
    const [bannerAd, setBannerAd] = useState([]);

    const getBanner = () => {
        
        axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
            table:"bannerads",
            scope:"waxdaofarmer",
            code:"waxdaofarmer",
            limit:100,
            lower_bound: (Math.round(Date.now() / 1000) - 86400),
            upper_bound: Math.round(Date.now()),
            json:true
            }).then((highlightResponse) => {
        
                setBannerAd(highlightResponse.data.rows);
                console.log(highlightResponse.data.rows);
        
        
        
        
            })
            
            .catch((error) => console.log(error));

    }

    return [bannerAd, getBanner]
}