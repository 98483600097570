import React, { useState } from 'react'
import { ipfsPrefix, resizerPrefix, resizerSuffix } from '../data/constants';
import { DropCardBottomHalf, DropCardEndDate, DropCardImage, DropCardName, DropCardTitle, DropCardTopHalf, DropCardWrapper } from '../data/css/DropStyles'
import { NFTCardCheckbox, ShowNFTInfo } from '../data/css/PremiintStyles';
import { WalletCardMint, WalletCardVideo } from '../data/css/WalletStyles';
import { handleAddAsset, handleAddingToRemoval, handleRemoveAsset, handleRemovingFromRemoval } from '../data/functions/premint_functions';
import { ModalOverlay } from './LeftDiv';
import { ImInfo } from 'react-icons/im'
import AttributeModal from './AttributeModal';
import { FarmNFTStakingRate } from '../data/css/FarmPageStyles';
import { currentWebsiteURL } from '../data/config';

const FarmNFTCard = (props) => {
    const item = props.item;
    const assetVector = props.assetVector;
    const setAssetVector = props.setAssetVector;
    const editMode = props.editMode;
    const removalVector = props.removalVector;
    const setRemovalVector = props.setRemovalVector;
    const rewardType = props.rewardType;
    const dynamicTemps = props.dynamicTemps;

    const [showAttributeModal, setShowAttributeModal] = useState(false);

  return (
    <DropCardWrapper>
        <DropCardTopHalf>
            {item.data.img != null && (
                <DropCardImage src={`${resizerPrefix}${item.data.img}${resizerSuffix}`} />
            )}
            {item.data != null && item.data.img == null && item.data.video != null && (
                <WalletCardVideo controls loop>
                    <source 
                    src={`${ipfsPrefix}${item.data.video.trim()}${resizerSuffix}`} 
                    type="video/mp4"/>
                </WalletCardVideo>
            )}
        </DropCardTopHalf>
            
        {editMode == 0 && (
            <NFTCardCheckbox selected={assetVector.indexOf(item.asset_id) > -1 ? "yes" : "no"} onClick={() => {assetVector.indexOf(item.asset_id) > -1 ? handleRemoveAsset(item.asset_id, assetVector, setAssetVector) : handleAddAsset(item.asset_id, assetVector, setAssetVector)}}>
                {assetVector.indexOf(item.asset_id) > -1 && (<span>&#x2713;</span>)}
            </NFTCardCheckbox>
        )}
        {editMode == 1 && (
            <NFTCardCheckbox selected={removalVector.indexOf(item.asset_id) > -1 ? "yes" : "no"} onClick={() => {removalVector.indexOf(item.asset_id) > -1 ? handleRemovingFromRemoval(item.asset_id, removalVector, setRemovalVector) : handleAddingToRemoval(item.asset_id, removalVector, setRemovalVector)}}>
                {removalVector.indexOf(item.asset_id) > -1 && (<span>&#x2713;</span>)}
            </NFTCardCheckbox>
        )}


        <ShowNFTInfo onClick={() => setShowAttributeModal(true)}>
            <ImInfo className='ml-auto mr-auto' />
        </ShowNFTInfo>

        {showAttributeModal && (
            <span>
                <ModalOverlay />
                <AttributeModal showAttributeModal={showAttributeModal} setShowAttributeModal={setShowAttributeModal} item={item} />
            </span>
        )}

        {item.template_mint != null && (
            <WalletCardMint>
            #{item.template_mint}
            </WalletCardMint>
        )}

      {item.collection != null && (
          <a href={`${currentWebsiteURL}/collection/${item.collection.collection_name}`}>
        <DropCardTitle>
        {item.collection.collection_name}
        </DropCardTitle>
        </a>
      )}

        {item.data.name != null && (
            <DropCardName>
                {item.data.name}
            </DropCardName>
        )}

        <DropCardBottomHalf />

        {item.end_time != null && item.total_left != null && item.total_left != '0' &&(
            <DropCardEndDate>
                Ends {new Date(item.end_time * 1000).toLocaleDateString()}
            </DropCardEndDate>
        )}

        {rewardType == "dynamic" && dynamicTemps?.length > 0 && (
            <FarmNFTStakingRate>
            {dynamicTemps[dynamicTemps.findIndex(attributeIndex => attributeIndex.template_id === parseInt(item.template.template_id))]?.reward_amount}/hr
            </FarmNFTStakingRate>
        )}


    </DropCardWrapper>
  )
}

export default FarmNFTCard