import { useState } from "react";
import { currentApiList, getCurrentApiList, getCurrentAtomicApiList } from "../../data/config";
import axios from 'axios';

export const useGetUnstakedAssets = () => {

    let list = [];

    const [unstaked, setUnstaked] = useState([]);

    const getUnstakedAssets = async (waxUsername, farmname, farmtype, rewardType, thisCollection, thisSchema, retry = 3) => {        
        try {
            if (farmtype === "collection" || (farmtype === "template" && rewardType !== "dynamic")) {
                const atomicResponse = await axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${thisCollection}&owner=${waxUsername}&page=1&limit=1000&order=desc&sort=transferred`);

                list = atomicResponse.data.data;
                setUnstaked(atomicResponse.data.data);

                if (atomicResponse.data.data.length === 1000) {
                    const atomicResponse2 = await axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${thisCollection}&owner=${waxUsername}&page=2&limit=1000&order=desc&sort=transferred`);
                    list = list.concat(atomicResponse2.data.data);
                    setUnstaked(list);
                }
            }
            else if (farmtype === "schema" || farmtype === "s.exclude") {
                const atomicResponse = await axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${thisCollection}&schema_name=${thisSchema}&owner=${waxUsername}&page=1&limit=1000&order=desc&sort=transferred`);
                setUnstaked(atomicResponse.data.data);
            }
            else if ((farmtype === "template" && rewardType === "dynamic") || (farmtype === "nc.c.shared") || (farmtype === "nc.v2" && rewardType === "dynamic")) {
                const atomicResponse = await axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${thisCollection}&owner=${waxUsername}&page=1&limit=1000&order=desc&sort=transferred`);

                list = atomicResponse.data.data;
                setUnstaked(atomicResponse.data.data);

                if (atomicResponse.data.data.length === 1000) {
                    const atomicResponse2 = await axios.get(`${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${thisCollection}&owner=${waxUsername}&page=2&limit=1000&order=desc&sort=transferred`);
                    list = list.concat(atomicResponse2.data.data);
                    setUnstaked(list);
                }
            }
        } catch (error) {
            if (retry > 0) {            
                return getUnstakedAssets(waxUsername, farmname, farmtype, rewardType, thisCollection, thisSchema, retry - 1);
            }
            throw error;
        }
    }

    return [unstaked, getUnstakedAssets]
}
