import { useState } from "react";

export const useSelectItem = () => {
    
    const [state, setState] = useState('some state');

    const toggle = (item) => {
        if(state == item){
            setState('some state');
        } else {
            setState(item);
            console.log(item)
        }
        


    }

    return [state, toggle]
}