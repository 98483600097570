import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { MdSwapVerticalCircle } from 'react-icons/md';
import { AllFarmsCont, BlendMessage, DaoButton, FarmsPageCont, FarmsTitle, GameButton, MissingInfoMsg, Modal, ModalContent, Price, ProposalInput, ProposerTypeMessage, SingleDropCont, Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed } from '../components/LeftDiv';
import { myChain, wax, anchor, currentApiList, currentAtomicApiList, adminMode, getCurrentApiList, wombat } from '../data/config';
import { HowItWorks, OTCMainCont, OTCSwapCont } from '../data/css/OTCStyles';
import NumberFormat from 'react-number-format';
import { transferTokens } from '../data/functions/TokenCreatorFunctions';
import { otcTransfer } from '../data/functions/otc_functions';
import { MainButton, NewListingBody, NewListingCont, TallFiltersCont, TallFiltersInnerCont } from '../Styles';

const OTC = () => {

    const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, tokenList, setTokenList, noTokensDisplay, setNoTokensDisplay, setBurnableDisplay, burnable, setBurnable, setNoAssetsDisplay, noAssetsDisplay, refreshAssets, setRefreshAssets, swapInAmount, setSwapInAmount, swapOutAmount, setSwapOutAmount, swapInToken, setSwapInToken, swapOutToken, setSwapOutToken, swapInDisplay, setSwapInDisplay, swapOutDisplay, setSwapOutDisplay,
        enterModalText,
        setEnterModalText,
        loadingDisplay, 
        setLoadingDisplay, 
        enterButtonsDisplay,
        setEnterButtonsDisplay,
        enterModalDisplay, 
        setEnterModalDisplay,
        
        
        } = useStateContext();

        const [alcorPrice, setAlcorPrice] = useState(0.006);

        const [wojakAvailable, setWojakAvailable] = useState(30000000);

        const [waxPrice, setWaxPrice] = useState(0);
        const [wojakAmount, setWojakAmount] = useState(0);



        useEffect(() => {

              axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
                
                table:"accounts",
                scope:"waxdaootcmkt",
                code:'mdcryptonfts',
                limit:50,
                json:true
              })
                .then((response) => {
        
                  var i = 0;

                  while( i < response.data.rows.length ){

                    if( response.data.rows[i].balance.indexOf("WOJAK") > -1 ){
                      setWojakAvailable( Number( response.data.rows[i].balance.substring( 0, response.data.rows[i].balance.indexOf(" ") ) ) );
                      break;
                    }

                    i++;
                  }

                })
                .catch((error) => console.log(error.response.data));
            
              }
            
            , []);

            useEffect(() => {

              axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
                
                table:"currentprice",
                scope:"waxdaootcmkt",
                code:'waxdaootcmkt',
                limit:1,
                json:true
              })
                .then((res) => {

                  if( res.data.rows.length > 0 ){                  
                    setAlcorPrice( Number( res.data.rows[0].price / 100000000 ).toFixed(8) );
                  }
                })
                .catch((error) => console.log(error.response.data));
            
              }
            
            , []);



    return (
        <div id="seo">
        <Helmet>
        <title>OTC WOJAK Market - WaxDAO</title>
        <meta name="description" content="Buy WOJAK Tokens OTC With No Slippage" />
        <link rel="canonical" href="https://waxdao.io/otc" />
        </Helmet>
    
        <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>
    
        <Modal className={enterModalDisplay}>
          <ModalContent>
            {enterModalText}
            
          </ModalContent>
    
            <Spinner style={{display:loadingDisplay}}>
            <SpinnerRed  />
            <SpinnerBlue  />
            <SpinnerGreen />
            </Spinner>
    
          <br/>
          <GameButton onClick={() => setEnterModalDisplay('hidden') }>
            Close Window
          </GameButton>
          </Modal>
    
<NewListingCont>
<NewListingBody>

<TallFiltersCont>
  <h2>WOJAK OTC Market</h2>
  <br/>
  <TallFiltersInnerCont>
  Buy WOJAK tokens in bulk with 0 slippage!
  </TallFiltersInnerCont>
</TallFiltersCont>


<TallFiltersCont>
  <h2>How It Works</h2>
  <br/>
  <TallFiltersInnerCont>
  Our contract checks the Alcor Swap pool to get the current WOJAK price. You buy direct from WaxDAO reserves, using the Alcor price but without any slippage.
  <br/><br/>
  There is a 25,000 WOJAK minimum purchase.    
  </TallFiltersInnerCont>
</TallFiltersCont>


<TallFiltersCont>
<h2>Buy Now</h2>
<br/>
<TallFiltersInnerCont>
<h3>Total WOJAK Available</h3>
<NumberFormat displayType='text' thousandSeparator={true} value={wojakAvailable} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> 
<br/><br/>

<h3>WOJAK Amount</h3>
<input 
  value={wojakAmount} 
  onChange={(e) => { 
    setWojakAmount(e.target.value); 
    setWaxPrice(e.target.value * alcorPrice) 
    }}
/>
<br/><br/>

<MainButton onClick={() => { otcTransfer(waxPrice, "WAX", 8, "waxdaootcmkt", wojakAmount, setEnterModalText, setLoadingDisplay, setEnterModalDisplay) } }>
Swap <NumberFormat displayType='text' thousandSeparator={true} value={waxPrice.toFixed(2)} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> WAX for <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(wojakAmount)} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> WOJAK
</MainButton>

</TallFiltersInnerCont>
</TallFiltersCont>


</NewListingBody>

</NewListingCont>


        </UALProvider>
    
        </div>
      )
}

export default OTC