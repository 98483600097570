import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { MainContainer, DaoButton, NFT, NFTsCont, NFTLogo, NoDaosMessage, ProposalInput, DaoButtonCont, RamModal, WalletCont, FarmButtonCont, ToggleSwitch, InnerSwitch, ToggleSwitchCont, ToggleMessage, StakeManyButton, StakeManyDiv, LeftProfile, RightProfile, ProfileCont, ProfileImage, LeftProfileImg, SocialButton, FarmsTitle, FarmsPageCont, DropPageCont, MissingInfoMsg, Drop, DropTitle, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DropImage, Remaining, Price, QuantityToBuy, MinMint, AddNFT, DropDescription, DropBody, PoolAssetsTitle, UnboxTemplate, BlendTitle, BlendModal, DisabledButton, ModalNFTCont, ModalOverlay, ModalNFT, ModalNFTLogo, ShowDataButton, WalletCallToActionHref, DropPageAdBannerCont, RequiredIngredient, RequiredIngredientsCont, RequiredIngredientButton, FungibleTokenButton } from '../../components/LeftDiv';

import { currentUsername, currentApiList, currentAtomicApiList, wax, anchor, myChain, currentHyperionApiList, currentWebsiteURL, currentAtomicHubLink, currentAdBanner  } from '../../data/config';
import { checkWallet } from '../../data/functions/wallet_functions';
import CurrentBannerAd from '../../components/CurrentBannerAd';

var list = [];

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min) ) + min;
}

const handleAddAsset = (ingredientIndex, asset_id, blendIngredients, setBlendIngredients, allPoolsInUse, setAllPoolsInUse) => {

    var list = [...blendIngredients];

    list[ingredientIndex].asset_ids = [...list[ingredientIndex].asset_ids, asset_id];


    //console.log(list);
    setBlendIngredients([...list])
    //console.log(blendIngredients[ingredientIndex].asset_ids.length)
    console.log('handled adding it');

    setAllPoolsInUse([...allPoolsInUse, asset_id])
    
}


const handleRemoveAsset = (ingredientIndex, asset_id, blendIngredients, setBlendIngredients, allPoolsInUse, setAllPoolsInUse) => {

    var list = [...blendIngredients];

    list[ingredientIndex].asset_ids.splice(list[ingredientIndex].asset_ids.indexOf(asset_id), 1);


    //console.log(list);
    setBlendIngredients([...list])
    //console.log(blendIngredients[ingredientIndex].asset_ids.length)
    console.log('handled removing it');

    const allPoolsList = [...allPoolsInUse];
    allPoolsList.splice(allPoolsList.indexOf(asset_id), 1);
    setAllPoolsInUse(allPoolsList);
    


}


const startBlend = async (setMintOnDemandResultText, setPremintedResultText, setFungibleResultText, setModalNFTContDisplay, assetVector, setAssetVector, stakeMultipleDisplay, ingredientIndex, blendIngredients, setBlendIngredients, unstaked, setUnstaked, setBlendModalText, setLoadingDisplay, setEnterButtonsDisplay, setBlendModalDisplay) => {

    setBlendModalDisplay('');
    setBlendModalText('Choose Ingredients');
    setFungibleResultText('');
    setPremintedResultText('');
    setMintOnDemandResultText('');

    var uniqueID = getRndInteger(100000000, 200000000);

    var apiWorked = "no";

    const walletProvider = localStorage.getItem('walletProvider');

    if(walletProvider != 'wax' && walletProvider != 'anchor'){

        setBlendModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }

    const waxUsername = currentUsername;

    //make API call for ingredient 0

    if(blendIngredients[ingredientIndex].ingredient_type == "fungible"){

        setModalNFTContDisplay('none');
        setBlendModalText('Fetching token balance...')
        setLoadingDisplay('');
        const timer = setTimeout(() => {
    
          try {
          //get result from API
          axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
            table:"accounts",
            scope:waxUsername,
            code:blendIngredients[ingredientIndex].fungible_token_contract,
            limit:1,
            lower_bound:blendIngredients[ingredientIndex].fungible_token_symbol.substr(blendIngredients[ingredientIndex].fungible_token_symbol.indexOf(',') + 1),
            upper_bound:blendIngredients[ingredientIndex].fungible_token_symbol.substr(blendIngredients[ingredientIndex].fungible_token_symbol.indexOf(',') + 1),
            json:true
          }).then((resultResponse) => {

            console.log(resultResponse);
              




            if(resultResponse.data.rows[0].balance != ''){
    
            apiWorked = "yes";

            if(Number(resultResponse.data.rows[0].balance.substr(0, resultResponse.data.rows[0].balance.indexOf(' '))) >= blendIngredients[ingredientIndex].fungible_token_amount ){

                setBlendModalText(
                    <span>
                        You have enough {blendIngredients[ingredientIndex].fungible_token_symbol.substr(blendIngredients[ingredientIndex].fungible_token_symbol.indexOf(',') + 1)} to cover the {Number(blendIngredients[ingredientIndex].fungible_token_amount).toFixed(Number(blendIngredients[ingredientIndex].fungible_token_symbol.substr(0, blendIngredients[ingredientIndex].fungible_token_symbol.indexOf(','))))} {blendIngredients[ingredientIndex].fungible_token_symbol.substr(blendIngredients[ingredientIndex].fungible_token_symbol.indexOf(',') + 1)} requirement. Click "Next" to proceed to the next ingredient.
                    </span>
                );

            } else {

                setBlendModalText(
                    <span>
                        You need {Number(blendIngredients[ingredientIndex].fungible_token_amount).toFixed(Number(blendIngredients[ingredientIndex].fungible_token_symbol.substr(0, blendIngredients[ingredientIndex].fungible_token_symbol.indexOf(','))))} but you only have {resultResponse.data.rows[0].balance}
                    </span>
                );

            }

        

            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
        
        }})
    
    
      }//end try timer
    
      catch(e) {
        setBlendModalText('Could not retrieve token balance.');
        console.log(e)
        
      }//end catch timer
    
        }, 2000);
        const timer2 = setTimeout(() => {
  
          if(apiWorked == "no"){
            setBlendModalText('Could not retrieve token balance.');
            setEnterButtonsDisplay('');
            setLoadingDisplay('none');
          }
    
        }, 3000);
  
  
        return () => {clearTimeout(timer); clearTimeout(timer2);}
  
  
  
        }//end if ingredient type is fungible


        if(blendIngredients[ingredientIndex].ingredient_type == "nonfungible" && blendIngredients[ingredientIndex].filter_by == "collection"){

            setModalNFTContDisplay('none');
            setBlendModalText('Fetching your NFTs...')
            setLoadingDisplay('');
            const timer = setTimeout(() => {
        
              try {
              //get result from API
              axios.get(`${currentAtomicApiList[Math.floor(Math.random() * (1 - 0) ) + 0]}/atomicassets/v1/assets?collection_name=${blendIngredients[ingredientIndex].collection_name}&owner=${waxUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
              .then((atomicResponse) => {
                list = atomicResponse.data.data;
                setUnstaked(atomicResponse.data.data);
                //console.log(atomicResponse.data.data);
               

                if(atomicResponse.data.data.length > 0){
        
                apiWorked = "yes";
    

                    setModalNFTContDisplay('');
                    setBlendModalText(
                        <span>
                            Select any {blendIngredients[ingredientIndex].non_fungible_quantity == 1 ? "NFT" : blendIngredients[ingredientIndex].non_fungible_quantity + " NFTs"} from {blendIngredients[ingredientIndex].collection_name} collection<br/>
                        </span>
                    );
    

                setLoadingDisplay('none');
                setEnterButtonsDisplay('');
            
            }})
        
        
          }//end try timer
        
          catch(e) {
            setBlendModalText('Could not retrieve your NFTs.');
            console.log(e)
            
          }//end catch timer
        
            }, 2000);
            const timer2 = setTimeout(() => {
      
              if(apiWorked == "no"){
                setBlendModalText('Could not retrieve your NFTs.');
                setEnterButtonsDisplay('');
                setLoadingDisplay('none');
              }
        
            }, 3000);
      
      
            return () => {clearTimeout(timer); clearTimeout(timer2);}
      
      
      
            }//end if ingredient type is nonfungible && filter is collection




            if(blendIngredients[ingredientIndex].ingredient_type == "nonfungible" && (blendIngredients[ingredientIndex].filter_by == "schema" || blendIngredients[ingredientIndex].filter_by == "attributes") ){

                setModalNFTContDisplay('none');
                setBlendModalText('Fetching your NFTs...')
                setLoadingDisplay('');
                const timer = setTimeout(() => {
            
                  try {
                  //get result from API
                  axios.get(`${currentAtomicApiList[Math.floor(Math.random() * (1 - 0) ) + 0]}/atomicassets/v1/assets?collection_name=${blendIngredients[ingredientIndex].collection_name}&schema_name=${blendIngredients[ingredientIndex].schema_name}&owner=${waxUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
                  .then((atomicResponse) => {
                    list = atomicResponse.data.data;
                    setUnstaked(atomicResponse.data.data);
                    //console.log(atomicResponse.data.data);
                   
    
                    if(atomicResponse.data.data.length > 0){
            
                    apiWorked = "yes";
        
    
                        setModalNFTContDisplay('');

                        if(blendIngredients[ingredientIndex].filter_by == "schema"){

                            setBlendModalText(
                                <span>
                                    Select any {blendIngredients[ingredientIndex].non_fungible_quantity == 1 ? "NFT" : blendIngredients[ingredientIndex].non_fungible_quantity + " NFTs"} from {blendIngredients[ingredientIndex].schema_name} schema<br/>
                                </span>
                            );

                            
                        } else if(blendIngredients[ingredientIndex].filter_by == "attributes"){
                            setBlendModalText(
                                <span>
                                    Select any {blendIngredients[ingredientIndex].non_fungible_quantity == 1 ? "NFT" : blendIngredients[ingredientIndex].non_fungible_quantity + " NFTs"} with the following attributes:<br/>
                                    {blendIngredients[ingredientIndex].required_attributes.map((attributeItem, attributeIndex) => (
                                        <span>
                                        {attributeItem.name}: {attributeItem.value}<br/>
                                        </span>
                                            ))}
                                        <br/>
                                </span>
                            );

                        }       
    
                    setLoadingDisplay('none');
                    setEnterButtonsDisplay('');
                
                }})
            
            
              }//end try timer
            
              catch(e) {
                setBlendModalText('Could not retrieve your NFTs.');
                console.log(e)
                
              }//end catch timer
            
                }, 2000);
                const timer2 = setTimeout(() => {
          
                  if(apiWorked == "no"){
                    setBlendModalText('Could not retrieve your NFTs.');
                    setEnterButtonsDisplay('');
                    setLoadingDisplay('none');
                  }
            
                }, 3000);
          
          
                return () => {clearTimeout(timer); clearTimeout(timer2);}
          
          
          
                }//end if ingredient type is nonfungible && filter is schema

                if(blendIngredients[ingredientIndex].ingredient_type == "nonfungible" && blendIngredients[ingredientIndex].filter_by == "template"){

                    setModalNFTContDisplay('none');
                    setBlendModalText('Fetching your NFTs...')
                    setLoadingDisplay('');
                    const timer = setTimeout(() => {
                
                      try {
                      //get result from API
                      axios.get(`${currentAtomicApiList[Math.floor(Math.random() * (1 - 0) ) + 0]}/atomicassets/v1/assets?collection_name=${blendIngredients[ingredientIndex].collection_name}&schema_name=${blendIngredients[ingredientIndex].schema_name}&template_id=${blendIngredients[ingredientIndex].template_id}&owner=${waxUsername}&page=1&limit=1000&order=desc&sort=asset_id`)
                      .then((atomicResponse) => {
                        list = atomicResponse.data.data;
                        setUnstaked(atomicResponse.data.data);
                        //console.log(atomicResponse.data.data);
                       
        
                        if(atomicResponse.data.data.length > 0){
                
                        apiWorked = "yes";
            
        
                            setModalNFTContDisplay('');
                            setBlendModalText(
                                <span>
                                    Here are your NFTs:<br/>
                                </span>
                            );
            
        
                        setLoadingDisplay('none');
                        setEnterButtonsDisplay('');
                    
                    }})
                
                
                  }//end try timer
                
                  catch(e) {
                    setBlendModalText('Could not retrieve your NFTs.');
                    console.log(e)
                    
                  }//end catch timer
                
                    }, 2000);
                    const timer2 = setTimeout(() => {
              
                      if(apiWorked == "no"){
                        setBlendModalText('Could not retrieve your NFTs.');
                        setEnterButtonsDisplay('');
                        setLoadingDisplay('none');
                      }
                
                    }, 3000);
              
              
                    return () => {clearTimeout(timer); clearTimeout(timer2);}
              
              
              
                    }//end if ingredient type is nonfungible && filter is template

  
  } //end startBlend








const finalizeBlend = async (setMintOnDemandResultText, setPremintedResultText, setFungibleResultText, DropID, setModalNFTContDisplay, assetVector, setAssetVector, stakeMultipleDisplay, blendIngredients, setBlendIngredients, unstaked, setUnstaked, setBlendModalText, setLoadingDisplay, setEnterButtonsDisplay, setBlendModalDisplay) => {

    setBlendModalDisplay('');
    setModalNFTContDisplay('none');
    setBlendModalText('Awaiting confirmation...');
    setFungibleResultText('');
    setPremintedResultText('');
    setMintOnDemandResultText('');

    var uniqueID = getRndInteger(100000000, 200000000);

    var apiWorked = "no";

    const walletProvider = localStorage.getItem('walletProvider');

    if(walletProvider != 'wax' && walletProvider != 'anchor'){

      setBlendModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }


  
    if(walletProvider == 'wax'){


    // Retrieve current session from state
    const session = await wax.login()
    //console.log(session[0].signTransaction);

      var current_ingredient_index = 0;
      var blend_actions = [];
      var ingredient_list = [...blendIngredients];
      
      while(current_ingredient_index < blendIngredients.length){
      
          if(ingredient_list[current_ingredient_index].ingredient_type == "fungible"){
              blend_actions[current_ingredient_index] = {
                  account: ingredient_list[current_ingredient_index].fungible_token_contract,
                  name: 'transfer',
                  authorization: [{
                    actor: wax.users[0].accountName,
                    permission: wax.users[0].requestPermission,
                }],
                data: {
                  to: 'waxdaomrktv2',
                  from: wax.users[0].accountName,
                  quantity: Number(ingredient_list[current_ingredient_index].fungible_token_amount).toFixed(Number(ingredient_list[current_ingredient_index].fungible_token_symbol.substr(0,ingredient_list[current_ingredient_index].fungible_token_symbol.indexOf(',')))) + ' ' + ingredient_list[current_ingredient_index].fungible_token_symbol.substr(ingredient_list[current_ingredient_index].fungible_token_symbol.indexOf(',') + 1),
                  memo: '|blend_deposit|' + DropID + '|' + current_ingredient_index + '|',
                }
              }
          } //end if type is fungible
          
          else if(ingredient_list[current_ingredient_index].ingredient_type == "nonfungible"){
              blend_actions[current_ingredient_index] = {
                  account: "atomicassets",
                  name: 'transfer',
                  authorization: [{
                    actor: wax.users[0].accountName,
                    permission: wax.users[0].requestPermission,
                }],
                data: {
                  to: 'waxdaomrktv2',
                  from: wax.users[0].accountName,
                  asset_ids:ingredient_list[current_ingredient_index].asset_ids,
                  memo: '|blend_deposit|' + DropID + '|' + current_ingredient_index + '|',
                }
              }
          } //end if type is fungible


      
          current_ingredient_index ++;
      }

  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = 
        [{
          account: 'waxdaomrktv2',
          name: 'assertblend',
          authorization: [{
            actor: wax.users[0].accountName,
            permission: wax.users[0].requestPermission,
        }],
        data: {
            user: wax.users[0].accountName,
            blend_ID: DropID,
            unique_id: uniqueID,
        }
      }
      ]

      const final_action = [...action, ...blend_actions]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: final_action
      }, {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');


      setBlendModalText('Fetching blend results...')
      const timer = setTimeout(() => {
  
        try {
        //get result from API
        axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
          table:"blendresult",
          scope:"waxdaomrktv2",
          code:"waxdaomrktv2",
          limit:1,
          lower_bound:uniqueID,
          upper_bound:uniqueID,
          json:true
        }).then((resultResponse) => {
          
          if(resultResponse.data.rows[0].time != null && resultResponse.data.rows[0].time > 0){
              setBlendModalText('');

          console.log(resultResponse.data.rows[0]);
  
            apiWorked = "yes";

            if(resultResponse.data.rows[0].preminted_results.length > 0){

            axios.get(`${currentAtomicApiList[Math.floor(Math.random() * (1 - 0) ) + 0]}/atomicassets/v1/assets?ids=${resultResponse.data.rows[0].preminted_results.join("%2C")}&page=1&limit=100&order=desc&sort=asset_id`)
            .then((validResponse) => {
              //console.log(validResponse.data);
              setPremintedResultText(
                <span className="text-center align-center justify-center">
                  Pre-Minted Results:<br/>
                  <ModalNFTCont>
                { validResponse != null && validResponse.data.data.length > 0 ? validResponse.data.data.map((item, index) => (
  
                <ModalNFT key={index} >
                <p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
                <br/>Mint {item.template_mint != null ? item.template_mint : ''}
                </p>
  
                {item.data.img != null && (
                <ModalNFTLogo
                src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
                />
  
                )}
  
                {item.data.img == null && item.data.video != null && (
  
                <video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
                <source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
                </video>
  
  
  
                )}
  
                {item.data.img == null && item.data.video == null && item.data.image != null && (
                <ModalNFTLogo
                src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
                />
  
                )}
  
                </ModalNFT>
  
  
  
                )) : (
               <span></span>
  
                )}
                
                </ModalNFTCont>
                </span>);
              
               
              })

              }//end if preminted results is > 0

              
                setMintOnDemandResultText(
                  <span className="text-center align-center justify-center">
                    Mint On Demand Results:<br/>
                    <ModalNFTCont>
                    {resultResponse.data.rows[0].mint_on_demand_results.length > 0 ? resultResponse.data.rows[0].mint_on_demand_results.map((item, index) => (

                  <ModalNFT key={index} >
                  <p><span className="font-bold">{item.nft_name != null ? item.nft_name : ''}</span>
                  <br/>Template {item.template_id != null ? item.template_id : ''}
                  </p>
    
                  {item.nft_image != null && (
                  <ModalNFTLogo
                  src={`https://ipfs.io/ipfs/${item.nft_image.trim()}`} 
                  />
    
                  )}

                  <br/>Quantity: {item.non_fungible_quantity != null ? item.non_fungible_quantity : '?'}

                  </ModalNFT>
                  
    
    
                  )) : (
                 <span>
                   No mint on demand results were found
                 </span>
                  )}
                   </ModalNFTCont>
                  </span>
                  );
                
                
              if(resultResponse.data.rows[0].fungible_results.length > 0){

                  setFungibleResultText(
                      <span className="text-center align-center justify-center">
                        <br/>Fungible Results:<br/>
                      <ModalNFTCont>
                      
                      {/* MAP THROUGH EACH TOKEN AND DISPLAY QUANTITY, CONTRACT */}

                      { resultResponse.data.rows[0].fungible_results.length > 0 ? resultResponse.data.rows[0].fungible_results.map((item, index) => (

                          <span key={index}>


                          {/* FOR FUNGIBLE INGREDIENTS */}
                          {item.amount != null && (
                          <NFT>   
                              {item.amount}@{item.contract}
                          </NFT>
                          )}
                          {/* END OF FUNGIBLE */}

                          </span>

                          )) : (
                          <NoDaosMessage>
                          Could not find any fungible token results
                          </NoDaosMessage>

                          )}





        
                      </ModalNFTCont>
        
                      </span>);

              } //end if fungible results > 0

              else {
                  //TELL THE USER THAT THEY HAVE NO FUNGIBLE RESULTS
              }


  
            } else {
            setBlendModalText('Could not retrieve result. Check your wallet to see what you got.');
          }
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
      
        })
  
  
    }//end try timer
  
    catch(e) {
      setBlendModalText('Could not retrieve result. Check your wallet.');
      console.log(e)
      
    }//end catch timer
  
      }, 4000);
      const timer2 = setTimeout(() => {

        if(apiWorked == "no"){
          setBlendModalText('Could not retrieve result. Check your wallet.');
          setEnterButtonsDisplay('');
          setLoadingDisplay('none');
        }
  
      }, 5000);


      return () => {clearTimeout(timer); clearTimeout(timer2);}



    } catch(e) {
      setBlendModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
      } //end if wallet = wax
  
  
  
      if(walletProvider == 'anchor'){

      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);

        var current_ingredient_index = 0;
        var blend_actions = [];
        var ingredient_list = [...blendIngredients];
        
        while(current_ingredient_index < blendIngredients.length){
        
            if(ingredient_list[current_ingredient_index].ingredient_type == "fungible"){
                blend_actions[current_ingredient_index] = {
                    account: ingredient_list[current_ingredient_index].fungible_token_contract,
                    name: 'transfer',
                    authorization: [{
                      actor: anchor.users[0].accountName,
                      permission: anchor.users[0].requestPermission,
                  }],
                  data: {
                    to: 'waxdaomrktv2',
                    from: anchor.users[0].accountName,
                    quantity: Number(ingredient_list[current_ingredient_index].fungible_token_amount).toFixed(Number(ingredient_list[current_ingredient_index].fungible_token_symbol.substr(0,ingredient_list[current_ingredient_index].fungible_token_symbol.indexOf(',')))) + ' ' + ingredient_list[current_ingredient_index].fungible_token_symbol.substr(ingredient_list[current_ingredient_index].fungible_token_symbol.indexOf(',') + 1),
                    memo: '|blend_deposit|' + DropID + '|' + current_ingredient_index + '|',
                  }
                }
            } //end if type is fungible
            
            else if(ingredient_list[current_ingredient_index].ingredient_type == "nonfungible"){
                blend_actions[current_ingredient_index] = {
                    account: "atomicassets",
                    name: 'transfer',
                    authorization: [{
                      actor: anchor.users[0].accountName,
                      permission: anchor.users[0].requestPermission,
                  }],
                  data: {
                    to: 'waxdaomrktv2',
                    from: anchor.users[0].accountName,
                    asset_ids:ingredient_list[current_ingredient_index].asset_ids,
                    memo: '|blend_deposit|' + DropID + '|' + current_ingredient_index + '|',
                  }
                }
            } //end if type is fungible


        
            current_ingredient_index ++;
        }

        
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = 
          [{
            account: 'waxdaomrktv2',
            name: 'assertblend',
            authorization: [{
              actor: anchor.users[0].accountName,
              permission: anchor.users[0].requestPermission,
          }],
          data: {
              user: anchor.users[0].accountName,
              blend_ID: DropID,
              unique_id: uniqueID,
          }
        }
        ]

        const final_action = [...action, ...blend_actions]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: final_action
        }, {
          blocksBehind: 3,
          expireSeconds: 600,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
  
  
        setBlendModalText('Fetching blend results...')
        const timer = setTimeout(() => {
    
          try {
          //get result from API
          axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
            table:"blendresult",
            scope:"waxdaomrktv2",
            code:"waxdaomrktv2",
            limit:1,
            lower_bound:uniqueID,
            upper_bound:uniqueID,
            json:true
          }).then((resultResponse) => {
            
            if(resultResponse.data.rows[0].time != null && resultResponse.data.rows[0].time > 0){
                setBlendModalText('');

            console.log(resultResponse.data.rows[0]);
    
              apiWorked = "yes";

              if(resultResponse.data.rows[0].preminted_results.length > 0){

              axios.get(`${currentAtomicApiList[Math.floor(Math.random() * (1 - 0) ) + 0]}/atomicassets/v1/assets?ids=${resultResponse.data.rows[0].preminted_results.join("%2C")}&page=1&limit=100&order=desc&sort=asset_id`)
              .then((validResponse) => {
                //console.log(validResponse.data);
                setPremintedResultText(
                  <span className="text-center align-center justify-center">
                    Pre-Minted Results:<br/>
                    <ModalNFTCont>
                  { validResponse != null && validResponse.data.data.length > 0 ? validResponse.data.data.map((item, index) => (
    
                  <ModalNFT key={index} >
                  <p key={index}><span className="font-bold">{item.name != null ? item.name : ''}</span>
                  <br/>Mint {item.template_mint != null ? item.template_mint : ''}
                  </p>
    
                  {item.data.img != null && (
                  <ModalNFTLogo
                  src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
                  />
    
                  )}
    
                  {item.data.img == null && item.data.video != null && (
    
                  <video style={{ width: "150px", maxWidth: "150px", height: "150px", maxHeight: "150px", borderRadius:'25%'}} loop>
                  <source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
                  </video>
    
    
    
                  )}
    
                  {item.data.img == null && item.data.video == null && item.data.image != null && (
                  <ModalNFTLogo
                  src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
                  />
    
                  )}
    
                  </ModalNFT>
    
    
    
                  )) : (
                 <span></span>
    
                  )}
                  
                  </ModalNFTCont>
                  </span>);
                
                 
                })

                }//end if preminted results is > 0

                
                  setMintOnDemandResultText(
                    <span className="text-center align-center justify-center">
                      Mint On Demand Results:<br/>
                      <ModalNFTCont>
                      {resultResponse.data.rows[0].mint_on_demand_results.length > 0 ? resultResponse.data.rows[0].mint_on_demand_results.map((item, index) => (

                    <ModalNFT key={index} >
                    <p><span className="font-bold">{item.nft_name != null ? item.nft_name : ''}</span>
                    <br/>Template {item.template_id != null ? item.template_id : ''}
                    </p>
      
                    {item.nft_image != null && (
                    <ModalNFTLogo
                    src={`https://ipfs.io/ipfs/${item.nft_image.trim()}`} 
                    />
      
                    )}

                    <br/>Quantity: {item.non_fungible_quantity != null ? item.non_fungible_quantity : '?'}

                    </ModalNFT>
                    
      
      
                    )) : (
                   <span>
                     No mint on demand results were found
                   </span>
                    )}
                     </ModalNFTCont>
                    </span>
                    );
                  
                  
                if(resultResponse.data.rows[0].fungible_results.length > 0){

                    setFungibleResultText(
                        <span className="text-center align-center justify-center">
                          <br/>Fungible Results:<br/>
                        <ModalNFTCont>
                        
                        {/* MAP THROUGH EACH TOKEN AND DISPLAY QUANTITY, CONTRACT */}

                        { resultResponse.data.rows[0].fungible_results.length > 0 ? resultResponse.data.rows[0].fungible_results.map((item, index) => (

                            <span key={index}>


                            {/* FOR FUNGIBLE INGREDIENTS */}
                            {item.amount != null && (
                            <NFT>   
                                {item.amount}@{item.contract}
                            </NFT>
                            )}
                            {/* END OF FUNGIBLE */}

                            </span>

                            )) : (
                            <NoDaosMessage>
                            Could not find any fungible token results
                            </NoDaosMessage>

                            )}





          
                        </ModalNFTCont>
          
                        </span>);

                } //end if fungible results > 0

                else {
                    //TELL THE USER THAT THEY HAVE NO FUNGIBLE RESULTS
                }


    
              } else {
              setBlendModalText('Could not retrieve result. Check your wallet to see what you got.');
            }
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
        
          })
    
    
      }//end try timer
    
      catch(e) {
        setBlendModalText('Could not retrieve result. Check your wallet.');
        console.log(e)
        
      }//end catch timer
    
        }, 4000);
        const timer2 = setTimeout(() => {
  
          if(apiWorked == "no"){
            setBlendModalText('Could not retrieve result. Check your wallet.');
            setEnterButtonsDisplay('');
            setLoadingDisplay('none');
          }
    
        }, 5000);
  
  
        return () => {clearTimeout(timer); clearTimeout(timer2);}
  
  

      } catch(e) {
        setBlendModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    

        } //end if wallet = anchor
  
  } //end finalizeBlend
  




const BlendPageV2 = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, 
    setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, 
    tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, 
    farmSchema, setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, 
    farmIcon, setFarmIcon, farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, 
    setUnstaked, stakedOrUnstaked, setStakedOrUnstaked, stakedDisplay, setStakedDisplay, 
    unstakedDisplay, setUnstakedDisplay, validAssets, setValidAssets, commaSeparated, 
    setCommaSeparated, stakedAssets, setStakedAssets, claimable, setClaimable, minTime, 
    unstakeNftsDisplay, setUnstakeNftsDisplay, walletDisplay, setWalletDisplay, assetVector,
    setAssetVector, stakeMultipleDisplay, setStakeMultipleDisplay, highlightedFarm, 
    userCanUnlock,
    setUserCanUnlock,
    setHighlightedFarm,
    profile,
    setProfile,
    rewardType,
    setRewardType,
    rewardAmount,
    setRewardAmount,
    farmPrecision,
    setFarmPrecision,
    dropList,
    setDropList,
    dropPrice,
    setDropPrice,
    whitelistType,
    setWhitelistType,
    quantity,
    setQuantity,
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,
    rewardContract,
    setRewardContract,
    pricePerNFT,
    setPricePerNFT,
    dropImage,
    setDropImage,
    totalLeft,
    setTotalLeft,
    totalAvailable,
    setTotalAvailable,
    minimumMint,
    setMinimumMint,
    templateName,
    setTemplateName,
    dropDescription,
    setDropDescription,
    limitPerUser,
    setLimitPerUser,
    cooldownSeconds,
    setCooldownSeconds,
    dropBegins,
    setDropBegins,
    dropEnds,
    setDropEnds,
    dropType,
    setDropType,
    poolIDs,
    setPoolIDs,
    blendIngredients,
    setBlendIngredients,
    blendResults,
    setBlendResults,
    blendTitle,
    setBlendTitle,
    blendModalText,
    setBlendModalText,
    currentIngredientIndex,
    setCurrentIngredientIndex,
    blendModalDisplay,
    setBlendModalDisplay,
    modalNFTContDisplay,
    setModalNFTContDisplay,
    currentFilterType,
    setCurrentFilterType,
    showNFTData,
    setShowNFTData,
    allPoolsInUse,
    setAllPoolsInUse,
    fungibleResultText,
    setFungibleResultText,
    premintedResultText,
    setPremintedResultText,
    mintOnDemandResultText,
    setMintOnDemandResultText,
    




   } = useStateContext();

const { DropID } = useParams();


//check drops table for drop ID

useEffect(() => {
    setDropList([]);
    setDropPrice('');
    setWhitelistType('');
    setQuantity(1);
    setRewardContract('');
    setPricePerNFT('');
    setFarmPrecision('');
    setTokenName('');
    setDropImage('');
    setTotalLeft('');
    setTotalAvailable('');
    setMinimumMint('');
    setMaxSupply('');
    setTemplateName('');
    setDropDescription('');
    setFarmCollection('');
    setCreatedBy('');
    setLimitPerUser('');
    setCooldownSeconds('');
    setDropBegins('');
    setDropEnds('');
    setDropType('standard');
    setStakedAssets([]);
    setPoolIDs([]);
    setBlendIngredients([]);
    setBlendResults([]);
    setBlendTitle('');
    setBlendModalText('Choose Ingredients');
    setCurrentIngredientIndex(0);
    setBlendModalDisplay('hidden');
    setUnstaked([]);
    setStakeMultipleDisplay('');
    setAssetVector([]);
    setModalNFTContDisplay('none');
    setCurrentFilterType('collection');
    setShowNFTData(false);
    setAllPoolsInUse([]);
    setFungibleResultText('');
    setPremintedResultText('');
    setMintOnDemandResultText('');
    

    

    
    axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
        table:"blends",
        scope:"waxdaomrktv2",
        code:"waxdaomrktv2",
        limit:1,
        lower_bound: DropID,
        upper_bound: DropID,
        json:true
        })
        .then((response) => {

            console.log(response.data.rows);
    
            setDropList([response.data.rows]);
            setWhitelistType(response.data.rows[0].whitelist_type);
            setTotalLeft(response.data.rows[0].blends_remaining);
            setTotalAvailable(response.data.rows[0].max_blends);
            setDropDescription(response.data.rows[0].blend_description);
            setFarmCollection(response.data.rows[0].collection);
            setCreatedBy(response.data.rows[0].creator);
            setLimitPerUser(response.data.rows[0].limit_per_user);
            setCooldownSeconds(response.data.rows[0].cooldown_reset);
            setDropBegins(response.data.rows[0].start_time);
            setDropEnds(response.data.rows[0].end_time);
            //setDropType(response.data.rows[0].drop_type);
            setDropImage(response.data.rows[0].cover_image);
            setPoolIDs(response.data.rows[0].other);

            var blendInputs = [...response.data.rows[0].blend_ingredients];
            var some_number = 0;
            while(some_number < blendInputs.length){
                blendInputs[some_number].asset_ids = [];
                some_number ++;
            }

            setBlendIngredients(blendInputs);
            
            
            setBlendResults(response.data.rows[0].blend_results);
            setBlendTitle(response.data.rows[0].blend_title);

            //var pool_id = response.data.rows[0].other[0];
            //console.log(pool_id)


        })
        .catch((error) => console.log(error));  

      }
    
    , []);

//if no result, render (this drop doesnt exist)

//if result, render the page

  return (
    <div id="seo">
    <Helmet>
    <title>Blend {DropID} Details</title>
    <meta name="description" content={`Use the WaxDao Super Blender to blend NFTs`} />
    <link rel="canonical" href={`https://waxdao.io/v2/blends/${DropID}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>

  <ModalOverlay className={blendModalDisplay} /> 

<BlendModal className={blendModalDisplay}>
    <ModalContent>
        {blendModalText}
        {mintOnDemandResultText}
        {fungibleResultText}
        {premintedResultText}


        <ModalNFTCont style={{display: modalNFTContDisplay}}>
                            {unstaked.length > 0 ? unstaked.map((item, index) => (


  <ModalNFT style={blendIngredients[currentIngredientIndex].asset_ids != null && blendIngredients[currentIngredientIndex].asset_ids.indexOf(item.asset_id) > -1 ? {border:'1px solid #fa872d'} :  {border:'1px solid #696969'}}>
 <span className={showNFTData == true && "hidden"}>
  <p><span className="font-bold">{item.name != null ? item.name : ''}</span>
          
  <br/><span className="font-semibold">Template: {item.template != null ? item.template.template_id : 'None'}</span> 
  <br/>Mint {item.template_mint != null ? item.template_mint : ''}
  </p>
  
  {item.data.img != null && (
    <ModalNFTLogo key={index} 
    src={`https://ipfs.io/ipfs/${item.data.img.trim()}`} 
    />
  
  )}
  
  {item.data.img == null && item.data.video != null && (
    
    <video style={{ width: "120px", maxWidth: "120px", height: "120px", maxHeight: "120px", borderRadius:'5px'}} loop>
    <source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
  </video>
  
    
  
  )}
  
  {item.data.img == null && item.data.video == null && item.data.image != null && (
    <ModalNFTLogo key={index} 
    src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
    />
  
  )}
</span>

{/* ATTRIBUTES */}
<span className={showNFTData == false && "hidden"}>
{Object.keys(item.data).map(key => (
    <span>
    {key}: {item.data[key]}<br/>
    </span>
    ))}
</span>
{/* END ATTRIBUTES */}

<ShowDataButton
onClick={() => {
    if(showNFTData == true){
        setShowNFTData(false);
      } else {
          setShowNFTData(true);
      }

}}
>
    {showNFTData == true ? "Hide Attributes" : "Show Attributes"}
</ShowDataButton>
<br/>

  <span key={index}>
  {stakeMultipleDisplay == "" && ((blendIngredients[currentIngredientIndex].asset_ids == null && allPoolsInUse.indexOf(item.asset_id) > -1) || blendIngredients[currentIngredientIndex].asset_ids.indexOf(item.asset_id) == -1 && allPoolsInUse.indexOf(item.asset_id) > -1) && (
<DisabledButton>
    In Use
</DisabledButton>
  )}

{blendIngredients[currentIngredientIndex].asset_ids != null && blendIngredients[currentIngredientIndex].asset_ids.indexOf(item.asset_id) > -1 && (
  <StakeManyButton
  onClick={() => {
    handleRemoveAsset(currentIngredientIndex, item.asset_id, blendIngredients, setBlendIngredients, allPoolsInUse, setAllPoolsInUse);
}}
style={{border:'1px solid #fa872d'}}
className="text-orange"
>
    Remove
</StakeManyButton>

)}

{blendIngredients[currentIngredientIndex].asset_ids != null && blendIngredients[currentIngredientIndex].asset_ids.indexOf(item.asset_id) == -1 && allPoolsInUse.indexOf(item.asset_id) == -1 && (
  <StakeManyButton
  onClick={() => {
    handleAddAsset(currentIngredientIndex, item.asset_id, blendIngredients, setBlendIngredients, allPoolsInUse, setAllPoolsInUse)
}}
style={{border:'1px solid #696969'}}
className="text-gray hover:text-black"
>
    Add
</StakeManyButton>

)}

  </span>





  </ModalNFT>


  
  )) : (
    <NoDaosMessage>
      You don't have any NFTs that qualify for this ingredient.
    </NoDaosMessage>
  
  
  )}

</ModalNFTCont>






      </ModalContent>

      <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>


        <br/><br/>
        {currentIngredientIndex == 0 && (
        <DisabledButton>
            Back
        </DisabledButton>         
        )}
        {currentIngredientIndex != 0 && (
        <DaoButton onClick={() => {var THIS_INGREDIENT = currentIngredientIndex - 1; setCurrentIngredientIndex(THIS_INGREDIENT); startBlend(setMintOnDemandResultText, setPremintedResultText, setFungibleResultText, setModalNFTContDisplay, assetVector, setAssetVector, stakeMultipleDisplay, THIS_INGREDIENT, blendIngredients, setBlendIngredients, unstaked, setUnstaked, setBlendModalText, setLoadingDisplay, setEnterButtonsDisplay, setBlendModalDisplay)}}>
            Back
        </DaoButton>         
        )}
        &nbsp;&nbsp;&nbsp;&nbsp;
            {blendIngredients[currentIngredientIndex] != null && blendIngredients[currentIngredientIndex].ingredient_type != null && (blendIngredients[currentIngredientIndex].ingredient_type == "nonfungible" && blendIngredients[currentIngredientIndex].asset_ids != null && blendIngredients[currentIngredientIndex].non_fungible_quantity != null && blendIngredients[currentIngredientIndex].asset_ids.length < blendIngredients[currentIngredientIndex].non_fungible_quantity || currentIngredientIndex == blendIngredients.length - 1) && (
        <DisabledButton>
        Next
        </DisabledButton>   
            ) }
            {currentIngredientIndex != blendIngredients.length - 1 && blendIngredients[currentIngredientIndex] != null && blendIngredients[currentIngredientIndex].ingredient_type != null && (blendIngredients[currentIngredientIndex].ingredient_type == "fungible" || (blendIngredients[currentIngredientIndex].ingredient_type == "nonfungible" && blendIngredients[currentIngredientIndex].asset_ids != null && blendIngredients[currentIngredientIndex].non_fungible_quantity != null && blendIngredients[currentIngredientIndex].asset_ids.length >= blendIngredients[currentIngredientIndex].non_fungible_quantity)) && (
        <DaoButton onClick={() => {var THIS_INGREDIENT = currentIngredientIndex + 1; setCurrentIngredientIndex(THIS_INGREDIENT); startBlend(setMintOnDemandResultText, setPremintedResultText, setFungibleResultText, setModalNFTContDisplay, assetVector, setAssetVector, stakeMultipleDisplay, THIS_INGREDIENT, blendIngredients, setBlendIngredients, unstaked, setUnstaked, setBlendModalText, setLoadingDisplay, setEnterButtonsDisplay, setBlendModalDisplay)}}>
            Next
        </DaoButton>
            )}
        <br/>
      <GameButton className={currentIngredientIndex != blendIngredients.length - 1 && "hidden"} onClick={() => finalizeBlend(setMintOnDemandResultText, setPremintedResultText, setFungibleResultText, DropID, setModalNFTContDisplay, assetVector, setAssetVector, stakeMultipleDisplay, blendIngredients, setBlendIngredients, unstaked, setUnstaked, setBlendModalText, setLoadingDisplay, setEnterButtonsDisplay, setBlendModalDisplay) }>
        Finalize Blend
      </GameButton>  

      <br/>
      <GameButton onClick={() => setBlendModalDisplay('hidden') }>
        Close Window
      </GameButton>      

</BlendModal>

    <MainContainer>

    <DropPageAdBannerCont>
              <CurrentBannerAd />
    </DropPageAdBannerCont>

    {dropDescription != null && dropDescription != '' && (


        <DropPageCont>
        <Drop>


{whitelistType != null && whitelistType != "none" && (
<span>
<MissingInfoMsg>
This is a whitelisted drop. If you are not whitelisted by the creator, you won't be able to participate.
<br/><br/>
Whitelist type: {whitelistType}

</MissingInfoMsg>
<br/>
</span>

)}

<BlendTitle>
    {blendTitle != null && blendTitle != '' && blendTitle}
</BlendTitle>


            <Remaining>
            {totalLeft != null && totalLeft != '0' && totalLeft}{totalLeft != null && totalLeft == '0' && (<span>&#8734;</span>)}/{totalAvailable != null && totalAvailable != '0' && totalAvailable}{totalAvailable != null && totalAvailable == '0' && (<span>&#8734;</span>)} left
            </Remaining>


{dropImage != null && dropImage != "" && (
            <span>
            <DropImage src={dropImage != null && `https://ipfs.io/ipfs/${dropImage}`} />
            </span>
)}



            {templateName != null && templateName}










{dropPrice != null && dropPrice != "0 FREE" && (

<DaoButton onClick={() => { startBlend(setMintOnDemandResultText, setPremintedResultText, setFungibleResultText, setModalNFTContDisplay, assetVector, setAssetVector, stakeMultipleDisplay, currentIngredientIndex, blendIngredients, setBlendIngredients, unstaked, setUnstaked, setBlendModalText, setLoadingDisplay, setEnterButtonsDisplay, setBlendModalDisplay) }}>
Start Blend Now
</DaoButton>

)}




{/* START OF INGREDIENT MAP */}
<DropDescription>
    <BlendTitle>
        Required Ingredients
    </BlendTitle>

<RequiredIngredientsCont>
    { blendIngredients.length > 0 ? blendIngredients.map((item, index) => (

<span key={index}>


{/* FOR COLLECTION WIDE INGREDIENTS */}
{item.ingredient_type == "nonfungible" && item.filter_by == "collection" && (
<RequiredIngredient> 
    • {item.non_fungible_quantity == 1 && "Any NFT from collection:"}{item.non_fungible_quantity > 1 && "Any " + item.non_fungible_quantity + " NFTs from collection:"}  <br/>    
    <a href={`${currentAtomicHubLink}/explorer/collection/${item.collection_name}`} target="none"><RequiredIngredientButton>{item.collection_name}</RequiredIngredientButton></a>
</RequiredIngredient>
)}
{/* END OF COLLECTION WIDE */}


{/* FOR SCHEMA WIDE INGREDIENTS */}
{item.ingredient_type == "nonfungible" && item.filter_by == "schema" && (
<RequiredIngredient> 
    • {item.non_fungible_quantity == 1 && "Any NFT from schema:"}{item.non_fungible_quantity > 1 && "Any " + item.non_fungible_quantity + " NFTs from schema:"}<br/>    
    <a href={`${currentAtomicHubLink}/explorer/schema/${item.collection_name}/${item.schema_name}`} target="none"><RequiredIngredientButton>{item.schema_name}</RequiredIngredientButton></a>
</RequiredIngredient>
)}
{/* END OF SCHEMA WIDE */}

{/* FOR TEMPLATE WIDE INGREDIENTS */}
{item.ingredient_type == "nonfungible" && item.filter_by == "template" && (
<RequiredIngredient> 
    • {item.non_fungible_quantity == 1 && "Any NFT using template ID:"}{item.non_fungible_quantity > 1 && "Any " + item.non_fungible_quantity + " NFTs using template ID:"}<br/>    
    <a href={`${currentAtomicHubLink}/explorer/template/${item.collection_name}/${item.template_id}`} target="none"><RequiredIngredientButton>{item.template_id}</RequiredIngredientButton></a>
</RequiredIngredient>
)}
{/* END OF TEMPLATE WIDE */}

{/* FOR ATTRIBUTE BASED INGREDIENTS */}
{item.ingredient_type == "nonfungible" && item.filter_by == "attributes" && (
<RequiredIngredient> 
    • {item.non_fungible_quantity == 1 && "Any NFT from collection:"}{item.non_fungible_quantity > 1 && "Any " + item.non_fungible_quantity + " NFTs from collection:"}<br/>    
    <a href={`${currentAtomicHubLink}/explorer/collection/${item.collection_name}`} target="none"><RequiredIngredientButton>{item.collection_name}</RequiredIngredientButton></a><br/>
    <br/>Using schema:<br/>
    <a href={`${currentAtomicHubLink}/explorer/schema/${item.collection_name}/${item.schema_name}`} target="none"><RequiredIngredientButton>{item.schema_name}</RequiredIngredientButton></a><br/>
    { item.required_attributes.length > 0 && (
      <span>
    <br/>With the following attributes:<br/>
    {item.required_attributes.map((attributeItem, attributeIndex) => (
    <span>
    {attributeItem.name}: {attributeItem.value}<br/>
    </span>
        ))}
    </span>
        )}
   
</RequiredIngredient>
)}
{/* END OF ATTRIBUTE BASED */}

{/* FOR FUNGIBLE INGREDIENTS */}
{item.ingredient_type == "fungible" && (
<RequiredIngredient> 
    • Fungible Token:<br/>
    <FungibleTokenButton href={`https://wax.bloks.io/account/${item.fungible_token_contract}?loadContract=true&tab=Tables&table=stat&account=${item.fungible_token_contract}&scope=${item.fungible_token_symbol.substr(item.fungible_token_symbol.indexOf(',') + 1)}&limit=100`} target="none">
    {Number(item.fungible_token_amount).toFixed(Number(item.fungible_token_symbol.substr(0,item.fungible_token_symbol.indexOf(','))))} {item.fungible_token_symbol.substr(item.fungible_token_symbol.indexOf(',') + 1)}@{item.fungible_token_contract}
    </FungibleTokenButton>    
</RequiredIngredient>
)}
{/* END OF FUNGIBLE */}

</span>

)) : (
<NoDaosMessage>
  Could not fetch ingredients
</NoDaosMessage>

)}

</RequiredIngredientsCont>

</DropDescription>
{/* END OF INGREDIENT MAP */}



{/* START OF RESULTS MAP */}
<DropDescription>
    <BlendTitle>
        Blend Results
    </BlendTitle>

<RequiredIngredientsCont>
    { blendResults.length > 0 ? blendResults.map((item, index) => (

<span key={index}>

{/* FOR ATTRIBUTE BASED INGREDIENTS */}
{item.result_type == "mintondemand" &&  (
<RequiredIngredient> 
    •  {item.non_fungible_quantity == 1 && "1 NFT from collection:"}{item.non_fungible_quantity > 1 && item.non_fungible_quantity + " NFTs from collection:"}<br/>    
    <a href={`${currentAtomicHubLink}/explorer/collection/${item.collection_name}`} target="none"><RequiredIngredientButton>{item.collection_name}</RequiredIngredientButton></a><br/>
    <br/>Using schema:<br/>
    <a href={`${currentAtomicHubLink}/explorer/schema/${item.collection_name}/${item.schema_name}`} target="none"><RequiredIngredientButton>{item.schema_name}</RequiredIngredientButton></a><br/>
    <br/>Template ID:<br/>
    <a href={`${currentAtomicHubLink}/explorer/template/${item.collection_name}/${item.template_id}`} target="none"><RequiredIngredientButton>{item.template_id}</RequiredIngredientButton></a><br/>
    { item.mutable_attributes.length > 0 && (
      <span>
    <br/>With the following attributes:<br/>
    {item.mutable_attributes.map((attributeItem, attributeIndex) => (
<span>
{attributeItem.name}: {attributeItem.value}<br/>
</span>
    ))}
</span>
    )}
   
</RequiredIngredient>
)}
{/* END OF ATTRIBUTE BASED */}

{/* FOR FUNGIBLE INGREDIENTS */}
{item.result_type == "fungible" && (
<RequiredIngredient> 
    • Fungible Token:<br/>
    <FungibleTokenButton href={`https://wax.bloks.io/account/${item.fungible_token_contract}?loadContract=true&tab=Tables&table=stat&account=${item.fungible_token_contract}&scope=${item.fungible_token_symbol.substr(item.fungible_token_symbol.indexOf(',') + 1)}&limit=100`} target="none">
    {Number(item.fungible_token_amount).toFixed(Number(item.fungible_token_symbol.substr(0,item.fungible_token_symbol.indexOf(','))))} {item.fungible_token_symbol.substr(item.fungible_token_symbol.indexOf(',') + 1)}@{item.fungible_token_contract}
    </FungibleTokenButton>   


</RequiredIngredient>
)}
{/* END OF FUNGIBLE */}

{/* FOR PREMINTED INGREDIENTS */}
{item.result_type == "preminted" && (
    <RequiredIngredient>
        <DropTitle>
       1 NFT From Each Of These Pools:
    </DropTitle>
    <RequiredIngredient>
       
    

    { item.preminted_pool_id.length > 0 ? item.preminted_pool_id.map((premintItem, premintIndex) => (


      //render all this if the farm type is template and the template matches

      <a key={premintIndex} href={`${currentWebsiteURL}/premint-pool/${premintItem}`} target="none"><UnboxTemplate>{premintItem}</UnboxTemplate></a>


      )) : (
      <NoDaosMessage>
        No pools to draw from.
      </NoDaosMessage>


      )}

      </RequiredIngredient>
      
</RequiredIngredient>
)}
{/* END OF PREMINTED */}

</span>

)) : (
<NoDaosMessage>
  Could not fetch ingredients
</NoDaosMessage>

)}

</RequiredIngredientsCont>

</DropDescription>
{/* END OF RESULTS MAP */}




        <DropDescription>

        <DropTitle>
            Drop Creator: 
        </DropTitle>
        <DropBody>
            {createdBy != null && createdBy}
        </DropBody>

        <DropTitle>
            Total Ingredients: 
        </DropTitle>
        <DropBody>
            {blendIngredients != null && blendIngredients.length}
        </DropBody>

        <DropTitle>
            Total Results: 
        </DropTitle>
        <DropBody>
            {blendResults != null && blendResults.length}
        </DropBody>

        {dropType != null && dropType == "premint.pool" && (
          <span>
        <DropTitle>
            NFTs Per Order:
        </DropTitle>
        <DropBody>
            {poolIDs != null && poolIDs.length > 0 && (<span>{poolIDs.length}</span>)}
        </DropBody>


        <DropTitle>
            Drop Draws From These Pools:
        </DropTitle>
        <DropBody>
           
        

        { poolIDs.length > 0 ? poolIDs.map((item, index) => (


          //render all this if the farm type is template and the template matches

          <a key={index} href={`https://${currentWebsiteURL}/premint-pool/${item}`} target="none"><UnboxTemplate>{item}</UnboxTemplate></a>


          )) : (
          <NoDaosMessage>
            No pools to draw from.
          </NoDaosMessage>


          )}

          </DropBody>


















        </span>
      )}

        <DropTitle>
            Limit Per User: 
        </DropTitle>
        <DropBody>
            {limitPerUser != null && limitPerUser}
        </DropBody>

        <DropTitle>
            Cooldown: 
        </DropTitle>
        <DropBody>
            {cooldownSeconds != null && cooldownSeconds != '0' && (<span>{cooldownSeconds} seconds</span>)}
            {cooldownSeconds != null && cooldownSeconds == '0' && (<span>No cooldown</span>)}
        </DropBody>

        <DropTitle>
            Start Time: 
        </DropTitle>
        <DropBody>
            {dropBegins != null && dropBegins != '0' && (<span>{new Date(dropBegins * 1000).toLocaleString()}</span>)}
            {dropBegins != null && dropBegins == '0' && (<span>In progress</span>)}
        </DropBody>

        <DropTitle>
            End Time: 
        </DropTitle>
        <DropBody>
           {dropEnds != null && dropEnds != '0' && (<span>{new Date(dropEnds * 1000).toLocaleString()}</span>)}
        </DropBody>

                <br/><br/>
                {dropDescription != null && dropDescription}
        </DropDescription>


        </Drop>

        </DropPageCont>


    )} 
    
    {/* //end if drop price is not null */}

    

    {(blendIngredients[0] == null || blendIngredients[0] == '') && (


    <DropPageCont>
        <MissingInfoMsg>
        This blend does not exist.<br/><br/>
        If this is an error, we may be having API issues.
        </MissingInfoMsg>
    </DropPageCont>


    )} 

    {/* //end if drop price is not null */}  

    </MainContainer>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default BlendPageV2