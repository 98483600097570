import React, { useEffect } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { AllFarmsCont, FarmsPageCont, FarmsTitle, SingleFarmCont, FarmLogo, FarmTitle, FarmEndDate, PoolAmount, ProposerTypeMessage, DaoButton } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { myChain, wax, anchor, currentApiList, currentAtomicApiList, currentWebsiteURL, currentUsername, wombat } from '../data/config';
import { NewListingBody, NewListingCont } from '../Styles';
import Synthetics from '../components/WalletComponents/Synthetics';


const SyntheticsPage = () => {

const { enterModalDisplay,
 setEnterModalDisplay,
 enterModalText,
 setEnterModalText,
 loadingDisplay,
 setLoadingDisplay
} = useStateContext()

  return (
  <div id="seo">
    <Helmet>
    <title>Synthetic Assets - WaxDAO</title>
    <meta name="description" content="Create and swap synthetic assets on WaxDAO" />
    <link rel="canonical" href="https://waxdao.io/synths" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>
   <NewListingCont>
       <NewListingBody>
       <Synthetics
                  something={"Hello"}
                  person="Mike"
                  a={enterModalDisplay}
                  b={setEnterModalDisplay}
                  c={enterModalText}
                  d={setEnterModalText}
                  e={loadingDisplay}
                  f={setLoadingDisplay}
                />
       </NewListingBody>
   </NewListingCont>


    </UALProvider>
 
    </div>
  )
}

export default SyntheticsPage