import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax';
import { User } from "universal-authenticator-library";
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { MdSwapVerticalCircle } from 'react-icons/md';
import { AllFarmsCont, DaoButton, FarmsPageCont, FarmsTitle, GameButton, MissingInfoMsg, Modal, ModalContent, Price, ProposalInput, SingleDropCont, Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed } from '../components/LeftDiv';
import { myChain, wax, anchor, currentApiList, currentAtomicApiList, adminMode } from '../data/config';


const swapTokens = async (swapInAmount, swapInToken, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');

    const walletProvider = localStorage.getItem('walletProvider');

    if(walletProvider != 'wax' && walletProvider != 'anchor'){

      setEnterModalText('You are not logged in. Click "Wallet" in the top menu');
  
    }
  
    if(walletProvider == 'wax'){
  
    // Retrieve current session from state
    const session = await wax.login()
    //console.log(session[0].signTransaction);
  
    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'mdcryptonfts',
            name: 'transfer',
            authorization: [{
              actor: wax.users[0].accountName,
              permission: wax.users[0].requestPermission,
          }],
          data: {
              to: 'waxywojaknft',
              from: wax.users[0].accountName,
              quantity: swapInAmount + ' ' + swapInToken,
              memo: 'swap',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 60,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your swap has been completed');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {


            setEnterModalText(JSON.stringify(e.message));

      console.log(e)
      
    }
    
    }//end if wallet = wax
  
  
    if(walletProvider == 'anchor'){
  
      // Retrieve current session from state
      const session = await anchor.login()
      //console.log(session[0].signTransaction);
    
      try {
        // Reset our response state to clear any previous transaction data
        
        const action = [{
              account: 'mdcryptonfts',
              name: 'transfer',
              authorization: [{
                actor: anchor.users[0].accountName,
                permission: anchor.users[0].requestPermission,
            }],
            data: {
                to: 'waxywojaknft',
                from: anchor.users[0].accountName,
                quantity: swapInAmount + ' ' + swapInToken,
                memo: 'swap',
            }
          }]
        // Call transact on the session (compatible with eosjs.transact)
        const response = await session[0].signTransaction({
          actions: action
        }, {

          blocksBehind: 3,
          expireSeconds: 60,
          broadcast: true,
        })
        // Update application state with the responses of the transaction
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Processing your transaction...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your swap has been completed');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 4000);
        return () => clearTimeout(timer);
    
      } catch(e) {
  
  
              setEnterModalText(JSON.stringify(e.message));
  
        console.log(e)
        
      }
      
      }//end if wallet = anchor
  
  
  
  }



const Swap = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, tokenList, setTokenList, noTokensDisplay, setNoTokensDisplay, setBurnableDisplay, burnable, setBurnable, setNoAssetsDisplay, noAssetsDisplay, refreshAssets, setRefreshAssets, swapInAmount, setSwapInAmount, swapOutAmount, setSwapOutAmount, swapInToken, setSwapInToken, swapOutToken, setSwapOutToken, swapInDisplay, setSwapInDisplay, swapOutDisplay, setSwapOutDisplay,
  enterModalText,
  setEnterModalText,
  loadingDisplay, 
  setLoadingDisplay, 
  enterButtonsDisplay,
  setEnterButtonsDisplay,
  enterModalDisplay, 
  setEnterModalDisplay,
  
  
  } = useStateContext();


useEffect(() => {
    setTokenList([]);
     const waxUsername = localStorage.getItem('waxAccount');

      
     if(waxUsername){
       //console.log('You have an active session');
       '';
       //setProfileDisplay('flex');
     }else{
       //console.log('You are not logged in');
       return('');
     }
      
      //console.log(waxUsername)
      axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
        
        table:"accounts",
        scope:waxUsername,
        code:'mdcryptonfts',
        limit:50,
        json:true
      })
        .then((response) => {

          if(response.data.rows[0] != undefined){
            setNoTokensDisplay('hidden');
          }
    
          setTokenList(response.data.rows);
          //console.log(response.data.rows);
        })
        .catch((error) => console.log(error.response.data));
    
      }
    
    , []);



useEffect(() => {
  const abortCont2 = new AbortController();
  setBurnable([]);
  setBurnableDisplay('hidden');
  setNoAssetsDisplay('block');
   const waxUsername = localStorage.getItem('waxAccount');
   const walletProvider = localStorage.getItem('walletProvider');
    
   if(waxUsername){
     //console.log('You have an active session');
     '';
     //setProfileDisplay('flex');
   }else{
     //console.log('You are not logged in');
     return('');
   }

        axios.get(`${currentAtomicApiList[Math.floor(Math.random() * (1 - 0) ) + 0]}/atomicassets/v1/assets?collection_name=hoodpunknfts&schema_name=wojakpacks&owner=${waxUsername}&page=1&limit=50&order=desc&sort=asset_id`, {signal: abortCont2.signal})
          .then((burnResponse) => {

            if(burnResponse.data.data[0] != undefined){
              setBurnableDisplay('flex')
              setNoAssetsDisplay('hidden')

            }
      
            setBurnable(burnResponse.data.data);
            //console.log(burnResponse);
          })


      .catch((error) => console.log(error));
  
      return() => {
      
        abortCont2.abort();
        
      }

    }

  
  , [refreshAssets]);












  return (
    <div id="seo">
    <Helmet>
    <title>Swap WAXDAO Tokens - WaxDAO</title>
    <meta name="description" content="Swap your WAXDAO tokens for WOJAK, using the WaxDAO token swap" />
    <link rel="canonical" href="https://waxdao.io/wojak/swap" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>


      <FarmsPageCont>

      <FarmsTitle>
        Swap Tokens
      </FarmsTitle>

      <MissingInfoMsg>
        You can think of WAXDAO token as "synthetic WOJAK". It is exchangeable 1:1 with WOJAK 
        at all times by using this page.
        <br/><br/>
        WAXDAO token was created because WOJAK is only divisible by whole numbers. If you plan 
        on selling, you need WOJAK because that's the one you will be able to sell on Alcor etc.
      </MissingInfoMsg>
    

<AllFarmsCont>
<SingleDropCont className='text-white'>
       <span className="font-semibold">Swap at a 1:1 rate</span>
       <br/>

<br/>
        <label htmlFor="tokenName"><span className="font-semibold">From:</span> {swapInToken}<br /></label>

      <ProposalInput type="number" 
      id="tokenName" 
      placeholder='0'
      value={swapInDisplay}
      onChange={(e) => {

        if(swapInToken == "WAXDAO"){

            setSwapInDisplay(Math.round(e.target.value));
            setSwapOutDisplay(Math.round(e.target.value));
            setSwapInAmount((Math.round(e.target.value) * 1.001).toFixed(8));
            setSwapOutAmount(Math.round(e.target.value));


        }


        if(swapInToken == "WOJAK"){

            setSwapInDisplay(Math.round(e.target.value));
            setSwapOutDisplay(Math.round(e.target.value));
            setSwapInAmount(Math.round(e.target.value));
            setSwapOutAmount((Math.round(e.target.value) * 0.999).toFixed(8));

        }

      
      
      }}
      />
      <br/>


      <button 
          type="button"
          className="text-lg
          hover:drop-shadow-xl
          hover:bg-light-gray
          text-white rounded-full mt-4"
          onClick={() => {

            if(swapInToken == "WOJAK" && swapOutToken == "WAXDAO"){
                let swapInAmt = swapInAmount;
                setSwapInAmount((Math.round(swapInAmount) * 1.001).toFixed(8));
                setSwapOutAmount(swapInAmt);

                setSwapInToken("WAXDAO");
                setSwapOutToken("WOJAK");
                
            }

            if(swapInToken == "WAXDAO" && swapOutToken == "WOJAK"){
                let swapInAmt = swapOutAmount;
                setSwapOutAmount((Math.round(swapInAmount) * 0.999).toFixed(8));
                setSwapInAmount(swapInAmt);

                setSwapInToken("WOJAK");
                setSwapOutToken("WAXDAO");
                
            }

          }
            
        
          }
          style={{ background: '#fa872d'
          }}
        
        >
         <MdSwapVerticalCircle className='text-3xl' />
        </button> 
        <br/>
      <br/>
        <label htmlFor="tokenName"><span className="font-semibold">To:</span> {swapOutToken} <br /></label>

      <ProposalInput type="number" 
      id="tokenName" 
      placeholder='0'
      value={swapOutDisplay}
      onChange={(e) => {
        if(swapOutToken == "WOJAK"){

            setSwapInDisplay(Math.round(e.target.value));
            setSwapOutDisplay(Math.round(e.target.value));
            setSwapInAmount((Math.round(e.target.value) * 1.001).toFixed(8));
            setSwapOutAmount(Math.round(e.target.value));

        }


        if(swapOutToken == "WAXDAO"){

            setSwapInDisplay(Math.round(e.target.value));
            setSwapOutDisplay(Math.round(e.target.value));
            setSwapInAmount(Math.round(e.target.value));
            setSwapOutAmount((Math.round(e.target.value) * 0.999).toFixed(8));

        }
      
      
      }}
      />
<br/>


Fee: 0.1%
<br/>
Swap {swapInAmount} {swapInToken} For {swapOutAmount} {swapOutToken}
<br/>
      <DaoButton 
          onClick={() => swapTokens(swapInAmount, swapInToken, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}
        >
          Swap
        </DaoButton> 

        </SingleDropCont>
        </AllFarmsCont>
    <Price>
              <p className="font-semibold text-xl m-auto text-center">My Balance: </p>

              <p className={`${noTokensDisplay} m-auto text-center`}>You hold no WOJAK or WAXDAO in this account.</p>
             


              {tokenList.map((item, index) => (


                
              <div key={index}

              className='block p-3 m-auto rounded-lg gap-4 m-auto mt-4 text-center'
              style={{ border: '1px solid #fa872d' }}

              >{item.balance}</div>


              ))}

              </Price>



      

   

      </FarmsPageCont>
      <br/><br/><br/>
    </UALProvider>

    </div>
  )
}

export default Swap