import React, { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { UALProvider } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
} from "../components/LeftDiv";
import {
  wax,
  anchor,
  myChain,
  currentWebsiteURL,
  wombat,
} from "../data/config";
import {
  MainButton,
  NewListingBody,
  NewListingCont,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../Styles";
import { createDAO } from "../data/functions/dao_functions";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const CreateDAO = () => {
  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
  } = useStateContext();

  const [twoAssetsToBurn, setTwoAssetsToBurn] = useState(Array(2));
  const [paymentMethod, setPaymentMethod] = useState("");

  return (
    <div id="seo">
      <Helmet>
        <title>Create A DAO - WaxDAO</title>
        <meta
          name="description"
          content="Create your own DAO on the WAX blockchain in just a few clicks."
        />
        <link rel="canonical" href="https://waxdao.io/create-dao" />
      </Helmet>

      <UALProvider
        chains={[myChain]}
        authenticators={[anchor, wax, wombat]}
        appName={"WaxDAO"}
      >
        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <GameButton onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </GameButton>
        </Modal>

        <NewListingCont>
          <NewListingBody>
            <TallFiltersCont>
              <h2>Attention!</h2>
              <br/>
              <TallFiltersInnerCont>
                <ReactMarkdown>
                A brand new version of the DAO creator has been released! Please use the 
                [V2 DAO Creator](https://waxdao.io/v2/create-dao) to create your DAO.
                </ReactMarkdown>
              </TallFiltersInnerCont>
            </TallFiltersCont>
          </NewListingBody>
        </NewListingCont>
      </UALProvider>
      <br />
      <br />
      <br />
    </div>
  );
};

export default CreateDAO;
