import React from 'react'
import { Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed } from './LeftDiv'

const LoadingDiv = () => {
  return (
    <div style={{textAlign:"center", paddingTop:"30px", width:"100%"}}>
    <Spinner>
    <SpinnerRed  />
    <SpinnerBlue  />
    <SpinnerGreen />
    </Spinner>
    <br/>
    <span className='text-white'>
    Loading...
    </span>
    </div>
  )
}

export default LoadingDiv