import React, { useEffect, useState } from 'react'
import { AdminPanelTopMenu, GameButton, MainContainer, Modal, ModalContent, ModalOverlay, NewBlogCont, Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed, SQLInput, SQLLabel, SQLTextarea } from './../components/LeftDiv'
import { SubmitBlogButton } from '../data/css/BlogStyles'
import { anchor, myChain, wax } from './../data/config'
import { Helmet } from 'react-helmet'
import { currentWebsiteURL } from '../data/config'
import { UALProvider } from 'ual-reactjs-renderer'
import { requestNewListing } from '../data/functions/ListingRequestFunctions';
import { useStateContext } from '../contexts/ContextProvider'
import { RequiredInput, RequiredLabel } from '../data/css/TokenTrackerStyles'
import { NewListingCont } from '../Styles'

const Listing = () => {

    const { setCurrentNavbarLocation } = useStateContext();

    const maxDescriptionCharacters = 500;

    const [website, setWebsite] = useState("");
    const [whitepaper, setWhitepaper] = useState("");
    const [nftCollection, setNftCollection] = useState("");
    const [projectName, setProjectName] = useState("");
    const [github, setGithub] = useState("");
    const [logo, setLogo] = useState("");

    //Socials

    const [twitter, setTwitter] = useState("");
    const [telegram, setTelegram] = useState("");
    const [medium, setMedium] = useState("");
    const [discord, setDiscord] = useState("");
    const [youtube, setYoutube] = useState("");

    //New Token Info
    const [symbol, setSymbol] = useState("");
    const [contract, setContract] = useState("");

    //Other
    const [description, setDescription] = useState("");
    const [comments, setComments] = useState("");

    const [enterModalDisplay, setEnterModalDisplay] = useState("hidden");
    const [enterModalText, setEnterModalText] = useState("Awaiting Confirmation...")
    const [loadingDisplay, setLoadingDisplay] = useState("none");

    useEffect(() => {
      setCurrentNavbarLocation("Tokens");
    
    }, [])
    


  return (
    <NewListingCont>
        <Helmet>
        <title>Request Token Listing</title>
        <meta name="description" content="Request listing of your project on WaxDAO's token tracker" />
        <link rel="canonical" href={`${currentWebsiteURL}/request-listing`} />
        </Helmet>

        <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    <ModalOverlay className={enterModalDisplay}/>
    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => {setEnterModalDisplay('hidden'); } }>
        Close Window
      </GameButton>
      </Modal>


        <AdminPanelTopMenu>
          Please fill out the form below to submit your project for listing. This info will be stored on the blockchain in plain text, and can 
          be seen by anyone.
          <br/><br/>
          Note: <span className='font-bold'>You must sign this transaction using the same account that the token contract is stored on.</span>

        </AdminPanelTopMenu>

        <NewBlogCont>
        <RequiredLabel><span style={{color: "#c30000"}}>*</span> = Required</RequiredLabel>
        <br/><br/>

        <RequiredLabel>Token Symbol <span style={{color: "#c30000"}}>*</span></RequiredLabel>
        <SQLInput type="text" placeholder='e.g. WAX' maxLength={7} value={symbol} onChange={(e) => { setSymbol(e.target.value.toUpperCase()) } } />

        <RequiredLabel>Token Contract <span style={{color: "#c30000"}}>*</span></RequiredLabel>
        <SQLInput type="text" placeholder='e.g. eosio.token' maxLength={12} value={contract} onChange={(e) => { setContract(e.target.value.toLowerCase()) } } />

        <RequiredLabel>Project Name <span style={{color: "#c30000"}}>*</span></RequiredLabel>
        <SQLInput type="text" maxLength={30} placeholder='e.g. waxdao' value={projectName} onChange={(e) => { setProjectName(e.target.value) } } />

        <RequiredLabel>Project Description <span style={{color: "#c30000"}}>*</span> ({(description == null || description == "") ? "500/500" : (<span>{maxDescriptionCharacters - description.length}/{maxDescriptionCharacters}</span>)  })</RequiredLabel>
        <SQLTextarea type="text" placeholder='Brief description of your project' maxLength={500} value={description} onChange={(e) => { setDescription(e.target.value) } } />

        <SQLLabel>Token Logo (IPFS HASH ONLY)</SQLLabel>
        <SQLInput type="text" maxLength={150} placeholder='e.g. Qmabc12345....' value={logo} onChange={(e) => { setLogo(e.target.value) } } />

        <RequiredLabel>Website <span style={{color: "#c30000"}}>*</span></RequiredLabel>
        <RequiredInput type="text" value={website} onChange={(e) => { setWebsite(e.target.value) } } />

        <SQLLabel>Whitepaper</SQLLabel>
        <SQLInput type="text" value={whitepaper} onChange={(e) => { setWhitepaper(e.target.value) } } />

        <SQLLabel>NFT Collection (Name Only)</SQLLabel>
        <SQLInput type="text" maxLength={12} placeholder="e.g. hoodpunknfts" value={nftCollection} onChange={(e) => { setNftCollection(e.target.value) } } />

        <SQLLabel>Github</SQLLabel>
        <SQLInput type="text" value={github} onChange={(e) => { setGithub(e.target.value) } } />


        <SQLLabel>Twitter</SQLLabel>
        <SQLInput type="text" value={twitter} onChange={(e) => { setTwitter(e.target.value) } } />

        <SQLLabel>Telegram</SQLLabel>
        <SQLInput type="text" value={telegram} onChange={(e) => { setTelegram(e.target.value) } } />

        <SQLLabel>Medium</SQLLabel>
        <SQLInput type="text" value={medium} onChange={(e) => { setMedium(e.target.value) } } />

        <SQLLabel>Discord</SQLLabel>
        <SQLInput type="text" value={discord} onChange={(e) => { setDiscord(e.target.value) } } />

        <SQLLabel>Youtube</SQLLabel>
        <SQLInput type="text" value={youtube} onChange={(e) => { setYoutube(e.target.value) } } />

        <SQLLabel>Additional Comments ({(comments == null || comments == "") ? "500/500" : (<span>{maxDescriptionCharacters - comments.length}/{maxDescriptionCharacters}</span>)  })</SQLLabel>
        <SQLTextarea type="text" maxLength={500} value={comments} onChange={(e) => { setComments(e.target.value) } } />



      {contract == null || contract.length == 0 || 
      symbol == null || symbol.length == 0 || 
      projectName == null || projectName.length == 0 || 
      description == null || description.length == 0 || 
      website == null || website.length == 0 ? (
        <RequiredLabel>
          <span style={{color: "#c30000"}}>*</span>
          &nbsp;Missing required info&nbsp;
          <span style={{color: "#c30000"}}>*</span>
          </RequiredLabel>
      ) :
      (
        <SubmitBlogButton onClick={() => { requestNewListing(contract, symbol, projectName, description, website, 
          whitepaper, nftCollection, github, twitter, telegram, medium,
          discord, youtube, comments, logo,
          setEnterModalText, setLoadingDisplay, setEnterModalDisplay); }}  style={{border:"1px solid #bcbcbc", marginTop:"25px"}}>
          Request Listing
        </SubmitBlogButton>
      )
      }

        <br/>

        </NewBlogCont>



    </UALProvider>

    </NewListingCont>
  )
}

export default Listing