import axios from "axios";
import {
  ModalNFT,
  ModalNFTCont,
  ModalNFTLogo,
  NFT,
  NoDaosMessage,
} from "../../components/LeftDiv";
import { getCurrentApiList, getCurrentAtomicApiList } from "../config";
import { getRndInteger } from "./DropFunctions";
import { getWalletSession } from "./wallet_functions";

export const createBlend = async (
  blendTitle,
  dropDescription,
  logoHash,
  startTimestamp,
  endTimestamp,
  totalAvailable,
  whitelistType,
  farmName,
  minimumNftsStaked,
  usernames,
  ingredientsList,
  resultsList,
  paymentReceivers,
  receivingAccount,
  limitPerUser,
  cooldownSeconds,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  var theMinimum;

  if (
    minimumNftsStaked == null ||
    minimumNftsStaked == "" ||
    minimumNftsStaked == undefined
  ) {
    theMinimum = "0";
  } else {
    theMinimum = minimumNftsStaked;
  }

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: "waxdaomrktv2",
        name: "newblend",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          creator: this_user,
          blend_title: blendTitle,
          blend_description: dropDescription,
          cover_image: logoHash,
          start_time: startTimestamp,
          end_time: endTimestamp,
          max_blends: totalAvailable,
          whitelist_type: whitelistType,
          whitelisted_farmname: farmName,
          minimum_to_stake: theMinimum,
          whitelisted_accounts: usernames.split(","),
          blend_ingredients: ingredientsList,
          blend_results: resultsList,
          payment_receivers: paymentReceivers,
          nft_receiver: receivingAccount,
          limit_per_user: limitPerUser,
          cooldown_reset: cooldownSeconds,
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Creating your blend...");
    const timer = setTimeout(() => {
      setEnterModalText("Your blend has been created");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
}; //end createBlend

export const finalizeBlend = async (
  setMintOnDemandResultText,
  setPremintedResultText,
  setFungibleResultText,
  DropID,
  setModalNFTContDisplay,
  assetVector,
  setAssetVector,
  stakeMultipleDisplay,
  blendIngredients,
  setBlendIngredients,
  unstaked,
  setUnstaked,
  setBlendModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setBlendModalDisplay
) => {
  setBlendModalDisplay("");
  setModalNFTContDisplay("none");
  setBlendModalText("Awaiting confirmation...");
  setFungibleResultText("");
  setPremintedResultText("");
  setMintOnDemandResultText("");

  let uniqueID = getRndInteger(100000000, 200000000);

  let apiWorked = "no";

  let walletSession = await getWalletSession(setBlendModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let current_ingredient_index = 0;
  let blend_actions = [];
  let ingredient_list = [...blendIngredients];

  while (current_ingredient_index < blendIngredients.length) {
    if (
      ingredient_list[current_ingredient_index].ingredient_type == "fungible"
    ) {
      blend_actions[current_ingredient_index] = {
        account:
          ingredient_list[current_ingredient_index].fungible_token_contract,
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxdaomarket",
          from: this_user,
          quantity:
            Number(
              ingredient_list[current_ingredient_index].fungible_token_amount
            ).toFixed(
              Number(
                ingredient_list[
                  current_ingredient_index
                ].fungible_token_symbol.substr(
                  0,
                  ingredient_list[
                    current_ingredient_index
                  ].fungible_token_symbol.indexOf(",")
                )
              )
            ) +
            " " +
            ingredient_list[
              current_ingredient_index
            ].fungible_token_symbol.substr(
              ingredient_list[
                current_ingredient_index
              ].fungible_token_symbol.indexOf(",") + 1
            ),
          memo:
            "|blend_deposit|" + DropID + "|" + current_ingredient_index + "|",
        },
      };
    } //end if type is fungible
    else if (
      ingredient_list[current_ingredient_index].ingredient_type == "nonfungible"
    ) {
      blend_actions[current_ingredient_index] = {
        account: "atomicassets",
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxdaomarket",
          from: this_user,
          asset_ids: ingredient_list[current_ingredient_index].asset_ids,
          memo:
            "|blend_deposit|" + DropID + "|" + current_ingredient_index + "|",
        },
      };
    } //end if type is fungible

    current_ingredient_index++;
  }

  try {
    const action = [
      {
        account: "waxdaomarket",
        name: "assertblend",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          blend_ID: DropID,
          unique_id: uniqueID,
        },
      },
    ];

    const final_action = [...action, ...blend_actions];

    const response = await session[0].signTransaction(
      {
        actions: final_action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");

    setBlendModalText("Fetching blend results...");
    const timer = setTimeout(() => {
      try {
        axios
          .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
            table: "blendresult",
            scope: "waxdaomarket",
            code: "waxdaomarket",
            limit: 1,
            lower_bound: uniqueID,
            upper_bound: uniqueID,
            json: true,
          })
          .then((resultResponse) => {
            if (
              resultResponse.data.rows[0].time != null &&
              resultResponse.data.rows[0].time > 0
            ) {
              setBlendModalText("");

              apiWorked = "yes";

              if (resultResponse.data.rows[0].preminted_results.length > 0) {
                axios
                  .get(
                    `${getCurrentAtomicApiList}/atomicassets/v1/assets?ids=${resultResponse.data.rows[0].preminted_results.join(
                      "%2C"
                    )}&page=1&limit=100&order=desc&sort=asset_id`
                  )
                  .then((validResponse) => {
                    setPremintedResultText(
                      <span className="text-center align-center justify-center">
                        Pre-Minted Results:
                        <br />
                        <ModalNFTCont>
                          {validResponse != null &&
                          validResponse.data.data.length > 0 ? (
                            validResponse.data.data.map((item, index) => (
                              <ModalNFT key={index}>
                                <p key={index}>
                                  <span className="font-bold">
                                    {item.name != null ? item.name : ""}
                                  </span>
                                  <br />
                                  Mint{" "}
                                  {item.template_mint != null
                                    ? item.template_mint
                                    : ""}
                                </p>

                                {item.data.img != null && (
                                  <ModalNFTLogo
                                    src={`https://ipfs.io/ipfs/${item.data.img.trim()}`}
                                  />
                                )}

                                {item.data.img == null &&
                                  item.data.video != null && (
                                    <video
                                      style={{
                                        width: "150px",
                                        maxWidth: "150px",
                                        height: "150px",
                                        maxHeight: "150px",
                                        borderRadius: "25%",
                                      }}
                                      loop
                                    >
                                      <source
                                        src={`https://ipfs.io/ipfs/${item.data.video.trim()}`}
                                        type="video/mp4"
                                      />
                                    </video>
                                  )}

                                {item.data.img == null &&
                                  item.data.video == null &&
                                  item.data.image != null && (
                                    <ModalNFTLogo
                                      src={`https://ipfs.io/ipfs/${item.data.image.trim()}`}
                                    />
                                  )}
                              </ModalNFT>
                            ))
                          ) : (
                            <span></span>
                          )}
                        </ModalNFTCont>
                      </span>
                    );
                  });
              } //end if preminted results is > 0

              setMintOnDemandResultText(
                <span className="text-center align-center justify-center">
                  Mint On Demand Results:
                  <br />
                  <ModalNFTCont>
                    {resultResponse.data.rows[0].mint_on_demand_results.length >
                    0 ? (
                      resultResponse.data.rows[0].mint_on_demand_results.map(
                        (item, index) => (
                          <ModalNFT key={index}>
                            <p>
                              <span className="font-bold">
                                {item.nft_name != null ? item.nft_name : ""}
                              </span>
                              <br />
                              Template{" "}
                              {item.template_id != null ? item.template_id : ""}
                            </p>
                            {item.nft_image != null && (
                              <ModalNFTLogo
                                src={`https://ipfs.io/ipfs/${item.nft_image.trim()}`}
                              />
                            )}
                            <br />
                            Quantity:{" "}
                            {item.non_fungible_quantity != null
                              ? item.non_fungible_quantity
                              : "?"}
                          </ModalNFT>
                        )
                      )
                    ) : (
                      <span>No mint on demand results were found</span>
                    )}
                  </ModalNFTCont>
                </span>
              );

              if (resultResponse.data.rows[0].fungible_results.length > 0) {
                setFungibleResultText(
                  <span className="text-center align-center justify-center">
                    <br />
                    Fungible Results:
                    <br />
                    <ModalNFTCont>
                      {/* MAP THROUGH EACH TOKEN AND DISPLAY QUANTITY, CONTRACT */}

                      {resultResponse.data.rows[0].fungible_results.length >
                      0 ? (
                        resultResponse.data.rows[0].fungible_results.map(
                          (item, index) => (
                            <span key={index}>
                              {/* FOR FUNGIBLE INGREDIENTS */}
                              {item.amount != null && (
                                <NFT>
                                  {item.amount}@{item.contract}
                                </NFT>
                              )}
                              {/* END OF FUNGIBLE */}
                            </span>
                          )
                        )
                      ) : (
                        <NoDaosMessage>
                          Could not find any fungible token results
                        </NoDaosMessage>
                      )}
                    </ModalNFTCont>
                  </span>
                );
              } //end if fungible results > 0
              else {
                //TELL THE USER THAT THEY HAVE NO FUNGIBLE RESULTS
              }
            } else {
              setBlendModalText(
                "Could not retrieve result. Check your wallet to see what you got."
              );
            }
            setLoadingDisplay("none");
            setEnterButtonsDisplay("");
          });
      } catch (e) {
        //end try timer

        setBlendModalText("Could not retrieve result. Check your wallet.");
        console.log(e);
      } //end catch timer
    }, 4000);

    const timer2 = setTimeout(() => {
      if (apiWorked == "no") {
        setBlendModalText("Could not retrieve result. Check your wallet.");
        setEnterButtonsDisplay("");
        setLoadingDisplay("none");
      }
    }, 5000);

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  } catch (e) {
    setBlendModalText(JSON.stringify(e.message));
    console.log(e);
  }
}; //end finalizeBlend
