import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { AllFarmsCont, PoolAmount, FarmButton, SingleFarmCont, FarmLogo, FarmTitle, FarmsPageCont, FarmsTitle, HighlightedFarm, HighlightedLogo, HighlightDetails, HighlightTitle, FarmEndDate, HighlightMessage, HighlightAmount, NewUIFarmsPageCont, WalletSearchBar } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { currentApiList, currentAtomicApiList, wax, anchor, myChain, currentWebsiteURL, currentAdBanner, wombat } from '../data/config';
import { checkWallet } from '../data/functions/wallet_functions';
import CurrentBannerAd from '../components/CurrentBannerAd';

const UserFarms = () => {

  const { CreatorName } = useParams();

  const { farmList, setFarmList, stakedOnly, setStakedOnly, setFarmsUserIsIn,
  } = useStateContext();

  const [farmToSearchFor, setFarmToSearchFor] = useState("");


useEffect(() => {
  const abortCont6 = new AbortController();

  setFarmList([]);  

  axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
    table:"farms",
    scope:"waxdaofarmer",
    code:"waxdaofarmer",
    key_type: 'name',
    index_position: 3,
    lower_bound:CreatorName,
    upper_bound:CreatorName,
    limit:1000,
    json:true,
    signal: abortCont6.signal
  })
    .then((response) => {

      setFarmList([response.data.rows]);

    })
    .catch((error) => console.log(error));

    return() => abortCont6.abort();

}, []);












  return (
  <div id="seo">
    <Helmet>
    <title>Farms</title>
    <meta name="description" content="NFT staking pools on WaxDao" />
    <link rel="canonical" href={`${currentWebsiteURL}/farms`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>
    <NewUIFarmsPageCont>
      <FarmsTitle>
       NFT Farms {CreatorName != null && (<span>By {CreatorName}</span>)}
      </FarmsTitle>
   
      <CurrentBannerAd />
      
      <br/>
      <div className='text-center'>
      <WalletSearchBar placeholder='Enter farm name...' value={farmToSearchFor} maxLength={12} onChange={(e) => { setFarmToSearchFor(e.target.value.toLowerCase()) }} />
      </div>

        <AllFarmsCont>



        {farmList[1] == null && farmList[0] != null && !Array.isArray(farmList[0]) && (
            <a href={`${currentWebsiteURL}/farm/${farmList[0].farmname}`}>
            <SingleFarmCont>
              {farmList[0].logo != null && (
                 <FarmLogo src={`https://ipfs.io/ipfs/${farmList[0].logo}`} />



              )}
              {farmList[0].farmname != null && (
                <FarmTitle>
                {farmList[0].farmname}
                </FarmTitle>
              )}
              {farmList[0].poolsize != null && (
                <PoolAmount>
                  <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(farmList[0].poolsize.substring(0, farmList[0].poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
                 {farmList[0].poolsize.substring(farmList[0].poolsize.indexOf(' '))}
                </PoolAmount>
              )}
              {farmList[0].enddate != null && (
                <FarmEndDate>
                  Ends {new Date(farmList[0].enddate * 1000).toLocaleDateString()}
                </FarmEndDate>
              )}

            </SingleFarmCont>
            </a>

)}




        {farmList[1] == null && farmList[0] != null && Array.isArray(farmList[0]) ? (farmList[0].sort((a,b) => b.time_created - a.time_created).map((item, index) => (
            <span className={(farmToSearchFor.length >= 1 && item.farmname.substring(0,farmToSearchFor.length).indexOf(farmToSearchFor) == -1 ) && "hidden" }>
            <a href={`${currentWebsiteURL}/farm/${item.farmname}`}>
            <SingleFarmCont>
              {item.logo != null && (
              <FarmLogo src={`https://ipfs.io/ipfs/${item.logo}`} />




              )}
              {item.farmname != null && (
                <FarmTitle>
                {item.farmname}
                </FarmTitle>
              )}
              {item.poolsize != null && (
                <PoolAmount>
                  <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
                 {item.poolsize.substring(item.poolsize.indexOf(' '))}
                </PoolAmount>
              )}
              {item.enddate != null && (
                <FarmEndDate>
                  Ends {new Date(item.enddate * 1000).toLocaleDateString()}
                </FarmEndDate>
              )}

            </SingleFarmCont>
            </a>
            </span>
            ))) : farmList[1] != null && Array.isArray(farmList) && farmList.sort((a,b) => b.time_created - a.time_created).map((item, index) => (
              <span className={(farmToSearchFor.length >= 1 && item.farmname.substring(0,farmToSearchFor.length).indexOf(farmToSearchFor) == -1 ) && "hidden" }>
              <a href={`${currentWebsiteURL}/farm/${item.farmname}`}>
              <SingleFarmCont>
              {item.logo != null && (
                <FarmLogo src={`https://ipfs.io/ipfs/${item.logo}`} />
              )}
              {item.farmname != null && (
                <FarmTitle>
                {item.farmname}
                </FarmTitle>
              )}
              {item.poolsize != null && (
                <PoolAmount>
                  <NumberFormat displayType='text' thousandSeparator={true} value={Math.round(item.poolsize.substring(0, item.poolsize.indexOf(' ')))} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />
                 {item.poolsize.substring(item.poolsize.indexOf(' '))}
                </PoolAmount>
              )}
              {item.enddate != null && (
                <FarmEndDate>
                  Ends {new Date(item.enddate * 1000).toLocaleDateString()}
                </FarmEndDate>
              )}

             </SingleFarmCont>
             </a>
             </span>
              ))}

       

       
       
      
      </AllFarmsCont>
      </NewUIFarmsPageCont>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default UserFarms