import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import { Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, WalletSearchBarCont, WalletSearchBar, CloseWalletSearchBar, WalletSearchBarCollectionList, NoDaosMessage, NewRow, DaoButton, DisabledButton, WalletSearchBarSingleCollectionButton } from '../components/LeftDiv';

import ReactMarkdown from 'react-markdown';
import { myChain, wax, anchor, currentWebsiteURL, wombat, currentUsername } from '../data/config';
import LoadingPage from '../components/LoadingPage';
import Error from '../components/Error';
import { ExploreFiltersCont, ExploreFiltersInnerCont, FarmDescriptionContainer, FoldersContainer, FoldersRow, JobOfferTitle, MainButton, MilestoneDescription, NewListingBody, SingleFolderTab, SmallButton, TallFiltersCont, TallFiltersInnerCont } from '../Styles';
import FarmCard from '../components/FarmCard';
import { RecentFarmsContainer } from '../data/css/FarmsListCSS';
import { CollectionProfileAvatarCont, CollectionProfileBGContainer, CollectionProfileContainer, FarmsAndDropsCont } from '../data/css/CollectionPageStyles';
import collection_profile_stock_bg from '../data/collection_profile_stock_bg.png'
import { resizerPrefix } from '../data/constants';
import { useGetFarmsByCreator } from '../components/CustomHooks/useGetFarmsByCreator';
import { useGetBlendsByUser } from '../components/CustomHooks/useGetBlendsByUser';
import BlendCard from '../components/BlendCard';
import { WalletNFTCardsCont } from '../data/css/WalletStyles';
import WalletCard from '../components/WalletComponents/WalletCard';
import { getFirstStakedPage, getFirstUnstakedPage, getNextStakedPage, getNextUnstakedPage, handleUserProfileInputChange, submitUserProfile, switchCollection } from '../data/functions/user_page_functions';
import { useGetMyCollections } from '../components/CustomHooks/useGetMyCollections';
import { useGetUserProfile } from '../components/CustomHooks/useGetUserProfile';
import { getFarmSocials } from '../data/functions/farm_functions';
import { useGetV2DaosByCreator } from '../components/CustomHooks/useGetV2DaosByCreator';
import V2DAOCard from '../components/V2DAOCard';
import { useGetPoolsByCreator } from '../components/CustomHooks/useGetPoolsByCreator';
import PoolCard from '../components/PoolCard';
import { useGetDropsByCreator } from '../components/CustomHooks/useGetDropsByCreator';
import DropCard from '../components/DropCard';
import LoadingDiv from '../components/LoadingDiv';




const UserPage = ({ location }) => {

  const { enterModalText,
    setEnterModalText,
    loadingDisplay, 
    setLoadingDisplay, 
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay, 
    setEnterModalDisplay,

   } = useStateContext();

const { UserName } = useParams();
const [userInfo, setUserInfo] = useState([]);
const [showBlends, setShowBlends] = useState(false);
const [showFarms, setShowFarms] = useState(false);
const [showDrops, setShowDrops] = useState(false);
const [showUnpack, setShowUnpack] = useState(false);
const [showDaos, setShowDaos] = useState(false);
const [showPools, setShowPools] = useState(false);
const [loadMoreDisplay, setLoadMoreDisplay] = useState("");
const [detailedList, setDetailedList] = useState([]);
const [stakedAssets, setStakedAssets] = useState([]);
const [listOfAssets, setListOfAssets] = useState([]);
const [totalAssets, setTotalAssets] = useState(0);
const [pageSize, setPageSize] = useState(100);
const [pageCount, setPageCount] = useState(1);
const [currentSection, setCurrentSection] = useState("Wallet");
const [firstStakedPageWasLoaded, setFirstStakedPageWasLoaded] = useState(false);
const [firstUnstakedPageWasLoaded, setFirstUnstakedPageWasLoaded] = useState(false);
const [unstakedLoadMoreDisplay, setUnstakedLoadMoreDisplay] = useState("");
const [unstaked, setUnstaked] = useState([]);
const [unstakedPageCount, setUnstakedPageCount] = useState(1);
const [newCollection, setNewCollection] = useState("");
const [enteredText, setEnteredText] = useState("");
const [showSearchCollections, setShowSearchCollections] = useState(false);

const [editProfile, setEditProfile] = useState(false);

//Custom Hooks
const [userProfile, getUserProfile, userProfileIsLoading, setUserProfile] = useGetUserProfile();
const [daosByCreator, getDAOsByCreator, creatorDAOsAreLoading] = useGetV2DaosByCreator();
const [poolsByCreator, getPoolsByCreator, creatorPoolsAreLoading] = useGetPoolsByCreator();
const [myDrops, myPacks, getDropsByCreator, dropsAreLoading] = useGetDropsByCreator();
const [farmsByCreator, getFarmsByCreator, creatorFarmsAreLoading] = useGetFarmsByCreator();
const [blendsByUser, getBlendsByUser, userBlendsAreLoading] = useGetBlendsByUser();
const [myCollections, getMyCollections, collectionsWereLoaded] = useGetMyCollections();

useEffect(() => {
  getUserProfile(UserName);
}, []);


useEffect(() => {
  if(currentSection == "Staked" && !firstStakedPageWasLoaded){
    getFirstStakedPage(UserName, setDetailedList, setStakedAssets, setListOfAssets, setTotalAssets, setPageCount, setLoadMoreDisplay, setFirstStakedPageWasLoaded)  
  }
}, [currentSection])

useEffect(() => {
  if(currentSection == "Wallet" && !firstUnstakedPageWasLoaded){
    getFirstUnstakedPage(UserName, setUnstaked, setUnstakedPageCount, setUnstakedLoadMoreDisplay, setFirstUnstakedPageWasLoaded)  
  }
}, [currentSection, blendsByUser])

useEffect(() => {
  if(currentSection == "Wallet" && !collectionsWereLoaded){
    getMyCollections(UserName);
  }
}, [currentSection])

if(!userProfileIsLoading){
  return (
    <div id="seo">
    <Helmet>
    <title>{`${UserName}'s Profile`}</title>
    <meta name="description" content={`View ${UserName}'s Wax wallet on WaxDAO`} />
    <link rel="canonical" href={`https://waxdao.io/u/${UserName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>


 
      <CollectionProfileContainer>
        <CollectionProfileBGContainer>
          {userProfile?.profile?.cover_image != null && userProfile?.profile.cover_image.length > 5 ? (
            <img src={`${resizerPrefix}${userProfile?.profile.cover_image}`} style={{objectFit:"cover", width: "100%", height: "100%"}} />
          ) : (
            <img src={collection_profile_stock_bg} style={{width: "auto", height: "auto", minHeight: "100%", minWidth: "100%"}} />
          )}
          
        </CollectionProfileBGContainer>

        <CollectionProfileAvatarCont>
        {userProfile?.profile?.avatar != null && userProfile?.profile.avatar.length > 5 ? (
          <img src={`${resizerPrefix}${userProfile?.profile.avatar}`} style={{width: "auto", height: "auto", minHeight: "100%", minWidth: "100%"}} />
        ) : (
          <img src={`https://avatars.dicebear.com/api/bottts/${UserName}@waxdao.png`} style={{width: "auto", height: "auto", minHeight: "100%", minWidth: "100%"}} />
        )}
          
        </CollectionProfileAvatarCont>
      </CollectionProfileContainer>

        <div className='p-5 mt-4 text-white'>
          <br/>
          <span className='text-2xl font-semibold'>
            {UserName} {currentUsername == UserName && (
              <span>
                <SmallButton
                  onClick={() => {
                    setEditProfile((prev) => !prev)
                  }}
                >{!editProfile ? "Edit Profile" : "Hide"}</SmallButton>
                <span className={!editProfile && "hidden"}>
                  <br/><br/>
                  <TallFiltersInnerCont>
                  <h3>Bio</h3>
                  <textarea name="description" value={userProfile.profile.description} onChange={(e) => handleUserProfileInputChange(e, userProfile, setUserProfile)} placeholder='Add a bio' />
                  <br/><br/>

                  <h3>Cover Image IPFS</h3>
                  <input name="cover_image" value={userProfile.profile.cover_image} onChange={(e) => handleUserProfileInputChange(e, userProfile, setUserProfile)} placeholder='e.g. QMabc123...' />
                  <br/><br/>

                  <h3>Avatar Image IPFS</h3>
                  <input name="avatar" value={userProfile.profile.avatar}  onChange={(e) => handleUserProfileInputChange(e, userProfile, setUserProfile)} placeholder='e.g. QMabc123...' />
                  <br/><br/>                  

                  <h3>Website</h3>
                  <input name="website" value={userProfile.socials.website}  onChange={(e) => handleUserProfileInputChange(e, userProfile, setUserProfile)} placeholder='e.g. waxdao.io' />
                  <br/><br/> 

                  <h3>Telegram</h3>
                  <input name="telegram" value={userProfile.socials.telegram}  onChange={(e) => handleUserProfileInputChange(e, userProfile, setUserProfile)} placeholder='e.g. t.me/hoodpunks' />
                  <br/><br/> 

                  <h3>Discord</h3>
                  <input name="discord" value={userProfile.socials.discord}  onChange={(e) => handleUserProfileInputChange(e, userProfile, setUserProfile)} placeholder='e.g. discord.gg/myserver' />
                  <br/><br/>   

                  <h3>Twitter</h3>
                  <input name="twitter" value={userProfile.socials.twitter}  onChange={(e) => handleUserProfileInputChange(e, userProfile, setUserProfile)} placeholder='e.g. twitter.com/waxdao_io' />
                  <br/><br/>   

                  <h3>Medium</h3>
                  <input name="medium" value={userProfile.socials.medium}  onChange={(e) => handleUserProfileInputChange(e, userProfile, setUserProfile)} placeholder='e.g. medium.com/hi' />
                  <br/><br/>  

                  <h3>YouTube</h3>
                  <input name="youtube" value={userProfile.socials.youtube}  onChange={(e) => handleUserProfileInputChange(e, userProfile, setUserProfile)} placeholder='e.g. youtube.com/mychannel' />
                  <br/><br/>                                                                                            


                  <MainButton
                    onClick={() => {
                      submitUserProfile(  userProfile,
                        setEnterModalDisplay,
                        setEnterModalText,
                        setLoadingDisplay);
                    }}
                  >
                    Save Changes
                  </MainButton>
                  </TallFiltersInnerCont>
                </span>
              </span>
            )}

          </span>
          <br/>
          <span className='text-md'>
            {userInfo[0]?.author != null && (<span>By {userInfo[0].author}<br/><br/></span>)}
          </span>
          
          <span className='text-md'>
            {userInfo[0]?.data?.description != null && (<ReactMarkdown>{userInfo[0].data.description}</ReactMarkdown>)}
          </span>

          {userProfile.profile?.description != null &&
             userProfile.profile?.description?.length > 3 && (
                <FarmDescriptionContainer>
                  <MilestoneDescription>
                    {userProfile.profile.description}
                  </MilestoneDescription>
                </FarmDescriptionContainer>
              )}          

          {getFarmSocials(userProfile.socials)}

        </div>

 


<FarmsAndDropsCont>
  <NewListingBody>






    <JobOfferTitle>NFT Farms
    <button onClick={() => {
        if(showFarms == false && (farmsByCreator == null || farmsByCreator.length == 0) ){ 
          getFarmsByCreator(UserName);
        }
        setShowFarms((prev) => !prev)
      }  
      }>
        {showFarms ? "x" : "+"}
      </button>
    </JobOfferTitle>
    <RecentFarmsContainer closed={!showFarms && true}>
      {creatorFarmsAreLoading && <LoadingDiv />}

      {!creatorFarmsAreLoading && farmsByCreator != null && farmsByCreator.length > 0 && farmsByCreator.map((item, index) => (
        <a href={`${currentWebsiteURL}/farm/${item.farmname}`}>
          <FarmCard item={item} />
        </a>
      ))}

    {!creatorFarmsAreLoading && (farmsByCreator == null || farmsByCreator.length == 0) && (
      <span className='pl-4'>
      No farms to show
      </span>
    )}
    </RecentFarmsContainer>
    <br/>

    <JobOfferTitle>Token Pools
    <button onClick={() => {
        if(showPools == false && (poolsByCreator == null || poolsByCreator.length == 0) ){ 
          getPoolsByCreator(UserName);
        }
        setShowPools((prev) => !prev)
      }  
      }>
        {showPools ? "x" : "+"}
      </button>
    </JobOfferTitle>
    <RecentFarmsContainer closed={!showPools && true}>
      {creatorPoolsAreLoading && <LoadingDiv />}

      {!creatorPoolsAreLoading && poolsByCreator != null && poolsByCreator.length > 0 && poolsByCreator.map((item, index) => (
        <a href={`${currentWebsiteURL}/pool/${item.poolname}`}>
          <PoolCard item={item} sort="" />
        </a>
      ))}

      {!creatorPoolsAreLoading && (poolsByCreator == null || poolsByCreator.length == 0) && (
      <span className='pl-4'>
      No pools to show
      </span>
      )}
    </RecentFarmsContainer>


    <br/>


    <JobOfferTitle>DAOs
      <button onClick={() => {
        if(showDaos == false && (daosByCreator == null || daosByCreator.length == 0) ){ 
          getDAOsByCreator(UserName);
        }
        setShowDaos((prev) => !prev)
      }  
      }>
        {showDaos ? "x" : "+"}
      </button>
    </JobOfferTitle>
    <RecentFarmsContainer closed={!showDaos && true}>
      {creatorDAOsAreLoading && <LoadingDiv />}

      {!creatorDAOsAreLoading && daosByCreator != null && daosByCreator.length > 0 && daosByCreator.map((item, index) => (
        <a href={`${currentWebsiteURL}/v2/daos/${item.daoname}`}>
          <V2DAOCard item={item} />
        </a>
      ))}

      {!creatorDAOsAreLoading && (daosByCreator == null || daosByCreator.length == 0) && (
      <span className='pl-4'>
      No DAOs to show
      </span>
      )}

    </RecentFarmsContainer>


    <br/>
    <JobOfferTitle>Blends
    <button onClick={() => {
        if(showBlends == false && (blendsByUser == null || blendsByUser.length == 0) ){
          getBlendsByUser(UserName);
        }
        setShowBlends((prev) => !prev)
      }  
      }>
        {showBlends ? "x" : "+"}
      </button>
    </JobOfferTitle>
    <RecentFarmsContainer closed={!showBlends && true}>
      {userBlendsAreLoading && <LoadingDiv />}

      {!userBlendsAreLoading && blendsByUser != null && blendsByUser.length > 0 && blendsByUser.map((item, index) => (
        <a href={`${currentWebsiteURL}/blends/${item.ID}`}>
          <BlendCard item={item} />
        </a>
      ))}

    {!userBlendsAreLoading && (blendsByUser == null || blendsByUser.length == 0) && (
      <span className='pl-4'>
      No blends to show
      </span>      
    )}
    </RecentFarmsContainer>

    <br/>
    <JobOfferTitle>Drops
    <button onClick={() => {
        if(showDrops == false && ( (myDrops == null || myDrops.length == 0) && (myPacks == null || myPacks.length == 0) ) ){
          getDropsByCreator(UserName);
        }
        setShowDrops((prev) => !prev)
      }  
      }>
        {showDrops ? "x" : "+"}
      </button>
    </JobOfferTitle>
    <RecentFarmsContainer closed={!showDrops && true}>
      {dropsAreLoading && <LoadingDiv />}

      {!dropsAreLoading && myDrops != null && myDrops.length > 0 && myDrops.map((item, index) => (
        <a href={`${currentWebsiteURL}/drops/${item.ID}`}>
          <DropCard item={item} />
        </a>
      ))}

{!dropsAreLoading && (myDrops == null || myDrops.length == 0) && (
      <span className='pl-4'>
      No drops to show
      </span>
)}

    </RecentFarmsContainer>

    
    <br/>
    <JobOfferTitle>Unbox Links
    <button onClick={() => {
        if(showUnpack == false && ( (myDrops == null || myDrops.length == 0) && (myPacks == null || myPacks.length == 0) ) ){
          getDropsByCreator(UserName);
        }
        setShowUnpack((prev) => !prev)
      }  
      }>
        {showUnpack ? "x" : "+"}
      </button>
    </JobOfferTitle>
    <RecentFarmsContainer closed={!showUnpack && true}>
      {dropsAreLoading && <LoadingDiv />}

      {!dropsAreLoading && myPacks != null && myPacks.length > 0 && myPacks.map((item, index) => (
        <a href={`${currentWebsiteURL}/unbox/${item.ID}`}>
          <DropCard item={item} />
        </a>
      ))}

      {!dropsAreLoading && (myPacks == null || myPacks.length == 0) && (
      <span className='pl-4'>
      No unbox links to show
      </span>        
      )}
    </RecentFarmsContainer>    

    <ExploreFiltersCont>
      <h2>NFTs</h2>

      <br />
      <FoldersContainer>
        <FoldersRow>
          <SingleFolderTab
            onClick={(e) => {
              setCurrentSection("Wallet");
            }}
            selected={currentSection == "Wallet" && true}
          >
            Wallet
          </SingleFolderTab>
          <SingleFolderTab
            onClick={(e) => {
              setCurrentSection("Staked");
            }}
            selected={currentSection == "Staked" && true}
          >
            Staked
          </SingleFolderTab>

        </FoldersRow>
      </FoldersContainer>
      <br />
      <ExploreFiltersInnerCont className={currentSection != "Wallet" && "hidden"}>
        <WalletSearchBarCont
          className={
            currentSection != "Wallet"
              ? "hidden"
              : "flex"
          }
        >
          <WalletSearchBar
            placeholder={`All Collections ${
              myCollections?.assets == null
                ? ""
                : "(" + myCollections.assets + ")"
            }`}
            maxLength={12}
            onChange={(e) => {
              setEnteredText(e.target.value.toLowerCase());
            }}
            onFocus={() => {
              setShowSearchCollections(true);
            }}
          />
          <CloseWalletSearchBar
            onClick={() => {
              setShowSearchCollections(false);
            }}
            className={
              !showSearchCollections ? "hidden" : "inline-block"
            }
          >
            X
          </CloseWalletSearchBar>
          <br />
          <WalletSearchBarCollectionList
            className={showSearchCollections == false && "hidden"}
          >
            {myCollections?.collections?.length > 0
              ? myCollections.collections.map((item, index) => (
                  <span
                    key={index}
                    className={
                      enteredText.length >= 1 &&
                      item.collection?.collection_name
                        .substring(0, enteredText.length)
                        .indexOf(enteredText) == -1 &&
                      "hidden"
                    }
                  >
                    {item.collection?.collection_name != null && (
                      <WalletSearchBarSingleCollectionButton
                        key={index}
                        onClick={() => {
                          switchCollection(
                            UserName,
                            setUnstakedPageCount,
                            setUnstaked,
                            setUnstakedLoadMoreDisplay,
                            setNewCollection,
                            item.collection.collection_name,
                            setShowSearchCollections
                          );
                        }}
                      >
                        {item.collection?.collection_name} (
                        {item.assets})
                      </WalletSearchBarSingleCollectionButton>
                    )}
                  </span>
                ))
              : "Nothing to show"}
          </WalletSearchBarCollectionList>
        </WalletSearchBarCont>
      </ExploreFiltersInnerCont>
    </ExploreFiltersCont>

    <span
                className={
                  (currentSection != "Staked") &&
                  "hidden"
                }
              >
                <span className={!firstStakedPageWasLoaded && "hidden"}>
                <WalletNFTCardsCont>
                  {stakedAssets.length > 0 ? (
                    stakedAssets.map((item, index) => (
                      <WalletCard
                        key={index}
                        item={item}
                        farmList={[]}
                        unstaked={false}
                        stakeableOnly={false}
                      />

                    ))
                  ) : (
                    <NoDaosMessage>
                      This user has no NFTs staked on WaxDAO
                    </NoDaosMessage>
                  )}
                </WalletNFTCardsCont>
                <br />
                <NewRow />
                <div className='w-100 text-center'>
                <DaoButton
                  className={loadMoreDisplay}
                  onClick={() => {
                    getNextStakedPage(
                      pageCount,
                      setPageCount,
                      stakedAssets,
                      setStakedAssets,
                      totalAssets,
                      listOfAssets,
                      detailedList,
                      pageSize,
                      setLoadMoreDisplay
                    );
                  }}
                >
                  Load More
                </DaoButton>

                <DisabledButton
                  className={loadMoreDisplay != "hidden" && "hidden"}
                >
                  No More Results
                </DisabledButton>
                </div>
                </span>

                  <span className={firstStakedPageWasLoaded && "hidden"}>
                    <NoDaosMessage>
                      Loading...
                    </NoDaosMessage>
                  </span>


              </span>


              <span
                className={
                  (currentSection != "Wallet") &&
                  "hidden"
                }
              >
                <span className={!firstUnstakedPageWasLoaded && "hidden"}>
                <WalletNFTCardsCont>
                  {unstaked.length > 0 ? (
                    unstaked.map((item, index) => (
                      <WalletCard
                        key={index}
                        item={item}
                        farmList={[]}
                        unstaked={true}
                        stakeableOnly={false}
                      />

                    ))
                  ) : (
                    <NoDaosMessage>
                      No NFTs to show
                    </NoDaosMessage>
                  )}
                </WalletNFTCardsCont>
                <br />
                <NewRow />
                <div className='w-100 text-center'>
                <DaoButton
                  className={unstakedLoadMoreDisplay}
                  onClick={() => {
                    getNextUnstakedPage(UserName, unstakedPageCount, setUnstakedPageCount, unstaked, setUnstaked, setUnstakedLoadMoreDisplay, newCollection)
                  }}
                >
                  Load More
                </DaoButton>
                
                <DisabledButton
                  className={unstakedLoadMoreDisplay != "hidden" && "hidden"}
                >
                  No More Results
                </DisabledButton>
                </div>

                </span>

                  <span className={firstUnstakedPageWasLoaded && "hidden"}>
                    Loading...
                  </span>


              </span>


  </NewListingBody>
</FarmsAndDropsCont>

    </UALProvider>
    <br/><br/><br/>
    </div>
  )}
  else{
    return <LoadingPage />
  }
}

export default UserPage