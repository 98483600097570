import React, { useState } from 'react'
import { ipfsPrefix, resizerPrefix, resizerSuffix } from '../data/constants';
import { DropCardBottomHalf, DropCardEndDate, DropCardImage, DropCardName, DropCardTitle, DropCardTopHalf, DropCardWrapper } from '../data/css/DropStyles'
import { NFTCardCheckbox, ShowNFTInfo } from '../data/css/PremiintStyles';
import { WalletCardMint, WalletCardVideo } from '../data/css/WalletStyles';
import { ModalOverlay } from './LeftDiv';
import { ImInfo } from 'react-icons/im'
import AttributeModal from './AttributeModal';
import { currentAtomicHubLink, currentNetworkNameForAtomicHub, currentWebsiteURL } from '../data/config';
import { isInUse, handleAddAsset, handleRemoveAsset } from '../pages/farmsv2/v2_farm_functions';

const V2FarmNftCard = (props) => {
    const item = props.item;
    const assetVector = props.assetVector;
    const setAssetVector = props.setAssetVector;
    const stakedAssets= props.stakedAssets;

    const listing_index = props.listing_index;

    const inUse = isInUse(item.asset_id, listing_index, assetVector, [], "asset", stakedAssets);

    const isAssetInVector = assetVector.some(asset => asset.asset_id === item.asset_id && asset.listing_index === listing_index);
    const handleAssetToggle = () => {
        if (isAssetInVector) {
            handleRemoveAsset({ asset_id: item.asset_id, listing_index }, assetVector, setAssetVector)

        } else {
            handleAddAsset({ asset_id: item.asset_id, listing_index }, assetVector, setAssetVector)

        }
    };

    const [showAttributeModal, setShowAttributeModal] = useState(false);

  return (
    <DropCardWrapper>
        <DropCardTopHalf>
            {item.data.img != null && (
                <DropCardImage src={`${resizerPrefix}${item.data.img}${resizerSuffix}`} />
            )}
            {item.data != null && item.data.img == null && item.data.video != null && (
                <WalletCardVideo controls loop>
                    <source 
                    src={`${ipfsPrefix}${item.data.video.trim()}${resizerSuffix}`} 
                    type="video/mp4"/>
                </WalletCardVideo>
            )}
        </DropCardTopHalf>
            

        <NFTCardCheckbox selected={isAssetInVector ? "yes" : "no"} onClick={!inUse && handleAssetToggle}>
                {isAssetInVector && (<span>&#x2713;</span>)}
            </NFTCardCheckbox>



        <ShowNFTInfo onClick={() => setShowAttributeModal(true)}>
            <ImInfo className='ml-auto mr-auto' />
        </ShowNFTInfo>

        {showAttributeModal && (
            <span>
                <ModalOverlay />
                <AttributeModal showAttributeModal={showAttributeModal} setShowAttributeModal={setShowAttributeModal} item={item} />
            </span>
        )}

{item.template_mint != null && (
            <WalletCardMint>
            {inUse ? "In Use" : `#${item.template_mint}` }
            </WalletCardMint>
        )}

      {item.collection != null && (
          <a 
            href={`${currentAtomicHubLink}/explorer/collection/${currentNetworkNameForAtomicHub}/${item.collection?.collection_name}`}
            target="none"
          >
        <DropCardTitle>
        {item.collection.collection_name}
        </DropCardTitle>
        </a>
      )}

        {item.data.name != null && (
            <DropCardName>
                {item.data.name}
            </DropCardName>
        )}

        <DropCardBottomHalf />



    </DropCardWrapper>
  )
}

export default V2FarmNftCard