import React from 'react';
import {Helmet} from "react-helmet";
import { LeftDiv, MainContainer, RightDiv, ArticleCont, ArticleH1, ArticleP, ArticleH2 } from '../components/LeftDiv';
import { currentWebsiteURL } from '../data/config';

const TokenGen = () => {
  return (
    <div id="seo">
    <Helmet>
    <title>Create Your Own Wax Token - WaxDAO</title>
    <meta name="description" content="Get your own custom Wax token in less than 5 minutes, using this simple Wax Token Creator." />
    <link rel="canonical" href="https://waxdao.io" />
    </Helmet>
    <MainContainer>
    <ArticleCont>
      <ArticleH1>
        How To Create A Token On WaxDAO
      </ArticleH1>

      <ArticleP>
        Welcome. WaxDAO provides you with a very cheap tool to create your own WAX token. No coding required. 
      </ArticleP>

      <ArticleP>
        The process is simple, but also a bit confusing if you're new here. 
      </ArticleP>

      <ArticleP>
        I'll make this as short and sweet as possible, but 
        please read these instructions before you try to create your token.
      </ArticleP>

      <ArticleH2>
        Steps Involved
      </ArticleH2>

      <ArticleP>
        <ul>
          <li>Step 1: Get Whitelisted</li>
          <li>Step 2: Get Resources</li>
          <li>Step 3: Deploy Contract</li>
          <li>Step 4: Create Tokens</li>
          <li>Step 4.1: Add A Token Logo</li>
          <li>Step 5: Issue Tokens</li>
        </ul>
      </ArticleP>

      <ArticleH2>
        Step 1: Get Whitelisted
      </ArticleH2>

      <ArticleP>
        Before you can use our tool, you need to get whitelisted (in other words, pay).
        This is fast and simple. We offer 3 payment options:<br/><br/>
        <ul>
          <li>250 WAXP</li>
          <li>25,000 <a href="https://wax.alcor.exchange/swap?input=WAX-eosio.token&output=WOJAK-mdcryptonfts" target="none">WOJAK</a></li>
          <li>Burn 1 Wojak NFT</li>
        </ul>
      </ArticleP>

      <ArticleP>
        All 3 of these can (and should) be done directly on the <a href={`${currentWebsiteURL}/whitelisting`}>Whitelisting Page</a>.
      </ArticleP>

      <ArticleP>
        Burning a Wojak is usually the cheapest option, but check the market to make sure. You can <a href={`${currentWebsiteURL}/drops/247`}>buy a Wojak NFT</a> directly on WaxDAO.
      </ArticleP>

      <ArticleH2>
        Step 2: Get Resources
      </ArticleH2>

      <ArticleP>
        After paying the whitelisting fee to WaxDAO, you also need some resources to cover transaction 
        fees on WAX. If you don't know how resources work, check out this article on <a href="https://mikedcrypto.com/videos/wax-blockchain-tutorial-what-are-cpu-net-and-ram-on-wax" target="none">
          how resources work on WAX</a>.
      </ArticleP>

      <ArticleP>
        Here's how much you need:<br/><br/>
        <ul>
          <li>500,000 bytes of RAM (About ~350 WAX worth)</li>
          <li>~50 WAX worth of NET</li>
          <li>~5ms of CPU</li>
        </ul>
      </ArticleP>

      <ArticleP>
        The cost of these resources fluctuates based on demand, that's why the amounts above are 
        just estimates. You can get the current <a href="https://wax.bloks.io/wallet/ram/buy" target="none">RAM, CPU, and NET prices on Bloks</a>.
      </ArticleP>

      <ArticleH2>
        Step 3: Deploy Contract
      </ArticleH2>

      <ArticleP>
        NOTE: THIS STEP MUST BE DONE ON A PC, NOT A MOBILE DEVICE. Anchor Wallet does not seem to get along well with mobile devices 
        when you are trying to deploy a contract.
      </ArticleP>

      <ArticleP>
        Once you are whitelisted AND have CPU, NET and RAM, you can deploy the contract.
      </ArticleP>

      <ArticleP>
        You need to do this BEFORE creating a token. You can't create a token without first 
        having the contract deployed to your account.
      </ArticleP>

      <ArticleP>
        How do I deploy the token contract?
         <br/><br/>
        Go to the <a href={`${currentWebsiteURL}/deploy-contract`}>deploy contract page</a>.<br/><br/>
        MAKE SURE YOU HAVE DANGEROUS TRANSACTIONS ENABLED IN ANCHOR WALLET.<br/><br/>
        Click the "deploy contract" button. Don't see the button? You probably forgot step 1, 
        get whitelisted (pay the fee).
      </ArticleP>

      <ArticleP>
        How do I know if it worked? How do I know if the contract was actually deployed?
         <br/><br/>
         Easy. View your account on wax.bloks.io. Do you see a "Contract" tab? Then there's a 
         contract on your account. Don't see a contract tab? There's no contract.
      </ArticleP>

      <ArticleH2>
        Step 4: Create Token
      </ArticleH2>

      <ArticleP>
        First part of the "Create Token" process:<br/><br/>
        Go back and make sure you completed steps 1, 2 and 3. If you didn't, you will just see 
        a spinner endlessly spinning when you try to create a token.
      </ArticleP>

      <ArticleP>
        Not whitelisted? Enjoy watching the spinner. Don't have resources on your account?
          Transaction will fail.
      </ArticleP>

      <ArticleP>
        Now that you got that out of the way, it's pretty straight forward. Just enter the details 
        for your token on the <a href={`${currentWebsiteURL}/create-token`}>create token page</a>.
      </ArticleP>

      <ArticleP>
        I highly recommend using 8 decimal places for your token. The token name can not be more than
         7 characters.
      </ArticleP>

      <ArticleH2>
        Optional: Adding A Token Logo
      </ArticleH2>

      <ArticleP>
        Bro Tip: You can't add a logo. It's not a real thing. Logo's are "Web 2" images. Yes, I'm sure. Positive.
        <br/><br/>
        You CAN add your 
        token logo to <a href="https://wax.alcor.exchange/" target="none">Alcor Exchange </a>AFTER you list your token on Alcor. To add the logo, you will need to create 
        a pull request on the <a href="https://github.com/avral/alcor-ui" target="none">Alcor Github repo</a>.<br/><br/>
        More detailed instructions can be found in the <a href="https://docs.alcor.exchange/developers-api/market-creation/token-logo" target="none">
          Alcor Documentation
        </a>.
      </ArticleP>

      <ArticleH2>
        Step 5: Issue Tokens
      </ArticleH2>

      <ArticleP>
        "Creating a token" does not put tokens into circulation. You created a token? Good job, but you don't actually HAVE 
        any tokens yet. All you did was create a name and a supply.
      </ArticleP>

      <ArticleP>
        Now you need to ISSUE tokens if you want to actually have a token balance that you can use.
         To issue tokens, go to the <a href={`${currentWebsiteURL}/create-token`}>issue tokens page</a>, 
         it's pretty straightforward.
      </ArticleP>

      <ArticleP>
        You can only issue to your own account (the one that created the token). You can not issue to 
        any other wallet. Issue first, then transfer to someone else.
      </ArticleP>

      <ArticleH2>
        Step 6: Join Our Telegram, Tell Your Friends
      </ArticleH2>

      <ArticleP>
        Enjoyed our service? Great. Our prices are insanely cheap, we don't make a ton off of you. So 
        we would really appreciate it if you told your friends about WaxDAO.
      </ArticleP>

      <ArticleP>
        The more users we get, the more we can earn- which helps us develop more useful products for you 
        to get creative with.
      </ArticleP>

      <ArticleP>
        Thanks for your time. If you have any questions, there's a link to our Telegram at the top of the page.
      </ArticleP>

    </ArticleCont>
    </MainContainer>
    </div>
  )
}

export default TokenGen