import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { MainContainer, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton
    } from '../../components/LeftDiv';
import { myChain, anchor, wax, getCurrentApiList, wombat, v2DAOContract, currentUsername } from '../../data/config';
import { resizerPrefix, resizerSuffix } from '../../data/constants';
import { CollectionProfileAvatarCont, CollectionProfileBGContainer, CollectionProfileContainer } from '../../data/css/CollectionPageStyles';
import collection_profile_stock_bg from "../../data/collection_profile_stock_bg.png";
import { FarmDescriptionContainer, FoldersContainer, FoldersRow, MilestoneDescription, NewListingBody, SingleFolderFourth, TallFiltersCont, TallFiltersInnerCont } from '../../Styles';
import LoadingPage from '../../components/LoadingPage';
import { useGetSingleDAOProfile } from '../../components/CustomHooks/useGetSingleDAOProfile';
import { getFarmSocials } from '../../data/functions/farm_functions';
import { daoHasStaking, showNftDepositSection, showStakeTokensSection, showTokenDepositSection, showV2DaoInfo, showV2Proposals } from './dao_v2_functions';
import { showDAOTreasury, showV2ProposalForm } from './proposal_v2_functions';
import { isLoggedIn } from '../../data/functions/wallet_functions';
import { useGetDAOProofAssets } from '../../components/CustomHooks/useGetDAOProofAssets';
import { useGetV2DAOTokenTreasury } from '../../components/CustomHooks/useGetV2DAOTokenTreasury';
import { useGetV2DAONftTreasury } from '../../components/CustomHooks/useGetV2DAONftTreasury';
import { useGetV2DAOProposals } from '../../components/CustomHooks/useGetV2DAOProposals';
import { useGetTokenBalances } from '../../components/CustomHooks/useGetTokenBalances';
import { useGetNftsByUser } from '../../components/CustomHooks/useGetNftsByUser';
import { useGetMyCollections } from '../../components/CustomHooks/useGetMyCollections';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

const DAOPageV2 = ({ location }) => {

  const { 
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    enterButtonsDisplay,
    setEnterButtonsDisplay,

    } = useStateContext();

const { DAOName } = useParams();

const [daoData, setDaoData] = useState([]);
const [currentSection, setCurrentSection] = useState("Settings");
const [currentAction, setCurrentAction] = useState("NFT Deposit");
const [daoDataWasLoaded, setDaoDataWasLoaded] = useState(false);

//new proposal details
const [title, setTitle] = useState("");
const [description, setDescription] = useState("");
const [proposalType, setProposalType] = useState(null);
const [choicesList, setChoicesList] = useState([{choice: ''}]);
const [tokenReceivers, setTokenReceivers] = useState([{wax_account: '', token_name: '', token_precision: '', contract: '', quantity: ''}]);
const [nftReceivers, setNftReceivers] = useState([{wax_account: '', asset_ids: ''}]);

//Treasury
const [currentTreasurySection, setCurrentTreasurySection] = useState("Tokens")
const [selectedTokenObject, setSelectedTokenObject] = useState({});
const [tokenAmountToDeposit, setTokenAmountToDeposit] = useState("");
const [selectedNfts, setSelectedNfts] = useState([]);
const [selectedCollection, setSelectedCollection] = useState("");
const [userHasConfirmed, setUserHasConfirmed] = useState(false);

//Staking
const [amountToStake, setAmountToStake] = useState("");

//custom hooks
const [proofAssets, getProofAssets] = useGetDAOProofAssets();
const [daoProfile, getDaoProfile, profileIsLoading] = useGetSingleDAOProfile();
const [daoTokens, getDaoTokenTreasury, tokensAreLoading] = useGetV2DAOTokenTreasury();
const [daoNfts, getDaoNftTreasury, daoNftsAreLoading, hasNextDaoNftPage] = useGetV2DAONftTreasury();
const [daoProposals, getDaoProposals, proposalsAreLoading] = useGetV2DAOProposals();
const [tokenBalances, getTokenBalances, balancesAreLoading] = useGetTokenBalances();
const [nfts, getNfts, nftsAreLoading, hasNextPage] = useGetNftsByUser();
const [myCollections, getMyCollections, collectionsWereLoaded] = useGetMyCollections();

useEffect(() => {

  axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
    table:"daos",
    scope:v2DAOContract,
    code:v2DAOContract,
    limit:1,
    lower_bound:DAOName,
    upper_bound:DAOName,
    json:true
  })
    .then((response) => {
      console.log(response.data.rows[0])
      setDaoData(response.data.rows[0]);
      setDaoDataWasLoaded(true);
    })
    .catch((error) => console.log(error));

}, []);

useEffect(() => {
  if(daoDataWasLoaded){
    getDaoProfile(DAOName);
  }
}, [daoDataWasLoaded]);

useEffect(() => {
  if(daoDataWasLoaded){
    if(daoData?.proposer_type == 2 && daoData.dao_type == 5){
      if(isLoggedIn()){
        getProofAssets(currentUsername, daoData.minimum_weight, daoData.gov_schemas);
      } 
    } 
  }
}, [daoDataWasLoaded]);



if(daoDataWasLoaded && !profileIsLoading){
  return (

    <div id="seo">
    <Helmet>
    <title>{DAOName} DAO - WaxDAO</title>
    <meta name="description" content={`Votes, propsals and details for ${DAOName} DAO on WaxDao`} />
    <link rel="canonical" href={`https://waxdao.io/v2/daos/${DAOName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>


    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
    </Modal>


    <CollectionProfileContainer>
            <CollectionProfileBGContainer>
              {daoProfile?.profile.cover_image != null && daoProfile?.profile.cover_image.length > 0 ? (
                <img
                  src={`${resizerPrefix}${daoProfile.profile.cover_image}`}
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={collection_profile_stock_bg}
                  style={{
                    width: "auto",
                    height: "auto",
                    minHeight: "100%",
                    minWidth: "100%",
                  }}
                />
              )}
            </CollectionProfileBGContainer>

            <CollectionProfileAvatarCont>
              <img
                src={
                  daoProfile?.profile.avatar != null &&
                  `${resizerPrefix}${daoProfile.profile.avatar}${resizerSuffix}`
                }
                style={{
                  width: "auto",
                  height: "auto",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              />
            </CollectionProfileAvatarCont>
          </CollectionProfileContainer>

          <div className="p-5 mt-4 text-white">
            <br />
            <span className="text-2xl font-semibold">{DAOName}</span>
            <br />
            <span className="text-md">
              {daoData?.creator != null && <span>By {daoData.creator}</span>}
            </span>

            {daoProfile?.profile.description != null &&
              daoProfile?.profile.description.length > 3 && (
                <FarmDescriptionContainer>
                  <MilestoneDescription>
                    <ReactMarkdown>
                    {daoProfile?.profile.description}
                    </ReactMarkdown>
                  </MilestoneDescription>
                </FarmDescriptionContainer>
              )}

              {getFarmSocials(daoProfile.socials)}            

          </div>

<NewListingBody>
  <TallFiltersCont>
  <h2>DAO Details</h2>
  <br/>
                    <FoldersContainer>
                    <FoldersRow>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentSection("Settings");
                        }}
                        selected={currentSection == "Settings" && true}
                      >
                        Settings
                      </SingleFolderFourth>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentSection("Proposals");
                          getDaoProposals(DAOName);
                        }}
                        selected={currentSection == "Proposals" && true}
                      >
                        Proposals
                      </SingleFolderFourth>

                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentSection("New Proposal");
                        }}
                        selected={currentSection == "New Proposal" && true}
                      >
                        New Proposal
                      </SingleFolderFourth>        

                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentSection("Treasury");
                          getDaoTokenTreasury(DAOName);
                        }}
                        selected={currentSection == "Treasury" && true}
                      >
                        Treasury
                      </SingleFolderFourth>

                    </FoldersRow>
                  </FoldersContainer>
  <TallFiltersInnerCont>
    {currentSection == "Settings" && showV2DaoInfo(daoData)}
    {currentSection == "Proposals" && showV2Proposals(DAOName, daoProposals, useGetV2DAOProposals, proposalsAreLoading)}
    {currentSection == "New Proposal" && 
      showV2ProposalForm(daoData, title, setTitle, description, setDescription, proposalType, 
        setProposalType, choicesList, setChoicesList, tokenReceivers, setTokenReceivers,
        nftReceivers, setNftReceivers, proofAssets, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}
    {currentSection == "Treasury" && showDAOTreasury(DAOName, currentTreasurySection, setCurrentTreasurySection, daoTokens, getDaoTokenTreasury, tokensAreLoading, daoNfts, getDaoNftTreasury, daoNftsAreLoading, hasNextDaoNftPage)}
  </TallFiltersInnerCont>
  </TallFiltersCont>


  <TallFiltersCont>
  <h2>Actions</h2>
  <br/>
                    <FoldersContainer>
                    <FoldersRow>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentAction("NFT Deposit");
                        }}
                        selected={currentAction == "NFT Deposit" && true}
                      >
                        NFT Deposit
                      </SingleFolderFourth>

                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentAction("Token Deposit");
                          if(tokenBalances.length === 0){
                            getTokenBalances();   
                          }                                                 
                        }}
                        selected={currentAction == "Token Deposit" && true}
                      >
                        Token Deposit
                      </SingleFolderFourth>

                      <span className={!daoHasStaking(daoData) && "hidden"}>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentAction("Stake");
                          if(tokenBalances.length === 0){
                            getTokenBalances();   
                          }                           
                        }}
                        selected={currentAction == "Stake" && true}
                      >
                        Stake
                      </SingleFolderFourth>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentAction("Unstake");
                        }}
                        selected={currentAction == "Unstake" && true}
                      >
                        Unstake
                      </SingleFolderFourth>
                      </span>

                    </FoldersRow>
                  </FoldersContainer>
  <TallFiltersInnerCont>
{currentAction == "Token Deposit" && showTokenDepositSection(DAOName, tokenBalances, balancesAreLoading, selectedTokenObject, setSelectedTokenObject, tokenAmountToDeposit, setTokenAmountToDeposit,   setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay)}

{currentAction == "NFT Deposit" && (
  showNftDepositSection(  DAOName,
    nfts,
    nftsAreLoading,
    selectedNfts,
    setSelectedNfts,
    myCollections,
    collectionsWereLoaded,
    selectedCollection,
    setSelectedCollection,
    hasNextPage,
    getNfts,
    getMyCollections,
    userHasConfirmed,
    setUserHasConfirmed,
    setEnterModalText,
    setLoadingDisplay,
    setEnterButtonsDisplay,
    setEnterModalDisplay)
)}

{currentAction == "Stake" && showStakeTokensSection(DAOName,
  tokenBalances,
  daoData,
  amountToStake,
  setAmountToStake,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay)}

  {currentAction == "Unstake" && (
    <span>
      Under maintenance, but tokens can be unstaked directly from <a href="https://waxblock.io/account/dao.waxdao?action=unstaketoken#contract-actions" target="none">the dao.waxdao</a> contract if needed.
    </span>
  )}

  </TallFiltersInnerCont>
  </TallFiltersCont>

</NewListingBody>


    <MainContainer>


  




























    </MainContainer>
    <br/><br/><br/>
    </UALProvider>
    </div>
  )}
  else{
    return <LoadingPage />
  }
}

export default DAOPageV2