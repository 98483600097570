import { useState } from "react";
import { currentApiList, currentUsername, v2DAOContract, v2FarmContract } from "../../data/config";
import axios from 'axios';
import { getPrecisionFromAsset, getTokenNameFromAsset } from "../../data/functions/pool_functions";

export const useGetAllV2StakedAssets = () => {
    
    const [stakedAssets, setStakedAssets] = useState([]);
    const [assetsInThisFarm, setAssetsInThisFarm] = useState([]);
    const [assetsAreLoading, setAssetsAreLoading] = useState(true);

    const getAllStakedAssets = async (user, farmname) => {
        setAssetsAreLoading(true);
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "stakers",
                    scope: v2FarmContract,
                    code: v2FarmContract,
                    limit: 1000,
                    key_type: "name",
                    index_position: 3,
                    lower_bound: user,
                    upper_bound: user,
                    json: true,
                });
                
                if (res.data.rows) {
                    console.log(res)
                    let asset_ids = [];
                    let assetsInFarm = [];
                    for(let row of res.data.rows){
                        asset_ids = [...asset_ids, ...row.asset_ids];
                        if (row.farmname === farmname) {
                            assetsInFarm = [...assetsInFarm, ...row.asset_ids];
                        }
                    }
                    setStakedAssets(asset_ids);
                    setAssetsInThisFarm(assetsInFarm); 
                }
            } catch (error) {
                console.log(error);
            }
        }
        setAssetsAreLoading(false);
    }

    return [stakedAssets, getAllStakedAssets, assetsAreLoading, assetsInThisFarm]
}
