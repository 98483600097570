import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { UALProvider } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  DropPageAdBannerCont,
  CreateFarmIPFS,
} from "../../components/LeftDiv";

import ReactMarkdown from "react-markdown";
import { currentUsername, getCurrentApiList, wombat } from "../../data/config";
import CurrentBannerAd from "../../components/CurrentBannerAd";
import {
  anchor,
  wax,
  myChain,
} from "../../data/config";
import {
  FoldersContainer,
  FoldersRow,
  MainButton,
  NewListingBody,
  NewListingCont,
  SingleFolderFourth,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import DatePicker from "react-datepicker";
import { handleCalendarChange } from "../../data/functions/pack_functions";
import { cancelBlend, cancelDrop, editBlend, editDropDetails } from "../../data/functions/wallet_functions";
import LoadingPage from "../../components/LoadingPage";
import Error from "../../components/Error";
import { resizerPrefix, resizerSuffix } from "../../data/constants";

const EditBlend = ({ location }) => {
  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
  } = useStateContext();

  const { DropID } = useParams();

  const [dropData, setDropData] = useState([]);
  const [dropIsLoading, setDropIsLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [currentDescriptionTab, setCurrentDescriptionTab] = useState("Edit");
  const [price, setPrice] = useState(0);
  const [precision, setPrecision] = useState(0);
  const [tokenName, setTokenName] = useState("");
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [title, setTitle] = useState("");
  const [cooldown, setCooldown] = useState(0);
  const [limitPerUser, setLimitPerUser] = useState(0);
  const [coverImage, setCoverImage] = useState("");

  useEffect(() => {
    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "blends",
        scope: "waxdaomarket",
        code: "waxdaomarket",
        limit: 1,
        lower_bound: DropID,
        upper_bound: DropID,
        json: true,
      })
      .then((response) => {
        setDropData(response.data.rows[0]);
        setDescription(response.data.rows[0].blend_description);
        setTitle(response.data.rows[0].blend_title);
        setStartTime(response.data.rows[0].start_time);
        setEndTime(response.data.rows[0].end_time);
        setCooldown(response.data.rows[0].cooldown_reset);
        setLimitPerUser(response.data.rows[0].limit_per_user);
        setCoverImage(response.data.rows[0].cover_image);
      })
      .catch((error) => console.log(error))
      .finally(() => setDropIsLoading(false));
  }, []);

if(!dropIsLoading && dropData != null && currentUsername == dropData.creator){

  return (
    <div id="seo">
      <Helmet>
        <title>Blend {DropID} Details</title>
        <meta
          name="description"
          content={`Buy NFTs from drop ${DropID} on WaxDao`}
        />
        <link rel="canonical" href={`https://waxdao.io/blends/${DropID}`} />
      </Helmet>

      <UALProvider
        chains={[myChain]}
        authenticators={[anchor, wax, wombat]}
        appName={"WaxDAO"}
      >
        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <GameButton onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </GameButton>
        </Modal>

        <NewListingCont>
          <DropPageAdBannerCont>
            <CurrentBannerAd />
          </DropPageAdBannerCont>

          <NewListingBody>
            <TallFiltersCont>
              <h2>Edit Blend {DropID}</h2>
              <br/>
              <TallFiltersInnerCont>
                  Note: Currently, the fields below are the only ones that can be edited. We plan to add more options soon, like editing ingredients and results.
                  <br/><br/>
                  For now, you would have to cancel your blend and create a new one if you need to make changes that are not shown on this page.
              </TallFiltersInnerCont>
            </TallFiltersCont>


            <TallFiltersCont>
              <h2>Title</h2>
              <br />
              <TallFiltersInnerCont>
                <input value={title} onChange={(e) => setTitle(e.target.value)} maxLength={40} />
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Cover Image</h2>
              <br />
              <TallFiltersInnerCont>
                <input value={coverImage} onChange={(e) => setCoverImage(e.target.value)} />

                {coverImage?.length > 10 && (
                    <span>
                        <br/><br/>
                        <CreateFarmIPFS src={`${resizerPrefix}${coverImage}${resizerSuffix}`} />
                    </span>
                )}
              </TallFiltersInnerCont>
            </TallFiltersCont>


            <TallFiltersCont>
              <h2>Start Time</h2>
              <br />
              <TallFiltersInnerCont>
                <DatePicker
                  showTimeSelect
                  timeIntervals={15}
                  selected={new Date(startTime * 1000)}
                  onChange={(e) => {
                    handleCalendarChange(e, setStartTime);
                  }}
                />
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>End Time</h2>
              <br />
              <TallFiltersInnerCont>
                <DatePicker
                  showTimeSelect
                  timeIntervals={15}
                  selected={new Date(endTime * 1000)}
                  onChange={(e) => {
                    handleCalendarChange(e, setEndTime);
                  }}
                />
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Description ({3000 - description.length}/3000)</h2>
              <br />
              <FoldersContainer>
                <FoldersRow>
                  <SingleFolderFourth
                    onClick={() => setCurrentDescriptionTab("Edit")}
                    selected={currentDescriptionTab == "Edit" && true}
                  >
                    Edit
                  </SingleFolderFourth>
                  <SingleFolderFourth
                    onClick={() => setCurrentDescriptionTab("Preview")}
                    selected={currentDescriptionTab == "Preview" && true}
                  >
                    Preview
                  </SingleFolderFourth>
                </FoldersRow>
              </FoldersContainer>
              <br />
              <TallFiltersInnerCont>
                {currentDescriptionTab == "Edit" && (
                  <textarea
                    value={description}
                    maxLength={3000}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                )}
                {currentDescriptionTab == "Preview" && (
                  <ReactMarkdown>{description}</ReactMarkdown>
                )}
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Cooldown (Seconds)</h2>
              <br />
              <TallFiltersInnerCont>
                <input value={cooldown} onChange={(e) => setCooldown(e.target.value)} />
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Limit Per User</h2>
              <br />
              <TallFiltersInnerCont>
                <input value={limitPerUser} onChange={(e) => setLimitPerUser(e.target.value)} />
              </TallFiltersInnerCont>
            </TallFiltersCont>                        
            <br />
            <br />

            <div className="text-center w-100">
              <MainButton
                onClick={() =>
                  editBlend(
                    DropID,
                    title,
                    description,
                    coverImage,
                    startTime,
                    endTime,
                    limitPerUser,
                    cooldown,
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterModalDisplay
                  )
                }
              >
                Confirm Changes
              </MainButton>
              &nbsp;&nbsp;
              <MainButton
                onClick={() =>
                  cancelBlend(
                    DropID,
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterModalDisplay
                  )
                }
              >
                Cancel Blend
              </MainButton>

            </div>
          </NewListingBody>
        </NewListingCont>
      </UALProvider>
      <br />
      <br />
      <br />
    </div>
  )}
  else if(dropIsLoading){
    return(<LoadingPage />)
  }

  else if(!dropIsLoading && dropData != null && (currentUsername == null || currentUsername == "" || currentUsername != dropData.creator) ){
      return(<Error error="You are not the creator of this blend" />)
  }
  else if(!dropIsLoading && dropData == null){
      return(<Error error="This blend does not exist. If this is an error, try refreshing the page" />)
  }
  else{
      return null
  }
};

export default EditBlend;
