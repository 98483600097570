import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';



import { Footer, Navbar, ThemeSettings } from './components';
import { TokenGen, HomePage, Listing, DAOs, DAOPage, Proposals, CreateDAO, MyDAOs, DAOOptions, FarmFAQ, MyFarms, MyPools, CreatePool, PoolFAQ, PoolPage, FarmOptions, Swap, FarmingNav, WojakNav, StakingNav, TokenNav, DaoNav, MyDrops, CreateDrop, DropPage, Drops, CollectionPage, Suggestions, PoolOptions, Escrow, MyEscrow, EscrowFAQ, CreatePremintedDrop } from './pages';

import { Body, MainWrapper } from './Styles';

const WalletPage = lazy(() => import("./pages/WalletPage"));
const AllTokens = lazy(() => import("./pages/token_tracker/AllTokens"));
const CreateFarm = lazy(() => import("./pages/CreateFarm"));
const FarmPage = lazy(() => import("./pages/FarmPage"));
const Farms = lazy(() => import("./pages/Farms"));
const Pools = lazy(() => import("./pages/pools/Pools"));
//drops
const CreatePremint = lazy(() => import("./pages/drops/CreatePremint"));
const PremintPage = lazy(() => import("./pages/drops/PremintPage"));
const CreatePack = lazy(() => import("./pages/drops/CreatePack"));
const UnboxList = lazy(() => import("./pages/drops/UnboxList"));
const UnboxPage = lazy(() => import("./pages/drops/UnboxPage"));
const CollectionDrops = lazy(() => import("./pages/drops/CollectionDrops"));
//blends
const BlendPage = lazy(() => import("./pages/blends/BlendPage"));
const CreateBlend = lazy(() => import("./pages/blends/CreateBlend"));
const Blends = lazy(() => import("./pages/blends/Blends"));
const TokenCreator = lazy(() => import("./pages/TokenCreator"));

import { useStateContext } from './contexts/ContextProvider';
import { WalletContextProvider } from './contexts/WalletContextProvider';
import './App.css';
import PackTutorial from './pages/PackTutorial';
import NotFound from './components/NotFound';
import AdTerms from './components/AdTerms';
import BlogPost from './pages/blog/BlogPost';
import AllBlogs from './pages/blog/AllBlogs';
import WaxLabs from './components/WaxLabs';
import CollectionBlends from './pages/blends/CollectionBlends';
import TokenPage from './pages/token_tracker/TokenPage';
import WaxLabs2 from './components/WaxLabs2';
import CreateBlendV2 from './pages/blendsv2/CreateBlendV2';
import BlendsV2 from './pages/blendsv2/BlendsV2';
import BlendPageV2 from './pages/blendsv2/BlendPageV2';
import OTC from './pages/OTC';
import UserFarms from './pages/UserFarms';
import LoadingPage from './components/LoadingPage';
import Create from './pages/Navigation/Create';
import UserPage from './pages/UserPage';
import Advertise from './components/Advertise';
import MyPremintedPools from './pages/drops/MyPremintedPools';
import NFTsStakedByUserInFarm from './pages/NFTsStakedByUserInFarm';
import EditDrop from './pages/drops/EditDrop';
import EditPack from './pages/drops/EditPack';
import EditBlend from './pages/blends/EditBlend';
import SyntheticsPage from './pages/SyntheticsPage';
import OIGDrop from './pages/drops/OIGDrop';
import Locker from './pages/locker/Locker';
import ManageLocks from './pages/locker/ManageLocks';
import LockPage from './pages/locker/LockPage';
import DAOsV2 from './pages/daosv2/DAOsV2';
import DAOPageV2 from './pages/daosv2/DAOPageV2';
import ProposalPageV2 from './pages/daosv2/ProposalPageV2';
import GuildCandidacy from './pages/GuildCandidacy';
import DAOFAQV2 from './pages/daosv2/DAOFAQV2';
import Royalty from './pages/Royalty';
import CreateFarmV2 from './pages/farmsv2/CreateV2Farm';
import EditV2Farm from './pages/farmsv2/EditV2Farm';
import FarmsV2 from './pages/farmsv2/FarmsV2';
import FarmPageV2 from './pages/farmsv2/FarmPageV2';

//Dao V2
const DAOForm = lazy(() => import("./pages/daosv2/DAOForm"));

const App = () => {



    const { activeMenu, themeSettings, currentMode, currentUser } = useStateContext();

    return (
    <div style={{fontFamily: "Roboto"}}>

        <BrowserRouter>
            <MainWrapper>

            <div className={`min-h-screen w-full flex-2`}>

                    <Navbar />
                    <Body>
              
            <div>
                {themeSettings && <ThemeSettings />}

                <Suspense fallback={<LoadingPage />}>
                <Routes>

                    {/* Home */}

                    <Route path="/" element={<HomePage />} />
                    <Route path="/suggestions" element={<Suggestions />} />
                    <Route path="/wallet" element={<WalletContextProvider> <WalletPage /> </WalletContextProvider> } />
                    
                    {/* User Profiles */}
                    <Route path="/u/:UserName" element={<UserPage />} />
                    
                    {/* TokenGen */}
                
                    <Route path="/token-creator" element={<TokenCreator />} />
                    <Route path="/wax-tokens" element={<TokenNav />} />
                    <Route path="/request-listing" element={<Listing />} />


                    <Route path="/tutorial" element={<TokenGen />} />
                    <Route path="/whitelisting" element={<TokenCreator />} />
                    <Route path="/deploy-contract" element={<TokenCreator />} />
                    <Route path="/create-token" element={<TokenCreator />} />
                    <Route path="/issue-tokens" element={<TokenCreator />} />
                    <Route path="/transfer-tokens" element={<TokenCreator />} />
                    <Route path="/burn-tokens" element={<TokenCreator />} />

                    {/* Synthetics */}

                    <Route path="/synths" element={<SyntheticsPage />} />

                    {/* Farms */}

                    <Route path="/farms" element={<Farms />} />
                    <Route path="/my-farms" element={<MyFarms />} />
                    <Route path="/create-farm" element={<CreateFarm />} />
                    <Route path="/farm/:FarmName" element={<FarmPage />} />
                    <Route path="/edit-farm/:FarmName" element={<FarmOptions />} />
                    <Route path="/farm-faq" element={<FarmFAQ />} />
                    <Route path="/u/:CreatorName/farms" element={<UserFarms />} />
                    <Route path="/view-farm-nfts/:FarmUserCombo" element={<NFTsStakedByUserInFarm />} />
                    
                    {/* Farms V2 */}

                    <Route path="/v2/create-farm" element={<CreateFarmV2 />} />    
                    <Route path="/v2/edit-farm/:FarmName" element={<EditV2Farm />} /> 
                    <Route path="/v2/farm/:FarmName" element={<FarmPageV2 />} /> 
                    <Route path="/v2/farms" element={<FarmsV2 />} />                  


                    {/* Wojak NFTs */}
                    <Route path="/wojak" element={<Navigate to="/drops/247" />} />
                    <Route path="/wojak/drop" element={<Navigate to="/drops/247" />} />
                    <Route path="/wojak/swap" element={<Swap />} />
                    <Route path="/wojak/unpack" element={ <Navigate to="/unbox/246" />}  />

                    <Route path="/wojak/staking" element={<Navigate to="/farm/waxdao.1week" />} />

                    {/* OTC Market */}

                    <Route path="/otc" element={<OTC />} />
                    

                    {/* Pools */}

                    <Route path="/pools" element={<Pools />} />
                    <Route path="/my-pools" element={<MyPools />} />
                    <Route path="/create-pool" element={<CreatePool />} />
                    <Route path="/pool/:PoolName" element={<PoolPage />} />
                    <Route path="/pool-faq" element={<PoolFAQ />} />
                    <Route path="/edit-pool/:PoolName" element={<PoolOptions />} />

                    {/* Drops */}

                    <Route path="/my-drops" element={<MyDrops />} />
                    <Route path="/create-drop" element={<CreateDrop />} />
                    <Route path="/pack-tutorial" element={<PackTutorial />} />
                    <Route path="/create-premint" element={<CreatePremint />} />
                    <Route path="/create-pack" element={<MyPremintedPools />} />
                    <Route path="/create-pack/:PoolID" element={<CreatePack />} />
                    <Route path="/premint-pool/:PoolID" element={<PremintPage />} />
                    <Route path="/create-preminted-drop/:PoolID" element={<CreatePremintedDrop />} />
                    <Route path="/drops/:DropID" element={<DropPage />} />
                    <Route path="/oig" element={<OIGDrop />} />
                    <Route path="/collection/:CollectionName" element={<CollectionPage />} />
                    <Route path="/drops" element={<Drops />} />
                    <Route path="/unbox" element={<UnboxList />} />
                    <Route path="/unbox/:DropID" element={<UnboxPage />} />
                    <Route path="/c/:CollectionName/drops" element={<CollectionDrops />} />
                    <Route path="/edit-drop/:DropID" element={<EditDrop />} />
                    <Route path="/edit-pack/:DropID" element={<EditPack />} />

                    {/* Blends */}

                    <Route path="/blends" element={<Blends />} />
                    <Route path="/create-blend" element={<CreateBlend />} />
                    <Route path="/blends/:DropID" element={<BlendPage />} />
                    <Route path="/u/:CollectionName/blends" element={<CollectionBlends />} />
                    <Route path="/edit-blend/:DropID" element={<EditBlend />} />



                    {/* V2 Blends */}

                    <Route path="/v2/create-blend" element={<CreateBlendV2 />} />
                    <Route path="/v2/blends" element={<BlendsV2 />} />
                    <Route path="/v2/blends/:DropID" element={<BlendPageV2 />} />

                    {/* Escrow */}

                    <Route path="/escrow" element={<Escrow />} />
                    <Route path="/manage-escrow" element={<MyEscrow />} />
                    <Route path="/escrow-faq" element={<EscrowFAQ />} />

                    {/* Liquidity Locker */}

                    <Route path="/locker" element={<Locker />} />
                    <Route path="/manage-locks" element={<ManageLocks />} />
                    <Route path="/locks/:LockID" element={<LockPage />} />                    

                    

                    {/* Governance */}
                    
                    <Route path="/daos" element={<DAOs />} />
                    <Route path="/proposals/:ProposalID" element={<Proposals />} />                    
                    <Route path="/votes" element={<DAOs />} />
                    <Route path="/create-dao" element={<CreateDAO />} />
                    <Route path="/my-daos" element={<MyDAOs />} />
                    <Route path="/edit-dao/:DAOName" element={<DAOOptions />} />
                    <Route path="/daos/:DAOName" element={<DAOPage />} />

                    {/* DAOs v2 */}
                    <Route path="/v2/create-dao" element={<DAOForm />} />
                    <Route path="/v2/daos" element={<DAOsV2 />} />
                    <Route path="/v2/daos/:DAOName" element={<DAOPageV2 />} />
                    <Route path="/v2/:DAOName/proposals/:ProposalID" element={<ProposalPageV2 />} />

                    {/* Navigation */}

                    <Route path="/nft-farming" element={<FarmingNav />} />
                    <Route path="/wojak-nfts" element={<WojakNav />} />
                    <Route path="/staking-pools" element={<StakingNav />} />
                    <Route path="/token-creator" element={<TokenNav />} />
                    <Route path="/dao-menu" element={<DaoNav />} />
                    <Route path="/create" element={<Create />} />


                    {/* Advertising */}
                    <Route path="/ad-terms" element={<AdTerms />} />
                    <Route path="/advertise" element={<Advertise />} />

                    {/* Blog */}
                    <Route path="/blog" element={<AllBlogs />} />
                    <Route path="/blog/:BlogID" element={<BlogPost />} />

                    {/* Token Tracker */}
                    <Route path="/tokens" element={<AllTokens />} />
                    <Route path="/tokens/:TokenID" element={<TokenPage />} />

                    {/* Royalty Contract */}
                    <Route path="/royalty-contract" element={<Royalty />} />


                    <Route path="/waxlabs-report-1" element={<WaxLabs />} />
                    <Route path="/coding/resources" element={<WaxLabs2 />} />

                    <Route path="/guild-candidacy" element={<GuildCandidacy />} />
                    <Route path="/v2/dao-faq" element={<DAOFAQV2 />} />


                    {/* 404 NOT FOUND CATCHALL */}

                    <Route path="*" element={<NotFound />} />

                </Routes>
                </Suspense>
            </div>
            </Body>
            <Footer />
            </div>

            </MainWrapper>       
        </BrowserRouter>
    </div>
  )
}

export default App