import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { MainContainer, NewUIFarmsPageCont, NFTsCont, NFTLogo, NoDaosMessage, ProposalInput, DaoButtonCont, RamModal, WalletCont, FarmButtonCont, ToggleSwitch, InnerSwitch, ToggleSwitchCont, ToggleMessage, StakeManyButton, StakeManyDiv, LeftProfile, RightProfile, ProfileCont, ProfileImage, LeftProfileImg, SocialButton, FarmsTitle, FarmsPageCont, DropPageCont, MissingInfoMsg, Drop, DropTitle, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DropImage, Remaining, Price, QuantityToBuy, MinMint, AddNFT, DropDescription, DropBody, PoolAssetsTitle, UnboxTemplate, DropPageAdBannerCont, NotFoundCont, SQLTextarea } from '../../components/LeftDiv';
import { getBlogPostEndpoint, localServerURL, adminMode, editBlogEndpoint, adminServerURL } from '../../data/config';
import NotFound from '../../components/NotFound';
import { BlogContainer, BlogBody, BlogFooter, RemoveBlogButton } from '../../data/css/BlogStyles';



const BlogPost = ({ location }) => {

const { BlogID } = useParams();

const [thisBlogPost, setThisBlogPost] = useState([]);
const [blogContent, setBlogContent] = useState("");

useEffect(() => {

    axios.post(`${localServerURL}/${getBlogPostEndpoint}`, {
        slug: BlogID,
      },
        {
        headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        },

    }).then((response) => {
        setThisBlogPost(response.data[0]);
        setBlogContent(response.data[0].body)
    })

}
    
    , []);



if(thisBlogPost?.title != null && thisBlogPost.title != ""){
  
if(adminMode){

  return (
    <div id="seo">
    <Helmet>
    <title>WaxDAO Blog - {BlogID}</title>
    <meta name="description" content={`${thisBlogPost.seo}`} />
    <link rel="canonical" href={`https://waxdao.io/blog/${BlogID}`} />
    </Helmet>

    <NewUIFarmsPageCont>
<BlogContainer>
<h2>
{thisBlogPost?.title != null && thisBlogPost.title}
</h2>


 <SQLTextarea value={blogContent} onChange={(e) => { setBlogContent(e.target.value) }} />


 <RemoveBlogButton className={!adminMode && "hidden"} onClick={() => { editBlogPost(thisBlogPost.ID); }}>
    Edit Blog
</RemoveBlogButton>

</BlogContainer>
    </NewUIFarmsPageCont>

    <br/><br/><br/>
    </div>
  )

} else {

  return (
    <div id="seo">
    <Helmet>
    <title>WaxDAO Blog - {BlogID}</title>
    <meta name="description" content={`${thisBlogPost.seo}`} />
    <link rel="canonical" href={`https://waxdao.io/blog/${BlogID}`} />
    </Helmet>

    <NewUIFarmsPageCont>
<BlogContainer>
<h2>
{thisBlogPost?.title != null && thisBlogPost.title}
</h2>


 <BlogBody>
{thisBlogPost?.body != null && (
  <div dangerouslySetInnerHTML={{ __html: thisBlogPost.body }} />

  )}


{thisBlogPost?.author != null && thisBlogPost.time_published != null && (
  <BlogFooter>
    Published by {thisBlogPost.author} on {new Date(thisBlogPost.time_published).toDateString()}
  </BlogFooter>

  )}


</BlogBody>




</BlogContainer>
    </NewUIFarmsPageCont>

    <br/><br/><br/>
    </div>
  )


}



}

else{return(
  <NotFound />
)}

}

export default BlogPost