import React, { useEffect } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { AllFarmsCont, FarmsPageCont, FarmsTitle, SingleFarmCont, FarmLogo, FarmTitle, FarmEndDate, PoolAmount, ProposerTypeMessage, MissingInfoMsg, DaoButton, PaymentOptCont, BurnableCont, NFT, NewRow, BuyWojak, ProposalInput, NewProposal, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DisabledButton, SingleDropLogo, DropDown, FilterMenu, DropType, SoldOut, NewUIFarmsPageCont } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { currentApiList, currentAtomicApiList, wax, anchor, myChain, currentWebsiteURL, currentAdBanner } from '../../data/config';
import CurrentBannerAd from '../../components/CurrentBannerAd';



const BlendsV2 = () => {

  const { 

     dropList, setDropList, 
    enterModalText,
    setEnterModalText,
    loadingDisplay, 
    enterModalDisplay, 
    setEnterModalDisplay,
    logoHash,
    setLogoHash,
    dropSortMode,
    setDropSortMode,
    pageCount,
    setPageCount,
    loadMoreDisplay,
    setLoadMoreDisplay,
    currentNavbarLocation,
    setCurrentNavbarLocation,

} = useStateContext();


useEffect(() => {

setDropList([]);
setLogoHash('');
setDropSortMode('newest');
setPageCount();
setLoadMoreDisplay('');
setCurrentNavbarLocation("Blends");





          axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
            table:"blends",
            scope:"waxdaomrktv2",
            code:"waxdaomrktv2",
            reverse: true,
            //lower_bound: 10000000000,
            upper_bound: 10000000000,
            limit:100,
            json:true
          })
            .then((response) => {
        
                setDropList(response.data.rows);
                console.log(response.data)

                if(response.data.more == true){

                  setPageCount(Number(response.data.next_key));
    
                } else { setLoadMoreDisplay('hidden'); }
              
            })
            .catch((error) => console.log(error));
  
  }

, []);




const getNextPage = (pageCount, setPageCount, dropList, setDropList) => {

    var lowerBound = pageCount;

      axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
        table:"blends",
        scope:"waxdaomrktv2",
        code:"waxdaomrktv2",
        reverse: true,
        upper_bound: lowerBound,
        limit:100,
        json:true
      })
        .then((response) => {

            var list = [...dropList];


            if(Array.isArray(dropList[0])){
              list = dropList[0];
            }

            list = list.concat(response.data.rows);

            setDropList(list);

            if(response.data.more == true){

              setPageCount(Number(response.data.next_key));

            } else { setLoadMoreDisplay('hidden'); }
            
          
        })
        .catch((error) => console.log(error));
      




}


  return (
  <div id="seo">
    <Helmet>
    <title>Blends</title>
    <meta name="description" content="View a list of NFT drops on WaxDAO" />
    <link rel="canonical" href={`${currentWebsiteURL}/blends`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>


    <NewUIFarmsPageCont>

      <FarmsTitle>
          Blends &nbsp;&nbsp;
        <a href={`${currentWebsiteURL}/create-blend`}>
        <DaoButton>
        Create New Blend
        </DaoButton>
        </a>
        &nbsp;&nbsp;
      </FarmsTitle>

<CurrentBannerAd />


      <AllFarmsCont>

      <form>
        <FilterMenu        
          onChange={(e) => {setDropSortMode(e.target.value)}}
          >
        <option value="" hidden>
          Sort
        </option>
        <option value="ending_soonest">Ending Soonest</option>
        <option value="ending_latest">Ending Latest</option>
        <option value="newest">Newest</option>
        <option value="oldest">Oldest</option>
        </FilterMenu>
        </form>
<NewRow />

        
{dropList == null && (
<span>
<PaymentOptCont>
<MissingInfoMsg>
  Error connecting to the WaxDAO APIs.<br/><br/>
  Try waiting a few seconds and refreshing the page.
</MissingInfoMsg>
</PaymentOptCont>
</span>
)}

{/* Sort by ending soonest */}

{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropSortMode != null && dropSortMode == "ending_soonest" && (dropList[0].sort((a,b) => a.end_time - b.end_time).map((item, index) => (
  <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
    <a href={`${currentWebsiteURL}/v2/blends/${item.ID}`}>
    <SingleFarmCont>
      
      {item.blend_title != null && (
        <DropType>
          {item.blend_title}
        </DropType>
      )}


      {item.cover_image != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.cover_image}`} />
      )}
      {item.creator != null && (
        <FarmTitle>
        By {item.creator}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         {item.price == "0 FREE" ? "Free Drop" : item.price}
        </PoolAmount>
      )}

      {item.end_time != null && item.total_left != null && item.total_left != '0' &&(
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

      {item.total_left != null && item.total_left == '0' && (
        <SoldOut>
          SOLD OUT
        </SoldOut>
      )}

    </SingleFarmCont>
    </a>
    </span>
    ))) }
    
    
    {dropList[1] != null && Array.isArray(dropList) && dropSortMode != null && dropSortMode == "ending_soonest" && (dropList.sort((a,b) => a.end_time - b.end_time).map((item, index) => (
     <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
     <a href={`${currentWebsiteURL}/v2/blends/${item.ID}`}>
      <SingleFarmCont>
        
      {item.blend_title != null && (
        <DropType>
          {item.blend_title}
        </DropType>
      )}



      {item.cover_image != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.cover_image}`} />
      )}
      {item.creator != null && (
        <FarmTitle>
        By {item.creator}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         {item.price == "0 FREE" ? "Free Drop" : item.price}
        </PoolAmount>
      )}

      {item.end_time != null && item.total_left != null && item.total_left != '0' &&(
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

      {item.total_left != null && item.total_left == '0' && (
        <SoldOut>
          SOLD OUT
        </SoldOut>
      )}

     </SingleFarmCont>
     </a>
     </span>
      )))}


{/* Sort by ending latest */}

{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropSortMode != null && dropSortMode == "ending_latest" && (dropList[0].sort((a,b) => b.end_time - a.end_time).map((item, index) => (
 <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
    <a href={`${currentWebsiteURL}/v2/blends/${item.ID}`}>
    <SingleFarmCont>
      
    {item.blend_title != null && (
        <DropType>
          {item.blend_title}
        </DropType>
      )}


      {item.cover_image != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.cover_image}`} />
      )}
      {item.creator != null && (
        <FarmTitle>
        By {item.creator}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         {item.price == "0 FREE" ? "Free Drop" : item.price}
        </PoolAmount>
      )}

      {item.end_time != null && item.total_left != null && item.total_left != '0' &&(
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

      {item.total_left != null && item.total_left == '0' && (
        <SoldOut>
          SOLD OUT
        </SoldOut>
      )}

    </SingleFarmCont>
    </a>
    </span>
    ))) }
    
    
    {dropList[1] != null && Array.isArray(dropList) && dropSortMode != null && dropSortMode == "ending_latest" && (dropList.sort((a,b) => b.end_time - a.end_time).map((item, index) => (
     <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
      <a href={`${currentWebsiteURL}/v2/blends/${item.ID}`}>
      <SingleFarmCont>
        
      {item.blend_title != null && (
        <DropType>
          {item.blend_title}
        </DropType>
      )}

      {item.cover_image != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.cover_image}`} />
      )}
      {item.creator != null && (
        <FarmTitle>
        By {item.creator}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         {item.price == "0 FREE" ? "Free Drop" : item.price}
        </PoolAmount>
      )}

      {item.end_time != null && item.total_left != null && item.total_left != '0' &&(
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

      {item.total_left != null && item.total_left == '0' && (
        <SoldOut>
          SOLD OUT
        </SoldOut>
      )}

     </SingleFarmCont>
     </a>
     </span>
      )))}



{/* Sort by newest */}

{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropSortMode != null && dropSortMode == "oldest" && (dropList[0].sort((a,b) => a.start_time - b.start_time).map((item, index) => (
 <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
    <a href={`${currentWebsiteURL}/v2/blends/${item.ID}`}>
    <SingleFarmCont>
      
    {item.blend_title != null && (
        <DropType>
          {item.blend_title}
        </DropType>
      )}

      {item.cover_image != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.cover_image}`} />
      )}
      {item.creator != null && (
        <FarmTitle>
        By {item.creator}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         {item.price == "0 FREE" ? "Free Drop" : item.price}
        </PoolAmount>
      )}

      {item.end_time != null && item.total_left != null && item.total_left != '0' &&(
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

      {item.total_left != null && item.total_left == '0' && (
        <SoldOut>
          SOLD OUT
        </SoldOut>
      )}

    </SingleFarmCont>
    </a>
    </span>
    ))) }
    
    
    {dropList[1] != null && Array.isArray(dropList) && dropSortMode != null && dropSortMode == "oldest" && (dropList.sort((a,b) => a.start_time - b.start_time).map((item, index) => (
    <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
      <a href={`${currentWebsiteURL}/v2/blends/${item.ID}`}>
      <SingleFarmCont>
        
      {item.blend_title != null && (
        <DropType>
          {item.blend_title}
        </DropType>
      )}
      {item.cover_image != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.cover_image}`} />
      )}
      {item.creator != null && (
        <FarmTitle>
        By {item.creator}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         {item.price == "0 FREE" ? "Free Drop" : item.price}
        </PoolAmount>
      )}

      {item.end_time != null && item.total_left != null && item.total_left != '0' &&(
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

      {item.total_left != null && item.total_left == '0' && (
        <SoldOut>
          SOLD OUT
        </SoldOut>
      )}

     </SingleFarmCont>
     </a>
     </span>
      )))}






{/* Sort by oldest */}

{dropList[1] == null && dropList[0] != null && Array.isArray(dropList[0]) && dropSortMode != null && dropSortMode == "newest" && (dropList[0].sort((a,b) => b.start_time - a.start_time).map((item, index) => (
 <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
    <a href={`${currentWebsiteURL}/v2/blends/${item.ID}`}>
    <SingleFarmCont>
      
    {item.blend_title != null && (
        <DropType>
          {item.blend_title}
        </DropType>
      )}
      {item.cover_image != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.cover_image}`} />
      )}
      {item.creator != null && (
        <FarmTitle>
        By {item.creator}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         {item.price == "0 FREE" ? "Free Drop" : item.price}
        </PoolAmount>
      )}

      {item.end_time != null && item.total_left != null && item.total_left != '0' &&(
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

      {item.total_left != null && item.total_left == '0' && (
        <SoldOut>
          SOLD OUT
        </SoldOut>
      )}

    </SingleFarmCont>
    </a>
    </span>
    ))) }
    
    
    {dropList[1] != null && Array.isArray(dropList) && dropSortMode != null && dropSortMode == "newest" && (dropList.sort((a,b) => b.start_time - a.start_time).map((item, index) => (
      <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
      <a href={`${currentWebsiteURL}/v2/blends/${item.ID}`}>
      <SingleFarmCont>
        
      {item.blend_title != null && (
        <DropType>
          {item.blend_title}
        </DropType>
      )}
      {item.cover_image != null && (
        <SingleDropLogo src={`https://ipfs.io/ipfs/${item.cover_image}`} />
      )}
      {item.creator != null && (
        <FarmTitle>
        By {item.creator}
        </FarmTitle>
      )}

      {item.price != null && (
        <PoolAmount>
         {/* <NumberFormat displayType='text' thousandSeparator={true} value={item.price} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /> */}
         {item.price == "0 FREE" ? "Free Drop" : item.price}
        </PoolAmount>
      )}

      {item.end_time != null && item.total_left != null && item.total_left != '0' &&(
        <FarmEndDate>
          Ends {new Date(item.end_time * 1000).toLocaleDateString()}
        </FarmEndDate>
      )}

      {item.total_left != null && item.total_left == '0' && (
        <SoldOut>
          SOLD OUT
        </SoldOut>
      )}

      

     </SingleFarmCont>
     </a>
     </span>
      )))}


<br/>
<NewRow />
<DaoButton className={loadMoreDisplay} onClick={() => { getNextPage(pageCount, setPageCount, dropList, setDropList) }}>
  Load More
</DaoButton>


<DisabledButton className={loadMoreDisplay != 'hidden' && 'hidden'} >
  No More Results
</DisabledButton>

</AllFarmsCont>

      </NewUIFarmsPageCont>
      <br/><br/><br/>
    </UALProvider>
 
    </div>
  )
}

export default BlendsV2