import React from "react";
import { UALProvider, withUAL } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import { wax, anchor, myChain, currentWebsiteURL, wombat } from "../data/config";
import google_analytics from "../data/google_analytics.png";
import dapp_radar from "../data/dapp_radar.png";
import google_search_console from "../data/google_search_console.png";
import {
  MilestoneContainer,
  NewListingBody,
  NewListingCont,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../Styles";

const AdTerms = () => {
  return (
    <div id="seo">
      <Helmet>
        <title>Ad Terms - WaxDAO</title>
        <meta
          name="description"
          content="Terms and conditions for advertising on WaxDAO"
        />
        <link rel="canonical" href={`${currentWebsiteURL}/ad-terms`} />
      </Helmet>
      <UALProvider
        chains={[myChain]}
        authenticators={[wax, anchor, wombat]}
        appName={"WaxDAO"}
      >
        <NewListingCont>
          <NewListingBody>
            <MilestoneContainer>
              <h2>Ad Terms And Conditions</h2>
            </MilestoneContainer>

            <TallFiltersCont>
              <h2>How much does a banner ad cost?</h2>
              <br />
              <TallFiltersInnerCont>
                Currently the price is 50 WAX for a 24 hour slot, or 10,000
                WOJAK (both tokens are accepted).
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Where will the image be shown?</h2>
              <br />
              <TallFiltersInnerCont>
                Your banner will be shown on individual{" "}
                <a href={`${currentWebsiteURL}/tokens`}>token pages</a>, the
                market page, the blends page, individual drop pages, the NFT
                farms page, and the token staking pools page.
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>What size should the image be?</h2>
              <br />
              <TallFiltersInnerCont>
                Ideal image size is 1280px wide by 200px tall. Your image will
                be resized to fit these proportions if you use a different size.
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>What file formats are accepted?</h2>
              <br />
              <TallFiltersInnerCont>
                Use a .png, .jpg or .gif file.
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Do I need to use IPFS?</h2>
              <br />
              <TallFiltersInnerCont>
                Yes, we do not allow file uploads on WaxDAO. IPFS images only.
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>How many sales/clicks will I get by advertising?</h2>
              <br />
              <TallFiltersInnerCont>
                WaxDAO makes 0 guarantees about any conversion rates. Here are
                some of our stats for December 2022:
                <br />
                <br />
                <h3>Google Search Console:</h3>
                <img src={google_search_console} />
                <br />
                <br />
                <h3>DappRadar:</h3>
                <img src={dapp_radar} />
                <br />
                <br />
                <h3>Google Analytics:</h3>
                <img src={google_analytics} />
                <br />
                <br />
                Do with this info as you please, we make no guarantees. We are
                constantly growing and building our userbase though, so
                personally I'd say it's a bargain.
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>
                What is allowed, can I post nudity and hate speech in my banner
                ad?
              </h2>
              <br />
              <TallFiltersInnerCont>
                Semi-provocative stuff is ok, but full on nudity will be
                removed. As far as language goes, we aren't here to police
                anyone - but use your brain please. WaxDAO reserves the right to
                remove any content for any reason, with or without a refund.
                <br />
                <br />
                If you submit a URL that links to anything malicious (malware
                etc), your banner ad will be removed and you will not be
                refunded.
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Is WaxDAO partnered with the project I saw in the banner?</h2>
              <br />
              <TallFiltersInnerCont>
                No, banner ads are submitted by users in exchange for a payment.
                WaxDAO is not partnered with anyone unless otherwise stated by
                WaxDAO. We hate the word partner just as much as anyone else
                does.
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>I cant find the advertising form!</h2>
              <br />
              <TallFiltersInnerCont>
                Visit the{" "}
                <a href={`${currentWebsiteURL}/advertise`}>Advertise page</a> to
                create your ad banner.
              </TallFiltersInnerCont>
            </TallFiltersCont>
          </NewListingBody>
        </NewListingCont>
        <br />
        <br />
        <br />
      </UALProvider>
    </div>
  );
};

export default AdTerms;
