import React, { useEffect } from "react";
import mindmaster_logo from "../data/mindmaster_logo.png";
import { useStateContext } from "../contexts/ContextProvider";
import { UALProvider } from "ual-reactjs-renderer";
import { Name } from "eos-common";
import {
  MindmasterLogo,
  MindmasterWrapper,
  MobileNavCont,
  Modal,
  ModalContent,
  ModalOverlay,
  NewAnchorButton,
  NewCloudButton,
  Spinner,
  SpinnerBlue,
  SpinnerGreen,
  SpinnerRed,
  WaxDAOText,
  CloseWindow,
  MobileModalOverlay,
  MindmasterMobileWrapper,
  MindmasterMobileLogo,
  WalletIconButtonWrapper,
} from "./LeftDiv";
import {
  wax,
  anchor,
  myChain,
  currentWebsiteURL,
  currentUsername,
  currentWalletProvider,
  wombat,
  mainnetAtomicApiList,
  notificationProps,
} from "../data/config";
import {
  NewNavbar,
  NewUINavLinkCont,
  NewUINavLink,
  MobileNavLine,
  SidebarNavLink,
} from "../data/css/NavbarStyles";
import {
  CloseSidebarButton,
  LargeScreenNavDropDown,
  MobileSidebar,
  SidebarLinkContainer,
  SidebarLinksGap,
  SidebarOverlay,
  SidebarScrollWrapper,
} from "../data/css/SidebarStyles";
import { isLoggedIn } from "../data/functions/wallet_functions";
import { theme, ViewPoolButton } from "../Styles";
import { checkWallet, getCustomersData, logUserIn, logUserOut } from "../data/functions/global_functions";
import NotificationBanner from "./NotificationBanner";

const Navbar = () => {
  const {
    setScreenSize,
    currentUser,
    setCurrentUser,
    setWhitelistStatus,
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
    mobileNavDisplay,
    setMobileNavDisplay,
    setWalletSidebarIsOpen,
    currentNavbarLocation,
    mobileNavIsOpen,
    setMobileNavIsOpen,
    isLoggedInContext,
    setIsLoggedInContext,
  } = useStateContext();

  useEffect(() => {
    setMobileNavDisplay("hidden");

    if (currentUsername && isLoggedIn()) {
      setCurrentUser(currentUsername);
      setIsLoggedInContext(true);

      if (currentWalletProvider == "anchor"){
        const nameValue = new Name(currentUsername).raw().toString();
        getCustomersData(nameValue, setWhitelistStatus);
      }

    } else {
      console.log("You are not logged in");
    }
  }, []);


  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="">
      <UALProvider
        chains={[myChain]}
        authenticators={[anchor, wax, wombat]}
        appName={"WaxDAO"}
      >
        <ModalOverlay className={enterModalDisplay} />
        <MobileModalOverlay className={mobileNavDisplay} />
        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <CloseWindow onClick={() => setEnterModalDisplay("hidden")}>
            Close Window
          </CloseWindow>
        </Modal>

        {/* <NotificationBanner uniqueId={notificationProps.uniqueId} message={notificationProps.message} />   */}
        
        <NewNavbar>        
          <MindmasterWrapper href={`${currentWebsiteURL}`}>
            <MindmasterLogo src={mindmaster_logo} />{" "}
            <WaxDAOText>WAXDAO</WaxDAOText>
          </MindmasterWrapper>

          <MindmasterMobileWrapper href={`${currentWebsiteURL}`}>
            <MindmasterMobileLogo src={mindmaster_logo} />
          </MindmasterMobileWrapper>

          {isLoggedIn() && (
              <WalletIconButtonWrapper>
              <ViewPoolButton
                onClick={() => {
                  setWalletSidebarIsOpen(false);
                  setMobileNavIsOpen((prev) => !prev);
                }}
              >
                {currentUsername}
              </ViewPoolButton>
              </WalletIconButtonWrapper>
            )}

          {!isLoggedIn() && (
              <WalletIconButtonWrapper>
              <ViewPoolButton
                  onClick={() => {
                    setWalletSidebarIsOpen(false);
                    setMobileNavIsOpen((prev) => !prev);
                  }}
              >
                Log In
              </ViewPoolButton>
              </WalletIconButtonWrapper>
            )}



          <NewUINavLinkCont
            navLocation={"Market"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <NewUINavLink href={`${currentWebsiteURL}/drops`}>
              Market
            </NewUINavLink>
          </NewUINavLinkCont>

          <NewUINavLinkCont
            navLocation={"Blends"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <NewUINavLink href={`${currentWebsiteURL}/blends`}>
              Blends
            </NewUINavLink>
          </NewUINavLinkCont>

          <NewUINavLinkCont
            navLocation={"Staking"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <NewUINavLink href={`${currentWebsiteURL}/nft-farming`}>
              Staking
            </NewUINavLink>
          </NewUINavLinkCont>

          <NewUINavLinkCont
            navLocation={"Tokens"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <NewUINavLink href={`${currentWebsiteURL}/wax-tokens`}>
              Tokens
            </NewUINavLink>
          </NewUINavLinkCont>

          <NewUINavLinkCont
            navLocation={"Create"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <NewUINavLink href={`${currentWebsiteURL}/create`}>
              Create
            </NewUINavLink>
          </NewUINavLinkCont>

          {/* MOBILE */}

          <MobileNavCont
            onClick={() => {
              setWalletSidebarIsOpen(false);
              setMobileNavIsOpen((prev) => !prev);
            }}
          >
            <MobileNavLine />
            <MobileNavLine />
            <MobileNavLine />
          </MobileNavCont>
        </NewNavbar>

        {/* MOBILE EXTENDED */}

        <SidebarOverlay className={!mobileNavIsOpen && "hidden"} />
        <MobileSidebar isOpen={mobileNavIsOpen}>
          <SidebarLinksGap>
            <CloseSidebarButton onClick={() => setMobileNavIsOpen(false)}>
              X
            </CloseSidebarButton>
          </SidebarLinksGap>

          <SidebarScrollWrapper>
          <SidebarLinkContainer
            navLocation={"Home"}
            currentNavbarLocation={currentNavbarLocation}
          >
           {isLoggedIn() ? (
           <span>
             <br/>
             <span className="text-sm">Hello,</span><br/>
             <a href={`${currentWebsiteURL}/u/${currentUsername}`} style={{color: theme.secondary, fontWeight: 900}}>{currentUsername}</a>
             <br/><br/>
           </span>) : (
             <span>
               <br/>
               <span className="text-sm">Get started!</span><br/>
               <ViewPoolButton
                onClick={() => {
                  setMobileNavDisplay("hidden");
                  setEnterModalDisplay("");
                  setEnterModalText(
                    <span>
                      <NewCloudButton
                        className="ml-auto mr-auto"
                        onClick={() => logUserIn("wax", setCurrentUser, setIsLoggedInContext, setEnterModalText, setWhitelistStatus)}
                      >
                        Login With MyCloudWallet
                      </NewCloudButton>
                      <br />
                      <NewAnchorButton
                        className="ml-auto mr-auto"
                        onClick={() => logUserIn("anchor", setCurrentUser, setIsLoggedInContext, setEnterModalText, setWhitelistStatus)}
                      >
                        Login With Anchor
                      </NewAnchorButton>
                      <br />
                      <NewAnchorButton
                        className="ml-auto mr-auto"
                        onClick={() => logUserIn("wombat", setCurrentUser, setIsLoggedInContext, setEnterModalText, setWhitelistStatus)}
                      >
                        Login With Wombat
                      </NewAnchorButton>
                      <br />                      

                    </span>
                  );
                }}  
               >
                LOG IN
               </ViewPoolButton>
               <br/><br/>

             </span>
           )}
           
          </SidebarLinkContainer>

          {isLoggedIn() && (
            <span>
              <span style={{color: theme.textMain, paddingLeft: "35px", fontSize: "10px"}}>PROFILE</span><br/>
              <SidebarLinkContainer
                  navLocation={"Wallet"}
                  currentNavbarLocation={currentNavbarLocation}
                >
                  <SidebarNavLink href={`${currentWebsiteURL}/wallet`}>
                    Inventory
                  </SidebarNavLink>
                </SidebarLinkContainer>  

                <SidebarLinkContainer
                  navLocation={"Profile"}
                  currentNavbarLocation={currentNavbarLocation}
                >
                  <SidebarNavLink href={`${currentWebsiteURL}/u/${currentUsername}`}>
                    Public Profile
                  </SidebarNavLink>
                </SidebarLinkContainer>  
                <br/><br/>
              </span>
          )}
          
          <span style={{color: theme.textMain, paddingLeft: "35px", fontSize: "10px"}}>NAVIGATION</span><br/>
          <SidebarLinkContainer
            navLocation={"Market"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/drops`}>
              Market
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"Blends"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/blends`}>
              Blends
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"Staking"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/nft-farming`}>
              Staking
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"Tokens"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/wax-tokens`}>
              Tokens
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"Create"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/create`}>
            Create
            </SidebarNavLink>
          </SidebarLinkContainer>

          <span style={{color: theme.textMain, paddingLeft: "35px", fontSize: "10px"}}>APIs</span><br/>
          <span style={{color: theme.textSecondary, paddingLeft: "50px", fontSize: "14px"}}>AtomicAssets</span><br/>
          <span style={{color: theme.textSecondary, paddingLeft: "50px", fontSize: "14px"}}>
            <select
    style={{
      width: "200px",
      backgroundColor: theme.mainDarker,
      height: "45px",
      border: "1px solid grey"
    }
  }

              onChange={(e) => {
                localStorage.setItem('userSelectedAnAtomicApi', true);
                localStorage.setItem('currentSelectedAtomicApi', e.target.value);
              }}
            >
                  <option value="" hidden>
                    Choose
                  </option>
                  {mainnetAtomicApiList.map((item, index) => (
                    <option key={index} value={item}
                      selected={item == localStorage.getItem('currentSelectedAtomicApi') && true}
                    >{item}</option>
                  ))}
            </select>
            </span><br/>
          {isLoggedIn() && (
            <SidebarLinkContainer>
              <br/>
            <ViewPoolButton
              onClick={() => logUserOut(currentWalletProvider, setCurrentUser, setIsLoggedInContext)}
            >
              LOG OUT
            </ViewPoolButton>
            </SidebarLinkContainer>
          )}
          </SidebarScrollWrapper>
        </MobileSidebar>




        {/* Large Screen Dropdown On Right Side */}

        <SidebarOverlay className={!mobileNavIsOpen && "hidden"} />
        <LargeScreenNavDropDown isOpen={mobileNavIsOpen}>
          <SidebarLinksGap>
          <CloseSidebarButton onClick={() => setMobileNavIsOpen(false)}>
              X
            </CloseSidebarButton>
          </SidebarLinksGap>

          <SidebarScrollWrapper>
          <SidebarLinkContainer
            navLocation={"Home"}
            currentNavbarLocation={currentNavbarLocation}
          >
           {isLoggedIn() ? (
           <span>
             <br/>
             <span className="text-sm">Hello,</span><br/>
             <a href={`${currentWebsiteURL}/u/${currentUsername}`} style={{color: theme.secondary, fontWeight: 900}}>{currentUsername}</a>
             <br/><br/>
           </span>) : (
             <span>
               <br/>
               <span className="text-sm">Get started!</span><br/>
               <ViewPoolButton
                onClick={() => {
                  setMobileNavDisplay("hidden");
                  setEnterModalDisplay("");
                  setEnterModalText(
                    <span>
                      <NewCloudButton
                        className="ml-auto mr-auto"
                        onClick={() => logUserIn("wax", setCurrentUser, setIsLoggedInContext, setEnterModalText, setWhitelistStatus)}
                      >
                        Login With MyCloudWallet
                      </NewCloudButton>
                      <br />
                      <NewAnchorButton
                        className="ml-auto mr-auto"
                        onClick={() => logUserIn("anchor", setCurrentUser, setIsLoggedInContext, setEnterModalText, setWhitelistStatus)}
                      >
                        Login With Anchor
                      </NewAnchorButton>
                      <br />
                      <NewAnchorButton
                        className="ml-auto mr-auto"
                        onClick={() => logUserIn("wombat", setCurrentUser, setIsLoggedInContext, setEnterModalText, setWhitelistStatus)}
                      >
                        Login With Wombat
                      </NewAnchorButton>
                      <br />                      

                    </span>
                  );
                }}  
               >
                LOG IN
               </ViewPoolButton>
               <br/><br/>

             </span>
           )}
           
          </SidebarLinkContainer>

          {isLoggedIn() && (
            <span>
              <span style={{color: theme.textMain, paddingLeft: "35px", fontSize: "10px"}}>PROFILE</span><br/>
              <SidebarLinkContainer
                  navLocation={"Wallet"}
                  currentNavbarLocation={currentNavbarLocation}
                >
                  <SidebarNavLink href={`${currentWebsiteURL}/wallet`}>
                    Inventory
                  </SidebarNavLink>
                </SidebarLinkContainer>  

                <SidebarLinkContainer
                  navLocation={"Profile"}
                  currentNavbarLocation={currentNavbarLocation}
                >
                  <SidebarNavLink href={`${currentWebsiteURL}/u/${currentUsername}`}>
                    Public Profile
                  </SidebarNavLink>
                </SidebarLinkContainer>  
                <br/><br/>
              </span>
          )}
          
          <span style={{color: theme.textMain, paddingLeft: "35px", fontSize: "10px"}}>TOOLS</span><br/>
          <SidebarLinkContainer
            navLocation={"Pack Creator"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/create-pack`}>
              Pack Creator
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"Blend Creator"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/create-blend`}>
              Blend Creator
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"Farm Creator"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/create-farm`}>
              Farm Creator
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"Tokens"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/token-creator`}>
              Token Creator
            </SidebarNavLink>
          </SidebarLinkContainer>

          <SidebarLinkContainer
            navLocation={"DAO Creator"}
            currentNavbarLocation={currentNavbarLocation}
          >
            <SidebarNavLink href={`${currentWebsiteURL}/v2/create-dao`}>
              DAO Creator
            </SidebarNavLink>
          </SidebarLinkContainer>

          <span style={{color: theme.textMain, paddingLeft: "35px", fontSize: "10px"}}>APIs</span><br/>
          <span style={{color: theme.textSecondary, paddingLeft: "50px", fontSize: "14px"}}>AtomicAssets</span><br/>
          <span style={{color: theme.textSecondary, paddingLeft: "50px", fontSize: "14px"}}>
            <select
    style={{
      width: "200px",
      backgroundColor: theme.mainDarker,
      height: "45px",
      border: "1px solid grey"
    }
  }

              onChange={(e) => {
                localStorage.setItem('userSelectedAnAtomicApi', true);
                localStorage.setItem('currentSelectedAtomicApi', e.target.value);
              }}
            >
                  <option value="" hidden>
                    Choose
                  </option>
                  {mainnetAtomicApiList.map((item, index) => (
                    <option key={index} value={item}
                      selected={item == localStorage.getItem('currentSelectedAtomicApi') && true}
                    >{item}</option>
                  ))}
            </select>
            </span><br/>          

          {isLoggedIn() && (
            <SidebarLinkContainer>
              <br/>
            <ViewPoolButton
              onClick={() => logUserOut(currentWalletProvider, setCurrentUser, setIsLoggedInContext)}
            >
              LOG OUT
            </ViewPoolButton>
            </SidebarLinkContainer>
          )}
          </SidebarScrollWrapper>
        </LargeScreenNavDropDown>




      </UALProvider>
    </div>
  );
};

export default Navbar;
