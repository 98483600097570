import { getWalletSession } from "./wallet_functions";

export const handleChoiceSelection = (e, index, selectedOption, setSelectedOption) => {
    setSelectedOption(index);
}

export const handleChoiceAdd = (setChoicesList, choicesList) => {
    setChoicesList([...choicesList, { choice: ''}])
}

export const handleChoiceRemove = (setChoicesList, choicesList, index) => {
    const list = [...choicesList];
    list.splice(index, 1);
    setChoicesList(list);
}

export const handleChoiceChange = (e, index, choicesList, setChoicesList) => {
    const {name, value} = e.target;
    const list = [...choicesList];
    list[index][name] = value;
    setChoicesList(list);
}

export const sumArray = (array, totalVoteWeight, setTotalVoteWeight, percentPerVote, choicesArray, totalVotesPerChoice, 
  setTotalVotesPerChoice, percentPerChoice, setPercentPerChoice, percentagesArray) => {
  const arrayToSum = [...array];
  var totalWeight = Number(0);

  for(var i = 0; i < array.length; i++){
    totalWeight += Number(array[i].voteweight);
    choicesArray[array[i].choice] += Number(array[i].voteweight);
  }

  setTotalVoteWeight(totalWeight);
  setTotalVotesPerChoice(choicesArray);
  percentPerVote = 100 / totalWeight;

  for(var z = 0; z < choicesArray.length; z++){
    percentagesArray[z] = choicesArray[z] * percentPerVote;
  }

  setPercentPerChoice(percentagesArray);
}

export const submitVote = async (daoName, ProposalID, selectedOption, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    let walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2];

    if(selectedOption === ''){
        setEnterModalText('You need to select an option first');
        return;
    }
  
    try {   
      const action = [{
            account: 'buildawaxdao',
            name: 'vote',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              user: this_user,
              daoname: daoName,
              proposal_id: ProposalID,
              choice: selectedOption,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your tokens have been staked');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
 
  }



  export const finalizeProposal = async (ProposalID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];

    try {   
        const action = [{
            account: 'buildawaxdao',
            name: 'finalize',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              user: this_user,
              proposal_id: ProposalID,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('The proposal has been finalized');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
 
  }