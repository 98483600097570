import { MilestoneContainer, SmallButton } from "../Styles";
import { ChoiceButton } from "../pages/daosv2/DAOStylesV2";
import { SubmitButton } from "./LeftDiv";


const ChoiceCard = ({ key, choice, selectedChoice, setSelectedChoice }) => {
    return (
      <ChoiceButton
        onClick={() => {
            setSelectedChoice(choice.choice)
        }}
        selected={selectedChoice == choice.choice ? true : false}
      >
        {choice.description}
      </ChoiceButton>
    );
};

export default ChoiceCard;
