import React, { useEffect } from 'react'
import NumberFormat from 'react-number-format';
import { useStateContext } from '../contexts/ContextProvider';
import { currentWebsiteURL } from '../data/config';
import { ESCROW_STATUSES } from '../data/constants';
import { PayerDripCardWrapper, ReceiverDetails, ReceiverIDNumber, ReceiverStatus, ReceiverTotal } from '../data/css/EscrowStyles';
import { claimDrip, getNextClaim, isClaimable } from '../data/functions/escrow_functions';
import { SmallButton } from '../Styles';
const ReceiverDripCard = (props) => {
    const item = props.item;

    const { 
        enterModalText,
        setEnterModalText,
        loadingDisplay, 
        setLoadingDisplay, 
        enterButtonsDisplay,
        setEnterButtonsDisplay,
        enterModalDisplay, 
        setEnterModalDisplay,
      } = useStateContext();

  return (
    <PayerDripCardWrapper>
        <ReceiverIDNumber>ID: {item.ID}</ReceiverIDNumber>


        <ReceiverStatus>
        Status:                     {
                      ESCROW_STATUSES[
                        ESCROW_STATUSES.findIndex(
                          (attributeIndex) =>
                            attributeIndex.value === item.status
                        )
                      ].display_text
                    }
        </ReceiverStatus>                    

        <ReceiverDetails>
        Details: {item.payout_amount}@{item.token_contract} released from <a href={`https://waxblock.io/account/${item.payer}`} target="none">{item.payer}</a>, every {item.hours_between_payouts} hours from {new Date(item.start_time * 1000).toLocaleDateString()} to {new Date(item.end_time * 1000).toLocaleDateString()}
        </ReceiverDetails>

        <ReceiverTotal>
        Total: {item.total_amount}
        </ReceiverTotal>
        <br/><br/>

        <div className='flex justify-around w-100'>
            <span className={item.status != 1 && "hidden"}>
            {isClaimable(item) ? (
                <SmallButton onClick={() => {claimDrip(item.ID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}}>
                    Claim
                </SmallButton>
            ) : (
            <SmallButton>
                Claim in {getNextClaim(item)}m
            </SmallButton>
            )}
            </span>


        </div>
    </PayerDripCardWrapper>
  )
}

export default ReceiverDripCard