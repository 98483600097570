import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import NumberFormat from 'react-number-format';
import { currentApiList, currentAtomicApiList, wax, anchor, myChain, currentWebsiteURL, wombat, getCurrentApiList, currentUsername } from '../data/config';
import { finalizeProposal, handleChoiceSelection, submitVote, sumArray } from '../data/functions/proposal_functions';
import { CloseWindow, Modal, ModalContent, Spinner, SpinnerBlue, SpinnerGreen, SpinnerRed } from '../components/LeftDiv';
import { PROPOSAL_STATUSES } from '../data/constants';

var totalWeight;
var choicesArray = Array(10).fill(0);
var percentPerVote;
var percentagesArray = Array(10).fill(0);

let zero = '0';

const Proposals = ({ location }) => {

  const { activeMenu, themeSettings, setThemeSettings, currentColor, currentMode, currentUser, 
    setCurrentUser, tokenName, setTokenName, maxSupply, setMaxSupply, decimals, setDecimals, 
    tokenString, setTokenString, farmData, setFarmData, farmCollection, setFarmCollection, farmSchema, 
    setFarmSchema, farmRewards, setFarmRewards, farmExpires, setFarmExpires, farmIcon, setFarmIcon, 
    farmContract, setFarmContract, createdBy, setCreatedBy, unstaked, setUnstaked, stakedOrUnstaked, 
    setStakedOrUnstaked, stakedDisplay, setStakedDisplay, unstakedDisplay, setUnstakedDisplay, validAssets, 
    setValidAssets, commaSeparated, setCommaSeparated, stakedAssets, setStakedAssets, claimable, 
    setClaimable, minTime, setMinTime, depositAmount, setDepositAmount, depositDisplay, setDepositDisplay, 
    totalStaked, setTotalStaked, ramBalance, setRamBalance, ramInfoDisplay, setRamInfoDisplay, farmTemplates, 
    setFarmTemplates, templatesDisplay, setTemplatesDisplay, farmType, setFarmType, nftsCoveredByRam, 
    setNftsCoveredByRam, hourlyPool, setHourlyPool, schemaDisplay, setSchemaDisplay, includeOrExcludeMessage, 
    setIncludeOrExcludeMessage, templateToView, setTemplateToView, refreshAssets, setRefreshAssets, 
    nftDaoDisplay, setNftDaoDisplay, stakeTokensDisplay, setStakeTokensDisplay, unstakeTokensDisplay, 
    setUnstakeTokensDisplay, proposalList, setProposalList, newProposalDisplay, setNewProposalDisplay, 
    newProposalText, setNewProposalText, proposalType, setProposalType, proposalTypeValue, setProposalTypeValue,
    choicesList, setChoicesList, description, setDescription, selectedOption, setSelectedOption, proposalInfo,
    setProposalInfo, daoName, setDaoName, daoInfo, setDaoInfo, stakeWeight, setStakeWeight, voteList, setVoteList,
    totalVoteWeight, setTotalVoteWeight, totalVotesPerChoice, setTotalVotesPerChoice, percentPerChoice,
    setPercentPerChoice
    } = useStateContext();


    const [enterModalDisplay, setEnterModalDisplay] = useState("hidden");
    const [enterModalText, setEnterModalText] = useState("");
    const [loadingDisplay, setLoadingDisplay] = useState("none");
    const [enterButtonsDisplay, setEnterButtonsDisplay] = useState("");


const { ProposalID } = useParams();

useEffect(() => {

  const abortCont = new AbortController();

  setProposalInfo([]);
  setDaoName('');
  setDaoInfo([]);
  setStakeWeight([]);

  axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
    table:"proposals",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    limit:1,
    lower_bound:ProposalID,
    upper_bound:ProposalID,
    json:true
  })
    .then((response) => {

      console.log(response.data.rows[0]);
      setProposalInfo(response.data.rows[0]);
      setDaoName(response.data.rows[0].daoname);

      if(response.data.rows[0].daoname != null){

        axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
          table:"daos",
          scope:"buildawaxdao",
          code:"buildawaxdao",
          limit:1,
          key_type: 'name',
          index_position: 2,
          lower_bound:response.data.rows[0].daoname,
          upper_bound:response.data.rows[0].daoname,
          json:true
        }).then((daoResponse) => {
          console.log(daoResponse)
          setDaoInfo(daoResponse.data.rows[0]);
          setDecimals(daoResponse.data.rows[0].tokensymbol.substring(0, daoResponse.data.rows[0].tokensymbol.indexOf(',')));

          if(daoResponse.data.rows[0].staketype == 'token' && currentUsername != null){
            axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
              table:"tokenpower",
              scope:"buildawaxdao",
              code:"buildawaxdao",
              limit:100,
              key_type: 'name',
              index_position: 2,
              lower_bound:currentUsername,
              upper_bound:currentUsername,
              json:true
            }).then((tokenPowerResponse) => {
              setStakeWeight(tokenPowerResponse.data.rows);
            }) //end.then for staketype = token response
            .catch((error) => console.log(error));
          } // end if staketype = token

          else if(daoResponse.data.rows[0].staketype != 'token' && daoResponse.data.rows[0].staketype != null && currentUsername != null){
            axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
              table:"nftpower",
              scope:"buildawaxdao",
              code:"buildawaxdao",
              limit:100,
              key_type: 'name',
              index_position: 2,
              lower_bound:currentUsername,
              upper_bound:currentUsername,
              json:true
            }).then((nftPowerResponse) => {
              setStakeWeight(nftPowerResponse.data.rows);
            }) //end.then for staketype != token response
            .catch((error) => console.log(error));
          }
        }) 
        .catch((error) => console.log(error));
      }
    }) //end .then for initial request to proposals table
    .catch((error) => console.log(error));
}, []);


useEffect(() => {

  const abortCont = new AbortController();

  setVoteList([]);
  setTotalVotesPerChoice([]);
  setPercentPerChoice([]);

  axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
    table:"votes",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    limit:1000,
    key_type: 'i64',
    index_position: 2,
    lower_bound:ProposalID,
    upper_bound:ProposalID,
    json:true
  }).then((voteResponse) => {
    setVoteList(voteResponse.data.rows);
    sumArray(voteResponse.data.rows, totalVoteWeight, setTotalVoteWeight, percentPerVote, choicesArray, totalVotesPerChoice, 
        setTotalVotesPerChoice, percentPerChoice, setPercentPerChoice, percentagesArray)
  })
    .catch((error) => console.log(error));

}, []);


  return (

    <div id="seo">
    <Helmet>
    <title>Proposal {ProposalID} - WaxDAO</title>
    <meta name="description" content={`Vote on proposal ${ProposalID} on WaxDao`} />
    <link rel="canonical" href={`${currentWebsiteURL}/proposals/${ProposalID}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>
    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <CloseWindow onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </CloseWindow>
      </Modal>
  <br/><br/><br/>






{proposalInfo != null && (
  
<span>
  <div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">
  <h2 className="text-lg">{proposalInfo.title != null && proposalInfo.title} {daoInfo?.daoname != null && (
    
   <span className="p-1 pl-2 pr-2 text-sm border-1 rounded-2xl font-semibold"
      style={{background: '#F8F8FF', color:'#696969'}}
   ><a href={`${currentWebsiteURL}/daos/${daoInfo.daoname}`}>in {daoInfo.daoname}</a></span> 
  
  )}</h2>
  
<div className="flex flex-wrap gap-2 mt-2" style={{justifyContent:'right', marginBottom:'10px'}}>

{proposalInfo.status != null && (
  <div className="inline-block p-1 pl-2 pr-2 text-sm border-1 rounded-2xl">
  {                PROPOSAL_STATUSES[
                  PROPOSAL_STATUSES.findIndex(
                    (attributeIndex) => attributeIndex.value === proposalInfo.status
                  )
                ].display_text}
  </div>
)}

{proposalInfo.ID != null && (
  <div className="inline-block p-1 pl-2 pr-2 text-sm border-1 rounded-2xl">
  ID #{proposalInfo.ID}
  </div>
)}

{proposalInfo.end_time != null && (
  <div className="inline-block p-1 pl-2 pr-2 text-sm border-1 rounded-2xl">

  {new Date(proposalInfo.end_time) > 
  Math.round(Date.now() / 1000) && (

    <span>Ends {new Date(proposalInfo.end_time * 1000).toLocaleDateString()}</span>

  )}

  {new Date(proposalInfo.end_time) <= 
  Math.round(Date.now() / 1000) && (

    <span>Ended {new Date(proposalInfo.end_time * 1000).toLocaleDateString()}</span>

  )}  


  </div>
)}




</div>

<div className="p-2 text-sm rounded-sm"
style={{backgroundColor:'#F8F8FF'}}
>

<div className="p-2 text-md inline-block">

  <span className="font-semibold" style={{color:'#696969'}}>TOTAL VOTERS:</span> <br/>
  <span className="font-bold">{voteList.length}</span>

</div>

<div className="p-2 text-md inline-block ml-10">

<span className="font-semibold" style={{color:'#696969'}}>TOTAL STAKED:</span> <br/>
<span className="font-bold">
  { totalVoteWeight != null && decimals != null && daoInfo != null && daoInfo.staketype != "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={totalVoteWeight / Math.pow(10, decimals) }  />}
  { totalVoteWeight != null && daoInfo != null && daoInfo.staketype == "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={totalVoteWeight}  />}
</span>
  

</div>

</div>


{proposalInfo.proposal_type == "tkn.transfer" && (
<p className="text-2xl font-bold mt-3">Transfer {proposalInfo.amount} from treasury to {proposalInfo.receivers[0]}</p>
)}

<div className="description mt-2">
<p className="font-semibold">Description:</p>
{proposalInfo.description != null && proposalInfo.description}


</div>




</div>

<div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">

<h2 className="text-lg mt-2">Choices</h2>
<br/>

<div className="items-center text-center">

<form>
{proposalInfo.proposal_type == "standard" && proposalInfo.choices.length > 0 ?  proposalInfo.choices.map((item, index) => (

<div className="inline-block" style={{width:'40%', margin:'5%', backgroundColor:'#F8F8FF', verticalAlign:'top', paddingBottom:'10px', boxSizing:'border-box'}}>

<div className="font-semibold p-3" style={{textAlign:'left', boxSizing:'border-box', width:'100%'}}>
<input type="radio" value={index} name="radioName" onChange={(e) => {handleChoiceSelection(e, index, selectedOption, setSelectedOption)}} /> {item}
</div> 

{/* SHOW THE TOTAL VOTES FOR THIS CHOICE */}

<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', boxSizing:'border-box'}}>
   <div className="text-md inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;
   {totalVotesPerChoice != null && daoInfo != null && daoInfo.staketype != "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={totalVotesPerChoice[index] / Math.pow(10, decimals) }  />}
   {totalVotesPerChoice != null && daoInfo != null && daoInfo.staketype == "nftfarm" &&<NumberFormat displayType='text' thousandSeparator={true} value={totalVotesPerChoice[index]}  />}
   
   </div>
    
   <div className="text-md inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>{percentPerChoice != null && voteList.length > 0 && Math.round(percentPerChoice[index], 2)}{percentPerChoice != null && voteList.length == 0 && 0}%&nbsp;&nbsp;</div>

    
</div>


{/* ADDRESS / VOTE TITLES */}

<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', paddingTop:'10px', boxSizing:'border-box'}}>
   
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;ADDRESS</div>
    
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>VOTE&nbsp;&nbsp;</div>

    
</div>

{/* ADDRESS / VOTE DETAILS */}

{voteList != null ? voteList.sort((a, b) => a.voteweight > b.voteweight ? 1:+1).map((vote, vote_index) => ( 
   <>
   
   
   {vote.choice == index && (
<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', boxSizing:'border-box'}}>
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;{vote.voter}</div>
    
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>
     {vote.voteweight != null && daoInfo != null && daoInfo.staketype != "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={vote.voteweight / Math.pow(10, decimals) }  />}
     {vote.voteweight != null && daoInfo != null && daoInfo.staketype == "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={vote.voteweight}  />}
   
   &nbsp;&nbsp;</div>

    
</div>
   )} 
   </>
)) : ''}


</div>



)
) : ''}
</form>




<form>
{proposalInfo.proposal_type == "tkn.transfer" && proposalInfo.choices.length > 0 ?  proposalInfo.choices.map((item, index) => (

<div className="inline-block" style={{width:'40%', margin:'5%', backgroundColor:'#F8F8FF', verticalAlign:'top', paddingBottom:'10px', boxSizing:'border-box'}}>

<div className="font-semibold p-3" style={{textAlign:'left', boxSizing:'border-box', width:'100%'}}>
<input type="radio" value={index} name="radioName" onChange={(e) => {handleChoiceSelection(e, index, selectedOption, setSelectedOption)}} /> {item}
</div> 

{/* SHOW THE TOTAL VOTES FOR THIS CHOICE */}

<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', boxSizing:'border-box'}}>
   <div className="text-md inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;
   
   {totalVotesPerChoice != null && daoInfo != null && daoInfo.staketype != "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={totalVotesPerChoice[index] / Math.pow(10, decimals) }  />}
   {totalVotesPerChoice != null && daoInfo != null && daoInfo.staketype == "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={totalVotesPerChoice[index]}  />}
   
   </div>
    
   <div className="text-md inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>{percentPerChoice != null && voteList.length > 0 && Math.round(percentPerChoice[index], 2)}{percentPerChoice != null && voteList.length == 0 && 0}%&nbsp;&nbsp;</div>

    
</div>


{/* ADDRESS / VOTE TITLES */}

<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', paddingTop:'10px', boxSizing:'border-box'}}>
   
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;ADDRESS</div>
    
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>VOTE&nbsp;&nbsp;</div>

    
</div>

{/* ADDRESS / VOTE DETAILS */}

{voteList != null ? voteList.sort((a, b) => a.voteweight > b.voteweight ? 1:+1).map((vote, vote_index) => ( 
   <>
   
   
   {vote.choice == index && (
<div style={{width:'90%', marginLeft:'auto', marginRight:'auto', color:'#696969', height:'20px', borderBottom:'1px solid #696969', paddingBottom:'30px', boxSizing:'border-box'}}>
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'left'}}>&nbsp;&nbsp;{vote.voter}</div>
    
   <div className="text-sm inline-block font-semibold" style={{width:'50%', textAlign:'right'}}>
     {vote.voteweight != null && daoInfo != null && daoInfo.staketype != "nftfarm" && <NumberFormat displayType='text' thousandSeparator={true} value={vote.voteweight / Math.pow(10, decimals) }  />}
     {vote.voteweight != null && daoInfo != null && daoInfo.staketype == "nftfarm" &&  <NumberFormat displayType='text' thousandSeparator={true} value={vote.voteweight}  />}
     
     &nbsp;&nbsp;</div>

    
</div>
   )} 
   </>
)) : ''}


</div>



)
) : ''}
</form>


<button 
        type="button"
        className="text-lg p-3
        hover:drop-shadow-xl
        hover:bg-light-gray
        text-white rounded-lg mt-4"
      onClick={() => {
          submitVote(daoName, ProposalID, selectedOption, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay);
      }}
        style={{ background: currentColor, width:'60%'
        }}
      
      >
        Submit Vote
      </button>

{proposalInfo.end_time != null && 
  proposalInfo.end_time > 
  Math.round(Date.now() / 1000) && (

    <button 
    type="button"
    className="text-lg p-3
    hover:drop-shadow-xl
    hover:bg-light-gray
    text-white rounded-lg mt-4"
    disabled
    style={{ background: '#DCDCDC', width:'60%', color: '#FFFAFA'
    }}
  
  >
    Finalize Proposal
  </button>

  )}


{proposalInfo.end_time != null && 
  proposalInfo.end_time <= 
  Math.round(Date.now() / 1000) && 
  proposalInfo.status == "finalized" &&
  
  (

    <button 
    type="button"
    className="text-lg p-3
    hover:drop-shadow-xl
    hover:bg-light-gray
    text-white rounded-lg mt-4"
    disabled
    style={{ background: '#DCDCDC', width:'60%', color: '#FFFAFA'
    }}
  >
    Finalized
  </button>

  )}


{proposalInfo.end_time != null && 
  proposalInfo.end_time <= 
  Math.round(Date.now() / 1000) &&
  proposalInfo.status != "finalized" &&
  (

    <button 
    type="button"
    className="text-lg p-3
    hover:drop-shadow-xl
    hover:bg-light-gray
    text-white rounded-lg mt-4"
    onClick={() => {
      finalizeProposal(ProposalID, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay);
  }}
    style={{ background: currentColor, width:'60%'
    }}
  
  >
    Finalize Proposal
  </button>

  )}
  <br/>
      </div>
</div>



</span>

)} 

{/* END IF PROPOSAL ID EXISTS */}

{proposalInfo == null && (

<span>


<div className="m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl">

<p>This proposal ID does not exist</p>

</div>
</span>

)}





    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default Proposals