import { useEffect, useState } from "react";
import { useGetFarmsByCreator } from "../../components/CustomHooks/useGetFarmsByCreator";
import { useGetStakedOnlyFarms } from "../../components/CustomHooks/useGetStakedOnlyFarms";
import LoadingDiv from "../../components/LoadingDiv";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentUsername, currentWebsiteURL } from "../../data/config";
import { resizerPrefix, resizerSuffix } from "../../data/constants";
import { ClaimInfo, TokenCard } from "../../data/css/WalletStyles";

import {
  SmallButton,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";

export const showManageEscrowSection = (currentToolsSection) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();

  useEffect(() => {
    //   if (currentToolsSection == "Escrow") {
    //
    //   }
  }, [currentToolsSection]);

  return (
    <span className={currentToolsSection != "Escrow" && "hidden"}>
      <TallFiltersCont>
        <h2>Escrow Manager</h2>
        <br />
        <TallFiltersInnerCont>
          This section is coming soon, but for now, you can manage your escrow
          deals on the{" "}
          <a href={`${currentWebsiteURL}/manage-escrow`}>Manage Escrow Section</a> of WaxDAO.
        </TallFiltersInnerCont>
      </TallFiltersCont>
    </span>
  );
};
