import { useEffect, useState } from "react";
import { useGetFarmsByCreator } from "../../components/CustomHooks/useGetFarmsByCreator";
import { useGetPoolsByCreator } from "../../components/CustomHooks/useGetPoolsByCreator";
import { useGetStakedOnlyFarms } from "../../components/CustomHooks/useGetStakedOnlyFarms";
import LoadingDiv from "../../components/LoadingDiv";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentUsername, currentWebsiteURL } from "../../data/config";
import { resizerPrefix, resizerSuffix } from "../../data/constants";
import { ClaimInfo, TokenCard } from "../../data/css/WalletStyles";

import { SmallButton } from "../../Styles";

export const showManagePoolsSection = (currentToolsSection) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();

  const [poolsByCreator, getPoolsByCreator, creatorPoolsAreLoading] =
    useGetPoolsByCreator(currentUsername);
  const [showPools, setShowPools] = useState(false);

  useEffect(() => {
    if (currentToolsSection == "Manage Pools") {
      getPoolsByCreator(currentUsername, setShowPools);
    }
  }, [currentToolsSection]);

  return (
    <span className={currentToolsSection != "Manage Pools" && "hidden"}>
      {!creatorPoolsAreLoading && (
        <span>
          {poolsByCreator?.length > 0
            ? poolsByCreator.map((item, index) => (
                <span key={index}>
                  <TokenCard>
                    <a href={`${currentWebsiteURL}/pool/${item.poolname}`}>
                      <img
                        src={`${resizerPrefix}${item.logo}${resizerSuffix}`}
                        onError={(e) =>
                          (e.target.onerror = null)(
                            (e.target.src =
                              "https://logos.waxdao.io/default-logo.png")
                          )
                        }
                      />
                    </a>
                    <ClaimInfo>
                      {item.poolname}
                        <a href={`${currentWebsiteURL}/edit-pool/${item.poolname}`}>
                      <SmallButton>Edit Pool</SmallButton>
                      </a>
                    </ClaimInfo>
                  </TokenCard>
                </span>
              ))
            : (
              <div className="w-100 text-center pt-3">No pools to show</div>
            )}
        </span>
      )}
      {creatorPoolsAreLoading && <LoadingDiv />}
    </span>
  );
};
