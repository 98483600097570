import React from 'react'
import { currentWebsiteURL } from '../data/config';
import { AttributeModalInnerWrapper, AttributeModalWrapper, AttrModalKey, AttrModalName, AttrModalRow } from '../data/css/PremiintStyles';
import { MainButton, SmallButton } from '../Styles';

import { GameButton, Modal, ModalContent } from './LeftDiv'

const StakeableFarmsModal = (props) => {
    const showFarmsModal = props.showFarmsModal;
    const setShowFarmsModal = props.setShowFarmsModal;
    const farms = props.farms;
    const possibleFarms = props.possibleFarms;
  return (
    <AttributeModalWrapper className={showFarmsModal}>
        <AttributeModalInnerWrapper>
            <ModalContent>
            {farms != null && farms.length > 0 && (
                <span>

                <AttrModalName>
                    Stake This NFT In These Farms:
                </AttrModalName>
             

                {farms.length > 0 ? farms.map((item, index) => 
                    <div className='mt-1' key={index}>
           
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href={`${currentWebsiteURL}/farm/${item}`}>
                        <SmallButton>
                        {item}
                        </SmallButton>
                        </a>
                      
                    </div>   
                ) : (<AttrModalRow>No farms to show</AttrModalRow>)}
                        


                </span>
            )}

            {possibleFarms != null && possibleFarms.length > 0 && (
                <span>
                    <br/>
                <p className='flex flex-wrap text-sm font-semibold'>
                    This collection is stakeable in the following farms, but you'll have to check the farm page to find out if this template can be staked there:
                </p>
                <br/>

                {possibleFarms.length > 0 ? possibleFarms.map((item, index) => 
                    <div className='mt-1' key={index}>
           
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a href={`${currentWebsiteURL}/farm/${item}`}>
                        <SmallButton>
                        {item}
                        </SmallButton>
                        </a>
                      
                    </div>   
                ) : (<AttrModalRow>No farms to show</AttrModalRow>)}
                        


                </span>
            )}
            
        </ModalContent>
      </AttributeModalInnerWrapper>
      <br/>
      <GameButton onClick={() => setShowFarmsModal(false) }>
        Close Window
      </GameButton>
      </AttributeModalWrapper>
  )
}

export default StakeableFarmsModal