import {
  FoldersContainer,
  FoldersRow,
  MainButton,
  SingleFolderThird,
  SmallButton,
  TallFiltersCont,
  theme,
} from "../../Styles";
import LoadingDiv from "../../components/LoadingDiv";
import OpenFarmModal from "../../components/OpenFarmModal";
import { currentWebsiteURL, v2FarmContract } from "../../data/config";
import { V2_FARM_STATUSES } from "../../data/constants";
import { buildCollectionUrl, buildSchemaUrl, buildTemplateUrl } from "../../data/functions/global_functions";
import { getWalletSession } from "../../data/functions/wallet_functions";
import { Table, TableData, TableHeader, TableRow } from "../daosv2/DAOStylesV2";


export const handleAddRewardToken = (setRewardTokens, rewardTokens) => {
  setRewardTokens([
    ...rewardTokens,
    { token_name: "", precision: "", contract: "" },
  ]);
};

export const handleRewardTokenValueChange = (
  e,
  index,
  rewardTokens,
  setRewardTokens
) => {
  const { name, value } = e.target;
  const list = [...rewardTokens];

  if (name == "token_name") {
    list[index][name] = value.toUpperCase();
  } else if (name == "precision") {
    if (isNaN(+value)) {
      return;
    }
    if (value === "") {
      list[index][name] = value;
    } else {
      list[index][name] = parseFloat(value);
    }
  } else if (name == "contract") {
    list[index][name] = value.toLowerCase();
  }

  setRewardTokens(list);
  console.log("Reward Tokens:");
  console.log(list);
};

export const handleRemoveRewardToken = (
  setRewardTokens,
  rewardTokens,
  index
) => {
  const list = [...rewardTokens];
  list.splice(index, 1);
  setRewardTokens(list);
};

export const handleProfileValueChange = (e, profile, setProfile) => {
  const { name, value } = e.target;
  const updatedProfile = { ...profile, [name]: value };
  setProfile(updatedProfile);
};

export const handleRewardAmountValueChange = (
  e,
  index,
  rewardAmounts,
  setRewardAmounts
) => {
  const { name, value } = e.target;
  const updated = [...rewardAmounts];
  updated[index][name] = value;
  setRewardAmounts(updated);
};

export const createFarmsDotWaxDaoFarm = async (
  farmName,
  avatar,
  coverImage,
  description,
  rewardTokens,
  selectedAsset,
  paymentMethod,
  website,
  telegram,
  discord,
  twitter,
  atomichub,
  waxdao,
  medium,
  youtube,
  farmType,
  hoursBetweenPayouts,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let assertAction = {
    account: v2FarmContract,
    name: "assertpoint",
    authorization: [
      {
        actor: this_user,
        permission: this_permission,
      },
    ],
    data: {
      user: this_user,
    },
  };

  let socials = {
    website: website,
    telegram: telegram,
    discord: discord,
    twitter: twitter,
    atomichub: atomichub,
    waxdao: waxdao,
    medium: medium,
    youtube: youtube,
  };

  let profile = {
    avatar: avatar,
    cover_image: coverImage,
    description: description,
  };

  let rwd_tokens = [];

  for (let tkn of rewardTokens) {
    let sym = Number(tkn.precision) + "," + tkn.token_name;
    rwd_tokens.push({ token_symbol: sym, contract: tkn.contract });
  }

  let paymentAction;

  if (paymentMethod == "Pay 250 Wax") {
    paymentAction = {
      account: "eosio.token",
      name: "transfer",
      authorization: [
        {
          actor: this_user,
          permission: this_permission,
        },
      ],
      data: {
        from: this_user,
        to: v2FarmContract,
        quantity: "250.00000000 WAX",
        memo: "|create_farm|",
      },
    };
  } else if (paymentMethod == "Pay 25,000 WAXDAO") {
    paymentAction = {
      account: "mdcryptonfts",
      name: "transfer",
      authorization: [
        {
          actor: this_user,
          permission: this_permission,
        },
      ],
      data: {
        from: this_user,
        to: v2FarmContract,
        quantity: "25000.00000000 WAXDAO",
        memo: "|create_farm|",
      },
    };
  } else if (paymentMethod == "Burn 1 Wojak NFT") {
    paymentAction = {
      account: "atomicassets",
      name: "transfer",
      authorization: [
        {
          actor: this_user,
          permission: this_permission,
        },
      ],
      data: {
        from: this_user,
        to: v2FarmContract,
        asset_ids: [selectedAsset],
        memo: "|create_farm|",
      },
    };
  }

  try {
    const action = [
      assertAction,
      paymentAction,
      {
        account: v2FarmContract,
        name: "createfarm",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farmname: farmName,
          reward_tokens: rwd_tokens,
          profile: profile,
          socials: socials,
          hours_between_payouts: hoursBetweenPayouts,
          farm_type: farmType,
        },
      },
    ];
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Creating your farm...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText(
        <span>
          Your farm has been created. Manage it at the{" "}
          <a
            style={{ color: theme.secondary }}
            href={`${currentWebsiteURL}/v2/edit-farm/${farmName}`}
          >
            edit-farm page
          </a>
        </span>
      );
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};

export const showEditFarmProfile = (
  farm_name,
  farmProfile,
  setFarmProfile,
  farmSocials,
  setFarmSocials,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  return (
    <span>
      <h3>Cover Image</h3>
      <input
        value={farmProfile.cover_image}
        name="cover_image"
        onChange={(e) => {
          handleProfileValueChange(e, farmProfile, setFarmProfile);
        }}
      />
      <br />
      <br />

      <h3>Avatar</h3>
      <input
        value={farmProfile.avatar}
        name="avatar"
        onChange={(e) => {
          handleProfileValueChange(e, farmProfile, setFarmProfile);
        }}
      />
      <br />
      <br />

      <h3>Description</h3>
      <textarea
        value={farmProfile.description}
        name="description"
        onChange={(e) => {
          handleProfileValueChange(e, farmProfile, setFarmProfile);
        }}
      />
      <br />

      <TallFiltersCont>
        <h2>Socials</h2>
      </TallFiltersCont>
      <br />
      <br />

      <h3>Website</h3>
      <input
        value={farmSocials.website}
        name="website"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>Twitter</h3>
      <input
        value={farmSocials.twitter}
        name="twitter"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>Telegram</h3>
      <input
        value={farmSocials.telegram}
        name="telegram"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>Discord</h3>
      <input
        value={farmSocials.discord}
        name="discord"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>Medium</h3>
      <input
        value={farmSocials.medium}
        name="medium"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>YouTube</h3>
      <input
        value={farmSocials.youtube}
        name="youtube"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>AtomicHub</h3>
      <input
        value={farmSocials.atomichub}
        name="atomichub"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <h3>WaxDAO</h3>
      <input
        value={farmSocials.waxdao}
        name="waxdao"
        onChange={(e) => {
          handleProfileValueChange(e, farmSocials, setFarmSocials);
        }}
      />
      <br />
      <br />

      <div className="w-100 text-center items-center justify-center">
        <MainButton
          onClick={() => {
            saveProfile(
              farm_name,
              farmProfile,
              farmSocials,
              setEnterModalText,
              setLoadingDisplay,
              setEnterButtonsDisplay,
              setEnterModalDisplay
            );
          }}
        >
          Save Profile
        </MainButton>
      </div>
    </span>
  );
};

const saveProfile = async (
  farm_name,
  farmProfile,
  farmSocials,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  const action = [
    {
      account: v2FarmContract,
      name: "setprofile",
      authorization: [
        {
          actor: this_user,
          permission: this_permission,
        },
      ],
      data: {
        user: this_user,
        farmname: farm_name,
        profile: farmProfile,
        socials: farmSocials,
      },
    },
  ];

  try {
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Saving your profile...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText(<span>Your profile has been saved</span>);
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};

export const showEditFarmStatus = (
  farmData,
  showOpenFarmModal,
  setShowOpenFarmModal,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay,
  openOrExtend,
  setOpenOrExtend,
  setEnterButtonsDisplay
) => {
  return (
    <span>
      <OpenFarmModal
        showOpenFarmModal={showOpenFarmModal}
        setShowOpenFarmModal={setShowOpenFarmModal}
        farmName={farmData.farmname}
        setEnterModalDisplay={setEnterModalDisplay}
        setEnterModalText={setEnterModalText}
        setLoadingDisplay={setLoadingDisplay}
        openOrExtend={openOrExtend}
        setEnterButtonsDisplay={setEnterButtonsDisplay}
      />

      <Table>
        <tbody>
          <TableRow>
            <TableHeader>Status</TableHeader>
            <TableData>
              {
                V2_FARM_STATUSES[
                  V2_FARM_STATUSES.findIndex(
                    (attributeIndex) => attributeIndex.value === farmData.status
                  )
                ].display_text
              }
              {farmData.status == 0 && (
                <span>
                  {"  "}
                  <SmallButton
                    onClick={() => {
                      setOpenOrExtend("Open");
                      setShowOpenFarmModal(true);
                    }}
                  >
                    Open Farm
                  </SmallButton>
                </span>
              )}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Expires</TableHeader>
            <TableData>
              {new Date(farmData.expiration * 1000).toLocaleDateString()}{" "}
              <SmallButton
                onClick={() => {
                  setOpenOrExtend("Extend");
                  setShowOpenFarmModal(true);
                }}
              >
                Extend Farm
              </SmallButton>
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>NFTs Staked</TableHeader>
            <TableData>{farmData.total_staked}</TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Payout Frequency</TableHeader>
            <TableData>
              {farmData.hours_between_payouts} Hour
              {farmData.hours_between_payouts > 1 && "s"}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Reward Pools</TableHeader>
            <TableData>
              {farmData.reward_pools.map((item, index) => (
                <span>
                  {item.total_funds}
                  <br />
                </span>
              ))}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Rewards Per Payout</TableHeader>
            <TableData>
              {farmData.reward_pools.map((item, index) => (
                <span>
                  {item.total_hourly_reward}
                  <br />
                </span>
              ))}
            </TableData>
          </TableRow>
        </tbody>
      </Table>
    </span>
  );
};

export const showEditFarmRewards = (
  farmData,
  currentRewardTab,
  setCurrentRewardTab,
  stakeables,
  stakeablesAreLoading,
  currentStakeablesTab,
  setCurrentStakeablesTab,
  collectionToAdd,
  setCollectionToAdd,
  schemaToAdd,
  setSchemaToAdd,
  amountsToAdd,
  setAmountsToAdd,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay,
  amountsToDeposit,
  setAmountsToDeposit
) => {
  return (
    <span>
      <FoldersContainer>
        <FoldersRow>
          <SingleFolderThird
            selected={currentRewardTab == "Stakeables"}
            onClick={() => {
              setCurrentRewardTab("Stakeables");
            }}
          >
            Stakeables
          </SingleFolderThird>

          <SingleFolderThird
            selected={currentRewardTab == "Reward Pools"}
            onClick={() => {
              setCurrentRewardTab("Reward Pools");
            }}
          >
            Reward Pools
          </SingleFolderThird>
        </FoldersRow>
      </FoldersContainer>

      {currentRewardTab == "Stakeables" && (
        <span>
          <FoldersContainer>
            <FoldersRow>
              <SingleFolderThird
                selected={currentStakeablesTab == "View"}
                onClick={() => {
                  setCurrentStakeablesTab("View");
                }}
              >
                View
              </SingleFolderThird>

              <SingleFolderThird
                selected={currentStakeablesTab == "Add"}
                onClick={() => {
                  setCurrentStakeablesTab("Add");
                }}
              >
                Add
              </SingleFolderThird>
            </FoldersRow>
          </FoldersContainer>

          {currentStakeablesTab == "View" && (
            <span>
              {stakeablesAreLoading && <LoadingDiv />}

              {!stakeablesAreLoading && (
                <span>
                  {stakeables.length == 0 && (
                    <div>
                      <br />
                      Nothing to show
                    </div>
                  )}
                  {stakeables.length > 0 && (
                    <span>
                      <br />
                      <Table>
                        <tbody>
                          {stakeables.map((item, index) => (
                            <TableRow key={index}>
                              <TableHeader>
                                {farmData.farm_type == 0 &&
                                  item.collection_name}
                                {farmData.farm_type == 1 && item.schema_name}
                                {farmData.farm_type == 2 && item.template_id}
                              </TableHeader>
                              <TableData>
                                {item.reward_values.map((rwdItem, rwdIndex) => (
                                  <span key={rwdIndex}>
                                    {rwdItem.quantity}
                                    <br />
                                  </span>
                                ))}
                              </TableData>
                            </TableRow>
                          ))}
                        </tbody>
                      </Table>
                    </span>
                  )}
                </span>
              )}
            </span>
          )}

          {currentStakeablesTab == "Add" && (
            <span>
              <TallFiltersCont>
                <h2>Asset Info</h2>
              </TallFiltersCont>
              <br />
              <br />
              <h3>Collection Name</h3>
              <input
                placeholder="e.g. hoodpunknfts"
                value={collectionToAdd}
                maxLength={13}
                onChange={(e) => {
                  setCollectionToAdd(e.target.value.toLowerCase());
                }}
              />
              <br />
              <br />

              {(farmData.farm_type == 1 || farmData.farm_type == 3) && (
                <span>
                  <h3>Schema Name</h3>
                  <input />
                  <br />
                  <br />
                </span>
              )}

              <TallFiltersCont>
                <h2>Reward Values</h2>
              </TallFiltersCont>

              {amountsToAdd?.length > 0 &&
                amountsToAdd.map((item, index) => (
                  <span key={index}>
                    <br />
                    <br />
                    <h3>{item.symbol} Per Payout</h3>
                    <input
                      name="amount"
                      value={item.amount}
                      onChange={(e) => {
                        handleRewardAmountValueChange(
                          e,
                          index,
                          amountsToAdd,
                          setAmountsToAdd
                        );
                      }}
                    />
                  </span>
                ))}
              <br />
              <br />
              <MainButton
                onClick={() => {
                  setRewardValues(
                    farmData.farmname,
                    farmData.farm_type,
                    amountsToAdd,
                    collectionToAdd,
                    schemaToAdd,
                    "",
                    "",
                    setEnterModalText,
                    setLoadingDisplay,
                    setEnterButtonsDisplay,
                    setEnterModalDisplay
                  );
                }}
              >
                Set Values
              </MainButton>
            </span>
          )}
        </span>
      )}

      {currentRewardTab == "Reward Pools" && (
        <span>
          <br />
          {amountsToDeposit.length > 0 &&
            amountsToDeposit.map((item, index) => (
              <span key={index}>
                <h3>{item.symbol} Amount</h3>
                <input
                  name="amount"
                  value={item.amount}
                  onChange={(e) => {
                    handleRewardAmountValueChange(
                      e,
                      index,
                      amountsToDeposit,
                      setAmountsToDeposit
                    );
                  }}
                />
                <br />
                <br />
              </span>
            ))}
          <MainButton
            onClick={() => {
              depositRewards(
                farmData.farmname,
                amountsToDeposit,
                setEnterModalText,
                setLoadingDisplay,
                setEnterButtonsDisplay,
                setEnterModalDisplay
              );
            }}
          >
            Deposit Tokens
          </MainButton>
        </span>
      )}
    </span>
  );
};

const setRewardValues = async (
  farm_name,
  farm_type,
  amountsToAdd,
  collection_name,
  schema_name,
  template_id,
  attributes,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let action;
  let values;
  let fixed_amounts = [];

  if (farm_type == 0) {
    for (let a of amountsToAdd) {
      if (a.amount > 0) {
        fixed_amounts.push({
          quantity:
            Number(a.amount).toFixed(Number(a.precision)) + " " + a.symbol,
          contract: a.contract,
        });
      }
    }

    values = [
      { collection_name: collection_name, hourly_rewards: fixed_amounts },
    ];

    action = [
      {
        account: v2FarmContract,
        name: "setcolvalues",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farmname: farm_name,
          values: values,
        },
      },
    ];
  }

  try {
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Adding stakeable asset...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText("This asset has been added");
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};

export const setFarmExpiration = async (
  farm_name,
  openOrExtend,
  expiration,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let action_name = openOrExtend == "Open" ? "openfarm" : "extendfarm";

  const action = [
    {
      account: v2FarmContract,
      name: action_name,
      authorization: [
        {
          actor: this_user,
          permission: this_permission,
        },
      ],
      data: {
        user: this_user,
        farmname: farm_name,
        expiration: expiration,
      },
    },
  ];

  try {
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Extending your farm...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText("Expiration date has been set");
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};

const depositRewards = async (
  farm_name,
  amountsToDeposit,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let action = [];

  for (let a of amountsToDeposit) {
    if (a.amount > 0) {
      action.push({
        account: a.contract,
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          from: this_user,
          to: v2FarmContract,
          quantity:
            Number(a.amount).toFixed(Number(a.precision)) + " " + a.symbol,
          memo: "|farm_deposit|" + farm_name + "|",
        },
      });
    }
  }

  try {
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Depositing your tokens...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText("Your funds were added to the farm");
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};

export const isInUse = (asset_id, listing_index, assetVector, whitelistVector, currentVector, stakedAssets) => {
  const isInOtherVector = currentVector === "asset" ? whitelistVector.some(asset => asset.asset_id === asset_id) : assetVector.some(asset => asset.asset_id === asset_id)

  const hasDifferentListingIndexInSameVector = currentVector === "asset" ? assetVector.some(
      asset => asset.asset_id === asset_id && asset.listing_index !== listing_index
  ) :  whitelistVector.some(
      asset => asset.asset_id === asset_id && asset.listing_index !== listing_index
  )

  const isStakedAlready = stakedAssets.indexOf(asset_id) > -1;

  return hasDifferentListingIndexInSameVector || isInOtherVector || isStakedAlready;
};

export const handleRemoveAsset = (assetObject, assetVector, setAssetVector) => {
  const index = assetVector.findIndex(
      asset => asset.asset_id === assetObject.asset_id && asset.listing_index === assetObject.listing_index
  );

  console.log("index: " + index)
  if (index !== -1) {
      const assetList = [...assetVector]; // clone the vector to a new array
      assetList.splice(index, 1); // remove the asset
      setAssetVector(assetList); // update state
  }
}

export const handleAddAsset = (assetObject, assetVector, setAssetVector) => {
  setAssetVector([...assetVector, assetObject])
}

export const showFarmStatus = (
  farmData
) => {
  return (
    <span>

      <Table>
        <tbody>
          <TableRow>
            <TableHeader>Status</TableHeader>
            <TableData>
              {
                V2_FARM_STATUSES[
                  V2_FARM_STATUSES.findIndex(
                    (attributeIndex) => attributeIndex.value === farmData.status
                  )
                ].display_text
              }
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Expires</TableHeader>
            <TableData>
              {new Date(farmData.expiration * 1000).toLocaleDateString()}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>NFTs Staked</TableHeader>
            <TableData>{farmData.total_staked}</TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Payout Frequency</TableHeader>
            <TableData>
              {farmData.hours_between_payouts} Hour
              {farmData.hours_between_payouts > 1 && "s"}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Reward Pools</TableHeader>
            <TableData>
              {farmData.reward_pools.map((item, index) => (
                <span>
                  {item.total_funds}
                  <br />
                </span>
              ))}
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Rewards Per Payout</TableHeader>
            <TableData>
              {farmData.reward_pools.map((item, index) => (
                <span>
                  {item.total_hourly_reward}
                  <br />
                </span>
              ))}
            </TableData>
          </TableRow>
        </tbody>
      </Table>
    </span>
  );
};


export const stakeV2 = async (
  farmData,
  assetVector,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let assets = [];
  if(farmData.farm_type != 3){
    for(let a of assetVector){
      assets.push(a.asset_id)
    }
  }

  let action = [{
        account: v2FarmContract,
        name: "stakenfts",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farmname: farmData.farmname,
          assets_to_stake: assets

        },
      }]
  

  try {
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Staking your NFTs...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText("Your NFTs have been staked");
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};

export const showNftIngredientTitle = (farmData, ingredient) => {
  let i = ingredient;

  if(farmData.farm_type === 0){ //collection
      return <TallFiltersCont>
          <h2>Any NFT from <a href={buildCollectionUrl(i.collection_name)} target="none">{i.collection_name}</a> collection</h2>
      </TallFiltersCont>
  }

  else if(farmData.farm_type === 1){ //schema
    return <TallFiltersCont>
        <h2>{i.quantity} NFT from <a href={buildCollectionUrl(i.collection_name)} target="none">{i.collection_name}</a> collection, using <a href={buildSchemaUrl(i.collection_name, i.schema_name)} target="none">{i.schema_name}</a> schema</h2>
    </TallFiltersCont>
}    

else if(farmData.farm_type === 2){ //template
  return <TallFiltersCont>
      <h2>{i.quantity} NFT from <a href={buildCollectionUrl(i.collection_name)} target="none">{i.collection_name}</a> collection, using template ID #<a href={buildTemplateUrl(i.collection_name, i.template_id)} target="none">{i.template_id}</a></h2>
  </TallFiltersCont>
}      
}

export const claimV2 = async (
  farmname,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let action = [{
        account: v2FarmContract,
        name: "claim",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farmname: farmname

        },
      }]
  

  try {
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Claiming your rewards...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText("Your rewards have been claimed");
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};

export const unstakeV2 = async (
  farmname,
  assets,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let action = [{
        account: v2FarmContract,
        name: "unstake",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farmname: farmname,
          asset_ids: assets

        },
      }]
  

  try {
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Unstaking your assets...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText("Your assets have been unstaked");
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};