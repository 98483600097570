import { useState } from "react";
import { currentApiList, v2DAOContract } from "../../data/config";
import axios from 'axios';

export const useGetV2DAOProfiles = () => {
    
    const [daoProfiles, setDaoProfiles] = useState([]);
    const [profilesAreLoading, setProfilesAreLoading] = useState(true);

    const getDaoProfiles = async () => {
        setProfilesAreLoading(true);
        for (const api of currentApiList) {
            try {
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "daoprofiles",
                    scope: v2DAOContract,
                    code: v2DAOContract,
                    limit: 1000,
                    json: true,
                });
                
                if (res.data.rows) {
                    setDaoProfiles(res.data.rows);
                    console.log(res.data.rows);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        setProfilesAreLoading(false);
    }

    return [daoProfiles, getDaoProfiles, profilesAreLoading]
}
