import React, { useEffect, useState } from 'react'
import { currentWebsiteURL } from '../data/config';
import { useGetBannerAds } from './CustomHooks/useGetBannerAds';
import { useSelectItem } from './CustomHooks/useSelectItem';
import { useToggle } from './CustomHooks/useToggle';
import { AdsCont, AdSlot, BannerPreview, BlendMessage, CreateDropIPFS, FungibleTokenButton, LoadSpinner, LoadSpinnerBlue, LoadSpinnerGreen, LoadSpinnerRed, ProposalInput, StakingPoolMsg, Wallet20To50, WalletCallToAction, WalletCallToActionHref, WalletRAMInput } from './LeftDiv';
import { useQuery } from "@tanstack/react-query";
import axios from 'axios';
import { useStateContext } from '../contexts/ContextProvider';
import { useGetBannerIpfs } from './CustomHooks/useGetBannerIpfs';
import { buyAdSpace } from '../data/functions/wallet_functions';
import { MainButton, MilestoneContainer, NewListingBody, NewListingCont, TallFiltersCont, TallFiltersInnerCont } from '../Styles';
import { AdCardBeginDate, AdCardBeginDateLine1, AdCardBeginDateLine2, AdCardEndDate, AdCardEndDateLine1, AdCardEndDateLine2 } from '../data/css/AdStyles';

const Advertise = (props) => {
    const [bannerAds, getBannerAds] = useGetBannerAds();
    const { currentWalletSection } = useState();
    const [selectedAd, toggle] = useSelectItem();
    const [adIsSelected, toggleAd] = useToggle();

    const { setEnterModalDisplay, setEnterModalText, adBannerIpfs, setAdBannerIpfs, adURL, setAdURL, loadingDisplay, setLoadingDisplay, loading, setLoading } = useStateContext();
    //const [ipfsData, ipfsIsLoading, ipfsRefetch] = useGetBannerIpfs();

    const getImage = (coverImage, loadingDisplay, setLoadingDisplay, loading, setLoading) => {


        setLoadingDisplay('');
        setLoading(true);
  
        }

        useEffect(() => {
          getBannerAds();
        }, [])
        


  return (
    <NewListingCont>
      <NewListingBody>
    <TallFiltersCont>
      <TallFiltersInnerCont>
        You can advertise on WaxDAO by placing banner ads across the site. Time slots currently last for 24 hours each.
        Cost is 10,000 $WOJAK or 50 WAX for a 24 hour slot.<br/><br/>
        Your banner will be shown on the following pages: Market page, Blends page, Farms page, Staking Pools page, individual drop pages, individual <a href={`${currentWebsiteURL}/tokens`}>token pages</a>.<br/>
        <br/>
        <a href={`${currentWebsiteURL}/ad-terms`} target="none">
        <MainButton>
        PLEASE VIEW TERMS FIRST!
        </MainButton>
        </a> 
        </TallFiltersInnerCont>
    </TallFiltersCont>
    <br/>
    <MilestoneContainer>
    All time slots below are available, and displayed in your local time zone.<br/>
    Process is 3 steps: Choose a time slot - choose an image - submit your payment.
    </MilestoneContainer>

    <AdsCont>
    {bannerAds != null && bannerAds.length >= 1 && bannerAds.map((item, index) => (
        <span key={index} className={(item.user != null && item.user != "waxdaomarket" || selectedAd != item.time && adIsSelected == true || item.time < Math.round(Date.now() / 1000 ) ) && "hidden"}>
        <AdSlot onClick={() => { toggle(item.time); toggleAd(); }} selected={selectedAd == item.time && adIsSelected == true && true}>
            {item.time != null && (
            <span>
              <AdCardBeginDate>
                Begins
              </AdCardBeginDate>
              <AdCardBeginDateLine1>
              {new Date(item.time * 1000).toLocaleDateString()}
              </AdCardBeginDateLine1>
              <AdCardBeginDateLine2>
              {new Date(item.time * 1000).toLocaleTimeString()}
              </AdCardBeginDateLine2>

              <AdCardEndDate>
                Ends
              </AdCardEndDate>
              <AdCardEndDateLine1>
              {new Date( (item.time + 86400) * 1000).toLocaleDateString()}
              </AdCardEndDateLine1>
              <AdCardEndDateLine2>
              {new Date( (item.time + 86400) * 1000).toLocaleTimeString()}
              </AdCardEndDateLine2>

                 
                 
            </span>
             )}
        </AdSlot>
        </span>
    )
    )}
 
</AdsCont>
<br/>
    <span className={!adIsSelected && "hidden"}>
      <TallFiltersCont>
        <TallFiltersInnerCont>
    Enter the IPFS hash of your 1280 * 200 banner image<br/>

<input
type="text"
maxLength={70}
placeholder='e.g. Qmabc123...'
value={adBannerIpfs}
onChange={(e) => {
setAdBannerIpfs(e.target.value);
getImage(e.target.value, loadingDisplay, setLoadingDisplay, loading, setLoading);
}}
/>




{adBannerIpfs != null && adBannerIpfs != '' && adBannerIpfs.length > 10 && (
  <span className={loadingDisplay}>
    <span className={loading == false && 'hidden'}>
    <LoadSpinner>
    <LoadSpinnerRed  />
    <LoadSpinnerBlue  />
    <LoadSpinnerGreen />
    </LoadSpinner>

      <br/>Loading Image...<br/>
    </span>
    <span className={loading == true && 'hidden'}>
  <BannerPreview src={`https://ipfs.io/ipfs/${adBannerIpfs}`} onLoad={() => {setLoading(false);}}  onError={() => {setLoading(false);}} />
  </span>
  </span>
)}


    
    <br/><br/>
    Enter the URL you want people to land on when they click the image<br/>
    <input placeholder='e.g. https://waxdao.io/...' value={adURL} onChange={(e) => { setAdURL(e.target.value) }} />
    <br/><br/>
    <h3>Pay Now</h3>

        <MainButton onClick={() => { buyAdSpace("wojak", adBannerIpfs, adURL, selectedAd, setEnterModalText, setLoadingDisplay, setEnterModalDisplay) }}>
           Pay 10k WOJAK
        </MainButton>
        &nbsp;&nbsp;
        <MainButton onClick={() => { buyAdSpace("wax", adBannerIpfs, adURL, selectedAd, setEnterModalText, setLoadingDisplay, setEnterModalDisplay) }}>
           Pay 50 WAX
        </MainButton>



        </TallFiltersInnerCont>
    </TallFiltersCont>
    </span>           
    </NewListingBody>
    </NewListingCont>
  )
}

export default Advertise