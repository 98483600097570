import React, { useEffect, useState } from "react";
import { UALProvider } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  ModalOverlay,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  Modal,
  CloseWindow,
  CreateFarmIPFS,
} from "../components/LeftDiv";
import "./datepicker.css";
import DatePicker from "react-datepicker";
import {
  wax,
  anchor,
  myChain,
  wombat,
} from "../data/config";
import {
  createFarm, createNonCustodialFarm,
} from "../data/functions/farm_functions";
import { BsInfoCircle } from "react-icons/bs";
import { InfoLabel } from "../data/css/CreateFarmStyles";
import {
  FoldersContainer,
  FoldersRow,
  MainButton,
  NewListingBody,
  NewListingCont,
  SingleFolderThird,
  SkillsButton,
  SmallButton,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../Styles";
import { resizerPrefix, resizerSuffix } from "../data/constants";
import { handleCalendarChange } from "../data/functions/pack_functions";
import CreateNonCustodial from "../components/CreateNonCustodial";

const CreateFarm = () => {
  const [rewardAmount, setRewardAmount] = useState("0");
  const [loadingDisplay, setLoadingDisplay] = useState("none");
  const [burnable, setBurnable] = useState([]);
  const [decimals, setDecimals] = useState("");
  const [farmName, setFarmName] = useState("");
  const [rewardToken, setRewardToken] = useState("");
  const [rewardContract, setRewardContract] = useState("");
  const [farmLogo, setFarmLogo] = useState("");
  const [farmType, setFarmType] = useState("Select Farm Type");
  const [paymentMethod, setPaymentMethod] = useState("Payment Method");
  const [schema, setSchema] = useState("");
  const [collection, setCollection] = useState("");
  const [stakingPeriod, setStakingPeriod] = useState("");
  const [rewardTypeValue, setRewardTypeValue] = useState("sharedpool");
  const [rewardType, setRewardType] = useState("");
  const [stakingSeconds, setStakingSeconds] = useState("");
  const [farmTypeDisplay, setFarmTypeDisplay] = useState("hidden");
  const [farmTypeValue, setFarmTypeValue] = useState("");
  const [templates, setTemplates] = useState("");
  const [schemaDisplay, setSchemaDisplay] = useState("hidden");
  const [templateMessage, setTemplateMessage] = useState(
    "You can use any template in your collection, even if they are not all in the same schema :)"
  );

  const [assetToBurn, setAssetToBurn] = useState("");
  const [timestamp, setTimestamp] = useState(Date.now() / 1000);
  const [enterModalDisplay, setEnterModalDisplay] = useState("hidden");
  const [enterModalText, setEnterModalText] = useState("Awaiting signature...");
  
  //noncustodial
  const [custodial, setCustodial] = useState(true);
  const [farmConfig, setFarmConfig] = useState("nc.c.shared");

  return (
    <div id="seo">
      <Helmet>
        <title>Create NFT Farm - WaxDAO</title>
        <meta
          name="description"
          content="Launch your own NFT farm on Wax. Your community will be able to stake your NFTs and earn passive income. "
        />
        <link rel="canonical" href="https://waxdao.io/create-farm" />
      </Helmet>

      <UALProvider
        chains={[myChain]}
        authenticators={[anchor, wax, wombat]}
        appName={"WaxDAO"}
      >
        <ModalOverlay className={enterModalDisplay} />
        <Modal className={enterModalDisplay}>
          <ModalContent>{enterModalText}</ModalContent>

          <Spinner style={{ display: loadingDisplay }}>
            <SpinnerRed />
            <SpinnerBlue />
            <SpinnerGreen />
          </Spinner>

          <br />
          <CloseWindow
            onClick={() => {
              setEnterModalDisplay("hidden");
            }}
          >
            Close Window
          </CloseWindow>
        </Modal>

        <NewListingCont>
          <NewListingBody>
            <TallFiltersCont>
              <h2>NFT Farm Creator</h2>
              <br />
              <TallFiltersInnerCont>
                Tip: Did you know that you can now set different staking values
                for each template in your farm?
                <br />
                <br />
                For a full tutorial on creating a Version 2 NFT Farm,{" "}
                <a href="https://youtu.be/SFeuzMjHxoc" target="none">
                  watch this video
                </a>
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Farm Details</h2>
              <br />

              <FoldersContainer>
                <FoldersRow>
                  <SingleFolderThird selected={!custodial && true}
                   onClick={() => setCustodial(false)}
                  >
                  Non-Custodial <span className="italic">*Beta</span>
                  </SingleFolderThird>
                  <SingleFolderThird selected={custodial && true}
                  onClick={() => setCustodial(true)}
                  >
                  Custodial
                  </SingleFolderThird>
                </FoldersRow>
              </FoldersContainer>
              <br/>

              {!custodial && 
                <CreateNonCustodial 
                  farmName={farmName} setFarmName={setFarmName} 
                  farmLogo={farmLogo} setFarmLogo={setFarmLogo}
                  rewardToken={rewardToken} setRewardToken={setRewardToken}
                  rewardContract={rewardContract} setRewardContract={setRewardContract}
                  setEnterModalDisplay={setEnterModalDisplay} setEnterModalText={setEnterModalText}
                  decimals={decimals} setDecimals={setDecimals}
                  timestamp={timestamp} setTimestamp={setTimestamp}
                  paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod}
                  assetToBurn={assetToBurn} setAssetToBurn={setAssetToBurn}
                  farmConfig={farmConfig} setFarmConfig={setFarmConfig}

              />}

              <span className={!custodial && "hidden"}>
              <TallFiltersInnerCont>
                <h3>Farm Name</h3>
                <input
                  type="text"
                  maxLength={12}
                  id="farmName"
                  placeholder="e.g. mynewnftfarm"
                  value={farmName}
                  onChange={(e) => {
                    setFarmName(e.target.value.toLowerCase());
                  }}
                />
                <br />
                <br />
                <h3>Farm Logo (IPFS Hash)</h3>
                <input
                  type="text"
                  placeholder="e.g. Qmabc....."
                  id="farmLogo"
                  value={farmLogo}
                  onChange={(e) => {
                    setFarmLogo(e.target.value);
                  }}
                />
                <br />
                <span className="text-sm italic">*100px by 100px</span>

                {farmLogo != null && farmLogo != "" && farmLogo.length > 10 && (
                  <CreateFarmIPFS
                    src={`${resizerPrefix}${farmLogo}${resizerSuffix}`}
                  />
                )}

                <br />
                <br />
                <h3>Farm Type</h3>
                <select
                  onChange={(e) => {
                    if (e.target.value == "Specific Templates") {
                      setFarmTypeValue("template");
                    } else if (e.target.value == "Entire Collection") {
                      setFarmTypeValue("collection");
                    } else if (e.target.value == "Schema w/ Exclusions") {
                      setFarmTypeValue("s.exclude");
                    } else if (e.target.value == "Entire Schema") {
                      setFarmTypeValue("schema");
                    } else if (e.target.value == "Version 2") {
                      setFarmTypeValue("template");
                    }
                    setFarmType(e.target.value);
                  }}
                >
                  <option value="" hidden>
                    Choose
                  </option>
                  <option value="Version 2">Version 2</option>
                  <option value="Entire Collection">Entire Collection</option>
                  <option value="Entire Schema">Entire Schema</option>
                  <option value="Schema w/ Exclusions">
                    Schema w/ Exclusions
                  </option>
                  <option value="Specific Templates">Specific Templates</option>
                </select>

                <span className={farmType != "Version 2" && "hidden"}>
                  <br />
                  <br />
                  <TallFiltersCont>
                    <TallFiltersInnerCont>
                      <span className="font-bold">Note:</span> For Version 2
                      farms, you can fill out all the template/collection info
                      AFTER you create the farm.
                      <br />
                      This includes which templates can be staked, their staking
                      values, which collections can be staked etc.
                    </TallFiltersInnerCont>
                  </TallFiltersCont>
                </span>

                <span className={farmType == "Version 2" && "hidden"}>
                  <br />
                  <br />
                  <h3>Collection To Stake</h3>

                  <input
                    type="text"
                    id="collection"
                    maxLength={12}
                    placeholder="e.g. hoodpunknfts"
                    value={collection}
                    onChange={(e) => {
                      setCollection(e.target.value.toLowerCase());
                    }}
                  />

                  <span
                    className={
                      farmTypeValue != "schema" &&
                      farmTypeValue != "s.exclude" &&
                      "hidden"
                    }
                  >
                    <br />
                    <br />
                    <h3>Schema To Stake</h3>
                    <input
                      type="text"
                      id="collection"
                      maxLength={12}
                      placeholder="e.g. wojakpacks"
                      value={schema}
                      onChange={(e) => {
                        setSchema(e.target.value.toLowerCase());
                      }}
                    />
                  </span>

                  <span
                    className={
                      farmTypeValue != "template" &&
                      farmTypeValue != "s.exclude" &&
                      "hidden"
                    }
                  >
                    <br />
                    <br />
                    <h3>Template IDs (10 Max)</h3>
                    <textarea
                      placeholder="123456,987654,696969,420420,444444,169169,420069"
                      maxLength="200"
                      id="template"
                      value={templates}
                      onChange={(e) => {
                        setTemplates(e.target.value.replace(" ", ""));
                      }}
                    />

                    <span className={`text-sm italic`}>
                      <br />
                      {`You can ${
                        farmTypeValue == "s.exclude" ? "exclude" : "include"
                      } up to template IDs, comma separated`}
                    </span>
                  </span>
                </span>

                <TallFiltersCont>
                  <div className="w-100 pl-3 pr-3">
                    <span className="font-bold">Warning:</span> Entering
                    incorrect token details will result in a pool that doesn't
                    work, and WaxDAO will not be able to help you. <br />
                    Make 100% sure that you have the correct symbol, precision
                    and contract for the reward token.
                    <br />
                    WaxDAO will not be held responsible for user errors.
                  </div>
                </TallFiltersCont>

                <br />
                <br />
                <h3>Reward Token Symbol</h3>

                <input
                  type="text"
                  id="rewardToken"
                  placeholder="e.g. WOJAK"
                  maxLength={7}
                  value={rewardToken}
                  onChange={(e) => {
                    setRewardToken(e.target.value.toUpperCase());
                  }}
                />

                <br />
                <br />
                <h3 className="flex">
                  Reward Token Contract&nbsp;&nbsp;
                  <BsInfoCircle
                    className="mt-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEnterModalDisplay("");
                      setEnterModalText(
                        <span>
                          If WAX is the reward token, the info is: <br />
                          Contract: eosio.token
                          <br />
                          Decimals: 8<br />
                          For other tokens, search our{" "}
                          <a
                            href="https://waxdao.io/tokens"
                            target="none"
                            className="text-secondary"
                          >
                            Token Tracker
                          </a>{" "}
                          for this info
                        </span>
                      );
                    }}
                  />
                  <br />
                </h3>

                <input
                  type="text"
                  id="rewardContract"
                  maxLength={12}
                  placeholder="e.g. eosio.token"
                  value={rewardContract}
                  onChange={(e) => {
                    setRewardContract(e.target.value.toLowerCase());
                  }}
                />

                <br />
                <br />
                <h3 className="flex">
                  Reward Token Decimals&nbsp;&nbsp;
                  <BsInfoCircle
                    className="mt-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEnterModalDisplay("");
                      setEnterModalText(
                        <span>
                          If WAX is the reward token, the info is: <br />
                          Contract: eosio.token
                          <br />
                          Decimals: 8<br />
                          For other tokens, search our{" "}
                          <a
                            href="https://waxdao.io/tokens"
                            target="none"
                            className="text-secondary"
                          >
                            Token Tracker
                          </a>{" "}
                          for this info
                        </span>
                      );
                    }}
                  />
                  <br />
                </h3>

                <input
                  type="number"
                  id="decimals"
                  placeholder="e.g. 8"
                  value={decimals}
                  onChange={(e) => {
                    setDecimals(e.target.value);
                  }}
                />

                <span className={farmType == "Version 2" && "hidden"}>
                  <br />
                  <br />
                  <h3>Reward Type</h3>
                  <select
                    onChange={(e) => {
                      if (e.target.value == "Shared Pool") {
                        setRewardTypeValue("sharedpool");
                      } else if (e.target.value == "Exact Amount Per NFT") {
                        setRewardTypeValue("static");
                      }
                      setRewardType(e.target.value);
                    }}
                  >
                    <option value="" hidden>
                      Choose
                    </option>
                    <option value="Shared Pool">Shared Pool</option>
                    <option value="Exact Amount Per NFT">
                      Exact Amount Per NFT
                    </option>
                  </select>

                  <span
                    htmlFor="rewardAmountContent"
                    className={
                      (farmType == "Version 2" ||
                        rewardTypeValue == "sharedpool") &&
                      "hidden"
                    }
                  >
                    <br />
                    <br />
                    <h3>Hourly Reward Per NFT</h3>

                    <input
                      type="number"
                      id="rewardAmountContent"
                      placeholder="numbers only"
                      value={rewardAmount}
                      onChange={(e) => {
                        setRewardAmount(e.target.value);
                      }}
                    />
                  </span>
                </span>

                <br />
                <br />
                <h3>Minimum Staking Period (Days)</h3>

                <input
                  type="number"
                  id="stakingPeriod"
                  placeholder=" e.g. 1"
                  value={stakingPeriod}
                  onChange={(e) => {
                    let prd = e.target.value;
                    if (prd > 1) {
                      prd = Math.round(prd);
                    }
                    setStakingPeriod(prd);
                    setStakingSeconds(prd * 86400);
                  }}
                />

                <br />
                <br />
                <h3>Farm Expiration Date</h3>

                <DatePicker
                  showTimeSelect
                  timeIntervals={15}
                  selected={new Date(timestamp * 1000)}
                  onChange={(e) => {
                    handleCalendarChange(e, setTimestamp);
                  }}
                />

                <br />
                <br />
                <h3>Payment Method</h3>
                <select
                  onChange={(e) => {
                    setPaymentMethod(e.target.value);
                  }}
                >
                  <option value="" hidden>
                    Choose
                  </option>
                  <option value="Pay 250 Wax">Pay 250 Wax</option>
                  <option value="Pay 25,000 WOJAK">Pay 25,000 WOJAK</option>
                  <option value="Burn 1 Wojak NFT">Burn 1 Wojak NFT</option>
                  <option value="Use 1 WaxDAO Credit">
                    Use 1 WaxDAO Credit
                  </option>
                </select>

                <span
                  className={paymentMethod != "Burn 1 Wojak NFT" && "hidden"}
                >
                  <br />
                  <br />
                  <h3>Asset ID To Burn</h3>
                  <input
                    placeholder="e.g. 19353289652"
                    id="assetID"
                    value={assetToBurn}
                    onChange={(e) => {
                      setAssetToBurn(e.target.value);
                    }}
                  />
                  <br />
                  <br />
                  <TallFiltersCont>
                    <div className="w-100 pl-3 pr-3">
                      Don't have a Wojak NFT to burn? You can buy one from our{" "}
                      <a href="https://waxdao.io/drops/247" target="none">
                        Official Drop
                      </a>{" "}
                      and then{" "}
                      <a href="https://waxdao.io/unbox/246" target="none">
                        unbox it
                      </a>
                      .
                      <br />
                      <br />
                      The unboxed Wojak can be burned to create a farm, by
                      simply pasting the asset ID into the field above.
                    </div>
                  </TallFiltersCont>
                </span>
              </TallFiltersInnerCont>
              </span>



            </TallFiltersCont>


            <TallFiltersCont>
              <div className="text-center w-100 pt-3">

                {custodial &&
                <MainButton
                  onClick={() => {
                    createFarm(
                      paymentMethod,
                      farmName,
                      farmLogo,
                      collection,
                      schema,
                      rewardToken,
                      rewardContract,
                      decimals,
                      timestamp,
                      assetToBurn,
                      stakingSeconds,
                      farmType,
                      farmTypeValue,
                      templates,
                      rewardTypeValue,
                      rewardAmount,
                      setEnterModalDisplay,
                      setEnterModalText,
                      setLoadingDisplay
                    );
                  }}
                >
                  Create Farm Now
                </MainButton>
              }

                {!custodial &&
                <MainButton
                  onClick={() => {
                    createNonCustodialFarm(
                      paymentMethod,
                      farmConfig,
                      farmName,
                      farmLogo,
                      rewardToken,
                      rewardContract,
                      decimals,
                      timestamp,
                      assetToBurn,
                      setEnterModalDisplay,
                      setEnterModalText,
                      setLoadingDisplay
                    );
                  }}
                >
                  Create Non Custodial Farm
                </MainButton>
              }              
              </div>
            </TallFiltersCont>
          </NewListingBody>
        </NewListingCont>
      </UALProvider>
      <br />
      <br />
      <br />
    </div>
  );
};

export default CreateFarm;