import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { UALProvider } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  StakeManyDiv,
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  WalletSearchBarCont,
  WalletSearchBar,
  CloseWalletSearchBar,
  WalletSearchBarCollectionList,
  WalletSearchBarSingleCollectionButton,
} from "../components/LeftDiv";
import { Name } from "eos-common";
import {
  wax,
  anchor,
  myChain,
  currentAtomicHubLink,
  currentUsername,
  getCurrentApiList,
  wombat,
  currentWebsiteURL,
} from "../data/config";
import {
  unstakeAll,
  stakeMany,
  unstake,
  claimRewards,
  unstakeMany,
} from "../data/functions/farm_functions";
import { useGetDynamicTemplates } from "../components/CustomHooks/useGetDynamicTemplates";
import { StakeableAssetsContainer } from "../data/css/FarmPageStyles";
import { useGetStakedAssets } from "../components/CustomHooks/useGetStakedAssets";
import { useGetUnstakedAssets } from "../components/CustomHooks/useGetUnstakedAssets";
import {
  CollectionProfileAvatarCont,
  CollectionProfileBGContainer,
  CollectionProfileContainer,
} from "../data/css/CollectionPageStyles";
import collection_profile_stock_bg from "../data/stars.png";
import {
  ExploreFiltersCont,
  ExploreFiltersInnerCont,
  ExploreFiltersScrollCont,
  FoldersContainer,
  FoldersRow,
  MainButton,
  MilestoneContainer,
  MilestoneDescription,
  NewListingBody,
  SingleFolderThird,
  theme,
} from "../Styles";
import { FARM_STATUSES, resizerPrefix, resizerSuffix } from "../data/constants";
import {
  PoolLineBreak,
  SinglePoolDetail,
  SinglePoolDetailBold,
} from "../data/css/PoolPageStyles";
import {
  getHourlyReward,
  getPoolSize,
  roundWalletBalance,
} from "../data/functions/pool_functions";
import LoadingPage from "../components/LoadingPage";
import {
  deselectAllStaked,
  deselectAllUnstaked,
  getStakeableAssets,
  selectAllStaked,
  selectAllUnstaked,
  showUnstakedAssets,
} from "./farmpage/showUnstaked";
import FarmNFTCard from "../components/FarmNFTCard";

var list = [];

const NFTsStakedByUserInFarm = ({ location }) => {
  const {
    farmType,
    rewardType,
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
  } = useStateContext();

  const [payoutInterval, setPayoutInterval] = useState(1);

  const { FarmUserCombo } = useParams();

  const FarmName = FarmUserCombo.substring(0, FarmUserCombo.indexOf("+"))
  const User = FarmUserCombo.substring(FarmUserCombo.indexOf("+") + 1)

  const [farmData, setFarmData] = useState([]);
  const [farmDataIsLoaded, setFarmDataIsLoaded] = useState(false);
  const [currentSection, setCurrentSection] = useState("Claim");

  const [dynamicTemps, getDynamicTemps] = useGetDynamicTemplates();
  const [stakedAssets, getStakedAssets, userCanUnlock, validAssets, claimable] =
    useGetStakedAssets();
  const [unstaked, getUnstakedAssets] = useGetUnstakedAssets();
  const [removalVector, setRemovalVector] = useState([]);
  const [assetVector, setAssetVector] = useState([]);

  const [farmCollections, setFarmCollections] = useState([]);
  const [profile, setProfile] = useState([]);
  const [showSearchCollections, setShowSearchCollections] = useState(false);
  const [enteredText, setEnteredText] = useState("");
  const [stakeMultipleDisplay, setStakeMultipleDisplay] = useState("hidden");

  useEffect(() => {
    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "farms",
        scope: "waxdaofarmer",
        code: "waxdaofarmer",
        key_type: "name",
        indexName: "farmname",
        limit: 1,
        lower_bound: FarmName,
        upper_bound: FarmName,
        json: true,
      })
      .then((response) => {
        console.log(response);
        setFarmData(response.data.rows[0]);
        setFarmDataIsLoaded(true);

        if (response.data.rows[0].reward_type != "dynamic") {
          if (
            Number(response.data.rows[0].totalstaked) >= 5000 &&
            Number(response.data.rows[0].totalstaked) < 10000
          ) {
            setPayoutInterval(2);
          } else if (
            Number(response.data.rows[0].totalstaked) >= 10000 &&
            Number(response.data.rows[0].totalstaked) <= 15000
          ) {
            setPayoutInterval(3);
          }
        } else {
          if (
            Number(response.data.rows[0].totalstaked) >= 1000 &&
            Number(response.data.rows[0].totalstaked) < 2000
          ) {
            setPayoutInterval(2);
          } else if (
            Number(response.data.rows[0].totalstaked) >= 20000 &&
            Number(response.data.rows[0].totalstaked) <= 3000
          ) {
            setPayoutInterval(3);
          }
        }

        const thisCollection = response.data.rows[0].collection;
        const thisSchema = response.data.rows[0].schema;
        const thisFarmType = response.data.rows[0].farmtype;
        const thisRewardType = response.data.rows[0].reward_type;

        if (
          response.data.rows[0].farmtype == "template" &&
          response.data.rows[0].reward_type == "dynamic"
        ) {
          getDynamicTemps(FarmName);
        } //end if farmtype == template

        if (User) {
          getStakedAssets(User, FarmName, thisRewardType);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const nameValue = new Name(FarmName).raw().toString();

    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "farmprofiles",
        scope: "waxdaofarmer",
        code: "waxdaofarmer",
        limit: 1,
        lower_bound: nameValue,
        upper_bound: nameValue,
        json: true,
      })
      .then((profileResponse) => {
        setProfile(profileResponse.data.rows[0]);
      })
      .catch((error) => console.log(error));
  }, []);

  if (farmData != null && farmDataIsLoaded) {
    return (
      <div id="seo">
        <Helmet>
          <title>{FarmName} Details</title>
          <meta
            name="description"
            content={`Stake NFTs in the ${FarmName} pool on WaxDao`}
          />
          <link rel="canonical" href={`https://waxdao.io/farm/${FarmName}`} />
        </Helmet>

        <UALProvider
          chains={[myChain]}
          authenticators={[anchor, wax, wombat]}
          appName={"WaxDAO"}
        >
          <Modal className={enterModalDisplay}>
            <ModalContent>{enterModalText}</ModalContent>

            <Spinner style={{ display: loadingDisplay }}>
              <SpinnerRed />
              <SpinnerBlue />
              <SpinnerGreen />
            </Spinner>

            <br />
            <GameButton onClick={() => setEnterModalDisplay("hidden")}>
              Close Window
            </GameButton>
          </Modal>

          <CollectionProfileContainer>
            <CollectionProfileBGContainer>
              {profile?.image1 != null && profile?.image1?.length > 0 ? (
                <img
                  src={`${"https://ipfs.io/ipfs/"}${profile.image1}`}
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={collection_profile_stock_bg}
                  style={{
                    width: "auto",
                    height: "auto",
                    minHeight: "100%",
                    minWidth: "100%",
                  }}
                />
              )}
            </CollectionProfileBGContainer>

            <CollectionProfileAvatarCont>
              <img
                src={
                  farmData.logo != null &&
                  `${resizerPrefix}${farmData.logo}${resizerSuffix}`
                }
                style={{
                  width: "auto",
                  height: "auto",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              />
            </CollectionProfileAvatarCont>
          </CollectionProfileContainer>

          <div className="p-5 mt-4 text-white">
            <br />
            <span className="text-2xl font-semibold">{FarmName}</span>
            <br />
            <span className="text-md">
              {farmData.creator != null && <span>NFTs Staked By <a href={`${currentWebsiteURL}/u/${User}`} style={{color: theme.secondary}}>{User}</a></span>}
            </span>

          </div>

          <NewListingBody>


            <div
              className={
                "flex flex-wrap gap-5 mt-4 justify-center"
              }
            >
              {stakedAssets.length > 0 &&
                stakedAssets.map((item, index) => (
                  <FarmNFTCard
                    item={item}
                    assetVector={assetVector}
                    setAssetVector={setAssetVector}
                    stakeMultipleDisplay={stakeMultipleDisplay}
                    //editMode={1}
                    removalVector={removalVector}
                    setRemovalVector={setRemovalVector}
                    rewardType={rewardType}
                    dynamicTemps={dynamicTemps}
                  />
                ))}
              {stakedAssets.length == 0 && (
                <span className="text-white">No assets to display</span>
              )}
            </div>
          </NewListingBody>
        </UALProvider>
        <br />
        <br />
        <br />
      </div>
    );
  } else {
    return <LoadingPage />;
  }
};

export default NFTsStakedByUserInFarm;
