import { useState } from "react";
import { currentApiList, currentHyperionApiList, currentUsername, v2DAOContract } from "../../data/config";
import axios from 'axios';
import { isLoggedIn } from "../../data/functions/wallet_functions";

export const useGetTokenBalances = () => {
    
    const [tokenBalances, setTokenBalances] = useState([]);
    const [balancesAreLoading, setBalancesAreLoading] = useState(true);

    const getTokenBalances = async () => {
        if(!isLoggedIn()) { return; }

        setBalancesAreLoading(true);

        let tempTokenBalances = [];

        for (const api of currentHyperionApiList) {
            let skip = 0;

            try {
                while(true) {
                    const res = await axios.get(`${api}/v2/state/get_tokens?limit=50&skip=${skip}&account=${currentUsername}&sort=desc`, {});

                    if (res.data.tokens) {
                        tempTokenBalances = [...tempTokenBalances, ...res.data.tokens];
                        if (res.data.tokens.length == 0) {
                            break;
                        }
                    }

                    skip += 50;
                }

                setTokenBalances(tempTokenBalances);
                console.log(tempTokenBalances);
                break;

            } catch (error) {
                console.log(`An error occurred with ${api}. Trying next API...`);
                console.error(error);
            }
        }
        
        setBalancesAreLoading(false);
    }

    return [tokenBalances, getTokenBalances, balancesAreLoading]
}
