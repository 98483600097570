import React, { useEffect } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import vote from '../data/vote.png';
import { MainContainer, DaoTitle, DaoButton, CreateDaoCont, DAO, DaosCont, MyDaoTitle, NoDaosMessage
  } from '../components/LeftDiv';
import { myChain, wax, anchor, currentApiList, currentAtomicApiList, currentWebsiteURL } from '../data/config';





const MyDAOs = () => {

  const { farmList, setFarmList } = useStateContext();


useEffect(() => {
setFarmList([]);
  //(async () => {

 // await anchor.login();

 const waxUsername = localStorage.getItem('waxAccount');
 const walletProvider = localStorage.getItem('walletProvider');
  
 if(waxUsername){
   //console.log('You have an active session');
   '';
   //setProfileDisplay('flex');
 }else{
   //console.log('You are not logged in');
   return('');
 }
  
  
  axios.post(`${currentApiList[Math.floor(Math.random() * (2 - 0) ) + 0]}/v1/chain/get_table_rows`,{
    table:"daos",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    key_type: 'name',
    index_position: 3,
    limit:50,
    lower_bound: waxUsername,
    upper_bound: waxUsername,
    json:true
  })
    .then((response) => {

      setFarmList(response.data.rows);
      console.log(response.data.rows);
    })
    .catch((error) => console.log(error));

  }

, []);

  return (
  <div id="seo">
    <Helmet>
    <title>My DAOs</title>
    <meta name="description" content="View a list of DAOs that you have created on WaxDAO" />
    <link rel="canonical" href="https://waxdao.io/my-daos" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax]} appName={"WaxDAO"}>
    <MainContainer>

    <CreateDaoCont>


    <DaoTitle>
        My DAOs
        &nbsp;&nbsp;

      <a href={`${currentWebsiteURL}/create-dao`}>
      <DaoButton>
      + New DAO
      </DaoButton>
      </a>
    </DaoTitle>

  


<DaosCont>

      {farmList.length > 0 ? farmList.map((item, index) => (

<span key={index}>
   

{item.status == 'unlocked' ? (


<DAO>

<MyDaoTitle>
  DAO {item.ID}
</MyDaoTitle>


<img 

        style={{ width:'150px', 
          maxWidth:'150px',
          height:'150px',
          maxHeight:'150px'
          }}
        className="rounded-xl hover:drop-shadow-xl"  
          src={vote}
        />


<a href={`${currentWebsiteURL}/edit-dao/${item.ID}`}>
<DaoButton>
  Edit Now
</DaoButton>
</a>
</DAO>



) : (

<DAO>

<MyDaoTitle>
  {item.daoname}
</MyDaoTitle>


<img 
        alt={`${item.daoname} Logo`} 
        style={{ width:'150px', 
          maxWidth:'150px',
          height:'150px',
          maxHeight:'150px'
          }}
        className="rounded-xl hover:drop-shadow-xl"  
          src={`https://ipfs.io/ipfs/${item.logo}`}
        />



<a href={`${currentWebsiteURL}/daos/${item.daoname}`}>
<DaoButton>
  View DAO
</DaoButton>
</a>
</DAO>






)}


    


</span>

)) : (
  <NoDaosMessage>
  You have not created any DAOs yet
  </NoDaosMessage>
  )}


</DaosCont>





</CreateDaoCont>
</MainContainer>


    </UALProvider>
    <br/><br/><br/>
    </div>
  )
}

export default MyDAOs