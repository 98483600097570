import { useState } from "react";
import axios from 'axios';
import { currentAtomicApiList } from "../../data/config";

export const useGetNftsByUser = () => {
    const [nfts, setNfts] = useState([]);
    const [nftsAreLoading, setNftsAreLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [collectionName, setCollectionName] = useState(null);
    const [lastPageSize, setLastPageSize] = useState(0);

    const getNfts = async (owner, collection = collectionName, page = currentPage + 1) => {
        setNftsAreLoading(true);
        if(collection !== collectionName){
            // If the collection is different, start over from page 1.
            setNfts([]);
            page = 1;
            setCollectionName(collection);
        }

        for (const api of currentAtomicApiList) {
            try {
                const collectionParam = collection ? `&collection_name=${collection}` : '';
                const res = await axios.get(`${api}/atomicassets/v1/assets?owner=${owner}&page=${page}&limit=100&order=desc&sort=transferred${collectionParam}`, {});
                
                if (res.data.data) {
                    setNfts(prevNfts => [...prevNfts, ...res.data.data]);
                    setLastPageSize(res.data.data.length)
                    console.log(res.data.data);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }

        setCurrentPage(page);
        setNftsAreLoading(false);
    }

    const hasNextPage = () => {
        // We assume that if the last fetch returned the maximum number of items,
        // there is a next page.
        return !nftsAreLoading && lastPageSize === 100;
    }

    return [nfts, getNfts, nftsAreLoading, hasNextPage]
}
