import { useState } from "react";
import axios from 'axios';
import { currentAtomicApiList, currentUsername } from "../../data/config";

export const useGetMyWojaks = () => {
    const [nfts, setNfts] = useState([]);
    const [nftsAreLoading, setNftsAreLoading] = useState(false);

    const getWojaks = async () => {
        setNftsAreLoading(true);

        for (const api of currentAtomicApiList) {
            try {

                const res = await axios.get(`${api}/atomicassets/v1/assets?owner=${currentUsername}&page=1&limit=20&order=desc&sort=transferred&collection=hoodpunknfts&schema=waxywojaks}`, {});
                
                if (res.data.data) {
                    setNfts(prevNfts => [...prevNfts, ...res.data.data]);
                    console.log(res.data.data);
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }
        setNftsAreLoading(false);
    }


    return [nfts, getWojaks, nftsAreLoading]
}
