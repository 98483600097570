import React, { useEffect } from 'react';
import mindmaster_logo from '../data/mindmaster_logo.png';
import { useStateContext } from '../contexts/ContextProvider';
import { ColumnLinks, ColumnTitle, FooterColumn, FooterHref, FooterLeft, FooterLogo, FooterRight, FooterWaxDAOText } from './LeftDiv';
import { currentWebsiteURL } from '../data/config';
import { FooterCont } from '../data/css/FooterStyles';


const Footer = () => {
  const { 


  } = useStateContext();


  return (
    <FooterCont>
      <FooterLeft>
      <FooterLogo src={mindmaster_logo} /><FooterWaxDAOText>WAXDAO</FooterWaxDAOText>
      </FooterLeft>

      <FooterRight>
        <FooterColumn>
        <ColumnTitle>
        Creator Tools
        </ColumnTitle>
        <ColumnLinks>
        <FooterHref href={`${currentWebsiteURL}/request-listing`}>Listing Request</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/token-creator`}>Token Creator</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/create-blend`}>Super Blender</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/create-farm`}>NFT Farms</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/create-pool`}>Staking Pools</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/create-drop`}>NFT Drop Creator</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/v2/create-dao`}>DAO Creator</FooterHref><br/>

        
        </ColumnLinks>
        </FooterColumn>
        <FooterColumn>
        <ColumnTitle>
        User Tools
        </ColumnTitle>
        <ColumnLinks>
        <FooterHref href={`${currentWebsiteURL}/farms`}>Stake NFTs</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/pools`}>Stake Tokens</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/tokens`}>Token Explorer</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/drops`}>Buy NFTs</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/wojak/swap`}>Swap Tokens</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/wallet`}>Wallet</FooterHref><br/>
        <FooterHref href={`${currentWebsiteURL}/v2/daos`}>DAOs</FooterHref><br/>
        </ColumnLinks>
        </FooterColumn>
        <FooterColumn>
        <ColumnTitle>
        Info
        </ColumnTitle>
        <ColumnLinks>
        <FooterHref href={`${currentWebsiteURL}/blog`}>Blog</FooterHref><br/>
        <FooterHref href="https://t.me/hoodpunks" target="none">Support</FooterHref><br/>
        {/* <FooterHref href={`${currentWebsiteURL}/faq`}>FAQ</FooterHref><br/> */}
        <FooterHref href={`${currentWebsiteURL}/ad-terms`}>Advertise</FooterHref><br/>
        {/* <FooterHref href={`${currentWebsiteURL}/terms`}>Terms</FooterHref><br/> */}
        
        </ColumnLinks>
        </FooterColumn>

      </FooterRight>
    </FooterCont>
  )
}

export default Footer