import { useState } from "react";
import { currentApiList, currentAtomicApiList, v2DAOContract } from "../../data/config";
import axios from 'axios';

export const useGetV2DAONftTreasury = () => {
    const [daoNfts, setDaoNfts] = useState([]);
    const [daoNftsAreLoading, setDaoNftsAreLoading] = useState(false);
    const [highestAssetId, setHighestAssetId] = useState(0);

    const getDaoNftTreasury = async (dao_name, loadMore = false) => {
        setDaoNftsAreLoading(true);
        
        for (const api of currentApiList) {
            try {
                const lowerBound = loadMore ? highestAssetId + 1 : 0;
                const res = await axios.post(`${api}/v1/chain/get_table_rows`,{
                    table: "nftvault",
                    scope: dao_name,
                    code: v2DAOContract,
                    limit: 100,
                    lower_bound: lowerBound,
                    json: true,
                });
                
                if (res.data.rows?.length >= 1) {
                    const asset_ids = res.data.rows.map(row => row.asset_id);
                    setHighestAssetId(Math.max(...asset_ids));

                    for (const atomicApi of currentAtomicApiList) {
                        try {
                            const assetRes = await axios.get(`${atomicApi}/atomicassets/v1/assets?ids=${asset_ids.join("%2C")}&page=1&limit=100&order=desc&sort=transferred`);
                            
                            if (assetRes.data.data) {
                                if (loadMore) {
                                    setDaoNfts(oldNfts => [...oldNfts, ...assetRes.data.data]);
                                } else {
                                    setDaoNfts(assetRes.data.data);
                                }
                                console.log(assetRes.data.data);
                                break;
                            }
                        } catch (error) {
                            console.log(`Error fetching assets from ${atomicApi}: ${error}`);
                        }
                    }
                    break;
                }
            } catch (error) {
                console.log(error);
            }
        }

        setDaoNftsAreLoading(false);
    }

    const hasNextPage = () => {
        return !daoNftsAreLoading && daoNfts.length % 100 === 0;
    }

    return [daoNfts, getDaoNftTreasury, daoNftsAreLoading, hasNextPage]
}
