import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { MainContainer, NewProposal, AboutDAO, Wallet, DaoTitle,
     ProposerTypeMessage,
    ProposerList, ProposalInput, PropDescription, ProposalChoices, RemoveButton, AddButton, SubmitButton, DropDown, AboutDaoTitle,
    AboutDaoBody, AboutDaoCont, DaoButton, DaoButtonCont, StakedAmount, TemplatesCont, SingleTemplate, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton
    } from '../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { myChain, anchor, wax, currentApiList, currentAtomicApiList, currentWebsiteURL, getCurrentApiList, currentUsername, wombat } from '../data/config';
import { PROPOSAL_STATUSES, resizerPrefix, resizerSuffix } from '../data/constants';
import { CollectionProfileAvatarCont, CollectionProfileBGContainer, CollectionProfileContainer } from '../data/css/CollectionPageStyles';
import collection_profile_stock_bg from "../data/stars.png";
import { ExploreFiltersCont, ExploreFiltersInnerCont, ExploreFiltersScrollCont, FoldersContainer, FoldersRow, MainButton, NewListingBody, SingleFolderFourth, TallFiltersCont, TallFiltersInnerCont } from '../Styles';
import { depositToTreasury, showDaoInfo, showProposals, stakeTokens, submitProposal, unstakeTokens } from '../data/functions/dao_functions';
import LoadingPage from '../components/LoadingPage';
import { getPrecisionFromSymbol, getTokenNameFromSymbol } from '../data/functions/pool_functions';

const handleChoiceAdd = (setChoicesList, choicesList) => {

    setChoicesList([...choicesList, { choice: ''}])

}

const handleChoiceRemove = (setChoicesList, choicesList, index) => {
    const list = [...choicesList];
    list.splice(index, 1);
    setChoicesList(list);

}

const handleChoiceChange = (e, index, choicesList, setChoicesList) => {
    const {name, value} = e.target;
    const list = [...choicesList];
    list[index][name] = value;
    setChoicesList(list);

}

const DAOPage = ({ location }) => {

  const { tokenName, decimals,
    farmContract, setFarmContract, unstaked, claimable, 
    setClaimable, minTime, setMinTime, setRamBalance, farmType, refreshAssets, 
    stakeTokensDisplay, setStakeTokensDisplay, unstakeTokensDisplay, 
    setUnstakeTokensDisplay, proposalList, setProposalList, setProposalType, proposalTypeValue, setProposalTypeValue,
    choicesList, setChoicesList, description, setDescription, proposalTitle,
    setProposalTitle,
    walletDisplay, setWalletDisplay, proposerType, setProposerType, proposers, setProposers,
    choicesDisplay, setChoicesDisplay, receiverDisplay, setReceiverDisplay, receivingAccount, setReceivingAccount,
    proposalSymbol,
    proposalDecimals,
    proposalContract,
    setProposalSymbol,
    setProposalDecimals,
    setProposalContract,
    treasuryDepositAmount,
    setTreasuryDepositAmount,
    treasuryDepositDisplay,
    setTreasuryDepositDisplay,
    minWeight,
    setMinWeight,
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    enterButtonsDisplay,
    setEnterButtonsDisplay,

    } = useStateContext();

const { DAOName } = useParams();

const [daoData, setDaoData] = useState([]);
const [stakeData, setStakeData] = useState([]);
const [tokenBalance, setTokenBalance] = useState("");
const [proposals, setProposals] = useState([]);
const [treasuryAssets, setTreasuryAssets] = useState([]);
const [currentSection, setCurrentSection] = useState("Info");
const [currentAction, setCurrentAction] = useState("New Proposal");
const [daoDataWasLoaded, setDaoDataWasLoaded] = useState(false);
const [depositAmount, setDepositAmount] = useState(0);
const [stakeAmount, setStakeAmount] = useState(0);
const [unstakeAmount, setUnstakeAmount] = useState(0);
const [proposalAmount, setProposalAmount] = useState(0);

const [dropTimer, setDropTimer] = useState("");
const [timerWasUpdated, setTimerWasUpdated] = useState(true);

useEffect(() => {
    const timer = setTimeout(() => {
      if (stakeData?.unlocktime != null) {
        let current_time = Date.now() / 1000;
        if (current_time < stakeData.unlocktime) {
          let total_minutes_left = Math.round(
            Number(stakeData.unlocktime - current_time) / 60
          );
          let days_left = Math.floor(total_minutes_left / 1440);
          let hours_left =  Math.floor( (total_minutes_left - (days_left * 1440) ) / 60 );
          let minutes_left = total_minutes_left - (days_left * 1440) - (hours_left * 60);
          setDropTimer(days_left + "d " + hours_left + "h " + minutes_left + "m");
        } else {
          setDropTimer("");
        }
      }
      setTimerWasUpdated((prev) => !prev);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [dropTimer, timerWasUpdated]);



useEffect(() => {

  axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
    table:"daos",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    limit:1,
    key_type: 'name',
    index_position: 2,
    lower_bound:DAOName,
    upper_bound:DAOName,
    json:true
  })
    .then((response) => {

      setDaoData(response.data.rows[0]);
      setDaoDataWasLoaded(true);

      let daocontract = response.data.rows[0].contract;
      let daosymbol = response.data.rows[0].tokensymbol.substr(response.data.rows[0].tokensymbol.indexOf(',') + 1);


      if(response.data.rows[0].staketype == "token"){
        if(currentUsername){
          axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
              table:"accounts",
              scope: currentUsername,
              code: daocontract,
              limit:100,
              lower_bound:daosymbol,
              upper_bound:daosymbol,
              json:true
            })
          .then((res) => {
            setTokenBalance(res.data.rows[0].balance);
          }) 
          .catch((error) => console.log(error));
        }
      }


    })
    .catch((error) => console.log(error));

}, [refreshAssets]);








useEffect(() => {

setClaimable([]);

  if(!currentUsername){
    return;
  }



  axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
    table:"tokenpower",
    scope:"buildawaxdao",
    code:"buildawaxdao",
    key_type: 'name',
    index_position: 2,
    limit:100,
    lower_bound:currentUsername,
    upper_bound:currentUsername,
    json:true
  }).then((claimableResponse) => {
    let claimitr = 0;

    while(claimitr < claimableResponse.data.rows.length){
      if(claimableResponse.data.rows[claimitr].daoname == DAOName){
        setStakeData(claimableResponse.data.rows[claimitr]);
        break;
      }
      else{
        claimitr ++;
      }
    }


  })

.catch((error) => console.log(error));

}, []);



useEffect(() => {
   
      axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
        table:"proposals",
        scope:"buildawaxdao",
        code:"buildawaxdao",
        key_type: 'name',
        index_position: 2,
        limit:100,
        lower_bound:DAOName,
        upper_bound:DAOName,
        reverse: true,
        json:true
      }).then((proposalResponse) => {
           setProposals(proposalResponse.data.rows);
      })
    
    .catch((error) => console.log(error));
    
    }, []);


    useEffect(() => {

        axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
          table:"cointreasury",
          scope:"buildawaxdao",
          code:"buildawaxdao",
          key_type: 'name',
          index_position: 2,
          limit:100,
          lower_bound:DAOName,
          upper_bound:DAOName,
          json:true
        }).then((treasuryResponse) => {
             setTreasuryAssets(treasuryResponse.data.rows);
        })
      
      .catch((error) => console.log(error));
      }, []);


if(daoDataWasLoaded){
  return (

    <div id="seo">
    <Helmet>
    <title>{DAOName} DAO - WAX Blockchain</title>
    <meta name="description" content={`Votes, propsals and details for ${DAOName} DAO on WaxDao`} />
    <link rel="canonical" href={`https://waxdao.io/daos/${DAOName}`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>


    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
    </Modal>


    <CollectionProfileContainer>
            <CollectionProfileBGContainer>
              {daoData?.image1 != null && profile?.image1?.length > 0 ? (
                <img
                  src={`${"https://ipfs.io/ipfs/"}${profile.image1}`}
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={collection_profile_stock_bg}
                  style={{
                    width: "auto",
                    height: "auto",
                    minHeight: "100%",
                    minWidth: "100%",
                  }}
                />
              )}
            </CollectionProfileBGContainer>

            <CollectionProfileAvatarCont>
              <img
                src={
                  daoData.logo != null &&
                  `${resizerPrefix}${daoData.logo}${resizerSuffix}`
                }
                style={{
                  width: "auto",
                  height: "auto",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              />
            </CollectionProfileAvatarCont>
          </CollectionProfileContainer>

          <div className="p-5 mt-4 text-white">
            <br />
            <span className="text-2xl font-semibold">{DAOName}</span>
            <br />
            <span className="text-md">
              {daoData?.creator != null && <span>By {daoData.creator}</span>}
            </span>

          </div>

<NewListingBody>
  <TallFiltersCont>
  <h2>DAO Details</h2>
  <br/>
                    <FoldersContainer>
                    <FoldersRow>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentSection("Info");
                        }}
                        selected={currentSection == "Info" && true}
                      >
                        Info
                      </SingleFolderFourth>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentSection("Proposals");
                        }}
                        selected={currentSection == "Proposals" && true}
                      >
                        Proposals
                      </SingleFolderFourth>
                    </FoldersRow>
                  </FoldersContainer>
  <TallFiltersInnerCont>
    {currentSection == "Info" && showDaoInfo(daoData, treasuryAssets)}
    {currentSection == "Proposals" && showProposals(proposals)}
  </TallFiltersInnerCont>
  </TallFiltersCont>


  <TallFiltersCont>
  <h2>Actions</h2>
  <br/>
                    <FoldersContainer>
                    <FoldersRow>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentAction("New Proposal");
                        }}
                        selected={currentAction == "New Proposal" && true}
                      >
                        New Proposal
                      </SingleFolderFourth>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentAction("Treasury Deposit");
                        }}
                        selected={currentAction == "Treasury Deposit" && true}
                      >
                        Treasury Deposit
                      </SingleFolderFourth>

                      <span className={daoData?.staketype != "token" && "hidden"}>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentAction("Stake");
                        }}
                        selected={currentAction == "Stake" && true}
                      >
                        Stake
                      </SingleFolderFourth>
                      <SingleFolderFourth
                        onClick={(e) => {
                          setCurrentAction("Unstake");
                        }}
                        selected={currentAction == "Unstake" && true}
                      >
                        Unstake
                      </SingleFolderFourth>
                      </span>

                    </FoldersRow>
                  </FoldersContainer>
  <TallFiltersInnerCont>

  <span className={currentAction != "Treasury Deposit" && "hidden"}>
  
  <h3>WAX Amount:</h3>
  <input type="number" value={depositAmount} onChange={(e) => {setDepositAmount(e.target.value)}} />
  <br/><br/>
  <MainButton
    onClick={() => {depositToTreasury(DAOName, depositAmount, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}}
  >
    Deposit
  </MainButton>


  </span>

  <span className={currentAction != "Stake" && "hidden"}>

  <h3>My Balance:</h3> 
  {tokenBalance != null && tokenBalance}
  <br/><br/>
  
  <h3>{getTokenNameFromSymbol(daoData?.tokensymbol)} Amount:</h3>
  <input type="number" value={stakeAmount} onChange={(e) => {setStakeAmount(e.target.value)}} />
  <br/><br/>
  <MainButton
    onClick={() => {stakeTokens(  
        DAOName,
        stakeAmount,
        daoData.contract,
        getTokenNameFromSymbol(daoData?.tokensymbol),
        getPrecisionFromSymbol(daoData?.tokensymbol),
        setEnterModalText,
        setLoadingDisplay,
        setEnterButtonsDisplay,
        setEnterModalDisplay
      )}}
  >
    Stake
  </MainButton>


  </span>


  <span className={currentAction != "Unstake" && "hidden"}>

  <h3>My Staked Balance:</h3>
  {stakeData.stakeweight}
  <br/><br/>

  <h3>Unlock Time:</h3>
  {dropTimer != "" && dropTimer}
  {dropTimer == "" && "Unlocked"}
  <br/><br/>
  
  {/* 
  <h3>{getTokenNameFromSymbol(daoData?.tokensymbol)} Amount:</h3>
  <input type="number" value={unstakeAmount} onChange={(e) => {setUnstakeAmount(e.target.value)}} />
  <br/><br/>
  */}

  <MainButton
    onClick={() => {unstakeTokens(  
        DAOName,
        setEnterModalText,
        setLoadingDisplay,
        setEnterButtonsDisplay,
        setEnterModalDisplay
      )}}
  >
    Unstake
  </MainButton>


  </span>



  <span className={currentAction != "New Proposal" && "hidden"}>


{proposerType != null && proposerType == 'usernames' && (
    <ProposerTypeMessage>
        Only the following WAX accounts can create proposals for this DAO:
        <ProposerList>
            {proposers != null && proposers.map((item, index) => (
                <span>{item}{index < proposers.length -1 && ', '} </span>
            ))}
        </ProposerList>
    </ProposerTypeMessage>

)}

{farmType != null && farmType == "nftfarm" && proposerType != null && proposerType == 'stakeweight' && (
    <ProposerTypeMessage>
        You must have at least {minWeight != null && minWeight} NFTs staked to create a proposal
    </ProposerTypeMessage>

)}



<br />

         <form>
        <select         
            onChange={(e) => {
                if(e.target.value == "standard"){ setChoicesDisplay(''); setReceiverDisplay('hidden'); } 
                if(e.target.value == "tkn.transfer"){ setChoicesDisplay('hidden'); setReceiverDisplay(''); } 
                
                setProposalType(e.target.value);
                setProposalTypeValue(e.target.value);
            }}>
        <option value="" hidden>
          Proposal Type
        </option>
        <option value="standard">Standard</option>
        <option value="tkn.transfer">Transfer Tokens</option>
        </select>
        </form>


    <label htmlFor="propTitle" ><br />Proposal Title: <br /></label>
      <ProposalInput
      type="text"
      maxLength={40}
      id="propTitle" 
      onChange={(e) => {
        setProposalTitle(e.target.value);
      }}
      />



    <label htmlFor="description" ><br />Description: <br /></label>
      <PropDescription
      placeholder="What is the reason for this proposal?"
      rows={4}
      id="description" 
      onChange={(e) => {
        setDescription(e.target.value);
      }}
      />


<span className={choicesDisplay}>
<p className="font-semibold mt-4">
    Choices
</p>

{choicesList.map((singleChoice,index) => (


<div key={index} className="w-100 text-center justify-center">

        <ProposalChoices
        placeholder={`Option ${index}`}
        rows={4}
        name="choice"
        id="choice" 
        value={singleChoice.choice}
        onChange={(e) => handleChoiceChange(e, index, choicesList, setChoicesList)}
        />


{choicesList.length > 1 && 

<RemoveButton 
onClick={() => {
    handleChoiceRemove(setChoicesList, choicesList, index);
}}
>
&nbsp; - &nbsp;
</RemoveButton>


}







{choicesList.length - 1 === index && choicesList.length < 4 && 
        <span>
            <br/>
        <AddButton 

            onClick={() => {
                handleChoiceAdd(setChoicesList, choicesList);
            }}

            
            >
            &nbsp; + &nbsp;
        </AddButton>
        </span>

}




</div>

)


)}

</span>

<span className={receiverDisplay}>
  <br/><br/>
Receiving Account:<br/>
      <input
      type="text"
      maxLength={12}
      value={receivingAccount}
      id="propReceiver" 
      onChange={(e) => {
        setReceivingAccount(e.target.value.replace(/[' ']/g, ''));
      }}
      />
      <br/>

<label htmlFor="description" ><br />WAX To Send: <br /></label>
      <ProposalInput
      id="description" 
      value={proposalAmount}
      type="number"
      onChange={(e) => {
        setProposalAmount(e.target.value);
      }}
      />

<label htmlFor="description" ><br />Token Symbol: <br /></label>
      <ProposalInput
      id="description"
      maxLength={7} 
      value={proposalSymbol}
      onChange={(e) => {
        setProposalSymbol(e.target.value.toUpperCase());
      }}
      />

<label htmlFor="description" ><br />Token Precision: <br /></label>
      <ProposalInput
      id="description" 
      type="text"
      value={proposalDecimals}
      onChange={(e) => {
        setProposalDecimals(e.target.value.replace(/[' ']/g, ''));
      }}
      />

<label htmlFor="description" ><br />Token Contract: <br /></label>
      <ProposalInput
      id="description" 
      maxLength={12}
      value={proposalContract}
      onChange={(e) => {
        setProposalContract(e.target.value.replace(/[' ']/g, ''));
      }}
      />




</span>

<br/>



<br/>
<div className='text-center w-100 justify-center'>
        <SubmitButton 
        onClick={() => {
            submitProposal(DAOName, proposalTypeValue, choicesList, description, proposalTitle, proposalAmount, proposalSymbol, proposalDecimals, proposalContract, receivingAccount, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay);
        }}        
        >
          Submit Proposal
        </SubmitButton>
        </div>




</span>


  </TallFiltersInnerCont>
  </TallFiltersCont>

</NewListingBody>


    <MainContainer>


  













<Wallet className={walletDisplay}>

<DaoTitle>
    My Wallet
</DaoTitle>



{(farmType != "tokenpool" && farmType != "nftfarm" ) && (

<DaoButtonCont>
    <DaoButton 
        onClick={() => {    
        setStakeTokensDisplay('');
        setUnstakeTokensDisplay('hidden');
        setTreasuryDepositDisplay('hidden');
    }}
    >
        Stake {tokenName}
    </DaoButton>

    <DaoButton 
        onClick={() => {
            setStakeTokensDisplay('hidden');
            setUnstakeTokensDisplay('');
            setTreasuryDepositDisplay('hidden');
        }}
    >
        Unstake
    </DaoButton>

    <DaoButton onClick={() => openRow(decimals, tokenName, farmContract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}>
        Open {tokenName} Row
    </DaoButton>

    </DaoButtonCont>

)}



<DaoButtonCont>
<DaoButton onClick={() => depositTokenRam(DAOName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)} >
        Deposit RAM
    </DaoButton>
    

    <DaoButton onClick={() => {
                  setStakeTokensDisplay('hidden');
                  setUnstakeTokensDisplay('hidden');
                  setTreasuryDepositDisplay('');
                 }
                }>
        Treasury Deposit
    </DaoButton>

</DaoButtonCont>

<div className={`${stakeTokensDisplay} mt-4`}>
    <StakedAmount>
        Balance: {unstaked}
    </StakedAmount>


    <label htmlFor="ram" ><br />Amount To Stake:<br /></label>
      <ProposalInput type="number" 
      id="ram" 
      value={depositAmount}
      onChange={(e) => {
        setDepositAmount(Math.round(e.target.value));
      }}
      />
      <br/>

        <DaoButton onClick={() => stakeTokens(DAOName, depositAmount, farmContract, tokenName, decimals, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)} >
            Stake Now
        </DaoButton>
</div>


<div className={`${unstakeTokensDisplay} mt-4`}>
    <StakedAmount>
        Staked: {claimable}
    </StakedAmount>

    <DaoButton onClick={() => { unstakeTokens(DAOName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay); }} >
        Unstake All
    </DaoButton>
</div>


</Wallet>















    </MainContainer>
    <br/><br/><br/>
    </UALProvider>
    </div>
  )}
  else{
    return <LoadingPage />
  }
}

export default DAOPage