import { getWalletSession } from "../../data/functions/wallet_functions";


export const createPool = async (paymentMethod, farmName, farmLogo, tokenToStake, decimals, tokenContract, rewardToken, rewardDecimals, rewardContract, stakingSeconds, timestamp, stakingAmount, assetToBurn, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay("");
    setEnterModalText("Awaiting confirmation...");
  
    let walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    let this_user = walletSession[1];
    let this_permission = walletSession[2];
  
  
    
    if(paymentMethod == "Pay 250 Wax"){
  
    try {
      
      const action = [{
            account: 'eosio.token',
            name: 'transfer',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: this_user,
              quantity: '250.00000000 WAX',
              memo: '|createpool|' + farmName + '|' + rewardContract + '|' + farmLogo + '|' + timestamp + '|' + stakingAmount + '|' + stakingSeconds + '|' + tokenToStake + '|'  + decimals + '|' + tokenContract + '|' + rewardToken + '|' + rewardDecimals + '|',
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 180,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Creating your pool...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your pool has been created. You can deposit rewards on the inventory page');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 2000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
    } //end if paymentMethod == WAX
  
  
    if(paymentMethod == "Pay 25,000 WOJAK"){
  
      try {
        const action = [{
              account: 'mdcryptonfts',
              name: 'transfer',
              authorization: [{
                actor: this_user,
                permission: this_permission,
            }],
            data: {
                to: 'waxdaofarmer',
                from: this_user,
                quantity: '25000 WOJAK',
                memo: '|createpool|' + farmName + '|' + rewardContract + '|' + farmLogo + '|' + timestamp + '|' + stakingAmount + '|' + stakingSeconds + '|' + tokenToStake + '|'  + decimals + '|' + tokenContract + '|' + rewardToken + '|' + rewardDecimals + '|',
            }
          }]
        const response = await session[0].signTransaction({
          actions: action
        }, {
    
          blocksBehind: 3,
          expireSeconds: 180,
          broadcast: true,
        })
        setLoadingDisplay('');
        setEnterButtonsDisplay('hidden');
        setEnterModalText('Creating your pool...')
        const timer = setTimeout(() => {
          
            setEnterModalText('Your pool has been created. You can deposit rewards on the inventory page');
            setLoadingDisplay('none');
            setEnterButtonsDisplay('');
      
        }, 2000);
        return () => clearTimeout(timer);
    
      } catch(e) {
        setEnterModalText(JSON.stringify(e.message));
        console.log(e)
        
      }
    
      } //end if paymentMethod == WOJAK
  
  
      if(paymentMethod == "Burn 1 Wojak NFT"){
  
        try {

          const action = [{
            account: 'atomicassets',
            name: 'transfer',
            authorization: [{
            actor: this_user,
            permission: this_permission,
            }],
            data: {
            asset_ids: [assetToBurn],
            from: this_user,
            to: 'waxdaofarmer',
            memo: '|createpool|' + farmName + '|' + rewardContract + '|' + farmLogo + '|' + timestamp + '|' + stakingAmount + '|' + stakingSeconds + '|' + tokenToStake + '|'  + decimals + '|' + tokenContract + '|' + rewardToken + '|' + rewardDecimals + '|',
              }
            }]
          const response = await session[0].signTransaction({
            actions: action
          }, {
     
            blocksBehind: 3,
            expireSeconds: 180,
            broadcast: true,
          })
          setLoadingDisplay('');
          setEnterButtonsDisplay('hidden');
          setEnterModalText('Creating your pool...')
          const timer = setTimeout(() => {
            
              setEnterModalText('Your pool has been created. You can deposit rewards on the inventory page');
              setLoadingDisplay('none');
              setEnterButtonsDisplay('');
        
          }, 2000);
          return () => clearTimeout(timer);
      
        } catch(e) {
          setEnterModalText(JSON.stringify(e.message));
          console.log(e)
          
        }
      
        } //end if paymentMethod == burn NFT
  
  }


export const getTokenHelp = (setEnterModalDisplay, setEnterModalText) => {
    setEnterModalDisplay(""); 
    
    setEnterModalText(<span>
        For WAX, the info is: <br/><br/>
        Contract: eosio.token<br/>
        Precision: 8<br/>
        For other tokens, search our <a href="https://waxdao.io/tokens" target="none" className="text-secondary">Token Tracker</a> for this info.
      </span>);

}

export const poolIsExpired = (expires) => {
  let isExpired = false;

  if(expires < Date.now() / 1000){
    isExpired = true;
  }
  return isExpired
}