import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { UALProvider } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import {
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  StakeManyDiv,
} from "../../components/LeftDiv";
import { Name } from "eos-common";
import {
  wax,
  anchor,
  myChain,
  currentAtomicHubLink,
  currentUsername,
  getCurrentApiList,
  wombat,
} from "../../data/config";
import {
  getFarmSocials,
} from "../../data/functions/farm_functions";
import {
  CollectionProfileAvatarCont,
  CollectionProfileBGContainer,
  CollectionProfileContainer,
} from "../../data/css/CollectionPageStyles";
import collection_profile_stock_bg from "../../data/collection_profile_stock_bg.png";
import waxapes_cover from "../../data/waxapes_cover.png";
import {
  FarmDescriptionContainer,
  FoldersContainer,
  FoldersRow,
  MainButton,
  MilestoneContainer,
  MilestoneDescription,
  NewListingBody,
  RequiredNftsContainer,
  SingleFolderThird,
  SmallButton,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import { resizerPrefix, resizerSuffix } from "../../data/constants";

import LoadingPage from "../../components/LoadingPage";
import { isLoggedIn } from "../../data/functions/wallet_functions";
import { useGetV2FarmInfo } from "../../components/CustomHooks/useGetV2FarmInfo";
import Error from "../../components/Error";
import { claimV2, showEditFarmProfile, showEditFarmRewards, showEditFarmStatus, showFarmStatus, showNftIngredientTitle, stakeV2, unstakeV2 } from "./v2_farm_functions";
import { useGetV2Stakeables } from "../../components/CustomHooks/useGetV2Stakeables";
import useGetRequiredNfts from "../../components/CustomHooks/useGetRequiredNfts";
import { useGetV2FarmRequirements } from "../../components/CustomHooks/useGetV2FarmRequirements";
import LoadingDiv from "../../components/LoadingDiv";
import FarmNFTCard from "../../components/FarmNFTCard";
import { RecentFarmsContainer } from '../../data/css/FarmsListCSS';
import V2FarmNftCard from "../../components/V2FarmNftCard";
import { useGetAllV2StakedAssets } from "../../components/CustomHooks/useGetAllV2StakedAssets";

const FarmPageV2 = ({ location }) => {
  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
  } = useStateContext();

  const { FarmName } = useParams();

  const [currentSection, setCurrentSection] = useState("Status");
  const [currentRewardTab, setCurrentRewardTab] = useState("Stakeables");
  const [currentStakeablesTab, setCurrentStakeablesTab] = useState("View");
  const [collectionToAdd, setCollectionToAdd] = useState("");
  const [schemaToAdd, setSchemaToAdd] = useState("");
  const [showOpenFarmModal, setShowOpenFarmModal] = useState(false);
  const [openOrExtend, setOpenOrExtend] = useState("Open");
  const [assetVector, setAssetVector] = useState([]);

  //Custom Hooks
  const [farmData, getFarmData, farmDataIsLoading, farmProfile, farmSocials, setFarmProfile, 
    setFarmSocials, amountsToAdd, setAmountsToAdd, amountsToDeposit, setAmountsToDeposit] = useGetV2FarmInfo();
    const [requirements, getRequirements, requirementsAreLoading] = useGetV2FarmRequirements();
    const [stakeables, getStakeables, stakeablesAreLoading] = useGetV2Stakeables();
    const [nfts, getRequiredNfts, loadingStates, fetchAttempts] = useGetRequiredNfts();
    const [stakedAssets, getAllStakedAssets, assetsAreLoading, assetsInThisFarm] = useGetAllV2StakedAssets();

  useEffect(() => {
    getFarmData(FarmName)
  }, [])

  useEffect(() => {
    if(!farmDataIsLoading && farmData != null){
        getRequirements(FarmName, farmData.farm_type)
    }
  }, [farmData, farmDataIsLoading])  

  useEffect(() => {
    if(isLoggedIn() && !farmDataIsLoading && !requirementsAreLoading){
        getAllStakedAssets(currentUsername, FarmName)
    }
  
  }, [farmDataIsLoading, requirementsAreLoading])
  
  


  if (!farmDataIsLoading && farmData != null) {
    return (
      <div id="seo">
        <Helmet>
          <title>{FarmName} Details</title>
          <meta
            name="description"
            content={`Stake NFTs in the ${FarmName} farm on WaxDao`}
          />
          <link rel="canonical" href={`https://waxdao.io/v2/farm/${FarmName}`} />
        </Helmet>

        <UALProvider
          chains={[myChain]}
          authenticators={[anchor, wax, wombat]}
          appName={"WaxDAO"}
        >
          <Modal className={enterModalDisplay}>
            <ModalContent>{enterModalText}</ModalContent>

            <Spinner style={{ display: loadingDisplay }}>
              <SpinnerRed />
              <SpinnerBlue />
              <SpinnerGreen />
            </Spinner>

            <br />
            <GameButton onClick={() => setEnterModalDisplay("hidden")}>
              Close Window
            </GameButton>
          </Modal>

          <CollectionProfileContainer>
            <CollectionProfileBGContainer>
              {farmProfile?.cover_image != null && farmProfile?.cover_image.length > 0 ? (
                <img
                  src={`${resizerPrefix}${farmProfile?.cover_image}`}
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={collection_profile_stock_bg}
                  style={{
                    width: "auto",
                    height: "auto",
                    minHeight: "100%",
                    minWidth: "100%",
                  }}
                />
              )}
            </CollectionProfileBGContainer>

            <CollectionProfileAvatarCont>
              <img
                src={
                    farmProfile.avatar != null &&
                  `${resizerPrefix}${farmProfile.avatar}${resizerSuffix}`
                }
                style={{
                  width: "auto",
                  height: "auto",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              />
            </CollectionProfileAvatarCont>
          </CollectionProfileContainer>

          <div className="p-5 mt-4 text-white">
            <br />
            <span className="text-2xl font-semibold">{FarmName}</span>
            <br />
            <span className="text-md">
              {farmData.creator != null && <span>By {farmData.creator}</span>}
            </span>

            {farmProfile?.description != null &&
              farmProfile?.description?.length > 3 && (
                <FarmDescriptionContainer>
                  <MilestoneDescription>
                    {farmProfile.description}
                  </MilestoneDescription>
                </FarmDescriptionContainer>
              )}         

              {getFarmSocials(farmSocials)}
          </div>

          <NewListingBody>

          <span className={assetVector.length == 0 && "hidden"}>
              <StakeManyDiv
                onClick={() => {
                    stakeV2(  farmData,
                        assetVector,
                        setEnterModalText,
                        setLoadingDisplay,
                        setEnterButtonsDisplay,
                        setEnterModalDisplay)

                }}
              >
                Stake {assetVector.length} NFTs <br />
              </StakeManyDiv>
            </span>


            <TallFiltersCont>
                <h2>Farm Details</h2>
                <br/>
                <FoldersContainer>
                    <FoldersRow>

                        <SingleFolderThird
                            selected={currentSection == "Status" && true}
                            onClick={() => setCurrentSection("Status")}                        
                        >
                            Farm Status
                        </SingleFolderThird>     

                                              
                    </FoldersRow>
                </FoldersContainer>
                <br/>
                <TallFiltersInnerCont>


                    {currentSection == "Status" && (
                        showFarmStatus(farmData)
                    )}

                </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
                <h2>Actions</h2>
                <br/>
                <TallFiltersInnerCont>
                    <div className="flex gap-4 w-100 flex-wrap">
                    <SmallButton
                        onClick={() => {
                            claimV2(  FarmName,
                                setEnterModalText,
                                setLoadingDisplay,
                                setEnterButtonsDisplay,
                                setEnterModalDisplay)
                        }}
                    >
                        Claim
                    </SmallButton>

                    <SmallButton
                        onClick={() => {
                            unstakeV2(  FarmName,
                                assetsInThisFarm,
                                setEnterModalText,
                                setLoadingDisplay,
                                setEnterButtonsDisplay,
                                setEnterModalDisplay)
                        }}
                    >
                        Unstake All
                    </SmallButton>         
                    </div>           
                </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
                            <h2>Stakeable NFTs</h2>
                            <br/>
                            <TallFiltersInnerCont>

                            {requirements.map((item, index) => (
                            <span key={index}>
                             {showNftIngredientTitle(farmData, item)}
                             <MilestoneContainer>
                                This NFT earns {item.reward_values.map((val, valIndex) => (
                                    <span key={valIndex}>{val.quantity}{valIndex < item.reward_values.length - 1 && ","}{" "}</span>
                                ))} per payout
                             </MilestoneContainer>
                            
                                <br/><br/>
                                {fetchAttempts[index] > 0 && nfts.find(nft => nft.index === index)?.nfts.length === 0 && (
                                  <span>
                                    No NFTs found
                                    <br/><br/>
                                  </span> 
                                )}  


                                <MainButton className={(nfts.find(nft => nft.index === index)?.nfts.length > 0 || loadingStates[index] ) ? 'hidden' : ''}
                                  onClick={() => {
                                    getRequiredNfts( currentUsername,
                                      index,
                                      farmData.farm_type,
                                      item.collection_name,
                                      item.schema_name,
                                      item.template_id,
                                      item.attributes)
                                  }}
                                >
                                  Scan Wallet {fetchAttempts[index] > 0 && "Again"}
                                </MainButton>


                                <span className={!loadingStates[index] && "hidden"}>
                                  <LoadingDiv />
                                </span>
                                                          
                                {nfts.find(nft => nft.index === index)?.nfts.length > 0 && (
                                    <span>
    
                                  <RequiredNftsContainer>
                                    {nfts.find(nft => nft.index === index)?.nfts.map((nft, nft_index) => (
                                      <span key={nft_index}>
                                  <V2FarmNftCard item={nft} assetVector={assetVector} setAssetVector={setAssetVector} listing_index={index} stakedAssets={stakedAssets}  />
                                  </span>
                                  ))}
                                  </RequiredNftsContainer>
                                  </span>
                                )}

                                
                                <br/><br/>
                            </span>
                        ))}                                
                            </TallFiltersInnerCont>
                        </TallFiltersCont>

        
          </NewListingBody>
        </UALProvider>
        <br />
        <br />
        <br />
      </div>
    );
  } else if(farmDataIsLoading){
    return <LoadingPage />
  } else if(!farmDataIsLoading && farmData == null){
    return <Error error="Farm could not be located" />
  }
  
  
  else {
    return <LoadingPage />;
  }
};

export default FarmPageV2;
