import styled from 'styled-components'
import { theme } from '../../Styles'


<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>

//COLORS
//#c30000
//#47158e
//#dc0bb6

export const TokenListBody = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    padding:1em;
    margin-top: 80px;
    overflow: hidden;
    

`


export const TokenPageCont = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto; 
    margin-right: auto;
    border-radius: 5px;
    padding-bottom: 50px;
    //padding:1em;
    margin-top: 80px;

`

export const TokenList = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    //border-bottom: 1px solid dimgray;
    height: auto;
    color: #dfdfdf;

    
    
    //overflow-y: hidden;
    

`

export const TokenListHeader = styled.div`
    white-space: nowrap;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid dimgray;
    border-bottom: 1px solid dimgray;
    height: 50px;
    background-color: #19181869;
    //width: 900px;
    //max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    ::-webkit-scrollbar{
        display: none;
    }
    
    //overflow-x: scroll;
    
    //overflow-x: scroll;

`

export const HeaderLogo = styled.div`
    width: 40px;
    height: 30px;
    margin-left: 1em;
    margin-top: 10px;
    
    //display: inline-block;
    float: left;
`

export const HeaderPrice = styled.div`
    width: 150px;
    height: 50px;
    font-weight: 900;
    font-size: 14px;
    display: inline-block;
    line-height: 30px;
    margin-left: 1em;
    text-align: right;

`

export const HeaderVolume = styled.div`
    width: 150px;
    height: 50px;
    font-weight: 900;
    font-size: 14px;
    display: inline-block;
    line-height: 30px;
    margin-left: 1em;
    text-align: right;

`

export const HeaderChange24 = styled.div`
    width: 80px;
    height: 50px;
    font-weight: 900;
    font-size: 14px;
    display: inline-block;
    line-height: 30px;
    margin-left: 1em;
    text-align: right;

`

export const HeaderMarketCap = styled.div`
    //border: 1px solid green;
    width: 200px;
    height: 50px;
    font-weight: 900;
    font-size: 14px;
    display: inline-block;
    line-height: 30px;
    margin-left: 1em;
    text-align: right;
    padding-right: 1em;

`

export const SingleTokenRow = styled.div`
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid dimgray;
    height: 60px;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    ::-webkit-scrollbar{
        display: none;
    }
`

export const TokenListLogo = styled.img`
    width: 40px;
    height: 40px;
    margin-left: 1em;
    margin-top: 10px;
    //display: inline-block;
    float: left;
`

export const TokenListSymbol = styled.div`
    width: 60px;
    height: 60px;
    font-weight: 900;
    font-size: 14px;
    display: inline-block;
    line-height: 60px;
    margin-left: 1em;

`

export const TokenListPrice = styled.div`
    width: 150px;
    height: 60px;
    //font-weight: 900;
    font-size: 14px;
    display: inline-block;
    line-height: 60px;
    margin-left: 1em;
    text-align: right;

`

export const TokenListVolume = styled.div`
    width: 150px;
    height: 60px;
    //font-weight: 900;
    font-size: 14px;
    display: inline-block;
    line-height: 60px;
    margin-left: 1em;
    text-align: right;

`

export const TokenListChange24 = styled.div`
    width: 80px;
    height: 60px;
    //font-weight: 900;
    font-size: 14px;
    display: inline-block;
    line-height: 60px;
    margin-left: 1em;
    text-align: right;

`

export const TokenListMarketCap = styled.div`
    width: 200px;
    height: 60px;
    //font-weight: 900;
    font-size: 14px;
    display: inline-block;
    line-height: 60px;
    margin-left: 1em;
    text-align: right;
    padding-right: 1em;

`

export const TokenPageAlert = styled.div`

    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;
    font-size: 18px;
    font-weight: 900;
    width: 90%;
    border-radius: 5px;
    background-color: #c30000b0;
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    word-break: break-word;

`

export const TokenPageAlertTwo = styled.div`

    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;
    font-size: 18px;
    font-weight: 900;
    width: 90%;
    border-radius: 5px;
    background-color: #c37500af;
    color: white;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    word-break: break-word;

`


export const TokenPageFirstCont = styled.div`
    display: flex;
    font-size: 36px;
    color: #dfdfdf;
    //border-right: 1px solid #bcbcbc28;
    width: 410px;
    float: left;

    @media (max-width: 810px) {
        width: 95%;
          
    }
    
`

export const NameAndPriceCont = styled.div`
    display: inline-block;
    width: 400px;
    //float: left;
    

    @media (max-width: 810px) {
        //clear:both;
        //color: pink;
        width: 100%;
          
    }

`
export const TokenPageInfoCont = styled.div`
    display: inline-block;
    font-size: 36px;
    color: #dfdfdf;
    width: 400px;
    padding-left: 1em;


    @media (max-width: 810px) {

        width: 100%;
        display: none;
          
    }

`

export const TokenPageMobileInfoCont = styled.div`
    display: none;
    font-size: 36px;
    color: #dfdfdf;
    width: 400px;
    padding-left: 1em;


    @media (max-width: 810px) {
        display: block;
        width: 100%;
          
    }

`

export const NameAndPriceTopRow = styled.div`
    display: flex;
    //width: 100%;
`

export const NameAndPriceStatsRow = styled.div`
    display: flex;
    //width: 100%;
    border-bottom: 1px solid #bcbcbc28;
    margin-left: 0.7em;
    padding-bottom: 0.2em;
    padding-top: 0.2em;
`
export const StatsTitle = styled.div`
    display: flex;
    //float: left;
    font-size: 14px;
    color: #bcbcbccd;
`

export const StatsStat = styled.div`
    display: flex;
    margin-left: auto;
    //float: right;
    font-weight: 900;
    font-size: 14px;

`



export const TokenPageLogo = styled.img`
    width: 30px;
    height: 30px;
    margin-left: 0.7em;
    margin-top: 10px;
    //display: inline-block;
    //float: left;
`

export const TokenPageSymbol = styled.div`
    width: 30px;
    height: 60px;
    font-weight: 900;
    font-size: 20px;
    display: inline-block;
    line-height: 53px;
    margin-left: 0.7em;

`

export const TokenPagePrice = styled.div`
    width: 100%;
    height: 60px;
    font-weight: 900;
    font-size: 22px;
    display: inline-block;
    line-height: 60px;
    margin-left: 1em;

`

export const TokenPageChange24 = styled.div`
    height: 60px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    justify-items: center;
    
    //font-weight: 900;
    font-size: 18px;
    display: inline-block;
    line-height: 60px;
    margin-left: 0.5em;
    text-align: right;

`




export const InfoContTopRow = styled.div`
    display: flex;
    //width: 100%;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 0.4em;

    
`

export const InfoContWebsiteRow = styled.div`
    display: flex;
    
    width: 100%;
    font-size: 14px;
    margin-bottom: 1em;
    //flex-wrap: wrap;
    //font-weight: 900;

    
`

export const InfoContWebsiteSubject = styled.div`
    display: flex;
    width: 90px;
    font-size: 14px;
    height: 30px;
    //border: 1px solid green;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    color: #bcbcbc;
    //font-weight: 900;

    
`

export const InfoWebsiteButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    //justify-content: center;
    //flex-direction: column;
    vertical-align: middle;
    width: 300px;




    
`

export const InfoWebsiteButton = styled.a`
    display: inline-flex;
    height: 30px;
    color: ${theme.textMain};
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 1em;
    padding-right: 1em;
    font-size: 14px;
    font-weight: 900;
    border-radius: 5px;
    background-color: ${theme.secondary};
    

    :hover{
        cursor: pointer;
        background-color: ${theme.secondaryHover};
    }

    
`

export const TokenPageShowInfoButtonCont = styled.div`
    display:none;
    text-align: center;
    margin-bottom: 15px;
    @media (max-width: 810px) {
        //clear:both;
        //color: pink;
        display:block;
          
    }
`

export const TokenPageShowInfoButton = styled.button`
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: ${theme.secondary};
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;
    width: 95%;

    :hover{
        background-color: ${theme.secondaryHover};
    }
`

export const TokenListTitleCont = styled.div`
    width: 95%;
    //border: 1px solid #bcbcbc28;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    vertical-align: middle;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2em;
    @media (max-width: 501px) {
        text-align: center;
        flex-direction: row;
        display: flex;
        margin-bottom: 35px;
        padding-left: 0px;
          
    }

`

export const PageTitle = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-align: left;
    @media (max-width: 501px) {
        text-align: center;
        
          
    }

`

export const TokenListSearchBar = styled.input`

    color: white;
    background-color: transparent;
    border: 1px solid ${theme.secondary};
    width: 200px;
    max-width: 90%;
    padding: 2px;
    border-radius: 5px;
    padding-left: 0.5em;
    font-size: 18px;
    float: right;

    @media (max-width: 501px) {
        width:100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
        margin-top: 10px;
        
          
    }


    :focus{
        outline: 1px solid white;
    }

`

export const TokenListFilterCont = styled.div`
    width: 95%;
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: 10px;
    color: white;
    border: 1px solid #bcbcbc28;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    //padding: 1em;
    @media (max-width: 501px) {

        margin-top: 20px;
        
          
    }
`


export const TokenListFilterButton = styled.button`
    font-size: 12px;
    font-weight: 500;
    width: 33%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    //color: #bcbcbc;
    //background-color: #c30000;
    //border: 1px solid #bcbcbc;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px;
    height: 30px;
    //margin-left: 1em;

    :hover{
        background-color: ${theme.secondaryHover};
        //color: white;
        //border: 1px solid white;
    }
`

export const TokenListLoadMoreButton = styled.button`
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: ${theme.secondary};
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2em;

    :hover{
        background-color: ${theme.secondaryHover};
    }
`

export const TokenPageQuestionsCont = styled.div`
    padding-top: 1em;
    //font-size: 36px;
    color: #dfdfdf;
    //border-right: 1px solid #bcbcbc;
    width: 95%;
    clear: both;
    margin-left: auto;
    margin-right: auto;

    a{
        color: ${theme.secondary};
    }


`

export const TokenPageQuestion = styled.div`
    font-size: 22px;
    color: white;
    font-weight: 900;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 15px;



`

export const TokenPageAnswer = styled.div`
    font-size: 14px;
    color: #bcbcbcae;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 10px;
    line-height: 1.7em;



`

export const TokenPageNavbar = styled.div`
    font-size: 16px;
    font-weight: 900;
    color: ${theme.secondary};
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 20px;
    line-height: 1.7em;

    a:hover{
        text-decoration: underline;
    }
`

export const TokenPageNavbarTokenName = styled.div`
    display: inline-flex;
    padding-left: 0.5em;
    color: #bcbcbc;
`

export const RequiredInput = styled.input`
    color: black;
    font-size: 14px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    max-width: 90%;
    margin-bottom: 20px;
    
`

export const RequiredLabel = styled.label`
    font-size: 14px;
    font-style: italic;
`