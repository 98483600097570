import React, { useEffect, useState } from 'react';
import { UALProvider } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { AllFarmsCont } from '../../components/LeftDiv';
import { currentApiList, wax, anchor, myChain, currentWebsiteURL, wombat, currentUsername, getCurrentApiList, v2FarmContract } from '../../data/config';
import CurrentBannerAd from '../../components/CurrentBannerAd';
import { ExploreFiltersCont, ExploreFiltersInnerCont, ExploreFiltersScrollCont, FoldersContainer, FoldersRow, JobOfferTitle, NewListingBody, NewListingCont, SingleFolderTab, SkillsButton } from '../../Styles';
import { RecentFarmsContainer } from '../../data/css/FarmsListCSS';
import LoadingPage from '../../components/LoadingPage';
import { farmIsExpired } from '../../data/functions/farm_functions';
import V2FarmCard from '../../components/V2FarmCard';

const FarmsV2 = () => {

  const [farmToSearchFor, setFarmToSearchFor] = useState("");
  const [farmList, setFarmList] = useState([]);
  const [stakedFarmList, setStakedFarmList] = useState([]);
  const [stakedOnly, setStakedOnly] = useState("no");
  const [farmsUserIsIn, setFarmsUserIsIn] = useState([]);
  const [collectionName, setCollectionName] = useState("");
  const [rewardToken, setRewardToken] = useState("");
  const [farmsWereLoaded, setFarmsWereLoaded] = useState(false);
  const [stakedFarmsWereLoaded, setStakedFarmsWereLoaded] = useState(false);
  const [showRecentlyCreated, setShowRecentlyCreated] = useState(true)
  const [showMostPopular, setShowMostPopular] = useState(true)


useEffect(() => {
  const abortCont6 = new AbortController();

  if(stakedOnly != 'yes' && !farmsWereLoaded){

  axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
    table:"farms",
    scope:v2FarmContract,
    code:v2FarmContract,
    limit:1000,
    json:true
  })
    .then((response) => {

      setFarmList(response.data.rows)
      setFarmsWereLoaded(true)
      console.log(response.data.rows)

    })
    .catch((error) => console.log(error));
  }

  else if(stakedOnly == 'yes' && !stakedFarmsWereLoaded){
    if(!currentUsername){return('');}

    axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
      table:"stakebackup",
      scope:"waxdaofarmer",
      code:"waxdaofarmer",
      key_type: 'name',
      index_position: '2',
      limit:1000,
      lower_bound:currentUsername,
      upper_bound:currentUsername,
      json:true
    }).then((userFarmResponse) => {
 
  
    setFarmsUserIsIn(userFarmResponse);

    let thisUsersFarms = [];
    for (var i = 0; i < userFarmResponse.data.rows.length; i++) {
      thisUsersFarms.push(userFarmResponse.data.rows[i].farmname);
    }

    axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
      table:"farms",
      scope:"waxdaofarmer",
      code:"waxdaofarmer",
      limit:1000,
      json:true,
      signal: abortCont6.signal
    })
    .then((response) => {
      setStakedFarmList([]);
      let testList = [];
      for (var b = 0; b < response.data.rows.length; b++) {
        if(thisUsersFarms.includes(response.data.rows[b].farmname)){
          testList.push(response.data.rows[b]);
        }
      }
      setStakedFarmList(testList)
      setStakedFarmsWereLoaded(true)
    })
    .catch((error) => console.log(error));
    })
    .catch((error) => console.log(error));
    } //end if stakedonly == yes


    return() => abortCont6.abort();
}, [stakedOnly]);



if(farmsWereLoaded){
  return (
  <div id="seo">
    <Helmet>
    <title>Farms</title>
    <meta name="description" content="NFT staking pools on WaxDao" />
    <link rel="canonical" href={`${currentWebsiteURL}/farms`} />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>
    <NewListingCont>
      <NewListingBody>

   
      <CurrentBannerAd />
      
      <br/>
      {/* <div className='text-center'> */}
      {/* <WalletSearchBar placeholder='Enter farm name...' value={farmToSearchFor} maxLength={12} onChange={(e) => { setFarmToSearchFor(e.target.value.toLowerCase()) }} /> */}
      {/* </div> */}

      <br/>
        <AllFarmsCont>


        <JobOfferTitle>Recently Created
        <button onClick={() => setShowRecentlyCreated((prev) => !prev)}>
        {showRecentlyCreated ? "x" : "+"}
        </button>
        </JobOfferTitle>
        <RecentFarmsContainer closed={!showRecentlyCreated && true}>


        {farmList != null && farmList.length > 0 && farmList.sort((a,b) => b.time_created - a.time_created).map((item, index) => ( 
            <span key={index} className={(index > 14 || (farmIsExpired(item.enddate)) ) && "hidden"}>
            <a href={`${currentWebsiteURL}/v2/farm/${item.farmname}`}>
            <V2FarmCard item={item} />
            </a>
            </span>
        ))}


      </RecentFarmsContainer>
  

      <JobOfferTitle>Most Popular
        <button onClick={() => setShowMostPopular((prev) => !prev)}>
          {showMostPopular ? "x" : "+"}
        </button>
      </JobOfferTitle>
        <RecentFarmsContainer closed={!showMostPopular && true}>


        {farmList != null && farmList.length > 0 && farmList.sort((a,b) => b.totalstaked - a.totalstaked).map((item, index) => (
            <span key={index} className={(index > 14 || (farmIsExpired(item.enddate)) ) && "hidden"}>
            <a href={`${currentWebsiteURL}/v2/farm/${item.farmname}`}>
            <V2FarmCard item={item} sort="popular" />
            </a>
            </span>
            ))}
      </RecentFarmsContainer>
 

    <JobOfferTitle>All Farms</JobOfferTitle>
    <ExploreFiltersCont>
      <h2>Filters</h2>
      <FoldersContainer>
        <FoldersRow>
          <SingleFolderTab onClick={(e) => { setStakedOnly("no") }} selected={stakedOnly == "no" && true}>
            All Farms
          </SingleFolderTab>

          {/* 
          <SingleFolderTab onClick={(e) => { setStakedOnly("yes") }} selected={stakedOnly == "yes" && true}>
            Staked Only
          </SingleFolderTab>
           */}
           
        </FoldersRow>
      </FoldersContainer>
    <br/>
    <ExploreFiltersInnerCont>
    <input placeholder='Farm name' value={farmToSearchFor} onChange={(e) => setFarmToSearchFor(e.target.value.toLowerCase())} />
    &nbsp;&nbsp;     

           {/* 
    <input placeholder='Collection name' value={collectionName} onChange={(e) => setCollectionName(e.target.value.toLowerCase())} />
    &nbsp;&nbsp;


    <input placeholder='Reward token' value={rewardToken} onChange={(e) => setRewardToken(e.target.value.toUpperCase())} />
 */}
    </ExploreFiltersInnerCont>
    </ExploreFiltersCont>
       
    <div className={stakedOnly == "no" ? "flex flex-wrap gap-5 mt-4 justify-center" : "hidden"}>




        {farmList != null && farmList.length > 0 && farmList.sort((a,b) => b.time_created - a.time_created).map((item, index) => (
            <span className={( (farmToSearchFor.length >= 1 && item.farmname.substring(0,farmToSearchFor.length).indexOf(farmToSearchFor) == -1 ) || (rewardToken.length >= 1 && item.rewardtoken.substring(0,rewardToken.length).indexOf(rewardToken) == -1 ) || (farmIsExpired(item.enddate)) ) && "hidden" }>
            <a href={`${currentWebsiteURL}/v2/farm/${item.farmname}`}>
            <V2FarmCard item={item} />
            </a>
            </span>
            ))}
    </div>

    <div className={stakedOnly == "yes" ? "flex flex-wrap gap-5 mt-4 justify-center" : "hidden"}>

        {stakedFarmList != null && stakedFarmList.length > 0 ? stakedFarmList.sort((a,b) => b.time_created - a.time_created).map((item, index) => (
            <span className={( (farmToSearchFor.length >= 1 && item.farmname.substring(0,farmToSearchFor.length).indexOf(farmToSearchFor) == -1 ) || (rewardToken.length >= 1 && item.rewardtoken.substring(0,rewardToken.length).indexOf(rewardToken) == -1 ) ) && "hidden" }>
            <a href={`${currentWebsiteURL}/v2/farm/${item.farmname}`}>
            <V2FarmCard item={item} />
            </a>
            </span>
            )) : (
              <div className='text-center'>
                No farms to show
              </div>
            )}
    </div>

      </AllFarmsCont>

      </NewListingBody>
      </NewListingCont>
    </UALProvider>
    <br/><br/><br/>
    </div>
  )}
  else{
    return(<LoadingPage />)
  }
}

export default FarmsV2