import React, { useEffect, useState } from "react";
import { UALProvider } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  ModalOverlay,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  Modal,
  CloseWindow,
  CreateFarmIPFS,
  StakeManyDiv,
} from "../components/LeftDiv";
import "./datepicker.css";
import DatePicker from "react-datepicker";
import {
  wax,
  anchor,
  myChain,
  wombat,
  currentUsername,
} from "../data/config";
import {
  createFarm, createNonCustodialFarm,
} from "../data/functions/farm_functions";
import { BsInfoCircle } from "react-icons/bs";
import { InfoLabel } from "../data/css/CreateFarmStyles";
import {
    MainButton,
  MilestoneContainer,
  NewListingBody,
  NewListingCont,
  TallFiltersCont,
  TallFiltersInnerCont,
  theme,
} from "../Styles";
import { useGetRoyaltyWhitelistStatus } from "../components/CustomHooks/useGetRoyaltyWhitelistStatus";
import { isLoggedIn } from "../data/functions/wallet_functions";
import Error from "../components/Error";
import LoadingPage from "../components/LoadingPage";
import { useGetMyWojaks } from "../components/CustomHooks/useGetMyWojaks";
import LoadingDiv from "../components/LoadingDiv";
import DaoNftCard from "../components/DaoNftCard";
import { deployRoyaltyContract, getRoyaltyWhitelist } from "../data/functions/royalty";
import { useStateContext } from "../contexts/ContextProvider";



const Royalty = () => {
    const {
        enterModalText,
        setEnterModalText,
        loadingDisplay,
        setLoadingDisplay,
        setEnterButtonsDisplay,
        enterModalDisplay,
        setEnterModalDisplay,
      } = useStateContext();

  const [assetVector, setAssetVector] = useState([]);
  const [removalVector, setRemovalVector] = useState([]);
  const [refreshAssets, setRefreshAssets] = useState(0);

  //custom hooks
  const [isWhitelisted, getWhitelistStatus, statusIsLoading] = useGetRoyaltyWhitelistStatus();
  const [nfts, getWojaks, nftsAreLoading] = useGetMyWojaks();
  
  
  useEffect(() => {
    if(isLoggedIn()){
        getWhitelistStatus();
    }
  }, [refreshAssets])

  useEffect(() => {
    if(isLoggedIn() && !statusIsLoading && !isWhitelisted){
       getWojaks();
    }
  }, [statusIsLoading, isWhitelisted])  
  
if(isLoggedIn() && !statusIsLoading){
    return (
        <div id="seo">
          <Helmet>
            <title>Royalty Contract - WaxDAO</title>
            <meta
              name="description"
              content="Deploy our royalty contract and automatically split funds between multiple accounts"
            />
            <link rel="canonical" href="https://waxdao.io/royalty-contract" />
          </Helmet>
    
          <UALProvider
            chains={[myChain]}
            authenticators={[anchor, wax, wombat]}
            appName={"WaxDAO"}
          >
            <ModalOverlay className={enterModalDisplay} />
            <Modal className={enterModalDisplay}>
              <ModalContent>{enterModalText}</ModalContent>
    
              <Spinner style={{ display: loadingDisplay }}>
                <SpinnerRed />
                <SpinnerBlue />
                <SpinnerGreen />
              </Spinner>
    
              <br />
              <CloseWindow
                onClick={() => {
                  setEnterModalDisplay("hidden");
                }}
              >
                Close Window
              </CloseWindow>
            </Modal>
    
            <NewListingCont>
              <NewListingBody>

              <span className={assetVector.length == 0 && "hidden"}>
              <StakeManyDiv
                onClick={() => {
                    getRoyaltyWhitelist(
                        assetVector,
                        setAssetVector,
                        refreshAssets,
                        setRefreshAssets,
                        setEnterModalText,
                        setLoadingDisplay,
                        setEnterButtonsDisplay,
                        setEnterModalDisplay                        
                    )
                }}
              >
                BURN ME <br />
              </StakeManyDiv>
            </span>

                <TallFiltersCont>
                  <h2>Royalty Contract</h2>
                  <br />
                  <TallFiltersInnerCont>
                    Detailed documentation for this contract can be found <a href="https://github.com/mdcryptonfts/royalty_contract/tree/main#readme" target="none">in its Github repository.</a>
                    <br/><br/>
                    Once you are ready to proceed with deploying the contract, follow the steps below.
                  </TallFiltersInnerCont>
                </TallFiltersCont>
    
                <TallFiltersCont>
                  <h2>Step 1: Get Whitelisted</h2>
                  <br />
                  <span className="pl-5"><b>Your current whitelist status: <span style={{color: isWhitelisted ? theme.secondary : "red"}}>{!isWhitelisted ? "NOT Whitelisted" : "Whitelisted"}</span></b></span>
                </TallFiltersCont>  

                {!isWhitelisted && (
                    <span>
                    <TallFiltersCont>
                    <h2>Whitelist Step 1: Buy A Wojak NFT</h2>
                    <br />
                    <TallFiltersInnerCont>
                        You can buy a Wojak NFT from our <b><a href="https://waxdao.io/drops/247">Official Drop</a></b>
                    </TallFiltersInnerCont>
                    </TallFiltersCont>  

                    <TallFiltersCont>
                    <h2>Whitelist Step 2: Unbox Your Wojak NFT</h2>
                    <br />
                    <TallFiltersInnerCont>
                        You can unbox your Wojak on our <b><a href="https://waxdao.io/unbox/246">unboxing page</a></b>
                    </TallFiltersInnerCont>
                    </TallFiltersCont>    

                    <TallFiltersCont>
                    <h2>Whitelist Step 3: Burn The Unboxed NFT</h2>
                    <br />
                    <TallFiltersInnerCont>
                        Select a Wojak from the the list below, and click the <span style={{color: theme.secondary}}><b>BURN ME</b></span> button
                    </TallFiltersInnerCont>
                    </TallFiltersCont>    

                    <TallFiltersCont>
                    <h2>My Wojak NFTs</h2>
                    <br />
                    <TallFiltersInnerCont>
                        {nftsAreLoading && (<LoadingDiv />)}
                        {!nftsAreLoading && (
                            <span>
                                {nfts.length == 0 ? (
                                    <MilestoneContainer>
                                        You have no Wojak NFTs
                                    </MilestoneContainer>
                                ) : (
                                    <div className="w-100 flex flex-wrap gap-4 justify-between">
                                        {nfts.map((item, index) => (
                                            <DaoNftCard item={item} assetVector={assetVector}
                                                setAssetVector={setAssetVector} removalVector={removalVector}
                                                setRemovalVector={setRemovalVector} editMode={0}
                                            />
                                        ))}
                                    </div>
                                )}

                            </span>
                        )}
                    </TallFiltersInnerCont>
                    </TallFiltersCont>                                                              
                    </span>
                )}                            

                {isWhitelisted && (
                    <span>
                    <TallFiltersCont>
                    <h2>Step 2: Deploy The Contract</h2>
                    <br />
                    <div className="pl-5"><b>NOTE:</b> You must have the <b>Allow Dangerous Transactions</b> setting enabled in Anchor Wallet, or Anchor will not allow you to upload a contract.</div>
                    </TallFiltersCont>  

                    <TallFiltersCont>
                    <div className="w-100 text-center items-center justify-center mt-4">
                        <MainButton
                            onClick={() => {
                                deployRoyaltyContract(
                                    setEnterModalText,
                                    setLoadingDisplay,
                                    setEnterButtonsDisplay,
                                    setEnterModalDisplay                                    
                                )
                            }}
                        >
                            DEPLOY CONTRACT NOW
                        </MainButton>
                    </div>
                    </TallFiltersCont>  

                    </span>
                )}
                          
    
                
              </NewListingBody>
            </NewListingCont>
          </UALProvider>
          <br />
          <br />
          <br />
        </div>
      );
}
else if(!isLoggedIn()){
    return <Error error="You need to log in to use this tool" />
}
else if(isLoggedIn() && statusIsLoading){
    return <LoadingPage />
}

};

export default Royalty;