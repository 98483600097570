import axios from "axios";
import {
  currentAtomicHubLink,
  currentUsername,
  currentWebsiteURL,
  getCurrentApiList,
  getCurrentAtomicApiList,
  v2DAOContract,
  waxExplorerLink,
} from "../../data/config";
import { getWalletSession } from "../../data/functions/wallet_functions";
import {
  SetttingsRow,
  Table,
  TableData,
  TableHeader,
  TableRow,
} from "./DAOStylesV2";
import {
  ExploreFiltersCont,
  ExploreFiltersInnerCont,
  MainButton,
  MilestoneContainer,
  SmallButton,
  theme,
} from "../../Styles";
import {
  getPrecisionFromSymbol,
  getTokenNameFromSymbol,
} from "../../data/functions/pool_functions";
import { DAO_TYPES } from "../../data/constants";
import LoadingDiv from "../../components/LoadingDiv";
import V2ProposalCard from "../../components/V2ProposalCard";
import DaoNftCard from "../../components/DaoNftCard";
import { StakeManyButton, StakeManyDiv } from "../../components/LeftDiv";

export const matchDAOProfile = (daoname, daoProfiles) => {
  for (const profile of daoProfiles) {
    if (profile.daoname == daoname) {
      return profile;
    }
  }
};

export const getWojakNfts = (myWojaks, setMyWojaks, retry = 3) => {
  setMyWojaks([]);

  if (!currentUsername) {
    return;
  }

  axios
    .get(
      `${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${`hoodpunknfts`}&schema_name=${`waxywojaks`}&owner=${currentUsername}&page=${1}&limit=20&order=desc&sort=transferred`
    )
    .then((atomicResponse) => {
      setMyWojaks(atomicResponse.data.data);
    })
    .catch((error) => {
      console.log(error);

      if (retry > 0) {
        getWojakNfts(myWojaks, setMyWojaks, retry - 1);
      }
    });
};

export const handleGovSchemaRemove = (govSchemas, setGovSchemas, index) => {
  const list = [...govSchemas];
  list.splice(index, 1);
  setGovSchemas(list);
};

export const handleGovSchemaAdd = (govSchemas, setGovSchemas) => {
  setGovSchemas([...govSchemas, { collection_name: "", schema_name: "" }]);
};

export const handleGovSchemaChange = (e, index, govSchemas, setGovSchemas) => {
  const { name, value } = e.target;
  const list = [...govSchemas];
  list[index][name] = value.toLowerCase();
  setGovSchemas(list);
};

export const createV2DAO = async (
  paymentMethod,
  assetID,
  dao_name,
  threshold,
  dao_type,
  proposer_type,
  gov_token_name,
  gov_token_decimals,
  gov_token_contract,
  hours_per_proposal,
  minimum_weight,
  minimum_votes,
  gov_farm_name,
  proposal_cost,
  authors,
  gov_schemas,
  profileDescription,
  avatar,
  coverImage,
  website,
  telegram,
  discord,
  twitter,
  medium,
  youtube,
  atomichub,
  waxdao,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  //payment type

  let assertAction = {
    account: v2DAOContract,
    name: "assertpoint",
    authorization: [
      {
        actor: this_user,
        permission: this_permission,
      },
    ],
    data: {
      user: this_user,
    },
  };

  let paymentAction;

  if (paymentMethod == "Pay 150 Wax") {
    paymentAction = {
      account: "eosio.token",
      name: "transfer",
      authorization: [
        {
          actor: this_user,
          permission: this_permission,
        },
      ],
      data: {
        from: this_user,
        to: v2DAOContract,
        quantity: "150.00000000 WAX",
        memo: "|dao_payment|",
      },
    };
  } else if (paymentMethod == "Pay 35,000 WOJAK") {
    paymentAction = {
      account: "mdcryptonfts",
      name: "transfer",
      authorization: [
        {
          actor: this_user,
          permission: this_permission,
        },
      ],
      data: {
        from: this_user,
        to: v2DAOContract,
        quantity: "35000 WOJAK",
        memo: "|dao_payment|",
      },
    };
  } else if (paymentMethod == "Burn 1 Wojak NFT") {
    paymentAction = {
      account: "atomicassets",
      name: "transfer",
      authorization: [
        {
          actor: this_user,
          permission: this_permission,
        },
      ],
      data: {
        from: this_user,
        to: v2DAOContract,
        asset_ids: [assetID],
        memo: "|dao_payment|",
      },
    };
  }

  const profileAction = {
    account: v2DAOContract,
    name: "setprofile",
    authorization: [
      {
        actor: this_user,
        permission: this_permission,
      },
    ],
    data: {
      user: this_user,
      dao: dao_name,
      profile: {
        avatar: avatar,
        cover_image: coverImage,
        description: profileDescription,
      },
      socials: {
        website: website,
        telegram: telegram,
        discord: discord,
        twitter: twitter,
        medium: medium,
        youtube: youtube,
        atomichub: atomichub,
        waxdao: waxdao,
      },
    },
  };

  //validation checks
  let gov_token_symbol = "0,NULL";

  if (Number(threshold) < 1 || Number(threshold) > 100) {
    setEnterModalText("Threshold must be between 1 and 100");
    return;
  }

  //DAO types
  if (Number(dao_type) == 1) {
    //custodial farm
  } else if (Number(dao_type) == 3) {
    //token pool
    minimum_weight = Number(minimum_weight) * (10 ^ Number(gov_token_decimals));
    minimum_votes = Number(minimum_votes) * (10 ^ Number(gov_token_decimals));
  } else if (Number(dao_type) == 4) {
    //tokens staked here
    gov_farm_name = "null";
    minimum_weight = Number(minimum_weight) * (10 ^ Number(gov_token_decimals));
    minimum_votes = Number(minimum_votes) * (10 ^ Number(gov_token_decimals));
    gov_token_symbol = Number(gov_token_decimals) + "," + gov_token_name;
  } else if (Number(dao_type) == 5) {
    //non-custodial NFTs
    gov_farm_name = "null";
  }

  //Proposer types
  if (Number(proposer_type) == 0) {
    //authors only
    authors = authors.split(",");
    console.log(authors);
  } else {
    authors = [];
    if (Number(proposer_type) == 2) {
      //validate stake weight
    }
  }

  try {
    const action = [
      assertAction,
      paymentAction,
      {
        account: "dao.waxdao",
        name: "createdao",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          daoname: dao_name,
          threshold: threshold,
          dao_type: dao_type,
          proposer_type: Number(proposer_type),
          gov_token_symbol: gov_token_symbol,
          gov_token_contract: gov_token_contract,
          hours_per_proposal: Number(hours_per_proposal),
          minimum_weight: Number(minimum_weight),
          minimum_votes: Number(minimum_votes),
          gov_farm_name: gov_farm_name,
          proposal_cost: Number(proposal_cost).toFixed(8) + " WAX",
          authors: authors,
          gov_schemas: gov_schemas,
        },
      },
      profileAction,
    ];
    console.log(action);
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Creating your DAO...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText(
        "Your DAO has been created. waxdao.io/v2/daos/" + dao_name
      );
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};

export const showV2DaoInfo = (daoData) => {
  return (
    <span>
      <Table>
        <tbody>
          <TableRow>
            <TableHeader>Dao Type</TableHeader>
            <TableData>
              {
                DAO_TYPES[
                  DAO_TYPES.findIndex(
                    (attributeIndex) =>
                      attributeIndex.value === daoData.dao_type
                  )
                ].display_text
              }
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Threshold</TableHeader>
            <TableData>
              At least {Number(daoData.threshold).toFixed(2)}% of votes must be
              in favor of the winning choice, in order for a proposal to pass.
            </TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Proposal Length</TableHeader>
            <TableData>{daoData.hours_per_proposal} hours</TableData>
          </TableRow>

          <TableRow>
            <TableHeader>Proposal Cost</TableHeader>
            <TableData>{daoData.proposal_cost}</TableData>
          </TableRow>

          {showGovAssetInfo(daoData)}

          {showWhoCanPropose(daoData)}

          {showMinimumVotes(daoData)}
        </tbody>
      </Table>
    </span>
  );
};

const showGovAssetInfo = (daoData) => {
  if (daoData.dao_type == 1) {
    //custodial farm
    return (
      <TableRow>
        <TableHeader>Governance Asset</TableHeader>
        <TableData>
          Stake NFTs in the{" "}
          <a href={`https://waxdao.io/farm/${daoData.gov_farm_name}`}>
            {daoData.gov_farm_name}
          </a>{" "}
          farm to get voting power
        </TableData>
      </TableRow>
    );
  } else if (daoData.dao_type == 3) {
    //token pool
    return (
      <TableRow>
        <TableHeader>Governance Asset</TableHeader>
        <TableData>
          Stake tokens in the{" "}
          <a href={`https://waxdao.io/pool/${daoData.gov_farm_name}`}>
            {daoData.gov_farm_name}
          </a>{" "}
          pool to get voting power
        </TableData>
      </TableRow>
    );
  } else if (daoData.dao_type == 4) {
    //tokens staked here
    return (
      <TableRow>
        <TableHeader>Governance Asset</TableHeader>
        <TableData>
          Stake {daoData.gov_token_name} tokens directly to this DAO to get
          voting power.
        </TableData>
      </TableRow>
    );
  } else if (daoData.dao_type == 5) {
    //non-custodial NFTs (not linked to farm)
    return (
      <TableRow>
        <TableHeader>Governance Asset</TableHeader>
        <TableData>
          This non-custodial DAO requires you to hold NFTs from the following
          schemas in order to vote:
          <br />
          <div className="flex flex-wrap gap-4 w-100">
            {daoData?.gov_schemas?.length >= 1 ? (
              daoData.gov_schemas.map((item, index) => (
                <a
                  key={index}
                  href={`${currentAtomicHubLink}/explorer/schema/wax-mainnet/${item.collection_name}/${item.schema_name}`}
                  target="none"
                >
                  <SmallButton>{item.schema_name}</SmallButton>
                </a>
              ))
            ) : (
              <span>No schemas found</span>
            )}
          </div>
        </TableData>
      </TableRow>
    );
  }
};

const showWhoCanPropose = (daoData) => {
  if (daoData.proposer_type == 0) {
    //authors only
    return (
      <TableRow>
        <TableHeader>Who Can Propose?</TableHeader>
        <TableData>
          This DAO is set to "Authors Only", which means that only approved
          accounts can create new proposals. Here is a list of the accounts who
          can propose:
          <br />
          <br />
          <div className="flex flex-wrap gap-4 w-100">
            {daoData?.authors?.length >= 1 ? (
              daoData.authors.map((item, index) => (
                <a
                  key={index}
                  href={`${waxExplorerLink}/account/${item}`}
                  target="none"
                >
                  <SmallButton>{item}</SmallButton>
                </a>
              ))
            ) : (
              <span>No accounts found</span>
            )}
          </div>
        </TableData>
      </TableRow>
    );
  } else if (daoData.proposer_type == 1) {
    //anyone
    return (
      <TableRow>
        <TableHeader>Who Can Propose?</TableHeader>
        <TableData>
          This DAO has no restrictions on who can create a proposal. Anyone can
          propose.
        </TableData>
      </TableRow>
    );
  } else if (daoData.proposer_type == 2) {
    //stake weight
    return (
      <TableRow>
        <TableHeader>Who Can Propose?</TableHeader>
        <TableData>{showMinimumStakeWeight(daoData)}</TableData>
      </TableRow>
    );
  }
};

const showMinimumStakeWeight = (daoData) => {
  if (daoData.dao_type == 1) {
    //custodial farm
    return (
      <span>
        This DAO requires you to have at least {daoData.minimum_weight} NFTs
        staked to the farm in order to create a proposal.
      </span>
    );
  } else if (daoData.dao_type == 3) {
    //custodial token pool
    return (
      <span>
        This DAO requires you to have at least{" "}
        {adjustUint64ForPrecision(daoData)}{" "}
        {getTokenNameFromSymbol(daoData.gov_token_symbol)} tokens staked to the
        pool in order to create a proposal.
      </span>
    );
  } else if (daoData.dao_type == 4) {
    //tokens staked here
    return (
      <span>
        This DAO requires you to have at least{" "}
        {adjustUint64ForPrecision(daoData)}{" "}
        {getTokenNameFromSymbol(daoData.gov_token_symbol)} tokens staked
        directly to this DAO in order to create a proposal.
      </span>
    );
  } else if (daoData.dao_type == 5) {
    //non-custodial NFTs, not linked to a farm
    return (
      <span>
        This non-custodial DAO requires you to hold at least{" "}
        {daoData.minimum_weight} NFTs from the supported schemas in order to
        create a proposal.
      </span>
    );
  }
};

const adjustUint64ForPrecision = (daoData) => {
  return Number(daoData.minimum_weight);
  if (
    Number(
      daoData.gov_token_symbol.substring(
        0,
        daoData.gov_token_symbol.indexOf(",")
      )
    ) == 0
  ) {
    return Number(daoData.minimum_weight);
  } else {
    return daoData.minimum_weight.substring(
      0,
      daoData.minimum_weight.length -
        Number(
          daoData.gov_token_symbol.substring(
            0,
            daoData.gov_token_symbol.indexOf(",")
          )
        )
    );
  }
};

const adjustUint64VotesForPrecision = (daoData) => {
  return Number(daoData.minimum_votes);
  if (
    Number(
      daoData.gov_token_symbol.substring(
        0,
        daoData.gov_token_symbol.indexOf(",")
      )
    ) == 0
  ) {
    return Number(daoData.minimum_votes);
  } else {
    return daoData.minimum_votes.substring(
      0,
      daoData.minimum_votes.length -
        Number(
          daoData.gov_token_symbol.substring(
            0,
            daoData.gov_token_symbol.indexOf(",")
          )
        )
    );
  }
};

const showMinimumVotes = (daoData) => {
  if (daoData.dao_type == 1 || daoData.dao_type == 5) {
    //NFTs
    return (
      <TableRow>
        <TableHeader>Minimum Votes</TableHeader>
        <TableData>
          In order for a proposal to pass, at least {daoData.minimum_votes} NFTs
          must cast their vote.
        </TableData>
      </TableRow>
    );
  } else if (daoData.dao_type == 3 || daoData.dao_type == 4) {
    //Tokens
    return (
      <TableRow>
        <TableHeader>Minimum Votes</TableHeader>
        <TableData>
          In order for a proposal to pass, at least{" "}
          {adjustUint64VotesForPrecision(daoData)}{" "}
          {getTokenNameFromSymbol(daoData.gov_token_symbol)} tokens must cast
          their vote.
        </TableData>
      </TableRow>
    );
  }
};

export const showV2Proposals = (
  dao_name,
  daoProposals,
  useGetV2DAOProposals,
  proposalsAreLoading
) => {
  return (
    <span>
      {proposalsAreLoading && <LoadingDiv />}
      {((!proposalsAreLoading && daoProposals == null) ||
        (!proposalsAreLoading && daoProposals?.length == 0)) && (
        <span>No proposals have been created for this DAO.</span>
      )}
      {!proposalsAreLoading && daoProposals?.length >= 1 && (
        <span>
          {daoProposals
            .sort((a, b) => b.end_time - a.end_time)
            .map((prop, index) => (
              <V2ProposalCard
                key={index}
                dao_name={dao_name}
                proposal_id={prop.proposal_id}
                title={prop.title}
                description={prop.description}
                status={prop.outcome}
                end_time={prop.end_time}
                proposal_type={prop.proposal_type}
                author={prop.author}
              />
            ))}
        </span>
      )}
    </span>
  );
};

export const daoHasStaking = (daoData) => {
  if (daoData.dao_type == 4) {
    return true;
  }
  return false;
};

export const showTokenDepositSection = (
  dao,
  tokens,
  tokensAreLoading,
  selectedTokenObject,
  setSelectedTokenObject,
  tokenAmountToDeposit,
  setTokenAmountToDeposit,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  return (
    <span>
      <br />
      {tokensAreLoading && <LoadingDiv />}
      {!tokensAreLoading && tokens.length == 0 && "No balances found"}
      {!tokensAreLoading && tokens.length >= 1 && (
        <span>
          <h3>Amount</h3>
          <input
            placeholder="e.g. 69.420"
            onChange={(e) => {
              setTokenAmountToDeposit(e.target.value);
            }}
            value={tokenAmountToDeposit}
          />
          &nbsp;&nbsp;
          <select
            onChange={(e) => setSelectedTokenObject(tokens[e.target.value])}
          >
            <option hidden>Choose Token</option>
            {tokens
              .sort((a, b) => b.amount - a.amount)
              .map((tkn, index) => (
                <option key={index} value={index}>
                  {tkn.amount} {tkn.symbol}
                </option>
              ))}
          </select>
          <br />
          <br />
          <MainButton
            onClick={() => {
              depositTokensToV2DAO(
                dao,
                tokenAmountToDeposit,
                selectedTokenObject,
                setEnterModalText,
                setLoadingDisplay,
                setEnterButtonsDisplay,
                setEnterModalDisplay
              );
            }}
          >
            Deposit {tokenAmountToDeposit}{" "}
            {selectedTokenObject && selectedTokenObject.symbol}
          </MainButton>
        </span>
      )}
    </span>
  );
};

const depositTokensToV2DAO = async (
  dao,
  amount,
  selectedToken,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let precision = selectedToken.precision ? selectedToken.precision : 0;
  let amount_to_send = Number(amount).toFixed(Number(precision));

  try {
    const action = [
      {
        account: v2DAOContract,
        name: "tokendeposit",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          dao: dao,
          token_symbol: precision + "," + selectedToken.symbol,
          token_contract: selectedToken.contract,
        },
      },
      {
        account: selectedToken.contract,
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          from: this_user,
          to: v2DAOContract,
          quantity: amount_to_send + " " + selectedToken.symbol,
          memo: "|treasury_deposit|" + dao + "|",
        },
      },
    ];
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Depositing your tokens...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText("Your tokens are now in the treasury");
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};

export const voteInV2DAO = async (
  dao,
  proposal_id,
  choice,
  proof_asset_ids,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  console.log(choice);

  try {
    const action = [
      {
        account: v2DAOContract,
        name: "vote",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          dao: dao,
          proposal_id: proposal_id,
          choice: choice,
          asset_ids: proof_asset_ids,
        },
      },
    ];
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Casting your vote...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText("Your vote has been submitted");
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};

export const showNftDepositSection = (
  dao,
  nfts,
  nftsAreLoading,
  selectedNfts,
  setSelectedNfts,
  myCollections,
  collectionsWereLoaded,
  selectedCollection,
  setSelectedCollection,
  hasNextPage,
  getNfts,
  getMyCollections,
  userHasConfirmed,
  setUserHasConfirmed,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  return (
    <span>
      {!collectionsWereLoaded && nfts.length == 0 && (
        <div className="w-100 text-center justify-center mt-3">
          <MainButton
            onClick={() => {
              getNfts(currentUsername);
              getMyCollections(currentUsername);
            }}
          >
            Load NFTs
          </MainButton>
        </div>
      )}

      {collectionsWereLoaded && (
        <ExploreFiltersCont>
          <ExploreFiltersInnerCont>
            <h3>Filter By Collection</h3>
            <select
              onChange={(e) => {
                setSelectedCollection(e.target.value);
                getNfts(currentUsername, e.target.value);
              }}
            >
              <option hidden>Choose</option>
              {myCollections?.collections?.map((col, index) => (
                <option value={col.collection.collection_name}>
                  {col?.collection?.collection_name}
                </option>
              ))}
            </select>
          </ExploreFiltersInnerCont>
        </ExploreFiltersCont>
      )}
      <br />
      <br />
      <div className="w-100 flex flex-wrap gap-4 justify-center">
        <br />

        {nftsAreLoading && <LoadingDiv />}
        {collectionsWereLoaded &&
          !nftsAreLoading &&
          nfts.length == 0 &&
          "No NFTs found"}
        {!nftsAreLoading &&
          nfts.length >= 1 &&
          nfts.map((nft, index) => (
            <DaoNftCard
              item={nft}
              editMode={0}
              assetVector={selectedNfts}
              setAssetVector={setSelectedNfts}
            />
          ))}
      </div>

      <div className="w-100 text-center justify-center mt-3">
        {hasNextPage() && (
          <MainButton
            onClick={() => {
              getNfts(currentUsername);
            }}
          >
            Load More
          </MainButton>
        )}
      </div>

      {selectedNfts.length >= 1 && (
        <StakeManyDiv
          onClick={() => {
            showConfirmationModal(
              dao,
              selectedNfts,
              setUserHasConfirmed,
              setEnterModalText,
              setLoadingDisplay,
              setEnterButtonsDisplay,
              setEnterModalDisplay
            );
          }}
        >
          Deposit {selectedNfts.length} NFTs
        </StakeManyDiv>
      )}
    </span>
  );
};

const showConfirmationModal = (
  dao,
  nfts,
  setUserHasConfirmed,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText(
    <span>
      You are about to deposit NFTs to the DAO treasury. This is NOT NECESSARY in order to vote. You vote by having the NFT in your wallet (for NFT-based DAOs). If you deposit these NFTs, the ONLY way to retrieve them is via a DAO proposal.
      <br/><br/>
      <MainButton
        onClick={() => {
          setUserHasConfirmed(true);
          depositNftsToV2DAO(
            dao,
            nfts,
            setUserHasConfirmed,
            setEnterModalText,
            setLoadingDisplay,
            setEnterButtonsDisplay,
            setEnterModalDisplay
          );
        }}
      >
        I Understand, Deposit My NFTs
      </MainButton>
    </span>
  );
}

const depositNftsToV2DAO = async (
  dao,
  nfts,
  setUserHasConfirmed,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  try {

    const action = [
      {
        account: v2DAOContract,
        name: "nftdeposit",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          dao: dao,
          asset_ids: nfts,
        },
      },
      {
        account: "atomicassets",
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          from: this_user,
          to: v2DAOContract,
          asset_ids: nfts,
          memo: "|treasury_deposit|" + dao + "|",
        },
      },
    ];
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Depositing your NFTs...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText("Your NFTs are now in the treasury");
      setUserHasConfirmed(false);
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};

export const showStakeTokensSection = (
  dao,
  balances,
  daoData,
  amountToStake,
  setAmountToStake,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  return (
    <span>
      <h3>Amount</h3>
      <input
        onChange={(e) => {
          setAmountToStake(e.target.value);
        }}
        value={amountToStake}
        placeholder="e.g. 69.420"
      />
      <br/>
      <span className="text-sm" style={{color: theme.textSecondary}}>
        Balance: &nbsp;{showGovTokenBalance(daoData, balances)}
      </span>

      <br />
      <br />
      <MainButton
        onClick={() => {
          stakeTokensToV2DAO(
            dao,
            daoData,
            amountToStake,
            setEnterModalText,
            setLoadingDisplay,
            setEnterButtonsDisplay,
            setEnterModalDisplay
          );
        }}
      >
        Stake {amountToStake} {getTokenNameFromSymbol(daoData.gov_token_symbol)}
      </MainButton>
    </span>
  );
};

const showGovTokenBalance = (daoData, balances) => {
  const govSymbolCode = getTokenNameFromSymbol(daoData.gov_token_symbol);
  const govContract = daoData.gov_token_contract;

  const matchingBalance = balances.find(
    (balance) =>
      balance.symbol === govSymbolCode && balance.contract === govContract
  );

  if (matchingBalance) {
    return matchingBalance.amount;
  } else {
    return 0;
  }
};

const stakeTokensToV2DAO = async (
  dao,
  daoData,
  amount,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let amount_to_deposit =
    Number(amount).toFixed(getPrecisionFromSymbol(daoData.gov_token_symbol)) +
    " " +
    getTokenNameFromSymbol(daoData.gov_token_symbol);

  try {
    const action = [
      {
        account: v2DAOContract,
        name: "staketokens",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          dao: dao,
        },
      },
      {
        account: daoData.gov_token_contract,
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          from: this_user,
          to: v2DAOContract,
          quantity: amount_to_deposit,
          memo: "|stake_tokens|" + dao + "|",
        },
      },
    ];
    console.log(action);

    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );

    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Staking your tokens...");
    const timer = setTimeout(async () => {
      setLoadingDisplay("none");
      setEnterModalText("Your tokens are now staked");
    }, 1000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));
    setLoadingDisplay("none");
    console.log(e);
  }
};
