import React, { useEffect } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import {Helmet} from "react-helmet";
import { JobOfferTitle, MilestoneContainer, MilestoneDescription, NewListingBody, NewListingCont } from '../../Styles';
import { MdCardGiftcard, MdGeneratingTokens, MdSell } from "react-icons/md"
import { AiFillLock } from "react-icons/ai"
import { GiFarmer, GiMedicalDrip, GiPaintBucket, GiTwoCoins } from "react-icons/gi"
import { FaBlender, FaFileContract } from "react-icons/fa"
import { GrGroup } from "react-icons/gr"
import { RiAdvertisementFill } from "react-icons/ri"
import { ImSpinner6 } from "react-icons/im"
import { currentWebsiteURL } from '../../data/config';

const Create = () => {
  
    const { setCurrentNavbarLocation } = useStateContext();

    useEffect(() => {
      setCurrentNavbarLocation("Create")
    }, []);
    

  return (
    <div id="seo">
    <Helmet>
    <title>Creator Tools - WaxDAO</title>
    <meta name="description" content="View a list of WaxDAO's creator tools that will help make your dream project a reality" />
    <link rel="canonical" href="https://waxdao.io/create" />
    </Helmet>

    <NewListingCont>
        <NewListingBody>
            <JobOfferTitle>
                Creator Tools
            </JobOfferTitle>

            <a href={`${currentWebsiteURL}/create-pack`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><MdCardGiftcard className="inline-block" /> Pre-Minted Packs</h3>
            </MilestoneContainer>
            </a>
      
            <a href={`${currentWebsiteURL}/create-premint`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><GiPaintBucket className="inline-block" /> Pre-Minted Pools</h3>
            </MilestoneContainer>
            </a>    

            <a href={`${currentWebsiteURL}/create-blend`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><FaBlender className="inline-block" /> Super Blends</h3>
            </MilestoneContainer>
            </a>

            <a href={`${currentWebsiteURL}/create-farm`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><GiFarmer className="inline-block" /> NFT Farms</h3>
            </MilestoneContainer>
            </a>

            <a href={`${currentWebsiteURL}/create-pool`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><GiTwoCoins className="inline-block" /> Token Staking</h3>
            </MilestoneContainer>
            </a>

            <a href={`${currentWebsiteURL}/token-creator`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><MdGeneratingTokens className="inline-block" /> WAX Token Creator</h3>
            </MilestoneContainer>
            </a>

            <a href={`${currentWebsiteURL}/royalty-contract`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><FaFileContract className="inline-block" /> Royalty Contract</h3>
            </MilestoneContainer>
            </a>            

            <a href={`${currentWebsiteURL}/create-drop`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><MdSell className="inline-block" /> NFT Drops</h3>
            </MilestoneContainer>
            </a>

            <a href={`${currentWebsiteURL}/v2/create-dao`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><GrGroup className="inline-block invert" /> DAO Creator</h3>
            </MilestoneContainer>
            </a>

            <a href={`${currentWebsiteURL}/synths`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><ImSpinner6 className="inline-block" /> Synthetic Assets</h3>
            </MilestoneContainer>
            </a>

            <a href={`${currentWebsiteURL}/locker`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><AiFillLock className="inline-block" /> Liquidity Locker</h3>
            </MilestoneContainer>
            </a>                

            <a href={`${currentWebsiteURL}/escrow`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><GiMedicalDrip className="inline-block" /> Slow Release Escrow</h3>
            </MilestoneContainer>
            </a>            


            <a href={`${currentWebsiteURL}/advertise`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><RiAdvertisementFill className="inline-block" /> Advertise</h3>
            </MilestoneContainer>
            </a>

        </NewListingBody>
    </NewListingCont>   

    </div>
  )
}

export default Create