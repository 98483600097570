import React from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import {Helmet} from "react-helmet";
import { LeftDiv, MainContainer, RightDiv, ArticleCont, ArticleH1, ArticleP, ArticleH2 } from '../components/LeftDiv';
import { currentWebsiteURL } from '../data/config';

const PackTutorial = () => {
  return (
    <div id="seo">
    <Helmet>
    <title>Pre-Minted Pack Tutorial - WaxDAO</title>
    <meta name="description" content="Learn how to use WaxDAO's Preminted pack drop tool." />
    <link rel="canonical" href="https://waxdao.io/pack-tutorial" />
    </Helmet>
    <MainContainer>
    <ArticleCont>
      <ArticleH1>
        How To Create Pre Minted Packs On WaxDAO
      </ArticleH1>

      <ArticleH2>
      **UPDATE: Oct 21, 2022**
      </ArticleH2>

      <ArticleP>
        A more up-to-date version of this tutorial is in video format <a href={`https://youtu.be/53h7cjSLGHw`} target="none">on YouTube</a>
      </ArticleP>

      <ArticleP>
        In this tutorial, I will explain how to create pre minted pack drops on WaxDAO.
      </ArticleP>

      <ArticleH2>
        Steps Involved
      </ArticleH2>

      <ArticleP>
        <ul>
          <li>Step 1: Deposit Collection RAM</li>
          <li>Step 2: Create Pool Of Pre-Minted NFTs</li>
          <li>Step 3: Choose A Pack Template</li>
          <li>Step 4: Create An Unboxing Link</li>
          <li>Step 5: Create A Pack Drop</li>
        </ul>
      </ArticleP>

      <ArticleH2>
      Step 1: Deposit Collection RAM
      </ArticleH2>

      <ArticleP>
        The first thing you will need to do is deposit some RAM to your collection on WaxDAO.<br/><br/>
        This can be done by going to the <a href={`${currentWebsiteURL}/wallet`}>Wallet page</a> and then clicking "My Collections" in the sidebar.<br/><br/>
        Simply enter the amount of WAX you want to deposit, and add it to your collection.
      </ArticleP>

      <ArticleH2>
      Step 2: Create Pool Of Pre-Minted NFTs
      </ArticleH2>

      <ArticleP>
        Once you have RAM added to your collection, you need to create a pool of pre-minted assets.<br/><br/>
        When people open their packs, the NFTs need to come from somewhere, right?<br/><br/>
        Well, that "somewhere" is going to be the pre-minted pool of NFTs that you create.<br/><br/>
        All you need to do is go to the wallet page, click "Pack Creator" and then create a new preminted pool. Then click "edit pool".<br/><br/>
        Choose which assets to add, and submit a transaction to add them to the pool.
      </ArticleP>

      <ArticleP>
        You can (and we suggest you do) break this up into multiple transactions. As long as the pool is not 
        locked, you can add/remove assets from it.
      </ArticleP>


      <ArticleH2>
      Steps 3 and 4: Choose A Pack Template And Create An Unbox Link
      </ArticleH2>

      <ArticleP>
        Now that your pool is set up, go back to the "Manage Drops" page. Under the "Preminted Pools" section,
        &nbsp;you will see a new option called "Create Pack".<br/><br/>
        Click that, then you can enter the details for your unboxing page.<br/><br/>
        You need to select which template you want to use as your "pack template". Which NFT pack will people
        &nbsp;be unboxing?<br/><br/>
        Enter that into the pack template box. E.g. 123456
      </ArticleP>

      <ArticleH2>
      Step 5: Create A Pack Drop
      </ArticleH2>

      <ArticleP>
        Did you finish setting up your unboxing page? Cool, now just create a regular drop so you can sell people
        &nbsp;the pack template.
        <br/><br/>
        You can either do a mint on demand pack drop, or you can pre-mint the packs and add them to a pool.
      </ArticleP>

      <ArticleH2>
        Step 6: Join Our Telegram, Tell Your Friends
      </ArticleH2>

      <ArticleP>
        Enjoyed our service? Great. Our prices are insanely cheap, we don't make a ton off of you. So 
        we would really appreciate it if you told your friends about WaxDAO.
      </ArticleP>

      <ArticleP>
        The more users we get, the more we can earn- which helps us develop more useful products for you 
        to get creative with.
      </ArticleP>

      <ArticleP>
        Thanks for your time. If you have any questions, there's a link to our Telegram at the top of the page.
      </ArticleP>

    </ArticleCont>
    </MainContainer>
    </div>
  )
}

export default PackTutorial