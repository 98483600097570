import styled from 'styled-components'


<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>

//COLORS
//#c30000
//#47158e
//#dc0bb6

export const OTCMainCont = styled.div`
    font-size: 14px;
    color: whitesmoke;
    display: block;
    width: 100%;
    padding-top: 100px;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    text-align: center;

    h2{
        font-size: 18px;
        font-weight: 900;
    }

    button{
        background-color: #dc0bb6;
        border-radius: 5px;
        padding: 1em;
        font-weight: 900;
    }

    input{
        color: black;
        padding-left: 0.5em;
        border-radius: 5px;
        margin-top: 5px;

        :focus{
            outline: 1px solid #dc0bb6;
        }
    }
   
`

export const HowItWorks = styled.div`
    width: 400px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    border: 1px solid #bcbcbc;
    border-radius: 5px;
    padding: 1em;
`

export const OTCSwapCont = styled.div`
    width: 400px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    border: 1px solid #bcbcbc;
    border-radius: 5px;
    padding-bottom: 2em;
   
`