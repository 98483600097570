import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { Api, JsonRpc } from "eosjs";
import {Helmet} from "react-helmet";
import axios from 'axios';
import { data } from 'autoprefixer';
import { Name, raw } from "eos-common";
import { AllFarmsCont, FarmsPageCont, FarmsTitle, SingleFarmCont, FarmLogo, FarmTitle, FarmEndDate, PoolAmount, ProposerTypeMessage, MissingInfoMsg, DaoButton, PaymentOptCont, BurnableCont, NFT, NewRow, BuyWojak, ProposalInput, NewProposal, Modal, ModalContent, Spinner, SpinnerRed, SpinnerBlue, SpinnerGreen, GameButton, DisabledButton, SingleDropLogo, DropDown, FilterMenu, DropType, SoldOut, NewUIFarmsPageCont } from '../../components/LeftDiv';
import NumberFormat from 'react-number-format';
import { myChain, anchor, wax, currentApiList, currentAtomicApiList, currentWebsiteURL, getCurrentApiList, wombat } from '../../data/config';
import CurrentBannerAd from '../../components/CurrentBannerAd';
import { FoldersContainer, FoldersRow, NewListingBody, NewListingCont, SingleFolderFourth } from '../../Styles';
import BlendCard from '../../components/BlendCard';



const CollectionBlends = () => {

  const {  dropList, setDropList,
    enterModalText,
    loadingDisplay, 
    enterModalDisplay, 
    setEnterModalDisplay,
    setLogoHash,
    dropSortMode,
    setDropSortMode,
    pageCount,
    setPageCount,
    loadMoreDisplay,
    setLoadMoreDisplay,

} = useStateContext();

const { CollectionName } = useParams();

useEffect(() => {
setDropList([]);
setLogoHash('');
setDropSortMode('newest');
setPageCount();
setLoadMoreDisplay('');





          axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
            table:"blends",
            scope:"waxdaomarket",
            code:"waxdaomarket",
            reverse: true,
            key_type: 'name',
            index_position: '2',
            lower_bound: CollectionName,
            upper_bound: CollectionName,
            limit:100,
            json:true
          })
            .then((response) => {
                console.log(response)
        
                setDropList(response.data.rows);

                if(response.data.more == true){

                  setPageCount(Number(response.data.next_key));
    
                } else { setLoadMoreDisplay('hidden'); }

                console.log(response.data.next_key);
              
              
            })
            .catch((error) => console.log(error));
  
  }

, []);




const getNextPage = (pageCount, setPageCount, dropList, setDropList) => {

    var lowerBound = pageCount;

      axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
        table:"blends",
        scope:"waxdaomarket",
        code:"waxdaomarket",
        //lower_bound: lowerBound,
        reverse: true,
        upper_bound: lowerBound,
        limit:100,
        json:true
      })
        .then((response) => {

            var list = [...dropList];


            if(Array.isArray(dropList[0])){
              list = dropList[0];
            }

            list = list.concat(response.data.rows);

            setDropList(list);

            if(response.data.more == true){

              setPageCount(Number(response.data.next_key));
              console.log(Number(response.data.next_key))

            } else { setLoadMoreDisplay('hidden'); }
            
          
        })
        .catch((error) => console.log(error));
      




}


  return (
  <div id="seo">
    <Helmet>
    <title>Super Blends - WaxDAO</title>
    <meta name="description" content="View blends by collection on WaxDAO" />
    <link rel="canonical" href="https://waxdao.io/blends" />
    </Helmet>

    <UALProvider chains={[myChain]} authenticators={[anchor, wax, wombat]} appName={"WaxDAO"}>

    <Modal className={enterModalDisplay}>
      <ModalContent>
        {enterModalText}
        
      </ModalContent>

        <Spinner style={{display:loadingDisplay}}>
        <SpinnerRed  />
        <SpinnerBlue  />
        <SpinnerGreen />
        </Spinner>

      <br/>
      <GameButton onClick={() => setEnterModalDisplay('hidden') }>
        Close Window
      </GameButton>
      </Modal>


      <NewListingCont>
      <NewListingBody>

<CurrentBannerAd />

<AllFarmsCont>

<FoldersContainer>
    <FoldersRow>
      <SingleFolderFourth onClick={(e) => { setDropSortMode("newest") }} selected={dropSortMode == "newest" && true}>
      Newest
      </SingleFolderFourth>
      <SingleFolderFourth onClick={(e) => { setDropSortMode("oldest") }} selected={dropSortMode == "oldest" && true}>
      Oldest
      </SingleFolderFourth>
      <SingleFolderFourth onClick={(e) => { setDropSortMode("ending_soonest") }} selected={dropSortMode == "ending_soonest" && true}>
      Ending Soonest
      </SingleFolderFourth>
      <SingleFolderFourth onClick={(e) => { setDropSortMode("ending_latest") }} selected={dropSortMode == "ending_latest" && true}>
      Ending Latest
      </SingleFolderFourth>
    </FoldersRow>
  </FoldersContainer>

<NewRow />

<div className="flex flex-wrap gap-5 mt-4 justify-center">
{dropList == null && (
<span>
<PaymentOptCont>
<MissingInfoMsg>
  Error connecting to the WaxDAO APIs.<br/><br/>
  Try waiting a few seconds and refreshing the page.
</MissingInfoMsg>
</PaymentOptCont>
</span>
)}

{/* Sort by ending soonest */}

{dropList != null && dropSortMode != null && dropSortMode == "ending_soonest" && (dropList.sort((a,b) => a.end_time - b.end_time).map((item, index) => (
     <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
     <a href={`${currentWebsiteURL}/blends/${item.ID}`}>
     <BlendCard item={item} />
     </a>
     </span>
    ))) }
    
  


{/* Sort by ending latest */}

{dropList != null && dropSortMode != null && dropSortMode == "ending_latest" && (dropList.sort((a,b) => b.end_time - a.end_time).map((item, index) => (
      <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
      <a href={`${currentWebsiteURL}/blends/${item.ID}`}>
      <BlendCard item={item} />
      </a>
      </span>
    ))) }


{/* Sort by newest */}

{dropList != null && dropSortMode != null && dropSortMode == "oldest" && (dropList.sort((a,b) => a.start_time - b.start_time).map((item, index) => (
     <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
     <a href={`${currentWebsiteURL}/blends/${item.ID}`}>
     <BlendCard item={item} />
     </a>
     </span>
    ))) }
    
  




{/* Sort by oldest */}

{dropList != null && dropSortMode != null && dropSortMode == "newest" && (dropList.sort((a,b) => b.start_time - a.start_time).map((item, index) => (
      <span key={index} className={(item.end_time != null && item.end_time != 0 && (item.end_time * 1000) < new Date() || item.start_time != null && item.start_time != 0 && (item.start_time * 1000)  > new Date() ) && "hidden"}>
      <a href={`${currentWebsiteURL}/blends/${item.ID}`}>
      <BlendCard item={item} />
      </a>
      </span>
    ))) }
    
    
</div>

<br/>
<NewRow />
<DaoButton className={loadMoreDisplay} onClick={() => { getNextPage(pageCount, setPageCount, dropList, setDropList) }}>
  Load More
</DaoButton>


<DisabledButton className={loadMoreDisplay != 'hidden' && 'hidden'} >
  No More Results
</DisabledButton>

</AllFarmsCont>


</NewListingBody>
</NewListingCont>
      <br/><br/><br/>
    </UALProvider>
 
    </div>
  )
}

export default CollectionBlends