import { MilestoneContainer, SmallButton } from "../Styles";
import { currentWebsiteURL } from "../data/config";
import { PROPOSAL_STATUSES, V2_PROPOSAL_STATUSES, V2_PROPOSAL_TYPES } from "../data/constants";
import { V2BottomLine, V2Button, V2Description, V2PropCard, V2Title, V2TopLine } from "../pages/daosv2/DAOStylesV2";
import { formatDistanceToNow, fromUnixTime, isPast } from 'date-fns';
import { ProposalAuthor, ProposalButtonContainer, ProposalDescription, ProposalTitle } from "./LeftDiv";
import { showProposalOutcomeText } from "../pages/daosv2/proposal_v2_functions";

const formatEndTime = (endTime) => {
    const endDate = fromUnixTime(endTime);
    const prefix = isPast(endDate) ? 'Ended' : 'Ends in';
    const timeDistance = formatDistanceToNow(endDate);
    return isPast(endDate) ? `${prefix} ${timeDistance} ago` : `${prefix} ${timeDistance}`;
};

const showOutcome = (status) => {                  {
    return V2_PROPOSAL_STATUSES[
        V2_PROPOSAL_STATUSES.findIndex(
            (attributeIndex) =>
              attributeIndex.value === status
          )
        ].display_text
      }
}


const showType = (type) => {                  {
    return V2_PROPOSAL_TYPES[
        V2_PROPOSAL_TYPES.findIndex(
            (attributeIndex) =>
              attributeIndex.value === type
          )
        ].display_text
      }
}


const V2ProposalCard = ({ dao_name, proposal_id, title, description, status, end_time, proposal_type, author }) => {
    return (
      <MilestoneContainer>
        <a href={`${currentWebsiteURL}/v2/${dao_name}/proposals/${proposal_id}`}>
          <V2TopLine>
            <ProposalTitle>{title}</ProposalTitle>
          </V2TopLine>
          

          <ProposalAuthor>by {author}</ProposalAuthor>

          <ProposalDescription>{description}</ProposalDescription>

          <ProposalButtonContainer>
            <SmallButton>
              {showProposalOutcomeText(end_time, status)}
            </SmallButton>

            <SmallButton>{showType(proposal_type)}</SmallButton>

            <SmallButton>ID #{proposal_id}</SmallButton>
          </ProposalButtonContainer>
        </a>
      </MilestoneContainer>
    );
};

export default V2ProposalCard;
