import React, { useEffect } from 'react';
import { useStateContext } from '../../contexts/ContextProvider';
import {Helmet} from "react-helmet";

import { currentWebsiteURL } from '../../data/config';
import { JobOfferTitle, MilestoneContainer, NewListingBody, NewListingCont } from '../../Styles';
import { MdGeneratingTokens } from 'react-icons/md';
import { RiExchangeDollarLine } from 'react-icons/ri';
import { GiFarmer, GiTwoCoins } from 'react-icons/gi';


const FarmingNav = () => {
  
  const {setCurrentNavbarLocation } = useStateContext();

  useEffect(() => {
    setCurrentNavbarLocation("Staking");
  }, [])
  

  return (
    <div id="seo">
    <Helmet>
    <title>NFT Farming Homepage - WaxDAO</title>
    <meta name="description" content="Create, view and stake in WaxDAO's NFT farms" />
    <link rel="canonical" href={`${currentWebsiteURL}/nft-farming`} />
    </Helmet>

    <NewListingCont>
        <NewListingBody>
            <JobOfferTitle>
                Staking
            </JobOfferTitle>

            <a href={`${currentWebsiteURL}/farms`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><GiFarmer className="inline-block" /> Stake NFTs</h3>
            </MilestoneContainer>
            </a>

            <a href={`${currentWebsiteURL}/pools`}>
            <MilestoneContainer>
            <h3 className="font-semibold"><GiTwoCoins className="inline-block" /> Stake Tokens</h3>
            </MilestoneContainer>
            </a>

        </NewListingBody>
    </NewListingCont> 
    </div>
  )
}

export default FarmingNav