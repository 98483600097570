import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { UALProvider } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import axios from "axios";
import {
  DaoButton,
  NFTsCont,
  NoDaosMessage,
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  DisabledButton,
  NewRow,
  ModalOverlay,
  NewAnchorButton,
  NewCloudButton,
  StakeManyDiv,
} from "../components/LeftDiv";
import {
  currentUsername,
  anchor,
  wax,
  myChain,
  currentWebsiteURL,
  getCurrentAtomicApiList,
  getCurrentApiList,
  wombat,
  currentAtomicApiList,
} from "../data/config";
import {
  getNextUnstakedPage,
  whiteListMe,
  openWalletTokenBalancesTab,
  transferNFTs,
} from "../data/functions/wallet_functions";
import { Name } from "eos-common";
import { useGetBannerAds } from "../components/CustomHooks/useGetBannerAds";
import { useWalletContext } from "../contexts/WalletContextProvider";
import {
  WalletNFTCardsCont,
} from "../data/css/WalletStyles";
import Synthetics from "../components/WalletComponents/Synthetics";
import WalletCard from "../components/WalletComponents/WalletCard";
import {
  ExploreFiltersCont,
  ExploreFiltersInnerCont,
  FoldersContainer,
  FoldersRow,
  MainButton,
  NewListingBody,
  NewListingCont,
  SingleFolderFourth,
  SingleFolderTab,
  SingleFolderThird,
} from "../Styles";
import { showTokensSection } from "./WalletComponents/TokensSection";
import { showNFTsSection } from "./WalletComponents/NFTsSection";
import { showRamSection } from "./WalletComponents/RamSection";
import { showCollectionSection } from "./WalletComponents/CollectionsSection";
import { showClaimRewardsSection } from "./WalletComponents/ClaimRewardsSection";
import { showManageFarmsSection } from "./WalletComponents/ManageFarmsSection";
import { showManagePoolsSection } from "./WalletComponents/ManagePoolsSection";
import { showManageDropsSection } from "./WalletComponents/ManageDropsSection";
import { showManageBlendsSection } from "./WalletComponents/ManageBlendsSection";
import { showManagePacksSection } from "./WalletComponents/ManagePacksSection";
import { showManagePremintedPoolsSection } from "./WalletComponents/ManagePremintedPoolsSection";
import { showManageDAOsSection } from "./WalletComponents/ManageDAOsSection";
import Error from "../components/Error";
import { showManageEscrowSection } from "./WalletComponents/EscrowSection";
import LoadingDiv from "../components/LoadingDiv";
import TransferNFTsModal from "../components/TransferNFTsModal";
import { showManageLocksSection } from "./WalletComponents/ManageLocksSection";

const WalletPage = ({ location }) => {
  const {
    currentUser,
    setCurrentUser,
    setFarmTemplates,
    aboutFarmDisplay,
    setAboutFarmDisplay,
    stakeNftsDisplay,
    setStakeNftsDisplay,
    stakeMultipleDisplay,
    setStakeMultipleDisplay,
    farmPrecision,
    setFarmPrecision,
    enterModalDisplay,
    setEnterModalDisplay,
    currentWalletSection,
    setCurrentWalletSection,
    walletSidebarIsOpen,
    setWalletSidebarIsOpen,
    myTokenBalances,
    setMyTokenBalances,
    currentExpandedToken,
    setCurrentExpandedToken,
    tokenIsExpanded,
    setTokenIsExpanded,
    thisTokenIsStakeableInThesePools,
    setThisTokenIsStakeableInThesePools,
    noPoolsFoundMessage,
    setNoPoolsFoundMessage,
    myCollections,
    setMyCollections,
    setCurrentExpandedCollection,
    dropIsExpanded,
    setDropIsExpanded,
    currentExpandedDrop,
    setCurrentExpandedDrop,
    myPremintedPools,
    setMyPremintedPools,
    loginDisplay,
    showSearchCollections,
    setShowSearchCollections,
    enteredText,
    setEnteredText,
    whitelistMeDisplay,
    setWhitelistMeDisplay,
    enterModalText,
    setEnterModalText,
    assetToBurn,
    setAssetToBurn,
  } = useStateContext();

  const {
    unstakedPageCount,
    setUnstakedPageCount,
    listOfAssets,
    setListOfAssets,
    stakedAssets,
    setStakedAssets,
  } = useWalletContext();

  const [unstaked, setUnstaked] = useState([]);
  const [farmList, setFarmList] = useState([]);
  const [totalAssets, setTotalAssets] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [pageCount, setPageCount] = useState(1);
  const [loadingDisplay, setLoadingDisplay] = useState("none");
  const [loadMoreDisplay, setLoadMoreDisplay] = useState("");
  const [newCollection, setNewCollection] = useState("");
  const [unstakedLoadMoreDisplay, setUnstakedLoadMoreDisplay] = useState("");
  const [currentSidebarSubcategory, setCurrentSidebarSubcategory] =
    useState("All");
  const [detailedList, setDetailedList] = useState([]);

  //New UI
  const [currentMainSection, setCurrentMainSection] = useState("Assets");
  const [currentAssetSection, setCurrentAssetSection] = useState("NFTs");
  const [currentNFTSection, setCurrentNFTSection] = useState("Unstaked");
  const [currentTokenSection, setCurrentTokenSection] = useState("Wallet");
  const [currentTokenAction, setCurrentTokenAction] = useState("Transfer");
  const [currentToolsSection, setCurrentToolsSection] = useState("Collections");
  const [firstUnstakedPageWasLoaded, setFirstUnstakedPageWasLoaded] = useState(false);
  const [retryUnstakedApi, setRetryUnstakedApi] = useState(false);
  const [apiIndex, setApiIndex] = useState(0);
  const [nextPageIsLoading, setNextPageIsLoading] = useState(false);

  const [assetVector, setAssetVector] = useState([]);
  const [showTransferNFTsModal, setShowTransferNFTsModal] = useState(false);

  // BEGIN USEEFFECT

  useEffect(() => {
    setStakeNftsDisplay("hidden");
    setAboutFarmDisplay("");
    setStakeMultipleDisplay("hidden");
    setFarmTemplates([]);
    setFarmPrecision("");
    setListOfAssets([]);
    setWalletSidebarIsOpen(false);
    setTokenIsExpanded(false);
    setCurrentExpandedToken("");
    setThisTokenIsStakeableInThesePools([]);
    setNoPoolsFoundMessage("");
    setCurrentExpandedCollection("");
    setMyCollections([]);
    setDropIsExpanded(false);
    setCurrentExpandedDrop(0);
    setMyPremintedPools([]);
    setShowSearchCollections(false);
    setEnteredText("");
    setWhitelistMeDisplay("none");

    const waxUsername = currentUsername;

    setMyCollections([]);

    axios
      .get(`${currentAtomicApiList[apiIndex]}/atomicassets/v1/accounts/${waxUsername}`)
      .then((atomicResponse) => {
        setMyCollections(atomicResponse.data.data);
      })
      .catch((error) => console.log(error));

    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "stakebackup",
        scope: "waxdaofarmer",
        code: "waxdaofarmer",
        key_type: "name",
        index_position: 2,
        limit: 100,
        lower_bound: waxUsername,
        upper_bound: waxUsername,
        json: true,
      })
      .then((farmVectors) => {
        var itr = 0;
        var list = [];
        var detailed_list = [];



        while (itr < farmVectors.data.rows.length) {
          if (farmVectors.data.rows[itr].assets != "") {
            list = list.concat(farmVectors.data.rows[itr].assets);
            var asset_index = 0;
            while (asset_index < farmVectors.data.rows[itr].assets.length) {
              detailed_list = [
                ...detailed_list,
                {
                  asset_id: farmVectors.data.rows[itr].assets[asset_index],
                  farmname: farmVectors.data.rows[itr].farmname,
                  unlock_time: farmVectors.data.rows[itr].unlocktime,
                },
              ];
              asset_index++;
            }

            itr++;
          } else {
            itr++;
          }
        }

        if (list.length >= 1) {
          axios
            .get(
              `${currentAtomicApiList[apiIndex]}/atomicassets/v1/assets?ids=${list.slice(0,100).join(
                "%2C"
              )}&page=1&limit=100&order=desc&sort=transferred`
            )
            .then((validResponse) => {
              var x = 0;
              if (validResponse.data.data.length > 0) {
                while (x < validResponse.data.data.length) {
                  validResponse.data.data[x].waxdao_farm_name =
                    detailed_list[
                      detailed_list.findIndex(
                        (attributeIndex) =>
                          attributeIndex.asset_id ===
                          validResponse.data.data[x].asset_id
                      )
                    ].farmname;
                  validResponse.data.data[x].waxdao_unlock_time =
                    detailed_list[
                      detailed_list.findIndex(
                        (attributeIndex) =>
                          attributeIndex.asset_id ===
                          validResponse.data.data[x].asset_id
                      )
                    ].unlock_time;
                  x++;
                }
              }

              setStakedAssets(validResponse.data.data);
              setListOfAssets(list);
              setTotalAssets(Number(list.length));
              setDetailedList(detailed_list);

              if (list.length > 100) {
                setPageCount(2);
              } else {
                setLoadMoreDisplay("hidden");
              }
            });
        } else {
          setLoadMoreDisplay("hidden");
        }
      });
  }, []);

  useEffect(() => {
    setCurrentWalletSection("NFTs");
    setUnstakedPageCount(1);
    setUnstakedLoadMoreDisplay("");

    const apiTimer = setTimeout(() => {

    if (!currentUsername) {return;}

    if(firstUnstakedPageWasLoaded) return

    setApiIndex(apiIndex + 1)

    setRetryUnstakedApi((prev) => !prev)

    axios
      .get(
        `${currentAtomicApiList[apiIndex]}/atomicassets/v1/assets?owner=${currentUsername}&page=1&limit=100&order=desc&sort=transferred`
      )
      .then((validResponse) => {
        setUnstaked(validResponse.data.data)
        setFirstUnstakedPageWasLoaded(true)

        if (validResponse.data.data.length == 100) {
          setUnstakedPageCount(2);
        } else {
          setUnstakedLoadMoreDisplay("hidden");
        }
      })

    }, 2000);

      return () => {clearTimeout(apiTimer);}


  }, [retryUnstakedApi]);

  useEffect(() => {
    setFarmList([]);

    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "farms",
        scope: "waxdaofarmer",
        code: "waxdaofarmer",
        limit: 1000,
        json: true,
      })
      .then((validResponse) => {
        setFarmList(validResponse.data.rows);
      });
  }, []);

  const getNextPage = (
    pageCount,
    setPageCount,
    stakedAssets,
    setStakedAssets,
    totalAssets,
    listOfAssets,
    detailedList
  ) => {

    axios
      .get(
        `${currentAtomicApiList[apiIndex]}/atomicassets/v1/assets?ids=${listOfAssets.slice(pageCount * 100, pageCount * 100 + 100).join(
          "%2C"
        )}&page=1&limit=100&order=desc&sort=transferred`
      )
      .then((validResponse) => {
        var list = [...stakedAssets];

        if (Array.isArray(stakedAssets[0])) {
          list = stakedAssets[0];
        }

        var x = 0;
        if (validResponse.data.data.length > 0) {
          while (x < validResponse.data.data.length) {
            validResponse.data.data[x].waxdao_farm_name =
              detailedList[
                detailedList.findIndex(
                  (attributeIndex) =>
                    attributeIndex.asset_id ===
                    validResponse.data.data[x].asset_id
                )
              ]?.farmname;
            validResponse.data.data[x].waxdao_unlock_time =
              detailedList[
                detailedList.findIndex(
                  (attributeIndex) =>
                    attributeIndex.asset_id ===
                    validResponse.data.data[x].asset_id
                )
              ]?.unlock_time;
            x++;
          }
        }

        list = list.concat(validResponse.data.data);

        setStakedAssets(list);

        if (pageCount * pageSize < totalAssets) {
          setPageCount(pageCount + 1);
        } else {
          setLoadMoreDisplay("hidden");
        }
      })
      .catch((error) => console.log(error));
  };


  const switchCollection = (
    unstakedPageCount,
    setUnstakedPageCount,
    unstaked,
    setUnstaked,
    setUnstakedLoadMoreDisplay,
    setNewCollection,
    newCollection,
    setShowSearchCollections
  ) => {
    setUnstaked([]);
    setShowSearchCollections(false);

    const waxUsername = currentUsername;
    if (!waxUsername) {
      return;
    }

    let thisPage = 1;
    setNewCollection(newCollection);

    axios
      .get(
        `${getCurrentAtomicApiList}/atomicassets/v1/assets?collection_name=${newCollection}&owner=${waxUsername}&page=${thisPage}&limit=100&order=desc&sort=transferred`
      )
      .then((atomicResponse) => {
        setUnstaked(atomicResponse.data.data);

        if (atomicResponse.data.data.length >= 100) {
          setUnstakedPageCount(2);
          setUnstakedLoadMoreDisplay("");
        } else {
          setUnstakedLoadMoreDisplay("hidden");
        }
      })
      .catch((error) => console.log(error));
  };

  if (currentUsername) {
    return (
      <div id="seo">
        <Helmet>
          <title>
            {currentUser != null && currentUser != ""
              ? `${currentUser}'s wallet`
              : "WaxDAO Wallet"}
          </title>
          <meta name="description" content="View your wallet on WaxDAO" />
          <link rel="canonical" href={`${currentWebsiteURL}/wallet`} />
        </Helmet>

        <UALProvider
          chains={[myChain]}
          authenticators={[anchor, wax, wombat]}
          appName={"WaxDAO"}
        >
          <ModalOverlay className={enterModalDisplay} />
          <Modal className={enterModalDisplay}>
            <ModalContent>{enterModalText}</ModalContent>

            <Spinner style={{ display: loadingDisplay }}>
              <SpinnerRed />
              <SpinnerBlue />
              <SpinnerGreen />
            </Spinner>

            <br />
            <MainButton
              onClick={() => {
                whiteListMe(
                  "token creator",
                  assetToBurn,
                  setEnterModalDisplay,
                  setEnterModalText,
                  setLoadingDisplay,
                  setWhitelistMeDisplay
                );
              }}
              style={{ display: whitelistMeDisplay }}
            >
              Whitelist Me
            </MainButton>
            <br />
            <GameButton
              onClick={() => {
                setEnterModalDisplay("hidden");
                setWhitelistMeDisplay("none");
              }}
            >
              {whitelistMeDisplay == "" ? "Don't touch my NFT" : "Close Window"}
            </GameButton>
          </Modal>

          <NewListingCont>
            <NewListingBody>
              <FoldersContainer>
                <FoldersRow>
                  <SingleFolderTab
                    onClick={() => setCurrentMainSection("Assets")}
                    selected={currentMainSection == "Assets" && true}
                  >
                    Assets
                  </SingleFolderTab>
                  <SingleFolderTab
                    onClick={() => setCurrentMainSection("Tools")}
                    selected={currentMainSection == "Tools" && true}
                  >
                    Tools
                  </SingleFolderTab>
                </FoldersRow>
              </FoldersContainer>

              <span className={currentMainSection != "Assets" && "hidden"}>
                <ExploreFiltersCont>
                  {/* <h2>Asset Types</h2> */}
                  <ExploreFiltersInnerCont>
                    <FoldersContainer>
                      <FoldersRow>
                        <SingleFolderThird
                          onClick={() => setCurrentAssetSection("NFTs")}
                          selected={currentAssetSection == "NFTs" && true}
                        >
                          NFTs
                        </SingleFolderThird>
                        <SingleFolderThird
                          onClick={() => {
                            setCurrentAssetSection("Tokens");
                            openWalletTokenBalancesTab(setMyTokenBalances);
                          }}
                          selected={currentAssetSection == "Tokens" && true}
                        >
                          Tokens
                        </SingleFolderThird>
                        <SingleFolderThird
                          onClick={() => setCurrentAssetSection("RAM")}
                          selected={currentAssetSection == "RAM" && true}
                        >
                          RAM
                        </SingleFolderThird>
                      </FoldersRow>
                    </FoldersContainer>
                  </ExploreFiltersInnerCont>
                </ExploreFiltersCont>

                {showNFTsSection(
                  currentAssetSection,
                  currentNFTSection,
                  setCurrentNFTSection,
                  currentWalletSection,
                  currentSidebarSubcategory,
                  myCollections,
                  enteredText,
                  setEnteredText,
                  showSearchCollections,
                  setShowSearchCollections,
                  switchCollection,
                  unstakedPageCount,
                  setUnstakedPageCount,
                  unstaked,
                  setUnstaked,
                  setUnstakedLoadMoreDisplay,
                  setNewCollection
                )}

                {showTokensSection(
                  currentAssetSection,
                  currentTokenSection,
                  setCurrentTokenSection,
                  openWalletTokenBalancesTab,
                  setMyTokenBalances,
                  currentTokenAction,
                  setCurrentTokenAction,
                  myTokenBalances,
                  setLoadingDisplay
                )}

                {showRamSection(currentAssetSection, setLoadingDisplay)}
              </span>

              <span className={currentMainSection != "Tools" && "hidden"}>
                <ExploreFiltersCont>
                  {/* <h2>Asset Types</h2> */}
                  <ExploreFiltersInnerCont>
                    <FoldersContainer>
                      <FoldersRow>
                        <SingleFolderFourth
                          onClick={() => setCurrentToolsSection("Collections")}
                          selected={
                            currentToolsSection == "Collections" && true
                          }
                        >
                          Collections
                        </SingleFolderFourth>

                        <SingleFolderFourth
                          onClick={() =>
                            setCurrentToolsSection("Claim Rewards")
                          }
                          selected={
                            currentToolsSection == "Claim Rewards" && true
                          }
                        >
                          Claim Rewards
                        </SingleFolderFourth>

                        <SingleFolderFourth
                          onClick={() =>
                            setCurrentToolsSection("Manage Farms")
                          }
                          selected={
                            currentToolsSection == "Manage Farms" && true
                          }
                        >
                          Manage Farms
                        </SingleFolderFourth>

                        <SingleFolderFourth
                          onClick={() =>
                            setCurrentToolsSection("Manage Pools")
                          }
                          selected={
                            currentToolsSection == "Manage Pools" && true
                          }
                        >
                          Manage Pools
                        </SingleFolderFourth>
                      </FoldersRow>

                      <FoldersRow>
                        <SingleFolderFourth
                          onClick={() => setCurrentToolsSection("Manage Drops")}
                          selected={
                            currentToolsSection == "Manage Drops" && true
                          }
                        >
                          Manage Drops
                        </SingleFolderFourth>

                        <SingleFolderFourth
                          onClick={() =>
                            setCurrentToolsSection("Manage Blends")
                          }
                          selected={
                            currentToolsSection == "Manage Blends" && true
                          }
                        >
                          Manage Blends
                        </SingleFolderFourth>

                        <SingleFolderFourth
                          onClick={() =>
                            setCurrentToolsSection("Manage Packs")
                          }
                          selected={
                            currentToolsSection == "Manage Packs" && true
                          }
                        >
                          Manage Packs
                        </SingleFolderFourth>

                        <SingleFolderFourth
                          onClick={() =>
                            setCurrentToolsSection("Premint Pools")
                          }
                          selected={
                            currentToolsSection == "Premint Pools" && true
                          }
                        >
                          Premint Pools
                        </SingleFolderFourth>                        

                      </FoldersRow>

                      <FoldersRow>

                      <SingleFolderFourth
                          onClick={() =>
                            setCurrentToolsSection("Manage DAOs")
                          }
                          selected={
                            currentToolsSection == "Manage DAOs" && true
                          }
                        >
                          Manage DAOs
                        </SingleFolderFourth>

                        <SingleFolderFourth
                          onClick={() => setCurrentToolsSection("Synthetics")}
                          selected={
                            currentToolsSection == "Synthetics" && true
                          }
                        >
                          Synthetics
                        </SingleFolderFourth>

                        <SingleFolderFourth
                          onClick={() =>
                            setCurrentToolsSection("Escrow")
                          }
                          selected={
                            currentToolsSection == "Escrow" && true
                          }
                        >
                          Escrow
                        </SingleFolderFourth>

                        <SingleFolderFourth
                          onClick={() =>
                            setCurrentToolsSection("Locks")
                          }
                          selected={
                            currentToolsSection == "Locks" && true
                          }
                        >
                          Token Locker
                        </SingleFolderFourth>                           

                      </FoldersRow>

                    </FoldersContainer>
                  </ExploreFiltersInnerCont>
                </ExploreFiltersCont>

                {showCollectionSection(
                  currentMainSection,
                  currentToolsSection,
                  setLoadingDisplay
                )}
              </span>

              {showClaimRewardsSection(
                currentMainSection,
                currentToolsSection,
                setLoadingDisplay
              )}

              {showManageFarmsSection(
                currentToolsSection
              )}

              {showManagePoolsSection(
                currentToolsSection
              )}

              {showManageDropsSection(
                currentToolsSection
              )}

              {showManageBlendsSection(
                currentToolsSection
              )}

              {showManagePacksSection(
                currentToolsSection
              )}

              {showManagePremintedPoolsSection(
                currentToolsSection
              )}

              {showManageDAOsSection(
                currentToolsSection
              )}

              {showManageEscrowSection(
                currentToolsSection
              )}

              {showManageLocksSection(
                currentToolsSection
              )}              

              {/* STAKED NFTs SECTION */}

              <span
                className={
                  (currentMainSection != "Assets" ||
                    currentAssetSection != "NFTs" ||
                    currentNFTSection != "Staked") &&
                  "hidden"
                }
              >
                <WalletNFTCardsCont>
                  {stakedAssets.length > 0 ? (
                    stakedAssets.map((item, index) => (
                      <WalletCard
                        key={index}
                        item={item}
                        farmList={farmList}
                        unstaked={false}
                        stakeableOnly={false}
                      />

                      

                      /*
<WalletNFTTopHalfNameAndMint>



{item.data != null && item.data.img == null && item.data.video != null && (
  <WalletNFTVideo controls loop>
  <source src={`https://ipfs.io/ipfs/${item.data.video.trim()}`} type="video/mp4"/>
</WalletNFTVideo>

  

)}

{item.data != null && item.data.img == null && item.data.video == null && item.data.image != null && (
  <WalletNFTLogo
  src={`https://ipfs.io/ipfs/${item.data.image.trim()}`} 
  />

)}

</WalletNFTTopHalf>
{item.waxdao_farm_name != null && 
(
  <span>
  <NFTCallToActionHref target="none" href={`${currentWebsiteURL}/farm/${item.waxdao_farm_name}`}>
    {item.waxdao_farm_name}
  </NFTCallToActionHref>
  {item.waxdao_unlock_time != null && item.waxdao_unlock_time * 1000 <= new Date() && (
  <NFTCallToAction onClick={() => { unstakeNFT(item.waxdao_farm_name, item.asset_id, setEnterModalText, setLoadingDisplay, setEnterModalDisplay)  }} >
  Unstake
</NFTCallToAction> 
  )}

{item.waxdao_unlock_time != null && item.waxdao_unlock_time * 1000 > new Date() && (
  <NFTCallToActionDisabled disabled>
  <BsLockFill style={{display: "inline-block"}} /> {new Date(item.waxdao_unlock_time * 1000).toLocaleDateString()}
</NFTCallToActionDisabled>
  )}
  </span>
)
}

  </WalletNFT>
*/
                    ))
                  ) : (
                    <NoDaosMessage>
                      You don't have any NFTs staked on WaxDAO
                    </NoDaosMessage>
                  )}
                </WalletNFTCardsCont>
                <br />
                <NewRow />
                <div className="text-center w-100">
                  <DaoButton
                    className={loadMoreDisplay}
                    onClick={() => {
                      getNextPage(
                        pageCount,
                        setPageCount,
                        stakedAssets,
                        setStakedAssets,
                        totalAssets,
                        listOfAssets,
                        detailedList
                      );
                    }}
                  >
                    Load More
                  </DaoButton>

                  <DisabledButton
                    className={loadMoreDisplay != "hidden" && "hidden"}
                  >
                    No More Results
                  </DisabledButton>
                </div>
              </span>

              {/* END OF STAKED NFTs SECTION */}

              {/* ALL (UNSTAKED) NFTs SECTION */}

              <span
                className={
                  (currentMainSection != "Assets" ||
                    currentAssetSection != "NFTs" ||
                    currentNFTSection != "Unstaked") &&
                  "hidden"
                }
              >
                {!firstUnstakedPageWasLoaded && (
                  <LoadingDiv />
                )}
                <span className={!firstUnstakedPageWasLoaded && "hidden"}>
                <WalletNFTCardsCont>

                  
                  {unstaked.length > 0 ? (
                    unstaked.map((item, index) => (
                      <WalletCard
                        key={index}
                        item={item}
                        farmList={farmList}
                        unstaked={true}
                        stakeableOnly={false}
                        setEnterModalText={setEnterModalText}
                        setEnterModalDisplay={setEnterModalDisplay}
                        setWhitelistMeDisplay={setWhitelistMeDisplay}
                        setAssetToBurn={setAssetToBurn}
                        showIcons={true}
                        assetVector={assetVector}
                        setAssetVector={setAssetVector}
                      />
                    ))
                  ) : (
                    <NoDaosMessage>
                      You don't have any NFTs in your wallet.
                    </NoDaosMessage>
                  )}
                
                </WalletNFTCardsCont>
                <br />
                <NewRow />
                <div className="text-center w-100">
                  <DaoButton
                    className={unstakedLoadMoreDisplay}
                    onClick={() => {
                      setNextPageIsLoading(true)

                      getNextUnstakedPage(
                        unstakedPageCount,
                        setUnstakedPageCount,
                        unstaked,
                        setUnstaked,
                        setUnstakedLoadMoreDisplay,
                        newCollection,
                        setNextPageIsLoading,
                      );
                    }}
                  >
                    Load More
                  </DaoButton>

                  <DisabledButton
                    className={unstakedLoadMoreDisplay != "hidden" && "hidden"}
                  >
                    No More Results
                  </DisabledButton>
                </div>
                </span>
              </span>

            {assetVector.length > 0 && (
              <StakeManyDiv onClick={() => setShowTransferNFTsModal(true)}>
                Send {assetVector.length} NFTs <br/>
              </StakeManyDiv>
            )}

            <TransferNFTsModal showTransferNFTsModal={showTransferNFTsModal} setShowTransferNFTsModal={setShowTransferNFTsModal}
              assetVector={assetVector} setEnterModalDisplay={setEnterModalDisplay} setEnterModalText={setEnterModalText} 
              setLoadingDisplay={setLoadingDisplay}
            />
            <ModalOverlay className={!showTransferNFTsModal && "hidden"} />



              {/* END OF UNSTAKED NFTs SECTION */}

              {/* ALL (UNSTAKED) NFTs SECTION */}

              <span
                className={
                  (currentMainSection != "Assets" ||
                    currentAssetSection != "NFTs" ||
                    currentNFTSection != "Stakeable") &&
                  "hidden"
                }
              >

                {!firstUnstakedPageWasLoaded && (
                  <LoadingDiv />
                )}

                  <span className={!firstUnstakedPageWasLoaded && "hidden"}>
                <WalletNFTCardsCont>
                  {unstaked.length > 0 ? (
                    unstaked.map((item, index) => (
                      <WalletCard
                        key={index}
                        item={item}
                        farmList={farmList}
                        unstaked={true}
                        stakeableOnly={true}
                        setEnterModalText={setEnterModalText}
                        setEnterModalDisplay={setEnterModalDisplay}
                        setWhitelistMeDisplay={setWhitelistMeDisplay}
                        setAssetToBurn={setAssetToBurn}
                        showIcons={true}
                      />
                    ))
                  ) : (
                    <NoDaosMessage>
                      You don't have any NFTs in your wallet.
                    </NoDaosMessage>
                  )}
                </WalletNFTCardsCont>
                <br />
                <NewRow />
                {nextPageIsLoading && <LoadingDiv />}
                <div className="text-center w-100">
                  <DaoButton
                    className={unstakedLoadMoreDisplay}
                    onClick={() => {
                      setNextPageIsLoading(true)

                      getNextUnstakedPage(
                        unstakedPageCount,
                        setUnstakedPageCount,
                        unstaked,
                        setUnstaked,
                        setUnstakedLoadMoreDisplay,
                        newCollection,
                        setNextPageIsLoading,
                      );
                    }}
                  >
                    Load More
                  </DaoButton>

                  <DisabledButton
                    className={unstakedLoadMoreDisplay != "hidden" && "hidden"}
                  >
                    No More Results
                  </DisabledButton>
                </div>
                </span>

              </span>

              {/* END OF STAKEABLE ONLY NFTs SECTION */}

              {/* SYNTHETIC TOKENS SECTION */}

              <span
                className={
                  currentToolsSection != "Synthetics" &&
                  "hidden"
                }
              >
                <Synthetics
                  something={"Hello"}
                  person="Mike"
                  a={enterModalDisplay}
                  b={setEnterModalDisplay}
                  c={enterModalText}
                  d={setEnterModalText}
                  e={loadingDisplay}
                  f={setLoadingDisplay}
                />
              </span>

              {/* END OF SYNTHETIC TOKENS SECTION */}

            </NewListingBody>
          </NewListingCont>
        </UALProvider>
        <br />
        <br />
        <br />
      </div>
    );
  } else {
    return (
      <Error error="You must log in to use the wallet page" />
    );
  }
};

export default WalletPage;
