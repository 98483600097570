import { getWalletSession } from "./wallet_functions";


export const otcTransfer = async (amount, symbol, precision, receiver, memo, setEnterModalText, setLoadingDisplay, setEnterModalDisplay) => {
    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');

    var amount_to_transfer;
    var response_message;
    

    if(Number(precision) == 0){
        amount_to_transfer = Math.round(Number(amount)) + " " + symbol;
    } else {
        amount_to_transfer = Number(amount).toFixed(Number(precision)) + " " + symbol;
    }

    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];

    var contract_account = "eosio.token";



        try {
            const action = [{
              account: contract_account,
              name: 'transfer',
              authorization: [{
                actor: this_user,
                permission: this_permission,
            }],
            data: {
                from: this_user,
                to: receiver,
                quantity: amount_to_transfer,
                memo: memo,
            }
          }]
            const response = await session[0].signTransaction({
              actions: action
            }, {
      
              blocksBehind: 3,
              expireSeconds: 300,
              broadcast: true,
            })
            setLoadingDisplay('');
            setEnterModalText('Processing your transaction...')
            const timer = setTimeout(() => {
              
                setEnterModalText(response_message);
                setLoadingDisplay('none');
          
            }, 4000);
            return () => clearTimeout(timer);
        
          } catch(e) {
                setEnterModalText(JSON.stringify(e.message));
                console.log(e)
          }

  }//end createPremintPool