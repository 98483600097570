import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { UALProvider } from "ual-reactjs-renderer";
import { Helmet } from "react-helmet";
import axios from "axios";
import collection_profile_stock_bg from "../data/stars.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  StakeLeftRow,
  StakeRightRow,
  StakersRow,
  Modal,
  ModalContent,
  Spinner,
  SpinnerRed,
  SpinnerBlue,
  SpinnerGreen,
  GameButton,
  ModalOverlay,
} from "../components/LeftDiv";
import { Name } from "eos-common";
import {
  anchor,
  currentAtomicHubLink,
  currentUsername,
  currentWebsiteURL,
  getApiListByIndex,
  getCurrentApiList,
  getCurrentAtomicApiList,
  myChain,
  wax,
  wombat,
} from "../data/config";
import {
  addCollection,
  addDynamicTemp,
  addTemplates,
  assetsAreAddable,
  banUser,
  changeLogoNew,
  depositTokens,
  editStakeableAssets,
  extendFarmNew,
  farmIsUpgradeable,
  getRewardTokenBalance,
  increaseTemplateMax,
  kickUser,
  newProfile,
  removeCollection,
  removeTemplate,
  unbanUser,
} from "../data/functions/edit_farm_functions";
import { useGetDynamicTemplates } from "../components/CustomHooks/useGetDynamicTemplates";
import { StakeableAssetsContainer } from "../data/css/FarmPageStyles";
import { createCSV, setFarmStatus } from "../data/functions/farm_functions";
import LoadingPage from "../components/LoadingPage";
import {
  CollectionProfileAvatarCont,
  CollectionProfileBGContainer,
  CollectionProfileContainer,
} from "../data/css/CollectionPageStyles";
import { FARM_STATUSES, resizerPrefix, resizerSuffix } from "../data/constants";
import Error from "../components/Error";
import {
  ExploreFiltersCont,
  ExploreFiltersInnerCont,
  ExploreFiltersScrollCont,
  FoldersContainer,
  FoldersRow,
  MainButton,
  MilestoneContainer,
  MilestoneDescription,
  NewListingBody,
  SingleFolderFourth,
  SingleFolderThird,
  SmallButton,
  TallFiltersCont,
  TallFiltersInnerCont,
  theme,
} from "../Styles";
import {
  PoolLineBreak,
  SinglePoolDetail,
  SinglePoolDetailBold,
} from "../data/css/PoolPageStyles";
import {
  getHourlyReward,
  getPoolSize,
  getPrecisionFromAsset,
} from "../data/functions/pool_functions";
import { handleCalendarChange } from "../data/functions/pack_functions";
import { useGetBannedUsers } from "../components/CustomHooks/useGetBannedUsers";
import { SendAllButton } from "../data/css/WalletStyles";

const FarmOptions = ({ location }) => {
  const {
    enterModalText,
    setEnterModalText,
    loadingDisplay,
    setLoadingDisplay,
    enterButtonsDisplay,
    setEnterButtonsDisplay,
    enterModalDisplay,
    setEnterModalDisplay,
  } = useStateContext();

  const { FarmName } = useParams();

  const [farmData, setFarmData] = useState([]);
  const [dynamicTemps, getDynamicTemps] = useGetDynamicTemplates();
  const [dynamicStakingValue, setDynamicStakingValue] = useState(0);
  const [dynamicCollection, setDynamicCollection] = useState("");
  const [currentSection, setCurrentSection] = useState("Deposit");
  const [farmIsLoading, setFarmIsLoading] = useState(true);
  const [listOfStakers, setListOfStakers] = useState([]);
  const [profile, setProfile] = useState([]);
  const [farmWasLoaded, setFarmWasLoaded] = useState(false);
  const [amount, setAmount] = useState(0);
  const [startTime, setStartTime] = useState(Date.now() / 1000);
  const [collectionToAdd, setCollectionToAdd] = useState("");
  const [showStakerModal, setShowStakerModal] = useState(false);

  const [currentBanSection, setCurrentBanSection] = useState("Add");
  const [currentStakeableAssetsSection, setCurrentStakeableAssetsSection] =
    useState("View");
  const [currentViewStakeableTab, setCurrentViewStakeableTab] =
    useState("Collections");
  const [userToBan, setUserToBan] = useState("");
  const [
    bannedUsers,
    getBannedUsers,
    bannedUsersAreLoading,
    bannedUsersWereLoaded,
  ] = useGetBannedUsers(FarmName);
  const [templateIDToAdd, setTemplateIDToAdd] = useState(0);
  const [templateIDListToAdd, setTemplateIDListToAdd] = useState("");

  const [rewardTokenBalance, setRewardTokenBalance] = useState("");

  //profile
  const [image1, setImage1] = useState("");
  const [description, setDescription] = useState("");
  const [telegram, setTelegram] = useState("");
  const [discord, setDiscord] = useState("");
  const [twitter, setTwitter] = useState("");
  const [website, setWebsite] = useState("");
  const [nftDrop, setNftDrop] = useState("");

  useEffect(() => {
    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "farms",
        scope: "waxdaofarmer",
        code: "waxdaofarmer",
        key_type: "name",
        indexName: "farmname",
        limit: 1,
        lower_bound: FarmName,
        upper_bound: FarmName,
        json: true,
      })
      .then((response) => {
        setFarmWasLoaded(true);
        setFarmData(response.data.rows[0]);

        if (
          response.data.rows[0].farmtype == "template" &&
          response.data.rows[0].reward_type == "dynamic"
        ) {
          getDynamicTemps(FarmName, response.data.rows[0].farmtype);
        }

        else if (
          response.data.rows[0].farmtype == "nc.v2" &&
          response.data.rows[0].reward_type == "dynamic"
        ) {
          getDynamicTemps(FarmName, response.data.rows[0].farmtype);
        }        


      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setFarmIsLoading(false));
  }, []);

  useEffect(() => {
    const nameValue = new Name(FarmName).raw().toString();
    if (!currentUsername) {
      return;
    }

    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "farmprofiles",
        scope: "waxdaofarmer",
        code: "waxdaofarmer",
        limit: 1,
        lower_bound: nameValue,
        upper_bound: nameValue,
        json: true,
      })
      .then((profileResponse) => {
        setProfile(profileResponse.data.rows[0]);
        if (profileResponse.data.rows[0] != null) {
          setDescription(profileResponse.data.rows[0].description);
          setTelegram(profileResponse.data.rows[0].telegram);
          setDiscord(profileResponse.data.rows[0].discord);
          setTwitter(profileResponse.data.rows[0].twitter);
          setWebsite(profileResponse.data.rows[0].website);
          setNftDrop(profileResponse.data.rows[0].latest_drop);
          setImage1(profileResponse.data.rows[0].image1);
        }
      })

      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const abortCont5 = new AbortController();

    if (!currentUsername) {
      return;
    }

    axios
      .post(`${getCurrentApiList}/v1/chain/get_table_rows`, {
        table: "stakebackup",
        scope: "waxdaofarmer",
        code: "waxdaofarmer",
        key_type: "name",
        index_position: "3",
        limit: 1000,
        lower_bound: FarmName,
        upper_bound: FarmName,
        json: true,
        signal: abortCont5,
      })
      .then((res) => {
        setListOfStakers(res.data.rows);
      })

      .catch((error) => console.log(error));

    return () => abortCont5.abort();
  }, []);

  useEffect(() => {
    if (currentBanSection == "View" && !bannedUsersWereLoaded) {
      getBannedUsers(FarmName);
    }
  }, [currentBanSection]);

  useEffect(() => {
    if (farmData?.creator != null) {
      getRewardTokenBalance(farmData.contract, farmData.rewardtoken, setRewardTokenBalance);
    }
  }, [farmData]);

  if (!farmIsLoading && farmData?.creator == currentUsername && farmWasLoaded) {
    return (
      <div id="seo">
        <Helmet>
          <title>Edit {FarmName} Farm</title>
          <meta name="description" content={`Manage your NFT farm on WaxDAO`} />
          <link
            rel="canonical"
            href={`${currentWebsiteURL}/edit-farm/${FarmName}`}
          />
        </Helmet>

        <UALProvider
          chains={[myChain]}
          authenticators={[anchor, wax, wombat]}
          appName={"WaxDAO"}
        >
          <Modal className={enterModalDisplay}>
            <ModalContent>{enterModalText}</ModalContent>

            <Spinner style={{ display: loadingDisplay }}>
              <SpinnerRed />
              <SpinnerBlue />
              <SpinnerGreen />
            </Spinner>

            <br />
            <GameButton onClick={() => setEnterModalDisplay("hidden")}>
              Close Window
            </GameButton>
          </Modal>

          <CollectionProfileContainer>
            <CollectionProfileBGContainer>
              {profile?.image1 != null && profile?.image1?.length > 0 ? (
                <img
                  src={`${"https://ipfs.io/ipfs/"}${profile.image1}`}
                  style={{ objectFit: "cover", width: "100%", height: "100%" }}
                />
              ) : (
                <img
                  src={collection_profile_stock_bg}
                  style={{
                    width: "auto",
                    height: "auto",
                    minHeight: "100%",
                    minWidth: "100%",
                  }}
                />
              )}
            </CollectionProfileBGContainer>

            <CollectionProfileAvatarCont>
              <img
                src={
                  farmData.logo != null &&
                  `${resizerPrefix}${farmData.logo}${resizerSuffix}`
                }
                style={{
                  width: "auto",
                  height: "auto",
                  minHeight: "100%",
                  minWidth: "100%",
                }}
              />
            </CollectionProfileAvatarCont>
          </CollectionProfileContainer>

          <div className="p-5 mt-4 text-white">
            <br />
            <span className="text-2xl font-semibold">{FarmName}</span>
            <br />
            <span className="text-md">
              {farmData.creator != null && <span>By {farmData.creator}</span>}
            </span>

            {profile?.description != null &&
              profile?.description?.length > 3 && (
                <MilestoneContainer>
                  <MilestoneDescription>
                    {profile.description}
                  </MilestoneDescription>
                </MilestoneContainer>
              )}
          </div>

          <NewListingBody>
            <ExploreFiltersCont>
              <h2>Farm Details</h2>

              <br />
              <ExploreFiltersInnerCont>
                <SinglePoolDetail>
                  Farm Status{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {
                      FARM_STATUSES[
                        FARM_STATUSES.findIndex(
                          (attributeIndex) =>
                            attributeIndex.value === farmData.allow_new_members
                        )
                      ].display_text
                    }
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Total Staked{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {farmData?.totalstaked}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Hourly Pool{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {getHourlyReward(farmData)}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Total Pool{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {getPoolSize(farmData)}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Lock Period{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {farmData.mintime / 86400} Days
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
                <SinglePoolDetail>
                  Expires{" "}
                  <PoolLineBreak>
                    <br />
                  </PoolLineBreak>
                  <SinglePoolDetailBold>
                    {new Date(farmData.enddate * 1000).toLocaleDateString()}
                  </SinglePoolDetailBold>
                </SinglePoolDetail>
              </ExploreFiltersInnerCont>
            </ExploreFiltersCont>

            <TallFiltersCont>
              <h2>Stakeable Assets</h2>

              <br />
              <FoldersContainer>
                <FoldersRow>
                  <SingleFolderThird
                    onClick={(e) => {
                      setCurrentStakeableAssetsSection("View");
                    }}
                    selected={currentStakeableAssetsSection == "View" && true}
                  >
                    View
                  </SingleFolderThird>

                  {assetsAreAddable(farmData) && (
                    <SingleFolderThird
                      onClick={(e) => {
                        setCurrentStakeableAssetsSection("Add");
                      }}
                      selected={currentStakeableAssetsSection == "Add" && true}
                    >
                      Add
                    </SingleFolderThird>
                  )}

                  {farmIsUpgradeable(farmData) && (
                    <SingleFolderThird
                      onClick={(e) => {
                        setCurrentStakeableAssetsSection("Upgrade");
                      }}
                      selected={
                        currentStakeableAssetsSection == "Upgrade" && true
                      }
                    >
                      Upgrade
                    </SingleFolderThird>
                  )}
                </FoldersRow>
              </FoldersContainer>
              <br />
              {((farmData.farmtype == "template" &&
                farmData.reward_type == "dynamic") ||
                (farmData.farmtype == "nc.v2" &&
                farmData.reward_type == "dynamic")
                ) && (
                  <span>
                    <FoldersContainer>
                      <FoldersRow>
                        <SingleFolderThird
                          onClick={(e) => {
                            setCurrentViewStakeableTab("Collections");
                          }}
                          selected={
                            currentViewStakeableTab == "Collections" && true
                          }
                        >
                          Collections
                        </SingleFolderThird>
                        <SingleFolderThird
                          onClick={(e) => {
                            setCurrentViewStakeableTab("Templates");
                          }}
                          selected={
                            currentViewStakeableTab == "Templates" && true
                          }
                        >
                          Templates
                        </SingleFolderThird>
                      </FoldersRow>
                    </FoldersContainer>
                    <br />
                  </span>
                )}
              <TallFiltersInnerCont>
                {currentStakeableAssetsSection == "View" && (
                  <ExploreFiltersScrollCont>
                    <StakeableAssetsContainer>
                      {editStakeableAssets(
                        farmData,
                        dynamicTemps,
                        currentViewStakeableTab,
                        setEnterModalText,
                        setLoadingDisplay,
                        setEnterButtonsDisplay,
                        setEnterModalDisplay
                      )}
                    </StakeableAssetsContainer>
                  </ExploreFiltersScrollCont>
                )}

                {currentStakeableAssetsSection == "Add" && (
                  <span>
                    {currentViewStakeableTab == "Collections" &&
                      (farmData.reward_type == "dynamic" || farmData.farmtype == "nc.c.shared") && (
                        <span>
                          <h3>Collection Name</h3>
                          <input value={collectionToAdd} onChange={(e) => setCollectionToAdd(e.target.value)} />
                          <br />
                          <br />
                          <MainButton
                            onClick={() => addCollection(FarmName, collectionToAdd, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay)}
                          
                          >Submit</MainButton>
                        </span>
                      )}

                    {(currentViewStakeableTab == "Templates" ||
                      (farmData.farmtype == "template" &&
                        farmData.reward_type != "dynamic") || farmData.farmtype == "s.exclude") && (
                      <span>
                        {((farmData.farmtype == "template" &&
                          farmData.reward_type == "dynamic") || 
                          (farmData.farmtype == "nc.v2" &&
                          farmData.reward_type == "dynamic")) && (
                            <span>
                              <h3>Collection Name</h3>
                              <input
                                placeholder="e.g. hoodpunknfts"
                                value={dynamicCollection}
                                onChange={(e) =>
                                  setDynamicCollection(
                                    e.target.value
                                  ).toLowerCase()
                                }
                                maxLength={13}
                              />
                              <br />
                              <br />
                            </span>
                          )}

                        {((farmData.farmtype == "template" &&
                          farmData.reward_type == "dynamic") ||
                          (farmData.farmtype == "nc.v2" &&
                          farmData.reward_type == "dynamic")
                          ) && (
                            <span>
                              <h3>Template ID</h3>
                              <input
                                placeholder="e.g. 123456"
                                value={
                                  templateIDToAdd != 0 ? templateIDToAdd : ""
                                }
                                onChange={(e) =>
                                  setTemplateIDToAdd(e.target.value)
                                }
                                maxLength={7}
                              />
                              <br />
                              <br />
                              <h3>Hourly {farmData.rewardtoken} Reward</h3>
                              <input
                                placeholder="e.g. 69.420"
                                value={dynamicStakingValue}
                                onChange={(e) =>
                                  setDynamicStakingValue(e.target.value)
                                }
                              />
                              <br />
                              <br />
                              <MainButton
                                onClick={() =>
                                  addDynamicTemp(
                                    FarmName,
                                    farmData.farmtype,
                                    templateIDToAdd,
                                    dynamicCollection,
                                    dynamicStakingValue,
                                    getPrecisionFromAsset(farmData.poolsize),
                                    farmData.rewardtoken,
                                    setEnterModalText,
                                    setLoadingDisplay,
                                    setEnterButtonsDisplay,
                                    setEnterModalDisplay
                                  )
                                }
                              >
                                Submit
                              </MainButton>
                            </span>
                          )}

                        {farmData.reward_type != "dynamic" && (
                          <span>
                            <h3>Template IDs (Comma separated)</h3>
                            <textarea
                              placeholder="e.g 123456, 222222, 333333"
                              value={templateIDListToAdd}
                              onChange={(e) =>
                                setTemplateIDListToAdd(
                                  e.target.value.replace(" ", "")
                                )
                              }
                            />
                            <br />
                            <br />

                            <MainButton
                              onClick={() =>
                                addTemplates(
                                  FarmName,
                                  templateIDListToAdd,
                                  setEnterModalText,
                                  setLoadingDisplay,
                                  setEnterButtonsDisplay,
                                  setEnterModalDisplay
                                )
                              }
                            >
                              Submit
                            </MainButton>
                          </span>
                        )}
                      </span>
                    )}
                  </span>
                )}

                {currentStakeableAssetsSection == "Upgrade" && (
                  <span>
                    Did you know you can close this farm and open a Version 2
                    farm? Version 2 farms allow unlimited templates with 0 extra
                    fees.
                    <br />
                    <br />
                    <a href="https://youtu.be/SFeuzMjHxoc" target="none">
                      <MainButton>Learn More</MainButton>
                    </a>
                  </span>
                )}
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Actions</h2>

              <br />
              <FoldersContainer>
                <FoldersRow>
                  <SingleFolderFourth
                    onClick={(e) => {
                      setCurrentSection("Deposit");
                    }}
                    selected={currentSection == "Deposit" && true}
                  >
                    Deposit
                  </SingleFolderFourth>
                  <SingleFolderFourth
                    onClick={(e) => {
                      setCurrentSection("Profile");
                    }}
                    selected={currentSection == "Profile" && true}
                  >
                    Profile
                  </SingleFolderFourth>
                  <SingleFolderFourth
                    onClick={(e) => {
                      setCurrentSection("Vacation");
                    }}
                    selected={currentSection == "Vacation" && true}
                  >
                    Vacation
                  </SingleFolderFourth>
                  <SingleFolderFourth
                    onClick={(e) => {
                      setCurrentSection("Extend");
                    }}
                    selected={currentSection == "Extend" && true}
                  >
                    Extend
                  </SingleFolderFourth>
                </FoldersRow>
              </FoldersContainer>
              <br />
              <TallFiltersInnerCont>
                {currentSection == "Deposit" && (
                  <span>
                    <h3>Amount</h3>
                    <input
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    <br />
                    <SendAllButton
                      onClick={() => {
                        if(rewardTokenBalance != ""){
                          setAmount(rewardTokenBalance)
                        }
                      }}
                    >
                      Deposit All {rewardTokenBalance}
                    </SendAllButton>
                    <br/><br/>
                    <MainButton
                      onClick={() =>
                        depositTokens(
                          FarmName,
                          amount,
                          getPrecisionFromAsset(farmData.poolsize),
                          farmData.contract,
                          farmData.rewardtoken,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay
                        )
                      }
                    >
                      Deposit {amount} {farmData.rewardtoken}
                    </MainButton>
                  </span>
                )}

                {currentSection == "Profile" && (
                  <span>
                    <h3>Cover Image</h3>
                    <input
                      value={image1}
                      onChange={(e) => setImage1(e.target.value)}
                      placeholder="e.g. Qmabc123"
                    />
                    <br />
                    <br />
                    <h3>Description ({1000 - description.length}/1000)</h3>
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      maxLength={1000}
                      placeholder='"This is an NFT farm and you can stake stuff..."'
                    />
                    <br />
                    <br />
                    <h3>Telegram</h3>
                    <input
                      value={telegram}
                      onChange={(e) => setTelegram(e.target.value)}
                      placeholder="e.g. https://t.me/hoodpunks"
                    />
                    <br />
                    <br />
                    <h3>Discord</h3>
                    <input
                      value={discord}
                      onChange={(e) => setDiscord(e.target.value)}
                      placeholder="e.g. https://discord.gg/hi"
                    />
                    <br />
                    <br />
                    <h3>Twitter</h3>
                    <input
                      value={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                      placeholder="e.g. https://twitter.com/elonmusk"
                    />
                    <br />
                    <br />
                    <h3>Website</h3>
                    <input
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                      placeholder="e.g. https://mywebsite.com"
                    />
                    <br />
                    <br />
                    <h3>Latest Drop</h3>
                    <input
                      value={nftDrop}
                      onChange={(e) => setNftDrop(e.target.value)}
                      placeholder="e.g. https://waxdao.io/drops/247"
                    />
                    <br />
                    <br />
                    <MainButton
                      onClick={() =>
                        newProfile(
                          FarmName,
                          description,
                          telegram,
                          discord,
                          twitter,
                          website,
                          nftDrop,
                          image1,
                          "",
                          "",
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay
                        )
                      }
                    >
                      Save Profile
                    </MainButton>
                  </span>
                )}

                {currentSection == "Vacation" && (
                  <span>
                    {farmData.allow_new_members == 0 && (
                      <span>
                        <MainButton
                          onClick={() => {
                            setFarmStatus(
                              FarmName,
                              1,
                              setEnterModalText,
                              setLoadingDisplay,
                              setEnterButtonsDisplay,
                              setEnterModalDisplay
                            );
                          }}
                        >
                          Open Farm
                        </MainButton>
                      </span>
                    )}
                    {farmData.allow_new_members == 1 && (
                      <span>
                        <MainButton
                          onClick={() => {
                            setFarmStatus(
                              FarmName,
                              0,
                              setEnterModalText,
                              setLoadingDisplay,
                              setEnterButtonsDisplay,
                              setEnterModalDisplay
                            );
                          }}
                        >
                          Close Temporarily
                        </MainButton>
                        &nbsp;&nbsp;
                        <MainButton
                          onClick={() => {
                            setFarmStatus(
                              FarmName,
                              2,
                              setEnterModalText,
                              setLoadingDisplay,
                              setEnterButtonsDisplay,
                              setEnterModalDisplay
                            );
                          }}
                        >
                          Close Permanently
                        </MainButton>
                      </span>
                    )}
                    {farmData.allow_new_members == 2 && "Permanently Closed"}
                  </span>
                )}

                {currentSection == "Extend" && (
                  <span>
                    <h3>New Farm Expiration</h3>
                    <DatePicker
                      selected={new Date(startTime * 1000)}
                      onChange={(e) => {
                        handleCalendarChange(e, setStartTime);
                      }}
                    />
                    <br />
                    <br />
                    <MainButton
                      onClick={() =>
                        extendFarmNew(
                          FarmName,
                          startTime,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay
                        )
                      }
                    >
                      Extend Farm
                    </MainButton>
                  </span>
                )}
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Ban List</h2>
              <br />
              <FoldersContainer>
                <FoldersRow>
                  <SingleFolderFourth
                    onClick={(e) => {
                      setCurrentBanSection("Add");
                    }}
                    selected={currentBanSection == "Add" && true}
                  >
                    Add
                  </SingleFolderFourth>
                  <SingleFolderFourth
                    onClick={(e) => {
                      setCurrentBanSection("View");
                    }}
                    selected={currentBanSection == "View" && true}
                  >
                    View
                  </SingleFolderFourth>
                </FoldersRow>
              </FoldersContainer>
              <br />
              <TallFiltersInnerCont>
                {currentBanSection == "Add" && (
                  <span>
                    <h3>WAX Address</h3>
                    <input
                      maxLength={13}
                      placeholder="e.g. mike.wam"
                      value={userToBan}
                      onChange={(e) =>
                        setUserToBan(e.target.value.toLowerCase())
                      }
                    />
                    <br />
                    <br />
                    <MainButton
                      onClick={() =>
                        banUser(
                          FarmName,
                          userToBan,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterButtonsDisplay,
                          setEnterModalDisplay
                        )
                      }
                    >
                      Ban User
                    </MainButton>
                  </span>
                )}

                {currentBanSection == "View" &&
                  bannedUsers.map((item, index) => (
                    <StakersRow key={index}>
                      <StakeLeftRow style={{ color: theme.textSecondary }}>
                        {index + 1}.&nbsp;
                        <a href={`${currentWebsiteURL}/u/${item.username}`}>
                          {item.username}
                        </a>
                      </StakeLeftRow>
                      <StakeRightRow>
                        <SmallButton
                          onClick={() =>
                            unbanUser(
                              FarmName,
                              item.username,
                              setEnterModalText,
                              setLoadingDisplay,
                              setEnterButtonsDisplay,
                              setEnterModalDisplay
                            )
                          }
                        >
                          Unban
                        </SmallButton>
                      </StakeRightRow>
                    </StakersRow>
                  ))}

                {currentBanSection == "View" && bannedUsers.length == 0 && (
                  <span>You have not banned any users from this farm</span>
                )}
              </TallFiltersInnerCont>
            </TallFiltersCont>

            <TallFiltersCont>
              <h2>Stakers List&nbsp;&nbsp;<SmallButton
                onClick={() => 
                  createCSV(listOfStakers)
                }
              >Export CSV</SmallButton></h2>
              <br />
              <TallFiltersInnerCont>
                <ExploreFiltersScrollCont>
                  {listOfStakers.length > 0 &&
                    listOfStakers
                      .sort((a, b) => b.assets.length - a.assets.length)
                      .map((item, index) => (
                        <StakersRow
                          key={index}
                          className={item.assets.length == 0 && "hidden"}
                        >
                          <StakeLeftRow style={{ color: theme.textSecondary }}>
                            {index + 1}.&nbsp;
                            <a
                              href={`${currentWebsiteURL}/view-farm-nfts/${FarmName}+${item.username}`}
                            >
                              {item.username}
                            </a>
                            &nbsp;({item.assets.length})
                          </StakeLeftRow>
                          <StakeRightRow>
                            <SmallButton
                              onClick={() =>
                                kickUser(
                                  FarmName,
                                  item.username,
                                  setEnterModalText,
                                  setLoadingDisplay,
                                  setEnterButtonsDisplay,
                                  setEnterModalDisplay
                                )
                              }
                            >
                              Kick
                            </SmallButton>
                          </StakeRightRow>
                        </StakersRow>
                      ))}
                </ExploreFiltersScrollCont>
              </TallFiltersInnerCont>
            </TallFiltersCont>
          </NewListingBody>
        </UALProvider>
        <br />
        <br />
        <br />
      </div>
    );
  } else if (
    !farmIsLoading &&
    farmData?.creator != currentUsername &&
    farmWasLoaded
  ) {
    return <Error error="You are not the creator of this farm" />;
  } else if (farmIsLoading) {
    return <LoadingPage />;
  } else if (!farmIsLoading && !farmWasLoaded) {
    return <Error error="Error loading farm. Try refreshing the page." />;
  }
};

export default FarmOptions;
