import React from 'react'
import { currentWebsiteURL } from '../data/config';
import { resizerPrefix, resizerSuffix } from '../data/constants';
import { DropCardBottomHalf, DropCardImage, DropCardName, DropCardTitle, DropCardTopHalf, DropCardWrapper } from '../data/css/DropStyles'

const V2DAOCard = (props) => {
    const item = props.item;
    const profile = props.profile;
  return (
    <DropCardWrapper>
        <DropCardTopHalf>
            {profile?.profile.avatar != null ? (
                <DropCardImage src={`${resizerPrefix}${profile?.profile.avatar}${resizerSuffix}`} />
            ) : (
              <DropCardImage src={`https://avatars.dicebear.com/api/bottts/${item.daoname}@waxdao.png`} />
            )}
        </DropCardTopHalf>

      {item.creator != null && (
        <a href={`${currentWebsiteURL}/u/${item.creator}`}>
            <DropCardTitle>
            {item.creator}
            </DropCardTitle>
        </a>
      )}

        {item.daoname != null && (
            <DropCardName>
                {item.daoname}
            </DropCardName>
        )}

        <DropCardBottomHalf />

    </DropCardWrapper>
  )
}

export default V2DAOCard