import { localServerURL } from "./config";

//export const resizerPrefix = "https://api.waxdao.io/image/w_300,h_300/https://ipfs.io/ipfs/"
//export const resizerPrefix = localServerURL + "/image/w_300,h_300/https://ipfs.io/ipfs/"
export const resizerPrefix = "https://ipfs.waxdaobp.io/ipfs/"
export const ipfsPrefix = "https://ipfs.io/ipfs/"

export const resizerSuffix = ""

export const DROP_FILTERS = [
	{value: "Hide sold out", display_text: "Hide Sold Out"},
	{value: "Live drops only", display_text: "Live Only"}, 
];

export const FARM_STATUSES = [
	{value: 0, display_text: "Temporarily Closed"},
	{value: 1, display_text: "Open"}, 
	{value: 2, display_text: "Permanently Closed"}, 
];

export const ESCROW_STATUSES = [
	{value: 0, display_text: "Awaiting Deposit"},
	{value: 1, display_text: "Funded, In Progress"}, 
	{value: 2, display_text: "Finalized, Completed"}, 
	{value: 3, display_text: "Cancelled"}, 
];

export const LOCK_STATUSES = [
	{value: 0, display_text: "Awaiting Deposit"},
	{value: 1, display_text: "Funded, In Progress"}, 
	{value: 2, display_text: "Finalized, Completed"}, 
	{value: 3, display_text: "Cancelled"}, 
];

export const PROPOSAL_STATUSES = [
	{value: 0, display_text: "Pending"},
	{value: 1, display_text: "Failed, Denied"}, 
	{value: 2, display_text: "Failed, Overdrawn Treasury"}, 
	{value: 3, display_text: "Approved"},
	{value: 4, display_text: "Inconclusive"}, 
];

export const DAO_TYPES = [
	{value: 1, display_text: "Custodial NFT Farm"},
	{value: 2, display_text: "farms.waxdao Non-Custodial NFT Farm"}, 
	{value: 3, display_text: "WaxDAO Token Staking Pool"}, 
	{value: 4, display_text: "Stake Tokens Directly To The DAO"},
	{value: 5, display_text: "Non-Custodial NFT (NOT linked to an NFT farm)"}, 
];

export const V2_PROPOSAL_TYPES = [
	{value: 0, display_text: "Standard"},
	{value: 1, display_text: "Ranked Choice"},
	{value: 2, display_text: "Token Transfer"},
	{value: 3, display_text: "NFT Transfer"},
	{value: 4, display_text: "Yes/No/Abstain"},
];

export const V2_PROPOSAL_STATUSES = [
	{value: 0, display_text: "In Progress"},
	{value: 1, display_text: "Finalized"}, 
	{value: 2, display_text: "Approved"}, 
	{value: 3, display_text: "Rejected"},
	{value: 4, display_text: "Inconclusive"}, 
	{value: 5, display_text: "Not Enough Votes"}, 
	{value: 4, display_text: "Insufficient Funds"}
];

export const V2_FARM_STATUSES = [
	{value: 0, display_text: "Under Construction"},
	{value: 1, display_text: "Open"}
];

export const waxapesExplainer = "With the successful implementation of an automated contract, a streamlined system has been put in place to ensure the efficient collection of royalty fees from the secondary market. These royalties will be automatically transferred to the treasury on a weekly basis, maintaining a consistent frequency of 168 hours. Upon arrival at the treasury, the funds will be promptly distributed by the WAXDAO farm to all ape holders. This distribution mechanism ensures that each Ape receives an equal share of the funds, promoting fairness and transparency within the DAO. It is important to note that immediate distribution of the funds will not occur right after depositing. Instead, a running script will be in place to regularly check the treasury every hour, identifying any available funds. This process may result in a slight delay of up to an hour or two before the distribution takes place. Once this verification process is completed, ape holders will have the ability to view their individual balances on WAXDAO. Their portion of the funds will be tied to their account address, ensuring accurate allocation. Even if holders choose not to claim their funds immediately, the funds will remain accessible for future withdrawal whenever they wish to do so. It is worth emphasizing that the payout will not be lost as long as the holders retain ownership of the corresponding NFT(s) at the time when the farm processes the distribution. This means that even if someone is no longer the holder of an ape, they will still have the opportunity to claim all processed deposits from the period during which they held the Ape, as long as they were in possession of it at the time of the farm's processing."

