import React from 'react'
import { currentWebsiteURL } from '../data/config';
import { AttributeModalInnerWrapper, AttributeModalWrapper, AttrModalKey, AttrModalName, AttrModalRow } from '../data/css/PremiintStyles';

import { GameButton, Modal, ModalContent } from './LeftDiv'

const AttributeModal = (props) => {
    const showAttributeModal = props.showAttributeModal;
    const setShowAttributeModal = props.setShowAttributeModal;
    const item = props.item;
  return (
    <AttributeModalWrapper className={showAttributeModal}>
        <AttributeModalInnerWrapper>
            <ModalContent>
            {item != null && (
                <span>
                    {item.data != null && (
                        <span>
                            <AttrModalName>
                                {item.data.name != null && item.data.name}
                            </AttrModalName>
                            <br/>
                            <AttrModalRow>
                                <AttrModalKey>
                                Asset ID:
                                </AttrModalKey>
                                {item.asset_id}
                            </AttrModalRow>

                            <AttrModalRow>
                                <AttrModalKey>
                                Mint #:
                                </AttrModalKey>
                                {item.template_mint != null && item.template_mint}
                            </AttrModalRow>

                            <AttrModalRow>
                                <AttrModalKey>
                                Transferable:
                                </AttrModalKey>
                                {item.is_transferable != null && item.is_transferable == 0 ? "No" : "Yes"}
                            </AttrModalRow>

                            <AttrModalRow>
                                <AttrModalKey>
                                Burnable:
                                </AttrModalKey>
                                {item.is_burnable != null && item.is_burnable == 0 ? "No" : "Yes"}
                            </AttrModalRow>
                            <br/>
                            <AttrModalRow>
                                <AttrModalKey>
                                Collection:
                                </AttrModalKey>
                                <a href={`${currentWebsiteURL}/collection/${item.collection?.collection_name}`}>
                                    {item.collection?.collection_name}
                                </a>
                            </AttrModalRow>

                            <AttrModalRow>
                                <AttrModalKey>
                                Schema:
                                </AttrModalKey>
                                {item.schema?.schema_name}
                            </AttrModalRow>

                            <AttrModalRow>
                                <AttrModalKey>
                                Template:
                                </AttrModalKey>
                                {item.template?.template_id}
                            </AttrModalRow>                        
                            
                            <br/>
                            <AttrModalName>
                                Template Immutable Data
                            </AttrModalName>

                            {Object.entries(item.template.immutable_data).length > 0 ? Object.entries(item.template.immutable_data).map(([attr, index]) => 
                                <AttrModalRow key={index}>
                                    <AttrModalKey>
                                    {attr}:
                                    </AttrModalKey>
                                    {index}
                                </AttrModalRow>   
                            ) : (<AttrModalRow>No data</AttrModalRow>)}
                            
                            <br/>
                            <AttrModalName>
                                Asset Immutable Data
                            </AttrModalName>
                            {Object.entries(item.immutable_data).length > 0 ? Object.entries(item.immutable_data).map(([attr, index]) => 
                                <AttrModalRow key={index}>
                                    <AttrModalKey>
                                    {attr}:
                                    </AttrModalKey>
                                    {index}
                                </AttrModalRow>   
                            ) : (<AttrModalRow>No data</AttrModalRow>)}
                            
                            <br/>
                            <AttrModalName>
                                Asset Mutable Data
                            </AttrModalName>
                            {Object.entries(item.mutable_data).length > 0 ? Object.entries(item.mutable_data).map(([attr, index]) => 
                                <AttrModalRow key={index}>
                                    <AttrModalKey>
                                    {attr}:
                                    </AttrModalKey>
                                    {index}
                                </AttrModalRow>   
                            ) : (<AttrModalRow>No data</AttrModalRow>)}<br/>

                        </span>
                    )}

                </span>
            )}
            
        </ModalContent>
      </AttributeModalInnerWrapper>
      <br/>
      <GameButton onClick={() => setShowAttributeModal(false) }>
        Close Window
      </GameButton>
      </AttributeModalWrapper>
  )
}

export default AttributeModal