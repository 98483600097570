import styled from 'styled-components'
import { currentTheme, testTheme, theme } from '../../Styles'

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
</style>

export const WalletFarmNameLink = styled.div`
    width: 20%;
    font-size: 14px;
    padding-top: 0.5em;
    font-family: Roboto;
    font-weight: 900;

    @media (max-width: 700px) {
        width: 100%;
        padding-bottom: 1em;
        
    }

    :hover{
        color: #c30000;
    }
`

export const WalletCardVideo = styled.video`

    width: auto;
    max-width: 180px;
    height: 180px;
    max-height: 180px;
    border-radius: 5%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;

    @media (max-width: 900px) {
       // width: auto;
       // max-width: 100px;
       // padding: 10px;
       // height: 100px;
       // max-height: 100px;
        
    }
`

export const WalletPremintedPoolLink = styled.div`

    width: 33%;
    font-size: 14px;
    padding-top: 0.5em;
    font-family: Roboto;
        font-weight: 900;

    @media (max-width: 700px) {
        width: 100%;
        padding-bottom: 1em;
    }

    :hover{
        color: #c30000;
    }

`

export const NFTCallToActionDisabled = styled.button`
    width: 100%;
    height: 30px;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    font-weight: bold;
    color: #bcbcbc;
    background-color: transparent;
    margin-top: 1em;
    border: 1px solid #bcbcbc;
`


export const WalletCallToActionDisabled = styled.button`
    width: 80%;
    padding: 0.5em;
    height: auto;
    border-radius: 5px;
    font-size: 12px;
    font-family: Roboto;
    color: #bcbcbc;
    border-color: #bcbcbc;
    background-color: transparent;
    border: 1px solid #bcbcbc;
    margin-top: 1em;
`


export const ClaimAllButton = styled.button`
    width: 80%;
    padding: 0.5em;
    height: auto;
    border-radius: 5px;
    font-size: 14px;
    color: ${currentTheme.textMain};
    background-color: ${currentTheme.secondary};
    font-weight: 500;

    :hover{
        background-color: ${currentTheme.secondaryHover};
        transition: background-color 1s;
    }
`


export const WalletCardWrapper = styled.div`
    display: block;
    position: relative;
    width: 220px;
    height: 400px;
    background-color: ${theme.mainDarker};
    border: 1px solid transparent;
    border-radius: 15px;

    :hover{
        background-color: #dfdcdc40;
        transition: background-color 0.5s;
    }
`

export const WalletCardName = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 14px;
    font-weight: 500;
    top: 270px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${testTheme.textSecondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const WalletCardMint = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 10px;
    font-weight: 200;
    top: 20px;
    right: 20px;
    padding:3px 10px 3px 10px;
    border-radius: 10px;
    color: ${testTheme.textMain};
    background-color: #171616a9;
`

export const WalletCardIcons = styled.div`
    position: absolute;
    z-index: 200;
    font-size: 20px;
    font-weight: 500;
    top: 350px;
    width:85%;
    text-align: left;
    padding-left: 20px;
    color: ${theme.secondary};

    --max-lines: 1;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
`

export const WalletCardSingleIconWrapper = styled.span`
    margin-right: 10px;
    :hover{
        cursor: pointer;
    }
`

export const WalletNFTCardsCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 15px;
    column-gap: 10px;
    width:100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;

`

export const FullWidthContainer = styled.div`
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    color: lightgray;
    font-size: 14px;
    text-align: center;

    h2{
        font-size: 20px;
        font-weight: 900;
    }

    h3{
        font-size: 16px;
        font-weight: 900;
    }
`

export const SelectPoolDropDown = styled.div`
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: 200px;
    background-color: ${theme.mainDarker};
    height: 45px;
    font-size: 12px;
    border-radius: 10px;
    border: 1px solid ${theme.secondary};
    :hover{
        cursor: pointer;
    }
`

export const SelectTokenDropDown = styled.div`
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    width: 200px;
    background-color: ${theme.mainDarker};
    height: 45px;
    font-size: 12px;
    border-radius: 10px;
    :hover{
        cursor: pointer;
    }
`

export const SelectTokenTextWrapper = styled.span`
    margin-top: auto;
    margin-bottom: auto;
    line-height: 45px;
`

export const AllTokensWrapper = styled.div`
    position: absolute;
    top: 45px;
    height: 200px;
    width: 100%;
    border: 1px solid ${theme.textSecondary};
    background-color: ${theme.mainDarker};
    overflow-y: scroll;
    z-index: 1500;
    border-radius: 10px;
`

export const SingleTokenButton = styled.button`
    width: 100%;
    border-bottom: 1px solid #6b6a81a9;
    background-color: ${theme.mainDarker};
    font-size: 12px;
    padding: 10px;
`

export const SendAllButton = styled.button`
    color: ${theme.secondary};
    font-size: 12px;
`

export const TokenCard = styled.div`
    position: relative;
    background-color: ${theme.mainDarker};
    width: 100%;
    padding: 15px;
    padding-left: 15px;
    border-radius: 10px;
    margin-top: 25px;
    border: 1px solid ${theme.secondaryHover};
    height: ${props => props.selected == true ? "auto" : "80px" };
    transition: height 0.5s;

    img{
        display: inline-block;
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }
`

export const TokenDetailsButton = styled.button`
    position: absolute;
    width: 100px;
    font-size: 14px;
    color: ${theme.secondary};
    border: 1px solid ${theme.secondary};
    border-radius: 8px;
    right: 15px;
    top: 30px;

    :hover{
        color: ${theme.secondaryHover};
        border: 1px solid ${theme.secondaryHover};
    }
`

export const ClaimInfo = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 90%;
    font-size: 14px;
    color: ${theme.textSecondary};
    right: 15px;
    top: 0px;
    text-align: right;
 
`

export const EditBlendButtonCont = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 90%;
    font-size: 14px;
    color: ${theme.textSecondary};
    right: 5px;
    top: 0px;
    text-align: right;
 
`

export const TokenDetailsContainer = styled.div`
    width: 100%;
    min-height: 100px;
    height: auto;
    padding-top: 30px;

    input{
        border-radius: 10px;
        background-color: ${theme.main};
        border: 1px solid ${theme.textSecondary};
        padding-left: 0.5em;
        max-width: 90%;
        height: 45px;

        :focus{
            outline:none;
        }
    }

    h3{
        font-size: 14px;
        font-weight: 900;
        //padding-left: 20px;
        margin-bottom: 5px;
    }

`

export const StakedTokenCard = styled.div`
    position: relative;
    background-color: ${theme.mainDarker};
    width: 100%;
    padding: 15px;
    padding-left: 15px;
    border-radius: 10px;
    margin-top: 25px;
    border: 1px solid ${theme.secondaryHover};
    height: ${props => props.selected == true ? "auto" : "80px" };
    transition: height 0.5s;

    img{
        display: inline-block;
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    a{
        color: ${theme.secondary};
    }
`

export const BlenderBalanceCard = styled.div`
    position: relative;
    background-color: ${theme.mainDarker};
    width: 100%;
    padding: 15px;
    padding-left: 15px;
    border-radius: 10px;
    margin-top: 25px;
    border: 1px solid ${theme.secondaryHover};
    height: ${props => props.selected == true ? "auto" : "80px" };
    transition: height 0.5s;

    img{
        display: inline-block;
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    a{
        color: ${theme.secondary};
    }
`