import NumberFormat from "react-number-format";
import { getWalletSession } from "./wallet_functions";



export const stakeTokens = async (stakingAmount, tokenContract, tokenToStake, PoolName, precision, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];
  
    try {
      
      const action = [{
        account: "waxdaofarmer",
        name: 'staketokens',
        authorization: [{
          actor: this_user,
          permission: this_permission,
      }],
      data: {
          user: this_user,
          poolname: PoolName,
  
      }
    },{
            account: tokenContract,
            name: 'transfer',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              to: 'waxdaofarmer',
              from: this_user,
              quantity: Number(stakingAmount).toFixed(Number(precision)) + ' ' + tokenToStake,
              memo: '|stake_tokens|' + PoolName + '|',
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your tokens have been staked');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
    
  
  } // end staketokens
  


export const unstakeTokens = async (stakingAmount, tokenToStake, PoolName, precision, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];
  
  
    const stakingString = Number(stakingAmount).toFixed(Number(precision));

    try {

      const action = [{
            account: 'waxdaofarmer',
            name: 'unstaketoken',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              user: this_user,
              amount: Number(stakingAmount).toFixed(Number(precision)) + ' ' + tokenToStake,
              poolname: PoolName,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your tokens have been unstaked');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
  } // end unstaketokens


  export const unstakeAllTokens = async (PoolName, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
  
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];

    try {
      // Reset our response state to clear any previous transaction data
      
      const action = [{
            account: 'waxdaofarmer',
            name: 'unstakalltkn',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              user: this_user,
              poolname: PoolName,
          }
        }]
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction({
        actions: action
      }, {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      // Update application state with the responses of the transaction
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your tokens have been unstaked');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
  } // end unstakealltokens

  

export const claimRewards = async (PoolName, contractToOpenRow, rwdtoken, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];

    try {
      
      const action = [{
            account: contractToOpenRow,
            name: 'open',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
            owner: this_user,
            symbol: rwdtoken,
            ram_payer: this_user,
          }
      },
        {
            account: 'waxdaofarmer',
            name: 'claimpoolrwd',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              user: this_user,
              pool: PoolName,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your rewards have been sent');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  
  }


export const openRow = async (rwdToken, contract, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {

    setEnterModalDisplay('');
    setEnterModalText('Awaiting confirmation...');
    var walletSession = await getWalletSession(setEnterModalText);
    const session = walletSession[0];
    var this_user = walletSession[1];
    var this_permission = walletSession[2];

    try {
      const action = [{
            account: contract,
            name: 'open',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
              owner: this_user,
              symbol: rwdToken,
              ram_payer: this_user,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
  
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Processing your transaction...')
      const timer = setTimeout(() => {
        
          setEnterModalText('You can now receive ' + rwdToken.substr(rwdToken.indexOf(',') + 1) + ' tokens');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }
  } // end openrow

export const getTokenToStake = (pool) => {
  let tokenToStake = pool.tokentostake.substring(pool.tokentostake.indexOf(",") + 1)

  return tokenToStake;
}

export const getRewardToken = (pool) => {
  let rewardToken = pool.rwdtoken.substring(pool.rwdtoken.indexOf(",") + 1)

  return rewardToken;
}

export const getPrecisionFromSymbol = (symbol) => {
  let precision = symbol.substring(0, symbol.indexOf(","))

  return precision;
}

export const getPrecisionFromAsset = (asset) => {
  let precision = 0;
  if(asset.indexOf(".") > -1){
    precision = asset.substring(asset.indexOf(".") + 1, asset.indexOf(" ")).length
  }
  
  return precision;
}

export const getQuantityFromAsset = (asset) => {
  let quantity = asset.substring(0, asset.indexOf(" "))
 
  return quantity;
}

export const getTokenNameFromAsset = (asset) => {
  let token = asset.substring(asset.indexOf(" ") + 1)
 
  return token;
}

export const getTokenNameFromSymbol = (symbol) => {
  let precision = symbol.substring(symbol.indexOf(",") + 1)

  return precision;
}

export const getTokenPrecision = (pool) => {
  let precision = pool.tokentostake.substring(0, pool.tokentostake.indexOf(","))

  return precision;
}

export const getHourlyReward = (pool) => {
  let amount = Number(pool.hourlyreward.substring(0, pool.hourlyreward.indexOf(" "))).toFixed(2)
  let symbol = pool.hourlyreward.substring(pool.hourlyreward.indexOf(" ") + 1)
  return (<span><NumberFormat displayType='text' thousandSeparator={true} value={amount} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />{" " + symbol}</span>);
}

export const getPoolSize = (pool) => {
  let amount = Number(pool.poolsize.substring(0, pool.poolsize.indexOf(" "))).toFixed(0)
  let symbol = pool.poolsize.substring(pool.poolsize.indexOf(" ") + 1)
  return (<span><NumberFormat displayType='text' thousandSeparator={true} value={amount} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />{" " + symbol}</span>);
}

export const getTotalStaked = (pool) => {
  let amount = Number(pool.totalstaked.substring(0, pool.totalstaked.indexOf(" "))).toFixed(0)
  let symbol = pool.totalstaked.substring(pool.totalstaked.indexOf(" ") + 1)
  return (<span><NumberFormat displayType='text' thousandSeparator={true} value={amount} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />{" " + symbol}</span>);
}

export const roundWalletBalance = (balance) => {
  let amount = Number(balance.substring(0, balance.indexOf(" "))).toFixed(2)
  let symbol = balance.substring(balance.indexOf(" ") + 1)
  return (<span><NumberFormat displayType='text' thousandSeparator={true} value={amount} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} />{" " + symbol}</span>);
}

export const getStakeAllAmount = (balance) => {
  let amount = Number(balance.substring(0, balance.indexOf(" ")))
  return (<span><NumberFormat displayType='text' thousandSeparator={true} value={amount} style={{backgroundColor:'transparent', textAlign:'center', width:'100%', maxWidth:'100%'}} /></span>);
}

export const getStakeAllAmountUnformatted = (balance) => {
  let amount = Number(balance.substring(0, balance.indexOf(" ")))
  return amount
}


export const changeLogo = async (poolname, logoHash, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];
  
    try {

      const action = [{
            account: 'waxdaofarmer',
            name: 'setpoollogo',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
            poolname: poolname,
            ipfs_hash: logoHash,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Changing your logo...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Your logo has been updated. It may take a minute to reflect the change');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }

}; //end change logo 


export const setPoolDays = async (poolname, minimumDays, setEnterModalText, setLoadingDisplay, setEnterButtonsDisplay, setEnterModalDisplay) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];
  
    try {

      const action = [{
            account: 'waxdaofarmer',
            name: 'setpooldays',
            authorization: [{
              actor: this_user,
              permission: this_permission,
          }],
          data: {
            poolname: poolname,
            minimum_stake_DAYS: minimumDays,
          }
        }]
      const response = await session[0].signTransaction({
        actions: action
      }, {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      })
      setLoadingDisplay('');
      setEnterButtonsDisplay('hidden');
      setEnterModalText('Submitting your changes...')
      const timer = setTimeout(() => {
        
          setEnterModalText('Minimum staking time has been updated');
          setLoadingDisplay('none');
          setEnterButtonsDisplay('');
    
      }, 4000);
      return () => clearTimeout(timer);
  
    } catch(e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e)
      
    }

}; //end change logo 


export const depositTokens = async (
  poolname,
  amountToAdd,
  contract,
  rewardtoken,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let precision = getPrecisionFromSymbol(rewardtoken);
  let tokenName = getTokenNameFromSymbol(rewardtoken);

    try {
      const action = [
        {
          account: contract,
          name: "transfer",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            to: "waxdaofarmer",
            from: this_user,
            quantity: Number(amountToAdd).toFixed(precision) + " " + tokenName,
            memo: "|pool_deposit|" + poolname + "|",
          },
        },
      ];

      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 600,
          broadcast: true,
        }
      );

      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Adding your funds...");
      const timer = setTimeout(() => {
        setEnterModalText("Your tokens have been added to the reward pool");
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 4000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e);
    }

};


export const extendPool = async (
  PoolName,
  daysToExtend,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay('');
  setEnterModalText('Awaiting confirmation...');
  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

    try {

      const action = [
        {
          account: "waxdaofarmer",
          name: "extendpool",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            poolname: PoolName,
            days_to_add: daysToExtend,
          },
        },
      ];

      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 600,
          broadcast: true,
        }
      );

      setLoadingDisplay("");
      setEnterButtonsDisplay("hidden");
      setEnterModalText("Entending your pool...");
      const timer = setTimeout(() => {
        setEnterModalText(
          "Your pool has been extended by " + daysToExtend + " days"
        );
        setLoadingDisplay("none");
        setEnterButtonsDisplay("");
      }, 4000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e);
    }
    
};
