import {
  SmallButton,
  TallFiltersCont,
  TallFiltersInnerCont,
} from "../../Styles";
import {
  wax,
  anchor,
  myChain,
  currentApiList,
  currentAtomicApiList,
} from "../config";
import { checkWallet, getWalletSession } from "./wallet_functions";

export const setEpochTime = (e, setTimestamp) => {
  let date = new Date(e.target.value);
  var epochTime = date.getTime() / 1000;
  setTimestamp(epochTime);
};

export const handleRemoveAsset = (asset_id, assetVector, setAssetVector) => {
  const assetList = [...assetVector];
  assetList.splice(assetList.indexOf(asset_id), 1);
  setAssetVector(assetList);
  console.log("handled removing it");
};

export const handleAddAsset = (asset_id, assetVector, setAssetVector) => {
  setAssetVector([...assetVector, asset_id]);
  console.log("handled adding it");
};

export const claimRewards = async (
  FarmName,
  rewardSymbol,
  rewardContract,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: rewardContract,
        name: 'open',
        authorization: [{
          actor: this_user,
          permission: this_permission,
      }],
      data: {
        owner: this_user,
        symbol: rewardSymbol,
        ram_payer: this_user,
      }
  },{
        account: "waxdaofarmer",
        name: "claimrewards",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farm: FarmName,
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your rewards have been sent");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 2000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
};

export const setFarmStatus = async (
  FarmName,
  newStatus,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  var action_name;

  if (newStatus == 0) {
    action_name = "tmpclosefarm";
  } else if (newStatus == 1) {
    action_name = "openfarm";
  } else if (newStatus == 2) {
    action_name = "deletefarm";
  } else {
    setEnterModalText("Invalid farm status");
    return;
  }

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: "waxdaofarmer",
        name: action_name,
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farmname: FarmName,
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your farm status has been changed");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
};

export const openRow = async (
  decimals,
  tokenSymbol,
  contract,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: contract,
        name: "open",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          owner: this_user,
          symbol: decimals + tokenSymbol,
          ram_payer: this_user,
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("You can now receive " + tokenSymbol + " tokens");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
};

export const unstake = async (
  FarmName,
  asset_id,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: "waxdaofarmer",
        name: "unstake",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          asset_id: asset_id,
          farmname: FarmName,
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your NFT has been unstaked");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
};

export const unstakeMany = async (
  FarmName,
  asset_ids,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    const action = [
      {
        account: "waxdaofarmer",
        name: "unstakemany",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          asset_ids: asset_ids,
          farmname: FarmName,
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your NFT has been unstaked");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
};

export const stakeMany = async (
  FarmName,
  assetVector,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    const action = [
      {
        account: "waxdaofarmer",
        name: "assertstake",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farmname: FarmName,
          assets_to_assert: assetVector,
        },
      },
      {
        account: "atomicassets",
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxdaofarmer",
          from: this_user,
          asset_ids: assetVector,
          memo: "|stake|" + FarmName + "|",
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your NFTs have been staked");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; //end stakeMany


export const stakeNonCustodial = async (
  FarmName,
  assetVector,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  let contract_account = "waxdaofarmer"
  let action_name = "stakenoncust"

  try {
    const action = [
      {
        account: contract_account,
        name: action_name,
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farmname: FarmName,
          assets_to_stake: assetVector,
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your NFTs have been staked");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 2000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; //end stakeNonCustodial

export const unstakeNonCustodial = async (
  FarmName,
  assetVector,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  let contract_account = "waxdaofarmer"
  let action_name = "unstknoncust"

  try {
    const action = [
      {
        account: contract_account,
        name: action_name,
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farmname: FarmName,
          assets_to_unstake: assetVector,
        },
      },
    ];
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your NFTs have been unstaked");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 2000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
}; //end unstakeNonCustodial

export const stake = async (
  FarmName,
  asset_id,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: "waxdaofarmer",
        name: "assertstake",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farmname: FarmName,
          assets_to_assert: [asset_id],
        },
      },
      {
        account: "atomicassets",
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxdaofarmer",
          from: this_user,
          asset_ids: [asset_id],
          memo: "|stake|" + FarmName + "|",
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your NFT has been staked");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
};

export const createFarm = async (
  paymentMethod,
  farmName,
  farmLogo,
  collection,
  schema,
  rewardToken,
  rewardContract,
  decimals,
  timestamp,
  assetToBurn,
  stakingSeconds,
  farmType,
  farmTypeValue,
  templates,
  rewardTypeValue,
  rewardAmount,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  let walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  let this_user = walletSession[1];
  let this_permission = walletSession[2];

  let reward_type = rewardTypeValue;
  if(farmType == "Version 2" && farmTypeValue == "template"){
    reward_type = "dynamic";
  }

  if (paymentMethod == "Pay 250 Wax") {
    try {
      // Reset our response state to clear any previous transaction data

      const action = [
        {
          account: "eosio.token",
          name: "transfer",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            to: "waxdaofarmer",
            from: this_user,
            quantity: "250.00000000 WAX",
            memo:
              "|createfarm|" +
              farmName +
              "|" +
              rewardToken +
              "|" +
              decimals +
              "|" +
              rewardContract +
              "|" +
              collection +
              "|" +
              schema +
              "|" +
              farmLogo +
              "|" +
              timestamp +
              "|" +
              stakingSeconds +
              "|" +
              farmTypeValue +
              "|" +
              templates +
              "|" +
              reward_type +
              "|" +
              rewardAmount +
              "|",
          },
        },
      ];
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          //
          blocksBehind: 3,
          expireSeconds: 180,
          broadcast: true,
        }
      );
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(
          "Your farm has been created. You can manage it in the wallet section."
        );
        setLoadingDisplay("none");
      }, 4000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));

      console.log(e);
    }
  } //end if paymentMethod == WAX
  else if (paymentMethod == "Pay 25,000 WOJAK") {
    try {
      // Reset our response state to clear any previous transaction data

      const action = [
        {
          account: "mdcryptonfts",
          name: "transfer",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            to: "waxdaofarmer",
            from: this_user,
            quantity: "25000 WOJAK",
            memo:
              "|createfarm|" +
              farmName +
              "|" +
              rewardToken +
              "|" +
              decimals +
              "|" +
              rewardContract +
              "|" +
              collection +
              "|" +
              schema +
              "|" +
              farmLogo +
              "|" +
              timestamp +
              "|" +
              stakingSeconds +
              "|" +
              farmTypeValue +
              "|" +
              templates +
              "|" +
              reward_type +
              "|" +
              rewardAmount +
              "|",
          },
        },
      ];
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 180,
          broadcast: true,
        }
      );
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(
          "Your farm has been created. You can manage it in the wallet section."
        );
        setLoadingDisplay("none");
      }, 4000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));

      console.log(e);
    }
  } //end if paymentMethod == WOJAK
  else if (paymentMethod == "Burn 1 Wojak NFT") {
    try {
      // Reset our response state to clear any previous transaction data

      const action = [
        {
          account: "atomicassets",
          name: "transfer",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            asset_ids: [assetToBurn],
            from: this_user,
            to: "waxdaofarmer",
            memo:
              "|createfarm|" +
              farmName +
              "|" +
              rewardToken +
              "|" +
              decimals +
              "|" +
              rewardContract +
              "|" +
              collection +
              "|" +
              schema +
              "|" +
              farmLogo +
              "|" +
              timestamp +
              "|" +
              stakingSeconds +
              "|" +
              farmTypeValue +
              "|" +
              templates +
              "|" +
              reward_type +
              "|" +
              rewardAmount +
              "|",
          },
        },
      ];
      // Call transact on the session (compatible with eosjs.transact)
      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 180,
          broadcast: true,
        }
      );
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(
          "Your farm has been created. You can manage it in the wallet section."
        );
        setLoadingDisplay("none");
      }, 4000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));

      console.log(e);
    }
  } //end if paymentMethod == burn NFT
  else if (paymentMethod == "Use 1 WaxDAO Credit") {
    try {
      const action = [
        {
          account: "waxdaofarmer",
          name: "createfarm",
          authorization: [
            {
              actor: this_user,
              permission: this_permission,
            },
          ],
          data: {
            user: this_user,
            farmname: farmName,
            rewardtoken: rewardToken,
            precision: decimals,
            rewardcontract: rewardContract,
            collectionToStake: collection,
            schemaToStake: schema,
            ipfs: farmLogo,
            endtime: timestamp,
            mintime: stakingSeconds,
            farmtype: farmTypeValue,
            template_csv: templates,
            reward_type: reward_type,
            decimal_hourly_cap: rewardAmount,
          },
        },
      ];
      const response = await session[0].signTransaction(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 180,
          broadcast: true,
        }
      );
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(
          "Your farm has been created. You can manage it in the wallet section."
        );
        setLoadingDisplay("none");
      }, 4000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));

      console.log(e);
    }
  } //end if paymentMethod == 1 WaxDAO Credit
};

export const createNonCustodialFarm = async (
  paymentMethod,
  farm_config,
  farmName,
  farmLogo,
  rewardToken,
  rewardContract,
  decimals,
  timestamp,
  assetToBurn,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  const walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  const this_user = walletSession[1];
  const this_permission = walletSession[2];

  const farmConfigMap = {
    "Full Collection": { reward_type: "sharedpool", farm_type: "nc.c.shared" },
    "Dynamic": { reward_type: "dynamic", farm_type: "nc.v2" },
  };

  if (!farmConfigMap[farm_config]) {
    setEnterModalText("Invalid farm config");
    return;
  }

  const { reward_type, farm_type } = farmConfigMap[farm_config];

  const buildMemo = () =>
    "|createfarm|" +
    [farmName, rewardToken, decimals, rewardContract, "", "", farmLogo, timestamp, 0, farm_type, "", reward_type, 0].join("|") +
    "|";

  const buildAction = (account, name, data) => [
    {
      account,
      name,
      authorization: [{ actor: this_user, permission: this_permission }],
      data: { from: this_user, ...data },
    },
  ];

  const paymentMethodMap = {
    "Pay 250 Wax": {
      account: "eosio.token",
      name: "transfer",
      data: {
        to: "waxdaofarmer",
        quantity: "250.00000000 WAX",
        memo: buildMemo(),
      },
    },
    "Pay 25,000 WOJAK": {
      account: "mdcryptonfts",
      name: "transfer",
      data: {
        to: "waxdaofarmer",
        quantity: "25000 WOJAK",
        memo: buildMemo(),
      },
    },
    "Burn 1 Wojak NFT": {
      account: "atomicassets",
      name: "transfer",
      data: {
        asset_ids: [assetToBurn],
        to: "waxdaofarmer",
        memo: buildMemo(),
      },
    },
    "Use 1 WaxDAO Credit": {
      account: "waxdaofarmer",
      name: "createfarm",
      data: {
        user: this_user,
        farmname: farmName,
        rewardtoken: rewardToken,
        precision: decimals,
        rewardcontract: rewardContract,
        collectionToStake: "",
        schemaToStake: "",
        ipfs: farmLogo,
        endtime: timestamp,
        mintime: 0,
        farmtype: farm_type,
        template_csv: "",
        reward_type: reward_type,
        decimal_hourly_cap: 0,
      },
    },
  };

  if (!paymentMethodMap[paymentMethod]) {
    setEnterModalText("Invalid payment method");
    return;
  }

  const transactionOptions = {
    blocksBehind: 3,
    expireSeconds: 600,
    broadcast: true,
  };

  const handleTransaction = async (actions) => {
    try {
      await session[0].signTransaction({ actions }, transactionOptions);
      setLoadingDisplay("");
      setEnterModalText("Processing your transaction...");
      const timer = setTimeout(() => {
        setEnterModalText(
          "Your farm has been created. You can manage it in the Inventory section."
        );
        setLoadingDisplay("none");
      }, 2000);
      return () => clearTimeout(timer);
    } catch (e) {
      setEnterModalText(JSON.stringify(e.message));
      console.log(e);
    }
  };

  const actions = buildAction(
    paymentMethodMap[paymentMethod].account,
    paymentMethodMap[paymentMethod].name,
    paymentMethodMap[paymentMethod].data
  );

  await handleTransaction(actions);
};



export const buyAWojak = async (
  quantity,
  setEnterModalDisplay,
  setEnterModalText,
  setLoadingDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  const amountToSend = quantity * 109 + ".00000000 WAX";

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: "eosio.token",
        name: "transfer",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          to: "waxywojaknft",
          from: this_user,
          quantity: amountToSend,
          memo: "purchase",
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 180,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Purchase successful. Unbox here: waxdao.io/unbox/246");
      setLoadingDisplay("none");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
};

export const unstakeAll = async (
  FarmName,
  setEnterModalText,
  setLoadingDisplay,
  setEnterButtonsDisplay,
  setEnterModalDisplay
) => {
  setEnterModalDisplay("");
  setEnterModalText("Awaiting confirmation...");

  var walletSession = await getWalletSession(setEnterModalText);
  const session = walletSession[0];
  var this_user = walletSession[1];
  var this_permission = walletSession[2];

  try {
    // Reset our response state to clear any previous transaction data

    const action = [
      {
        account: "waxdaofarmer",
        name: "unstakeall",
        authorization: [
          {
            actor: this_user,
            permission: this_permission,
          },
        ],
        data: {
          user: this_user,
          farmname: FarmName,
        },
      },
    ];
    // Call transact on the session (compatible with eosjs.transact)
    const response = await session[0].signTransaction(
      {
        actions: action,
      },
      {
        blocksBehind: 3,
        expireSeconds: 600,
        broadcast: true,
      }
    );
    // Update application state with the responses of the transaction
    setLoadingDisplay("");
    setEnterButtonsDisplay("hidden");
    setEnterModalText("Processing your transaction...");
    const timer = setTimeout(() => {
      setEnterModalText("Your NFTs have been unstaked");
      setLoadingDisplay("none");
      setEnterButtonsDisplay("");
    }, 4000);
    return () => clearTimeout(timer);
  } catch (e) {
    setEnterModalText(JSON.stringify(e.message));

    console.log(e);
  }
};

export const isAnOfficialFarm = (farmData) => {
  if ( (farmData.reward_type == "dynamic" || (farmData.farmtype == "nc.c.shared" && farmData.reward_type == "sharedpool") ) ) {
    let i = 0;
    while (i < farmData.collections.length) {
      if (farmData.collections[i].is_authorized == 0) {
        return false;
      }
      i++;
    }
  } else {
    if (farmData.collections.length == 0) {
      return false;
    } else {
      let i = 0;
      while (i < farmData.collections.length) {
        if (
          farmData.collections[i].collection_name != farmData.collection ||
          farmData.collections[i].is_authorized == 0
        ) {
          return false;
        }
        i++;
      }
    }
  }
  return true;
};

const checkHTTPSPrefix = (link) => {
  let url = link
  if(url.indexOf("http") == -1){
    url = "https://" + link;
  }
  return url
}

export const getFarmSocials = (profile) => {
  let socialsList = [];
  if (profile?.telegram?.indexOf("http") > -1 || profile?.telegram?.indexOf("t.me") > -1) {
    socialsList = [
      ...socialsList,
      { display_name: "telegram", link: profile.telegram },
    ];
  }
  if (
    profile?.discord?.indexOf("http") > -1 ||
    profile?.discord?.indexOf("discord.") > -1
  ) {
    socialsList = [
      ...socialsList,
      { display_name: "discord", link: profile.discord },
    ];
  }
  if (
    profile?.twitter?.indexOf("http") > -1 ||
    profile?.twitter?.indexOf("twitter.com") > -1
  ) {
    socialsList = [
      ...socialsList,
      { display_name: "twitter", link: profile.twitter },
    ];
  }
  if (profile?.website?.indexOf("http") > -1 || profile?.website?.length >= 7) {
    socialsList = [
      ...socialsList,
      { display_name: "website", link: profile.website },
    ];
  }
  if (
    profile?.latest_drop?.indexOf("http") > -1 ||
    profile?.latest_drop?.length >= 10
  ) {
    socialsList = [
      ...socialsList,
      { display_name: "nft drop", link: profile.latest_drop },
    ];
  }
  if (
    profile?.atomichub?.indexOf("http") > -1 ||
    profile?.atomichub?.length >= 10
  ) {
    socialsList = [
      ...socialsList,
      { display_name: "atomichub", link: profile.atomichub },
    ];
  }
  if (
    profile?.waxdao?.indexOf("http") > -1 ||
    profile?.waxdao?.length >= 10
  ) {
    socialsList = [
      ...socialsList,
      { display_name: "waxdao", link: profile.waxdao },
    ];
  }  


  if (socialsList.length > 0) {
    return (
      <TallFiltersCont>
        <h2>Socials</h2>
        <br/>
        <TallFiltersInnerCont>
          <div className="flex flex-wrap gap-5 pl-4 pr-4">
          {socialsList.map((item, index) => (
            <a key={index} href={checkHTTPSPrefix(item.link)} target="none">
              <SmallButton>{item.display_name}</SmallButton>
            </a>
          ))}
          </div>
        </TallFiltersInnerCont>
      </TallFiltersCont>
    );
  } else {
    return null;
  }
};

export const farmIsExpired = (expires) => {
  let isExpired = false;

  if(expires < Date.now() / 1000){
    isExpired = true;
  }
  return isExpired
}

export const createCSV = (list) => {
  let csvHeader = '"WAX Address","Total NFTs","Asset IDs"' + "\n";
  let csvData;
  if(list.length > 0){
      csvData = list.sort((a, b) => b.assets.length - a.assets.length)
      .map((item, index) => (
            '"' + item.username + '","' + item.assets.length + '","' + item.assets.join(",") + '"\n'
      ))
  }

  let csvParts = []
  const file = new Blob([csvHeader], {type: 'text/plain'});
  csvParts.push(file)
  const file2 = new Blob(csvData, {type: 'text/plain'});
  csvParts.push(file2)
  const final = new Blob(csvParts, {type: 'text/plain'});

 // anchor link
 const element = document.createElement("a");
 element.href = URL.createObjectURL(final);
 element.download = "stakers_list-" + Date.now() + ".csv";

 // simulate link click
 document.body.appendChild(element);    
 element.click(); 

}