import { useState } from "react";
import { currentApiList, getCurrentApiList } from "../../data/config";
import axios from 'axios';

export const useGetCollectionDrops = () => {
    
    const [collectionDrops, setCollectionDrops] = useState([]);
    const [collectionPacks, setCollectionPacks] = useState([]);
    const [collectionDropsAreLoading, setCollectionDropsAreLoading] = useState(true);

    const getCollectionDrops = (CollectionName, setShowDrops, setShowUnpack) => {
        
        axios.post(`${getCurrentApiList}/v1/chain/get_table_rows`,{
            table:"drops",
            scope:"waxdaomarket",
            code:"waxdaomarket",
            reverse: true,
            key_type: 'name',
            index_position: '3',
            lower_bound: CollectionName,
            upper_bound: CollectionName,
            limit:1000,
            json:true
            }).then((res) => {
                
                if(res.data.rows.length > 0){
                    let i = 0;
                    let drop_list = [];
                    let pack_list = [];
                    while(i < res.data.rows.length){
                        if(res.data.rows[i].drop_type != "premint.pack"){
                            drop_list.push(res.data.rows[i])
                        }else{
                            pack_list.push(res.data.rows[i])
                        }
                        i++;
                    }
                    setCollectionDrops(drop_list);
                    setCollectionPacks(pack_list);
                    if(drop_list.length == 0){
                        setShowDrops(false);
                    }
                    if(pack_list.length == 0){
                        setShowUnpack(false);
                    }
                    console.log(res.data.rows);
                }
                else{setShowDrops(false); setShowUnpack(false);}
            })
            .catch((error) => console.log(error))
            .finally(() => setCollectionDropsAreLoading(false))

    }

    return [collectionDrops, collectionPacks, getCollectionDrops, collectionDropsAreLoading]
}