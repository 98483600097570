import { useEffect, useState } from "react";
import { useGetBlendRamBalance } from "../../components/CustomHooks/useGetBlendRamBalance";
import { useGetCollectionRamBalance } from "../../components/CustomHooks/useGetCollectionRamBalance";
import { useGetCollectionsByAuthorizedAccount } from "../../components/CustomHooks/useGetCollectionsByAuthorizedAccount";
import LoadingDiv from "../../components/LoadingDiv";
import { useStateContext } from "../../contexts/ContextProvider";
import { currentUsername } from "../../data/config";
import { resizerPrefix, resizerSuffix } from "../../data/constants";
import {
  PoolLineBreak,
  SinglePoolDetailBold,
} from "../../data/css/PoolPageStyles";
import {
  SendAllButton,
  TokenCard,
  TokenDetailsButton,
  TokenDetailsContainer,
} from "../../data/css/WalletStyles";
import {
  authorizeWaxDAO,
  depositBlendRam,
  depositCollectionRam,
  getAssetQuantity,
  notifyWaxDAO,
  removeWaxDAOCollectionAuth,
  removeWaxDAOCollectionNotify,
  withdrawBlendRam,
  withdrawCollectionRam,
} from "../../data/functions/wallet_functions";
import {
  FoldersContainer,
  FoldersRow,
  MainButton,
  SingleFolderFourth,
  SmallButton,
  TallFiltersCont,
  TallFiltersInnerCont,
  theme,
} from "../../Styles";

export const showCollectionSection = (
  currentMainSection,
  currentToolsSection,
  setLoadingDisplay
) => {
  const {
    enterModalDisplay,
    setEnterModalDisplay,
    enterModalText,
    setEnterModalText,
  } = useStateContext();

  const [selectedCollection, setSelectedCollection] = useState("");

  const [myCollections, getMyCollections, collectionsWereLoaded, collectionsAreLoading] =
    useGetCollectionsByAuthorizedAccount(currentUsername);

  useEffect(() => {
    if (
      !collectionsWereLoaded &&
      currentToolsSection == "Collections" &&
      currentMainSection == "Tools"
    ) {
      getMyCollections(currentUsername);
    }
  }, [currentToolsSection, currentMainSection]);

  return (
    <span className={currentToolsSection != "Collections" && "hidden"}>
      {collectionsWereLoaded && myCollections?.length > 0 && myCollections?.map((item, index) => (
            <TokenCard
              selected={
                selectedCollection == item.collection_name ? true : false
              }
              key={index}
            >
              <img
                src={`${resizerPrefix}${item.img}${resizerSuffix}`}
                onError={(e) =>
                  (e.target.onerror = null)(
                    (e.target.src = "https://logos.waxdao.io/default-logo.png")
                  )
                }
              />
              {item.collection_name}
              <TokenDetailsButton
                onClick={() => {
                  setSelectedCollection(
                    selectedCollection != item.collection_name
                      ? item.collection_name
                      : ""
                  );
                }}
              >
                Details
              </TokenDetailsButton>
              <br />
              <TokenDetailsContainer
                className={
                  selectedCollection != item.collection_name && "hidden"
                }
              >
                <br />
                <h3>Creator</h3>
                <span className="pl-2" style={{ color: theme.textSecondary }}>
                  {item.author}
                </span>

                <br />
                <br />

                <h3>
                  Authorized Accounts&nbsp;
                  {item.authorized_accounts.indexOf("waxdaomarket") > -1 ? (
                    <SmallButton
                      onClick={() => {
                        removeWaxDAOCollectionAuth(
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay
                        );
                      }}
                    >
                      Remove WaxDAO
                    </SmallButton>
                  ) : (
                    <SmallButton
                      onClick={() => {
                        authorizeWaxDAO(
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay
                        );
                      }}
                    >
                      Add WaxDAO
                    </SmallButton>
                  )}
                </h3>
                {item?.authorized_accounts?.length > 0 ? (
                  item?.authorized_accounts?.map((acct, acctIndex) => (
                    <span
                      className="pl-2"
                      style={{ color: theme.textSecondary }}
                    >
                      {acct}
                      <br />
                    </span>
                  ))
                ) : (
                  <span className="pl-2" style={{ color: theme.textSecondary }}>
                    No accounts to show
                  </span>
                )}

                <br />

                <h3>
                  Notify Accounts&nbsp;
                  {item.notify_accounts.indexOf("waxdaomarket") > -1 ? (
                    <SmallButton
                      onClick={() => {
                        removeWaxDAOCollectionNotify(
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay
                        );
                      }}
                    >
                      Remove WaxDAO
                    </SmallButton>
                  ) : (
                    <SmallButton
                      onClick={() => {
                        notifyWaxDAO(
                          item.collection_name,
                          setEnterModalText,
                          setLoadingDisplay,
                          setEnterModalDisplay
                        );
                      }}
                    >
                      Add WaxDAO
                    </SmallButton>
                  )}
                </h3>
                {item?.notify_accounts?.length > 0 ? (
                  item?.notify_accounts?.map((acct, acctIndex) => (
                    <span
                      className="pl-2"
                      style={{ color: theme.textSecondary }}
                    >
                      {acct}
                      <br />
                    </span>
                  ))
                ) : (
                  <span className="pl-2" style={{ color: theme.textSecondary }}>
                    No accounts to show
                  </span>
                )}
              </TokenDetailsContainer>
            </TokenCard>
          ))}
    {collectionsAreLoading && <LoadingDiv />}
    {collectionsWereLoaded && myCollections?.length == 0 && (
      <div className="w-100 text-center pt-3">
        No collections to show
      </div>
    )}
    {!collectionsWereLoaded && !collectionsAreLoading && (
      <div className="w-100 text-center pt-3">
        API error, please refresh the page to see your collections
      </div>
    )}


    </span>
  );
};
